/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {Box, Typography, Tab, Badge} from '@mui/material'
import {TabContext, TabList, TabPanel} from '@mui/lab';
import UpdateSpinner from '../../UpdateSpinner';
import IconUtils from '../../util/IconUtil';
import InvoicesTable from './invoicesTable';
import InvoicesErrorTable from './invoicesErrorTable';
import {
  BulkInvoicesStyles,
  mobileScrollNoteStyles
} from '../../../jss/inlineStyles'
import MobileScrollNote from '../../shared/MobileScrollNote';
import BulkReviewInformationCard from './bulkReviewInformationCard';

const BulkReviewPage = (props) => {

  const { details, errorFile, t, isFetching } = props;

  const [tab, setTab] = React.useState('1');

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const a11yProps = (index) => {
    return {
      id: `bulk-invoice-tab-${index}`,
      'aria-controls': `bulk-invoice-tabpanel-${index}`,
    };
  }

  const getTab = () => {
    let tabValue = tab;

    if (details.isCompleted) {
      tabValue = '2';
    }

    if (!details.totalErrors) {
      tabValue = '1';
    }

    return tabValue;
  }

  const downloadIcon = IconUtils.getIcon('FileDownload');

  let noDataMessage;

  if (!isFetching) {
    noDataMessage = (
      <Typography variant='heading1' component='p' sx={{textAlign: 'center', mt: 20}}>
        {t('BulkInvoices.NoInvoicesReadyToSend')}
      </Typography>
    );
  }

  return (<Box
      data-testid='bulkReviewPage'
      display='flex'
      flexDirection='column'
      gap='10px'
    >
      <BulkReviewInformationCard
        isFetching={isFetching}
        details={details}
        t={t}
      />
      <TabContext value={getTab()}>
        <Box sx={BulkInvoicesStyles.bulkReviewPage.tabWrapper}>
          <TabList aria-label='bulk invoice details'
                   onChange={handleChange}
          >
            {!details.isCompleted && <Tab
              icon={<Badge
                showZero
                badgeContent={details.totalImports}
                color='primary'
                max={999}
                sx={BulkInvoicesStyles.bulkReviewPage.tabIcon}
              />}
              iconPosition='end'
              label={t('BulkInvoices.ReadyToSend')}
              sx={BulkInvoicesStyles.bulkReviewPage.tab}
              value='1'
              {...a11yProps(0)}
            />}
            {!!details.totalErrors && <Tab
              data-testid='bulkReviewPageErrorTab'
              icon={<Badge
                badgeContent={details.totalErrors}
                color='error'
                max={999}
              />}
              iconPosition='end'
              label={t('BulkInvoices.Errors')}
              sx={BulkInvoicesStyles.bulkReviewPage.tab}
              value='2'
              {...a11yProps(1)}
            />}
          </TabList>
        </Box>
        <TabPanel value='1' sx={{padding: 0}}>
          {details.invoicesRow?.length > 0 ?
          <>
            <Box sx={mobileScrollNoteStyles}>
              <MobileScrollNote data-test-id='bulk-invoices-scroll-note' t={t} />
            </Box>
            <InvoicesTable
              isFetching={isFetching}
              rows={details.invoicesRow}
              t={t}
            />
          </>
            : noDataMessage
          }
        </TabPanel>
        <TabPanel value='2' sx={{padding: 0}}>
          <Box
            sx={BulkInvoicesStyles.bulkReviewPage.errorTabWrapper}
          >
            <Typography sx={{maxWidth: '600px'}}
                        variant='primaryFontFamilyMedium'
            >
              {t('BulkInvoices.ErrorDescription')}
            </Typography>
            <a
              download
              href={errorFile}
            >
              <Box
                alignItems='center'
                display='flex'
                gap='10px'
              >
                <Typography
                  component='span'
                  sx={{fontSize: '12px'}}
                >{t('BulkInvoices.DownloadWithErrors')}
                </Typography>
                {downloadIcon}
              </Box>
            </a>
          </Box>
          <Box sx={mobileScrollNoteStyles}>
            <MobileScrollNote data-test-id='bulk-invoices-error-scroll-note' t={t} />
          </Box>
          <InvoicesErrorTable
            {...props}
            headers={details.errorHeaders}
            rows={details.errorRows}
            isCompleted={details.isCompleted}
            t={t}
            isFetching={isFetching}
          />
        </TabPanel>
      </TabContext>
      {isFetching && <UpdateSpinner />}
    </Box>
  )
}

export default BulkReviewPage;
