/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import _ from 'lodash';
import { getEmployees } from '../../actions/employeesActions';
import {
  createCustomReports
} from '../../actions/merchantSettingsActions';
import { getCustomers } from '../../actions/customerActions';
import {
  getReports,
  setReportSummaryAll,
  setSelectedReport,
  clearReportError,
  getScheduledReports,
  toggleNoDataReportModal,
  setReportFailure,
  getLoyaltyReport,
  clearCustomReportSelected,
  getFraudReport,
  setIsInitializingReports,
  getPaymentLinksReport,
  closeHighCountWarning
} from '../../actions/reportsActions';
import {
  setVisibilityFilter,
  setOpenReportsDialog,
  toggleAllAccounts,
  setLoaderPercentage,
  generateExpandedTransactionCsv,
  generateCustomReportCsv,
  generateTransactionCsv,
  showLoader,
  hideLoader
} from '../../actions/userExperienceActions';
import { getTransactionReceipts } from '../../actions/transactionsActions';

import AccountSummaryReport from './reports/AccountSummaryReport';
import BatchReport from './reports/BatchReport';
import BatchSummaryReport from './reports/BatchSummaryReport';
import CardBrandSummary from './reports/CardBrandSummary';
import CustomerExperienceReport from './reports/CustomerExperienceReport';
import CustomerExport from './reports/CustomerExport';
import Loyalty from './reports/Loyalty';
import CustomerPerformanceReport from './reports/CustomerPerformanceReport';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import DateUtils from './../util/DateUtil';
import DepositDetailsReport from './reports/DepositDetailsReport';
import DepositSummaryReport from './reports/DepositSummaryReport';
import DisputesReport from './reports/DisputesReport';
import EmployeeFlashReport from './reports/EmployeeFlashReport';
import EmployeePerformanceReport from './reports/EmployeePerformanceReport';
import ExpandedTransactionReport from './reports/ExpandedTransactionReport';
import FilterPanel from '../shared/FilterPanel';
import FilterUtil from '../util/FilterUtil';
import FlashReport from './reports/FlashReport';
import IconUtil from '../util/IconUtil';
import ItemExport from './reports/ItemExport';
import ItemizedExport from './reports/ItemizedExport';
import ModifierPerformance from './reports/ModifierPerformance';
import NoData from '../NoData';
import PaymentTypeSummary from './reports/PaymentTypeSummary';
import ProductSalesReport from './reports/ProductSalesReport';
import RefundSummaryReport from './reports/RefundSummaryReport';
import ReportDetail from './ReportDetail';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import TaxExport from './reports/TaxExport';
import DeclinedTransactionExport from './reports/DeclinedTransactionExport';
import TransactionExport from './reports/TransactionExport';
import TransactionsCategoryExport from './reports/TransactionsCategoryExport';
import CustomReports from './reports/CustomReports';
import TransactionsItemExport from './reports/TransactionsItemExport';
import UserUtil from '../util/UserUtil';
import Modal from '../shared/Modal';
import GiftCardSummary from './reports/GiftCardSummary';
import ScheduleReportDialog from '../dialogs/activity/ScheduleReportDialog';
import MobileUtil from '../util/MobileUtil';
import Button from '../shared/Button';
import { ReportType } from '../util/ScheduleReportUtil';
import CsvUtil from '../util/CsvUtil';
import CsvExporter from '../util/CsvExporter';
import moment from 'moment';
import numeral from 'numeral';
import {getDeposits} from '../../actions/depositsActions';
import {getItems} from '../../actions/itemsActions';
import {getDisputes} from '../../actions/disputesActions';
import EmployeeFlashReportTable from './EmployeeFlashReportTable';
import {getBatches} from '../../actions/batchesActions';
import SecondaryLoader from '../SecondaryLoader';
import CustomReport from './CustomReport';
import { submit } from 'redux-form';
import SettingsUtil from '../util/SettingsUtil';
import Page from '../shared/Page';
import FraudReport from './reports/FraudReport';
import ButtonAccessory from '../appBar/accessories/ButtonAccesory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cancelcon from '@mui/icons-material/Cancel';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';
import PaymentLinksReport from './reports/PaymentLinksReport';

const defaultDateSelectionPeriod = 'Today';
const defaultTimeSelection = '00:00:00';
const REPORTS_INFO = 'What does Total Collected mean?';
const CUSTOM_REPORTS = 'Custom Reports';
const isLargeProcessorLength = 10000;

export default class Reports extends Component {

  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.getReport = this.getReport.bind(this);
    this.toggleAllAccounts = this.toggleAllAccounts.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.closeReportsInfo = this.closeReportsInfo.bind(this);
    this.closeNoDataReportModal = this.closeNoDataReportModal.bind(this);
    this.reportsErrorHandler = this.reportsErrorHandler.bind(this);
    this.showScheduleDialog = this.showScheduleDialog.bind(this);
    this.hideScheduleDialog = this.hideScheduleDialog.bind(this);
    this.getReportActions = this.getReportActions.bind(this);
    this.toggleActiveMobileActions = this.toggleActiveMobileActions.bind(this);
    this.initialize = this.initialize.bind(this);
    this.constructTransactionsCsv = this.constructTransactionsCsv.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.closeEmployeesDialog = this.closeEmployeesDialog.bind(this);
    this.setReportDataToView = this.setReportDataToView.bind(this);
    this.exportReport = this.exportReport.bind(this);
    this.handleExportButton = this.handleExportButton.bind(this);
    this.setReportToExport = this.setReportToExport.bind(this);
    this.handleViewReport = this.handleViewReport.bind(this);
    this.handleCustomReportDialog = this.handleCustomReportDialog.bind(this);
    this.closeCustomReportDialog = this.closeCustomReportDialog.bind(this);
    this.handleCustomReport = this.handleCustomReport.bind(this);
    this.submitCustomReport = this.submitCustomReport.bind(this);
    this.getCustomFields = this.getCustomFields.bind(this);
    this.toggleReportHeader = this.toggleReportHeader.bind(this);
    this.closeHighCountWarningModal = this.closeHighCountWarningModal.bind(this);

    const { auth, userExperience } = props;
    const { selectedDate } = userExperience;

    const dateRange = selectedDate?.dateRange ?? DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, defaultTimeSelection);

    this.state = {
      dateRange: dateRange,
      openReportsInfo: false,
      isScheduleDialogOpen: false,
      scheduleReportName: '',
      activeMobileActions: '',
      isReportDateFetching: false,
      isEmployeesDialogOpen: false,
      isCustomTransactionReportsDialogOpen: false,
      customReportEnabled: ['owner', 'admin'].includes(auth.role),
      customReportEnabledForManagerReporter: ['manager', 'report'].includes(auth.role),
      isReportHeaderOpen: true
    };
  }

  async componentDidMount() {
    await this.initialize();
  }

  componentDidUpdate(prevProps) {
    let dateRange = this.state.dateRange;

    if (!_.isEqual(prevProps.merchantSettings.customReportStartTime, this.props.merchantSettings.customReportStartTime)) {
      dateRange = this.props.userExperience.selectedDate?.dateRange ??
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, this.props.merchantSettings.customReportStartTime);
    }

    if (!_.isEqual(prevProps.userExperience.selectedDate, this.props.userExperience.selectedDate)) {
      dateRange = this.props.userExperience.selectedDate.dateRange;
    }

    if (dateRange !== this.state.dateRange) {
      this.setState({ dateRange });
    }
  }


  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
  }

  async initialize() {
    const { dispatch, userExperience } = this.props;

    dispatch(setIsInitializingReports(true));
    await SettingsUtil.getMerchantSettings(this.props);

    const dateRange = userExperience.selectedDate?.dateRange ??
      DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, this.props.merchantSettings.customReportStartTime);

    dispatch(setVisibilityFilter({
      property: 'type',
      value: 'All Reports'
    }, 'filter'));

    await this.loadData(this.props, dateRange, userExperience.allAccounts);

    if (userExperience.selectedReport) {

      await this.handleViewReport(userExperience.selectedReport, this.props);
      dispatch(setIsInitializingReports(false));

      if (this.props.location.state?.shouldDownload) {
        this.exportReport(userExperience.selectedReport, this.props);
        this.props.history.replace(this.props.location.path);
      }

    } else {
      dispatch(setIsInitializingReports(false));
    }

  }

  loadData(props, dateRange, allAccounts) {

    const { user, userExperience, selectedMerchantAccount, dispatch} = props;
    const { historicHardwareMode, selectedReport } = userExperience;

    let that = this;
    let accounts = allAccounts ? user.data.merchantAccounts :
      [_.find(user.data.merchantAccounts, (account) => account?.mea_id === parseInt(selectedMerchantAccount))];

    if (!Array.isArray(accounts)) {
      accounts = [accounts];
    }
    accounts = accounts.filter(account => !!(account || null));

    return new Promise((resolve) => {
      const requests = [
        dispatch(getReports(user, dateRange, null, false, null, historicHardwareMode)),
        dispatch(getReports(user, dateRange, 'advanced', allAccounts, null, historicHardwareMode)),
        dispatch(getScheduledReports(user)),
        dispatch(getEmployees(user))
      ];

      Promise.all(requests).then((response) => {

        let summaryResponses;
        if (!response.find(res => res.error)) {
          if (Array.isArray(response[0].response)) {
            summaryResponses = response[0].response.map(resp => {
              const account = _.find(accounts, (acc) => parseInt(acc.mea_id) === parseInt(resp.merchantAccount?.mea_pk) || acc.mid === resp.merchantAccount?.mea_mid);
              if (account || null) {
                resp.accountId = account.mea_id;
                resp.dba = account.dba_name;
                resp.mid = account.mid;
                return {response: resp};
              }

              return null;
            }).filter(resp => !!resp);
          } else if (response[0].response && response[0].response.merchantAccount) {

            const account = _.find(accounts, (acc) => parseInt(acc.mea_id) === parseInt(response[0].response.merchantAccount?.mea_pk) || acc.mid === response[0].response.merchantAccount?.mea_mid);
            if (account || null) {
              summaryResponses = [{
                response: {
                  ...response[0].response,
                  accountId: account?.mea_id,
                  dba: account?.dba_name,
                  mid: account?.mid,
                }
              }];
            } else {
              summaryResponses = [];
            }
          } else {
            summaryResponses = _.take(response, accounts.length);

            _.each(summaryResponses, (resp, idx) => {
              resp.response = {
                ...resp.response,
                accountId: accounts[idx]?.mea_id,
                dba: accounts[idx].dba_name,
                mid: accounts[idx].mid,
              };
            });
          }
        } else {
          summaryResponses = [];
        }

        // Also sets report data for selectedMerchantAccount
        props.dispatch(setReportSummaryAll(summaryResponses, that.props.selectedMerchantAccount));
        this.setReportDataToView(selectedReport, props);

        resolve();
      });
    });
  }

  toggleAllAccounts() {
    const showAllAccounts = !this.props.userExperience.allAccounts;
    this.props.dispatch(toggleAllAccounts(showAllAccounts));
    this.loadData(this.props, this.state.dateRange, showAllAccounts);
  }

  handleFilterSelection(filter) {
    if (filter.name === REPORTS_INFO) {
      this.setState({openReportsInfo: true, activeMobileActions: ''});
    } else {
      this.setState({ activeMobileActions: '' });
      this.props.dispatch(setSelectedReport(null));
      this.props.dispatch(setOpenReportsDialog(false));
      this.props.dispatch(setVisibilityFilter({
        property: 'type',
        value: filter.name
      }, 'filter'));
    }
  }

  handleClose() {
    this.props.dispatch(setOpenReportsDialog(false));
    this.loadData(this.props, this.state.dateRange, this.props.userExperience.allAccounts);
    this.props.dispatch(setSelectedReport(null));
  }

  handleDateSelection(value, dateRange) {

    const { userExperience: { allAccounts, selectedReport } } = this.props;
    this.setState({ dateRange: dateRange });
    this.loadData(this.props, dateRange, allAccounts);

    if (selectedReport) {
      this.setReportToExport(selectedReport, this.props);
    }

  }

  async setReportToExport(reportName, props) {
    await props.dispatch(setSelectedReport(reportName));
    await props.dispatch(setOpenReportsDialog(true));
    await this.setReportDataToView(reportName, props);
  }

  async constructTransactionsCsv({
                                   transactionsfirstPage,
                                   user,
                                   auth,
                                   dispatch,
                                   rowCount,
                                   userExperience,
                                   reportDate,
                                   isExpanded = false,
                                   customReport = {}
                                 }) {
    const initialPage = 2;
    const pageSize = 5000;
    const totalPages = Math.ceil(rowCount / pageSize);
    const percentageByPage = 100 / totalPages;
    let totalPercentage = 0;

    let finalResult = [];

    const generationFunction = isExpanded ? generateExpandedTransactionCsv : generateTransactionCsv;
    let resultResponse = [];
    if (!_.isEmpty(customReport)) {
      dispatch(generateCustomReportCsv({
        transactions: transactionsfirstPage,
        user,
        debtRepayment: auth.debtRepayment,
        processingLevel: auth.processingLevel,
        customReport,
        customFields: this.getCustomFields()
      })).then((result) => {
        if (result?.response?.length) {
          resultResponse = result.response;
        }
      });
    } else {
      dispatch(generationFunction({
        transactions: transactionsfirstPage,
        user,
        debtRepayment: auth.debtRepayment,
        processingLevel: auth.processingLevel
      })).then((result) => {
        if (result?.response?.length) finalResult.push(...result.response);
      });
    }

    const promiseChain = [];
    for(let page = initialPage; page <= totalPages; page++) {
      promiseChain.push(
        new Promise(async (resolve) => {
          const transactions = await dispatch(getTransactionReceipts(user, reportDate, userExperience.allAccounts, 'verbose', userExperience.historicHardwareMode, page, null));
          totalPercentage = totalPercentage + percentageByPage;
          dispatch(setLoaderPercentage(Math.round(totalPercentage)));
          if (transactions?.response?.rows?.length) {
            dispatch(generationFunction(transactions.response.rows, user, auth.debtRepayment, auth.processingLevel)).then((result) => {
              if (result?.response?.length) finalResult.push(...result.response);
              resolve();
            });
          }
          else {
            resolve();
          }
        })
      );
    }

    await Promise.all(promiseChain);
    return finalResult;
  }

  itemizedExportJson(props) {
    const { userExperience: {allAccounts}} = props;
    let accountNumber = _.find(props.user.data.merchantAccounts, ['mea_id', Number(props.user.selectedMerchantAccount)]).mid;

    return props.reports?.itemized?.length ? props.reports.itemized?.map((item) => {

      let discountPercentage = item.discount_rate ? numeral(item.discount_rate / 100).format('0.000 %') : '0.000 %';
      let taxPercentage = item.tax_rate ? numeral(item.tax_rate / 100).format('0.000 %') : '0.000 %';
      let itemQuantity = item.item_quantity ? item.item_quantity.toString() : '';
      let refundQuantity = item.items_refunded ? item.items_refunded : '0';
      let voidQuantity = item.items_voided ? item.items_voided : '0';

      return {
        'Account Number': allAccounts ? item.mid : accountNumber,
        'Item': item.item_name,
        'Items Sold': itemQuantity,
        'Items Refunded': refundQuantity,
        'Refunds': numeral(item.refund_total).format('$0,0.00'),
        'Items Voided': voidQuantity,
        'Voids': numeral(item.void_total).format('$0,0.00'),
        'Category': item.category,
        'Price': numeral(item.price).format('$0,0.00'),
        'Cost': numeral(item.cost).format('$0,0.00'),
        'Discount $': numeral(item.discount_amt).format('$0,0.00'),
        'Discount %': discountPercentage,
        'Barcode': item.barcode,
        'Tax %': taxPercentage,
        'Gross Sales': numeral(item.gross_sales).format('$0,0.00',),
        'Net Sales': numeral(item.net_sales).format('$0,0.00'),
        'Total Collected': numeral(item.total).format('$0,0.00')
      }
    }) : []
  }

  async exportReport(reportName, props, details = {}) {
    const {
      auth,
      user,
      merchantSettings,
      userExperience,
      reports,
      batches
    } = props;
    let transactionsJson = {};
    let fullReportName = reportName;
    let itemHeaders = undefined;
    let reportData = [];
    const reportDate = props?.reportDate ?? this.state.dateRange;
    const allAccounts = userExperience?.allAccounts;

    const isLargeProcessor = UserUtil.isLargeProcessor(user);
    const achEnabled = merchantSettings.merchantSettings?.ach_enabled;

    await props.dispatch(setSelectedReport(reportName));
    const generateCsv = ({ transactionsJson, reportName, fullReportName, itemHeaders }) => {
      if (reportName !== 'EmployeeFlashReport') {
        if (!_.isEmpty(transactionsJson)) {
          const fileName = CsvUtil.buildFilename(fullReportName, {
            ...props,
            reportDate,
          });
          CsvExporter.download(transactionsJson, fileName, itemHeaders);
        } else {
          props.dispatch(toggleNoDataReportModal());
        }
      }
    };

    const count = isLargeProcessor ? null : 'all';

    switch (reportName) {
      case 'AccountSummary':
        transactionsJson = await CsvUtil.createAccountSummaryCsv(reports.allReports);
        break;
      case 'Batches':
      case 'BatchSummary':
        return props.dispatch(getBatches(user, reportDate, null, 'batch'))
          .then(async (result) => {
            if (result?.response?.length) {
              transactionsJson = await CsvUtil.createBatchesSummaryCsv(batches.data);
              generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => {
            return props.dispatch(setReportFailure(error));
          });
      case 'FlashReport':
        transactionsJson = await CsvUtil.createFlashReportCsv(reports.data, user, achEnabled);
        fullReportName = 'Flash';
        break;
      case 'GiftCardSummary':
        transactionsJson = await CsvUtil.createGiftCardSummaryReportCSV(reports.data, user);
        fullReportName = 'Giftcard_summary';
        break;
      case 'ItemPerformance':
        transactionsJson = await this.itemizedExportJson(props);
        fullReportName = 'ItemizedExport';
        break;
      case 'ModifierPerformance':
        transactionsJson = await CsvUtil.createModifierPerformanceCsv(reports.modifiers, user, allAccounts);
        break;
      case 'ProductSales':
        transactionsJson = await CsvUtil.createProductSalesCsv(reports.data);
        break;
      case 'BrandSummary':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, 'all'))
          .then(async (result) => {
            transactionsJson = result?.response?.length && CsvUtil.mapNetworkTransactions(result.response);
            transactionsJson = await CsvUtil.createCardBrandSummaryCsv(transactionsJson, user, merchantSettings.merchantSettings?.cash_discount_enabled);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => {
            return props.dispatch(setReportFailure(error));
          });
      case 'CardBrandSummary':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, 'all'))
          .then(async (result) => {
            transactionsJson = result?.response?.length && await CsvUtil.createCardBrandSummaryCsv(result.response, user, merchantSettings.merchantSettings?.cash_discount_enabled);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => {
            return props.dispatch(setReportFailure(error));
          });
      case 'CustomerExperience':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts))
          .then((result) => {
            const filteredReceipts = result?.response?.filter((receipt) => receipt?.customer_rating && receipt.customer_rating);
            if (filteredReceipts.length) {
              transactionsJson = CsvUtil.createCustomerExperienceCsv(result.response, user, auth.debtRepayment);
              generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'CustomerExport':
        return props.dispatch(getCustomers(props.user))
          .then((result) => {
            transactionsJson = result?.response?.length && CsvUtil.createCustomersCsv(result.response, user, auth.debtRepayment);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'LoyaltyRewardsReport':
        return props.dispatch(getLoyaltyReport(props.user))
          .then((result) => {
            const loyaltyData = result?.response;
            if (loyaltyData?.length) {
              transactionsJson = CsvUtil.createLoyaltyCsv(loyaltyData, user);
              generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'CustomerPerformance':
        return props.dispatch(getTransactionReceipts(user))
          .then(async (result) => {
            transactionsJson = result?.response?.length && await CsvUtil.createCustomerPerformanceCsv(result.response, user, auth.debtRepayment);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'DepositSummary':
        return props.dispatch(getDeposits(user, reportDate, null, null, allAccounts))
          .then(async (result) => {
            transactionsJson = result?.response?.length && await CsvUtil.createDepositsCsv(result.response, user);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'DeclinedTransactions':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, null, null, 'all', undefined, true))
          .then(async (result) => {
            if (result?.response?.length) {
              try {
                props.dispatch(setLoaderPercentage(0));
                if (result.response.length > isLargeProcessorLength) {
                  transactionsJson = await this.constructTransactionsCsv({
                    transactionsfirstPage: result.response,
                    user,
                    auth,
                    dispatch: props.dispatch,
                    rowCount,
                    userExperience,
                    reportDate
                  });
                } else {
                  transactionsJson = await CsvUtil.createTransactionCsv(result.response, user, auth.debtRepayment, true);
                  props.dispatch(setLoaderPercentage(100));
                }
                await CsvExporter.download(
                  transactionsJson,
                  'Declined_Transaction_' + moment().format('MMDDYYYY') + '.csv');
              } catch (error) {
                props.dispatch(setReportFailure(error));
              }
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'FraudMonitoring':
        const { reports: reportsData } = props;

        const safeFraudData = reportsData?.fraudReports?.safeFraudData;
        const tc40FraudData = reportsData?.fraudReports?.tc40FraudData;
        const fraudData = [...safeFraudData, ...tc40FraudData].filter(reportInfo => details.reportIds.includes(reportInfo.ID || reportInfo.GUID));
        await CsvExporter.download(
          fraudData,
          `${details.type}_REPORT_${details.date}.csv`);
        return;
      case 'DisputesReport':
        fullReportName = `Disputes_${props.user.selectedMerchantAccount}_${moment().format('MMDDYYYY')}.csv`;
        return props.dispatch(getDisputes(user, allAccounts, reportDate))
          .then(async (result) => {
            transactionsJson = result?.response?.disputes?.length && await CsvUtil.createDisputesCsv(result.response.disputes, user);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'EmployeeFlashReport':
        if (!props?.fromReportView) {
          this.setState({
            isEmployeesDialogOpen: true
          });
        } else {
          const { dateRange } = this.state;
          const employee = props?.employee;
          return props.dispatch(getReports(user, dateRange, null, allAccounts, employee.id))
            .then(async (resp) => {
              if (resp?.response && !_.isEmpty(resp?.response)) {
                const flashReportData = { ...resp.response, employee  };
                transactionsJson = await CsvUtil.createFlashReportCsv(flashReportData, user, achEnabled);
                fullReportName = `Flash_${employee.first_name}${employee.last_name}`;
                const fileName = await CsvUtil.buildFilename(fullReportName, {
                  ...props
                });
                await CsvExporter.download(transactionsJson, fileName);
              } else {
                props.dispatch(toggleNoDataReportModal());
              }
            })
            .catch(error => props.dispatch(setReportFailure(error)));
        }
      case 'EmployeePerformance':
        const employees = props?.employees?.data ?? this.props.employees.data;
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts))
          .then(async (result) => {
            transactionsJson = result?.response?.length && await CsvUtil.createEmployeePerformanceCsv(result.response, user, employees);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'CustomReports':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, 'verbose', userExperience.historicHardwareMode, null, count))
          .then(async (result) => {
            const transactionsList = isLargeProcessor ? result?.response?.rows : result?.response;
            if (transactionsList?.length) {
              try {
                props.dispatch(setLoaderPercentage(0));
                const rowCount = result?.response?.rowCount;
                props.dispatch(showLoader());
                if (transactionsList.length > isLargeProcessorLength || isLargeProcessor && rowCount > transactionsList.length) {
                  transactionsJson = await this.constructTransactionsCsv({
                    transactionsfirstPage: result.response,
                    user,
                    auth,
                    dispatch: props.dispatch,
                    rowCount,
                    userExperience,
                    reportDate,
                    isExpanded: true,
                    customReport: props.reports.customReportSelected
                  });
                } else {
                  transactionsJson = await CsvUtil.createCustomReportCsv({
                    transactions: transactionsList,
                    user,
                    processingLevel: auth.processingLevel,
                    columns: props.reports.customReportSelected?.columns,
                    customFields: this.getCustomFields(),
                  });
                  props.dispatch(setLoaderPercentage(100));
                }
                props.dispatch(hideLoader());
                const reportName = props.reports.customReportSelected?.name;
                props.dispatch(clearCustomReportSelected());
                return transactionsJson.length > 0 && await CsvExporter.download(
                  transactionsJson,
                  `${reportName}_${user.data.merchantAccounts[0].mid}_from_${moment(reportDate.startDate).format('MMDDYYYY')}_to_${moment(reportDate.endDate).format('MMDDYYYY')}.csv`
                );
              } catch (error) {
                return props.dispatch(setReportFailure(error));
              }
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          });
      case 'ExpandedTransactionReport':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, 'verbose', userExperience.historicHardwareMode, null, count))
          .then(async (result) => {
            const transactionsList = isLargeProcessor ? result?.response?.rows : result?.response;
            if (transactionsList?.length) {
              try {
                props.dispatch(setLoaderPercentage(0));
                const rowCount = result?.response?.rowCount;
                props.dispatch(showLoader());
                if (transactionsList.length > isLargeProcessorLength || isLargeProcessor && rowCount > transactionsList.length) {
                  transactionsJson = await this.constructTransactionsCsv({
                    transactionsfirstPage: transactionsList,
                    user,
                    auth,
                    dispatch: props.dispatch,
                    rowCount,
                    userExperience,
                    reportDate,
                    isExpanded: true
                  });
                } else {
                  transactionsJson = await CsvUtil.createExpandedTransactionCsv(transactionsList, user, auth.debtRepayment, auth.processingLevel);
                  props.dispatch(setLoaderPercentage(100));
                }
                props.dispatch(hideLoader());
                return transactionsJson.length > 0 && await CsvExporter.download(
                  transactionsJson,
                  'Transaction_expanded_' + moment().format('MMDDYYYY') + '.csv');
              } catch (error) {
                return props.dispatch(setReportFailure(error));
              }
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'InventoryExport':
        return props.dispatch(getItems(user))
          .then((result) => {
            if (result?.response) {
              let { filteredItems } = FilterUtil.filterItems(result.response, props.visibilityFilter);
              filteredItems = filteredItems.map(item => {
                const modifierSets = _.map(result.response.modifierSets, (modifier) => {
                  return {
                    ...modifier,
                    isSelected: !!_.find(item.option_set_ids, (option) => option === modifier.id)
                  }
                });
                item.categories = [_.find(result.response.categories, ['id', item.categories[0]])];
                return {
                  ...item,
                  details: {
                    ...item.details,
                    modifierSets
                  }
                };
              });
              transactionsJson = CsvUtil.constructExportItem(filteredItems, user);
              itemHeaders = CsvUtil.constructItemExportHeaders(transactionsJson);
              fullReportName = 'ItemExport';
              generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'PaymentLinksReport':
        return props.dispatch(getPaymentLinksReport(user, reportDate))
          .then((result) => {
            fullReportName = 'Payment Links';
            const paymentLinksData = result?.response;
            if (paymentLinksData?.length) {
              transactionsJson = CsvUtil.createPaymentLinksCsv(paymentLinksData);
              generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'PaymentTypeSummary':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, 'all'))
          .then(async (result) => {
            transactionsJson = result?.response?.length && await CsvUtil.createPaymentTypeSummaryCsv(result.response, user);
            fullReportName = 'PaymentType';
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'RefundSummary':
        return props.dispatch(getTransactionReceipts(props.user, reportDate, allAccounts, null, null, null, 'all'))
          .then(async (result) => {
            reportData = result?.response?.length && result.response.filter(refund => refund.type.includes('Refund'));
            transactionsJson = await CsvUtil.createRefundSummaryCsv(reportData, user);
            fullReportName = 'Refunds';
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'Tax':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, null, null, 'all'))
          .then(async (result) => {
            transactionsJson = await CsvUtil.createTaxCsv(result.response, user);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'Transactions':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, 'verbose', userExperience.historicHardwareMode, null, count))
          .then(async (result) => {
            const transactionsList = isLargeProcessor ? result?.response?.rows : result?.response;
            if (transactionsList?.length) {
              try {
                props.dispatch(setLoaderPercentage(0));
                const rowCount = result?.response?.rowCount;
                props.dispatch(showLoader());
                if (transactionsList.length > isLargeProcessorLength || isLargeProcessor && rowCount > transactionsList.length) {
                  transactionsJson = await this.constructTransactionsCsv({
                    transactionsfirstPage: transactionsList,
                    user,
                    auth,
                    dispatch: props.dispatch,
                    rowCount,
                    userExperience,
                    reportDate
                  });
                } else {
                  transactionsJson = await CsvUtil.createTransactionCsv(transactionsList, user, auth.debtRepayment, auth.debtRepayment);
                  props.dispatch(setLoaderPercentage(100));
                }
                props.dispatch(hideLoader());
                return transactionsJson.length > 0 && await CsvExporter.download(
                  transactionsJson,
                  'Transaction_' + moment().format('MMDDYYYY') + '.csv');
              } catch (error) {
                return props.dispatch(setReportFailure(error));
              }
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'TransactionsByCategory':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode))
          .then(async (result) => {
            if (result?.response?.length) {
              transactionsJson = await CsvUtil.createTransactionWithCategoriesJson(result.response, user);
              itemHeaders = await CsvUtil.constructTransactionsWithCategoriesHeaders(transactionsJson);
              fullReportName = 'TransactionsCategory';
              generateCsv({
                transactionsJson,
                reportName,
                fullReportName,
                itemHeaders
              });
            } else {
              props.dispatch(toggleNoDataReportModal());
            }
          })
          .catch(error => props.dispatch(setReportFailure(error)));
      case 'TransactionsByItem':
        return props.dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode))
          .then(async (result) => {
            transactionsJson = await CsvUtil.createTransactionWithItemsJson({ ...props, transactions: { receipts: result.response } });
            itemHeaders = await CsvUtil.constructTransactionsWithItemsHeaders(transactionsJson);
            generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
          })
          .catch(error => props.dispatch(setReportFailure(error)));
    }
    generateCsv({ transactionsJson, reportName, fullReportName, itemHeaders });
  }

  getReport() {

    const { userExperience } = this.props;
    const selectedReport = userExperience?.selectedReport ? _.startCase(userExperience.selectedReport)?.replace(/ /g, '') : '';
    
    switch (selectedReport) {
      case 'AccountSummary':
        return (<AccountSummaryReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'Batch':
      case 'BatchDetail':
        return (<BatchReport reportDate={this.state.dateRange} toggleReportHeader={this.toggleReportHeader} {...this.props} />);
      case 'Batches':
      case 'BatchSummary':
        return (<BatchSummaryReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'BrandSummary':
        return (<CardBrandSummary reportDate={this.state.dateRange} exportReport={this.exportReport} isBrandReport={true} {...this.props} />);
      case 'CardBrandSummary':
        return (<CardBrandSummary reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'CustomerExperience':
        return (<CustomerExperienceReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'CustomerExport':
        return (<CustomerExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'LoyaltyRewardsReport':
        return (<Loyalty reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'CustomerPerformance':
        return (<CustomerPerformanceReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'DeclinedTransactions':
        return (<DeclinedTransactionExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'DepositDetail':
        return (<DepositDetailsReport reportDate={this.state.dateRange} {...this.props} />);
      case 'FraudMonitoring':
        return (<FraudReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'DepositSummary':
        return (<DepositSummaryReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'DisputesReport':
        return (<DisputesReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props}/>);
      case 'EmployeeFlashReport':
        return (<EmployeeFlashReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'EmployeePerformance':
        return (<EmployeePerformanceReport exportReport={this.exportReport} reportDate={this.state.dateRange} {...this.props} />);
      case 'ExpandedTransactionReport':
        return (<ExpandedTransactionReport reportDate={this.state.dateRange} exportReport={this.exportReport}  {...this.props} />);
      case 'FlashReport':
        return (<FlashReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'GiftCardSummary':
        return (<GiftCardSummary reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'InventoryExport':
        return (<ItemExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'ItemPerformance':
        return (<ItemizedExport reportDate={this.state.dateRange} exportReport={this.exportReport} itemizedExportJson={this.itemizedExportJson} {...this.props} />);
      case 'ModifierPerformance':
        return (<ModifierPerformance reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'PaymentLinksReport':
        return (<PaymentLinksReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'PaymentTypeSummary':
        return (<PaymentTypeSummary reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'ProductSales':
        return (<ProductSalesReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'RefundSummary':
        return (<RefundSummaryReport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props}/>);
      case 'Tax':
        return (<TaxExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'Transactions':
        return (<TransactionExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'TransactionsByItem':
        return (<TransactionsItemExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'TransactionsByCategory':
        return (<TransactionsCategoryExport reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} />);
      case 'CustomReports':
        return (<CustomReports reportDate={this.state.dateRange} exportReport={this.exportReport} {...this.props} customFields={this.getCustomFields()} />);
      default:
        return (<NoData text={this.props.t('ReportComingSoon')} />);
    }
  }

  closeReportsInfo() {
    this.setState({openReportsInfo: false});
  }

  showScheduleDialog(reportName) {
    this.setState({isScheduleDialogOpen: true, scheduleReportName: reportName});
  }

  hideScheduleDialog() {
    this.setState({isScheduleDialogOpen: false, scheduleReportName: ''});
  }

  toggleActiveMobileActions(reportName) {
    const { activeMobileActions } = this.state;
    this.setState({ activeMobileActions: activeMobileActions === reportName ? '' : reportName });
  }

  closeNoDataReportModal() {
    this.props.dispatch(toggleNoDataReportModal());
  }
  
  closeHighCountWarningModal() {
    this.props.dispatch(setSelectedReport(null));
    this.props.dispatch(setOpenReportsDialog(false));
    this.props.dispatch(closeHighCountWarning());
  }

  async setReportDataToView(reportName, props) {
    const {
      dispatch,
      user,
      userExperience,
      merchantSettings
    } = props;
    const isLargeProcessor = UserUtil.isLargeProcessor(user);
    const reportDate = this.state.dateRange;
    const count = isLargeProcessor ? null : 'all';
    const allAccounts = this.props.userExperience?.allAccounts;
    const isCountAll = count === 'all';

    switch (reportName) {
      case 'BatchDetail':
        await dispatch(getDeposits(user, reportDate, null, 'batch', allAccounts, isCountAll));
        break;
      case 'Batches':
      case 'BatchSummary':
        await dispatch(getBatches(user, reportDate, null, 'batch'));
        break;
      case 'CardBrandSummary':
      case 'BrandSummary':
        await dispatch(getTransactionReceipts(user, reportDate, userExperience.allAccounts, 'raw', userExperience.historicHardwareMode, null, 'all', null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'CustomerExperience':
        await dispatch(getTransactionReceipts(user, reportDate, userExperience.allAccounts, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true))
        break;
      case 'CustomerExport':
        await dispatch(getCustomers(user));
        break;
      case 'LoyaltyRewardsReport':
        await dispatch(getLoyaltyReport(user));
        break;
      case 'CustomerPerformance':
        await dispatch(getTransactionReceipts(user, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'DeclinedTransactions':
        await dispatch(getTransactionReceipts(user, reportDate, userExperience.allAccounts, null, null, null, 'all', undefined, true, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'DepositDetail':
      case 'DepositSummary':
        await dispatch(getDeposits(user, reportDate, null, null, allAccounts));
        break;
      case 'DisputesReport':
        await dispatch(getDisputes(user, allAccounts, reportDate));
        break;
      case 'EmployeePerformance':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'ExpandedTransactionReport':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, 'verbose', userExperience.historicHardwareMode, null, count, null, null, null, null, null, null, null, null, null, null, null, null, true));
        return;
      case 'InventoryExport':
        await dispatch(getItems(user));
        break;
      case 'FraudMonitoring':
        const dateRange = this.state.dateRange?.text ? this.state.dateRange :
          DateUtils.getPersonalizedDateRange('Today', merchantSettings.customReportStartTime);
        await dispatch(getFraudReport(user, dateRange));
        break;
      case 'PaymentLinksReport':
        await dispatch(getPaymentLinksReport(user, reportDate));
      case 'PaymentTypeSummary':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, 'all', null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'RefundSummary':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, null, null, 'all', null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'Tax':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, null, null, 'all', null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'Transactions':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, 'verbose', userExperience.historicHardwareMode, null, count, null, null, null, null, null, null, null, null, null, null, null, null, true));
        return;
      case 'TransactionsByItem':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
      case 'TransactionsByCategory':
        await dispatch(getTransactionReceipts(user, reportDate, allAccounts, null, userExperience.historicHardwareMode, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true));
        break;
    }
  }

  async handleExportButton(reportName, props) {
    const report = reportName ? _.startCase(reportName)?.replace(/ /g, '') : '';
    await this.exportReport(report, props);
    this.props.dispatch(setSelectedReport(null));
  }

  async handleViewReport(reportName, props) {
    const report = reportName ? _.startCase(reportName)?.replace(/ /g, '') : '';
    await this.setReportToExport(report, props);
  }

  async handleScheduleButton(reportName) {
    await this.showScheduleDialog(reportName);
  }

  getCustomFields() {
    const { merchantSettings } = this.props;
    const vt_fields = merchantSettings?.merchantSettings?.custom_fields?.vt_fields;
    if (!!vt_fields) {
      return vt_fields.map(customField => {
        for (let key in customField) {
          return {
            fieldName: customField[key].label,
            fieldKey: _.camelCase(customField[key].label),
            fieldId: key,
            isRequired: customField[key].required
          }
        }
      });
    } else {
      return [];
    }

  }

  handleCustomReportDialog() {
    this.setState({
      isCustomTransactionReportsDialogOpen: true
    });
  }

  async handleCustomReport(values) {
    const { dispatch, user } = this.props;
    const customFields = this.getCustomFields();
    let customReport = {
      name: values.reportName,
      columns: _.filter([
        'Date',
        values?.txn_source ? 'Transaction Source' : '',
        'Transaction Type',
        'Account Number',
        'DBA',
        values?.invoice ? 'Invoice' : '',
        values?.sold_by ? 'Sold By' : '',
        values?.sold_by_override_name ? 'Placed For' : '',
        values?.customer_name ? 'Customer Name' : '',
        'Total Transaction Amount',
        values?.amount ? 'Payment Amount' : '',
        values?.ccs_authorized_amt ? 'Authorized Amount' : '',
        values?.tip_amount ? 'Tip' : '',
        values?.cc_auth_code ? 'Auth' : '',
        values?.bric ? 'BRIC' : '',
        values?.parent_bric ? 'Original BRIC' : '',
        values?.deposit_datetime ? 'Deposit Date' : '',
        values?.rate ? 'Rate' : '',
        values?.fee ? 'Fee' : '',
        values?.deposit_amount ? 'Deposit Amount' : '',
        values?.total_discount_amt ? '$ Discount' : '',
        values?.total_discount_percentage_amt ? '% Discount' : '',
        values?.partial_tax ? '$ Tax' : '',
        values?.service_fee_amount ? 'Cash Discounting Amount' : '',
        values?.service_fee_amount_total ? 'Total Cash Transaction Amount' : '',
        values?.state_tax ? 'State Tax' : '',
        values?.county_tax ? 'County Tax' : '',
        values?.city_tax ? 'City Tax' : '',
        values?.customized_tax ? 'Custom Tax' : '',
        values?.type ? 'Payment Tax' : '',
        values?.network ? 'Card Brand' : '',
        values?.cc_first6 ? 'First 6' : '',
        values?.cc_last4 ? 'Last 4' : '',
        values?.comment ? 'Comment' : '',
        values?.l2 ? 'L2' : '',
        values?.l3 ? 'L3' : ''
      ], column => column !== '')
    };
    if (customFields?.length) {
      const customColumns = customFields.map(field => {
        return field.fieldName;
      });
      customReport.columns = [
        ...customReport.columns,
        ...customColumns
      ];
    }
    this.closeCustomReportDialog();
    await dispatch(createCustomReports(user, customReport));
  }

  submitCustomReport() {
    this.props.dispatch(submit('customReportForm'));
  }

  getReportActions(report, scheduleReportInfo) {
    const isReportActive = scheduleReportInfo && scheduleReportInfo.is_active;
    const { t } = this.props;
    return (
      <div className='reportActions'>
        {
          ['Flash Report', 'Batch Summary'].includes(report.name) &&
          <div className={`exportButtonContainer ${isReportActive ? 'extendedButton' : ''}`}>
            <Button variant='text' onClick={() => this.handleScheduleButton(report.name)} label={`${isReportActive ? t('ScheduledReports.EditSchedule') : t('ScheduledReports.Schedule')}`} className='whiteFlatButton' />
          </div>
        }
        <div className='exportButtonContainer'>
          {
            !['Batch', 'Batch Detail', 'Deposit Detail', 'Custom Reports', 'Fraud Monitoring'].includes(report.name) && (
              <Button className='handleExportButton whiteFlatButton' variant='text' label={t('Export')} onClick={() => this.handleExportButton(report.name, this.props)} />
            )
          }
        </div>
        <div className='exportButtonContainer'>
          <Button className='handleViewButton' label={t('View')} onClick={() => this.handleViewReport(report.name, this.props)} />
        </div>
      </div>
    );
  }

  reportsErrorHandler() {
    this.props.dispatch(toggleAllAccounts(false));
    this.props.dispatch(clearReportError());
  }

  async onRowClick({ rowIndex, employees }) {
    let transactionsJson = [];
    let fullReportName = '';
    const employee = employees[rowIndex];
    const { userExperience, merchantSettings, user, dispatch } = this.props;
    const dateRange = this.state.dateRange?.text ? this.state.dateRange :
      DateUtils.getPersonalizedDateRange('Today', merchantSettings.customReportStartTime);
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    await dispatch(getReports(user, dateRange, null, userExperience.allAccounts, employee.id))
      .then(async (resp) => {
        if (resp?.response) {
          const flashReportData = { ...resp.response, employee  }
          transactionsJson = await CsvUtil.createFlashReportCsv(flashReportData, user, achEnabled);
          fullReportName = `Flash_${employee.first_name}${employee.last_name}`;
          const fileName = await CsvUtil.buildFilename(fullReportName, {
            ...this.props
          });
          await CsvExporter.download(transactionsJson, fileName);
        } else {
          dispatch(toggleNoDataReportModal());
        }
        this.closeEmployeesDialog();
      })
      .catch((error) => {
        return dispatch(setReportFailure(error));
      });
  }

  closeEmployeesDialog () {
    this.setState({
      isEmployeesDialogOpen: false,
    });
  }

  closeCustomReportDialog () {
    this.setState({
      isCustomTransactionReportsDialogOpen: false,
    });
  }

  toggleReportHeader () {
    this.setState((prevState) => ({
      isReportHeaderOpen: !prevState.isReportHeaderOpen
    }));
  }

  render() {

    const { reports, user, userExperience, merchantSettings, accountProfile, invoices, employees, auth, t } = this.props;

    const isFetching = reports.isFetching || user.isFetching || accountProfile.isFetching || invoices.isFetching
      || employees.isFetching || reports.isFetchingReportsAdvanced;
    const {isProcessing} = merchantSettings;

    const scheduledReports = reports.scheduledReports;

    const { historicHardwareMode } = userExperience;
    const isMobile = MobileUtil.isMobileDevice();
    const isLargeProcessor = UserUtil.isLargeProcessor(user);
    const isEmpty = (isLargeProcessor & (reports.data === null || merchantSettings.customReportStartTime === null)) ||
      (!isLargeProcessor && (reports.data === null || reports.itemized === null || merchantSettings.customReportStartTime === null));

    const isClosedAccount = !UserUtil.isActive(user);
    const isCsUser = UserUtil.isUserCS(user, auth);
    const allAccounts = userExperience.allAccounts;

    if (reports.constructingCsv) {
      return (<SecondaryLoader {...this.props} />);
    } else if ((isEmpty && isFetching) || reports.isFetchingReports || isProcessing || reports.isInitializing) {
      return (<Page loading title={t('Reports')}/>);
    } else {
      const filter = [
        {name: 'All Reports'},
        {name: 'Sales Reports'},
        {name: 'Customer Reports'},
        {name: 'Employee Reports'},
        {name: 'Inventory Reports'},
        {/* spacer */},
        {name: REPORTS_INFO, icon: 'InfoIcon', openModal: true}
      ];
      if (this.state.customReportEnabled || this.state.customReportEnabledForManagerReporter) {
        filter.splice(5, 0, {name: CUSTOM_REPORTS});
      }
      const filterMBPandHBT = [
        {name: 'All Reports'},
        {name: 'Sales Reports'},
        {/* spacer */},
        {name: REPORTS_INFO, icon: 'InfoIcon', openModal: true}
      ];
      const reportsInfo = (
        <Modal
          externalClassName='reportsInfoDialog'
          title={t('What does Total Collected mean?')}
          open={this.state.openReportsInfo}
          confirmText={t('Close')}
          onConfirm={this.closeReportsInfo}
          hideCancelButton
        >
          <div className='reportsInfo'>
            <h4>{t('Gross Sales')}</h4>
            <p>{t('ReportsInfo.GrossSales')}</p>

            <h4>{t('Net Sales')}</h4>
            <p>{t('ReportsInfo.NetSales')}</p>

            <h4>{t('Total Collected')}</h4>
            <p>{t('ReportsInfo.TotalCollected')}</p>
          </div>
        </Modal>
      );

      const noDataOnReportFetched = (
        <Modal
          externalClassName='reportsInfoDialog'
          title={t('NoDataDialog.Title')}
          open={reports.isNoDataReportModalOpen}
          confirmText={t('Close')}
          onConfirm={this.closeNoDataReportModal}
          hideCancelButton
        >
          <p>{t('NoDataDialog.Message')}</p>
        </Modal>
      );

      const reportUnauthorizedErrorModal = (
        <Modal
          className='reportsErrorModal'
          title={t('UnauthorizedErrorModal.Title')}
          open={!!reports.error}
          confirmText={t('Close')}
          onConfirm={() => this.reportsErrorHandler()}
          hideCancelButton
        >
          <p>{t('UnauthorizedErrorModal.Message')}</p>
        </Modal>
      );

      const employeesDialog = (
        <Modal
          externalClassName='employeesDialog'
          title={t('Employees')}
          open={this.state.isEmployeesDialogOpen}
          cancelText={t('Cancel')}
          onClose={this.closeEmployeesDialog}
          hideConfirmButton
        >
          <EmployeeFlashReportTable t={t} onRowClick={this.onRowClick} {...this.props} />
        </Modal>
      );
      const customReportDialog = this.state.customReportEnabled && !this.state.customReportEnabledForManagerReporter ? (
        <Modal
          externalClassName='customReportDialog'
          title={t('CreateReportTitle')}
          open={this.state.isCustomTransactionReportsDialogOpen}
          cancelText={t('Cancel')}
          confirmText={t('Save')}
          onConfirm={this.submitCustomReport}
          onClose={this.closeCustomReportDialog}
          maxWidth={'md'}
        >
          <CustomReport t={t} onSubmit={this.handleCustomReport} customFields={this.getCustomFields()} />
        </Modal>
      ) : null;
      
      const highCountWarningModal = (
        <Modal
          externalClassName='reportsInfoDialog'
          title={t('HighCountWarning.title')}
          open={reports.showHighDataCountWarning}
          confirmText={t('Close')}
          onConfirm={this.closeHighCountWarningModal}
          hideCancelButton
        >
          <p>{allAccounts? t('HighCountWarning.bodyMultiple') : t('HighCountWarning.body')}</p>
        </Modal>
      )

      const filterData = UserUtil.userType(user) === 'PA' && !historicHardwareMode ? filter : filterMBPandHBT;

      const reportToShow = this.getReport();

      const loyaltyVpc = merchantSettings.loyaltyVpc;
      const isLoyaltyProgramEnabled = loyaltyVpc?.enabled;
      const hasNeverEnabledLoyalty = _.isEqual(loyaltyVpc, {enabled: false});
      const isLoyaltyReport = userExperience?.selectedReport === 'LoyaltyRewardsReport';

      let reportTitle;

      if (isLoyaltyReport) {
        if (isLoyaltyProgramEnabled || hasNeverEnabledLoyalty) reportTitle = t('LoyaltyRewardsReport');
        else reportTitle = t('LoyaltyRewardsReportDisabled');
      } else {
        reportTitle = userExperience?.selectedReport ? t(_.startCase(userExperience.selectedReport)?.replace(/ /g, '')) : null;
      }

      const reportDetail = (<ReportDetail
        title={reportTitle}
        open={userExperience.openReportDialog}
        onRequestClose={this.handleClose}
        dateRange={this.state.dateRange}
        isReportHeaderOpen={this.state.isReportHeaderOpen}
      >
        {reportToShow}
      </ReportDetail>);

      let reportsToShow = isLargeProcessor ?  UserUtil.filterLargeProcessor(reports.filteredReportTypes) :
        UserUtil.userType(user) === 'PA' && !historicHardwareMode ?
          UserUtil.filterReportsPA(reports.filteredReportTypes, UserUtil.getActiveAccount(user)?.manual_close, merchantSettings?.paymentLinksEnabled) :
          UserUtil.filterReportsMBPandHBT(reports.filteredReportTypes);

      if (reportsToShow && !UserUtil.isLoyaltyEnabled(user, merchantSettings)) {
        reportsToShow = reportsToShow.filter(report => {
          return !report.name || !report.name.includes('Loyalty');
        });
      }

      if (!UserUtil.isEPX(user) || historicHardwareMode) {
        _.remove(reportsToShow, {name: 'Disputes Report'});
      }

      if (!UserUtil.isPayanywhere(user)) {
        _.remove(reportsToShow, {name: 'Inventory Export'});
      }

      const scheduledIcon = IconUtil.getIcon('ScheduledIcon');

      const reportsRows = reports.filteredReportTypes ? reportsToShow.map((report, i) => {

        if (report.name === 'Fraud Monitoring' &&
          !merchantSettings?.merchantSettings?.tc_safe_report_enabled) return null;

        const reportScheduleInfo = scheduledReports?.find((item) => ReportType[item.report_type] === report.name);

        return (
          <div
            key={i}
            className={`reportRow reportRowData${report.name?.slice(0, 4)}`}
            onClick={isMobile ? this.toggleActiveMobileActions.bind(this, report.name) : null}
          >
            <div className='reportDetails'>
              <div className='reportName'>
                {t(_.startCase(report.name)?.replace(/ /g, ''))}
              </div>
              <div className='reportSummary'>
                {t(report.summary)}
              </div>
            </div>
            {
              reportScheduleInfo && reportScheduleInfo.is_active &&
              <div className='scheduledIndicator'>
                <div className='frequency'>{`${t('ScheduledReports.Scheduled')} ${t(reportScheduleInfo.frequency)}`}</div>
                {scheduledIcon}
              </div>
            }
            <div className='reportActions'>
              {
                isMobile ?
                  this.state.activeMobileActions === report.name &&
                  <div className='reportActionsMobileContainer'>
                    {this.getReportActions(report, reportScheduleInfo)}
                  </div>
                  :
                  this.getReportActions(report, reportScheduleInfo)
              }
            </div>
          </div>
        );
      }) : (<NoData text={t('NoReports')} />);

      const searchBar = (
        <SearchBarAccessory {...this.props} searchPlaceholder={t('SearchReports')} />
      );

      const createNewButton = <CreateButtonAccessory
        onClick={this.state.customReportEnabled ? this.handleCustomReportDialog : null}
        disabled={isCsUser || isClosedAccount || this.state.customReportEnabledForManagerReporter}
        isMobileOnly
      />

      const filterPanel = (
        <FilterPanel
          buttonText={ this.state.customReportEnabled ? 'Create Report' : null }
          buttonOnClick={ this.state.customReportEnabled ? this.handleCustomReportDialog : null }
          buttonClass={ this.state.customReportEnabled ? 'createButton' : null }
          buttonWithCreateIcon
          disabled={isCsUser || isClosedAccount || this.state.customReportEnabledForManagerReporter}
          {...this.props}
          filterData={filterData}
          selectFilterCallback={this.handleFilterSelection}
        />
      );

      const reportsList = !userExperience.openReportDialog ? (
        <div className='reportsList masterListContainer'>
          {reportsRows}
        </div>
      ) : null;

      const scheduleDialog = (
        <div>
          {
            this.state.isScheduleDialogOpen && this.state.scheduleReportName &&
            <ScheduleReportDialog
              closeAction={this.hideScheduleDialog}
              submitCallback={this.initialize}
              reportName={this.state.scheduleReportName}
              merchantEmail={merchantSettings.merchantSettings?.email_address || ''}
              {...this.props}
            />
          }
        </div>
      );

      const activityReportsClass = 'activityReports pageWrap ';

      const pageAccessories = [
        {
          name: 'createNew',
          accessory: createNewButton,
          showInMobile: true,
        },
        {
          name: 'search',
          accessory: searchBar,
          showInMobile: true,
          hide: !!userExperience?.selectedReport
        },
        {
          name: 'dateFilter',
          accessory: (
            <DateFilterAccessory
              {...this.props}
              handleSelection={this.handleDateSelection}
              defaultValue={this.state.dateRange?.text}
              showAllTime={false}
              showPreviousCustomSelection={false}
              dataName={'Reports data'}
              disableCompactVersion
            />
          ),
          showInMobile: true,
          hide: !merchantSettings.customReportStartTime
        },
        {
          name: 'allAccounts',
          accessory: (
            <ButtonAccessory
              onClick={this.toggleAllAccounts}
              endIcon={userExperience.allAccounts ?
                <CheckCircleIcon color='primary' fontSize='large'/> :
                <Cancelcon fontSize='large' />
              }
              label={t('AllAccounts')}
            />
          ),
          showInMobile: true,
          hide: !(user.data.merchantAccounts.length >= 2)
        },
      ];

      return (
        <section className={activityReportsClass}>
          <Page
            accessories={pageAccessories}
            title={t('Reports')}
            initialSubpage={t('All Reports')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
            {filterPanel}
            {reportsList}
            {reportDetail}
            {reportsInfo}
            {reportUnauthorizedErrorModal}
            {scheduleDialog}
            {noDataOnReportFetched}
            {employeesDialog}
            {customReportDialog}
              {highCountWarningModal}
          </div>
          </Page>
        </section>
      );
    }
  }
}
