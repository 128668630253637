/**
NAB Holdings, LLC (“NAB”) CONFIDENTIAL MATERIAL

Unpublished Copyright © 2000-2017 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import _ from 'lodash';
import { CustomTextField } from '../shared/TextField';
import Box from '@mui/material/Box';
import Validator from '../util/Validator';
import messages from '../../constants/messages';
import Toggle from '../shared/Toggle';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import { previewStoreLink, storeSettingsFormStyles } from '../../jss/inlineStyles';

export const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    return Validator.validateStoreSettingsForm(values);
  } else {
    return {};
  }
};

export class StoreSettingsFormComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const {onSubmit, t, isStoreEnabled, openPreview} = this.props;

    const labelColor = LabelUtil.getLabelColor();
    const visibilityIcon = IconUtils.getIcon('Visibility', labelColor);

    return(
      <form onSubmit={onSubmit} className='storeSettingsForm'>
        <Box sx={storeSettingsFormStyles}>
          <div className='toggleContainer'>
            <Field
              component={Toggle}
              customClass='storeToggle'
              label={t(messages.storeSettings.formEnableStore)}
              name='online_store_enabled'
              t={t}
            />
            <div className='previewStore' onClick={openPreview}>
              <div className='icon'>{visibilityIcon}</div>
              <Box className='text' sx={previewStoreLink}>{t(messages.storeSettings.formPreviewStore)}</Box>
            </div>
          </div>
          <hr className='appSpacer'/>
          <div className={`${!isStoreEnabled ? 'formDisabled' : ''}`}>
            <div className='urlTextFieldContainer'>
              <span className='storeDomain' data-test-id='storeDomain'>https://store.paymentshub.com/</span>
              <Field
                component={CustomTextField}
                label={t(messages.storeSettings.formStoreURL)}
                name='online_store_subdomain'
                hintText={t(messages.storeSettings.formBusinessName)}
                disabled={!isStoreEnabled}
                className='urlTextField'
                t={t}
              />
            </div>
          </div>
        </Box>
      </form>
    );
  }
}

let StoreSettingsForm = reduxForm({
  form: 'storeSettingsForm',
  validate,
  fields: ['online_store_enabled', 'online_store_subdomain'],
  enableReinitialize: true
})(StoreSettingsFormComponent);

const selector = formValueSelector('storeSettingsForm');

function mapStateToProps(state, ownProps) {
  const isStoreEnabled = selector(state, 'online_store_enabled');

  return {
    initialValues: {
      online_store_enabled: ownProps.merchantSettings?.online_store_enabled || false,
      online_store_subdomain: ownProps.merchantSettings?.online_store_subdomain || ''
    },
    isStoreEnabled
  };
}

StoreSettingsForm = connect(mapStateToProps)(StoreSettingsForm);

export default StoreSettingsForm;
