 /**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import React, {Component} from 'react';
import { connect } from 'react-redux';
import IconUtil from '../../util/IconUtil';
import {
  togglePaymentMethodsDeleteDialog,
  togglePaymentMethodsEditDialog,
  togglePaymentMethodsAddDialog
} from '../../../actions/userExperienceActions';
import MessageDialog from '../../shared/MessageDialog';
import { Dialog, DialogActions, Menu, MenuItem, Fab } from '@mui/material';
import Button from '../../shared/Button';
import EditAddPaymentMethodForm from './EditAddPaymentMethodForm';
import Spinner from '../../UpdateSpinner';
import {getFormValues, submit} from 'redux-form';
import FilterUtil from '../../util/FilterUtil';
import {
  getCustomerPaymentMethods,
  postPaymentMethodWithToken,
  deletePaymentMethodWithToken, putPaymentMethodWithToken
} from '../../../actions/customerActions';
import actionTypes from '../../../constants/actionTypes';

import PaymentMethodSummary from '../../../components/shared/enhancedInvoices/PaymentMethodSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomerPaymentHeadline from '../../shared/enhancedInvoices/CustomerPaymentHeadline';
import NoPaymentMethod from '../../icons/NoPaymentMethod';
import Modal from '../../../components/shared/Modal';
import {
  setModalVisibility,
} from '../../../actions/userExperienceActions';
import CustomerUtil from '../../util/CustomerUtil';
import planTypes from '../../../constants/planTypes';
import LabelUtil from '../../util/LabelUtil';
import {paymentPageMainTitle} from '../../../jss/invoicePaymentPageStyles';
import FormatTextUtils from '../../util/FormatTextUtil';
import {checkIsSpanish} from '../../../locales/i18n';

 export class PaymentMethodsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionsToShow: null,
      anchorEl: null,
      currentMethod: null,
      paymentMethods: []
    };

    this.showOptions = this.showOptions.bind(this);
    this.makeDefault = this.makeDefault.bind(this);
    this.handleDeleteDialogToggle = this.handleDeleteDialogToggle.bind(this);
    this.handleEditDialogToggle = this.handleEditDialogToggle.bind(this);
    this.handleAddDialogToggle = this.handleAddDialogToggle.bind(this);
    this.deleteMethod = this.deleteMethod.bind(this);
    this.handleCloseDialogs = this.handleCloseDialogs.bind(this);
    this.addEditMethod = this.addEditMethod.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.loadPaymentMethods = this.loadPaymentMethods.bind(this);
    this.resetState = this.resetState.bind(this);
    this.setPaymentMethodsState = this.setPaymentMethodsState.bind(this);
    this.closeCardErrorModal = this.closeCardErrorModal.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  openCardErrorModal (cardErrorMessage) {
    this.props.dispatch(setModalVisibility('cardError', { cardErrorMessage }));
  }

  closeCardErrorModal () {
    this.props.dispatch(setModalVisibility('hidden'));
  }

  async componentDidMount() {
    await this.loadPaymentMethods();
  }

  async loadPaymentMethods() {
    const { dispatch, token, merchantSettings } = this.props;
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const res = await dispatch(getCustomerPaymentMethods(token));
    const data = res.response;
    const paymentMethods = data?.payment_methods || [];
    const renderedPaymentMethods = FilterUtil.filterPaymentMethodsAch(paymentMethods, achEnabled);
    this.setPaymentMethodsState(renderedPaymentMethods);
  }

  showOptions(e, index) {
    this.setState({
      optionsToShow: this.state.optionsToShow === index ? null : index,
      anchorEl: e.currentTarget
    })
  }

  async makeDefault(is_default, paymentMethodId, type) {
    if (!is_default) {
      const { dispatch, token } = this.props;
      this.setState({ optionsToShow: null }, async () => {
        await dispatch(putPaymentMethodWithToken({ is_default: true, type }, paymentMethodId, token));
        await this.loadPaymentMethods();
      })
    }
  }

  handleDeleteDialogToggle(method) {
    this.resetState();
    this.setState({ currentMethod: method });
    this.props.dispatch(togglePaymentMethodsDeleteDialog(true));
  }

  handleEditDialogToggle(method) {
    this.setState({currentMethod: method});
    this.props.dispatch(togglePaymentMethodsEditDialog(true));
  }

  handleAddDialogToggle() {
    this.props.dispatch(togglePaymentMethodsAddDialog(true))
  }

  handleCloseDialogs() {
    this.props.dispatch(togglePaymentMethodsDeleteDialog(false));
    this.props.dispatch(togglePaymentMethodsEditDialog(false));
    this.props.dispatch(togglePaymentMethodsAddDialog(false));
    this.resetState();
  }

  async deleteMethod() {
    const { dispatch, token } = this.props;
    const { currentMethod } = this.state;
    const { id, type } = currentMethod;
    await dispatch(deletePaymentMethodWithToken(id, token, type));
    this.handleCloseDialogs();
    this.loadPaymentMethods();
  }

  saveClick() {
    this.props.dispatch(submit('editAddPaymentMethodForm'))
  };

  resetState() {
    this.setState({ anchorEl: null, currentMethod: null, optionsToShow: null });
  }

  setPaymentMethodsState(paymentArray) {
    const paymentMethods = paymentArray.filter((paymentMethod) => {
      if ((paymentMethod?.type === 'card' && paymentMethod?.network) || paymentMethod?.type === 'ach') {
        return paymentMethod;
      }
    });
    this.setState({paymentMethods});
  }

  handleResponse(response, customerPaymentData) {
    if (response?.type === actionTypes.paymentMethodsPutSuccess || response?.type === actionTypes.paymentMethodsPostSuccess) {
      this.handleCloseDialogs();
      this.loadPaymentMethods();
    } else {
      this.setPaymentMethodsState(customerPaymentData.payment_methods);
      this.openCardErrorModal(response.error);
    }
  }

  async addEditMethod() {
    const {allFormValues, customers, dispatch, token} = this.props;
    const {customerPaymentData} = customers;
    const {currentMethod} = this.state;
    const isEditing = currentMethod !== null;
    const paymentMethodToAdd = CustomerUtil.formatPaymentMethod(allFormValues);
    try {
      let response;
      if (isEditing) {
        const paymentMethodId = currentMethod.id;
        response = await dispatch(putPaymentMethodWithToken(paymentMethodToAdd, paymentMethodId, token));
      } else {
        response = await dispatch(postPaymentMethodWithToken(paymentMethodToAdd, token));
      }
      this.handleResponse(response, customerPaymentData);
    } catch (error) {
      this.resetState();
      this.loadPaymentMethods();
    } finally {
      this.resetState();
    }
  }

  render() {

    const {customers, userExperience, t, merchantSettings} = this.props;
    const {
      modalVisibility: {cardErrorDialogOpen, cardErrorMessage},
      deletePaymentMethodDialog,
      editPaymentMethodDialog,
      addPaymentMethodDialog
    } = userExperience;

    const isSpanish = checkIsSpanish();
    const {customerPaymentData, processingPaymentMethods} = customers;
    const merchant = merchantSettings?.merchantSettings;
    const merchantName = merchant?.business_name;
    const notificationTime = merchant?.ach_notification_revoke_days || 10;
    const notificationType = FormatTextUtils.formatKeys(FormatTextUtils.getTrueKeys(merchant?.ach_notification_revoke_type) || 'mail', isSpanish);
    const planType = merchant?.plan_type;
    const noPaymentFunctionality = planType === planTypes.free || planType === planTypes.premium;
    const {paymentMethods, anchorEl} = this.state;
    const paymentMethodsLength = paymentMethods?.length;

    const colorRender = merchant?.brand_color || LabelUtil.getLabelColor();

    const addButton = (
      <Fab
        id='addPayment'
        color='primary'
        onClick={this.handleAddDialogToggle}
        disabled={noPaymentFunctionality}
      >{IconUtil.getIcon('AddIconPaymentMethod', '#FFF')}</Fab>
    );

    const contentTitle = (
      <div className='paymentMethodsContentTitle'>
        <div className='sectionTitle'>
          <Typography
            component='h1'
            sx={paymentPageMainTitle}
          >
            {t('PaymentPortalPreview.PaymentMethods')}
          </Typography>
        </div>
        {this?.state?.paymentMethods?.length > 1 && addButton}
      </div>
    );

    const paymentMethodsHeader = (
      <CustomerPaymentHeadline customerInfo={customerPaymentData}/>
    );

    const methodOptions = (method, index) => (
      <Menu
        anchorEl={anchorEl}
        aria-labelledby={`methodOptionMenu${index}`}
        className='MethodOption'
        id={`methodOptionMenu${index}`}
        onClose={this.resetState}
        open={this.state.optionsToShow === index}>
        <MenuItem className='MethodOptionItem'
                  onClick={() => this.handleEditDialogToggle(method)}>
          {t('Edit')}
        </MenuItem>
        <MenuItem className={`MethodOptionItem ${method.is_default ? 'disabled' : ''}`}
                  onClick={() => this.makeDefault(method.is_default, method.id, method.type)}>
          {t('MakeDefault')}
        </MenuItem>
        <MenuItem
          className={`MethodOptionItem ${(method.is_default && paymentMethodsLength > 1) ? 'disabled' : ''}`}
          onClick={() => this.handleDeleteDialogToggle(method)}>
          {t('Delete')}
        </MenuItem>
      </Menu>
    );

    const defaultIcon = (
      <div className='MethodDefault'>
        {IconUtil.getIcon('CheckPaymentMethod', undefined, '20px')} {'Default'}
      </div>
    );

    const paymentMethod = (paymentMethodsLength && paymentMethods.map((method, index) => (
      <div className='paymentMethodsContentMethod' key={index}>
        <PaymentMethodSummary
          paymentMethod={method}
          backgroundColor='#FFF'
          showEditButton={false}
          showDefault={method.is_default}
          showIcon={true}
          customDropDownIcon='ArrowDropdownToggle'
          dropDownOnClick={(e) => this.showOptions(e, index)}
          t={t}
        />
        {methodOptions(method, index)}
      </div>
    )));

    const addSecondPaymentMethod = (
      <Box className='paymentMethodsContentMethodAdd' onClick={this.handleAddDialogToggle} sx={{color: 'labelColor'}}>
        {IconUtil.getIcon('AddIconPaymentMethod')}{t('AddSecondPaymentMethod.Button')}
      </Box>
    );

    const deleteMethodDialog = (
      <MessageDialog
        bodyText={t('DeleteMethodDialog.Text')}
        cancelText={t('Cancel')}
        confirmText={t('Delete')}
        isChoiceRequired
        onConfirm={this.deleteMethod}
        onRequestClose={this.handleCloseDialogs}
        open={!!deletePaymentMethodDialog}
        scrollable={false}
        titleText={t('DeleteMethodDialog.Header')}
      />
    );

    const submitButton = (show) => {
      return (
        <DialogActions className='editAddPaymentDialogActions'>
          <Button variant='text' label={t('Cancel')} onClick={this.handleCloseDialogs}/>
          <Button
            label={t('Save')}
            onClick={this.saveClick}
            disabled={!show}
          />
        </DialogActions>
      )
    };

    const editAddMethodDialog = (
      <Dialog
        open={!!editPaymentMethodDialog || !!addPaymentMethodDialog}
        onClose={this.handleCloseDialogs}
        id='EditAddPaymentMethodDialog'
        className='editAddPaymentDialog'
        scroll={'paper'}>
        <div style={{padding: 20}}>
          <EditAddPaymentMethodForm
            methodToEdit={this.state.currentMethod}
            onSubmit={this.addEditMethod}
            submitButton={submitButton}
            achEnabled={merchant?.ach_enabled}
            merchant={merchantName}
            notificationType={notificationType}
            notificationTime={notificationTime}
            isClp
            isManagingPaymentMethods
            {...this.props}
          />
        </div>
      </Dialog>
    );

    const errorModal = (
      <Modal
        hideCancelButton
        confirmText={t('Okay')}
        maxWidth='md'
        onConfirm={this.closeCardErrorModal}
        open={cardErrorDialogOpen}
        title={t('CustomerDetailForm.AddPaymentMethodErrorTitle')}
        subtitle={`(${cardErrorMessage}) ${t('CustomerDetailForm.AddPaymentMethodError')}`}
      />
    );

    const paymentMethodsRender = (
      <div className='paymentMethods'>
        {paymentMethodsHeader}
        <div className='paymentMethodsContent'>
          {contentTitle}
          {paymentMethod}
          {paymentMethodsLength < 2 && addSecondPaymentMethod}
          {deleteMethodDialog}
          {editAddMethodDialog}
          {errorModal}
        </div>
      </div>
    );

    const noPaymentMethod = (
      <>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          height={{xs: '85%', sm: '100%'}}
          pt={{xs: 1, sm: 0}}
        >
          <NoPaymentMethod
            style={{
              height: '334px',
              width: '324px',
              marginBottom: '50px'
            }}
            color={colorRender}
          />
          <Typography
            variant='h1'
            sx={{
              fontFamily: 'inter',
              fontSize: '30px',
              marginBottom: '15px',
              textAlign: 'center',
              fontWeight: 'bold',
              padding: '0 10px'
            }}
          >
            {t('NoPaymentMethod.Header')}
          </Typography>
          <Typography
            variant='p'
            sx={{
              fontFamily: 'inter',
              fontWeight: '500',
              fontSize: '17px',
              textAlign: 'center',
              maxWidth: '535px',
              marginBottom: {xs: 2, sm: '50px'},
              padding: '0 10px'
            }}
          >
            {t('NoPaymentMethod.Text')}
          </Typography>
          <Button
            disabled={noPaymentFunctionality}
            icon={IconUtil.getIcon('AddIconPaymentMethod', '#FFF')}
            label={t('NoPaymentMethod.Button')}
            onClick={this.handleAddDialogToggle}
          />
        </Box>
        {editAddMethodDialog}
        {errorModal}
      </>
    );

    return (
      processingPaymentMethods ? <Spinner/> :
        paymentMethodsLength ? paymentMethodsRender : noPaymentMethod
    );
  }

}

function mapStateToProps(state, ownProps) {
  const currentFormValues = getFormValues('editAddPaymentMethodForm')(state) || {};
  const cardOnFileFormValues = getFormValues('cardOnFileForm')(state) || {};
  const allFormValues = {...currentFormValues, ...cardOnFileFormValues};
  return {allFormValues};
}

export default connect(mapStateToProps)(PaymentMethodsComponent);
