/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import io from 'socket.io-client';
import _ from 'lodash';
import { uuidUtil } from './CommonUtil';
import messages from '../../constants/messages';
import {
  resetCardPresentState,
  updateCardPresentState
} from '../../actions/cardPresentActions';
import {
  requestDesktopToken,
  requestPayNowCPToken
} from '../../actions/authActions';
import BrowserUtil from './BrowserUtil';
import { Field } from 'redux-form';
import TextField from '../shared/TextField';
import Select from '../shared/Select';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import numeral from 'numeral';

const platform = require('platform');

const DriverState = {
  // no action has been taken
  unknown: 0,
  // the application started up properly
  started: 1,
  // the application is started but it isn't scanning or connected
  idle: 2,

  // device scanning
  deviceScanningAndSocketNotConnected: 3,
  deviceScanningAndSocketConnectedAndNotAuthenticated: 4,
  deviceScanningAndSocketConnectedAndAuthenticated: 5,

  // device not scanning (shouldn't happen)
  deviceNotScanningAndSocketConnectedAndNotAuthenticated: 6,
  deviceNotScanningAndSocketConnectedAndAuthenticated: 7,

  // device connected
  deviceConnectedAndSocketNotConnected: 8,
  deviceConnectedAndSocketConnectedAndNotAuthenticated: 9,
  deviceConnectedAndSocketConnectedAndAuthenticated: 10,

  // card reading
  deviceConnectedAndReadingAndSocketConnectedAndAuthenticated: 11,
  deviceConnectedAndReadingAndNeedsInputAndSocketConnectedAndAuthenticated: 12,
  deviceConnectedAndReadingAndHasMessageAndSocketConnectedAndAuthenticated: 13,
  deviceConnectedAndReadingAndHasErrorAndSocketConnectedAndAuthenticated: 14,

  // card processing
  deviceConnectedAndProcessingAndSocketConnectedAndAuthenticated: 15,
  deviceConnectedAndProcessingAndNeedsInputAndSocketConnectedAndAuthenticated: 16,
  deviceConnectedAndProcessingAndHasMessageAndSocketConnectedAndAuthenticated: 17,
  deviceConnectedAndProcessingAndHasErrorAndSocketConnectedAndAuthenticated: 18,

  // card processed
  deviceConnectedAndProcessedAndSocketConnectedAndAuthenticated: 19,

  // local action taken, and we're waiting on a response
  localActionTaken: 20
};

const ConnectionState = {
  disconnected: 0,
  connecting: 1,
  connectedNotAuthenticated: 2,
  connectedAndAuthenticated: 3
};

const RequestSource = {
  local: 0, // represents the driver
  remote: 1 // represent the portal
};

// the socket object to pass
const SocketRequest = {
  room_id: null,
  hash: null,
  requestId: null,
  data: null,
  token: null,
  isComplete: false,
  direction: RequestSource.remote,
  source: RequestSource.remote
};

const InputType = {
  string: 0,
  number: 1,
  choice: 2
};

export default class CardPresentUtil {

  constructor(dispatch, type) {
    this.dispatcher = dispatch;

    // static variables
    this.socketUrl = serverBaseUrl.split('/').slice(0, 3).join('/');
    this.oldVersion = {
      windows: '1.2.1',
      mac: '1.2.1'
    };
    this.currentVersion = '0.0.0';

    // passable variables
    this.salt = uuidUtil.uuid();

    // stateful objects
    this.appToken = null;
    this.type = type;
    this.socket = null;
    this.connectionState = ConnectionState.disconnected;
    this.reconnectTimeoutInterval = 2000;
    this.reconnectTimeout = null;
    this.connectionAttempts = 0;

    // health check objects
    this.isHealthy = false;

    // response information
    this.responseId = null;
  }

  get status() {

    // no socket available
    if (!this.socket) {
      this.connect(); // go ahead and attempt to connect
      return ConnectionState.disconnected;
    }

    // socket doesn't agree with our current state
    if ((this.connectionState > ConnectionState.connecting && !this.socket.connected) ||
      (this.connectionState == ConnectionState.connecting && !this.socket.connecting && !this.socket.connected)) {
      return ConnectionState.disconnected;
    }

    // all is well
    if (this.connectionState == ConnectionState.connectedAndAuthenticated && this.socket.connected && this.isHealthy) {
      return ConnectionState.connectedAndAuthenticated;
    }
    // can't communicate with device driver
    else if (this.connectionState == ConnectionState.connectedAndAuthenticated && !this.isHealthy) {
      return ConnectionState.connectedNotAuthenticated;
    }
    // hasn't joined the room yet
    else if (this.connectionState == ConnectionState.connectedNotAuthenticated) {
      return ConnectionState.connectedNotAuthenticated;
    }
    // hasn't connected yet but is attempting to
    else if (this.connectionState == ConnectionState.connecting) {
      return ConnectionState.connecting;
    }

    // isn't connected at all
    return ConnectionState.disconnected;
  }

  // socket management
  connect() {

    // this means we're already connected, we should just join the room
    if (this.socket && this.status == ConnectionState.connectedNotAuthenticated) {
      this.joinRoom();
      return; // already connected
    }

    // we'll need to force destroy the current socket
    if (this.socket && !this.socket.disconnected) {
      this.disconnect();
    }

    // create the socket and connect
    this.socket = io(this.socketUrl, {
      reconnectionAttempts: 3
    });
    this.stateListeners();
    this.roomListeners();

    // go ahead and dispatch the current state
    this.dispatch({});
  }

  disconnect(close) {
    // also reset the app start
    this.resetReading();

    if (this.socket) {
      this.socket.removeAllListeners();
      this.isHealthy = false;

      // disconnect
      this.socket.disconnect();
      if (close) {
        this.socket.close();
        this.socket = null;
      }
    }
  }

  close() {
    this.disconnect(true);
  }

  authenticate(token) {

    this.appToken = token;
    if (this.status == ConnectionState.connectedNotAuthenticated) {
      this.joinRoom();
    }
    this.dispatch({});
  }

  joinRoom(joinRoomCallback) {

    this.socket.emit('join', {
      token: this.token(),
      room_id: this.roomId(),
      data: {}
    }, (response) => {
      const success = !!(response);
      if (joinRoomCallback || null) {
        joinRoomCallback(success);
      }

      if (success) {
        this.connectionAttempts = 0;
        this.connectionState = ConnectionState.connectedAndAuthenticated;
        if (!this.isHealthy) {
          // make device connection
          this.connectToDriver(null);
        }
      }
    });
  }

  // attach to socket.io listeners
  stateListeners() {

    if (!this.socket) {
      return; // we can't do anything here
    }

    this.socket.on('connect', () => {
      // set the state
      this.connectionState = ConnectionState.connectedNotAuthenticated;
      // go ahead and join the room
      this.joinRoom();
    });

    this.socket.on('connecting', () => {
      this.connectionState = ConnectionState.connecting;
    });

    this.socket.on('connect_error', () => {
      this.connectionAttempts++
      if (this.connectionAttempts > 2) {
        this.disconnect();
      } else {
        if (this.reconnectTimeout) {
          clearTimeout(this.reconnectTimeout);
        }
        this.reconnectTimeout = setTimeout(() => {
          this.connectionState = ConnectionState.connecting;
          this.connect();
        }, this.reconnectTimeoutInterval);
      }
    });

    this.socket.on('connect_timeout', () => {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
      }
      this.reconnectTimeout = setTimeout(() => {
        this.connectionState = ConnectionState.connecting;
        this.connect();
      }, this.reconnectTimeoutInterval);
    });

    this.socket.on('disconnect', () => {
      this.connectionState = ConnectionState.disconnected;
    });

    this.socket.on('reconnecting', () => {
      this.connectionState = ConnectionState.connecting;
    });
  }

  // attach to payanywhere listeners
  roomListeners() {

    if (!this.socket) {
      return; // we can't do anything here
    }

    // request
    this.listen('request', (request, ack) => {
      this.isHealthy = true; // we're getting a request, this means we're healthy
      ack(true);
      this.dispatch(request.data, 'request');
    });

    // salt
    this.listen('salt', (request, ack) => {
      ack(this.salt);
    });
  }

  // a wrapper to sanitize incoming data
  listen(to, cb) {
    this.socket.on(to, (data, ack) => {
      if (Array.isArray(data) && data.length > 0) {
        data = data.pop();
      }
      cb(Object.assign({}, SocketRequest, data), ack);
    });
  }

  // abstract getters
  roomId() {
    let userData;

    try {
      userData = JSON.parse(localStorage.getItem('pa-u'));
    } catch {
      userData = null;
    }

    if (userData && userData.user_id) {
      return userData.user_id.toString();
    }

    const userId = localStorage.getItem('pa-cp');
    return userId ? userId : null;

  }

  token() {
    const paToken = localStorage.getItem('pa-token');
    return paToken ? paToken : localStorage.getItem('pa-cp-t');
  }

  // dispatch helpers
  dispatch(state, from) {
    if (!this.dispatcher) {
      return;
    }

    this.dispatcher(updateCardPresentState({
      state: state,
      activityTitle: this.activityTitle(state),
      activityDetails: this.activityDetails(state),
      activityActions: this.activityActions(state)
    }));
  }

  reset() {
    if (!this.dispatcher) {
      return;
    }

    this.dispatcher(resetCardPresentState(true));
  }

  // context state helpers
  deviceState(state) {
    return state && state.state || null;
  }

  // helper for the current operating system
  get operatingSystem() {
    return platform.os.family.toLowerCase().indexOf('window') >= 0 ? 'windows' : 'mac';
  }

  /**
   * version comparison
   * @return <-1|0|1>
   * @note:
   *    -1 for if versionA is smaller than versionB
   *    0 for if versionA equals versionB or if they can't be compared
   *    1 for if versionA is larger than versionB
   */
  compareVersions(versionA, versionB) {
    if (versionA === versionB)
      return 0;

    let versionAChunks = versionA.split('.'),
        versionBChunks = versionB.split('.');

    for (let i in versionAChunks) {
      try {
        let iVersionAChunk = parseInt(versionAChunks[i]),
          iVersionBChunk = parseInt(versionBChunks[i]);
        // nothing to do here because this level is the same
        if (iVersionAChunk === iVersionBChunk)
          continue;
        return iVersionAChunk > iVersionBChunk ? 1 : -1;
      } catch (e) {
        return 0; // one of these is not a number and can't be parsed
      }
    }
  }

  setVersion(state) {
    return this.currentVersion = state.driver && state.driver.version > this.currentVersion ? state.driver.version : this.currentVersion;
  }

  activityTitle(state) {
    // we'll first need to check the driver state
    if (!this.isHealthy) {
      if (!this.appToken) {
        return messages.cardPresent.activity.connectionConnecting;
      }
    }

    const deviceState = this.deviceState(state);
    // version is too old
    const oldVersion = this.oldVersion[this.operatingSystem];
    this.setVersion(state);

    if (this.isHealthy && this.compareVersions(this.currentVersion, oldVersion) !== 1) {
      return messages.cardPresent.activity.needsUpgrade;
    } else if (this.isHealthy && deviceState && deviceState > DriverState.idle) {
      // device is scanning
      if (deviceState >= DriverState.deviceScanningAndSocketNotConnected &&
        deviceState <= DriverState.deviceScanningAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activity.connecting
      }
      // device is not scanning or some authentication issue
      else if (deviceState >= DriverState.deviceNotScanningAndSocketConnectedAndNotAuthenticated &&
        deviceState <= DriverState.deviceNotScanningAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activity.unknown;
      }
      // device is ready to use
      else if (deviceState == DriverState.deviceConnectedAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activity.readyToUse;
      }
      // needs input
      else if ((deviceState == DriverState.deviceConnectedAndReadingAndNeedsInputAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndProcessingAndNeedsInputAndSocketConnectedAndAuthenticated) &&
        state.cardInputRequest) {
        return messages.cardPresent.activity.needsInput;
      }
      // device transaction started
      else if (deviceState >= DriverState.deviceConnectedAndReadingAndSocketConnectedAndAuthenticated &&
        deviceState <= DriverState.deviceConnectedAndReadingAndHasErrorAndSocketConnectedAndAuthenticated) {
        return (state && ((state.message && state.message.message) || (state.error && state.error.error))) || messages.cardPresent.activity.useCard;
      }
      // card data processing
      else if (deviceState >= DriverState.deviceConnectedAndProcessingAndSocketConnectedAndAuthenticated &&
        deviceState <= DriverState.deviceConnectedAndProcessingAndHasErrorAndSocketConnectedAndAuthenticated) {
        return (state && ((state.message && state.message.message) || (state.error && state.error.error))) || messages.cardPresent.activity.isProcessing;
      }
      // card data processed
      else if (deviceState == DriverState.deviceConnectedAndProcessedAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activity.processed;
      }
      // local action taken
      else if (deviceState == DriverState.localActionTaken) {
        return messages.cardPresent.activity.localAction;
      }
    }

    // we don't have any information on the device state
    return messages.cardPresent.activity.missingDriver;
  }

  activityDetails(state) {

    // we'll first need to check the driver state
    if (!this.isHealthy) {
      if (!this.appToken) {
        return messages.cardPresent.activityDetails.connectionConnecting;
      }
    }

    const deviceState = this.deviceState(state);
    // version is too old
    const oldVersion = this.oldVersion[this.operatingSystem];
    this.setVersion(state);

    if (this.isHealthy && this.compareVersions(this.currentVersion, oldVersion) !== 1) {
      return messages.cardPresent.activityDetails.needsUpgrade;
    } else if (this.isHealthy && deviceState && deviceState > DriverState.idle) {
      // device is scanning
      if (deviceState >= DriverState.deviceScanningAndSocketNotConnected &&
        deviceState <= DriverState.deviceScanningAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activityDetails.connecting
      }
      // device is not scanning or some authentication issue
      else if (deviceState >= DriverState.deviceNotScanningAndSocketConnectedAndNotAuthenticated &&
        deviceState <= DriverState.deviceNotScanningAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activityDetails.unknown;
      }
      // device is ready to use
      else if (deviceState == DriverState.deviceConnectedAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activityDetails.readyToUse;
      }
      // needs input
      else if ((deviceState == DriverState.deviceConnectedAndReadingAndNeedsInputAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndProcessingAndNeedsInputAndSocketConnectedAndAuthenticated) &&
        state.cardInputRequest) {
        let inputTypes = _.invert(InputType);
        return {
          ...state.cardInputRequest,
          inputType: inputTypes[state.cardInputRequest.inputType]
        }; // send back the object to be parsed into HTML
      }
      // device transaction started
      else if (deviceState >= DriverState.deviceConnectedAndReadingAndSocketConnectedAndAuthenticated &&
               deviceState <= DriverState.deviceConnectedAndReadingAndHasErrorAndSocketConnectedAndAuthenticated) {
        return (state && ((state.message && state.message.submessage) || (state.error && state.error.submessage))) || '';
      }
      // card data processing
      else if (deviceState >= DriverState.deviceConnectedAndProcessingAndSocketConnectedAndAuthenticated &&
        deviceState <= DriverState.deviceConnectedAndProcessingAndHasErrorAndSocketConnectedAndAuthenticated) {
        return (state && ((state.message && state.message.submessage) || (state.error && state.error.submessage))) || '';
      }
      // card data processed
      else if (deviceState == DriverState.deviceConnectedAndProcessedAndSocketConnectedAndAuthenticated) {
        return messages.cardPresent.activityDetails.processed;
      }
      // local action taken
      else if (deviceState == DriverState.localActionTaken) {
        return messages.cardPresent.activityDetails.localAction;
      }
    }

    // we don't have any information on the device state
    return messages.cardPresent.activityDetails.missingDriver;
  }

  activityActions(state) {

    let results = {};
    if (!this.appToken) {
      return results;
    }

    const deviceState = this.deviceState(state);
    // version is too old
    const oldVersion = this.oldVersion[this.operatingSystem];
    this.setVersion(state);

    if (this.isHealthy && this.compareVersions(this.currentVersion, oldVersion) !== 1) {
      results[messages.cardPresent.activityActions.download] = this.download.bind(this);
      results[messages.cardPresent.activityActions.upgradedConnect] = this.connectToDriver.bind(this);
      // we know we at least have a device state
    } else if (this.isHealthy && deviceState && deviceState > DriverState.idle) {
      if (deviceState == DriverState.deviceConnectedAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndReadingAndHasMessageAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndReadingAndHasErrorAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndProcessingAndHasMessageAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndProcessingAndHasErrorAndSocketConnectedAndAuthenticated) {
        // we can start the reader from here
        results[messages.cardPresent.activityActions.startReader] = this.process.bind(this);
      } else if (deviceState == DriverState.deviceConnectedAndReadingAndNeedsInputAndSocketConnectedAndAuthenticated ||
        deviceState == DriverState.deviceConnectedAndProcessingAndNeedsInputAndSocketConnectedAndAuthenticated) {
        // show the submit button
        results[messages.cardPresent.activityActions.submitResponse] = this.submitResponse.bind(this);
      } else if (deviceState > DriverState.deviceConnectedAndSocketNotConnected) {
        // show the cancel button
        results[messages.cardPresent.activityActions.cancelReader] = this.resetReading.bind(this);
      }
    } else {
      // we don't have any information on the device state
      results[messages.cardPresent.activityActions.download] = this.download.bind(this);
      results[messages.cardPresent.activityActions.connect] = this.connectToDriver.bind(this);
    }
    return results;
  }

  // app requests
  request(params, needsToken) {

    if ((window || null) && (!needsToken || this.appToken)) {
      let serialize = function (obj) {
        let str = [];
        for (let p in obj)
          /* istanbul ignore else */
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + (typeof (obj[p]) == 'object' ? JSON.stringify(obj[p]) : obj[p]));
          }
        return str.join('&');
      };

      BrowserUtil.isSafari() ? window.open(`payanywhere://?${serialize(params)}`, '_blank') : window.location = `payanywhere://?${serialize(params)}`;
    }
  }

  // action callbacks
  download(downloadEvent) {

    if (window || null) {
      if (downloadEvent || null) downloadEvent.preventDefault();

      const operatingSystem = platform.os.family.toLowerCase().indexOf('window') >= 0 ? 'windows' : 'mac';
      const endpoint = '/vt/download/' + operatingSystem + '/';
      const openWindow = window.open(endpoint, '_blank');
      openWindow.focus();
    }
  }

  connectToDriver(driverConnectionEvent) {

    if (driverConnectionEvent || null) driverConnectionEvent.preventDefault();

    if (this.appToken) {
      this.request({token: this.appToken, salt: this.salt});
    } else if (this.dispatcher) {
      if (this.type === 'payNow') {
        this.dispatcher(requestPayNowCPToken());
      } else {
        this.dispatcher(requestDesktopToken());
      }

    }
  }

  process(processEvent, values, validate, handleSubmit) {
    if (processEvent || null) processEvent.preventDefault();
    const validation = validate(values);

    if (this.type !== 'payNow' && !_.isEmpty(validation)) {
      // Handles Validation Submission (not needed for CP on Pay Now - since no client input)
      handleSubmit();
    } else {
      this.dispatch({state: DriverState.localActionTaken});
      let amount = numeral(values['amount']).value() || numeral(values['total_amt']).value();
      amount = parseFloat(Math.round((amount) * 100) / 100).toFixed(2);
      this.request({action: 'process', meta: {amount: amount}});
    }
  }

  submitResponse(submitEvent, values, validate, handleSubmit, state) {
    if ('response' in values) {
      this.request({
        action: 'respondingTo',
        meta: {value: values.response, type: state.cardInputRequest.requestType}
      });
    }

  }

  resetReading() {
    this.dispatch({state: DriverState.localActionTaken});
    this.request({action: 'resetReading', meta: null});
  }

  cardPresentInputRequest(cardPresentState, submitting) {

    if (cardPresentState.activityDetails instanceof String || typeof cardPresentState.activityDetails === 'string') {
      return cardPresentState.activityDetails;
    } else if (cardPresentState.activityDetails) {
      if (cardPresentState.activityDetails.inputType === 'string') {
        return <Field
          label={cardPresentState.activityDetails.title}
          component={TextField}
          name='response'
          hintText={(cardPresentState.activityDetails.meta || {}).helpText || ''}
          maxLength={(cardPresentState.activityDetails.meta || {}).maxLength || 256}
          disabled={submitting}
          className='alignBottom textField'
        />;
      } else if (cardPresentState.activityDetails.inputType === 'number') {
        return <Field
          label={cardPresentState.activityDetails.title}
          component={TextField}
          name='response'
          type='number'
          hintText={(cardPresentState.activityDetails.meta || {}).helpText || ''}
          min={(cardPresentState.activityDetails.meta || {}).min || ''}
          max={(cardPresentState.activityDetails.meta || {}).max || ''}
          disabled={submitting}
          className='alignBottom textField'
        />;
      } else if (cardPresentState.activityDetails.inputType === 'choice' && (cardPresentState.activityDetails.meta || {}).choices) {
        // the choices may be a dictionary instead of an array
        let choices = (cardPresentState.activityDetails.meta).choices;

        if (!Array.isArray(choices) && typeof choices === 'object') {
          choices = Object.keys(choices).map((key) => {
            return [key, choices[key]];
          });
        }

        let menuItems = [];

        choices.forEach((choice, indx) => {

          if (choice instanceof String || typeof choice === 'string') {
            menuItems.push(<MenuItem value={choice} key={indx}>
              {choice}</MenuItem>);
          } else if (Array.isArray(choice) && choice.length > 0) {
            menuItems.push(<MenuItem value={choice[0]} key={indx}>
              {choice.length > 1 ? choice[1] : choice[0]}</MenuItem>);
          } else if (choice && ('value' in choice && 'display' in choice)) {
            menuItems.push(<MenuItem value={choice.value} key={indx}>
              {choice.display}</MenuItem>);
          }
        });

        return <Field
          label={cardPresentState.activityDetails.title}
          component={Select}
          name='response'
          disabled={submitting}
          className='alignBottom'>
          {menuItems}
        </Field>;
      }
    }

    return '';
  }
}
