/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import Badge from '@mui/material/Badge';
import {Dialog, Divider, MenuItem} from '@mui/material';
import Button from './Button';
import IconUtils from '../util/IconUtil';
import Select from './Select';
import withLayoutBreakPoints from '../util/WithLayoutBreakPoints';
import {
  filterPanelMobileMenuItem,
  filterPanelMobileMenuItemSelected,
  mainMenuHeaderStyles,
  filterPanelAdditionalComponentStyles
} from '../../jss/inlineStyles';
import BetaDisclaimer from '../BetaDisclaimer';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import SettingsUtil from '../util/SettingsUtil';
import LabelUtil from '../util/LabelUtil';

export class FilterPanel extends Component {

  constructor(props) {
    super(props);

    this.closeFiltersDialog = this.closeFiltersDialog.bind(this);
    this.closeSearchBar = this.closeSearchBar.bind(this);
    this.findSelectedFilter = this.findSelectedFilter.bind(this);
    this.listItems = this.listItems.bind(this);
    this.openFiltersDialog = this.openFiltersDialog.bind(this);
    this.openSearchBar = this.openSearchBar.bind(this);
    this.updateSelectedFilter = this.updateSelectedFilter.bind(this);
    this.analyticsEventPush = this.analyticsEventPush.bind(this);

    this.state = {
      selectedFilter: this.findSelectedFilter(),
      isSearchBarOpen: false,
      isFiltersDialogOpen: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateSelectedFilter(nextProps);
  }

  findSelectedFilter(optionalNextProps) {

    const {filterData, visibilityFilter} = optionalNextProps || this.props;
    let result = '';

    if (filterData && filterData.length) {

      const selectedReactRouterLink = filterData.filter(item => !_.isEmpty(item) && typeof (item?.name) !== 'string');

      let updatedVisibilityFilterValue = {};
      if (visibilityFilter.filter && visibilityFilter.filter.value && selectedReactRouterLink.length === 0) {
        //If the selected filter contains a quantity of items in parenthesis, removes this quantity so we can find the selected filter again after this quantity changes
        const filterRoot = visibilityFilter.filter.value.split('(')[0];
        updatedVisibilityFilterValue = filterData.filter(item => item?.name && item.name.includes(filterRoot))[0];
      }

      const filterName = typeof filterData[0]?.name === 'string' ? filterData[0]?.name : filterData[0]?.name?.props?.children;

      const selectedReactRouterLinkName = selectedReactRouterLink.length ? selectedReactRouterLink[0]?.name?.props?.children : null;

      result = selectedReactRouterLink.length > 0
        ? selectedReactRouterLinkName       //Select React Router link
        : (visibilityFilter && visibilityFilter.filter && visibilityFilter.filter.value && !_.isEmpty(updatedVisibilityFilterValue))
          ? updatedVisibilityFilterValue?.key || updatedVisibilityFilterValue?.name                  //Select current filter's value
          : filterData[0] && filterName;                //Select the first item as default

    }

    return result;
  }

  onFilterSelected(filter, row) {
    if (!this.props.blockSelectFilterHighlight) {
      const filterName = typeof filter.name === 'string' ? filter.name : filter.name.props.children;
      this.setState({selectedFilter: filterName});
    }

    if (typeof this.props.selectFilterCallback === 'function') {
      this.props.selectFilterCallback(filter, row);
    }
  }

  updateSelectedFilter(nextProps) {
    const {
      visibilityFilter: nextVisibilityFilter,
      i18n: nextLanguage,
    } = nextProps;

    const {
      visibilityFilter: currentVisibilityFilter,
      i18n: currentLanguage,
    } = this.props;

    if ((
      nextVisibilityFilter && nextVisibilityFilter.filter &&
      currentVisibilityFilter && currentVisibilityFilter.filter &&
      (nextVisibilityFilter.filter.value !== currentVisibilityFilter.filter.value)
    ) || (nextLanguage?.language !== currentLanguage?.language)) {
      const newFilter = this.findSelectedFilter(nextProps);
      newFilter && this.setState({
        selectedFilter: newFilter
      });
    }
  }

  analyticsEventPush(pageTitle = '') {

    const {location} = this.props;

    const baseUrl = serverBaseUrl?.replace('/api/', '') || '';
    const pathname = location?.pathname || '';
    const pageUrl = baseUrl + pathname;

    const dataLayerEvent = {
      'event': 'virtualPageview',
      'pageUrl': pageUrl,
      'pageTitle': pageTitle
    };
    window?.dataLayer?.push(dataLayerEvent);

  }

  listItems() {
    const {t, filterData, visibilityFilter, merchantSettings, clp, invoices} = this.props;
    const fetchingData = invoices?.isFetching;
    const merchantColor = merchantSettings?.merchantSettings?.brand_color
    const labelColor = LabelUtil.getLabelColor();
    const selectedColor = clp && merchantColor ? merchantColor : labelColor;
    /* istanbul ignore else */
    if (filterData) {
      return (
        filterData.map((item, i) => {
          if (_.isEmpty(item) || item?.name === 'divider') {
            return (
              <li
                key={i}
                className='divider'
              >
                &nbsp;
              </li>
            )
          } else {
            let itemName = typeof item?.name === 'string' ? item?.name : item?.name.props.children;

            const filterName = visibilityFilter.filter && visibilityFilter.filter.value;

            const isFilterNameEqualToItemName = itemName === filterName;
            const isDynamicFilterName = isFilterNameEqualToItemName ? filterName?.includes('(') || this.state.selectedFilter?.includes('(') : itemName?.includes('(');
            const selectedItem =
              isDynamicFilterName ?
                (filterName?.includes(itemName?.split(' ')[0]) || this.state.selectedFilter?.includes(itemName?.split(' ')[0]))
                :
                (filterName === itemName || this.state.selectedFilter === itemName);

            let itemClassName = selectedItem ? 'filterItem filterItemSelected' : 'filterItem';
            itemClassName = item?.openModal ? 'filterItem' : itemClassName;
            itemClassName = item?.border ? `${itemClassName} borderTop` : itemClassName;
            itemClassName = item?.disabled ? `${itemClassName} filterItemDisabled` : itemClassName;

            itemName = t(itemName);
            const translatedNameWithoutCount = itemName;

            const clickAction = (filter, row) => {
              this.onFilterSelected(item, i);
              const scrollContainer = document.querySelector('.masterListContainer');
              if (scrollContainer && typeof scrollContainer?.scrollTo === 'function') {
                scrollContainer.scrollTo({top: 0, behavior: 'smooth'});
              }
              this.analyticsEventPush(translatedNameWithoutCount);
            };

            const showCountLabel = (typeof item?.name === 'string' && item?.count > -1) && (
              !item.showCountOnSelection || (item.showCountOnSelection && selectedItem)
            );

            if (showCountLabel && !fetchingData) {
              itemName = `${itemName} (${item.count})`;
            }

            return (
              <li
                key={i}
                className={itemClassName}
                onClick={!item?.disabled ? clickAction : () => {
                }}
                style={{color: selectedItem ? selectedColor : ''}}
              >
                {itemName}
                {item.badge && item.count ?
                  <Badge badgeContent={item.count} color='error'/> : null}
              </li>
            );
          }
        })
      )
    }

  }

  openSearchBar() {
    this.setState({isSearchBarOpen: true});
  }

  closeSearchBar() {
    this.setState({isSearchBarOpen: false});
  }

  openFiltersDialog() {
    this.setState({isFiltersDialogOpen: true});
  }

  closeFiltersDialog() {
    this.setState({isFiltersDialogOpen: false});
  }

  render() {
    const appRoutePrefix = globalApplicationLabel.path;

    const {
      mobileSearchBar,
      mobileSearchAccessory,
      LayoutBreakPoints,
      overrideAccessoryAction,
      mobileOnly,
      className,
      hideFilterLogo,
      optionalFilterFooter,
      betaDisclaimer,
      buttonBar,
      t,
      merchantSettings,
      clp,
      buttonWithCreateIcon,
      buttonText,
      buttonClass,
      buttonOnClick,
      buttonIcon,
      disabled,
      filterData,
      invoices,
      additionalFilterDataComponent
    } = this.props;

    const isMobileOrTabletLayout = LayoutBreakPoints && LayoutBreakPoints.isMaxTablet;
    const closeIcon = IconUtils.getIcon('Close', '#3E3935');
    const plusIcon = IconUtils.getIcon('PlusIcon');
    const selectedButtonIcon = buttonWithCreateIcon ? plusIcon : buttonIcon;

    const logo = merchantSettings?.merchantSettings?.logo;
    const logoRender = clp && !hideFilterLogo && logo && (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Avatar
          data-test-id='merchant-logo'
          src={`data:image/${SettingsUtil.getImgExt(logo)};base64,${logo}`}
          variant='square'
          alt='Merchant Logo'
          sx={{
            width: '200px',
            height: '100px',
            margin: 0,
            '& img': {
              objectFit: 'contain'
            }
          }}/>
      </Box>
    );

    const wrappedMobileSearchAccessory = (
      <div className='wrappedMobileSearchAccessory'>
        <div className='accessoryDialogCloseIcon'
             onClick={this.closeFiltersDialog}>{closeIcon}</div>
        <div className='accessoryWrapper'>
          <p className='accessoryDialogTitle'>Filters:</p>
          <div className='accessoryDialogContainer'>
            {mobileSearchAccessory}
          </div>
        </div>
      </div>
    );
    const fetchingData = invoices?.isFetching;
    const filterDataParsed = filterData?.filter((item) => item?.key !== 'divider');
    return isMobileOrTabletLayout ? (
      <Box component='aside' className={`filter filterMobile ${className}`}
           sx={{...mainMenuHeaderStyles}}>

        {!_.isEmpty(filterDataParsed) &&
          <div className='filtersSelectDropdown'>
            <Select
              disableUnderline
              value={t(this.state.selectedFilter)}
            >
              {
                filterDataParsed
                  .map((item, index) => {

                    const isEmpty = _.isEmpty(item);

                    let itemNameWithCount = '';

                    let itemName = isEmpty
                      ? ''
                      : (typeof item.name) === 'string'
                        ? item.name
                        : item.name.props.children;

                    const selectedMenuItemStyles = this.state.selectedFilter === itemName ? (
                      filterPanelMobileMenuItemSelected
                    ) : {};

                    itemName = t(itemName);
                    const translatedNameWithoutCount = itemName;

                    if (typeof item?.name === 'string' && item?.count > -1 && item?.name === this.state.selectedFilter && !fetchingData) {
                      itemNameWithCount = itemName;
                      itemName = `${itemName} (${item.count})`;
                    }

                    return (
                      <MenuItem
                        key={index}
                        value={itemNameWithCount ? itemNameWithCount : itemName}
                        disabled={isEmpty}
                        onClick={() => {
                          this.onFilterSelected(filterDataParsed[index], index);
                          this.analyticsEventPush(translatedNameWithoutCount);
                        }}
                        style={filterPanelMobileMenuItem}
                        sx={selectedMenuItemStyles}
                      >
                        {isEmpty ? <Divider style={{
                          width: '100%',
                          backgroundColor: '#393A3F'
                        }}/> : t(itemName)}
                      </MenuItem>
                    );
                  })
              }
            </Select>
            {
              additionalFilterDataComponent ? <Box>{additionalFilterDataComponent}</Box> : null
            }
          </div>
        }

        {mobileSearchAccessory &&
          <Dialog
            open={this.state.isFiltersDialogOpen}
            className='dialogBody dialogRootContainer inventoryFiltersDialog detailDialog'
            fullScreen
            onClose={this.closeFiltersDialog}
          >
            {wrappedMobileSearchAccessory}
          </Dialog>
        }

      </Box>
    ) : !mobileOnly && (
      <aside className={`filter ${className}`}>
        {logoRender}
        <div className='filterContent'>
          <div className='filterButton'>
            {buttonText && (
              <Button
                className={buttonClass}
                onClick={buttonOnClick}
                endIcon={selectedButtonIcon}
                labelStyle={{fontWeight: 600}}
                label={t(buttonText)}
                disabled={disabled}
                fullWidth
                isSpaceBetween
              />
            )}
          </div>
          <ul className={buttonBar ? 'filterListButtonBar' : 'filterList'}>
            {this.listItems()}
            {
              additionalFilterDataComponent ? 
                <Box sx={filterPanelAdditionalComponentStyles}>
                  <Divider/> 
                  {additionalFilterDataComponent}
                </Box> 
              : 
                null
            }
          </ul>
        </div>

        {optionalFilterFooter && (
          <div className='optionalFilterFooter'>{optionalFilterFooter}</div>
        )}

        {betaDisclaimer && betaDisclaimer.show && (
          <BetaDisclaimer
            shouldHaveBottomMargin={betaDisclaimer.shouldHaveBottomMargin}
            t={t}
            type={betaDisclaimer.type}
          />
        )}

      </aside>
    );
  }
}

const FilterPanelWithLayoutBreakPoints = withLayoutBreakPoints(FilterPanel);

FilterPanelWithLayoutBreakPoints.displayName = 'FilterPanel';

export default FilterPanelWithLayoutBreakPoints
