/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import TextUtil from './FormatTextUtil';
import CsvUtil from './CsvUtil';

const ReportUtil = {

  getTransactionInvoice: function (currentTransaction, allTransactions) {

    let parentTransaction = null;
    let invoiceNumber;
    if (currentTransaction.invoice) {
      invoiceNumber = currentTransaction.invoice;
    } else {
      switch (currentTransaction.type) {
        case 'Void':
          parentTransaction = _.find(allTransactions, {void_uniq_id: currentTransaction.uniq_id});
          break;
        case 'Credit Refund':
          parentTransaction = _.find(allTransactions, {uniq_id: currentTransaction.parent_uniq_id});
          break;
        case 'Cash Refund':
          parentTransaction = _.find(allTransactions, {uniq_id: currentTransaction.parent_uniq_id});
          break;
        default:
          parentTransaction = _.find(allTransactions, {receipt_id: currentTransaction.receipt_id});
          break;
      }
      invoiceNumber = parentTransaction && parentTransaction.invoice ? parentTransaction.invoice : null;
    }

    return invoiceNumber;
  },

  buildReportStyle: function () {

    return {
      header: {
        fontSize: 36,
        bold: true,
        alignment: 'justify'
      },
      subheader: {
        fontSize: 13,
        bold: false
      },
      small: {
        fontSize: 8
      },
      tableData: {
        fontSize: 10,
        color: 'gray',
      },
      reportsTable: {
        margin: [0, 5, 0, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      },
      tableDataSmall: {
        fontSize: 6,
      },
      tableDataExtraSmall: {
        fontSize: 5,
      },
      tableHeaderSmall: {
        bold: true,
        fontSize: 8,
        color: 'black',
        alignment: 'justify'
      },
      tableHeaderExtraSmall: {
        bold: true,
        fontSize: 6,
        color: 'black',
        alignment: 'justify'
      }
    };

  },

  buildFlashReportGeneralTable: function (transactionData) {

    let categoryTotalSold = 0,
      categoryTotalSales = 0,
      discountsTotalSold = 0,
      discountsTotalSales = 0;

    let totalRefunds = transactionData.refunds && transactionData.refunds.total ? transactionData.refunds.total : 0;
    let totalUnpaidAmount = transactionData.unpaidAmount && transactionData.unpaidAmount.total ? transactionData.unpaidAmount.total : 0;
    let totalVoids = transactionData.voids && transactionData.voids.total ? transactionData.voids.total : 0;

    let soldRefunds = transactionData.refunds && transactionData.refunds.amount ? transactionData.refunds.amount : 0;
    let soldVoids = transactionData.voids && transactionData.voids.amount ? transactionData.voids.amount : 0;


    let tableBody = [];
    tableBody.push([{text: 'NAME', style: 'tableHeader'}, {
      text: '# ITEMS SOLD',
      style: 'tableHeader',
      alignment: 'right'
    }, {text: 'TOTAL SALES', style: 'tableHeader', alignment: 'right'}]);

    ///////////////////
    // CATEGORY SALES
    ///////////////////

    tableBody.push([{text: 'Category Sales', colSpan: 3, margin: [0, 10]}]);
    transactionData.categorySales.map((category, i) => {
      categoryTotalSold = categoryTotalSold + category.sold;
      categoryTotalSales = categoryTotalSales + category.total_with_modifier;

      tableBody.push([{text: category.name, style: 'tableData'}, {
        text: category.sold.toString(),
        style: 'tableData',
        alignment: 'right'
      }, {text: numeral(category.total_with_modifier).format('$0,0.00'), bold: true, alignment: 'right'}]);
    });

    tableBody.push(['Subtotal', {
      text: categoryTotalSold.toString(),
      alignment: 'right'
    }, {text: numeral(categoryTotalSales).format('$0,0.00'), bold: true, alignment: 'right'}]);

    ///////////////////
    // DISCOUNTS
    ///////////////////

    tableBody.push([{text: 'Discounts', colSpan: 3, margin: [0, 10]}]);
    transactionData.discounts.map((discount, i) => {
      discountsTotalSold = discountsTotalSold + discount.sold;
      discountsTotalSales = discountsTotalSales + discount.total;

      tableBody.push([{text: discount.name, style: 'tableData'}, {
        text: discount.sold.toString(),
        style: 'tableData',
        alignment: 'right'
      }, {text: numeral(discount.total).format('$0,0.00'), bold: true, alignment: 'right'}]);
    });

    tableBody.push(['Subtotal', {
      text: discountsTotalSold.toString(),
      alignment: 'right'
    }, {text: numeral(discountsTotalSales).format('$0,0.00'), bold: true, alignment: 'right'}]);

    //////////////////////////////////////
    // REFUNDS, VOIDS, UNPAID AMOUNT
    //////////////////////////////////////

    tableBody.push([{text: 'Refunds', style: 'tableData'}, {
      text: soldRefunds.toString(),
      style: 'tableData',
      alignment: 'right'
    }, {text: numeral(totalRefunds).format('$0,0.00'), bold: true, alignment: 'right'}]);
    tableBody.push([{text: 'Voids', style: 'tableData'}, {
      text: soldVoids.toString(),
      style: 'tableData',
      alignment: 'right'
    }, {text: numeral(totalVoids).format('$0,0.00'), bold: true, alignment: 'right'}]);
    tableBody.push([{text: 'Unpaid Amount', style: 'tableData'}, {
      text: '',
      style: 'tableData',
      alignment: 'right'
    }, {text: numeral(totalUnpaidAmount).format('$0,0.00'), bold: true, alignment: 'right'}]);

    let soldRefundsVoidsAndUnpaid = soldRefunds + soldVoids;
    let totalRefundsVoidsUnpaid = totalRefunds + totalUnpaidAmount + totalVoids;

    tableBody.push(['Refunds, Voids and Unpaid Subtotal', {
      text: soldRefundsVoidsAndUnpaid.toString(),
      alignment: 'right'
    }, {text: numeral(totalRefundsVoidsUnpaid).format('$0,0.00'), bold: true, alignment: 'right'}]);

    //////////////////////////////////////
    // TOTAL TO ACCOUNT FOR
    //////////////////////////////////////
    tableBody.push(['Net Sales', {
      text: '',
      alignment: 'right'
    }, {
      text: numeral(categoryTotalSales + (discountsTotalSales + totalRefundsVoidsUnpaid)).format('$0,0.00'),
      bold: true,
      alignment: 'right'
    }]);
    tableBody.push(['Tips', {
      text: transactionData.tips.amount.toString(),
      alignment: 'right'
    }, {text: numeral(transactionData.tips.total).format('$0,0.00'), bold: true, alignment: 'right'}]);
    tableBody.push(['Tax', {text: '', alignment: 'right'}, {
      text: numeral(transactionData.tax).format('$0,0.00'),
      bold: true,
      alignment: 'right'
    }]);

    let totalToAccountFor = (transactionData.tax + transactionData.tips.total + categoryTotalSales) + (discountsTotalSales + totalRefundsVoidsUnpaid);
    tableBody.push(['Total to Account For', {
      text: '',
      alignment: 'right'
    }, {text: numeral(totalToAccountFor).format('$0,0.00'), bold: true, alignment: 'right'}]);

    return tableBody;
  },

  buildFlashReportPaymentMethodsTable: function (transactionData) {

    let creditCardTotalSold = 0,
      creditCardTotalSales = 0;

    let tableBody = [];
    tableBody.push([{text: 'PAYMENT METHODS', style: 'tableHeader'}, {text: ''}, {text: ''}]);

    ///////////////////
    // CASH SALES
    ///////////////////

    tableBody.push([{text: 'Cash', style: 'tableData'}, {
      text: transactionData.cash.amount.toString(),
      style: 'tableData',
      alignment: 'right'
    }, {text: numeral(transactionData.cash.total).format('$0,0.00'), bold: true, alignment: 'right'}]);

    ///////////////////
    // CREDIT CARDS
    ///////////////////

    tableBody.push([{text: 'Credit Cards', style: 'tableData', colSpan: 3}]);

    transactionData.creditCards.map((creditCard, i) => {
      creditCardTotalSold = creditCardTotalSold + creditCard.sold;
      creditCardTotalSales = creditCardTotalSales + creditCard.total;

      tableBody.push([{text: creditCard.name, style: 'tableData'}, {
        text: creditCard.sold.toString(),
        style: 'tableData',
        alignment: 'right'
      }, {text: numeral(creditCard.total).format('$0,0.00'), bold: true, alignment: 'right'}]);
    });

    let dollarTotal = creditCardTotalSales + transactionData.cash.total;

    tableBody.push(['Subtotal', {
      text: creditCardTotalSold.toString(),
      alignment: 'right'
    }, {text: numeral(creditCardTotalSales).format('$0,0.00'), bold: true, alignment: 'right'}]);

    //////////////////////////////////////
    // TOTAL TO ACCOUNT FOR
    //////////////////////////////////////

    tableBody.push(['Total to Account For', {
      text: '',
      alignment: 'right'
    }, {text: numeral(dollarTotal).format('$0,0.00'), bold: true, alignment: 'right'}]);

    return tableBody;
  },

  buildProductSalesGeneralTable: function (transactionData) {

    let categorySalesTotalSold = _.reduce(_.map(transactionData.categorySales, 'sold'), function (sum, n) {
      return sum + n;
    }, 0);

    let categorySalesTotalAmount = _.reduce(_.map(transactionData.categorySales, 'total'), function (sum, n) {
      return sum + n;
    }, 0);

    let discountsTotal = _.reduce(_.map(transactionData.discounts, 'sold'), function (sum, n) {
      return sum + n;
    }, 0);

    let discountsAmount = _.reduce(_.map(transactionData.discounts, 'total'), function (sum, n) {
      return sum + n;
    }, 0);

    let totalRefunds = transactionData.refunds && transactionData.refunds.total ? transactionData.refunds.total : 0;
    let totalUnpaidAmount = transactionData.unpaidAmount && transactionData.unpaidAmount.total ? transactionData.refunds.total : 0;
    let totalVoids = transactionData.voids && transactionData.voids.total ? transactionData.voids.total : 0;

    let soldRefunds = transactionData.refunds && transactionData.refunds.amount ? transactionData.refunds.amount : 0;
    let soldVoids = transactionData.voids && transactionData.voids.amount ? transactionData.voids.amount : 0;

    let netSales = categorySalesTotalAmount + totalRefunds + totalVoids + totalUnpaidAmount + discountsAmount;

    let averageDiscount = discountsTotal ? discountsAmount / discountsTotal : 0;
    let averageCategorySales = categorySalesTotalSold ? categorySalesTotalAmount / categorySalesTotalSold : 0;

    let tableBody = [];
    tableBody.push([
      {text: 'NAME', style: 'tableHeader'},
      {text: 'AVERAGE PRICE', style: 'tableHeader', alignment: 'right'},
      {text: '# ITEMS SOLD', style: 'tableHeader', alignment: 'right'},
      {text: 'TOTAL SALES', style: 'tableHeader', alignment: 'right'}
    ]);

    ///////////////////
    // CATEGORY SALES
    ///////////////////

    transactionData.categorySales.map((category, i) => {

      tableBody.push([{text: category.name, colSpan: 4, margin: [0, 10]}]);

      let totalSales = _.reduce(_.map(category.transactions, 'sold'), function (sum, n) {
        return sum + n;
      }, 0);

      let totalSalesAmount = _.reduce(_.map(category.transactions, 'total'), function (sum, n) {
        return sum + n;
      }, 0);

      let sortedTransactions = _.sortBy(category.transactions, 'total').reverse();

      sortedTransactions.map((transaction, i) => {

        let transactionAverage = transaction.total ? transaction.total / transaction.sold : 0;

        tableBody.push([
          {text: transaction.name, style: 'tableData'},
          {text: numeral(transactionAverage).format('$0,0.00'), style: 'tableData'},
          {text: transaction.sold.toString(), style: 'tableData', alignment: 'right'},
          {text: numeral(transaction.total).format('$0,0.00'), bold: true, alignment: 'right'}
        ]);
      });

      tableBody.push([
        'Subtotal',
        {text: '', alignment: 'right'},
        {text: totalSales.toString(), alignment: 'right'},
        {text: numeral(totalSalesAmount).format('$0,0.00'), bold: true, alignment: 'right'}
      ]);

    });

    tableBody.push([
      {text: 'Category Sales Subtotal', style: 'tableData'},
      {text: numeral(averageCategorySales).format('$0,0.00'), style: 'tableData'},
      {text: categorySalesTotalSold.toString(), style: 'tableData', alignment: 'right'},
      {text: numeral(categorySalesTotalAmount).format('$0,0.00'), bold: true, alignment: 'right'},
    ]);

    tableBody.push([
      {text: 'Discounts Subtotal', style: 'tableData'},
      {text: numeral(averageDiscount).format('$0,0.00'), style: 'tableData'},
      {text: discountsTotal.toString(), style: 'tableData', alignment: 'right'},
      {text: numeral(discountsAmount).format('$0,0.00'), bold: true, alignment: 'right'}
    ]);

    tableBody.push([
      {text: 'Refunds', style: 'tableData'},
      {text: '', style: 'tableData'},
      {text: soldRefunds.toString(), style: 'tableData', alignment: 'right'},
      {text: numeral(totalRefunds).format('$0,0.00'), bold: true, alignment: 'right'}
    ]);

    tableBody.push([
      {text: 'Voids', style: 'tableData'},
      {text: '', style: 'tableData'},
      {text: soldVoids.toString(), style: 'tableData', alignment: 'right'},
      {text: numeral(totalVoids).format('$0,0.00'), bold: true, alignment: 'right'}
    ]);

    tableBody.push([
      {text: 'Unpaid Amount', style: 'tableData'},
      {text: '', style: 'tableData'},
      {text: '', style: 'tableData', alignment: 'right'},
      {text: numeral(totalUnpaidAmount).format('$0,0.00'), bold: true, alignment: 'right'}
    ]);

    tableBody.push([
      {text: 'Net Sales', style: 'tableData'},
      {text: '', style: 'tableData'},
      {text: '', style: 'tableData'},
      {text: numeral(netSales).format('$0,0.00'), bold: true, alignment: 'right'}
    ]);

    return tableBody;
  },

  buildTransactionExportTable: function (transactionData, user) {

    let tableBody = [];

    tableBody.push([
      {text: 'Date', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Account Number', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Invoice', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Sold By', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Customer Name', style: 'tableHeaderExtraSmall'},
      {text: 'Total Transaction Amount', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Payment Amount', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Authorized Amount', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Tip', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: '$ Discount', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: '$ Tax', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'State Tax', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'County Tax', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'City Tax', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Custom Tax', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Payment Type', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Card Brand', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'First 6', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Last 4', style: 'tableHeaderExtraSmall', alignment: 'right'},
      {text: 'Comment', style: 'tableHeaderExtraSmall', alignment: 'right'}
    ]);

    transactionData.receipts.map((transaction, i) => {

      let customerName = TextUtil.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');

      let invoiceNumber = transaction.invoice ? transaction.invoice.toString() : '';
      let transactionNetwork = transaction.network ? transaction.network.toString() : '';
      let first6 = transaction.cc_first6 ? transaction.cc_first6.toString() : '';
      let last4 = transaction.cc_last4 ? transaction.cc_last4.toString() : '';
      let accountNumber = transaction.mid ? transaction.mid.toString() : '';
      let soldBy = transaction.sold_by ? transaction.sold_by.toString() : '';
      let comment = transaction.comment ? transaction.comment : '';
      let stateTax = transaction.state_tax ? numeral(transaction.state_tax / 100).format('0.000 %') : 'n/a';
      let countyTax = transaction.county_tax ? numeral(transaction.county_tax / 100).format('0.000 %') : 'n/a';
      let cityTax = transaction.city_tax ? numeral(transaction.city_tax / 100).format('0.000 %') : 'n/a';
      let customizedTax = transaction.customized_tax ? numeral(transaction.customized_tax / 100).format('0.000 %') : 'n/a';
      let authorizedAmount = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).format('$0,0.00') : 'n/a';

      tableBody.push([
        {text: moment(transaction.datetime).format('MM/DD/YYYY, h:mm:ss a'), style: 'tableDataExtraSmall'},
        {text: accountNumber, style: 'tableDataSmall'},
        {text: invoiceNumber, alignment: 'right', style: 'tableDataSmall'},
        {text: soldBy, alignment: 'right', style: 'tableDataExtraSmall'},
        {text: customerName, style: 'tableDataExtraSmall'},
        {text: numeral(transaction.total_amt).format('$0,0.00'), alignment: 'right', style: 'tableDataSmall'},
        {text: numeral(transaction.amount).format('$0,0.00'), alignment: 'right', style: 'tableDataSmall'},
        {text: authorizedAmount, alignment: 'right', style: 'tableDataSmall'},
        {text: numeral(transaction.tip_amount).format('$0,0.00'), alignment: 'right', style: 'tableDataSmall'},
        {text: numeral(transaction.total_discount_amt).format('$0,0.00'), alignment: 'right', style: 'tableDataSmall'},
        {text: numeral(transaction.tax_amt).format('$0,0.00'), alignment: 'right', style: 'tableDataSmall'},
        {text: stateTax, alignment: 'right', style: 'tableDataSmall'},
        {text: countyTax, alignment: 'right', style: 'tableDataSmall'},
        {text: cityTax, alignment: 'right', style: 'tableDataSmall'},
        {text: customizedTax, alignment: 'right', style: 'tableDataSmall'},
        {text: transaction.type, style: 'tableDataSmall'},
        {text: transactionNetwork, style: 'tableDataSmall'},
        {text: first6, style: 'tableDataSmall'},
        {text: last4, style: 'tableDataSmall'},
        {text: comment, style: 'tableDataExtraSmall'}
      ]);

    });

    return tableBody;
  },

  buildItemizedExportTable: function (transactionData, user) {

    let tableBody = [];
    tableBody.push([
      {text: 'Item Name', style: 'tableHeaderSmall'},
      {text: 'Item Quantity', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Category', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Price', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Discount $', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Discount %', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Tax %', style: 'tableHeaderSmall', alignment: 'right'},
      {text: 'Total', style: 'tableHeaderSmall', alignment: 'right'}
    ]);

    transactionData.map((item, i) => {

      let discountPercentage = item.discount_rate ? numeral(item.discount_rate / 100).format('0.00 %') : '0.00 %';
      let taxPercentage = item.tax_rate ? numeral(item.tax_rate / 100).format('0.00 %') : '0.00 %';
      let itemQuantity = item.item_quantity ? item.item_quantity.toString() : '';

      tableBody.push([
        {text: item.item_name, style: 'tableDataSmall'},
        {text: itemQuantity, style: 'tableDataSmall'},
        {text: item.category, style: 'tableDataSmall'},
        {text: numeral(item.price).format('$0,0.00'), style: 'tableDataSmall'},
        {text: numeral(item.discount_amt).format('$0,0.00'), style: 'tableDataSmall'},
        {text: discountPercentage, style: 'tableDataSmall'},
        {text: taxPercentage, style: 'tableDataSmall'},
        {text: numeral(item.total).format('$0,0.00'), style: 'tableDataSmall'}
      ]);
    });

    return tableBody;
  },

  generatePdfFlashReport: function (reportData) {

    let startDate = reportData.dateRange.startDate ? moment(reportData.dateRange.startDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';
    let endDate = reportData.dateRange.endDate ? moment(reportData.dateRange.endDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';

    return {
      content: [
        {text: 'FLASH REPORT\n', style: 'header'},
        {
          text: _.toUpper(reportData.user.data.username) + ' | GENERATED ' + moment().format('dddd, MMMM Do YYYY, h:mm:ss a') + '\n\n\n\n',
          style: 'small'
        },
        {
          text: 'START: ' + startDate + '\n',
          style: 'subheader'
        },
        {
          text: 'END: ' + endDate + '\n\n\n',
          style: 'subheader'
        },
        {
          style: 'reportsTable',
          table: {
            widths: ['*', '*', '*'],
            alignment: 'justify',
            headerRows: 1,
            body: this.buildFlashReportGeneralTable(reportData.transactionData)
          },
          layout: 'headerLineOnly'
        },
        {
          style: 'reportsTable',
          table: {
            widths: ['*', '*', '*'],
            alignment: 'justify',
            headerRows: 1,
            body: this.buildFlashReportPaymentMethodsTable(reportData.transactionData)
          },
          layout: 'headerLineOnly'
        },
      ],
      styles: this.buildReportStyle(),
    };

  },

  generatePdfProductSalesReport: function (reportData) {

    let startDate = reportData.dateRange.startDate ? moment(reportData.dateRange.startDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';
    let endDate = reportData.dateRange.endDate ? moment(reportData.dateRange.endDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';

    return {
      content: [
        {text: 'PRODUCT SALES REPORT\n', style: 'header'},
        {
          text: _.toUpper(reportData.user.data.username) + ' | GENERATED ' + moment().format('dddd, MMMM Do YYYY, h:mm:ss a') + '\n\n\n\n',
          style: 'small'
        },
        {
          text: 'START: ' + startDate + '\n',
          style: 'subheader'
        },
        {
          text: 'END: ' + endDate + '\n\n\n',
          style: 'subheader'
        },
        {
          style: 'reportsTable',
          table: {
            widths: ['*', '*', '*', '*'],
            alignment: 'justify',
            headerRows: 1,
            body: this.buildProductSalesGeneralTable(reportData.transactionData)
          },
          layout: 'headerLineOnly'
        },
      ],
      styles: this.buildReportStyle(),
    };

  },

  buildProductSalesTotals: function (transactionData = {}) {

    let productSalesTotals = {};

    productSalesTotals.categorySalesTotalSold = _.reduce(_.map(transactionData.categorySales, 'sold'), function (sum, n) {
      return sum + n;
    }, 0);

    productSalesTotals.categorySalesTotalAmount = _.reduce(_.map(transactionData.categorySales, 'total_with_modifier'), function (sum, n) {
      return sum + n;
    }, 0);

    productSalesTotals.discountsTotal = _.reduce(_.map(transactionData.discounts, 'sold'), function (sum, n) {
      return sum + n;
    }, 0);

    productSalesTotals.discountsAmount = _.reduce(_.map(transactionData.discounts, 'total'), function (sum, n) {
      return sum + n;
    }, 0);

    productSalesTotals.totalRefunds = transactionData.refunds && transactionData.refunds.total ? transactionData.refunds.total : 0;
    productSalesTotals.totalUnpaidAmount = transactionData.unpaidAmount && transactionData.unpaidAmount.total ? transactionData.unpaidAmount.total : 0;
    productSalesTotals.totalVoids = transactionData.voids && transactionData.voids.total ? transactionData.voids.total : 0;

    productSalesTotals.soldRefunds = transactionData.refunds && transactionData.refunds.amount ? transactionData.refunds.amount : 0;
    productSalesTotals.soldVoids = transactionData.voids && transactionData.voids.amount ? transactionData.voids.amount : 0;

    productSalesTotals.netSales = productSalesTotals.categorySalesTotalAmount + productSalesTotals.totalRefunds +
      productSalesTotals.totalVoids + productSalesTotals.totalUnpaidAmount + productSalesTotals.discountsAmount;

    productSalesTotals.averageDiscount = productSalesTotals.discountsTotal ? productSalesTotals.discountsAmount / productSalesTotals.discountsTotal : 0;
    productSalesTotals.averageCategorySales = productSalesTotals.categorySalesTotalSold ? productSalesTotals.categorySalesTotalAmount / productSalesTotals.categorySalesTotalSold : 0;

    return productSalesTotals;
  },

  buildFlashReportTotals: function (transactionData) {

    let flashReportTotals = {};

    flashReportTotals.categoryTotalSold = 0;
    flashReportTotals.categoryTotalSales = 0;
    flashReportTotals.discountsTotalSold = 0;
    flashReportTotals.discountsTotalSales = 0;
    flashReportTotals.creditCardTotalSold = 0;
    flashReportTotals.creditCardTotalSales = 0;
    flashReportTotals.giftCardTotalSales = 0;

    flashReportTotals.totalRefunds = transactionData?.refunds && transactionData.refunds.total ? transactionData.refunds.total : 0;
    flashReportTotals.totalUnpaidAmount = transactionData?.unpaidAmount && transactionData.unpaidAmount.total ? transactionData.unpaidAmount.total : 0;
    flashReportTotals.totalVoids = transactionData?.voids && transactionData.voids.total ? transactionData.voids.total : 0;

    flashReportTotals.soldRefunds = transactionData?.refunds && transactionData.refunds.amount ? transactionData.refunds.amount : 0;
    flashReportTotals.soldVoids = transactionData?.voids && transactionData.voids.amount ? transactionData.voids.amount : 0;

    flashReportTotals.totalRefundsVoidsUnpaid = flashReportTotals.totalRefunds + flashReportTotals.totalUnpaidAmount + flashReportTotals.totalVoids;

    return flashReportTotals;
  },

  buildDepositTransactionsHtml: function (transactionData, deposit, filename) {

    const depositDate = moment(deposit.effective_date).format('MM/DD/YYYY') + ' ' + moment(deposit.effective_date).format('h:mm a');
    let depositTransactionsHtml = '<div class="printDepositDetails"><h1>' + filename + '</h1>';

    const depositDetailsHtml = '<div><p>Deposit Date: ' + depositDate + '</p>' +
      '<p>Type: ' + deposit.type + '</p>' +
      '<p>Bank Account Ending in: ' + deposit.account + '</p>' +
      '<p>Amount: ' + numeral(deposit.amount_to_clear).format('-$0,0.00') + '</p></div>';

    depositTransactionsHtml += depositDetailsHtml;

    if (transactionData.length > 0) {
      const depositTransactionsHtmlHeaders = '<table border="1" class="depositTransactions" >' +
        '<tr><th>Transaction Date & Time</th>' +
        '<th>Payment Type</th>' +
        '<th>Type</th>' +
        '<th>Description</th>' +
        '<th>GUID</th>' +
        '<th>BRIC</th>' +
        '<th>Card Brand</th>' +
        '<th>First 6</th>' +
        '<th>Last 4</th>' +
        '<th>Auth Code</th>' +
        '<th>Transaction Amount</th>' +
        '<th>Rate</th>' +
        '<th>Processing Fee</th>' +
        '<th>Net Deposit Amount</th></tr>';

      depositTransactionsHtml += depositTransactionsHtmlHeaders;

      let transactionTotal = 0;
      let processingFeeTotal = 0;
      let netDepositAmountTotal = 0;

      transactionData.map((depositTransaction, i) => {

        transactionTotal = depositTransaction.amount ? transactionTotal + Number(depositTransaction.amount) : transactionTotal;
        processingFeeTotal = depositTransaction.fee ? processingFeeTotal + Number(depositTransaction.fee) : processingFeeTotal;
        netDepositAmountTotal = depositTransaction.total ? netDepositAmountTotal + Number(depositTransaction.total) : netDepositAmountTotal;

        depositTransactionsHtml += '<tr><td>' + TextUtil.formatDateOrReturnBlank(depositTransaction.date, 'MM/DD/YYYY h:mm a') + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.type) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.transactionType) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.description) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.guid) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.bric) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.brand) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.first6) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.last4) + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.auth_code) + '</td>' +
          '<td>' + TextUtil.formatDollarsOrReturnBlank(depositTransaction.amount, '$0,0.00') + '</td>' +
          '<td>' + TextUtil.valeOrReturnBlank(depositTransaction.rate) + '</td>' +
          '<td>' + TextUtil.formatDollarsOrReturnBlank(depositTransaction.fee, '$0,0.00') + '</td>' +
          '<td>' + TextUtil.formatDollarsOrReturnBlank(depositTransaction.total, '$0,0.00') + '</td></tr>';
      });

      depositTransactionsHtml += '<tr><td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td> </td>' +
        '<td><b>' + numeral(transactionTotal).format('$0,0.00') + '</b></td>' +
        '<td> </td>' +
        '<td><b>' + numeral(processingFeeTotal).format('$0,0.00') + '</b></td>' +
        '<td><b>' + numeral(netDepositAmountTotal).format('$0,0.00') + '</b></td></tr>'

    }

    depositTransactionsHtml += '</div><script type="text/javascript">window.print()</script></table>';

    return depositTransactionsHtml;
  },

  createHtmlFromCsvReport: function (csvReportName, props, reportData, optionalReportHeaders) {

    let keys = optionalReportHeaders || CsvUtil.createHeaders(reportData);

    let headingArray = _.map(keys, (key) => {
      return {text: key, style: 'tableHeaderSmall', alignment: 'right'}
    });

    let rowArray = _.map(reportData, (item) => {
      return _.map(keys, (key, keyIndex) => {
        return {
          text: item[key] || '',
          style: 'tableDataSmall',
          alignment: headingArray[keyIndex].alignment
        };
      });
    });

    let json = _.concat([headingArray], rowArray);
    return this.createReportTableHtmlFromJSON(csvReportName, props, json)
  },

  createReportTableHtmlFromJSON: function (csvReportName, props, jsonData) {

    let accountNumber = props.userExperience.allAccounts ? 'AllAccounts' :
      _.find(props.user.data.merchantAccounts, ['mea_id', Number(props.user.selectedMerchantAccount)]).mid;

    const startDate = props.reportDate && props.reportDate.startDate ? moment(props.reportDate.startDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';
    const endDate = props.reportDate && props.reportDate.endDate ? moment(props.reportDate.endDate).format('MM/DD/YYYY, h:mm a') : ' N/A ';

    let reportHeading = `
        <div style='text-align: center'>
          <h2>${csvReportName}</h2>
          <span>${accountNumber}</span><br />
          <span>START: ${startDate}</span><br />
          <span>END: ${endDate}</span><br /><br />
          <a id='printButton' href='#'>Print Report</a><br /><br />
        </div>
    `;

    let tableHtml = reportHeading;

    if (jsonData.length > 0) {

      let tableHeader = '<tr>' + _.map(_.head(jsonData), rowHeading => {
          return `<th class='${rowHeading.style}' align='${rowHeading.alignment}'>${rowHeading.text}</th>`;
        }).join('') + '</tr>';

      let allRows = _.tail(jsonData).map(rows => {
        let row = _.map(rows, (rowValues) => {
          return `<td class='${rowValues.style}' align='${rowValues.alignment}'>${this.sanitizeHtml(rowValues.text)}</td>`;
        })
        return `<tr>${row.join('')}</tr>`;
      });

      tableHtml += `
        <table width='100%' border="1">
            ${tableHeader}
            ${allRows.join('')}
        </table>`;

    }

    return tableHtml;
  },

  sanitizeHtml: function (str) {
    let data = document.createElement('div');
    data.textContent = str;
    return data.innerHTML;
  },

  mergeDepositTransactionArrays: function (transactionsArray, chargebacksArray, feesArray, representmentsArray) {

    const newTransactionsArray = transactionsArray.map(transaction => ({ ...transaction, ...{transactionType: 'transaction'} }));
    const newChargebacksArray = chargebacksArray.map(chargeback => ({ ...chargeback, ...{transactionType: 'chargeback'} }));
    const newFeesArray = feesArray.map(fee => ({ ...fee, ...{transactionType: 'fee'} }));
    const newRepresentmentArray = representmentsArray.map(representment => ({ ...representment, ...{transactionType: 'representment'} }));

    const depositIncludeArrays = newTransactionsArray.concat(newChargebacksArray.concat(newFeesArray.concat(newRepresentmentArray)));

    return depositIncludeArrays;
  },

  groupArray: function (receipts, chunkSize) {

    return new Promise((resolve, reject) => {

      let groups = [], i;

      for (i = 0; i < receipts.length; i += chunkSize) {
        groups.push(receipts.slice(i, i + chunkSize));
      }

      resolve(groups);

    });

  },

  // Look through all the custom fields in the transactions and return an array of unique custom field names (sorted)
  customFieldHeaders(transactions) {
    let customFieldsHeaders = _.reduce(transactions, (acc, transaction) => {
      const customFields = transaction?.custom_fields;
      for(const prop in customFields) {
        acc[customFields[prop].name] = true;
      }
      return acc;
    }, {});
    return Object.keys(customFieldsHeaders).sort();
  },

  // Generate a row of custom fields for each transaction, adding a blank value if the custom field is not present
  generateCustomFieldRows(transaction, customFieldHeaders) {
    const customFields = {};
    for(const header of customFieldHeaders) {
      const value = _.find(transaction.custom_fields, { 'name': header })?.value;
      customFields[header] = value || '';
    }
    return customFields;
  },

  printButtonCallback: (reportWindow) => () => reportWindow.print(),

  windowBindListeners: function (reportWindow) {
    const printButton = reportWindow.document.querySelector('#printButton');

    if (printButton) {
      printButton.addEventListener('click', ReportUtil.printButtonCallback(reportWindow));
    }
  }

};
export default ReportUtil;
