import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';

export function getAccountProfile(user) {

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.accountProfileRequest, actionTypes.accountProfileSuccess, actionTypes.accountProfileFailure]
    }
  };

}

export function getAccountFundingProfile(user, type) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/funding-profile?type=${type}`;

  let requestActionTypes = [actionTypes.accountBankingProfileRequest, actionTypes.accountBankingProfileSuccess, actionTypes.accountBankingProfileFailure];

  if (type === 'funding') {
    requestActionTypes = [actionTypes.accountFundingProfileRequest, actionTypes.accountFundingProfileSuccess, actionTypes.accountFundingProfileFailure];
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: requestActionTypes
    }
  };

}

export function getQuickBooksURI(user) {

  const endpoint = `integrations/users/accounts/${user.selectedMerchantAccount}/quickbooks/connect`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.getQuickBooksURIRequest, actionTypes.getQuickBooksURISuccess, actionTypes.getQuickBooksURIFailure]
    }
  };
}

export function getQuickbooksToken(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/quickbooks`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.getQuickBooksTokenRequest, actionTypes.getQuickBooksTokenSuccess, actionTypes.getQuickBooksTokenFailure]
    }
  };
}

export function deleteQuickbooksToken(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/quickbooks`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.deleteQuickBooksTokenRequest, actionTypes.deleteQuickBooksTokenRequestSuccess, actionTypes.deleteQuickBooksTokenRequestFailure]
    }
  };
}

export function deleteOwnerByContactId(user, accountId) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/owner`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.deleteOwnerByContactIdRequest, actionTypes.deleteOwnerByContactIdSuccess, actionTypes.deleteOwnerByContactIdFailure],
      body: {accountId}
    }
  };
}

export function addOwnerContactApi(user, values) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/owner`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      types: [actionTypes.addOwnerContactApiRequest, actionTypes.addOwnerContactApiSuccess, actionTypes.addOwnerContactApiFailure],
      body: values
    }
  };
}

export function updateOwnerContactApi(user, values) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/owner`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'PUT',
      authenticated: true,
      types: [actionTypes.updateOwnerContactApiRequest, actionTypes.updateOwnerContactApiSuccess, actionTypes.updateOwnerContactApiFailure],
      body: values
    }
  };
}

export function getQuickbooksStatus(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/quickbooks/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [
        actionTypes.getQuickBooksStatusRequest,
        actionTypes.getQuickBooksStatusSuccess,
        actionTypes.getQuickBooksStatusFailed
      ]
    }
  };

}

export function getChargezoomStatus(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/chargezoom/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [
        actionTypes.getChargezoomStatusRequest,
        actionTypes.getChargezoomStatusSuccess,
        actionTypes.getChargezoomStatusFailure
      ]
    }
  };

}

export function activateChargezoomPlan(user, plan_id) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/chargezoom/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      body: {plan_id},
      types: [
        actionTypes.activateChargezoomPlanRequest,
        actionTypes.activateChargezoomPlanSuccess,
        actionTypes.activateChargezoomPlanFailure
      ]
    }
  };

}

export function deactivateChargezoomPlan(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/chargezoom/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [
        actionTypes.deactivateChargezoomPlanRequest,
        actionTypes.deactivateChargezoomPlanSuccess,
        actionTypes.deactivateChargezoomPlanFailure
      ]
    }
  };

}

export function getDavoStatus(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/davo/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [
        actionTypes.getDavoStatusRequest,
        actionTypes.getDavoStatusSuccess,
        actionTypes.getDavoStatusFailure
      ]
    }
  };

}

export function activateDavoPlan(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/davo/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      body: {
        type: 'connect',
        davo_tc_accepted: true
      },
      types: [
        actionTypes.activateDavoPlanRequest,
        actionTypes.activateDavoPlanSuccess,
        actionTypes.activateDavoPlanFailure
      ]
    }
  };

}

export function deactivateDavoPlan(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/davo/status`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      body: {type: 'disconnect'},
      types: [
        actionTypes.deactivateDavoPlanRequest,
        actionTypes.deactivateDavoPlanSuccess,
        actionTypes.deactivateDavoPlanFailure
      ]
    }
  };

}

export function getNextSignature(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/integrations/next/signature`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [
        actionTypes.getNextSignatureRequest,
        actionTypes.getNextSignatureSuccess,
        actionTypes.getNextSignatureFailure
      ]
    }
  };

}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

