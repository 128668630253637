import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const willUnmount = (unblockFn) => () => unblockFn();

const RouterPrompt = ({ when, renderModal }) => {
  const history = useHistory();
  const [unblock, setUnblock] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    if (!when) return;

    const unblockFn = history.block((location) => {
      setNextLocation(location);
      setShowModal(true);
      return false;
    });

    setUnblock(() => unblockFn);

    return willUnmount(unblockFn);
  }, [when, history]);

  const confirmNavigation = () => {
    unblock();
    setShowModal(false);
    history.push(nextLocation.pathname);
  };

  const cancelNavigation = () => {
    setShowModal(false);
    setNextLocation(null);
  };

  return when && showModal
    ? renderModal({ confirmNavigation, cancelNavigation })
    : null;
};

export default RouterPrompt;
