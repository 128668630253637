/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {Box, Grid, Stack} from '@mui/material';

import {
  createSignup,
  clearSignup,
  setLeadError
} from '../../actions/leadSignupActions';
import {
  loginUser,
  logoutUser,
  getTwoFactorToken,
  verifyTwoFactorRegistrationToken
} from '../../actions/authActions';

import IconButton from '../shared/IconButton';
import Stepper from '../shared/Stepper';
import IconUtils from '../util/IconUtil';

import actionTypes from '../../constants/actionTypes';

import LabelUtil from '../util/LabelUtil';

import {
  CreateAccountComponentStyles,
  baseStockImageStyles,
  servingCoffeeStyles,
  holdingBoxStyles,
  northLogoStyles
} from '../../jss/leadSignup/CreateAccountComponentStyles';

import LeadSignupForm from './LeadSignupForm';
import Loading from '../Loading';

import MultiFactorAuthenticationForm from './MultiFactorAuthenticationForm';
import twoFactorRequestTypes from '../../constants/twoFactorRequestTypes';
import applicationConstants from '../../constants/applicationConstants';
import {change, reset} from 'redux-form';
import {suppressAppError} from '../../actions/userExperienceActions';
import routes from '../../constants/routes';

const label = LabelUtil.getLabel();
const appRoutePrefix = globalApplicationLabel.path;

export class CreateAccountComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      phoneNumLastFourDigits: 'XXXX',
      twoFactorType: twoFactorRequestTypes.SMS
    };

    this.sendCode = this.sendCode.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
  }

  componentWillUnmount() {
    this.resetAndClearSignUpForm();
  }

  resetAndClearSignUpForm = () => {
    const { dispatch } = this.props;
    dispatch(clearSignup());
    dispatch(reset('signupForm'));
  }

  getStockImageStyles(step) {
    switch (step) {
      case 0:
        return {
          ...servingCoffeeStyles,
          ...baseStockImageStyles
        };
      case 1:
        return {
          ...holdingBoxStyles,
          ...baseStockImageStyles
        };
      default:
        return null;
    }
  }

  handleSignupSubmit = async (fields) => {
    const { auth, dispatch, googleReCaptchaProps: { executeRecaptcha } } = this.props;

    const recaptchaToken = await executeRecaptcha('lead_signup');

    if (fields.mobileNumber) {
      this.setState({
        phoneNumLastFourDigits : fields.mobileNumber.slice(-4)
      });
    }

    if (auth.isAuthenticated) {
      dispatch(logoutUser());
    }

    dispatch(suppressAppError(true));

    const result = await dispatch(createSignup({
      ...(_.omit(fields, ['confirmPassword'])),
      first_seen_url: document.referrer,
      last_seen_url: document.location?.href,
      recaptchaToken
    }));

    if (result.type === actionTypes.leadSignupSuccess) {
      this.sendCode();
    }
  }

  sendCode(requestType = twoFactorRequestTypes.SMS) {
    const { auth, dispatch, t } = this.props;
    const { mea_id: selectedMerchantAccount, mid_user_id: user_id } = auth.leadSignupUser;

    this.setState({
      twoFactorType: requestType
    });

    return dispatch(getTwoFactorToken({
      data: { user_id },
      selectedMerchantAccount
    }, true, requestType)).then((result) => {
      const rateLimitError = t('Errors.TwoFactor.RateLimit');
      if (result.type === actionTypes.twoFactorSendTokenFailure && result.error === rateLimitError) {
        dispatch(clearSignup(rateLimitError));
      }
    });
  }

  verifyCode(fields) {
    const {
      auth: {
        leadSignupUser: { mid_user_id, mea_id }
      },
      dispatch,
      history,
      t
    } = this.props;

    dispatch(suppressAppError(true));

    return dispatch(verifyTwoFactorRegistrationToken(mid_user_id, mea_id, fields.codeArray.join(''), this.state.twoFactorType)).then((result) => {

      if (result.type === actionTypes.twoFactorVerifyRegistrationTokenFailure) {
        const rateLimitError = t('Errors.TwoFactor.RateLimit');
        if (result.error === rateLimitError) {
          dispatch(suppressAppError(true));
          dispatch(clearSignup(rateLimitError));
        } else {
          dispatch(suppressAppError(true));
          dispatch(setLeadError(t('Errors.TwoFactor.VerifyFailed')));
          dispatch(change('signupForm', 'codeArray', Array(6).fill('')));
        }
      } else {
        return dispatch(loginUser({
          username: fields.email,
          password: fields.password
        })).then(() => {
          history.push(`${appRoutePrefix}${routes.activity.root}${routes.activity.dashboard}`);
        });
      }
    });
  }

  handleBack = () => {
    this.resetAndClearSignUpForm();
  }

  render() {
    const { auth, t } = this.props

    const previousButton = auth.leadSignupUser && (
      <IconButton
        className='iconButton prevButton'
        onClick={this.handleBack}
        tooltip={t('GoBackAction')}
        size='large'>
        {IconUtils.getIcon('Back')}
      </IconButton>
    );


    const mfaLoading = auth.twoFactorCodeStatus === applicationConstants.codeStatus.validating ||
      auth.twoFactorCodeStatus === applicationConstants.codeStatus.sending;

    const stepContent = !auth.leadSignupUser ? (
      <LeadSignupForm
        onSubmit={this.handleSignupSubmit}
        {...this.props}
      />
    ) : (
      <MultiFactorAuthenticationForm
        phoneNumLastFourDigits={this.state.phoneNumLastFourDigits}
        handleResendCode={this.sendCode}
        onSubmit={this.verifyCode}
        {...this.props}
      />
    );

    const currentStep = !auth.leadSignupUser ? 0 : 1;

    return (
      <Grid id='leadSignup' container spacing={1} sx={CreateAccountComponentStyles}>
        <Grid item xs={12} lg={6}>
          {(!!auth.isFetching || mfaLoading) && <Loading/>}
          <Stack
            className='formPanel'
            direction='column'>
            <Box className='formHeader'>
              {previousButton}
              <Box sx={northLogoStyles(currentStep)}>
                <img src={label.brandLogo} alt={label.brand}/>
              </Box>
              <Stepper
                steps={2}
                activeStep={currentStep}
              />
            </Box>
            {stepContent}
          </Stack>
        </Grid>
        <Grid item xs={0} lg={6} sx={this.getStockImageStyles(currentStep)}>
        </Grid>
      </Grid>
    );
  }
}

const CreateAccountComponentWithRecaptcha = withGoogleReCaptcha(CreateAccountComponent);
const CreateAccountComponentProvider = (props) => (
  <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaClientId}>
    <CreateAccountComponentWithRecaptcha {...props} />
  </GoogleReCaptchaProvider>
);

export default CreateAccountComponentProvider;
