import generalOptions from './generalOptions';

export const IDLE_USER_TIMEOUT = 1000 * generalOptions.timeForPromptTokenExpiration; // 5 minutes

export const IDLE_USE_CROSS_TAB = true;
export const IDLE_CROSS_TAB_NAME = 'cross-tab-idle';
export const IDLE_CROSS_TAB_SYNC_INTERVAL = 1000;

export const IDLE_EVENTS_THROTTLE_INTERVAL = 500;
export const IDLE_DEBOUNCE_INTERVAL = 500;




