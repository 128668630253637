/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import FilterPanel from '../shared/FilterPanel';
import { setVisibilityFilter } from '../../actions/userExperienceActions';
import { getAccountProfile } from '../../actions/accountProfileActions';
import { clearCart, clearShippingMethod } from '../../actions/shoppingCartActions';
import Hardware from './supplies/Hardware';
import Supply from './supplies/Supply';
import OrderHistory from './supplies/OrderHistory';
import Allotments from './supplies/Allotments';
import ElectronicReturnLabels from './supplies/ElectronicReturnLabels';
import Page from '../shared/Page';
import UserUtil from '../util/UserUtil';

const filterPanelData = [
  {name: 'Shop Supplies'},
  {name: 'Shop Hardware'},
  {name: 'Order History'},
  {name: 'Allotments'},
  {name: 'Electronic Return Labels', border: true }
];

export default class Supplies extends Component {
  constructor(props) {
    super(props);

    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.loadData = this.loadData.bind(this);
    this.disableFilterOnMidIsInactive = this.disableFilterOnMidIsInactive.bind(this);
    this.state = {
      filterData: this.disableFilterOnMidIsInactive(props),
    };
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.visibilityFilter?.filter?.value !== nextProps.visibilityFilter?.filter?.value){
      this.props.dispatch(setVisibilityFilter('', this.props.filterType));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'))
  }

  disableFilterOnMidIsInactive(props) {
    const { user, auth } = props;
    const isCSorCSWriteUser = UserUtil.isUserCS(user, auth) || UserUtil.isUserCSWrite(user);

    let filterPanelDataClone = isCSorCSWriteUser
      ? filterPanelData.filter(({name}) => name !== 'Electronic Return Labels')
      : [...filterPanelData];

    const currentAccountIsActive = UserUtil.getActiveAccount(user).active;
    if (!currentAccountIsActive) {
      filterPanelDataClone = filterPanelDataClone.map(filter => {
        if (filter.name === 'Shop Supplies' || filter.name === 'Shop Hardware') {
          filter['disabled'] = true;
        }
        return filter;
      });
    }
    return filterPanelDataClone;
  }

  async loadData(props) {
    await props.dispatch(getAccountProfile(props.user));
    const {filterData} = this.state;
    const supplies = filterData.find(filter => filter.name === 'Shop Supplies');
    props.dispatch(setVisibilityFilter({property: 'type', value: supplies?.disabled ? 'Order History' : 'Shop Supplies'}, 'filter'));
  }

  handleFilterSelection(filter) {
    this.props.dispatch(clearCart());
    this.props.dispatch(clearShippingMethod());
    this.props.dispatch(setVisibilityFilter({property: 'type', value: filter.name}, 'filter'));
  }

  render() {

    const {filterData} = this.state;
    const {visibilityFilter, t} = this.props;
    let displayContent = '';
    let hideSearchBar;

    const supply = (<Supply {...this.props}/>);

    const hardware = (<Hardware {...this.props}/>);

    const tabFilter = (type) => {
      const tabs = {
        'Shop Supplies': supply,
        'Shop Hardware': hardware,
        'Order History': (<OrderHistory {...this.props}/>),
        'Allotments': (<Allotments {...this.props}/>),
        'Electronic Return Labels': (<ElectronicReturnLabels {...this.props}/>),
        'default': supply
      };
      return (tabs[type] || tabs['default']);
    };

    /* istanbul ignore else */
    if (visibilityFilter.filter) {
      displayContent = tabFilter(visibilityFilter.filter.value);
      hideSearchBar = (visibilityFilter.filter.value !== 'Order History');
    }

    const searchBar = (
      <SearchBarAccessory {...this.props} searchPlaceholder={t('Search')} />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: hideSearchBar
      },
    ];

    return (
      <section className='accountSupplies'>
        <Page
          accessories={pageAccessories}
          title={t('SuppliesPage.Title')}
        >
          <div className='pageScrollableArea accountSuppliesWrapper flexContainerResponsiveLayout'>
          <FilterPanel
            {...this.props}
            className='filterPanel'
            defaultIcon=''
            filterData={filterData}
            selectFilterCallback={this.handleFilterSelection}
          />
          <div className='supply_content'>
            {displayContent}
          </div>
        </div>
        </Page>
      </section>
    );
  }
}

Supplies.propTypes = {
  dispatch: PropTypes.func,
  shoppingCart: PropTypes.object,
  visibilityFilter: PropTypes.object,
  t: PropTypes.func.isRequired
};
