/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { getOrderHistory } from '../../../actions/shoppingCartActions';
import OrderHistoryDetails from './OrderHistoryDetails';
import TableHeader from '../../shared/TableHeader';
import NoData from '../../NoData';
import Loading from '../../Loading';
import numeral from 'numeral';
import moment from 'moment';
import MobileUtil from '../../util/MobileUtil';

export default class OrderHistory extends Component {
  constructor(props) {
    super(props);

    this.clickOrder = this.clickOrder.bind(this);
    this.clickClose = this.clickClose.bind(this);

    this.state = {
      activeOrder: {},
      isOpen: false
    };
  }

  clickOrder(order) {
    this.setState({activeOrder: order, isOpen: true});
    window.scrollTo(0,0);
  }

  clickClose() {
    this.setState({isOpen: false});
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    props.dispatch(getOrderHistory(props.user))
  }

  render() {

    const {isFetching, shoppingCart, t} = this.props;
    const isMobile = MobileUtil.isMobileDevice();
    const isEmpty = shoppingCart.orders && shoppingCart.orders.data === null;
    const SHIPPING_TYPE = 'SHIPPING';

    if (isEmpty && isFetching) {
      return (
        <Loading/>
      );
    } else {

      const renderOrders = shoppingCart.orders.filteredData && shoppingCart.orders.filteredData.length > 0
        ? (
          <div className='itemsList orderList'>
            <div className='itemRow itemHeader'>
              <div className='itemItems'>
                <TableHeader
                  {...this.props}
                  tableId={'orders'}
                  className='itemColumn'
                  name={t('OrderHistoryTable.OrderIdLabel')}
                  clickable={true}
                  attribute={'id'}
                  headerId={'id'}
                />
                {!isMobile ? <TableHeader
                    {...this.props}
                    tableId={'orders'}
                    className='itemColumn'
                    name={t('OrderHistoryTable.ItemsOrderedLabel')}
                    clickable={true}
                    attribute={'supplies.length'}
                    headerId={'supplies.length'}
                  />: null}
                <TableHeader
                  {...this.props}
                  tableId={'orders'}
                  className='itemColumn'
                  name={t('OrderHistoryTable.OrderDateLabel')}
                  clickable={true}
                  attribute={'order_date'}
                  headerId={'order_date'}
                />
                {!isMobile ? <TableHeader
                  {...this.props}
                  tableId={'orders'}
                  className='itemColumn'
                  name={t('OrderHistoryTable.ShippingDateLabel')}
                  clickable={true}
                  attribute={'ship_date'}
                  headerId={'ship_date'}
                />: null}
                {!isMobile ? <TableHeader
                    {...this.props}
                    tableId={'orders'}
                    className='itemColumn'
                    name={t('OrderHistoryTable.TrackingLabel')}
                    clickable={true}
                    attribute={'tracking_number'}
                    headerId={'tracking_number'}
                  />: null}
                <TableHeader
                  {...this.props}
                  tableId={'orders'}
                  className='itemColumn'
                  name={t('OrderHistoryTable.OrderTotalLabel')}
                  clickable={true}
                  attribute={'merchant_amount'}
                  headerId={'merchant_amount'}
                />
              </div>
            </div>
            {shoppingCart.orders.filteredData.map((order, index) => {
              
              const unitsAmount = (order?.supplies || []).reduce((acc, supply) =>
                acc + ((supply.supply_name !== SHIPPING_TYPE && supply.quantity) ?? 0), 0);
              
              return (
                <div key={index} className='itemRow' onClick={() => this.clickOrder(order)}>
                  <div className='itemItems'>
                    <div className='itemColumn'>
                      <div className='itemColumnItem'>{t('OrderHistoryPage.OrderId', {id: order.id})}</div>
                    </div>
                    {!isMobile ? <div className='itemColumn'>
                      <div
                        className='itemColumnItem'>{`${unitsAmount} ${t('OrderHistoryPage.Items')}`}</div>
                    </div> : null}
                    <div className='itemColumn'>
                      <div className='itemColumnItem'>
                        {moment(order.order_date).format('MM/DD/YY')}
                      </div>
                    </div>
                    {!isMobile ? <div className='itemColumn'>
                      <div className='itemColumnItem'>
                        {order.ship_date ? moment(order.ship_date).format('MM/DD/YY') : null}
                      </div>
                    </div> : null}
                    {!isMobile ? <div className='itemColumn'>
                      <div className='itemColumnItem trackingNumber'>
                        {order.tracking_number ? order.tracking_number : null}
                      </div>
                    </div> : null}
                    <div className='itemColumn'>
                      <div className='itemColumnItem'>{numeral(order.merchant_amount).format('$0.00')}</div>
                    </div>
                  </div>
                </div>);
              })}
          </div>
        )
        : (<NoData text={t('OrderHistoryPage.NoOrdersFoundLabel')}/>);
      
      const renderHistory = shoppingCart.orders.filteredData
        ? <OrderHistoryDetails
          accountProfile={this.props.accountProfile}
          orderInfo={this.state.activeOrder}
          clickClose={this.clickClose}
          shouldDisplay={this.state.isOpen}
          t={t}
        />
        : <div/>;
      
      return (
        <section className='orderHistoryContainer'>
          <div className={this.state.isOpen ? 'orderHistoryList orderHistoryListOpen' : 'orderHistoryList'}>
            <div className='titleContainer'>
              <div className='titlePanel'>
                <div className='title'>
                  <div className='orderTitle hideTablet'>{t('Order History')}</div>
                  <div
                    className='orders'>{`${shoppingCart.orders.filteredData && shoppingCart.orders.filteredData.length} ${t('OrderHistoryPage.Orders')}`}</div>
                </div>
              </div>
            </div>
            {renderOrders}
          </div>
          {renderHistory}
        </section>
      );
    }
  }
}

OrderHistory.defaultProps = {
  isFetching: true
};
