/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */


import React, { Component } from 'react';
import { getDevices, setDevice } from '../../actions/devicesActions';
import { getUserProfile } from '../../actions/userProfileActions';
import { grantAgentAccess } from '../../actions/userActions';
import FilterPanel from '../shared/FilterPanel';
import MasterDetailLayout from '../MasterDetailLayout';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHeader from '../shared/TableHeader';
import NoData from '../NoData';
import moment from 'moment';
import DeviceStatusIndicator from './devices/DeviceStatusIndicator';
import IconUtil from '../util/IconUtil';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DeviceDetails from './devices/DeviceDetail';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import Toggle from '../shared/Toggle';
import UserUtil from '../util/UserUtil';
import MessageDialog from '../shared/MessageDialog';
import { withTranslate } from '../../hoc/withTranslate';

export class DevicesComponent extends Component {

  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.changePermission = this.changePermission.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

    this.state = {
      selectedIndex: 'none',
      permissionGranted: false,
      openDialog: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { user } = this.props;
    const activeAccount = UserUtil.getActiveAccount(user);
    this.setState({permissionGranted: UserUtil.getAgentStatus(activeAccount.agent_access_status)});
    if (!UserUtil.isNorthDemoAccount(user)) {
      this.props.dispatch(getDevices(user));
    }
  }


  showDetailForRow(row) {
    if (row) {
      const selectedIndex = row - 1; // Title Panel is row zero
      this.setState({selectedIndex: selectedIndex});
      this.props.dispatch(setDevice(this.props.devices.filteredData[selectedIndex]));
    } else {
      this.setState({selectedIndex: null});
    }

  }

  openDialog() {
    this.setState({openDialog: true, permissionGranted: !this.state.permissionGranted})
  }

  closeDialog() {
    this.setState({openDialog: false, permissionGranted: !this.state.permissionGranted})
  }

  changePermission() {
      let value;
      if(this.state.permissionGranted) {
        value = 'APPROVED';
      } else {
        value = 'DECLINED';
      }
      this.setState({openDialog: false});
      this.props.dispatch(grantAgentAccess(this.props.user, value));
      this.props.dispatch(getUserProfile(this.props.user));
  }

  render() {
    const { user, auth, devices, userExperience, t } = this.props;
    const isLoading = devices.isFetching || userExperience.isFetching;

    const denyTitle = t('DevicesPage.DenyTitle');
    const approveTitle = t('DevicesPage.ApproveTitle');

    const denyText = t('DevicesPage.DenyText');
    const approveText = t('DevicesPage.ApproveText');

    const okButtonConfirm = t('DevicesPage.OkButtonConfirm');
    const closeButtonConfirm = t('DevicesPage.CloseButtonConfirm');

    const okButtonDeny = t('DevicesPage.OkButtonDeny');
    const closeButtonDeny = t('DevicesPage.CloseButtonDeny');

    const dialogTitle = this.state.permissionGranted ? approveTitle : denyTitle;
    const dialogText = this.state.permissionGranted ? approveText : denyText;

    const dialogConfirmButton =  this.state.permissionGranted ? okButtonDeny : okButtonConfirm;
    const dialogCloseButton = this.state.permissionGranted ? closeButtonDeny : closeButtonConfirm;
    const searchBar = (
      <SearchBarAccessory {...this.props}
          searchPlaceholder={t('DevicesPage.SearchDevices')}
      />
    );
    const filterPanel = (
      <FilterPanel
          filterData={this.props.settingsMenuItems}
          selectFilterCallback={this.props.handleMenuSelection}
          {...this.props}
      />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
    ];

    const toggleInput = {
      value: this.state.permissionGranted,
      onChange: this.openDialog
    };

    const isCsUser = UserUtil.isUserCS(user, auth);
    const isAgentMid = UserUtil.isAgentMid(user);

    const showAgentDevicePermissionToggle = isAgentMid && (UserUtil.isOwner(user) || auth.isSpr);

    const devicesPermissionAgentToggle = showAgentDevicePermissionToggle ? (
      <div className='managePermissions'>
        <div className='toggleHolder'>
          {<Toggle disabled={isCsUser}
              input={toggleInput}
          />}
        </div>
        <div>
          <h3 className='header'>{t('DevicesPage.Header')}</h3>
          <p className='text'>{t('DevicesPage.Description')}</p>
        </div>
      </div>
    ) : null;

    const devicesTableHeader = (<TableRow className={'tableRow'}>
      <TableHeader
          className='hideMobile'
          headerId={'deviceHeaderOne'}
          name={t('Online')}
          type='table'
          {...this.props}
      />
      <TableHeader
          attribute={'hbt_info.device_model'}
          clickable
          headerId={'deviceHeaderTwo'}
          name={t('DevicesPage.DeviceModel')}
          type='table'
          {...this.props}
      />
      <TableHeader
          attribute={'hbt_info.device_type'}
          className='hideMobile'
          clickable
          headerId={'deviceHeaderThree'}
          name={t('DevicesPage.DeviceType')}
          type='table'
          {...this.props}
      />
      <TableHeader
          attribute={'paVersion'}
          className='hideMobile'
          clickable
          headerId={'deviceHeaderFour'}
          name={t('DevicesPage.PaVersion')}
          type='table'
          {...this.props}
      />
      <TableHeader
          attribute={'serialNumber'}
          className='hideMobile'
          clickable
          headerId={'deviceHeaderFive'}
          name={t('DevicesPage.Serial', { serialNumber: '' })}
          type='table'
          {...this.props}
      />
      <TableHeader
          attribute={'lastHeartbeat'}
          className='hideMobile'
          clickable
          headerId={'deviceHeaderSix'}
          name={t('DevicesPage.LastHeartbeat')}
          type='table'
          {...this.props}
      />
    </TableRow>);

    const devicesRow = devices.filteredData && !!devices.filteredData.length ?
      devices.filteredData.map((device, i) => {
          const tableRowClassName = (this.state.selectedIndex === i) ? 'tableRow selectedListRow devicesRow' : 'tableRow devicesRow';
          const lastHeartBeatText = device.lastHeartbeat ? moment(device.lastHeartbeat).local().format('MM/DD/YYYY') + ' ' + moment(device.lastHeartbeat).local().format(' h:mm a') : 'N/A';

          return (
            <TableRow className={tableRowClassName}
                key={i}
            >
              <TableCell className='hideMobile'
                  style={{width: '0'}}
              >
                <DeviceStatusIndicator status={device.networkStatus}/>
              </TableCell>
              <TableCell>
                {device?.hbt_info?.device_model}
              </TableCell>
              <TableCell>
                {device?.hbt_info?.device_type === 'iOS' ? IconUtil.getIcon('AppleIcon', '', '24px') : IconUtil.getIcon('AndroidIcon')}
              </TableCell>
              <TableCell>
                {device?.paVersion || 'N/A'}
              </TableCell>
              <TableCell>
                {device?.serialNumber || 'N/A'}
              </TableCell>
              <TableCell>
                {lastHeartBeatText}
              </TableCell>
            </TableRow>
          );
        }
      ) : (<NoData colSpan={6}
          text='No Devices Found'
          type='table'
           />);

    const devicesTable = (
      <TableBody>
        {devicesTableHeader}
        {devicesRow}
      </TableBody>
    );

    const deviceDetail = devices.selectedDevice && (
      <DetailPanel
          confirmationText={t('Save')}
          heading={devices?.selectedDevice?.hbt_info?.device_model}
          isNew={false}
      >
        <DeviceDetails device={devices.selectedDevice}/>
      </DetailPanel>);

    return (
      <section className='accountDevices'>
        <MasterDetailLayout
            detailDisplayCallback={this.showDetailForRow}
            filterPanel={filterPanel}
            forceOpenDetail
            header={devicesPermissionAgentToggle}
            pageAccessories={pageAccessories}
            pageInitialSubpage={t('Devices')}
            pageLoading={isLoading}
            pageTitle={t('Business Settings')}
            tableData={devices.filteredData}
            tableRowLayout={devicesTable}
            {...this.props}
        >
          {deviceDetail}
        </MasterDetailLayout>
        <MessageDialog
            bodyText={dialogText}
            cancelText={dialogCloseButton}
            confirmText={dialogConfirmButton}
            isChoiceRequired
            onConfirm={this.changePermission}
            onRequestClose={this.closeDialog}
            open={this.state.openDialog}
            titleText={dialogTitle}
        />
      </section>
    );
  }
}

export default withTranslate(DevicesComponent);
