/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

const activationHeaderHeightXS = 85;
const activationHeaderHeightMD = 95;
const reviewAndFinishContentHeight = 535;
const stepContentMaxWidth = 1160;

export const activateFlowStyles = (theme) => ({
  minHeight: '100vh',

  '.iconButton': {
    backgroundColor: 'common.lightGray',
    textAlign: 'center',
    overflow: 'hidden',
    color: 'common.black',
    width: '40px',
    height: '40px',

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: '50px',
      height: '50px'
    },

    '& span:first-of-type': {
      display: 'flex'
    },

    '&.prevButton': {
      position: 'absolute',
      left: `calc((100vw - ${stepContentMaxWidth}px) / 2 - 125px)`,
      display: 'none',

      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        display: 'flex',
        marginTop: '28px'
      },

      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        marginTop: '68px'
      },

      [theme.breakpoints.down(theme.breakpoints.values.sizeDesktopMax)]: {
        left: '15px'
      }
    }
  },

  '.flowContainer': {
    height:`calc(100vh - ${activationHeaderHeightXS}px)`,
    overflowY: 'auto',
    margin: 0,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      height: `calc(100vh - ${activationHeaderHeightMD}px)`
    },

    [theme.breakpoints.down(theme.breakpoints.values.lg)] : {
      '.stepActions': {
        display: 'none'
      },

      '.nestedStepActions': {
        display: 'block',
        mb: '20px'
      }
    },
  },

  '.nestedStepActions': {
    display: 'none',
    textAlign: 'right',

    'button:last-child': {
      mr: 0
    },

    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
      '.action': {
        width: '100%'
      },
    }
  },

  '.stepInnerContainer, .stepActions': {
    maxWidth: `${stepContentMaxWidth}px`,
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box'
  },

  '.stepInnerContainer': {
    pb: '20px',

    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      p: '0 18px 20px 18px'
    }
  },

  '.stepContainer': {
    overflow: 'auto',
    padding: '25px 0 18px',
    userSelect: 'none',

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      padding: '30px 85px 10px',
      margin: 0,
    },

    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      padding: '30px 70px 10px 70px',
    },

    '&__title': {
      color: 'common.black',
      fontFamily: 'Century Gothic, Arial',
      fontWeight: 700,
      fontSize: 22,
      margin: '0 0 15px 15px',

      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        fontSize: 37,
        margin: 0
      },

      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        margin: '40px 0 0 0'
      },

    },

    '&__subtitle': {
      color: 'common.subtext',
      fontSize: 13,
      margin: '0 0 25px 15px',

      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        margin: 0,
        fontSize: 14
      },

    },

    '&__content': {

      [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        marginTop: '30px'
      },

      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        marginTop: '50px'
      },

      '.error': {
        color: 'red',
        marginLeft: '5px'
      }
    }
  },

  '.stepActions': {
    textAlign: 'right',

    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
      width: '97%'
    },

    '.nextButton': {
      pb: 4,
      mr: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
        width: '100%'
      },
    },

    '.action': {
      minWidth: '277px',

      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMin)]: {
        width: '100%'
      },
    },

    '.buttonFlex': {
      minWidth: 160,
      justifyContent: 'space-between'
    }
  },

  '.selectField': {
    minHeight: '48px'
  },

  '.secondTitle': {

    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '.tooltipHolder': {
      position: 'initial'
    }

  },

  '.inputDescription': {
    padding: '0 0 40px',
    margin: 0,
    color: '#666F72'
  },

  '.selectHint': {
    color: '#ff0000'
  },

  '.accountType': {
    marginLeft: 0
  },

  '.firstRowInput': {
    marginLeft: 0
  },

  '.step1, .step2': {

    '&.flowContainer': {

      [theme.breakpoints.up(theme.breakpoints.values.lg)] : {
        flexDirection: 'row',
        maxWidth: 'none',
        justifyContent: 'center',

        '.stepActions': {
          minHeight: '600px',
          boxSizing: 'border-box'
        }
      },

    },

    '.stepContainer': {

      [theme.breakpoints.down(theme.breakpoints.values.lg)] : {
        flex: 'initial',
      },

      [theme.breakpoints.up(theme.breakpoints.values.lg)] : {
        flex: '3',
        overflow: 'initial',
        maxWidth: '840px',
      },

    },

    '.stepActions': {

      [theme.breakpoints.down(theme.breakpoints.values.md)] : {
        padding: '0 0 30px 0',
      },

      [theme.breakpoints.down(theme.breakpoints.values.lg)] : {
        padding: '0 0 40px 0',
        flex: 'initial',
      },

      [theme.breakpoints.up(theme.breakpoints.values.lg)] : {
        padding: '40px 0',
        flex: '0',
        position: 'sticky',
        top: 0,
        textAlign: 'left',
        margin: 0,

        'div:first-of-type': {
          [theme.breakpoints.downVertical(830)]: {
            marginTop: '40px',
          },
          [theme.breakpoints.downVertical(715)]: {
            marginTop: 0,
          }
        }
      },

      '.nextButton': {

        [theme.breakpoints.down(theme.breakpoints.values.lg)] : {
          padding: 0
        },

        [theme.breakpoints.up(theme.breakpoints.values.lg)] : {
          padding: '32px 0 32px 32px',
          marginRight: 0
        },

      }

    }

  },

  '.step4': {
    '.stepActions': {
      display: 'none'
    },

    '.nestedStepActions': {
      display: 'block',
      mb: '20px'
    }
  },

  '.step5': {

    '&.flowContainer': {
      '.stepActions': {
        display: 'block'
      }
    },

    '.terms': {
      height: `calc(100vh - ${activationHeaderHeightXS + reviewAndFinishContentHeight}px)`,
      minHeight: '200px'
    },

    '> .stepActions': {
      display: 'none'
    }

  },

  '.mobileDevice &': {

    '.flowContainer': {
      height: {xs: `calc(100svh - ${activationHeaderHeightXS}px)`, md: `calc(100svh - ${activationHeaderHeightMD}px)`},
    },

    '.step5 .terms': {
      height: `calc(100svh - ${activationHeaderHeightXS + reviewAndFinishContentHeight}px)`,
    }

  }

});

export const activateHeaderStyles = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'common.white',
  borderBottom: '0.5px solid',
  borderColor: 'common.rule',
  padding: { xs: '0 10px', md: '0 30px' },
  height: { xs: activationHeaderHeightXS, md: activationHeaderHeightMD },
  boxSizing: 'border-box',
  mb: '0',
  zIndex: 1,

  '&.firstStep': {
    '.divider': {
      display: 'block'
    },

    '.logoSmall': {
      display: { xs: 'block', md: 'none' },
      ml: 0,
      cursor: 'pointer'
    },
  },

  '.logo': {
    width: '100px',
    ml: 0,
    display: { xs: 'none', md: 'block' },
    cursor: 'pointer'
  },

  '.logoSmall': {
    display: 'none',
    width: 55
  },

  '.divider': {
    display: { xs: 'none', md: 'block' },
    height: '35px',
    backgroundColor: 'common.rule'
  },

  '.title': {
    fontSize: '15px',
    color: 'common.black',
    fontWeight: 600,
    flex: 1
  },

  '.backButton': {
    display: { xs: 'flex', md: 'none' }
  }
};

export const addHardwareStyles = {
  '.showMoreButton, .showLessButton': {
    color: 'common.borderBlue',
    my: '15px',
    height: '30px !important',
    borderRadius: '15px',
    lineHeight: 'normal',
    p: '10px 6px !important'
  }
};

export const addHardwareDeviceCardContainerStyles = {
  borderColor: 'transparent',
  borderWidth: '4px',
  borderStyle: 'solid',
  borderRadius: '8px',
  '&.selected': {
    borderColor: 'primary.main',
    '.deviceCardContent': {
      backgroundColor: 'white',
    },
    '.deviceCardActions': {
      backgroundColor: 'white',
    }
  },
};
export const productDetailsStyles = (theme) => ({
  mb: 1,
  '.productDetails': {
    paddingTop: 0,
    height: {
      sizeMobileMin: 'auto',
      sizeTabletMin: '265px'
    }
  },
  '.productDetail': {
    px: 0,
    fontSize: '15px',
    alignItems: 'flex-start',

    [theme.breakpoints.between('sizeTabletMin', 'sizeDesktopMedium')]: {
      pb: '4px'
    },

    '& .MuiListItemIcon-root': {
      minWidth: 'auto'
    },

    '.productIcon': {
      marginRight: '8px',
    },

    '.productDetailText': {
      margin: 0
    }
  }
});

export const addHardwareDeviceCardStyles = {
  borderRadius: '8px',
  backgroundColor: 'common.featureBgcolor',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '.deviceCardContent': {
    p: 4,
    pb: 2
  },
  '.deviceCardContentHeader': {
    borderBottom: '1px solid',
    borderColor: 'rgba(110, 110, 110, 0.3)',
    minHeight: '55px',
    marginBottom: '24px',
    paddingBottom: '16px',
    '.fees': {
      minWidth: '115px'
    },
    '.title': {
      fontSize: '22px',
      alignSelf: 'flex-start'
    },
    '.price': {
      fontSize: '15px',
      mt: '3px'
    },
    '.fee': {
      color: '#666f72',
      fontSize: '12px',
    },
  },
  '.deviceCardMedia': {
    backgroundSize: 'contain',
    backgroundColor: 'common.cardMediaBgcolor',
    height: '206px',
  },
  '.deviceCardActions': {
    px: '10px',
    pb: '28px',
    minHeight: '72px',

    '.action': {
      ml: '18px'
    },

    '.removeHardware': {
      backgroundColor: 'transparent',
      ml: '5px',
      color: 'primary.main'
    }
  }
};

export const getActivateButtonStyles = (includeLabel, light, shouldHaveDisabledStyle, isMobile) => theme => {
  return {
    gap: 3,
    backgroundColor: light ? theme.palette.common.white : 'primary.main',
    textTransform: 'none',
    span: {
      color: `${light ? theme.palette.primary.main : theme.palette.common.white } !important`,
    },
    svg: {
      fill: light ? theme.palette.primary.main : theme.palette.common.white
    },
    '&.raisedButton:hover': {
      backgroundColor: light ? theme.palette.common.lightGray : 'primary.light',
    },
    ...(!includeLabel && {
      width: '50px',
      minWidth: 'auto',
      justifyContent: 'center',
      gap: 0,
      '.MuiButton-endIcon': {
        marginRight: 0,
        marginLeft: 0,
      }
    }),
    ...(isMobile && {
      '.MuiButton-endIcon': {
        marginRight: 0,
        marginLeft: 0,
      }
    }),
    ...(shouldHaveDisabledStyle && {
      backgroundColor: light ? 'primary.main': theme.palette.common.white,
      span: {
        color: `${theme.palette.common.white} !important`,
        opacity: '1 !important'
      }
    })
  }
};

export const inputContainer = (theme) => ({
  display: {xs: 'block', md: 'flex'},
  '.textField.large .textFieldHolder': {
    backgroundColor: theme.palette.common.featureBgcolor,
  }
});

export const grayInput = (theme) => ({
  '.textFieldInfo': {
    label: theme.mergeStyles(
      theme.sharedStyles.responsiveFontSize(theme, 16, { sizeMobileMax: 16 }),
      {
        pl: 2
      }
    ),
  },
  '.textFieldHolder': {
    backgroundColor: theme.palette.common.featureBgcolor,

    '&.selectField .MuiInputBase-root': {
      top: '0px'
    },

    '.MuiInputBase-root': {
      '.MuiInputBase-input:-webkit-autofill': {
        WebkitBoxShadow: 'none',
      }
    }
  },

  '.information': {
    color: 'common.errorColor',
    marginTop: 0,
    marginBottom: '32px'
  }
});

export const largeInput = (theme) => theme.mergeStyles(
  {
    flex: 3,
  },
  grayInput(theme),
);

export const smallInput = {
  flex: 1.5,
  marginLeft: {xs: 0, md: 3},

  ...grayInput
};

export const getToKnowYouStyles = {
  background:'url(/images/activate/get_to_know_you.png)',
  backgroundSize: '300px 500px',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: '0px',
  width: '300px',
  mt: { md: 0, lg: 19 },
  height: { md: '0px', lg: '450px' }
}

export const yourBusinessImageStyles = {
  background:'url(/images/activate/your_business.png)',
  backgroundSize: '300px 500px',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: '0px',
  width: '300px',
  mt: { md: 0, lg: 19 },
  height: { md: '0px', lg: '450px' }
}

export const label = {
  fontFamily: 'inter',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '16px',

  'span': {
    fontSize: '17px',
  }
}

export const formControlLabel = {
  width: 'fit-content'
};

export const activationBusinessFormStyles = (theme) => ({
  '.taxIdContainer': {
    '.textFieldHolder div:not(.MuiAutocomplete-popper)': {
      width: 'unset !important'
    },
    '.tooltip': {
      marginLeft: 'auto'
    }
  },
  '&#activationBusinessForm .textField .textFieldHolder': {
    backgroundColor: theme.palette.common.featureBgcolor,
    '&.selectField': {
      backgroundColor: theme.palette.common.featureBgcolor,
      height: '64px'
    },
  }
});

export const pdfViewerStyles = {
  height: '100%',
  overflow: 'auto',

  'canvas': {
    margin: '0 auto',
    width: '100%',
    minWidth: '600px'
  }
};
