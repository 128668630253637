/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TerminalSupplies from './TerminalSupplies';
import CheckoutSupply from './CheckoutSupply';
import OrderSummary from './OrderSummary';
import NoData from '../../NoData';
import {
  addToCart,
  clearCart,
  getShippingRates,
  performCartAction,
  removeFromCart,
  updateCart,
  userSuppliesRequest,
  setTotalOrderAmount
} from '../../../actions/shoppingCartActions';
import Loading from '../../Loading';
import UserUtil from '../../util/UserUtil';
import Badge from '@mui/material/Badge';
import _ from 'lodash';
import ShoppingCartUtil from '../../util/ShoppingCartUtil';
import MobileUtil from '../../util/MobileUtil';
import Button from '../../shared/Button';
import Modal from '../../shared/Modal';
import IconUtil from '../../util/IconUtil';
import numeral from 'numeral';

export default class Supply extends Component {
  constructor(props) {
    super(props);

    this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
    this.getShippingRequest = this.getShippingRequest.bind(this);
    this.updateItemQuantity = this.updateItemQuantity.bind(this);
    this.closeSuccessDialog = this.closeSuccessDialog.bind(this);
    this.openSuccessDialog = this.openSuccessDialog.bind(this);
    this.closeSummary = this.closeSummary.bind(this);
    this.openSummary= this.openSummary.bind(this);
    this.clearCart = this.clearCart.bind(this);

    this.state = {
      showOrderSummaryDialog: false,
      showCheckoutDialog: false,
      agreeUser: false,
      showSuccessDialog: false
    };
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    props.dispatch(userSuppliesRequest(props.user));
  }

  updateItemQuantity(product, quantity) {

    if (quantity === 0) {
      this.props.dispatch(removeFromCart(product));
    } else {

      let productId = product.id ? product.id : product.supply_id;

      let cartProduct = this.props.shoppingCart.cart.productHashes[productId];

      if (cartProduct) {
        this.props.dispatch(updateCart(product, quantity));
      } else {
        this.props.dispatch(addToCart(product, quantity));
      }
    }
    this.props.dispatch(performCartAction());
  }

  clearCart() {
    this.props.dispatch(setTotalOrderAmount(0));
    this.props.dispatch(clearCart());
    this.props.dispatch(performCartAction());
  }

  handleCheckoutClick() {
    this.setState({showCheckoutDialog: !this.state.showCheckoutDialog});
  }

  getShippingRequest() {

    const {accountProfile, shoppingCart, user} = this.props;
    const {cart, allotments} = shoppingCart;

    const isPremium = UserUtil.isAnyPremiumAccount(user);

    if (cart && cart.productIds && cart.productIds.length > 0
      && allotments && allotments.data && allotments.data.length > 0) {

      let totalShippingWeight = 0, totalShippingWeightPremium = 0;

      _.forEach(cart.productIds, (productId) => {

        let cartProduct = cart.productHashes[productId];
        let cartProductQuantity = cartProduct.quantity;
        let supplyInfo = _.find(allotments.data, {'supply_id': cartProduct?.product?.supply_id,});

        const individualSupplyWeight = supplyInfo?.size_info?.weight || 0.01;
        const supplyWeightUnits = supplyInfo?.size_info?.weight_units || 'lb';
        const freeSuppliesRemaining = supplyInfo?.supplies_remaining || 0;
        const totalSupplyWeight = ShoppingCartUtil.calculateShippingWeight(individualSupplyWeight, cartProductQuantity, supplyWeightUnits);

        totalShippingWeight = totalSupplyWeight + totalShippingWeight;

        if (isPremium) {

          if (freeSuppliesRemaining < cartProductQuantity) {
            const cartProductQuantityToPayShippingFor = cartProductQuantity - freeSuppliesRemaining;
            const totalSupplyWeightPremium = ShoppingCartUtil.calculateShippingWeight(individualSupplyWeight, cartProductQuantityToPayShippingFor, supplyWeightUnits);
            totalShippingWeightPremium = totalSupplyWeightPremium + totalShippingWeightPremium;
          }

        }

      });

      const accountProfileData = accountProfile?.data || {};

      const shippingRequirements = {
        toStreetAddress1: accountProfileData?.shipping_address_1 ?? '',
        toStreetAddress2: accountProfileData?.shipping_address_2 ?? '',
        toCity: accountProfileData?.shipping_city ?? '',
        toState: accountProfileData?.shipping_state ?? '',
        toPostalCode: accountProfileData?.shipping_zip ?? '',
        toCountryCode: 'US',
        packageType: '02'
      };

      if (isPremium && totalShippingWeightPremium > 0) {
        shippingRequirements['packageWeight'] = totalShippingWeightPremium;
        this.props.dispatch(getShippingRates(this.props.user, shippingRequirements, 'Premium'));
      } else {
        shippingRequirements['packageWeight'] = totalShippingWeight;
        this.props.dispatch(getShippingRates(this.props.user, shippingRequirements));
      }
    }

  }

  closeSuccessDialog() {
    this.setState({showSuccessDialog: false});
    this.props.dispatch(setTotalOrderAmount(0));
  }

  openSuccessDialog() {
    this.setState({showSuccessDialog: true});
  }

  openSummary() {
    this.setState({showOrderSummaryDialog: true});
  }

  closeSummary() {
    this.setState({ showOrderSummaryDialog: false });
    this.props.dispatch(setTotalOrderAmount(0));
  }

  render() {
    const {showCheckoutDialog} = this.state;
    const {shoppingCart, user, auth, t} = this.props;
    const {cart, allotments, totalOrderAmount} = shoppingCart;
    const enablePay = cart && cart.productIds && cart.productIds.length > 0;
    const isCsUser = UserUtil.isUserCS(user, auth);
    const isPa = UserUtil.userType(user) === 'PA';
    const shoppingCartIcon = IconUtil.getIcon('ShoppingCart', '#888C8D');

    let showNoSupplies = false;

    if (isPa) {
      showNoSupplies = true;

      if (shoppingCart.equipment && shoppingCart.equipment.data) {
        shoppingCart.equipment.data.map((terminal) => {
          if (terminal.supplies && terminal.supplies.length > 0) {
            showNoSupplies = false
          }
        })
      }
    }

    if (!shoppingCart.equipment || shoppingCart.equipment.isFetching) {
      return (
        <Loading/>
      );
    } else if (showNoSupplies) {
        return (
          <NoData className='noSuppliesForTerminal' text={t('SuppliesPage.NoData')} />
        )
    } else {

      const successDialog = (
        <Modal
          open={this.state.showSuccessDialog}
          confirmText={t('Ok')}
          onConfirm={this.closeSuccessDialog}
          onClose={this.closeSuccessDialog}
          hideCancelButton
        >
          <div>
            <h2>{t('SuppliesPage.OrderProcessed')}</h2>
          </div>
        </Modal>
      );

      const orderSummaryContainer = (
        <OrderSummary
          updateItemQuantity={this.updateItemQuantity}
          clearCart={this.clearCart}
          closeCart={this.closeSummary}
          handleCheckoutClick={this.handleCheckoutClick}
          isSupply={true}
          shouldDisplay={this.state.showOrderSummaryDialog}
          totalOrderAmount={totalOrderAmount}
          {...this.props}/>
      );

      const shoppingCartAmount = cart && cart.productHashes ? ShoppingCartUtil.getAmountOfProductsInCart(cart.productHashes) : 0;
      const shoppingCartTotalAmount = cart && cart.productIds && allotments && allotments.data ? ShoppingCartUtil.calculateOrderAmount(
        cart.productIds,
        cart.productHashes,
        allotments,
        0) : 0;

      return (
        <section className='supplyContainer'>
          <div className='terminalsAndSupplies'>
            <TerminalSupplies
              updateItemQuantity={this.updateItemQuantity}
              {...this.props} />
            <div className='cartContainer'>
              <div className='amountContainer'>
                <div className='totalAmount'>
                  <p>{t('SuppliesPage.Total', { shoppingCartTotalAmount: numeral(shoppingCartTotalAmount).format('$0.00') })}</p>
                </div>
                <Badge
                    badgeContent={<span style={{color: '#fff'}}>{shoppingCartAmount}</span>}
                    className='cartQuantity'
                    color='primary'
                    showZero
                >
                  {shoppingCartIcon}
                </Badge>
              </div>
              <div className='actionContainer'>
                <Button
                  disabled={this.state.showOrderSummaryDialog || !enablePay || isCsUser}
                  label={MobileUtil.isMobileDevice() ? t('SuppliesPage.OrderSummaryDialogButton', { shoppingCartTotalAmount: numeral(shoppingCartTotalAmount).format('$0.00') }) : t('OrderSummaryPage.OrderSummaryTitle')}
                  onClick={this.openSummary}
                />
              </div>
            </div>
          </div>
          {orderSummaryContainer}
          <CheckoutSupply
            showCheckoutDialog={showCheckoutDialog}
            clearCart={this.clearCart}
            loadSupplies={this.loadData}
            getShippingRequest={this.getShippingRequest}
            handleCheckoutClick={this.handleCheckoutClick}
            openSuccessDialog={this.openSuccessDialog}
            closeCart={this.closeSummary}
            {...this.props}
          />
          {successDialog}
        </section>
      );
    }
  }
}

Supply.propTypes = {
  clearCart: PropTypes.func,
  dispatch: PropTypes.func,
  shoppingCart: PropTypes.object,
  user: PropTypes.object,
  t: PropTypes.func.isRequired
};
