/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import Box from '@mui/material/Box';

import Modal from '../../shared/Modal';

import TwoFactorForm from '../../account/TwoFactorForm';
import TwoFactorVerificationForm from '../../account/TwoFactorVerificationForm';
import TwoFactorVerificationFailedDialog from './TwoFactorAuthenticationFailedDialog';

import twoFactorCodeStatusUtil from '../../util/twoFactorCodeStatusUtil';
import LabelUtil from '../../util/LabelUtil';
import IdentityVerificationUtils from '../../util/IdentityVerificationUtil';

import {
  getPhoneVerificationCode,
  getTwoFactorToken,
  logoutUser,
  resetTwoFactorAuthCodeStatus,
  resetTwoFactorAuthPhoneStatus,
  show2FAError,
  updatedTwoFactorForm,
  validatePhoneVerificationCode,
  verifyTwoFactorToken
} from '../../../actions/authActions';
import { verifyIdentity } from '../../../actions/identityVerificationActions';
import {
  setModalVisibility,
  suppressAppError
} from '../../../actions/userExperienceActions';
import {
  block2FA,
  storeVerification
} from '../../../actions/verificationActions';
import { getAccountProfile } from '../../../actions/accountProfileActions';

import messages from '../../../constants/messages';
import actionTypes from '../../../constants/actionTypes';

import { submit } from 'redux-form';
import MessageDialog from '../../shared/MessageDialog';
import TwoFactorSetupDialog from './TwoFactorSetupDialog';
import Validator from '../../util/Validator';
import { requestCodeStyles } from '../../../jss/twoFactorStyles';
import {toastr} from 'react-redux-toastr';

export const requestTwoFactorToken = (user, t, dispatch, verification) => {
  if (!verification.twoFactorBlocked) {
    return dispatch(getTwoFactorToken(user, true)).then(result => {
       if (result.type === actionTypes.twoFactorSendTokenFailure && result.error === t('Errors.TwoFactor.RateLimit')) {
         dispatch(block2FA());
       }
       return result;
     });
   } else {
     toastr.error('Error', t('Errors.TwoFactor.RateLimit'), { progressBar: false });
   }
}

export default class TwoFactorTools extends Component {

  constructor(props) {
    super(props);

    this.requestCode = this.requestCode.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.submitNoEcpForm = this.submitNoEcpForm.bind(this);
    this.verifyNoEcpCode = this.verifyNoEcpCode.bind(this);
    this.close = this.close.bind(this);
    this.closeAndClear = this.closeAndClear.bind(this);
    this.closeFailure = this.closeFailure.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      userExperience: {
        modalVisibility: {
          twoFactorDialog: prevTwoFactorDialog,
          twoFactorSetupDialog: prevTwoFactorSetupDialog
        }
      }
    } = prevProps;

    const {
      user,
      verification,
      userExperience: {
        modalVisibility: {
          twoFactorDialog,
          twoFactorSetupDialog
        }
      },
      t
    } = this.props;

    const hasPhone = !!(user?.data?.has_cell);
    if (verification.twoFactorBlocked && ((twoFactorDialog && !prevTwoFactorDialog) || (twoFactorSetupDialog && !prevTwoFactorSetupDialog))) {
      toastr.error(t('Toastr.Error.Title'), t('Errors.TwoFactor.RateLimit'), { progressBar: false });
    } else if (hasPhone && twoFactorDialog && !prevTwoFactorDialog) {
      this.requestCode();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updatedTwoFactorForm(null, null));
    dispatch(resetTwoFactorAuthCodeStatus());
    dispatch(resetTwoFactorAuthPhoneStatus());
  }

  requestCode() {
    const { user, t, dispatch, verification } = this.props;
    return requestTwoFactorToken(user, t, dispatch, verification);
  }

  verifyCode({ validation_code }) {
    const { user, dispatch, onVerified, type } = this.props;

    dispatch(suppressAppError(true));
    return new Promise(resolve => {
      dispatch(verifyTwoFactorToken(user, validation_code, type)).then(result => {
        if (result.type === actionTypes.twoFactorVerifyTokenSuccess) {
          dispatch(setModalVisibility('hidden'));
          type && dispatch(storeVerification(type));
          dispatch(updatedTwoFactorForm(null, null));
          dispatch(resetTwoFactorAuthCodeStatus());
          dispatch(resetTwoFactorAuthPhoneStatus());
          onVerified && onVerified(false);
        } else {
          dispatch(setModalVisibility('twoFactorFailedVerificationDialog'));
        }
      }).finally(() => {
        dispatch(suppressAppError(false));
        resolve();
      });
    });
  }

  async verifyNoEcpCode(values) {
    const { user, auth, accountProfile, dispatch, onProcessing, onVerified, type } = this.props;
    onProcessing && onProcessing(true);

    if (!accountProfile?.data) {
      await dispatch(getAccountProfile(user));
    }

    if (IdentityVerificationUtils.validateIdVerificationData(accountProfile, values.cellPhone, user) && !user.data.is_ecp && !twoFactorCodeStatusUtil.isSendingCode(auth.twoFactorCodeStatus) && !twoFactorCodeStatusUtil.isCodeSent(auth.twoFactorCodeStatus)) {
      dispatch(updatedTwoFactorForm(values.cellPhone, null));
      return dispatch(verifyIdentity(accountProfile, values.cellPhone, user)).then(result => {
        if (result.type !== actionTypes.postIdentityVerificationFailure) {
          return dispatch(getPhoneVerificationCode(user, values.cellPhone));
        } else {
          dispatch(suppressAppError(false));
          dispatch(updatedTwoFactorForm(null, null));
          dispatch(setModalVisibility('failedIdentityVerificationDialog'));
        }
      }).finally(() => {
        onProcessing && onProcessing(false);
      });
    } else {
      dispatch(suppressAppError(true));
      return dispatch(validatePhoneVerificationCode(user, values.cellPhone, values.validationCode, undefined, type)).then((res) => {
        if (res.type !== actionTypes.twoFactorPhoneVerifyFailure) {
          dispatch(setModalVisibility('hidden'));
          type && dispatch(storeVerification(type));
          dispatch(updatedTwoFactorForm(null, null));
          dispatch(resetTwoFactorAuthCodeStatus());
          dispatch(resetTwoFactorAuthPhoneStatus());
          onVerified && onVerified(true);
        } else {
          dispatch(setModalVisibility('twoFactorFailedVerificationDialog'));
        }
      }).finally(() => {
        onProcessing && onProcessing(false);
        dispatch(suppressAppError(false));
      });
    }
  }

  submitForm() {
    this.props.dispatch(submit('twoFactorVerificationForm'));
  }

  submitNoEcpForm() {
    this.props.dispatch(submit('twoFactorForm'));
  }

  close() {
    const { dispatch, onClose } = this.props;
    onClose && onClose();
    dispatch(setModalVisibility('hidden'));
  }

  closeAndClear() {
    const { dispatch } = this.props;
    dispatch(resetTwoFactorAuthCodeStatus());
    dispatch(resetTwoFactorAuthPhoneStatus());
    this.close();
  }

  closeFailure() {
    const { dispatch, onClose } = this.props;
    onClose && onClose();
    dispatch(setModalVisibility('hidden'));
    dispatch(logoutUser());
    dispatch(show2FAError());
  }

  render() {
    const {
      user,
      auth,
      t,
      twoFactorFormCellPhone,
      userExperience: {
        modalVisibility: {
          twoFactorDialog,
          twoFactorSetupDialog,
          twoFactorFailedVerificationDialog,
          failedIdentityVerificationDialog
        }
      },
      verification,
      custom = {}
    } = this.props;
    const label = LabelUtil.getLabel();

    const hasPhone = !!(user?.data?.has_cell);
    const buttonDisabled = verification.twoFactorBlocked || (twoFactorCodeStatusUtil.isSendingCode(auth.twoFactorCodeStatus) || twoFactorCodeStatusUtil.isValidatingCode(auth.twoFactorCodeStatus)) || (!hasPhone && !Validator.isPhoneNumber(twoFactorFormCellPhone));

    return (
      <>
        {twoFactorDialog && <Modal
          open
          onClose={hasPhone ? this.closeAndClear : this.close}
          centerTitle={custom.centerTitle}
          isConfirmDisabled={buttonDisabled}
          title={custom.title ? custom.title : t(messages.twoFactorAuth.header)}
          confirmText={t(auth.twoFactorCodeStatus)}
          cancelText={t('Cancel')}
          paperProps={custom.paperProps}
          onConfirm={hasPhone ? this.submitForm : this.submitNoEcpForm}
        >
          {hasPhone ?
            <div id='TwoFactorVerificationForm'>
              <TwoFactorVerificationForm
                  custom={custom}
                  disabled={buttonDisabled}
                  onSubmit={this.verifyCode}
                  phoneNumber={user.data.mobile_number}
                  t={t}
              />

              <Box component={'span'} sx={requestCodeStyles}>
                <span className={classNames('action', { disabled: verification.twoFactorBlocked })} onClick={this.requestCode}>
                  {custom.resendMessage ? custom.resendMessage : t('TwoFactorForm.RequestNewCode')}
                </span>
              </Box>
            </div>
            :
            <div id='TwoFactorForm'>
              <TwoFactorForm
                codeStatus={auth.twoFactorCodeStatus}
                onSubmit={this.verifyNoEcpCode}
                showLoginTwoFAToggle={false}
                blocked={verification.twoFactorBlocked}
                t={t}
              />
            </div>
          }
        </Modal>}

        {twoFactorSetupDialog &&
          <TwoFactorSetupDialog
            onClose={this.close}
            onSetup={this.props.onSetup}
            onProcessing={this.props.onProcessing}
            {...this.props}
          />
        }

        {failedIdentityVerificationDialog &&
          <MessageDialog
            bodyText={
              <p>{t('UnableEditContent', { phoneNumber: label.phoneNumber})}</p>
            }
            confirmText='Ok'
            externalClassName='failedIdologyDialog'
            isChoiceRequired={false}
            onConfirm={this.close}
            onRequestClose={this.close}
            open
            titleText={t('UnableEdit')}
          />
        }

        {twoFactorFailedVerificationDialog &&
          <TwoFactorVerificationFailedDialog
            closeAction={this.closeFailure}
            t={t}
          />
        }
      </>
    );
  }
}

TwoFactorTools.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  onVerified: PropTypes.func,
  onProcessing: PropTypes.func,
  onSetup: PropTypes.func,
};

