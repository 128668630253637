/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { useState } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { Box, MenuItem, Typography } from '@mui/material';
import IconUtils from '../../util/IconUtil';
import Validator from '../../util/Validator';
import Button from '../../shared/Button';
import MapFieldSelector from '../../MapFieldSelector';
import {
  BulkInvoicesMapFieldsStyles
} from '../../../jss/inlineStyles'
import Select from '../../shared/Select';
import { connect } from 'react-redux';

export const BulkInvoicesMapFields = ({
                                        defaultTemplateFields,
                                        userTemplateFields,
                                        t,
                                        handleSubmit,
                                        merchantMappings,
                                        handleManageSavingMappings,
                                        handleSelectMapping,
                                        selectedExistingMapping,
                                        formValues
                                      }) => {
  const [merchantMapping, setMerchantMapping] = useState('');
  const fieldsSelector = defaultTemplateFields.map(defaultField =>
    (
      <MapFieldSelector
        key={defaultField}
        defaultTemplateField={defaultField}
        userTemplateFields={userTemplateFields}
        t={t}
        selectedExistingMapping={selectedExistingMapping}
        formValues={formValues}
      />
    )
  );
  const handleChange = (event) => {
    setMerchantMapping(event.target.value);
    let selectedMapping = null;
    if (Boolean(event.target.value)) {
      selectedMapping = merchantMappings.find(m => m.id === event.target.value);
    }
    handleSelectMapping(selectedMapping);
  }

  return (
    <Box
      data-test-id='bulkMapFields'
      sx={BulkInvoicesMapFieldsStyles.wrapper}
    >
      <Box component='form' sx={BulkInvoicesMapFieldsStyles.formWrapper} onSubmit={handleSubmit}>

        <Box sx={BulkInvoicesMapFieldsStyles.textWrapper}>
          <Typography sx={BulkInvoicesMapFieldsStyles.header}>
            {t('BulkInvoices.BulkInvoicesMapFields.MapFieldsHeader')}
          </Typography>
          <Typography sx={BulkInvoicesMapFieldsStyles.description}>
            {t('BulkInvoices.BulkInvoicesMapFields.MapFieldsDescription')}
          </Typography>
          {Boolean(merchantMappings.length) &&
            <>
              <Select
                label={t('BulkInvoices.BulkInvoicesMapFields.ExistingMappingLabel')}
                value={merchantMapping}
                onChange={handleChange}
              >
                <MenuItem value=''>
                  {t('BulkInvoices.BulkInvoicesMapFields.Manually')}
                </MenuItem>
                {merchantMappings.map((mapping, index) => (
                  <MenuItem value={mapping.id} key={`merchantMapping${index}`}>
                    {mapping.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography
                data-testid='ManageSavedMappingSettingsInstructions'
                sx={BulkInvoicesMapFieldsStyles.instructions}
                onClick={handleManageSavingMappings}
              >
                {t('BulkInvoices.BulkInvoicesMapFields.ManageSavedMappingSettings')}
              </Typography>
            </>
          }
        </Box>

        <Box sx={BulkInvoicesMapFieldsStyles.headersWrapper}>
          <Typography sx={BulkInvoicesMapFieldsStyles.colHeader}>
            {t('BulkInvoices.BulkInvoicesMapFields.MapPaymentHubFields')}
          </Typography>
          <Typography sx={BulkInvoicesMapFieldsStyles.colHeader}>
            {t('BulkInvoices.BulkInvoicesMapFields.MapYourTemplateFields')}
          </Typography>
          <Typography sx={BulkInvoicesMapFieldsStyles.colHeader}>
            {t('BulkInvoices.BulkInvoicesMapFields.MapYourTemplateFieldsPreview')}
          </Typography>
        </Box>

        <Box sx={BulkInvoicesMapFieldsStyles.contentWrapper}>
          {fieldsSelector}
          <Button
            sx={BulkInvoicesMapFieldsStyles.contentWrapperBtn}
            data-test-id='bulkMapFieldsNextBtn'
            endIcon={IconUtils.getIcon('ChevronRightIcon', '#FFF')}
            label={t('BulkInvoices.BulkInvoicesMapFields.NextBtn')}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  )
};

const BulkInvoicesMapFieldsForm = reduxForm({
  form: 'bulkInvoicesMapFieldsForm',
  validate: Validator.validateMapFieldSelector,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false
})(BulkInvoicesMapFields);

export function mapStateToProps(state, ownProps) {
  const {bulkImports} = state;
  if (bulkImports.bulkSelectedExistingMapping) {
    const formattedValues = Object.fromEntries(
      Object.entries(bulkImports.bulkSelectedExistingMapping.mapping).map(([key, value]) => [value, key])
    );
    return {
      initialValues: formattedValues,
      selectedExistingMapping:true,
      formValues: getFormValues('bulkInvoicesMapFieldsForm')(state)
    }
  }
  return {
    initialValues: {},
    selectedExistingMapping:false,
    formValues: {}
  }
}

export default connect(mapStateToProps)(BulkInvoicesMapFieldsForm);
