/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change,
  Field,
  FieldArray,
  getFormValues,
  reduxForm
} from 'redux-form';
import CustomRadio from '../shared/Radio';
import numeral from 'numeral';
import {InputAdornment, MenuItem, Box} from '@mui/material';
import Checkbox from '../shared/Checkbox';
import IconButton from '../shared/IconButton';
import Toggle from '../shared/Toggle';

import FormatTextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import ItemThumbnail from '../ItemThumbnail';
import ItemNoFetchThumbnail from '../ItemNoFetchThumbnail'
import LabelUtil from '../util/LabelUtil';
import Validator from '../util/Validator';
import { roundToTwoDecimals } from '../util/CommonUtil';

import Button from '../shared/Button';
import MessageDialog from '../shared/MessageDialog';
import Select from '../shared/Select';
import TextField from '../shared/TextField';
import Tooltip from '../shared/Tooltip';
import InvoiceUtil from '../util/InvoiceUtil';
import FormUtil from '../util/FormUtil';
import {
  modifierColStyles,
  expressItemToggleLabel
} from '../../jss/inlineStyles';
import UserUtil from '../util/UserUtil';
import {
  expressItemQuantityStyles,
  expressItemsFormStyles
} from '../../jss/addExpressItemStyles';

const normalizeCurrency = (value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 20);
const normalizeTaxRate = (taxRate) => FormatTextUtil.formatTaxRate(taxRate);
const normalizeQuantity = (quantity) => FormatTextUtil.formatItemStockQuantity(quantity);

const validate = (values) => {
  return Validator.validateAddVTItem(values);
};

export class VirtualTerminalFormItemSelectionComponent extends Component {

  constructor(props) {
    super(props);
    this.toggleModifierOption = this.toggleModifierOption.bind(this);
    this.addItemQuantity = this.addItemQuantity.bind(this);
    this.subtractItemQuantity = this.subtractItemQuantity.bind(this);
    this.calculateItemTotal = this.calculateItemTotal.bind(this);
    this.closeOutOfStockDialog = this.closeOutOfStockDialog.bind(this);
    this.confirmOutOfStockDialog = this.confirmOutOfStockDialog.bind(this);
    this.handleAddToOrder = this.handleAddToOrder.bind(this);

    this.state = {
      selectedModifiers: {},
      openOutOfStockDialog: false,
      itemCurrentSubtotal: 0,
      itemCurrentTax: 0,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    const { currentValues: { itemDiscountType } } = this.props;
    const selectedItemId = nextProps.itemizedCart.item_ids[nextProps.selectedItemIndex];

    if (itemDiscountType && nextProps?.currentValues?.itemDiscountType !== itemDiscountType) {
      this.props.dispatch(change('virtualTerminalFormItemSelection', 'itemDiscountValue', ''));
    }

    const isExpressItemSelected = nextProps.isEditingItem
      ? selectedItemId === 'express' || !(nextProps.items?.salesItems?.length > 0 && nextProps.items.salesItems.filter(item => item.id === selectedItemId)[0])
      : nextProps.selectedItemIndex === -1;

    const { itemCurrentSubtotal, itemCurrentTax } = this.calculateItemTotal(nextProps.currentValues, isExpressItemSelected);
    const itemCurrentTotal = numeral(roundToTwoDecimals(itemCurrentSubtotal + itemCurrentTax)).format('$0,0.00');


    if (itemCurrentTotal !== nextProps?.currentValues?.itemTotal) {
      this.props.dispatch(change('virtualTerminalFormItemSelection', 'itemTotal', itemCurrentTotal));
    }

  }

  componentDidMount() {

    const { isEditingItem, itemizedCart, selectedItemIndex, items } = this.props;
    const { selectedItem } = items;
    const selectedItemId = itemizedCart.item_ids[selectedItemIndex];

    const isExpressItemSelected = isEditingItem
      ? selectedItemId === 'express' || !(items?.salesItems?.length > 0 && items.salesItems.filter(item => item.id === selectedItemId)[0])
      : selectedItemIndex === -1;

    if (!isExpressItemSelected) {

      if (!isEditingItem) {

        //If item stock isnt trackable we can set the default selected price to be the first one, otherwise we need to select the first one that isn't out of stock
        const defaultSelectedPrice = selectedItem && selectedItem.details && selectedItem.details.prices && selectedItem.details.prices[0] && (
          !selectedItem.is_trackable
            ? selectedItem.details.prices[0].id
            : selectedItem.details.prices.reduce((result, currentPrice) => {
                return (currentPrice.quantity > 0 && !result) ? currentPrice.id : result;
              }, null)
        );

        this.props.dispatch(change('virtualTerminalFormItemSelection', 'itemPriceId', defaultSelectedPrice));

      } else {

        const selectedModifiers = {};
        itemizedCart.modifier_ids[selectedItemIndex].map(modifierPair => {
          selectedModifiers[modifierPair.setId] = selectedModifiers[modifierPair.setId] || [];
          selectedModifiers[modifierPair.setId].push(modifierPair.modifierId);
        });
        this.setState({selectedModifiers: selectedModifiers});

      }

    }

  }

  toggleModifierOption(modifierId, modifierOptionId, isMultipleSelectionEnabled) {

    const selectedModifiers = Object.assign({}, this.state.selectedModifiers);

    let modifiersArray = selectedModifiers[modifierId] || [];

    if (modifiersArray.includes(modifierOptionId)) {
      modifiersArray = modifiersArray.filter(modifierOption => modifierOption !== modifierOptionId)
    } else {

      if (isMultipleSelectionEnabled) {
        modifiersArray.push(modifierOptionId);
      } else {
        modifiersArray = [modifierOptionId];
      }

    }

    if (_.isEmpty(modifiersArray)) {
      delete selectedModifiers[modifierId]
    } else {
      selectedModifiers[modifierId] = modifiersArray;
    }

    this.setState({
      selectedModifiers: selectedModifiers
    });

  }

  addItemQuantity() {
    const currentValue = parseInt(this.props.currentValues.itemQuantity, 10);
    const newValue =  currentValue ? currentValue + 1 : 1;
    this.props.dispatch(change('virtualTerminalFormItemSelection', 'itemQuantity', newValue));
  }

  subtractItemQuantity() {
    const currentValue = parseInt(this.props.currentValues.itemQuantity, 10);
    if (currentValue > 1) {
      const newValue = currentValue - 1;
      this.props.dispatch(change('virtualTerminalFormItemSelection', 'itemQuantity', newValue));
    }
  }

  //Calculates the total for the selected item, based on its selected quantity, price, modifiers and discounts applied
  calculateItemTotal(currentValues, isExpressItemSelected) {

    const { selectedModifiers } = this.state;
    const { selectedItem } = this.props.items;
    const { itemDiscountValue, itemDiscountType, expressModifiers } = currentValues;
    const taxRate = this.props.taxes.taxRate || 0;
    let itemCurrentSubtotal = 0, itemCurrentTax = 0, modifiersTotal = 0;

    if (expressModifiers?.length > 0) {
      modifiersTotal = expressModifiers.reduce((partialSum, modifier) => roundToTwoDecimals(partialSum + numeral(modifier?.price || 0).value()), modifiersTotal);
    }

    if (!isExpressItemSelected) {

      const selectedPriceObject = selectedItem?.details?.prices.find(price => price.id === currentValues.itemPriceId);

      for (let modifierSetId in selectedModifiers) {
        selectedModifiers[modifierSetId]?.map(modifierId => {

          const modifierSet = selectedItem?.details?.modifierSets.find(modifierSet => modifierSet.id === parseInt(modifierSetId));
          const specificModifier = modifierSet?.modifiers.find(modifier => modifier.id === modifierId);

          modifiersTotal = roundToTwoDecimals(modifiersTotal + roundToTwoDecimals(specificModifier?.price));
        });
      }

      let discountAmount = 0;
      let discountRate = 1;

      if (itemDiscountValue) {
        const discountValue = numeral(itemDiscountValue).value();

        if (itemDiscountType === 'percent') {
          discountRate = 1 - discountValue;
        } else {
          discountAmount = discountValue;
        }
      }

      itemCurrentSubtotal = selectedPriceObject
        ? roundToTwoDecimals(currentValues.itemQuantity * (roundToTwoDecimals(selectedPriceObject.price) + modifiersTotal - discountAmount) * discountRate)
        : 0;

      itemCurrentTax = selectedItem?.is_taxable ? roundToTwoDecimals(itemCurrentSubtotal * taxRate / 100) : 0;

    } else {

      const price = numeral(currentValues.expressItemPrice).value();

      itemCurrentSubtotal = roundToTwoDecimals(roundToTwoDecimals(price + modifiersTotal) * currentValues.itemQuantity);
      itemCurrentTax = currentValues.expressItemTaxable
        ? roundToTwoDecimals(itemCurrentSubtotal * numeral(currentValues.expressItemTaxRate).value()) / 100
        : 0;

    }

    return {
      itemCurrentSubtotal, itemCurrentTax
    };

  }

  closeOutOfStockDialog() {
    this.setState({openOutOfStockDialog: false});
  }

  confirmOutOfStockDialog() {
    const { itemCurrentSubtotal, itemCurrentTax, selectedModifiers } = this.state;
    this.props.onAddToOrderClick(itemCurrentSubtotal, itemCurrentTax, selectedModifiers);
    this.setState({
      openOutOfStockDialog: false,
      itemCurrentSubtotal: 0,
      itemCurrentTax: 0,
      selectedModifiers: {}
    });
  }

  handleAddToOrder(itemCurrentSubtotal, itemCurrentTax, selectedModifiers, currentValues) {

    const { items } = this.props;
    const { selectedItem } = items;

    const selectedPrice = selectedItem?.details?.prices?.find(price => price.id === currentValues.itemPriceId);

    if (selectedItem?.is_trackable && selectedPrice?.quantity <= 0) {

      this.setState({
        openOutOfStockDialog: true,
        itemCurrentSubtotal,
        itemCurrentTax,
        selectedModifiers
      });

    } else {

      this.props.onAddToOrderClick(itemCurrentSubtotal, itemCurrentTax, selectedModifiers);
      this.setState({
        itemCurrentSubtotal: 0,
        itemCurrentTax: 0,
        selectedModifiers: {}
      });

    }

  }

  renderExpressModifiers = ({ fields }) => {
    const { t } = this.props;

     return (
      <div>
        {fields.map((item, index) =>
          <div key={index} className='flexRow modifierRow'>

            <Box sx={modifierColStyles}>
              <Field
                className='name errorSpacingBottom'
                component={TextField}
                name={`${item}.name`}
                label={t('Modifiers.ModifierNameLabel')}
              />
            </Box>

            <Box sx={modifierColStyles}>
              <Field
                className='modifierPrice'
                component={TextField}
                normalize={normalizeCurrency}
                name={`${item}.price`}
                hintText='$0.00'
                label={t('Modifiers.PriceLabel')}
              />

              <IconButton
                onClick={() => fields.remove(index)}
                className='removeRow'
                size='large'
              >
                {IconUtil.getIcon('DeleteIcon')}
              </IconButton>
            </Box>

          </div>
        )}

        { !fields.length &&
          <div className='buttonContainer'>
            <Button
              onClick={() => fields.push({})}
              startIcon={IconUtil.getIcon('PlusIcon')}
              label={t('AddExpressModifier')}
              variant='text'
            />
          </div>
        }

      </div>
    );
  };

  render() {
    const { selectedItemIndex, merchantSettings, currentValues, onRequestClose, handleSubmit, items, itemizedCart, isEditingItem, onRemoveFromOrderClick, t, cartValues, taxes: {taxRate}, user } = this.props;
    const { selectedItem } = items;
    const selectedItemId = itemizedCart.item_ids[selectedItemIndex];

    //If editing, selectedItemIndex is the item's index in the cart, otherwise it's the item's index in the salesItems array
    const isExpressItemSelected = isEditingItem
      ? selectedItemId === 'express' || !(items?.salesItems?.length > 0 && items.salesItems.filter(item => item.id === selectedItemId)[0])
      : selectedItemIndex === -1;

    const labelColor = LabelUtil.getLabelColor();
    const deleteIcon = IconUtil.getIcon('DeleteIcon', labelColor);

    const categoryFlag = merchantSettings.express_category_enabled;
    const categories = items.categories || [];
    const taxCheckboxWidthStyle = !currentValues.expressItemTaxable ? 'fullScreen' : 'halfScreen withTextField';

    const { itemCurrentSubtotal, itemCurrentTax } = this.calculateItemTotal(currentValues, isExpressItemSelected);
    const {selectedModifiers} = this.state;
    const addToOrderAdditionalInfo = {
      itemCurrentSubtotal,
      itemCurrentTax,
      selectedModifiers
    };
    let newCart = InvoiceUtil.handleAddItemToOrder(currentValues, addToOrderAdditionalInfo, itemizedCart, selectedItemIndex, isEditingItem, selectedItem, taxRate);
    const cashDiscountingAmount = FormUtil.getCashDiscountingAmount(this.props, newCart);
    newCart = InvoiceUtil.recalculateCart(newCart, {...cartValues, cashDiscountingAmount});
    const itemIndex = isEditingItem ? selectedItemIndex : newCart.item_subtotal.length - 1;
    const itemCurrentTotal = numeral(roundToTwoDecimals(newCart.item_subtotal[itemIndex])).format('$0,0.00');

    const plusIcon = IconUtil.getIcon('AddIcon');
    const minusIcon = IconUtil.getIcon('SubtractIcon');

    let isMoreThanStock = false;
    const isTrackable = selectedItem?.is_trackable;

    const selectedItemPrices = selectedItem && selectedItem.details && selectedItem.details.prices && selectedItem.details.prices.map((priceDetails, priceIndex) => {

      const isLowStock = isTrackable && priceDetails.quantity <= priceDetails.low_stock_alert_amount;
      const isOutofStock = isTrackable && priceDetails.quantity <= 0;
      const allowsOutofStock = selectedItem.allow_outof_stock;

      isMoreThanStock = isTrackable && !allowsOutofStock && currentValues.itemQuantity > priceDetails.quantity;

      const itemInStockQuantity = isTrackable
        ? priceDetails.quantity > 0
          ? `${priceDetails.quantity} ${t('InStock')}`
          : t('OutStock')
        : t('NotTracked');

      return {
        disabled: isOutofStock && !allowsOutofStock,
        label: (
          <div className={`selectedItemPrice ${isOutofStock && !allowsOutofStock ? 'outOfStock' : ''}`}>
            <div className='leftSide'>
              <div className='itemName'>{priceDetails.name}</div>
              <div className='itemBarcode'>{priceDetails.barcode || t('NoBarCode')}</div>
            </div>
            <div className='rightSide'>
              {(isLowStock || isMoreThanStock) &&
              <Tooltip
                component='span'
                placement='right'
                className='lowStockWarning'
                title={isOutofStock ? t('Tooltips.OutOfStock') : isMoreThanStock ? t('Tooltips.MoreOfStock') : t('Tooltips.LowOfStock')}
              >
                {IconUtil.getIcon('Warning', '#EB3223')}
              </Tooltip>}
              <div className='priceAndQuantity'>
                <div className='itemPrice'>{numeral(priceDetails.price).format('$0,0.00')}</div>
                <div className='itemInStockQuantity'>{itemInStockQuantity}</div>
              </div>
            </div>
          </div>
        ),
        value: priceDetails.id
      };

    });

    const selectedItemModifiers = selectedItem && selectedItem.details && selectedItem.details.modifierSets && selectedItem.details.modifierSets
      .filter(modifier => modifier.isSelected)
      .map(modifier => {

        return (
          <div className='selectedItemModifierSet' key={modifier.id} >
            <div className='groupLabel'>{`${modifier.name} ${modifier.can_select_multiple ? '' : `(${t('DisabledMultipleSelections')})`}`}</div>

            <div className='modifierOptions'>
              {modifier.modifiers.map(modifierOption => (
                <Checkbox
                  key={modifierOption.id}
                  externalClassName={'modifierOptionWrapper'}
                  className='modifierOptionCheckbox'
                  label={
                    <div className='modifierOptionLabel'>
                      <div className='modifierOptionName'>{modifierOption.name}</div>
                      <div className='modifierOptionPrice'>{numeral(modifierOption.price).format('$0,0.00')}</div>
                    </div>
                  }
                  input={{
                    value: !!(this.state.selectedModifiers[modifier.id] && this.state.selectedModifiers[modifier.id].includes(modifierOption.id)),
                    onChange: () => this.toggleModifierOption(modifier.id, modifierOption.id, modifier.can_select_multiple),
                  }}
                />
              ))}
            </div>

          </div>
        );
      });

    const confirmAddOutOfStockItem = (
      <MessageDialog
        onRequestClose={this.closeOutOfStockDialog}
        onConfirm={this.confirmOutOfStockDialog}
        open={this.state.openOutOfStockDialog}
        bodyText={t('OutOfStockItemDialogBody')}
        cancelText={t('Cancel')}
        scrollable={false}
        titleText={t('OutOfStockItemDialogTitle')}
        confirmText={t('Confirm')}
        isChoiceRequired={true}/>
    );

    const itemImage = selectedItem?.thumbnail ? (<ItemNoFetchThumbnail {...this.props} item={selectedItem} />) : selectedItem ? (<ItemThumbnail {...this.props} item={selectedItem} />) : null;
    const shouldDisplayButton = currentValues.itemPriceId || isExpressItemSelected;

    const selectedPrice = selectedItem?.details?.prices?.find(price => price.id === currentValues.itemPriceId);
    const isMoreThanStockForSelectedPrice = isTrackable && (currentValues.itemQuantity > selectedPrice?.quantity);
    const isMbp = UserUtil.userType(user) === 'MBP';

    return (selectedItem || isExpressItemSelected) && (
      <form onSubmit={handleSubmit}>
        <div className='itemsDialogContainer'>

          {!isExpressItemSelected && <div className='existingItem'>

            <div className='titleContainer'>
              <div className='titleMainInfo'>
                <h3 className='itemName'>{selectedItem.name}</h3>

                <div className='itemSubtitle'>

                  {selectedItem.categories && selectedItem.categories[0] &&
                    <span className='itemCategory'>
                      <span className='itemCategoryColor'>{IconUtil.getIcon('DotIcon', `#${selectedItem.categories[0].color}`)}</span>
                      <span className='itemCategoryName'>{selectedItem.categories[0].name} </span>
                    </span>
                  }

                </div>

              </div>
              <div className='titleImage'>
                { itemImage }
              </div>
            </div>

            {isEditingItem && (
              <Box sx={expressItemsFormStyles} className='removeFromOrder' onClick={() => {onRemoveFromOrderClick(selectedItemIndex)}}>
                <span className='removeIcon'>{deleteIcon}</span>
                <span className='removeText'>{t('RemoveOrder')}</span>
              </Box>
            )}

            <div className='pricesContainer'>
              <div className='groupLabel'>{t('SelectPrice')}</div>
              <Field
                buttons={selectedItemPrices}
                component={CustomRadio}
                isNumeric
                name='itemPriceId'
                externalClassName='selectedItemPriceRadioButton'
              />
            </div>

            <div className='modifiersContainer'>
              {selectedItemModifiers}

              <FieldArray
                name='expressModifiers'
                component={this.renderExpressModifiers}
              />
            </div>

            <div className='discountsContainer'>
              <div className='groupLabel'>{t('AddExpressDiscount')}</div>
              <div className='discountContainer'>

                <Field
                  component={TextField}
                  name='itemDiscountValue'
                  label=''
                  hintText={currentValues.itemDiscountType === 'percent' ? `0% ${t('Off')}` : '$0.00'}
                  className={'itemDiscountValue'}
                  normalize={currentValues.itemDiscountType === 'percent' ? normalizeTaxRate : normalizeCurrency}
                />

                <Field
                  component={Select}
                  style={{ height: 46 }}
                  name='itemDiscountType'
                  className={'itemDiscountTypeSelect'}
                >
                  <MenuItem value={'percent'}>{t('Percent')}</MenuItem>
                  <MenuItem value={'amount'}>{t('Amount')}</MenuItem>
                </Field>

              </div>
            </div>

          </div>}

          {isExpressItemSelected && <div className='expressItem'>

            <div className='titleContainer'>
              <h3 className='itemName'>{t('ExpressItem.Title')}</h3>
            </div>

            {isEditingItem && (
              <Box sx={expressItemsFormStyles} className='removeFromOrder' onClick={() => {onRemoveFromOrderClick(selectedItemIndex)}}>
                <span className='removeIcon'>{deleteIcon}</span>
                <span className='removeText'>{t('RemoveOrder')}</span>
              </Box>
            )}

            <Field
              component={TextField}
              name='expressItemName'
              label={t('ExpressItem.ItemNameLabel')}
              hintText={t('ExpressItem.ItemNameHint')}
              maxLength='50'
              className={'textField descriptionField halfScreen'}
            />

            <Field
              component={TextField}
              name='expressItemPrice'
              label={t('ExpressItem.ItemPriceLabel')}
              hintText={t('ExpressItem.ItemPriceHint')}
              className={'amount textField halfScreen'}
              maxLength='14'
              normalize={normalizeCurrency}
            />

            { taxRate > 0 && (
              <>
                <Field
                  component={Checkbox}
                  name='expressItemTaxable'
                  label={t('Taxable')}
                  labelStyle={{color: '#888C8D'}}
                  checked={!!this.value}
                  externalClassName={`checkField taxable ${taxCheckboxWidthStyle}`}
                />

                {currentValues.expressItemTaxable && <Field
                  component={TextField}
                  name='expressItemTaxRate'
                  label={t('TaxRate')}
                  hintText={t('ExpressItem.TaxRateHint')}
                  className={'textField customTaxRate halfScreen'}
                  InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
                  disabled
                  value={taxRate}
                />}
              </>
            ) }


            {(categoryFlag) ? <Field
              component={Select}
              style={{ height: 46 }}
              name='expressItemCategory'
              className='expressItemCategory'>
              <MenuItem value={-1}>{t('Category.NoCategory')}</MenuItem>
              {
                categories.map((item, i) => {
                  return <MenuItem value={item.id} key={i} className='categoryItem'>
                    <span className='bubble' style={{ marginRight: 15, marginLeft: 0 }}>
                      {IconUtil.getIcon('DotIcon', `#${item.color || '8A8C8C'}`)}
                    </span>
                    {item.name}
                  </MenuItem>
                })
              }
            </Field> : null}

            <FieldArray
              name='expressModifiers'
              component={this.renderExpressModifiers}
            />

            {!isMbp &&
            <Box sx={expressItemToggleLabel}>
              <Field
              component={Toggle}
              label={t('ExpressItem.SaveItemLabel')}
              name='saveItemToInventory'
              t={t}
              description={t('ExpressItem.ToggleDescription')}
            />
            </Box>
            }
          </div>}

          <div className='hideMe'>
            <Field
              component={TextField}
              name='itemTotal'
              label=''
              hintText=''
              maxLength=''
              normalize={normalizeCurrency}
            />
          </div>

          <div className='itemsActionsContainer'>

            <Box className='selectQuantityContainer' sx={expressItemQuantityStyles.quantityContainer}>
              <IconButton
                iconStyle={expressItemQuantityStyles.quantityButton}
                disableTouchRipple
                onClick={this.subtractItemQuantity}
                className='quantityButton'
                iconStyle={{display: 'flex'}}
                data-test-id='decreaseQuantityButton'
              >
                {minusIcon}
              </IconButton>
              <Field
                  component={TextField}
                  name='itemQuantity'
                  label=''
                  hintText=''
                  className={'quantityField'}
                  data-test-id='quantityField'
                  normalize={normalizeQuantity}
                />
              <IconButton
                onClick={this.addItemQuantity}
                className='quantityButton'
                disableTouchRipple
                iconStyle={expressItemQuantityStyles.quantityButton}
                data-test-id='increaseQuantityButton'
              >
                {plusIcon}
              </IconButton>

            </Box>

            <div className='itemsButtons'>
              <Button
                label={t('Cancel')}
                className='itemSelectionCancel'
                onClick={onRequestClose}
                variant='text'
              />
              <Button
                label={`${isEditingItem ? t('Update') : t('AddTo')} ${t('Order')} | ${numeral(itemCurrentTotal).value() > 0 ? itemCurrentTotal : '$0.00'}`}
                disabled={isMoreThanStockForSelectedPrice || numeral(itemCurrentTotal).value() <= 0 || !shouldDisplayButton}
                className='itemSelectionAddToOrder'
                onClick={() => this.handleAddToOrder(itemCurrentSubtotal, itemCurrentTax, this.state.selectedModifiers, currentValues)}
              />
            </div>

          </div>

        </div>
        {confirmAddOutOfStockItem}
      </form>
    );

  }

}

let VirtualTerminalFormItemSelection = reduxForm({
  form: 'virtualTerminalFormItemSelection',
  fields: [],
  validate,
  enableReinitialize: false
})(VirtualTerminalFormItemSelectionComponent);

function mapStateToProps(state, ownProps) {

  const currentValues = getFormValues('virtualTerminalFormItemSelection')(state) || {};
  const { itemizedCart, isEditingItem, selectedItemIndex, items, isVirtualTerminal } = ownProps;
  const cartValues =  getFormValues(isVirtualTerminal ? 'virtualTerminalForm' : 'invoiceForm')(state) || {};
  const selectedItemId = itemizedCart.item_ids[selectedItemIndex];

  const isEditingExpress = isEditingItem
    ? selectedItemId === 'express' || !(items?.salesItems?.length > 0 && items.salesItems.filter(item => item.id === selectedItemId)[0])
    : selectedItemIndex === -1;

  let itemDiscountType = 'percent';
  let itemDiscountValue = normalizeTaxRate(itemizedCart.item_discount_rate[selectedItemIndex]);

  if (itemizedCart.item_discount_amt[selectedItemIndex] && !itemizedCart.item_discount_rate[selectedItemIndex]) {
    itemDiscountType = 'amount';
    itemDiscountValue = normalizeCurrency(roundToTwoDecimals(itemizedCart.item_discount_amt[selectedItemIndex] / itemizedCart.item_quantity[selectedItemIndex]).toFixed(2));
  }

  return  {
    initialValues: isEditingItem
      ? isEditingExpress
        ? {
          expressItemCategory: itemizedCart.item_category[selectedItemIndex],
          expressItemName: itemizedCart.item_names[selectedItemIndex],
          expressItemPrice: normalizeCurrency(itemizedCart.item_unit_price[selectedItemIndex].toFixed(2)),
          expressItemTaxRate: FormatTextUtil.formatNumberToThreeDecimals(ownProps.taxes.taxRate),
          expressItemTaxable: !!itemizedCart.item_tax_rate[selectedItemIndex],
          expressModifiers: itemizedCart.express_options[selectedItemIndex],
          itemDiscountValue: null,
          itemDiscountType: 'percent',
          itemPriceId: null,
          itemQuantity: itemizedCart.item_quantity[selectedItemIndex],
          itemTotal: 0,
          saveItemToInventory: itemizedCart.save_to_inventory[selectedItemIndex],
        } : {
          expressItemCategory: -1,
          expressItemName: null,
          expressItemPrice: null,
          expressItemTaxRate: null,
          expressItemTaxable: false,
          expressModifiers: itemizedCart.express_options[selectedItemIndex],
          itemDiscountValue,
          itemDiscountType,
          itemPriceId: itemizedCart.item_price_id[selectedItemIndex],
          itemQuantity: itemizedCart.item_quantity[selectedItemIndex],
          itemTotal: 0,
          saveItemToInventory: false
        }
      : {
        expressItemCategory: -1,
        expressItemName: null,
        expressItemPrice: null,
        expressItemTaxRate: FormatTextUtil.formatNumberToThreeDecimals(ownProps.taxes.taxRate),
        expressItemTaxable: false,
        expressModifiers: [],
        itemDiscountValue: null,
        itemDiscountType: 'percent',
        itemPriceId: null,
        itemQuantity: 1,
        itemTotal: 0,
        saveItemToInventory: false
      },
    currentValues: currentValues,
    cartValues,
  };

}

VirtualTerminalFormItemSelection = connect(mapStateToProps)(VirtualTerminalFormItemSelection);

export default VirtualTerminalFormItemSelection;
