import { CALL_API } from '../middleware/api';
import actionTypes from '../constants/actionTypes';
import numeral from 'numeral';

export function getDiscounts(user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/discounts`;
  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.discountGetRequest, actionTypes.discountGetSuccess, actionTypes.discountGetFailure]
    }
  };
};

export function saveDiscounts(user, selectedIndex, values) {

  const method = selectedIndex === 'new' ? 'POST' : 'PUT';
  const postEndpoint = `users/accounts/${user.selectedMerchantAccount}/discounts`;
  const putEndpoint = `users/accounts/${user.selectedMerchantAccount}/discounts/${values.id}`;
  const endpoint = method === 'PUT' ? putEndpoint : postEndpoint;

  if (values.type === 'amount') {
    values.type = 'flat';
  }

  const body = {
    name: values.name,
    type_code: values.type
  };

  if (values.color !== '') {
    body.color = values.color;
  }

  if (values.type === 'percent') {
    const cleanAmount = values.percentage.toString().replace(/[^\d]/g, '');
    body.percentage = Number(cleanAmount);
  } else {
    body.amount = numeral(values.amount).value();
  }

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      types: [actionTypes.discountSaveRequest, actionTypes.discountSaveSuccess, actionTypes.discountSaveFailure],
      body
    }
  };
}

export function setDiscount(discount) {
  return {
    type: actionTypes.discountSelected,
    selectedDiscount: discount
  };
}

export function deleteDiscount(selectedDiscount, user) {

  const endpoint = `users/accounts/${user.selectedMerchantAccount}/discounts/${selectedDiscount.id}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'DELETE',
      authenticated: true,
      types: [actionTypes.discountSaveRequest, actionTypes.discountSaveSuccess, actionTypes.discountSaveFailure]
    }
  };
}
