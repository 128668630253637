/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import { submit } from 'redux-form';
import MasterDetailLayout from '../MasterDetailLayout';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '../shared/IconButton';
import TableHeader from '../shared/TableHeader';
import IconUtils from './../util/IconUtil';
import {getCategories, saveCategories, deleteCategory, mergeCategories} from '../../actions/categoriesActions';
import actionTypes from '../../constants/actionTypes';
import MessageDialog from '../shared/MessageDialog';
import NoData from '../NoData';
import CategoriesDetailForm from './CategoriesDetail';
import UserUtil from '../util/UserUtil';
import FilterPanel from '../shared/FilterPanel';
import LabelUtil from '../util/LabelUtil';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import MergeCategoryForm from './MergeCategoryForm';
import { Trans } from 'react-i18next';
import Page from '../shared/Page';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';
import {categoriesIndicatorStyles} from '../../jss/inlineStyles';
import InventorySyncText from './InventorySyncText';

const defaultCategoryColor = '888C8D';

export default class Categories extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCloseDetail = this.onCloseDetail.bind(this);
    this.handleFilterSelection = this.props.handleFilterSelection.bind(this);
    this.handleCreateMenuClose = this.props.handleCreateMenuClose.bind(this);
    this.handleCreateNew = this.props.handleCreateNew;
    this.renderCreateMenu = this.props.renderCreateMenu.bind(this);
    this.handleCreateClick = this.props.handleCreateClick.bind(this);
    this.displayDeleteDialogDetail = this.displayDeleteDialogDetail.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.displayMergeCategoryDialogDetail = this.displayMergeCategoryDialogDetail.bind(this);
    this.closeMergeCategoryDialog = this.closeMergeCategoryDialog.bind(this);
    this.onMergeCategorySubmit = this.onMergeCategorySubmit.bind(this);
    this.triggerMergeCategorySubmit = this.triggerMergeCategorySubmit.bind(this);

    this.state = {
      selectedIndex: 0,
      openDetail: false,
      openDeleteDialog: false,
      openMergeCategoryDialog: false
    };
  }

  componentDidMount() {
    this.loadData(this.props);
    if(this.props.location?.state?.openCreateNewFromTheStart) {
      this.showDetailForRow('new');
    }
  }

  displayDeleteDialogDetail() {
    this.setState({openDeleteDialog: true});
  }

  closeDeleteDialog() {
    this.setState({openDeleteDialog: false});
  }

  displayMergeCategoryDialogDetail() {
    this.setState({openMergeCategoryDialog: true});
  }

  closeMergeCategoryDialog() {
    this.setState({openMergeCategoryDialog: false});
  }


  loadData(props) {
    props.dispatch(getCategories(props.user));
  }

  defaultsColor(values) {
    const sanitizedValues = {...values};
    if (!sanitizedValues.color) {
      sanitizedValues.color = defaultCategoryColor;
    }
    return sanitizedValues;
  }

  save(values) {
    const that = this;
    this.setState({showLoadingIndicator: true});
    this.props.dispatch(saveCategories(this.props.user, this.state.selectedIndex, this.defaultsColor(values)))
      .then(function () {
        that.setState({ selectedIndex: 0, showLoadingIndicator: false, openDetail: false });
        that.loadData(that.props);
      });
  }

  delete() {

    const {dispatch, items, user} = this.props;

    let that = this;

    this.setState({openDeleteDialog: false, showLoadingIndicator: true});

    dispatch(deleteCategory(items.filteredCategories[this.state.selectedIndex], user)).then(() => {
        that.setState({ selectedIndex: 0, showLoadingIndicator: false, openDetail: false });
        that.loadData(that.props);
      });
  }

  onSaveClick() {
    this.props.dispatch(submit('categoriesDetailForm'));
  }

  onCloseDetail() {
    this.setState({ selectedIndex: -1, openDetail: false });
  }

  onCellClick(selectedIndex) {
    this.setState({ selectedIndex, openDetail: true });
  }

  showDetailForRow(selectedIndex) {
    this.onCellClick(selectedIndex);
  }

  newItem() {
    return {
      name: '',
      color: ''
    }
  }

  onMergeCategorySubmit({ baseCategory, selectedCategory, ...newCategory }) {
    const that = this;

    return this.props
      .dispatch(mergeCategories(this.props.user, { baseCategory, selectedCategory }, newCategory))
      .then((response) => {
        if (response.type !== actionTypes.mergeCategoryFailure) {
          that.closeMergeCategoryDialog();
          this.setState({ selectedIndex: 0, openDetail: false });
          that.loadData(that.props);
        }
      });

  }

  triggerMergeCategorySubmit() {
    this.props.dispatch(submit('mergeCategoryForm'));
  }

  render() {

    const {isFetching, items, user, auth, t} = this.props;

    const isEmpty = items.categories === null;
    const isCsUser = UserUtil.isUserCS(user, auth);

    const readOnly = !UserUtil.isActive(this.props.user);


    const pageLoading = (isEmpty && isFetching) || this.state.showLoadingIndicator;

    if (pageLoading) {
      return (
        <Page title={t('Inventory')} loading />
      )
    }


    const isNewCategory = this.state.selectedIndex === 'new';
    const categories = items.filteredCategories || items.categories;
    const selectedCategory = this.state.selectedIndex !== -1 && categories[this.state.selectedIndex] || this.newItem();

    const ammountOfCategories = categories ? categories.length : 0;

    const listHeaders = ((categories.length > 0) &&
      <TableRow className={'tableRow'}>
        <TableHeader
          type='table'
          headerId={'categoriesHeaderOne'}
          attribute={''}
          name={''}
          {...this.props}/>
        <TableHeader
          type='table'
          clickable={true}
          attribute={'name'}
          headerId={'categoriesHeaderTwo'}
          name={t('CategoriesPage.CategoryName')}
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'categoriesHeaderThree'}
          clickable={true}
          attribute={'count'}
          name={t('CategoriesPage.CategoryCount')}
          {...this.props}/>
      </TableRow>
    ) || null;

    let categoryRows = categories.length > 0 ?
      categories.map((item, i) => {
          const tableRowClassName = this.state.selectedIndex === i ? 'selectedListRow categoryRow' : 'categoryRow';
          const formattedColor = item.color ? `#${item.color}` : `#${defaultCategoryColor}`;
          return (
            <TableRow key={i} className={`tableRow ${tableRowClassName}`} onClick={this.onCellClick.bind(this, i)}>
              <TableCell className='categoriesIconColumn'>
                <IconButton disableTouchRipple={true} size='large'>
                  <Avatar style={{
                    ...categoriesIndicatorStyles,
                    backgroundColor: formattedColor
                  }}>
                    {IconUtils.getIcon('CircleIcon', formattedColor)}
                  </Avatar>
                </IconButton>
              </TableCell>
              <TableCell>
                <div className='name'>{item.name}</div>
              </TableCell>
              <TableCell >
                  <span className='count'>
                    {t('CategoriesPage.Count', {count: item.count})}
                  </span>
              </TableCell>
            </TableRow>
          );
        }
      ) : (<NoData text={t('CategoriesPage.NoData')} type='table'/>);

    let itemsTableLayout = (
      <TableBody>
        {listHeaders}
        {categoryRows}
      </TableBody>
    );


    let submitButtonDisabled = !this.props.userExperience.enableFormSubmitButton;

    const labelColor = LabelUtil.getLabelColor();
    const deleteIcon = IconUtils.getIcon('DeleteIcon', labelColor);
    const mergeIcon = IconUtils.getIcon('CallMergeIcon', labelColor);
    const deleteOption =
      {
        onClick: this.displayDeleteDialogDetail,
        text: t('CategoriesPage.DeleteCategory'),
        icon: deleteIcon
      };
    const mergeCategoryOption =
      {
        onClick: this.displayMergeCategoryDialogDetail,
        text: t('CategoriesPage.MergeCategory'),
        icon: mergeIcon
      };

    const categoriesOptions = !isNewCategory && selectedCategory && !readOnly ? [mergeCategoryOption, deleteOption] : [];

    let categoriesDetailPanel =
      (
        <DetailPanel
          open={this.state.openDetail}
          heading={isNewCategory ? t('CategoriesPage.NewCategory') : selectedCategory.name}
          isNew={true}
          shouldDisplay={true}
          onClick={this.onSaveClick}
          confirmationText={t('Save')}
          onCloseClick={this.onCloseDetail}
          isSubmitButtonVisible={!readOnly}
        >
          <DetailPanelOptions options={categoriesOptions} />
          <CategoriesDetailForm item={selectedCategory} readOnly={readOnly} onSubmit={this.save} onDelete={this.delete} t={t} />
        </DetailPanel>
      );

    const searchBar = (
      <SearchBarAccessory
        {...this.props}
        searchPlaceholder={t('CategoriesPage.SearchCategories')}
      />
    );

    const createNewButton = <CreateButtonAccessory
      onClick={this.handleCreateClick}
      disabled={readOnly}
      isMobileOnly
    />

    const filterPanel = (
      <FilterPanel
        buttonOnClick={this.handleCreateClick}
        buttonText={'Create'}
        buttonWithCreateIcon
        {...this.props}
        filterData={UserUtil.getInventoryOptions('Categories', ammountOfCategories, this.props.auth, this.props.user, this.props.t)}
        selectFilterCallback={this.handleFilterSelection}
        disabled={readOnly}
        additionalFilterDataComponent={<InventorySyncText t={t}/>}
      />
    );

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
    ];


    const deleteConfirmationDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='deleteConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.delete}
        onRequestClose={this.closeDeleteDialog}
        open={this.state.openDeleteDialog}
        scrollable={true}
        titleText={t('DeleteConfirmationDialog.TitleText')}
        bodyText={
          <p className='confirmationText'>{t('DeleteConfirmationDialog.ConfirmationText')}</p>
        } />
    );

    const mergeCategoryDialog = (
      <MessageDialog
        titleText={t('MergeCategoryDialog.TitleText')}
        confirmText={t('MergeCategoryDialog.ConfirmText')}
        cancelText={t('Cancel')}
        cancelDisabled={items.isUpdating}
        disabled={items.isUpdating || submitButtonDisabled}
        isChoiceRequired={false}
        onConfirm={this.triggerMergeCategorySubmit}
        onRequestClose={this.closeMergeCategoryDialog}
        open={this.state.openMergeCategoryDialog}
        bodyText={
          <MergeCategoryForm
            onSubmit={this.onMergeCategorySubmit}
            item={selectedCategory}
            items={items}
            isCsUser={isCsUser}
            t={t}
          />
        }
      />
    );

    return (

      <section className='inventoryCategories'>
        <MasterDetailLayout
          {...this.props}
          pageInitialSubpage={t('Categories')}
          pageTitle={t('Inventory')}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          header={null}
          tableData={this.props.items.filteredCategories}
          filterPanel={filterPanel}
          tableRowLayout={itemsTableLayout}
          forceOpenDetail={isNewCategory}
        />

        {categoriesDetailPanel}
        {deleteConfirmationDialog}
        {mergeCategoryDialog}
        {this.renderCreateMenu()}
      </section>
    )
  }
}

Categories.defaultProps = {
  isFetching: true,
};
