//  TODO: clean label/* files and remove duplicate styles

const styles = (theme) => ({
  '.north': {
    fontFamily: theme.typography.primaryFontFamily,

    '& .grecaptcha-badge': {
      display: 'none',
    },
    '& .grecaptcha-protection': {
      margin: '20px 20px 35px',
      fontSize: '11px',
      textAlign: 'center',
      color: '#333',
      fontFamily: theme.typography.primaryFontFamilyRegular,
      'a': {
        margin: '0 3px',
        color: 'inherit',
      },
    },
    '& .login, & .clientService': {
      '.loginContainer .forgotPasswordContainer .arrowIcon svg': {
        fill: theme.palette.labelColor.main,
      },
      '.signUpContainer, .requestTokenContainer, .forgotPasswordContainer': {
        'a': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
      '.forgotPasswordContainer': {
        '.arrowIcon': {
          'svg': {
            fill: theme.palette.labelColor.main,
          },
        },
      },
    },
    '.customTooltip': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
      '&.place-right': {
        '&:after': {
          borderRightColor: `${theme.palette.labelColor.main} !important`,
          borderRightStyle: 'solid !important',
          borderRightWidth: '6px !important',
        },
      },
    },
    '.isPrimaryColor': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    'header': {
      '.navSelected': {
        color: theme.palette.labelColor.main,
      },
      'nav': {
        'a:hover': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '& .linkButton:hover': {
      color: theme.palette.labelColor.main,
      '& svg': {
        fill: theme.palette.labelColor.main,
      },
    },
    '& .pageWrap': {
      '& .detail': {
        '& .bar': {
          '& .barButton': {
            '& span': {
              color: theme.palette.labelColor.main,
              cursor: 'auto'
            },
          },
        },
      },
    },
    '& .inventoryItems': {
      '& .tab': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .activeTab': {
        color: theme.palette.labelColor.main,
      },
      '& .inactiveTab': {
        color: theme.palette.common.regularColor,
      },
    },
    '& div.stock .stockAlert svg': {
      fill: theme.palette.labelColor.main,
    },
    '& .subMenuContainer': {
      '& .active': {
        color: theme.palette.labelColor.main,
      },
      '& .subMenuItem.active': {
        color: theme.palette.labelColor.main,
        '& svg': {
          fill: theme.palette.labelColor.main,
        },
      },
      '& .subMenuIconContainer.active': {
        color: theme.palette.labelColor.main,
      },
    },
    '& .searchBar': {
      '& > div': {
        '& .addButton': {
          backgroundColor: theme.palette.labelColor.main,
        },
      },
    },
    '& .material-icons.active svg': {
      color: theme.palette.labelColor.main,
    },
    '& .portalCheckbox.disabledCheckbox': {
      '& svg + svg': {
        fill: theme.palette.labelColor.main,
        stroke: 'none',
        color: theme.palette.labelColor.main,
      },
    },
    '& .portalCheckbox': {
      '& svg + svg': {
        fill: theme.palette.labelColor.main,
        stroke: 'none',
        color: theme.palette.labelColor.main,
      },
    },
    '& .addButton': {
      backgroundColor: theme.palette.labelColor.main,
    },
    '& .login': {
      '& .loginContainer': {
        '& .signUpContainer, & .forgotPasswordContainer, & .requestTokenContainer': {
          '& a': {
            color: theme.palette.labelColor.main,
          },
        },
      },
    },
    '& .activitySales': {
      '& .salesSummaryRow': {
        '& .summaryPanel': {
          '& .summaryPanelHeader': {
            '& .summaryPanelCount': {
              backgroundColor: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '& .activityReports': {
      '& .reportRow': {
        '& .reportActions': {
          '& .exportButtonContainer': {
            color: theme.palette.labelColor.main,
            '& svg': {
              fill: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '& .reputation .reputationSettings .connectionCard .descriptionItems p svg': {
      color: theme.palette.labelColor.main,
    },
    '& .editLocationDialog .MuiDialogContent-root .locationsContainer .activeLocation': {
      borderColor: theme.palette.labelColor.main,
    }, '& .reputation .reputationWrapper .reputationContent .reputationSettings .quickReplies .addReply': {
      color: theme.palette.labelColor.main,
    },
    '& .customRating .MuiRating-iconFilled': {
      color: theme.palette.labelColor.main,
    },
    '& .invoiceDetail a': {
      color: theme.palette.labelColor.main,
    },
    '& .optionsContainer .favoriteFieldWrapper label > span + span': {
      color: theme.palette.labelColor.main,
    },
    '& .exportSelector': {
      color: theme.palette.labelColor.main,
      '& .exportButtons': {
        '& .label': {
          color: theme.palette.labelColor.main,
        },
      },
      '& .exportIconWrapper': {
        '& svg': {
          fill: theme.palette.labelColor.main,
        },
      },
    },
    '.userAccountMenuItem': {
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
      '&:hover': {
        background: `${theme.palette.labelColor.main} !important`,

        '& svg': {
          fill: `${theme.palette.text.primary} !important`,
        },
      },
    },
    '& svg.customImportIcon path': {
      fill: theme.palette.labelColor.main,
    },
    '& .statements': {
      '& .statementsLink': {
        color: theme.palette.labelColor.main,
        '& svg': {
          fill: theme.palette.labelColor.main,
        },
      },
    },
    '& .userAccountMenuItem': {
      '& svg': {
        fill: theme.palette.labelColor.main,
      },
      '&:hover': {
        background: theme.palette.labelColor.main,
        '& svg': {
          fill: theme.palette.text.primary,
        },
      },
    },
    '& .accountProfile': {
      '& .pageWrap': {
        '& .membershipInfo': {
          '& .membershipContainer': {
            '& .membershipButton': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
        '& .pageWrap': {
          '& .flatButtonLink': {
            '& span': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
          '& .addOns': {
            '& .loyalty': {
              '& .status': {
                '& .activateContainer': {
                  '& .activate': {
                    color: theme.palette.labelColor.main,
                    border: `1px solid ${theme.palette.labelColor.main}`,
                  },
                },
              },
            },
          },
        },
      },
    },
    '& .updatePassword, & .updateEmail, & .updateTwoFactor, & .updateProfile, & .showHide, & .linkLike, & .relatedTransactions, & .editButton, & .membershipButton, & .toastrButton, & .updateLanguage, & .updateManageRememberedDevices': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .merchantAccountRow.active': {
      color: `${theme.palette.labelColor.main} !important`,
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .accountMenuIcon svg': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .employeeReportMenuItem': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .fraudExport': {
      top: 0,
    },
    '& .depositTransactionsDetail .depositTransactionLink': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .formLinks, & .taxLinks, & .secondaryText, & .equipmentLinks': {
      '& a': {
        ...theme.sharedStyles.link(theme),
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .customCheckBox': {
      '& svg': {
        fill: '#ffffff !important',
        stroke: theme.palette.common.gray,
        color: theme.palette.common.gray,
      },
      '& svg + svg': {
        stroke: 'none !important',
      },
    },
    '& .mobileNavigationItem.active': {
      '& a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .mobileContainer, & .testDriveContainer': {
      '& .titlePanel': {
        '& .titlePanelSubText': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .mobileFooterToggle': {
      '& .active': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .reportsToolbar': {
      '& .backButton, & .mobileExportButton': {
        '&:hover': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .loginButton, & .submitButton': {
      '& .loginFront, & .submitFront': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
      '& .loginArrow, & .submitArrow': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .resetTokenButton': {
      width: '150px !important',
      '& .loginFront, & .submitFront': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
      '& .loginArrow, & .submitArrow': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .niceLink': {
      '& a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .closedAccountContainer': {
      '& .closedAccountIndicator': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .closedAccountIcon': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .isLockedIcon': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .isArchivedIcon': {
      fill: `${theme.palette.labelColor.main} !important`,
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .resendInviteContainer': {
      '& .resendIconContainer': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .buttonInverse': {
      color: theme.palette.labelColor.main,
    },
    '& .businessForm .editButton span': {
      color: `${theme.palette.labelColor.main} !important`,
      cursor: 'pointer',
    },
    '& .virtualTerminal': {
      '& .virtualTerminalFormContainer': {
        '& .customCheckbox': {
          '& svg + svg': {
            fill: theme.palette.labelColor.main,
            stroke: 'none',
            color: theme.palette.labelColor.main,
          },
        },
      },
      '& .invoiceForm': {
        '& a': {
          color: theme.palette.labelColor.main,
        },
      },
      '& .virtualTerminalFormContainer, & .invoiceForm': {
        '& .itemSearchDropdown': {
          '& .taxNote': {
            '& .linkLike': {
              color: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '& .allAccountsContainer': {
      '& .allAccountsCheckbox.selected': {
        '& label': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '& .businessOnlinePayments': {
      '& .formHeading': {
        '& .action': {
          color: theme.palette.labelColor.main,
        },
      },
      '& .formDescription': {
        '& .formDescriptionLink': {
          color: theme.palette.labelColor.main,
        },
      },
      '& .paymentInformation': {
        '& .cardPresentIndicator, & .selectPaymentMethodButton': {
          '& a': {
            ...theme.sharedStyles.link(theme),
            color: theme.palette.labelColor.main,
          },
        },
      },
      '& .formItems': {
        '& a': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '& .customerRatingContainer': {
      '.footer': {
        background: `${theme.palette.labelColor.main} !important`,
        '.branding': {
          visibility: 'hidden',
        },
      },
    },
    '& .today ': {
      display: 'flex',
      flexDirection: 'column',
      '.gridContainer': {
        '.grid': {
          '.col': {
            '&Tips': {
              '.tips': {
                backgroundImage: 'url(\'/images/paymenthubs/today_tips_ph_navigating.png\')',
              },
              '.tipsRow': {
                '.heading': {
                  paddingRight: '15%',
                },
                '.todayLink': {
                  marginTop: '57px',
                },
              },
            },
            '&Feedback': {
              '.tips': {
                '&:before': {
                  background: `url('/images/paymenthubs/today_feedback.png') no-repeat 170px 175px/170px 170px ${theme.palette.common.white} !important`,
                },
                '.tipsRow': {
                  alignItems: 'flex-start !important',
                  zIndex: 1,
                  '.heading': {
                    paddingTop: '15px',
                    color: theme.palette.text.primary,
                    fontFamily: theme.typography.primaryFontFamilySemiBold,
                    fontSize: '22px !important',
                    paddingRight: '10%',
                    margin: '10% 0 0 0',
                    paddingBottom: '30px',
                    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
                      marginTop: '20px',
                      fontSize: '23px !important',
                    },
                  },
                  '.text': {
                    ...theme.sharedStyles.bodyText(theme),
                    color: `${theme.palette.text.primary} !important`,
                  },
                  '.reverseLinkAsButton': {
                    boxShadow: 'none !important',
                    alignSelf: 'flex-start',
                    marginTop: '15px',
                    height: '15px !important',
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    svg: {
                      width: '18px !important',
                    },
                  },
                },
              },
            },
            '.titleContainer': {
              '.title': {
                '.redirect': {
                  color: `${theme.palette.labelColor.main} !important`,
                  '&:visited': {
                    color: theme.palette.labelColor.main,
                  },
                  svg: {
                    fill: `${theme.palette.labelColor.main} !important`,
                  },
                },
              },
            },
          },
          '.deposits': {
            backgroundColor: `${theme.palette.labelColor.main} !important`,
          },
          '.trends': {
            '.trendsRow': {
              '.statisticsContainer': {
                '.stars': {
                  svg: {
                    fill: `${theme.palette.labelColor.main} !important`,
                  },
                },
              },
            },
          },
        },
      },
      '.todayLink': {
        backgroundColor: theme.palette.labelColor.main,
        '.iconLink': {
          backgroundColor: theme.palette.labelColor.main,
        },
      },
      '.action a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .endSeriesButton span, .invoiceDetail .barButton span, .linkButton span, .markInvoicePaidButton span, .removeAutopayButton span, .removeCardButton span, .resendInvoiceButton span, .seeTransactionsButton span, .viewInvoiceSeriesButton span, .viewSeriesButton span': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .recurringDetails button': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .clearButton': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .barButton span': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .buttonWrapper .buttonColor svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .openTransactionsHeader .filterIconContainer svg, .incompleteTransactionsFilter .filterIconContainer svg': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .detail .bar svg:hover': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .detail .bar .barButton span': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .contactFooter p': {
      ...theme.sharedStyles.contentLabel(theme),
      textAlign: 'center',
      color: theme.palette.labelColor.main,
      padding: 0,
      fontSize: '17px',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.labelColor.main,
      },
    },
    '& .contactFooter .divider': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .addMerchantAccount': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .addAccountDialog': {
      '& .activeField label': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .errorField.activeField label': {
        color: `${theme.palette.error.main} !important`,
      },
      '& hr:first-of-type, & .activeField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.labelColor.main} !important`,
      },
      '& hr + hr, & .activeField hr + hr': {
        borderBottom: `2px solid ${theme.palette.labelColor.main} !important`,
      },
      '& .errorField hr:first-of-type': {
        borderBottom: `1px solid ${theme.palette.error.main} !important`,
      },
      '& .errorField hr + hr': {
        borderBottom: `2px solid ${theme.palette.error.main} !important`,
      },
    },
    '& .cornerCheckMark': {
      '& .st0': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .mainMenuItem': {
      '& .st0': {
        fill: `${theme.palette.text.primary} !important`,
      },
      '&.selected': {
        '& .st0': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .integrationsPage': {
      '& .accountIntegrations': {
        '& .integrationContainer': {
          '& .integrationInformationContainer': {
            '& .learnMoreHomebase, & .disconnectQuickBooks': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
      '& .integrationDetails .integrationInformationDetailsContainer .integrationStatusDetails .integrationStatusDetailsAndActions .integrationStatusDetailsActions .integrationStatusDetailsButtons .launchButtonContainer span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .returnToIntegrations': {
        color: `${theme.palette.labelColor.main} !important`,
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
      '& .imageGallery': {
        '& .image-gallery-left-nav:hover::before, & .image-gallery-right-nav:hover::before': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .midSelector': {
      '& .active': {
        color: theme.palette.labelColor.main,
        '& span': {
          '& svg': {
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '& .cardReaderStatusWell': {
      '& .cardReaderStatusAction': {
        '& a': {
          ...theme.sharedStyles.link(theme),
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .settings': {
      '& .banner': {
        backgroundImage: 'url(\'/images/paymenthubs/settings.png\')',
      },
      '& .input, & .receiptButton': {
        '& .button': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '& .openBatchButton.selected': {
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '._elevio_launcher': {
      button: {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '._elevio_navbar': {
      backgroundColor: `${theme.palette.labelColor.main} !important`,
    },
    '._elevio_module_icon:hover': {
      '& > div:first-of-type': {
        backgroundColor: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.batchDetailsSummary': {
      '.buttonHolder': {
        display: 'flex',
        '.showButton': {
          color: theme.palette.labelColor.main,
        },
      },
    },
    '.reverseLinkAsButton': {
      background: `${theme.palette.common.white} !important`,
      color: `${theme.palette.labelColor.main} !important`,
      svg: {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '.raisedButton': {
      'button:disabled, button[disabled]': {
        background: `${theme.palette.common.disabledGray} !important`,
      },
      button: {
        background: `${theme.palette.labelColor.main} !important`,
        span: {
          color: `${theme.palette.common.white} !important`,
        },
        div: {
          div: {
            span: {
              color: `${theme.palette.common.white} !important`,
            },
          },
        },
      },
    },
    '.optionsContainer .favoriteButton label': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '.filter': {
      '&Logo': {
        backgroundImage: 'url(\'/images/north/logoSmall.svg\')',
      },
      '&Item': {
        '&Selected': {
          color: theme.palette.labelColor.main,
          '&.filterItemDisabled': {
            fontFamily: `${theme.typography.primaryFontFamilyMedium} !important`,
          },
        },
        '&Selected .filterItemDisabled, &Disabled': {
          color: `${theme.palette.common.gray} !important`,
          cursor: 'auto'
        },
      },
    },
    '.optionsList': {
      fontFamily: theme.typography.primaryFontFamilyMedium,
      listStyle: 'none',
      padding: 0,
      '&Item': {
        color: theme.palette.labelColor.main,
        '.optionsHolder': {
          span: {
            color: theme.palette.labelColor.main,
            svg: {
              fill: theme.palette.labelColor.main,
            },
            a: {
              color: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '.masterTable': {
      table: {
        tbody: {
          tr: {
            '.activeHeader': {
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '.accountSupplies': {
      '.accountSuppliesWrapper': {
        '.itemsList': {
          '.itemHeader': {
            '.activeHeader': {
              color: `${theme.palette.labelColor.main} !important`,
            },
            '.iconHeader': {
              svg: {
                fill: `${theme.palette.labelColor.main} !important`,
              },
            },
          },
        },
      },
    },
    '.orderSummaryDialog': {
      '.orderSummary': {
        '.orderSummaryClearButton': {
          '.clearButton': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '& .tableRow, & .ReactVirtualized__Table__headerRow, & .ReactVirtualized__Table__row': {
      '& th, & .ReactVirtualized__Table__headerColumn': {
        '& .activeHeader': {
          color: `${theme.palette.labelColor.main} !important`,
        },
        '& .iconHeader': {
          '& svg': {
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '& .toolbar': {
      '& .logoBar': {
        '& h1': {
          margin: 0,
          '& .logo': {
            backgroundImage: 'url(\'/images/paymenthubs/logoSmall.png\')',
          },
        },
      },
    },
    '& .detailPanel': {
      '& .viewTransactionButton': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .dueDate': {
        '& button': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
      '& .sendDateSelect': {
        '& button': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .w9': {
      color: theme.palette.labelColor.main,
      '& a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .assistanceDialog': {
      '& .formCopyLink': {
        '& .textWithLink': {
          '& .copyOfForm, & .correctionOfForm': {
            '& a': {
              textDecoration: 'none',
              color: `${theme.palette.labelColor.main} !important`,
            },
          },
        },
      },
    },
    '& .customCheckbox': {
      '& > div': {
        '& svg': {
          '&:last-child': { //Selected checkbox
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
    },
    '& .mobileTable .tableFirst': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& div.merchantApplicationSection': {
      '& .merchantApplicationBody': {
        '& .merchantApplicationIcon': {
          '& svg': {
            fill: `${theme.palette.labelColor.main} !important`,
          },
        },
        '& .merchantApplicationDescriptionTitle': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .failedPaymentInfo': {
      '& .failureButtonContainer': {
        '& .failureResendButton': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .settings .settingsFormContainer .formHeading.banking span.edit': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .editButtonContainer span': {
      color: theme.palette.labelColor.main,
    },
    '& .pagination': {
      '& li.active': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .paymentPortal': {
      '& .sideContainer': {
        '& .panel': {
          '& .list': {
            '& .active': {
              color: theme.palette.labelColor.main,
            },
          },
          '& .footer': {
            marginTop: 'auto',
            '& p': {
              fontSize: '16px',
              lineHeight: '1.5',
              marginBottom: '0',
              '& b': {
                fontWeight: '800',
              },
              '& span': {
                color: theme.palette.paymentAddressColor,
              },
            },
            '& .branding': {
              '& .img': {
                width: '35px',
                height: '32px',
                backgroundImage: 'url(\'/images/north/logoSmall.svg\')',
              },
            },
          },
        },
      },
    },
    '& .pciFaq': {
      '& section': {
        '& .content': {
          '& a': {
            color: theme.palette.labelColor.main,
          },
        },
      },
    },
    '& .detailList': {
      '& .detailItem': {
        '& .itemMainContainer': {
          '& .itemThumbnailContainer': {
            '& .itemQuantity': {
              background: theme.palette.labelColor.main,
            },
          },
        },
      },
    },
    '& .appSettingsWrapper, & .profileBusinessForm': {
      '& .learnMoreLink': {
        color: theme.palette.labelColor.main,
      },
    },
    '& .receiptSettings .previewContainer .previewButton': {
      color: theme.palette.labelColor.main,
    },
    '& .redux-toastr .toastr .rrt-left-container .toastr-icon': {
      fill: `${theme.palette.labelColor.main} !important`,
    },
    '& .loyaltyProgramContent': {
      '& a': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .generalPointRule, & .rewards': {
        '& span': {
          color: `${theme.palette.labelColor.main} !important`,
        },
      },
      '& .loyaltyFormBottomBar .previewLoyaltyProgram': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .loyaltyProgramSettings': {
      '& .loyaltyPreviewContainer span': {
        color: `${theme.palette.labelColor.main} !important`,
      },
      '& .tollFreeNumber': {
        '& a': {
          color: `${theme.palette.labelColor.main} !important`,
        },
        '& .loyaltySettingsWebsite #submitFormButton': {
          borderColor: `${theme.palette.labelColor.main} !important`,
          '& span': {
            color: `${theme.palette.labelColor.main} !important`,
          },
        },
      },
      '& .businessSettingsRoute': {
        color: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .betaDisclaimerContainer': {
      '& button': {
        borderColor: theme.palette.labelColor.main,
      },
      '& span': {
        color: theme.palette.labelColor.main,
      },
      '& svg': {
        fill: `${theme.palette.labelColor.main} !important`,
      },
    },
    '& .createInvoiceModal .content a': {
      color: `${theme.palette.labelColor.main} !important`,
    },
    '& .paymentLinksDetailRoot .detailContent .paymentLinksDetailContainer .mainInfo .imageContainer .uploadedImage .removeLogo': {
      background: theme.palette.labelColor.main,
    },
  },
  '.mobileDevice': {
    '._elevio_launcher': {
      div: {
        display: 'none !important', // hides elevio needs help button
      },
    },
  },
  '.createPaymentLinkStatusDialog': {

    '.content': {

      '.options': {

        '.option': {

          border: `2px solid ${theme.palette.labelColor.main}  !important`,

          '.optionText': {
            color: `${theme.palette.labelColor.main} !important`,
          },

          '.optionIcon': {
            fill: `${theme.palette.labelColor.main}  !important`,
          }

        }

      }

    }
  },

  '.paymentLinksDetailRoot .detailContent .optionsList': {

    '& .optionsListItem.disabled': {

      '.optionsHolder': {

        'span': {
          color: `${theme.palette.labelColor.main}5c`,

          '& svg': {
            fill: `${theme.palette.labelColor.main}5c`
          },

        }
      }
    }
  },
  '.paymentStatusMessageDialog .paymentLink': {
    color: `${theme.palette.labelColor.main} !important`
  },
  '.paymentStatusMessageDialog .invoiceLink': {
    color: `${theme.palette.labelColor.main} !important`
  },
  '.invoiceStatusMessageDialog .paymentLink': {
    color: `${theme.palette.labelColor.main} !important`
  },
  '.invoiceStatusMessageDialog .invoiceLink': {
    color: `${theme.palette.labelColor.main} !important`
  },
  '.customerDetail .rewardResend .actionContainer .text': {
    color: theme.palette.labelColor.main
  },
  '.customerDetail .rewardResend .actionContainer .icon svg': {
    fill: theme.palette.labelColor.main
  },
  '.reputation': {
    '& .reputationWrapper .reputationContent':{
      '& .competitorPerformaceCard .content .addCompetitor':{
        color: `${theme.palette.labelColor.main} !important`
      },
      '& .reputationSettings .notifications .contactContainer .addContact':{
        color: `${theme.palette.labelColor.main} !important`
      }
    },
    '& .reputationSettings #googleConnectButton': {
      color: `${theme.palette.labelColor.main} !important`
    },
    '.competitorsBar .addCompetitor':{
      color: `${theme.palette.labelColor.main} !important`
    }
  },
  '.paymentStatusMessageDialog': {
    '.loyaltyStatusMessage': {
      '.loyaltyIcon': {
        'path': {
          fill: `${theme.palette.labelColor.main} !important`
        }
      }
    }
  },
  '.returnLabel .returnLabelBody .returnLabelBodyActions .returnLabelBodyExport': {
    color: `${theme.palette.labelColor.main} !important`
  },
  '.returnLabel .returnLabelBody .returnLabelBodyActions .returnLabelBodyExport svg': {
    fill: `${theme.palette.labelColor.main} !important`
  },
});


export default styles;
