/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray } from 'redux-form';
import numeral from 'numeral';
import Button from '../shared/Button';
import TextField from '../shared/TextField'
import CustomToggle from '../shared/Toggle';
import IconButton from '../shared/IconButton';
import IconUtils from './../util/IconUtil';
import FormatTextUtil from '../util/FormatTextUtil'
import Validator from '../util/Validator';

const validate = (values, props) => {
  return Validator.validateModifiers(values, props)
};

const renderToggle = ({ input, label, disabled }) => (
  <CustomToggle label={label}
    input={input}
    toggled={!!input.value}
    labelPosition='end'
    onToggle={input.onChange}
    disabled={disabled}
  />
);

export const renderModifiersField = ({ fields, meta: { touched, error }, disabled, ...props }) => (
  <div>
    <div className='modifiers'>

      {error && fields.length === 0 ?
        <span className='errorText'>{props.t(error)}</span>
        : null}

      {fields.map((item, index) =>
        <div key={index} className='flexRow modifierRow'>

          <Field
            className='name errorSpacingBottom'
            fullWidth={true}
            component={TextField}
            name={`${item}.name`}
            label={props.t('Modifiers.ModifierNameLabel')}
            disabled={disabled}
          />

          <Field
            className='modifierPrice'
            component={TextField}
            fullWidth={true}
            format={(value) => numeral(value).format('$0,0.00')}
            normalize={(value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 7)}
            name={`${item}.price`}
            hintText='$0.00'
            label={props.t('Modifiers.PriceLabel')}
            disabled={disabled}
          />

          {!disabled && (
            <IconButton onClick={() => fields.remove(index)} className='removeRow' size='large'>{IconUtils.getIcon('DeleteIcon')}</IconButton>
          )}

        </div>
      )}

    </div>

    {!disabled && (
      <Button
        className='simpleButton'
        onClick={() => fields.push({})}
        startIcon={IconUtils.getIcon('PlusIcon')}
        label={props.t('Modifiers.AddModifier')}
        variant='text'
      />
    )}

  </div>
);

class ModifiersDetail extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { handleSubmit, readOnly, t } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className='modifiersDetail'>
          <Field
            component={TextField}
            label={t('Modifiers.ModifierSetName')}
            fullWidth={true}
            name='modifierSet.name'
            disabled={readOnly}
          />
          <br /> <br />
          <div className='detailsCheckBox'>
            <Field
              label={t('Modifiers.AllowMultipleSelections')}
              name='modifierSet.can_select_multiple'
              component={renderToggle}
              disabled={readOnly}
            />
          </div>
          <br /> <br />
          <FieldArray
            name='modifierSet.modifiers'
            component={renderModifiersField}
            disabled={readOnly}
            t={t}
          />
        </div>
      </form>
    )
  }
}

ModifiersDetail = reduxForm({
  form: 'modifiersDetailForm',
  validate,
  fields: [
    'name',
    'can_select_multiple',
    'modifiers'
  ],
  enableReinitialize: true,
})(ModifiersDetail);

function mapStateToProps(state, ownProps) {

  let selectedModifierSet = ownProps?.item;

  let existingModifiers = [];

  if (selectedModifierSet && selectedModifierSet.id) {
    existingModifiers = selectedModifierSet?.modifiers || [];
  } else {
    selectedModifierSet.modifiers = [{ name: '', price: 0 }];
  }

  return {
    initialValues: {
      modifierSet: selectedModifierSet,
      existingModifiers: existingModifiers,
      isNewItem: ownProps?.item && !ownProps?.item?.id,
    },
    modifierSets: state?.items?.modifierSets || []
  }

}

ModifiersDetail = connect(mapStateToProps)(ModifiersDetail);

export default ModifiersDetail;
