/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

export default function GoToIcon({style}) {
  return (
    <svg
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M8 14.7499C7.78717 14.7499 7.609 14.6781 7.4655 14.5344C7.32183 14.3909 7.25 14.2127 7.25 13.9999V9.05765C7.25 8.55899 7.42658 8.13299 7.77975 7.77965C8.13308 7.42649 8.55908 7.2499 9.05775 7.2499H17.6115L15.3135 4.9519C15.1648 4.80307 15.0888 4.6264 15.0855 4.4219C15.0823 4.21757 15.1583 4.03782 15.3135 3.88265C15.4687 3.72749 15.6468 3.6499 15.848 3.6499C16.0493 3.6499 16.2276 3.72749 16.3828 3.88265L19.8673 7.36715C20.0481 7.54799 20.1385 7.7589 20.1385 7.9999C20.1385 8.2409 20.0481 8.45182 19.8673 8.63265L16.3828 12.1269C16.2443 12.2717 16.0728 12.3467 15.8682 12.3519C15.6637 12.3569 15.4839 12.2819 15.3288 12.1269C15.1738 11.9717 15.0952 11.7935 15.0932 11.5922C15.0914 11.391 15.1648 11.2128 15.3135 11.0577L17.6115 8.7499H9.05775C8.96792 8.7499 8.89417 8.77874 8.8365 8.8364C8.77883 8.89407 8.75 8.96782 8.75 9.05765V13.9999C8.75 14.2127 8.67817 14.3909 8.5345 14.5344C8.391 14.6781 8.21283 14.7499 8 14.7499ZM5.30775 20.4999C4.80908 20.4999 4.38308 20.3233 4.02975 19.9702C3.67658 19.6168 3.5 19.1908 3.5 18.6922V5.05765C3.5 4.84482 3.57183 4.66657 3.7155 4.5229C3.859 4.3794 4.03717 4.30765 4.25 4.30765C4.46283 4.30765 4.641 4.3794 4.7845 4.5229C4.92817 4.66657 5 4.84482 5 5.05765V18.6922C5 18.782 5.02883 18.8557 5.0865 18.9134C5.14417 18.9711 5.21792 18.9999 5.30775 18.9999H16.6923C16.7821 18.9999 16.8558 18.9711 16.9135 18.9134C16.9712 18.8557 17 18.782 17 18.6922V15.4999C17 15.2871 17.0718 15.1088 17.2155 14.9652C17.359 14.8217 17.5372 14.7499 17.75 14.7499C17.9628 14.7499 18.141 14.8217 18.2845 14.9652C18.4282 15.1088 18.5 15.2871 18.5 15.4999V18.6922C18.5 19.1908 18.3234 19.6168 17.9703 19.9702C17.6169 20.3233 17.1909 20.4999 16.6923 20.4999H5.30775Z'
      />
    </svg>
  )
}
