/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

'use strict';

import imageUtil from './ImageUtil';
import itemsUtil from './ItemsUtil';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import ReportUtil from './ReportUtil';
import FilterUtil from './FilterUtil';
import UserUtil from './UserUtil';
import FormatTextUtils from './FormatTextUtil';
import SalesDataUtil from '../util/SalesDataUtil';
import InvoiceUtil from './InvoiceUtil';
import TransactionsUtil from './TransactionsUtil';
import { roundToTwoDecimals } from '../util/CommonUtil';
import {
  customFieldHeaders,
  generateCustomFieldRows
} from '../util/ReportUtil';
import Papa from 'papaparse';

const csvUtil = {

  buildFilename(csvReportName, props) {

    let accountNumber = props?.userExperience?.allAccounts ? 'AllAccounts' :
      _.find(props?.user?.data?.merchantAccounts, ['mea_id', Number(props?.user?.selectedMerchantAccount)]).mid;

    let startDate = props?.reportDate && props.reportDate?.startDate ? '_FROM_' + moment(props.reportDate?.startDate).format('MMDDYYYY') : '';
    let endDate = props?.reportDate && props.reportDate?.endDate ? '_TO_' + moment(props.reportDate.endDate).format('MMDDYYYY') : '';

    return csvReportName + '_' + accountNumber + '_' + moment().format('MMDDYYYY') + startDate + endDate + '.csv';

  },

  createHeaders(array) {

    // Merge all keys, and avoid duplicates using Set
    const singleTransactionKeys = array.reduce((acc, item)=>{
        return [...new Set([...acc, ...Object.keys(item)])]
    },[]);

    return singleTransactionKeys.length ? singleTransactionKeys : null;
  },

  constructTransactionsWithItemsHeaders(array) {

    if (array && array.length) {
      return this.createHeaders(array);
    } else {
      return ['Date', 'Transaction Source', 'Account Number', 'Invoice', 'Category', 'Item Name', 'Modifiers Applied', 'Modifiers Prices', 'Item Quantity', 'Unit Price', 'Discount $', 'Discount %', 'Tax %', 'Total Transaction Amount', 'Transaction Type', 'Sold By', 'Placed For', 'Customer'];
    }

  },

  constructTransactionsWithCategoriesHeaders(array) {

    if (array && array.length) {
      return this.createHeaders(array);
    } else {
      return ['Transaction Source', 'Account Number', 'Date & Time', 'Invoice', 'Sold By', 'Total Transaction Amount', 'Payment Type', 'Card Brand', 'First 6', 'Last 4', 'Comment'];
    }

  },

  constructItemExportHeaders(array) {

    if (array && array.length) {
      return this.createHeaders(array);
    } else {
      return ['Item Name', 'Item Code', 'Favorite', 'Tax', 'Trackable', 'Allow out of stock sales', 'Modifier Set', 'Price Name', 'Price Value', 'Price SKU', 'Cost', 'Stock Count', 'Low Stock Alert']
    }

  },

  constructExportItem(items, user) {
    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);
    let array = [];
    if (items && items.length) {
      items.map((item) => {
        let itemColumns = {};
        itemColumns['Account Number'] = account.mid;
        itemColumns['DBA'] = account.dba_name;
        itemColumns['Item Name'] = item.name;
        itemColumns['Item Code'] = item.id;
        item.categories.map((category, i) => {
          itemColumns[`Item Category ${i + 1}`] = (category && category.name) ? category.name : '';
        });
        itemColumns['Favorite'] = (item.is_favorite) ? 'Yes' : 'No';
        itemColumns['Tax'] = (item.is_taxable) ? 'Yes' : 'No';
        itemColumns['Trackable'] = (item.is_trackable) ? 'Yes' : 'No';
        itemColumns['Allow out of stock sales'] = (item.allow_outof_stock) ? 'Yes' : 'No';
        if (item.details?.modifierSets && item.details.modifierSets.length) {
          item.details.modifierSets.forEach((modifierSet, i) => {
            if (modifierSet && modifierSet.isSelected && modifierSet.name) {
              itemColumns[`Modifier Set ${i + 1}`] = modifierSet.name;
            } else {
              itemColumns[`Modifier Set ${i + 1}`] = '';
            }
          });
        }
        item.details.prices.map((price, i) => {
          itemColumns[`Price Name ${i + 1}`] = (price.name) ? price.name : '';
          itemColumns[`Price Value ${i + 1}`] = (price.price) ? `$${price.price.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}` : '';
          itemColumns[`Price SKU ${i + 1}`] = (price.barcode) ? price.barcode : '';
          itemColumns[`Cost ${i + 1}`] = (price.cost) ? `$${price.cost}` : '';
          if (price.name) {
            itemColumns[`Stock Count ${i + 1}`] = (price.quantity) ? price.quantity : '0';
          } else {
            itemColumns[`Stock Count ${i + 1}`] = (price.quantity) ? price.quantity : '';
          }
          itemColumns[`Low Stock Alert ${i + 1}`] = (price.low_stock_alert_amount) ? price.low_stock_alert_amount : '0';
        });
        array.push(itemColumns);
      });
    }
    return array;
  },

  createTransactionWithItemsJson(props) {
    let transactionsWithItems = [];

    const debtRepayment = props.auth.debtRepayment;

    if (props && props.transactions) {

      let transactions = props.transactions.receipts;

      if (transactions && transactions.length) {

        transactions.forEach((transaction) => {
          if (transaction.item_names) {

            transaction.item_names.forEach((item, i) => {

              let itemId = transaction.item_ids[i];

              let itemModifierSets = transaction.modifier_sets[i];

              let mappedModifierSets = !!itemModifierSets && itemModifierSets.length && Array.isArray(itemModifierSets) ? transaction.modifier_sets.map(InvoiceUtil.mapModifiers)[i] : [];

              let matchedModifierSets = !!mappedModifierSets && mappedModifierSets.length ? mappedModifierSets.filter(modifierSet => modifierSet.id == itemId).map(modifierSet => modifierSet.name) : [];

              let itemModifiersApplied = matchedModifierSets.length ? matchedModifierSets.join(', '): '';

              let matchedModifierPrices = mappedModifierSets.length ? mappedModifierSets.filter(modifierSet => modifierSet.id == itemId).map(modifierSet => numeral(modifierSet.price).format('$0,0.00')) : [];

              let itemModifiersPrices = matchedModifierPrices.length ? matchedModifierPrices.join(', ') : '';

              let date = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
              let accountNumber = transaction.mid;
              let dba = _.find(props.user.data.merchantAccounts, ['mid', transaction.mid]).dba_name;
              let invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);
              let itemCategory = transaction && transaction.item_categories && transaction.item_categories[i] ? JSON.parse(transaction.item_categories[i]) : '';
              let itemName = item ? item : 'Unnamed - ' + i;
              let itemQuantity = transaction.item_quantity && transaction.item_quantity.length > i ? transaction.item_quantity[i] : 0;
              let itemUnitPrice = transaction.item_unit_price && transaction.item_unit_price.length > i ? transaction.item_unit_price[i] : 0;
              let itemUnitPriceFormatted = numeral(itemUnitPrice).format('$0,0.00');
              let itemDiscountAmount = transaction.item_discount_amt && transaction.item_discount_amt.length > i ? transaction.item_discount_amt[i] : 0;
              let itemDiscountAmountFormatted = numeral(itemDiscountAmount).format('$0,0.00');
              let itemDiscountRate = transaction.item_discount_rate && transaction.item_discount_rate.length > i ? transaction.item_discount_rate[i] : 0;
              let itemDiscountRateFormatted = numeral(itemDiscountRate / 100).format('0.00%');
              let itemTaxRateFormatted = transaction.item_tax_rate && transaction.item_tax_rate.length > i ? numeral(transaction.item_tax_rate[i] / 100).format('0.000%') : 'n/a';
              let totalTransactionAmount = transaction.total_amt;
              let totalTransactionAmountFormatted = numeral(totalTransactionAmount).format('$0,0.00');
              let transactionType = transaction.type;
              let soldBy = transaction.sold_by;
              let placedFor = transaction.sold_by_override_name;
              let customer = FormatTextUtils.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');
              let transactionSource = FilterUtil.getTransactionSource(transaction);

              let transactionWithItems = {};
              transactionWithItems['Date'] = date;
              transactionWithItems['Transaction Source'] = transactionSource;
              transactionWithItems['Account Number'] = accountNumber;
              transactionWithItems['DBA'] = dba;
              transactionWithItems['Invoice'] = invoiceNumber;
              transactionWithItems['Category'] = itemCategory;
              transactionWithItems['Item Name'] = itemName;
              transactionWithItems['Modifiers Applied'] = itemModifiersApplied;
              transactionWithItems['Modifiers Prices'] = itemModifiersPrices;
              transactionWithItems['Item Quantity'] = itemQuantity;
              transactionWithItems['Unit Price'] = itemUnitPriceFormatted;
              transactionWithItems['Discount $'] = itemDiscountAmountFormatted;
              transactionWithItems['Discount %'] = itemDiscountRateFormatted;
              transactionWithItems['Tax %'] = itemTaxRateFormatted;
              transactionWithItems['Total Transaction Amount'] = totalTransactionAmountFormatted;
              transactionWithItems['Transaction Type'] = transactionType;
              transactionWithItems['Sold By'] = soldBy;
              transactionWithItems['Placed For'] = placedFor;
              transactionWithItems['Customer'] = customer;
              if (debtRepayment) {
                transactionWithItems['Customer Account Number'] = transaction.customer_account_number ? transaction.customer_account_number : '';
              }
              if (transaction.custom_fields?.custom_field_1) {
                transactionWithItems['Custom Field'] = transaction.custom_fields.custom_field_1.value;
              }

              transactionsWithItems.push(transactionWithItems);
            });
          }
        });
      }
    }

    return transactionsWithItems;
  },

  createTransactionWithCategoriesJson(transactions, user) {
    let transactionsWithCategories = [];

    if (transactions && transactions.length) {

      transactions.forEach((transaction) => {
        let invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);
        let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount;
        const transactionSource = FilterUtil.getTransactionSource(transaction);

        let transactionWithCategories = {};
        transactionWithCategories['Transaction Source'] = transactionSource;
        transactionWithCategories['Account Number'] = transaction.mid;
        transactionWithCategories['DBA'] = _.find(user.data.merchantAccounts, ['mid', transaction.mid]) ? _.find(user.data.merchantAccounts, ['mid', transaction.mid]).dba_name : null;
        transactionWithCategories['Date & Time'] = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
        transactionWithCategories['Invoice'] = invoiceNumber;
        transactionWithCategories['Sold By'] = transaction.sold_by;
        transactionWithCategories['Placed For'] = transaction.sold_by_override_name;
        transactionWithCategories['Payment Type'] = TransactionsUtil.getAchTransactionName(transaction);
        transactionWithCategories['Card Brand'] = TransactionsUtil.getAchNetwork(transaction);
        transactionWithCategories['First 6'] = transaction.cc_first6;
        transactionWithCategories['Last 4'] = transaction.cc_last4;
        transactionWithCategories['Routing Number'] = transaction.routing_nbr
        transactionWithCategories['Comment'] = transaction.comment;

        if (transaction.custom_fields?.custom_field_1) {
          transactionWithCategories['Custom Field'] = transaction.custom_fields.custom_field_1.value;
        }

        transactionWithCategories['Total Transaction Amount'] = numeral(amount).format('$0,0.00');

        if (transaction.item_categories) {

          let itemSubtotalsWithCd = [];
          const cdVersion = transaction.cd_version;
          const itemModifierSets = transaction.modifier_sets || [];

          if (cdVersion === 4) { // CD 4 gives us some other information we can use to simplify this calc.
            itemSubtotalsWithCd = transaction.item_names.map((item, i ) => {
              const mappedModifierSets =  itemModifierSets.map(InvoiceUtil.mapModifiers)[i] || [];
              let totalModifierPrices = mappedModifierSets.reduce((acc, modifier) => acc + parseFloat(modifier.total_amt),0); // includes CD
              const priceTotalAmount = transaction.price_total_amount[i]; // this does not include discounts,  nor modifiers, just CD is included.
              const discountTotalAmount = transaction.item_discount_total_amt[i] || 0;
              return (priceTotalAmount + totalModifierPrices) - discountTotalAmount;
            })
          } else {
            const itemAmounts = transaction.item_names.map((item, i) => {

              const mappedModifierSets =  itemModifierSets.map(InvoiceUtil.mapModifiers)[i] || [];
              let totalModifierPrices = mappedModifierSets.reduce((acc, modifier) => acc + parseFloat(modifier.price),0);

              let itemAmount = transaction.item_unit_price[i];
              const itemQuantity = transaction.item_quantity[i];
              const totalItemAmount =  itemAmount * itemQuantity;

              const totalModifiersAmount =  totalModifierPrices * itemQuantity;

              let totalDiscountsAmount = transaction.item_discount_amt[i];
              const hasDiscountAmt = Boolean(transaction.item_discount_amt[i]);
              let totalDiscountRate = transaction.item_discount_rate[i] / 100;
              const hasDiscountRate = Boolean(transaction.item_discount_rate[i]);

              let discount = 0;

              if (hasDiscountRate) {
                discount = (totalModifiersAmount + totalItemAmount) * totalDiscountRate;
              } else if (hasDiscountAmt) {
                discount = totalDiscountsAmount;
              }

              return {
                itemDiscount: discount,
                itemTotalPrice: totalItemAmount,
                itemTotalModifiers: totalModifiersAmount
              }
            });
            const itemsSubtotalNoCdSum = itemAmounts.reduce((acc, item) =>
              acc + (item.itemTotalPrice + item.itemTotalModifiers - item.itemDiscount), 0);

            const serviceFeeAmount = transaction.service_fee_amount || '0';
            const subTotalWithCd = itemsSubtotalNoCdSum + parseFloat(serviceFeeAmount);

            const cdPerDollar = serviceFeeAmount / itemsSubtotalNoCdSum;

            let itemAmountsWithCd = itemAmounts.map(item => {
              const itemDiscountWithCd = roundToTwoDecimals(item.itemDiscount + (item.itemDiscount * cdPerDollar));
              const itemTotalPriceWithCd = roundToTwoDecimals(item.itemTotalPrice + (item.itemTotalPrice * cdPerDollar));
              const itemTotalModifiersWithCd = roundToTwoDecimals(item.itemTotalModifiers + (item.itemTotalModifiers * cdPerDollar));

              return {
                itemDiscountWithCd,
                itemTotalPriceWithCd,
                itemTotalModifiersWithCd,
                itemSubtotalWithCd: itemTotalModifiersWithCd + itemTotalPriceWithCd - itemDiscountWithCd
              };
            });

            // Check if floating penny for computed subtotal
            const computedSubTotalWithCd = itemAmountsWithCd.reduce((acc, item) => acc + item.itemSubtotalWithCd, 0);
            const floatingPenny = Math.abs(parseFloat(numeral(subTotalWithCd - computedSubTotalWithCd).format('0.0[0000]')));

            itemSubtotalsWithCd = [...itemAmountsWithCd.map(item => item.itemSubtotalWithCd)];

            if (floatingPenny > 0) { // if floating penny, round up item with max price
              const maxPriceIndex = itemSubtotalsWithCd.indexOf(Math.max(...itemSubtotalsWithCd));
              itemSubtotalsWithCd = itemAmountsWithCd.map((item, i) => {
                let itemTotalPriceWithCd =  item.itemTotalPriceWithCd;

                if (i === maxPriceIndex) {
                  const roundUp = 0.9;
                  itemTotalPriceWithCd = Math.round((item.itemTotalPriceWithCd * 100) + roundUp) / 100;
                }

                return item.itemTotalModifiersWithCd + itemTotalPriceWithCd - item.itemDiscountWithCd;
              })
            }
          }


          transaction.item_categories.forEach((itemCategoryName, i) => {
            let columnName = (itemCategoryName === '[]' || itemCategoryName === null) ? 'No Category' : JSON.parse(itemCategoryName)[0];
            if (transactionWithCategories[columnName + ' Amount']) {
                transactionWithCategories[columnName + ' Amount'] = transactionWithCategories[columnName + ' Amount'] + itemSubtotalsWithCd[i];
            } else {
                transactionWithCategories[columnName + ' Amount'] = itemSubtotalsWithCd[i];
            }
          });

          transaction.item_categories.forEach((itemCategoryName, i) => {
            let columnName = (itemCategoryName === '[]' || itemCategoryName === null) ? 'No Category' : JSON.parse(itemCategoryName)[0];
            if (transactionWithCategories[columnName + ' Amount']) {
              transactionWithCategories[columnName + ' Amount'] = numeral(transactionWithCategories[columnName + ' Amount']).format('$0,0.00');
            }
          });
        }

        transactionsWithCategories.push(transactionWithCategories);
      });
    }
    return transactionsWithCategories;
  },

  getItemsFromCsv: function (importedCsvContent) {

    return new Promise(
      (resolve, reject) => {

        let itemValues = [];

        let keyMapping = {
          'Item Name': 'name',
          'Category': 'categories',
          'Favorite': 'is_favorite',
          'Tax': 'is_taxable',
          'Trackable': 'is_trackable',
          'Allow out of stock sales': 'allow_outof_stock',
          'ImageUrl': 'image_url'
        };

        for (let rowIndex = 0; rowIndex < importedCsvContent.data.length; rowIndex++) {
          /* istanbul ignore if  */
          if (rowIndex > 0) {
            let row = importedCsvContent.data[rowIndex];

            let itemObj = {prices: []};

            let valueIndex = 0;

            for (let rowValue of row) {
              let header = importedCsvContent.data[0][valueIndex].trim();
              let key = keyMapping[header] || header;
              let value = rowValue.trim();

              if (_.head(key.split(' ')) == 'Price') {

                let parsedPriceValue = null;
                let priceRowData = row[valueIndex + 1].trim().replace('$', '');
                let parsedCostValue = null;
                let costRowData = row[valueIndex + 2].trim().replace('$', '');

                if (!isNaN(priceRowData) && _.size(priceRowData)) {
                  parsedPriceValue = parseFloat(numeral(priceRowData).value());
                }

                if (!isNaN(costRowData) && _.size(costRowData)) {
                  parsedCostValue = parseFloat(numeral(costRowData).value());
                }

                let price = {
                  name: row[valueIndex].trim(),
                  price: parsedPriceValue,
                  cost: parsedCostValue,
                  barcode: row[valueIndex + 3].trim(),
                  quantity: _.size(row[valueIndex + 4]) ? parseInt(row[valueIndex + 4].trim()) : 0,
                  low_stock_alert_amount: _.size(row[valueIndex + 5]) ? parseInt(row[valueIndex + 5].trim()) : 0,
                };

                price.stock_enabled = !isNaN(price.quantity) && price.quantity > 0; // true if valid quantity value

                if (_.size(price.name) || price.price !== null) {
                  itemObj.prices.push(price);
                }

                valueIndex += 6;
              } else {

                if (['YES', 'NO'].includes(value.trim().toUpperCase())) {
                  itemObj[key] = (value.trim().toUpperCase() === 'YES');
                } else {
                  itemObj[key] = value.trim();
                }
                valueIndex++;
              }

              if (valueIndex >= importedCsvContent.data[0].length) {
                break;
              }
            }

            const completeItem = itemsUtil.formatCsvItem(itemObj);
            let {result, reason} = itemsUtil.validateItem(completeItem);
            if (result) {
              itemValues.push(completeItem);
            } else {
              reject({reason, completeItem})
            }
          }
        }

        Promise.all(itemValues.map(i => {
          return imageUtil.getImage(i.image_url);
        }))
          .then(images => {
            itemValues.forEach((v, i, a) => {
              if (!_.isNull(images[i].data)) {
                a[i].image_data = images[i].data;
              }
              a[i].image_message = images[i].message;
            });
            resolve(itemValues);
          });
      });
  },

  createTaxCsv: function (transactions, user) {

    const itemTaxRateNoNullTxns = transactions.map((transaction) => {
      transaction.item_tax_rate = [...new Set(transaction.item_tax_rate)];
      transaction.item_tax_rate = transaction.item_tax_rate.filter((item) => item !== null);
      return transaction;
    });

    let groupedTransactions = _(itemTaxRateNoNullTxns)
      .groupBy('mid')
      .map()
      .value();

    groupedTransactions = groupedTransactions.map((transactions, i) =>
      _(transactions).groupBy('item_tax_rate').value());

    groupedTransactions = groupedTransactions.map((tax) => Object.values(tax));

    groupedTransactions = _(groupedTransactions)
      .map((transactions) =>
        transactions.map((innerTransactionsArray) =>
          innerTransactionsArray.map((singleTransaction) => {
            const mid = singleTransaction.mid;
            return ({
              accountNumber: mid,
              dba: _.find(user.data.merchantAccounts, ['mid', mid]) ? _.find(user.data.merchantAccounts, ['mid', mid]).dba_name : null,
              stateTax: _.result(_.find(innerTransactionsArray, transaction => {
                return transaction.state_tax > 0;
              }), 'state_tax'),
              customTax: _.result(_.find(innerTransactionsArray, transaction => {
                return transaction.customized_tax > 0;
              }), 'customized_tax'),
              taxCollected: _.sumBy(innerTransactionsArray, function (transaction) {

                if (!transaction.partial_tax || !transaction.type) {
                  return 0;
                }

                if (transaction.type === 'Credit Sale' ||
                  transaction.type === 'Cash Sale' ||
                  transaction.type === 'ACH Sale' ||
                  transaction.type === 'Credit Sale - Split Payment' ||
                  transaction.type === 'Cash Sale - Split Payment' ||
                  transaction.type === 'Credit Refund' ||
                  transaction.type === 'Cash Refund' ||
                  transaction.type === 'ACH Refund' ||
                  transaction.type === 'Void' ||
                  transaction.type === 'ACH Void') {
                  return Math.round(parseFloat(transaction.partial_tax, 10) * 100) / 100;
                } else { // Credit Card Voids, Credit Cashback
                  return 0;
                }

              }),
              taxableAmount: _.sumBy(innerTransactionsArray, function (transaction) {
                //We want to use the minimum of the amount and the authorized amount.
                //This value is how the partial_tax from the taxCollected calculation is determined.
                //Note: We only want to count taxable values if a tax is included.
                if (!transaction.partial_tax) {
                  return 0;
                }

                const amount = transaction.taxable_amt || transaction.partial_sub_total_amt || transaction.sub_total_amt || transaction.amount;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }),
              itemTaxRate: _.result(_.find(innerTransactionsArray), 'item_tax_rate'),
            })}))).value();

    //The taxed percentage is taxes / taxable amount. This shows the
    //actual percentage of taxes collected.
    const taxedPercentage = (taxAmount, taxableAmount) => {
      if (!taxAmount || !taxableAmount) {
        return 0;
      }
      return Math.min(taxAmount / taxableAmount, 1);
    };

    groupedTransactions = groupedTransactions.map((arrayOfArrays) =>
      arrayOfArrays.map((array) =>
        _.uniqBy(array, 'accountNumber')));

    let toReturn = groupedTransactions.map((arrayOfArrays, index) => {
      return arrayOfArrays.map((array) => {
        return array.map((transaction) => {
          return {
            'Account Number': transaction.accountNumber,
            'DBA': transaction.dba,
            'State Tax': transaction.stateTax ? numeral(transaction.stateTax / 100).format('0.000 %') : 'n/a',
            'Custom Tax': transaction.customTax ? numeral(transaction.customTax / 100).format('0.000 %') : 'n/a',
            //Taxed Percentage uses item_tax_rate which is supplied by api, if not present, we use prior calculation
            'Taxed Percentage': transaction?.itemTaxRate?.length === 1 ? numeral(transaction.itemTaxRate / 100).format('0.000 %') : numeral(taxedPercentage(transaction.taxCollected, transaction.taxableAmount)).format('0.000 %'),
            'Taxable Amount': numeral(transaction.taxableAmount).format('$0,0.00'),
            'Tax Collected': numeral(transaction.taxCollected).format('$0,0.00')
          }
        })
      })
      }
    );

    //filters out tax collected = 0 since it does not impact report figures
    toReturn = _.flattenDeep(toReturn);

    return toReturn;

  },

  createAccountSummaryCsv: function (reports) {

    let groupedTransactions = [];

    _.each(reports, row => {
      let volumeData = SalesDataUtil.volumeData(row);

      groupedTransactions.push({
        accountNumber: row.mid,
        dba: row.dba,
        grossSales: volumeData.grossSalesTotal,
        refunds: volumeData.refundsTotal,
        unpaid: volumeData.unpaidAmount,
        voids: volumeData.voidsTotal,
        discounts: volumeData.discountsTotal,
        taxCollected: volumeData.tax,
        tipsCollected: volumeData.tipsTotal,
        totalCollected: (volumeData.totalNetSales + volumeData.tax + volumeData.tipsTotal)
      })
    });

    let totals = {
      accountNumber: 'TOTALS: ',
      grossSales: _.sumBy(groupedTransactions, (row) => row.grossSales),
      refunds: _.sumBy(groupedTransactions, (row) => row.refunds),
      unpaid: _.sumBy(groupedTransactions, (row) => row.unpaid),
      voids: _.sumBy(groupedTransactions, (row) => row.voids),
      discounts: _.sumBy(groupedTransactions, (row) => row.discounts),
      taxCollected: _.sumBy(groupedTransactions, (row) => row.taxCollected),
      tipsCollected: _.sumBy(groupedTransactions, (row) => row.tipsCollected),
      totalCollected: _.sumBy(groupedTransactions, (row) => row.totalCollected)
    };

    groupedTransactions.push(totals);

    return groupedTransactions.map((transaction) => {
      return {
        'Account Number': transaction.accountNumber,
        'DBA': transaction.dba,
        'Gross Sales': numeral(transaction.grossSales).format('$0,0.00'),
        'Refunds': numeral(transaction.refunds).format('$0,0.00'),
        'Voids': numeral(transaction.voids).format('$0,0.00'),
        'Unpaid': numeral(transaction.unpaid).format('$0,0.00'),
        'Discounts': numeral(transaction.discounts).format('$0,0.00'),
        'Tax': numeral(transaction.taxCollected).format('$0,0.00'),
        'Tips': numeral(transaction.tipsCollected).format('$0,0.00'),
        'Total Collected': numeral(transaction.totalCollected).format('$0,0.00'),
      }
    });
  },

  createCardBrandSummaryCsv: function (transactions, user, isCashDiscountEnabled = false) {

    const noNullTransactions = _.filter(transactions, (transaction => (transaction.network)));
    const uniqueBrandSales = _.sortBy(_(noNullTransactions).countBy('network').map((count, name) => ({
      name,
      count
    })).value(), 'count');

    let groupedCardBrandSummary = _.map(uniqueBrandSales, function (brand) {
      const noNullBrandTransactions = _.filter(noNullTransactions, (transaction => transaction.network === brand.name));
      const brandTransactions = _.filter(noNullBrandTransactions, (transaction => _.find(user.data.merchantAccounts, ['mid', transaction.mid])));
      return _(brandTransactions).groupBy('mid').map((transactions, mid) => ({
        accountNumber: mid,
        dba: _.find(user.data.merchantAccounts, ['mid', mid]).dba_name,
        transactions: transactions.length,
        brand: brand.name,
        grossSales: _.sumBy(transactions, function (transaction) {
          const shouldBeProcessed = !(transaction.type?.includes('Refund') || (transaction.type?.includes('Void')));
          if (shouldBeProcessed) {
            let amount;
            const subtotalAmount = numeral(transaction?.sub_total_amt).value();
            const paidAmount = numeral(transaction?.ccs_authorized_amt).value();
            const tipAmount = numeral(transaction?.tip_amount).value();
            const taxAmount = numeral(transaction?.tax_amt).value();
            const amountDifference = subtotalAmount - (paidAmount - (tipAmount + taxAmount));
            if (amountDifference > 0) {
              amount = Math.abs(subtotalAmount - amountDifference);
            } else {
              amount = subtotalAmount;
            }
            return Math.round(parseFloat(amount) * 100) / 100;
          }
        }),
        taxes: transactions.reduce((taxes_acc, transaction) => {
          if (transaction.type?.includes('Refund') || transaction.type?.includes('Void')) {
            const amount = transaction.partial_tax ? numeral(transaction.partial_tax).value() : 0;
            return taxes_acc + Math.round(parseFloat(amount) * 100) / 100;
          } else {
            return taxes_acc + +transaction.tax_amt;
          }
        }, 0),
        tips: _.sumBy(transactions, transaction => {
          const tipFee = transaction?.tip_amount ? numeral(transaction?.tip_amount).value() : 0;
          return Math.round(parseFloat(tipFee) * 100) / 100;
        }),
        refunds: _.sumBy(transactions, transaction => {
          if (transaction.type?.includes('Refund')) {
            let amount = 0;
            let tax = 0;
            const tip = transaction?.tip_amount ? Math.abs(numeral(transaction.tip_amount).value()) : 0;
            const subtotal = numeral(transaction.partial_sub_total_amt || transaction.sub_total_amt).value();
            const authorized = numeral(transaction.ccs_authorized_amt).value();

            if(subtotal === authorized) {
              amount = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).value() : numeral(transaction.amount).value();
              tax = transaction?.partial_tax ? Math.abs(numeral(transaction.partial_tax).value()) : 0;
              amount = amount + tax + tip;
            } else {
              amount = transaction.partial_sub_total_amt ? +transaction.partial_sub_total_amt : +transaction.amount;
            }

            return amount;
          }
        }),
        voids: _.sumBy(transactions, transaction => {
          if (transaction.type?.includes('Void')) {
            const amount = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).value() : numeral(transaction.amount).value();
            const tax = transaction?.tax_amt ? Math.abs(numeral(transaction.tax_amt).value()) : 0;
            const tip = transaction?.tip_amount ? Math.abs(numeral(transaction.tip_amount).value()) : 0;
            const total = amount + tax + tip;
            return Math.round(parseFloat(total) * 100) / 100;
          }
        }),
        netSales: _.sumBy(transactions, transaction => {
          let amount = 0;
          const saleTypes = ['Credit Sale', 'ACH Sale'];
          const otherTypes = ['Cash Sale', 'Credit Sale - Split Payment', 'Cash Sale - Split Payment'];
          const voidTypes = ['Void', 'ACH Void'];
          if (saleTypes.includes(transaction?.type)) {
            const subtotalAmount = numeral(transaction?.sub_total_amt).value();
            const paidAmount = numeral(transaction?.ccs_authorized_amt).value();
            const tipAmount = numeral(transaction?.tip_amount).value();
            const taxAmount = numeral(transaction?.tax_amt).value();
            const amountDifference = subtotalAmount - (paidAmount - (tipAmount + taxAmount));
            if (amountDifference > 0) {
              amount = Math.abs(subtotalAmount - amountDifference);
            } else {
              amount = subtotalAmount;
            }
          } else if (otherTypes.includes(transaction?.type)) {
            amount = numeral(transaction.sub_total_amt).value() || numeral(transaction.taxable_amt).value();
          } else if (voidTypes.includes(transaction?.type)) {
            const subtotalAmount = +transaction?.sub_total_amt;
            const tipAmount = +transaction?.tip_amount;
            const taxAmount = +transaction?.tax_amt;
            const total = Math.round(parseFloat(subtotalAmount + tipAmount + taxAmount, 10) * 100) / 100;
            const ccsAuthorizedAmt = +transaction.ccs_authorized_amt;

            if(total === ccsAuthorizedAmt) {
              amount = subtotalAmount;
            } else {
              amount = ccsAuthorizedAmt || +transaction.amount;
            }

          } else if (transaction.type?.includes('Refund')) {
            const subtotal = numeral(transaction.partial_sub_total_amt || transaction.sub_total_amt).value();
            amount = subtotal || numeral(transaction.taxable_amt).value() || numeral(transaction.ccs_authorized_amt).value() || numeral(transaction.amount).value();
          }
          return amount;
        }),
        cashDiscountAmount: _.sumBy(transactions, transaction => {
          const serviceFee = transaction?.service_fee_amount ? numeral(transaction?.service_fee_amount).value() : 0;
          return Math.round(parseFloat(serviceFee) * 100) / 100;
        })
      })).value();
    });

    groupedCardBrandSummary = groupedCardBrandSummary.map((transactions) => {

      return transactions.map((transaction) => {

        const taxes = transaction.taxes;
        const tips = transaction.tips;
        const cashDiscountAmount = transaction.cashDiscountAmount;
        const netSales = Math.round(parseFloat(transaction.netSales) * 100) / 100;;
        const totalCollected = netSales + taxes + tips;
        const totalWithoutCD = netSales - cashDiscountAmount;

        return {
          'Account Number': transaction.accountNumber,
          'DBA': transaction.dba,
          'Card Brand': transaction.brand,
          'Transactions': transaction.transactions,
          'Gross Sales': FormatTextUtils.formatParseFloatCurrencyValues(transaction.grossSales),
          'Refunds': FormatTextUtils.formatParseFloatCurrencyValues(transaction.refunds),
          'Voids': FormatTextUtils.formatParseFloatCurrencyValues(transaction.voids),
          'Net Sales': FormatTextUtils.formatParseFloatCurrencyValues(transaction.netSales),
          ...(!!isCashDiscountEnabled && {'Cash Discounting Amount': FormatTextUtils.formatParseFloatCurrencyValues(transaction.cashDiscountAmount)}),
          ...(!!isCashDiscountEnabled && {'Total w/o CD': FormatTextUtils.formatParseFloatCurrencyValues(totalWithoutCD)}),
          'Tax': FormatTextUtils.formatParseFloatCurrencyValues(taxes),
          'Tips': FormatTextUtils.formatParseFloatCurrencyValues(tips),
          'Total Collected': FormatTextUtils.formatParseFloatCurrencyValues(totalCollected)
        }
      });

    });

    groupedCardBrandSummary = _.flatMap(groupedCardBrandSummary);

    groupedCardBrandSummary.push(this.createCardBrandSummaryTotals(groupedCardBrandSummary, isCashDiscountEnabled))

    return groupedCardBrandSummary;
  },

  createCardBrandSummaryTotals: function (cardBrandSummary, isCashDiscountEnabled = false) {
    return {
      'Account Number': 'TOTALS:',
      'DBA': '',
      'Card Brand': '',
      'Transactions': cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Transactions']).value(), 0),
      'Gross Sales': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Gross Sales']).value(), 0)).format('$0,0.00'),
      'Refunds': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Refunds']).value(), 0)).format('$0,0.00'),
      'Voids': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Voids']).value(), 0)).format('$0,0.00'),
      'Net Sales': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Net Sales']).value(), 0)).format('$0,0.00'),
      ...(!!isCashDiscountEnabled && { 'Cash Discounting Amount': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Cash Discounting Amount']).value(), 0)).format('$0,0.00') }),
      ...(!!isCashDiscountEnabled && { 'Total w/o CD': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Total w/o CD']).value(), 0)).format('$0,0.00') }),
      'Tax': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Tax']).value(), 0)).format('$0,0.00'),
      'Tips': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Tips']).value(), 0)).format('$0,0.00'),
      'Total Collected': numeral(cardBrandSummary.reduce((partialSum, summaryTotal) => partialSum + numeral(summaryTotal['Total Collected']).value(), 0)).format('$0,0.00'),
    };
  },

  createPaymentTypeSummaryCsv: function (transactions, user) {

    const noNullTransactions = _.filter(transactions, (transaction => (transaction?.type)));

    const uniquePaymentType = _.sortBy(_(noNullTransactions).countBy('type').map((count, name) => ({
      name,
      count
    })).value(), 'count');

    if (!uniquePaymentType?.length) {
      return [{
        'Account Number': '',
        'DBA': '',
        'Payment Type': '',
        'Transaction': '',
        'Gross Sales': '$0.00',
        'Refunds': '$0.00',
        'Voids': '$0.00',
        'Net Sales': '$0.00',
        'Tips': '$0.00',
        'Tax': '$0.00',
        'Total Collected': '$0.00',
      }];
    }

    let groupedPaymentTypeSummary = _.map(uniquePaymentType, function (paymentType) {

      let brandTransactions = _.filter(noNullTransactions, (transaction => transaction.type === paymentType.name));
      return _(brandTransactions)
        .groupBy('mid')
        .map((paymentTypeTransactions, mid) => ({
          accountNumber: mid,
          dba: _.find(user.data.merchantAccounts, ['mid', mid]).dba_name,
          transactions: paymentType.count,
          paymentType: paymentType.name,
          grossSales: _.sumBy(paymentTypeTransactions, function (transaction) {
            if(transaction.type.includes('Refund')) {
              const amount = transaction.partial_sub_total_amt || transaction.sub_total_amt;
              return numeral(amount).value();
            } else {
              const amount = transaction.total_amt;
              const taxAmount = numeral(transaction.partial_tax).value();
              const tipAmount = numeral(transaction.tip_amount).value();
              const total = amount - taxAmount - tipAmount;
              return Math.round(parseFloat(total, 10) * 100) / 100;
            }
          }, 0),
          taxes: _.sumBy(paymentTypeTransactions, transaction => {
            if(transaction.type.includes('Refund') || transaction.type.includes('Void')) {
              const tax = Math.round(parseFloat(numeral(transaction.partial_tax).value()) * 100) / 100;
              return tax;
            } else {
              const refundOrVoidTransaction = _.find(transactions, function (t) {
                return t.invoice === transaction.invoice && t.partial_tax !== transaction.partial_tax;
              });
              if (refundOrVoidTransaction) {
                const refundedTaxAmount = numeral(refundOrVoidTransaction.partial_tax).value();
                const finalTaxAmount = numeral(transaction.partial_tax).value() + refundedTaxAmount;
                return Math.round(parseFloat(finalTaxAmount, 10) * 100) / 100;
              } else {
                return Math.round(parseFloat(numeral(transaction.partial_tax).value()) * 100) / 100;
              }
            }
          }, 0),
          tips: _.sumBy(paymentTypeTransactions, transaction => {
            const refundOrVoidTransaction = _.find(transactions, function (t) {
              return t.parent_uniq_id === transaction.uniq_id;
            });
            if (refundOrVoidTransaction) {
              const refundedTipAmount = numeral(refundOrVoidTransaction.tip_amount).value();
              const finalTipAmount = numeral(transaction.tip_amount).value() + refundedTipAmount;
              return Math.round(parseFloat(finalTipAmount, 10) * 100) / 100;
            } else {
              return Math.round(parseFloat(numeral(transaction.tip_amount).value()) * 100) / 100;
            }
          }),
          refunds: _.sumBy(paymentTypeTransactions, transaction => {
            let refundTransaction = _.find(transactions, function (t) {
              return (t.parent_uniq_id === transaction.uniq_id && t.type.includes('Refund'));
            });

            if (refundTransaction) {
              const amount = refundTransaction.partial_sub_total_amt || refundTransaction.sub_total_amt || refundTransaction.ccs_authorized_amt || refundTransaction.amount;
              return Math.round(parseFloat(amount, 10) * 100) / 100;
            }
          }, 0),
          voids: _.sumBy(paymentTypeTransactions, transaction => {

            let voidTransaction = _.find(transactions, function (t) {
              return t.uniq_id === transaction.void_uniq_id
            });

            if (voidTransaction) {
              let amount = voidTransaction.sub_total_amt || voidTransaction.ccs_authorized_amt || voidTransaction.amount;
              return Math.round(parseFloat(amount, 10) * 100) / 100;
            }

          }, 0),
          netSales: _.sumBy(paymentTypeTransactions, transaction => {
            let amount = 0;
            if (!transaction.void_uniq_id) { //we need to discard refunds too - need API changes to return which transactions are refunded
              amount = numeral(transaction.partial_sub_total_amt || transaction.sub_total_amt).value();
            }
            return Math.round(parseFloat(amount, 10) * 100) / 100;
          }, 0),
        })).value();
    });

    const rows = groupedPaymentTypeSummary.map((transactions) => {

      return transactions.map((transaction) => {

        const refunds = transaction.refunds || 0;
        const taxes = transaction.taxes || 0;
        const tips = transaction.tips || 0;

        const netSales = transaction.netSales - Math.abs(refunds);
        const total = netSales + taxes + tips;
        const totalCollected = total;

        return {
          'Account Number': transaction.accountNumber,
          'DBA': transaction.dba,
          'Payment Type': transaction.paymentType?.replace('ACH', 'Bank Account'),
          'Transaction': transaction.transactions,
          'Gross Sales': numeral(transaction.grossSales).format('$0,0.00'),
          'Refunds': numeral(refunds).format('$0,0.00'),
          'Voids': numeral(transaction.voids).format('$0,0.00'),
          'Net Sales': numeral(netSales).format('$0,0.00'),
          'Tips': numeral(tips).format('$0,0.00'),
          'Tax': numeral(taxes).format('$0,0.00'),
          'Total Collected': numeral(totalCollected).format('$0,0.00'),
        }

      });


    });

    return _.flatMap(rows);

  },

  createDisputesCsv: function (disputes, user) {

    return (disputes && disputes.length) ? disputes.map((dispute) => {
      const cardNumbersOnly = dispute.card_number.replace(/\*/g, '');
      let first6 = '';
      let last4 = cardNumbersOnly;

      if (cardNumbersOnly.length > 4) {
        first6 = cardNumbersOnly.substring(0, cardNumbersOnly.length - 4);
        last4 = cardNumbersOnly.substring(cardNumbersOnly.length - 4);
      }

      return {
        'Account Number': dispute.mid,
        'DBA': _.find(user.data.merchantAccounts, ['mid', dispute.mid]) ? _.find(user.data.merchantAccounts, ['mid', dispute.mid]).dba_name : null,
        'Case Number': dispute.chargeback_guid,
        'ARN': dispute.arn,
        'Invoice': dispute.invoice_number,
        'Card Brand': dispute.card_brand,
        'First 6': first6,
        'Last 4': last4,
        'Disputed Amount': dispute.chargeback_amount,
        'Status': dispute.dispute_status,
        'Original Transaction Invoice Number': dispute.transaction_id,
        'Original Transaction Amount': dispute.original_transaction_amount,
        'Transaction Date': dispute.original_transaction_date,
        'Sort Date': dispute.due_date,
        'Reason Code': dispute.reason_code,
        'Reason': dispute.reason_description,
        'Date Received': dispute.load_date
      };
    }) :
    {
      'Account Number': '',
      'DBA': '',
      'Case Number': '',
      'ARN': '',
      'Invoice': '',
      'Card Brand': '',
      'First 6': '',
      'Last 4': '',
      'Disputed Amount': '',
      'Status': '',
      'Original Transaction Invoice Number': '',
      'Original Transaction Amount': '',
      'Transaction Date': '',
      'Sort Date': '',
      'Reason Code': '',
      'Reason': '',
      'Date Received': ''
    };

  },

  createManualCloseOpenTransactionsCsv: function (transactions, user) {
    const rows = [];

    if(transactions && transactions.length) {
      transactions.map((transaction) => {
        const row = {};
        const invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);

        row['Date'] = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
        row['DBA'] = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]).dba_name;
        row['Invoice'] = invoiceNumber;
        row['Sold By'] = transaction.sold_by;
        row['Customer Name'] = FormatTextUtils.formatName(transaction.customer_firstname, transaction.customer_lastname, 'Unnamed Customer');
        row['Payment Amount'] = numeral(transaction.amount).format('$0,0.00');
        row['Tip'] = numeral(transaction.tip_amount).format('$0,0.00');
        row['Cash Discounting Amount'] = numeral(transaction.service_fee_amount).format('$0,0.00');
        row['Payment Type'] = TransactionsUtil.getAchTransactionName(transaction);
        row['Card Brand'] = TransactionsUtil.getAchNetwork(transaction);
        row['Last 4'] = transaction.last4;

        rows.push(row);
      });
    }else{
      const row = {};

      row['Date'] = '';
      row['DBA'] = '';
      row['Invoice'] = '';
      row['Sold By'] = '';
      row['Customer Name'] = '';
      row['Payment Amount'] = '';
      row['Tip'] = '';
      row['Cash Discounting Amount'] = '';
      row['Payment Type'] = '';
      row['Card Brand'] = '';
      row['Last 4'] = '';

      rows.push(row);
    }
    return rows;
  },

  createTransactionCsv: function (transactions, user, debtRepayment, showDeclinedTransactions) {

    let array = [];

    if (transactions && transactions.length) {
      transactions.map((transaction) => {

        let invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);
        const transactionSource = FilterUtil.getTransactionSource(transaction);

        let discountPercentageAmount = 0;

        if(transaction.receipt_discount_amt?.length)
          transaction.receipt_discount_amt.forEach((discount, index) => {
            if(transaction.receipt_discount_type[index] === 'Percentage')
            discountPercentageAmount += Number(JSON.parse(transaction.receipt_discount_info[index]).percentage);
          });

        const discountPercentage = `${discountPercentageAmount}%`;

        let object = {};


        object['Date'] = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
        object['Transaction Source'] = transactionSource;
        object['Transaction Type'] = transaction.cc_type;
        object['Account Number'] = transaction.mid;
        object['DBA'] = _.find(user.data.merchantAccounts, ['mid', transaction.mid])?.dba_name || _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)])?.dba_name;
        object['Invoice'] = invoiceNumber;
        object['Auth'] = transaction.cc_auth_code;
        object['BRIC'] = transaction.bric;
        object['Sold By'] = transaction.sold_by;
        object['Placed For'] = transaction.sold_by_override_name;
        object['Customer Name'] = FormatTextUtils.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');
        object['Total Transaction Amount'] = numeral(transaction.total_amt).format('$0,0.00');
        object['Payment Amount'] = numeral(transaction.amount).format('$0,0.00');
        object['Authorized Amount'] = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).format('$0,0.00') : 'n/a';
        object['Tip'] = numeral(transaction.tip_amount).format('$0,0.00');
        object['$ Discount'] = numeral(transaction.total_discount_amt).format('$0,0.00');
        object['% Discount'] = discountPercentage;
        object['$ Tax'] = numeral(transaction.partial_tax).format('$0,0.00');
        object['Cash Discounting Amount'] = numeral(transaction.service_fee_amount).format('$0,0.00');
        object['State Tax'] = transaction.state_tax ? numeral(transaction.state_tax / 100).format('0.000 %') : 'n/a';
        object['County Tax'] = transaction.county_tax ? numeral(transaction.county_tax / 100).format('0.000 %') : 'n/a';
        object['City Tax'] = transaction.city_tax ? numeral(transaction.city_tax / 100).format('0.000 %') : 'n/a';
        object['Custom Tax'] = transaction.customized_tax ? numeral(transaction.customized_tax / 100).format('0.000 %') : 'n/a';
        object['Payment Type'] = TransactionsUtil.getAchTransactionName(transaction);
        object['Card Brand'] = TransactionsUtil.getAchNetwork(transaction);
        object['First 6'] = transaction.cc_first6;
        object['Last 4'] = transaction.cc_last4;
        object['Routing Number'] = transaction.routing_nbr;

        object['Comment'] = transaction.comment;
        if (transaction.custom_fields?.custom_field_1) {
          object['Custom Field'] = transaction.custom_fields.custom_field_1.value;
        }
        if (debtRepayment) {
          object['Customer Account Number'] = transaction.customer_account_number ? transaction.customer_account_number : '';
        }
        if(showDeclinedTransactions) {
          object['Routing Number'] = transaction.routing_nbr;
          object['Status'] = transaction?.status_message || '';
        }
        array.push(object);
      });
    } else {
      let object = {};

      object['Date'] = '';
      object['Transaction Source'] = '';
      object['Transaction Type'] = '';
      object['Account Number'] = '';
      object['DBA'] = '';
      object['Invoice'] = '';
      object['Auth'] = '';
      object['BRIC'] = '';
      object['Sold By'] = '';
      object['Placed For'] = '';
      object['Customer Name'] = '';
      object['Total Transaction Amount'] = '';
      object['Payment Amount'] = '';
      object['Authorized Amount'] = '';
      object['Tip'] = '';
      object['$ Discount'] = '';
      object['% Discount'] = '';
      object['$ Tax'] = '';
      object['Cash Discounting Amount'] = '';
      object['State Tax'] = '';
      object['County Tax'] = '';
      object['City Tax'] = '';
      object['Custom Tax'] = '';
      object['Payment Type'] = '';
      object['Card Brand'] = '';
      object['First 6'] = '';
      object['Last 4'] = '';
      object['Routing Number'] = '';
      object['Comment'] = '';
      if (debtRepayment) {
        object['Customer Account Number'] = '';
      }
      if(showDeclinedTransactions) {
        object['Status'] = '';
      }
      array.push(object);
    }

    return array;
  },

  createExpandedTransactionCsv: function (transactions, user, debtRepayment, processingLevel) {

    const isL2 = processingLevel === 'L2';
    const isL3 = processingLevel === 'L3';

    const customFieldsHeaders = customFieldHeaders(transactions);

    let array = [];

    if (transactions && transactions.length) {
      transactions.map((transaction) => {
        const totalCashTransactionAmount =
          numeral(transaction.total_amt).value() - numeral(transaction.service_fee_amount).value();
        let invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);
        let transactionSource = FilterUtil.getTransactionSource(transaction);
        const isPartialRefund =
          !!((transaction.type === 'Credit Refund' || transaction.type === 'Cash Refund')
            && Math.abs(transaction.amount) < Math.abs(transaction.total_amt));

        let discountPercentageAmount = 0;

        if(transaction.receipt_discount_amt?.length)
          transaction.receipt_discount_amt.forEach((discount, index) => {
            if(transaction.receipt_discount_type[index] === 'Percentage')
            discountPercentageAmount += Number(JSON.parse(transaction.receipt_discount_info[index]).percentage);
          });

        const discountPercentage = `${discountPercentageAmount}%`;

        let object = {};

        object['Date'] = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
        object['Transaction Source'] = transactionSource;
        object['Transaction Type'] = transaction.cc_type;
        object['Account Number'] = transaction.mid;
        object['DBA'] = _.find(user.data.merchantAccounts, ['mid', transaction.mid]) ? _.find(user.data.merchantAccounts, ['mid', transaction.mid]).dba_name : null;
        object['Invoice'] = invoiceNumber;
        object['Sold By'] = transaction.sold_by;
        object['Placed For'] = transaction.sold_by_override_name;
        object['Customer Name'] = FormatTextUtils.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');
        object['Total Transaction Amount'] = isPartialRefund ? 'n/a' : numeral(transaction.total_amt).format('$0,0.00');
        object['Payment Amount'] = numeral(transaction.amount).format('$0,0.00');
        object['Authorized Amount'] = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).format('$0,0.00') : 'n/a';
        object['Tip'] = isPartialRefund ? 'n/a' : numeral(transaction.tip_amount).format('$0,0.00');
        object['Auth'] = transaction.cc_auth_code;
        object['BRIC'] = transaction.bric;
        object['Original BRIC'] = transaction.parent_bric ? transaction.parent_bric : 'n/a';
        object['Deposit Date'] = transaction.deposit_datetime ? moment(transaction.deposit_datetime).format('MM/DD/YYYY h:mm:ss a') : 'n/a';
        object['Rate'] = isPartialRefund ? 'n/a' : transaction.rate ? transaction.rate : 'n/a';
        object['Fee'] = isPartialRefund ? 'n/a' : transaction.fee ? numeral(transaction.fee).format('$0,0.00') : 'n/a';
        object['Deposit Amount'] = transaction.deposit_amount ? numeral(transaction.deposit_amount).format('$0,0.00') : 'n/a';
        object['$ Discount'] = isPartialRefund ? 'n/a' : numeral(transaction.total_discount_amt).format('$0,0.00');
        object['% Discount'] = isPartialRefund ? 'n/a' : discountPercentage;
        object['$ Tax'] = isPartialRefund ? 'n/a' : numeral(transaction.partial_tax).format('$0,0.00');
        object['Cash Discounting Amount'] = isPartialRefund ? numeral(transaction.partial_service_fee_amt).format('$0,0.00') : numeral(transaction.service_fee_amount).format('$0,0.00');
        object['Total Cash Transaction Amount'] =
          isPartialRefund ? 'n/a' : numeral(totalCashTransactionAmount).format('$0,0.00');
        object['State Tax'] = isPartialRefund ? 'n/a'
          : (transaction.state_tax ? numeral(transaction.state_tax / 100).format('0.000 %') : 'n/a');
        object['County Tax'] = isPartialRefund ? 'n/a'
          : (transaction.county_tax ? numeral(transaction.county_tax / 100).format('0.000 %') : 'n/a');
        object['City Tax'] = isPartialRefund ? 'n/a'
          : (transaction.city_tax ? numeral(transaction.city_tax / 100).format('0.000 %') : 'n/a');
        object['Custom Tax'] = isPartialRefund ? 'n/a'
          : (transaction.customized_tax ? numeral(transaction.customized_tax / 100).format('0.000 %') : 'n/a');
        object['Payment Type'] = TransactionsUtil.getAchTransactionName(transaction);
        object['Card Brand'] = TransactionsUtil.getAchNetwork(transaction);
        object['First 6'] = transaction.cc_first6;
        object['Last 4'] = transaction.cc_last4;
        object['Routing Number'] = transaction.routing_nbr;
        object['Comment'] = transaction.comment;

        if (debtRepayment) {
          object['Customer Account Number'] = transaction.customer_account_number ? transaction.customer_account_number : '';
        }

        const l2 = transaction?.business_data && (transaction.business_data.l3 || transaction.business_data.l2);
        const l3 = transaction?.business_data && transaction.business_data.l3;

        const taxAmount = !l2 ? 'n/a' : transaction.business_data.l2.tag002 ? transaction.business_data.l2.tag002 : transaction.business_data.l2.tax_amount ? transaction.business_data.l2.tax_amount : '0';

        if (isL2 || isL3) {
          object['Data Type'] = l2 ? transaction.business_data.dataType : 'n/a';
          object['Customer Code'] = l2 && transaction.business_data.l2.tag001 ? transaction.business_data.l2.tag001 : 'n/a';
          object['Tax Amount'] = l2 ? taxAmount : 'n/a';
          object['Tax Id'] = l2 && transaction.business_data.l2.tag003 ? transaction.business_data.l2.tag003 : 'n/a';
          object['Tax Exempt'] = l2 && transaction.business_data.l2.tax_exempt ? FormatTextUtils.formatYesNo(transaction.business_data.l2.tax_exempt) : 'n/a';
        }

        if (isL3) {
          object['Item Description'] = l3 ? transaction.business_data.l3.tag004 : 'n/a';
          object['Item Quantity'] = l3 ? transaction.business_data.l3.tag005 : 'n/a';
          object['Measure Unit'] = l3 ? transaction.business_data.l3.tag006 : 'n/a';
          object['Unit Price'] = l3 ? transaction.business_data.l3.tag007 : 'n/a';
          object['Product Code'] = l3 ? transaction.business_data.l3.tag008 : 'n/a';
          object['Order Date'] = l3 ? moment(transaction.business_data.l3.tag009).format('MM/DD/YYYY h:mm:ss a') : 'n/a';
          object['Commodity Code'] = l3 ? transaction.business_data.l3.tag014 : 'n/a';
          object['Requestor Name'] = l3 ? transaction.business_data.l3.tag015 : 'n/a';
          object['Company Name'] = l3 ? transaction.business_data.l3.tag016 : 'n/a';
          object['Shipment Id'] = l3 ? transaction.business_data.l3.tag017 : 'n/a';
          object['Shipment Country'] = l3 ? transaction.business_data.l3.tag018 : 'n/a';
          object['Shipment State'] = l3 ? transaction.business_data.l3.tag019 : 'n/a';
          object['Shipment Zip Code'] = l3 ? transaction.business_data.l3.tag020 : 'n/a';
          object['Destination Country'] = l3 ? transaction.business_data.l3.tag021 : 'n/a';
          object['Destination State'] = l3 ? transaction.business_data.l3.tag022 : 'n/a';
          object['Destination Zip Code'] = l3 ? transaction.business_data.l3.tag023 : 'n/a';
          object['Discount Amount'] = l3 ? transaction.business_data.l3.tag024 : 'n/a';
          object['Duty Amount'] = l3 ? transaction.business_data.l3.tag025 : 'n/a';
          object['Freight Amount'] = l3 ? transaction.business_data.l3.tag026 : 'n/a';
        }

        if (customFieldsHeaders.length > 0) {
          const rows = generateCustomFieldRows(transaction, customFieldsHeaders);
          object = { ...object, ...rows};
        }

        array.push(object);

      });
    } else {

      let object = {};

      object['Date'] = '';
      object['Account Number'] = '';
      object['Invoice'] = '';
      object['Sold By'] = '';
      object['Customer Name'] = '';
      object['Total Transaction Amount'] = '';
      object['Payment Amount'] = '';
      object['Authorized Amount'] = '';
      object['Tip'] = '';
      object['Auth'] = '';
      object['Deposit Date'] = '';
      object['Rate'] = '';
      object['Fee'] = '';
      object['Deposit Amount'] = '';
      object['$ Discount'] = '';
      object['% Discount'] = '';
      object['$ Tax'] = '';
      object['Cash Discounting Amount'] = '';
      object['Total Cash Transaction Amount'] = '';
      object['State Tax'] = '';
      object['County Tax'] = '';
      object['City Tax'] = '';
      object['Custom Tax'] = '';
      object['Payment Type'] = '';
      object['Card Brand'] = '';
      object['First 6'] = '';
      object['Last 4'] = '';
      object['Comment'] = '';
      if (debtRepayment) {
        object['Customer Account Number'] = '';
      }

      array.push(object);
    }

    return array;

  },

  createCustomReportCsv: function({ transactions, user, processingLevel, columns, customFields }) {
    const isL2 = processingLevel === 'L2';
    const isL3 = processingLevel === 'L3';

    let array = [];

    if (transactions && transactions.length) {
      transactions.map((transaction) => {
        const totalCashTransactionAmount =
          numeral(transaction.total_amt).value() - numeral(transaction.service_fee_amount).value();
        let invoiceNumber = ReportUtil.getTransactionInvoice(transaction, transactions);
        let transactionSource = FilterUtil.getTransactionSource(transaction);
        const isPartialRefund =
          !!((transaction.type === 'Credit Refund' || transaction.type === 'Cash Refund')
            && Math.abs(transaction.amount) < Math.abs(transaction.total_amt));

        let discountPercentageAmount = 0;

        if (transaction.receipt_discount_amt?.length)
          transaction.receipt_discount_amt.forEach((discount, index) => {
            if(transaction.receipt_discount_type[index] === 'Percentage')
              discountPercentageAmount += Number(JSON.parse(transaction.receipt_discount_info[index]).percentage);
          });

        const discountPercentage = `${discountPercentageAmount}%`;

        let object = {};

        object['Date'] = moment(transaction.datetime).format('MM/DD/YYYY h:mm:ss a');
        if (_.includes(columns, 'Transaction Source')) {
          object['Transaction Source'] = transactionSource;
        }
        object['Transaction Type'] = transaction.cc_type;
        object['Account Number'] = transaction.mid;
        object['DBA'] = _.find(user.data.merchantAccounts, ['mid', transaction.mid]) ? _.find(user.data.merchantAccounts, ['mid', transaction.mid]).dba_name : null;
        if (_.includes(columns, 'Invoice')) {
          object['Invoice'] = invoiceNumber;
        }
        if (_.includes(columns, 'Sold By')) {
          object['Sold By'] = transaction.sold_by;
        }
        if (_.includes(columns, 'Placed For')) {
          object['Placed For'] = transaction.sold_by_override_name;
        }
        if (_.includes(columns, 'Customer Name')) {
          object['Customer Name'] = FormatTextUtils.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');
        }
        object['Total Transaction Amount'] = isPartialRefund ? 'n/a' : numeral(transaction.total_amt).format('$0,0.00');
        if (_.includes(columns, 'Payment Amount')) {
          object['Payment Amount'] = numeral(transaction.amount).format('$0,0.00');
        }
        if (_.includes(columns, 'Authorized Amount')) {
          object['Authorized Amount'] = transaction.ccs_authorized_amt ? numeral(transaction.ccs_authorized_amt).format('$0,0.00') : 'n/a';
        }
        if (_.includes(columns, 'Tip')) {
          object['Tip'] = isPartialRefund ? 'n/a' : numeral(transaction.tip_amount).format('$0,0.00');
        }
        if (_.includes(columns, 'Auth')) {
          object['Auth'] = transaction?.cc_auth_code ?? '';
        }
        if (_.includes(columns, 'BRIC')) {
          object['BRIC'] = transaction.bric;
        }
        if (_.includes(columns, 'Original BRIC')) {
          object['Original BRIC'] = transaction.parent_bric ? transaction.parent_bric : 'n/a';
        }
        if (_.includes(columns, 'Deposit Date')) {
          object['Deposit Date'] = transaction.deposit_datetime ? moment(transaction.deposit_datetime).format('MM/DD/YYYY h:mm:ss a') : 'n/a';
        }
        if (_.includes(columns, 'Rate')) {
          object['Rate'] = isPartialRefund ? 'n/a' : transaction.rate ? transaction.rate : 'n/a';
        }
        if (_.includes(columns, 'Fee')) {
          object['Fee'] = isPartialRefund ? 'n/a' : transaction.fee ? numeral(transaction.fee).format('$0,0.00') : 'n/a';
        }
        if (_.includes(columns, 'Deposit Amount')) {
          object['Deposit Amount'] = transaction.deposit_amount ? numeral(transaction.deposit_amount).format('$0,0.00') : 'n/a';
        }
        if (_.includes(columns, '$ Discount')) {
          object['$ Discount'] = isPartialRefund ? 'n/a' : numeral(transaction.total_discount_amt).format('$0,0.00');
        }
        if (_.includes(columns, '% Discount')) {
          object['% Discount'] = isPartialRefund ? 'n/a' : discountPercentage;
        }
        if (_.includes(columns, '$ Tax')) {
          object['$ Tax'] = isPartialRefund ? 'n/a' : numeral(transaction.partial_tax).format('$0,0.00');
        }
        if (_.includes(columns, 'Cash Discounting Amount')) {
          object['Cash Discounting Amount'] = isPartialRefund ? numeral(transaction.partial_service_fee_amt).format('$0,0.00') : numeral(transaction.service_fee_amount).format('$0,0.00');
        }
        if (_.includes(columns, 'Total Cash Transaction Amount')) {
          object['Total Cash Transaction Amount'] =
            isPartialRefund ? 'n/a' : numeral(totalCashTransactionAmount).format('$0,0.00');
        }
        if (_.includes(columns, 'State Tax')) {
          object['State Tax'] = isPartialRefund ? 'n/a'
            : (transaction.state_tax ? numeral(transaction.state_tax / 100).format('0.000%') : 'n/a');
        }
        if (_.includes(columns, 'County Tax')) {
          object['County Tax'] = isPartialRefund ? 'n/a'
            : (transaction.county_tax ? numeral(transaction.county_tax / 100).format('0.000%') : 'n/a');
        }
        if (_.includes(columns, 'City Tax')) {
          object['City Tax'] = isPartialRefund ? 'n/a'
            : (transaction.city_tax ? numeral(transaction.city_tax / 100).format('0.000%') : 'n/a');
        }
        if (_.includes(columns, 'Custom Tax')) {
          object['Custom Tax'] = isPartialRefund ? 'n/a'
            : (transaction.customized_tax ? numeral(transaction.customized_tax / 100).format('0.000%') : 'n/a');
        }
        if (_.includes(columns, 'Payment Type')) {
          object['Payment Type'] = TransactionsUtil.getAchTransactionName(transaction);
        }
        if (_.includes(columns, 'Card Brand')) {
          object['Card Brand'] = TransactionsUtil.getAchNetwork(transaction);
        }
        if (_.includes(columns, 'First 6')) {
          object['First 6'] = transaction.cc_first6;
        }
        if (_.includes(columns, 'Last 4')) {
          object['Last 4'] = transaction.cc_last4;
        }
        if (_.includes(columns, 'Comment')) {
          object['Comment'] = transaction.cc_last4;
        }

        const l2 = transaction && transaction.business_data && (transaction.business_data.l3 || transaction.business_data.l2);
        const l3 = transaction && transaction.business_data && transaction.business_data.l3;

        const taxAmount = !l2 ? 'n/a' : transaction.business_data.l2?.tag002 ? transaction.business_data.l2.tag002 : transaction.business_data.l2?.tax_amount ? transaction.business_data.l2.tax_amount : '0';

        if ((isL2 && _.includes(columns, 'L2')) || (isL3 && _.includes(columns, 'L3'))) {
          object['Data Type'] = l2 && transaction.business_data?.dataType ? transaction.business_data.dataType : 'n/a';
          object['Customer Code'] = l2 && transaction.business_data?.l2?.tag001 ? transaction.business_data.l2.tag001 : 'n/a';
          object['Tax Amount'] = l2 && taxAmount;
          object['Tax Id'] = l2 && transaction.business_data?.l2?.tag003 ? transaction.business_data.l2.tag003 : 'n/a';
          object['Tax Exempt'] = l2 && transaction.business_data?.l2?.tax_exempt ? FormatTextUtils.formatYesNo(transaction.business_data.l2.tax_exempt) : 'n/a';
        }

        if (isL3 && _.includes(columns, 'L3')) {
          object['Item Description'] = transaction.business_data?.l3?.tag004 ?? 'n/a';
          object['Item Quantity'] = transaction.business_data?.l3?.tag005 ?? 'n/a';
          object['Measure Unit'] = transaction.business_data?.l3?.tag006 ?? 'n/a';
          object['Unit Price'] = transaction.business_data?.l3?.tag007 ?? 'n/a';
          object['Product Code'] = transaction.business_data?.l3?.tag008 ?? 'n/a';
          object['Order Date'] = transaction.business_data?.l3?.tag009 ? moment(transaction.business_data.l3.tag009).format('MM/DD/YYYY h:mm:ss a') : 'n/a';
          object['Commodity Code'] = transaction.business_data?.l3?.tag014 ?? 'n/a';
          object['Requestor Name'] = transaction.business_data?.l3?.tag015 ?? 'n/a';
          object['Company Name'] = transaction.business_data?.l3?.tag016 ?? 'n/a';
          object['Shipment Id'] = transaction.business_data?.l3?.tag017 ?? 'n/a';
          object['Shipment Country'] = transaction.business_data?.l3?.tag018 ?? 'n/a';
          object['Shipment State'] = transaction.business_data?.l3?.tag019 ?? 'n/a';
          object['Shipment Zip Code'] = transaction.business_data?.l3?.tag020 ?? 'n/a';
          object['Destination Country'] = transaction.business_data?.l3?.tag021 ?? 'n/a';
          object['Destination State'] = transaction.business_data?.l3?.tag022 ?? 'n/a';
          object['Destination Zip Code'] = transaction.business_data?.l3?.tag023 ?? 'n/a';
          object['Discount Amount'] = transaction.business_data?.l3?.tag024 ?? 'n/a';
          object['Duty Amount'] = transaction.business_data?.l3?.tag025 ?? 'n/a';
          object['Freight Amount'] = transaction.business_data?.l3?.tag026 ?? 'n/a';
        }

        if (customFields?.length) {
          columns.forEach(column => {
            const customField = customFields.find(field => field.fieldName === column);
            if (customField && transaction?.custom_fields?.hasOwnProperty(customField.fieldId)) {
              object[column] = transaction.custom_fields[customField.fieldId].value;
            } else if (customField) {
              object[customField.fieldName] = '';
            }
          });
        }

        array.push(object);

      });
    } else {

      let object = {};

      object['Date'] = '';
      object['Transaction Type'] = '';
      object['Account Number'] = '';
      object['DBA'] = '';
      object['Total Transaction Amount'] = '';

      if (customFields?.length) {
        customFields.forEach(customField => {
          if (customField.isRequired) {
            object[customField.fieldName] = '';
          }
        });
      }

      array.push(object);
    }

    return array;

  },

  createCustomersCsv: function (customers, user, debtRepayment) {

    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);

    let array = [];

    if (customers && customers.length) {
      customers.map((customer) => {
        let object = {};
        object['Account Number'] = account.mid;
        object['DBA'] = account.dba_name;
        object['First Name'] = customer.first_name;
        object['Last Name'] = customer.last_name;
        object['Mobile Number'] = customer.phone_number;
        object['Email 1'] = customer.email_addresses[0] ? customer.email_addresses[0] : '';
        object['Email 2'] = customer.email_addresses[1] ? customer.email_addresses[1] : '';
        object['Email 3'] = customer.email_addresses[2] ? customer.email_addresses[2] : '';
        object['Number of Email Addresses'] = customer.email_addresses?.filter(emailAddress => emailAddress !== '').length;
        object['Points Earned'] = customer.loyalty_vpc_status?.points_earned;
        object['Points to Earned Reward'] = customer.loyalty_vpc_status?.points_to_earn_reward;
        object['Reward Amount'] = customer.loyalty_vpc_status?.reward_amount;
        object['Reward Amount Type'] = customer.loyalty_vpc_status?.reward_amount_type;
        if (debtRepayment) {
          object['Customer Account Number'] = customer.account_number ? customer.account_number : '';
        }
        array.push(object)
      });

    } else {
      let object = {};
      object['Account Number'] = account.mid;
      object['DBA'] = account.dba_name;
      object['First Name'] = '';
      object['Last Name'] = '';
      object['Mobile Number'] = '';
      object['Email 1'] = '';
      object['Email 2'] = '';
      object['Email 3'] = '';
      object['Number of Email Addresses'] = '';
      if (debtRepayment) {
        object['Customer Account Number'] = '';
      }
      array.push(object);
    }

    return array;
  },

  createLoyaltyCsv: function (customers, user) {
    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);

    let array = [];

    if (customers && customers.length) {
      customers.map((customer) => {
        let object = {};
        object['Account Number'] = account.mid;
        object['DBA'] = account.dba_name;
        object['First Name'] = customer.first_name;
        object['Last Name'] = customer.last_name;
        object['Email'] = customer.email;
        object['Reward Redeemed On'] = customer.redeemed_on ? moment(customer.redeemed_on).format('MM/DD/YYYY h:mm:ss a') : '';
        array.push(object);
      });

    } else {
      let object = {};
      object['Account Number'] = account.mid;
      object['DBA'] = account.dba_name;
      object['First Name'] = '';
      object['Last Name'] = '';
      object['Email'] = '';
      object['Reward Redeemed On'] = '';
      array.push(object);
    }

    return array;
  },

  createPaymentLinksCsv: function (paymentLinksData) {

    let array = [];

    paymentLinksData.map((data) => {
      let object = {};
      object['Date'] = moment(data.datetime).format('MM/DD/YYYY h:mm:ss a');
      object['Transaction Source'] = data.transaction_source;
      object['Transaction Type'] = data.transaction_type;
      object['Account Number'] = data.account_number;
      object['DBA'] = data.dba;
      object['Payment Link'] = data.title;
      object['Sold By'] = data.sold_by;
      object['Invoice'] = data.invoice_number;
      object['Customer Name'] = data.customer_name;
      object['Total Transaction Amount'] = numeral(data.total_amt).format('$0,0.00');
      object['Payment Amount'] = numeral(data.payment_amt).format('$0,0.00');
      object['Authorized Amount'] = numeral(data.authorized_amt).format('$0,0.00');
      object['Tip'] = numeral(data.tip_amt).format('$0,0.00');
      object['Tax'] = numeral(data.tax_amt).format('$0,0.00');
      object['Cash Discounting Amount'] = numeral(data.service_fee_amt).format('$0,0.00');
      object['Payment Type'] = data.payment_type;
      object['Card Brand'] = data.card_brand;
      object['First 6'] = data.first_6;
      object['Last 4'] = data.last_4;
      object['Custom Field'] = data.custom_field;
      array.push(object);
    });

    return array;
  },

  createCustomerPerformanceCsv: function (transactions, user, debtRepayment) {

    let noNullTransactions = _.filter(transactions, (transaction => (transaction?.pa_customer_id)));

    let uniqueCustomers = {};
    if (noNullTransactions?.length) {
      uniqueCustomers = _.sortBy(_(noNullTransactions).countBy('pa_customer_id').map((count, id) => ({
        id,
        count
      })).value(), 'count');
    }

    let groupByCustomerTransactions = _.map(uniqueCustomers, function (customer) {

      let customerTransactions = [];

      customerTransactions = _.filter(noNullTransactions, (transaction => transaction.pa_customer_id === Number(customer.id)));

      return _(customerTransactions)
        .groupBy('mid')
        .map((customerTransactions, mid) => ({
          accountNumber: mid,
          dba: _.find(user.data.merchantAccounts, ['mid', mid]) ? _.find(user.data.merchantAccounts, ['mid', mid]).dba_name : null,
          transactions: customer.count,
          lastVisit: _.maxBy(customerTransactions, 'datetime'),
          totalSales: _.sumBy(customerTransactions, function (transaction) {
            if (transaction.type === 'Credit Sale' || transaction.type === 'Cash Sale') {
              let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount;
              return Math.round(parseFloat(amount, 10) * 100) / 100;
            }
          }),
          name: FormatTextUtils.formatName(customerTransactions[0].first_name, customerTransactions[0].last_name, 'Unnamed Customer - ' + customer.id),
          customer_account_number: customerTransactions[0].customer_account_number
        })).value();

    });

    let array = [];

    if (groupByCustomerTransactions && groupByCustomerTransactions.length) {
      groupByCustomerTransactions.map((transaction) => {
        let object = {};
        object['Account Number'] = transaction[0].accountNumber;
        object['DBA'] = transaction[0].dba;
        object['Customer'] = transaction[0].name;
        object['Last Visit'] = transaction[0].lastVisit ? moment(transaction[0].lastVisit.datetime).format('MM/DD/YYYY h:mm:ss a') : 'N/A';
        object['Transactions'] = transaction[0].transactions;
        object['Total'] = numeral(transaction[0].totalSales).format('$0,0.00');
        if (debtRepayment) {
          object['Customer Account Number'] = transaction[0].customer_account_number
        }
        array.push(object);
      });
    } else {
      let object = {};
      object['Account Number'] = '';
      object['DBA'] = '';
      object['Customer'] = '';
      object['Last Visit'] = '';
      object['Transactions'] = '';
      object['Total'] = '';

      if (debtRepayment) {
        object['Customer Account Number'] = '';
      }
      array.push(object);
    }

    return array;
  },

  createEmployeePerformanceCsv: function (transactions, user, employees) {

    // Net Sales includes discounts, refunds, and voids
    // Total Collected includes discounts, refunds, voids, tips, taxes, and authorized amounts for partial sales (does not include pre auth)
    // Total Sales includes everything from Total Collected but uses full amounts for partial sales instead of the authorized amounts

    const uniqueEmployees = _.sortBy(_(transactions)
      .filter(transaction => transaction?.type !== 'Pre Auth') //Exclude pre auth from number of transactions
      .countBy('sold_by')
      .map((count, email) => ({
        email,
        count
      })).value(), 'count');

    let groupByEmployeeTransactions = _.map(uniqueEmployees, function (employee) {

      if (employee.email) {

        let employeeTransactions = _.filter(transactions, (transaction => transaction.sold_by === employee.email));
        let employeeRole = _.find(employees, ['email_address', employee.email]) ? _.find(employees, ['email_address', employee.email]).role : null;

        return _(employeeTransactions)
          .groupBy('mid')
          .map((employeeTransactions, mid) => ({
            accountNumber: mid,
            dba: _.find(user.data.merchantAccounts, ['mid', mid]) ? _.find(user.data.merchantAccounts, ['mid', mid]).dba_name : null,
            transactions: employee.count,
            netSales: _.sumBy(employeeTransactions, function (transaction) {
              if (transaction.type === 'Credit Sale' || transaction.type === 'Cash Sale') {
                let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              } else if (transaction.type === 'Credit Sale - Split Payment' ||
                transaction.type === 'Cash Sale - Split Payment') {
                let tipAmount = transaction.tip_amount ? parseFloat(transaction.tip_amount) : 0;
                let taxAmount = transaction.partial_tax ? parseFloat(transaction.partial_tax) : 0;
                let splitAmount = (transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount) - (tipAmount + taxAmount);
                return Math.round(parseFloat(splitAmount, 10) * 100) / 100;
              }
            }),
            refunds: _.sumBy(employeeTransactions, transaction => {
              if (transaction.type === 'Credit Refund' || transaction.type === 'Cash Refund') {
                let amount = transaction.amount;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }, 0),
            voids: _.sumBy(employeeTransactions, transaction => {
              if (transaction.type === 'Void') {
                let amount = transaction.amount;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }, 0),
            tips: _.sumBy(employeeTransactions, function (transaction) {
              if (transaction.type === 'Credit Sale' ||
                transaction.type === 'Cash Sale' ||
                transaction.type === 'Credit Sale - Split Payment' ||
                transaction.type === 'Cash Sale - Split Payment') {
                let amount = transaction.tip_amount ? transaction.tip_amount : 0;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }),
            discounts: _.sumBy(employeeTransactions, function (transaction) {
              if (transaction.type === 'Credit Sale' ||
                transaction.type === 'Cash Sale') {
                let amount = transaction.total_discount_amt ? transaction.total_discount_amt : 0;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }),
            taxes: _.sumBy(employeeTransactions, function (transaction) {
              if (transaction.type === 'Credit Sale' ||
                transaction.type === 'Cash Sale' ||
                transaction.type === 'Credit Sale - Split Payment' ||
                transaction.type === 'Cash Sale - Split Payment') {
                let amount = transaction.partial_tax ? transaction.partial_tax : 0;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              } else if (transaction.type === 'Credit Refund' ||
                transaction.type === 'Cash Refund' ||
                transaction.type === 'Void') {
                let amount = transaction.partial_tax ? transaction.partial_tax : 0;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }),
            totalCollected: _.sumBy(employeeTransactions, transaction => {
              if (transaction.type === 'Credit Sale' ||
                transaction.type === 'Cash Sale' ||
                transaction.type === 'Credit Sale - Split Payment' ||
                transaction.type === 'Cash Sale - Split Payment' ||
                transaction.type === 'Void' ||
                transaction.type === 'Credit Refund' ||
                transaction.type === 'Cash Refund') {
                let amount = transaction.ccs_authorized_amt ? transaction.ccs_authorized_amt : transaction.amount; //transaction.amount includes only the authorized amount for split payments, not the full amount
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }, 0),
            totalSales: _.sumBy(employeeTransactions, transaction => {
              if (transaction?.type && (transaction.type === 'Credit Sale' ||
                transaction.type === 'Cash Sale' ||
                transaction.type === 'Credit Sale - Split Payment' ||
                transaction.type === 'Cash Sale - Split Payment' ||
                transaction.type === 'Void' ||
                transaction.type === 'Credit Refund' ||
                transaction.type === 'Cash Refund')) {
                const amount = (transaction.amount < 0 || !transaction.total_amt || transaction.type.includes('Split Payment')) ? transaction.amount : transaction.total_amt;
                return Math.round(parseFloat(amount, 10) * 100) / 100;
              }
            }, 0),
            email: employee.email,
            role: employeeRole
          })).value();

      }
    });

    const resultCsv = [];
    const defaultRow = {
      'Account Number': 'N/A',
      'DBA': '',
      'Sold By': '',
      'Role': '',
      'Transactions': 0,
      'Discounts': '$0.00',
      'Refunds': '$0.00',
      'Voids': '$0.00',
      'Net Sales': '$0.00',
      'Tips': '$0.00',
      'Taxes': '$0.00',
      'Total Collected': '$0.00',
      'Total Sales': '$0.00',
    };

    if (groupByEmployeeTransactions && groupByEmployeeTransactions.length) {
      groupByEmployeeTransactions.forEach((transactions) => {
        if (transactions && transactions.length && transactions[0]) {
          const transaction = transactions[0];
          const accountNumber = transaction?.accountNumber && transaction.accountNumber !== 'null' ? transaction.accountNumber : 'N/A';
          resultCsv.push({
            'Account Number': accountNumber,
            'DBA': transaction.dba,
            'Sold By': transaction.email,
            'Role': transaction.role,
            'Transactions': transaction.transactions,
            'Discounts': numeral(transaction.discounts).format('$0,0.00'),
            'Refunds': numeral(transaction.refunds).format('$0,0.00'),
            'Voids': numeral(transaction.voids).format('$0,0.00'),
            'Net Sales': numeral(transaction.netSales).format('$0,0.00'),
            'Tips': numeral(transaction.tips).format('$0,0.00'),
            'Taxes': numeral(transaction.taxes).format('$0,0.00'),
            'Total Collected': numeral(transaction.totalCollected).format('$0,0.00'),
            'Total Sales': numeral(transaction.totalSales).format('$0,0.00'),
          });
        } else {
          resultCsv.push(defaultRow);
        }
      });

    } else {
      resultCsv.push(defaultRow);
    }

    return resultCsv;
  },

  createCustomerExperienceCsv: function (transactions, user, debtRepayment) {

    const ignoredTypes = ['VOID', 'REFUND', 'CREDIT REFUND'];
    let ratedReceipts = _.filter(transactions, (t) => t.customer_rating && !ignoredTypes.includes(t.type.toUpperCase()));
    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);

    let array = [];

    if (ratedReceipts && ratedReceipts.length) {
      ratedReceipts.map((transaction) => {

        let object = {};

        object['Account Number'] = account.mid;
        object['DBA'] = account.dba_name;
        object['Date'] = transaction.datetime;
        object['Invoice'] = transaction.invoice;
        object['Customer Name'] = FormatTextUtils.formatName(transaction.first_name, transaction.last_name, 'Unnamed Customer');
        object['Customer Email'] = transaction.email || _.head(transaction.email_addresses);
        object['Sold By'] = transaction.sold_by;
        object['Rating'] = transaction.customer_rating;
        if (debtRepayment) {
          object['Customer Account Number'] = transaction.customer_account_number ? transaction.customer_account_number : '';
        }
        array.push(object);
      });
    } else {

      let object = {};

      object['Account Number'] = account.mid;
      object['DBA'] = account.dba_name;
      object['Date'] = '';
      object['Invoice'] = '';
      object['Customer Name'] = '';
      object['Customer Email'] = '';
      object['Sold By'] = '';
      object['Rating'] = '';
      if (debtRepayment) {
        object['Customer Account Number'] = '';
      }
      array.push(object);
    }

    return array;
  },

  createDepositsCsv: function (deposits, user) {
    return deposits.map((deposit) => {
      const account = _.find(user.data.merchantAccounts, ['mid', deposit.mea_mid]);
      return {
        'Account Number': deposit.mea_mid,
        'DBA': account.dba_name,
        'Batch Date': FormatTextUtils.formatDateOrReturnBlank(deposit.deposit_date, 'MM/DD/YYYY'),
        'Process Date & Time': FormatTextUtils.formatDateOrReturnBlank(deposit.proc_date, 'MM/DD/YYYY h:mm a'),
        'Deposit Date & Time': FormatTextUtils.formatDateOrReturnBlank(deposit.effective_date, 'MM/DD/YYYY h:mm a'),
        'Deposit Type': deposit.type,
        'Last 4 Bank Account': deposit.account,
        'Total Fees': FormatTextUtils.formatDollarsOrReturnBlank(deposit.total_fees, '$0,0.00'),
        'Chargeback Adjustments': FormatTextUtils.formatDollarsOrReturnBlank(deposit.chargeback_adjustments, '$0,0.00'),
        'Chargeback Representment': FormatTextUtils.formatDollarsOrReturnBlank(deposit.chargeback_representment, '$0,0.00'),
        'Other Adjustments': FormatTextUtils.formatDollarsOrReturnBlank(deposit.other_adjustments, '$0,0.00'),
        'Total Transactions': FormatTextUtils.formatDollarsOrReturnBlank(deposit.total_transactions, '$0,0.00'),
        'Net Deposit Amount': FormatTextUtils.formatDollarsOrReturnBlank(deposit.amount_to_clear, '$0,0.00')
      }
    });
  },

  createRefundSummaryCsv: function (refunds, user) {
    return refunds.map(refund => {
      return {
        'Account Number': refund.mid,
        'DBA': _.find(user.data.merchantAccounts, ['mid', refund.mid]).dba_name,
        'Date & Time': moment(refund.datetime).format('MM/DD/YYYY h:mm a'),
        'Card Brand': refund.network,
        'Auth Types': refund.cc_type,
        'Process': (refund.is_complete) ? 'Complete' : 'Pending',
        'Auth': refund.cc_auth_code,
        'Number': refund.receipt_id,
        'Amount': numeral(refund.amount).format('$0,0.00')
      }
    });
  },

  createDepositTransactionsCsv: function (depositTransactions, user) {

    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);

    return depositTransactions.map((depositTransaction) => {
      return {
        'Account Number': account.mid,
        'DBA': account.dba_name,
        'ID': account.id,
        'Batch Date': FormatTextUtils.formatDateOrReturnBlank(depositTransaction.batch_date, 'MM/DD/YYYY'),
        'Batch Number': depositTransaction.batch_id,
        'Type': depositTransaction.transactionType,
        'Description': depositTransaction.description,
        'BRIC': depositTransaction.bric,
        'Device': depositTransaction.device,
        'Transaction Date': FormatTextUtils.formatDateOrReturnBlank(depositTransaction.date, 'MM/DD/YYYY'),
        'Payment Type': TransactionsUtil.getAchTransactionName(depositTransaction),
        'Card Brand': depositTransaction.brand,
        'First 6': depositTransaction.first6,
        'Last 4': depositTransaction.last4,
        'Auth Code': depositTransaction.auth_code,
        'Transaction Amount': FormatTextUtils.formatDollarsOrReturnBlank(depositTransaction.amount, '$0,0.00'),
        'Rate': depositTransaction.rate,
        'Processing Fee': FormatTextUtils.formatDollarsOrReturnBlank(depositTransaction.fee, '$0,0.00'),
        'Net Deposit Amount': FormatTextUtils.formatDollarsOrReturnBlank(depositTransaction.total, '$0,0.00')
      }
    });
  },

  createProductSalesCsv: function (transactionData) {

    let productSalesTotals = ReportUtil.buildProductSalesTotals(transactionData);

    let productSalesJson = [];

    transactionData.categorySales.map((category) => {

      // Category Section Header
      productSalesJson.push({
        '': category.name,
        'Average Price': '',
        'Items Sold': '',
        'Total': ''
      });
      let sortedTransactions = _.sortBy(category.transactions, 'total_with_modifier').reverse();

      sortedTransactions.map((transaction, i) => {

        productSalesJson.push({
          '': transaction.name,
          'Average Price': numeral(transaction.total_with_modifier / transaction.sold).format('$0,0.00'),
          'Items Sold': transaction.sold,
          'Total': numeral(transaction.total_with_modifier).format('$0,0.00')
        });
      });

      productSalesJson.push({
        '': 'Subtotal',
        'Average Price': '',
        'Items Sold': category.sold,
        'Total': category.total
      });
    });

    productSalesJson.push({
      '': 'Category Sales Subtotal',
      'Average Price': numeral(productSalesTotals.averageCategorySales).format('$0,0.00'),
      'Items Sold': productSalesTotals.categorySalesTotalSold,
      'Total': numeral(productSalesTotals.categorySalesTotalAmount).format('$0,0.00')
    });

    productSalesJson.push({
      '': 'Discounts Subtotal',
      'Average Price': numeral(productSalesTotals.averageDiscount).format('$0,0.00'),
      'Items Sold': productSalesTotals.discountsTotal,
      'Total': numeral(productSalesTotals.discountsAmount).format('$0,0.00')
    });

    productSalesJson.push({
      '': 'Refunds',
      'Average Price': '',
      'Items Sold': productSalesTotals.soldRefunds,
      'Total': numeral(productSalesTotals.totalRefunds).format('$0,0.00')
    });

    productSalesJson.push({
      '': 'Voids',
      'Average Price': '',
      'Items Sold': productSalesTotals.soldVoids,
      'Total': numeral(productSalesTotals.totalVoids).format('$0,0.00')
    });

    productSalesJson.push({
      '': 'Unpaid Amount',
      'Average Price': '',
      'Items Sold': '',
      'Total': numeral(productSalesTotals.totalUnpaidAmount).format('$0,0.00')
    });

    productSalesJson.push({
      '': 'Net Sales',
      'Average Price': '',
      'Items Sold': '',
      'Total': numeral(productSalesTotals.netSales).format('$0,0.00')
    });

    return productSalesJson;

  },

  createFlashReportCsv: function (transactionData, user, achEnabled) {

    let flashReportJson = [];

    if(_.isNil(transactionData?.categorySales)){
      return flashReportJson;
    }

    let flashReportTotals = ReportUtil.buildFlashReportTotals(transactionData);

    const userType = UserUtil.userType(user);
    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);
    const giftCardData = _.find(transactionData?.creditCards, element => element.name === 'Gift Card');

    const employee = transactionData.employee;
    const employeeName = employee?.first_name && employee?.last_name ? `${employee.first_name} ${employee.last_name}` : false

    flashReportJson.push({
      ...(!!employeeName && {'Employee Name': employeeName}),
      'Account Number': account.mid,
      'DBA': account.dba_name,
      '': 'Category Sales',
      'Items Sold': '',
      'Total Sales': '',
    });

    transactionData?.categorySales.map((category) => {

      flashReportTotals.categoryTotalSold = flashReportTotals.categoryTotalSold + category.sold;
      flashReportTotals.categoryTotalSales = flashReportTotals.categoryTotalSales + category.total_with_modifier;

      flashReportJson.push({
        '': category.name,
        'Items Sold': category.sold,
        'Total Sales': numeral(category.total_with_modifier).format('$0,0.00')
      });

    });

    flashReportJson.push({
      '': 'Subtotal',
      'Items Sold': flashReportTotals.categoryTotalSold,
      'Total Sales': numeral(flashReportTotals.categoryTotalSales).format('$0,0.00')
    });

    flashReportJson.push({
      '': '',
      'Items Sold': '',
      'Total Sales': ''
    });

    flashReportJson.push({
      '': 'Discounts',
      'Items Sold': '',
      'Total Sales': ''
    });

    transactionData?.discounts.map((discount) => {

      flashReportTotals.discountsTotalSold = flashReportTotals.discountsTotalSold + discount.sold;
      flashReportTotals.discountsTotalSales = flashReportTotals.discountsTotalSales + discount.total;

      flashReportJson.push({
        '': discount.name,
        'Items Sold': discount.sold,
        'Total Sales': numeral(discount.total).format('$0,0.00')
      });

    });

    flashReportJson.push({
      '': 'Discounts Subtotal',
      'Items Sold': flashReportTotals.discountsTotalSold,
      'Total Sales': numeral(flashReportTotals.discountsTotalSales).format('$0,0.00')
    });

    flashReportJson.push({
      '': '',
      'Items Sold': '',
      'Total Sales': ''
    });

    flashReportJson.push({
      '': 'Refunds',
      'Items Sold': flashReportTotals.soldRefunds,
      'Total Sales': numeral(flashReportTotals.totalRefunds).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Voids',
      'Items Sold': flashReportTotals.soldVoids,
      'Total Sales': numeral(flashReportTotals.totalVoids).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Unpaid Amount',
      'Items Sold': '',
      'Total Sales': numeral(flashReportTotals.totalUnpaidAmount).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Refunds, Voids and Unpaid Subtotal',
      'Items Sold': flashReportTotals.soldRefunds + flashReportTotals.soldVoids,
      'Total Sales': numeral(flashReportTotals.totalRefundsVoidsUnpaid).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Net Sales',
      'Items Sold': '',
      'Total Sales': numeral(flashReportTotals.categoryTotalSales + (flashReportTotals.discountsTotalSales +
        flashReportTotals.totalRefundsVoidsUnpaid)).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Tips',
      'Items Sold': transactionData?.tips.amount,
      'Total Sales': numeral(transactionData?.tips.total).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Tax',
      'Items Sold': '',
      'Total Sales': numeral(transactionData?.tax).format('$0,0.00')
    });

    flashReportJson.push({
      '': 'Total Collected',
      'Items Sold': '',
      'Total Sales': numeral((transactionData?.tax + transactionData?.tips.total + flashReportTotals.categoryTotalSales) + (flashReportTotals.discountsTotalSales + flashReportTotals.totalRefundsVoidsUnpaid)).format('$0,0.00')
    });


    flashReportJson.push({
      '': '',
      'Items Sold': '',
      'Total Sales': ''
    });

    flashReportJson.push({
      '': 'PAYMENT METHODS',
      'Items Sold': '',
      'Total Sales': ''
    });

    flashReportJson.push({
      '': 'Cash',
      'Items Sold': transactionData?.cash.amount,
      'Total Sales': numeral(transactionData?.cash.total).format('$0,0.00')
    });

    achEnabled && flashReportJson.push({
      '': 'Bank Account',
      'Items Sold': transactionData?.ach?.amount ?? 0,
      'Total Sales': numeral(transactionData?.ach?.total).format('$0,0.00') ?? '$0.00'
    });

    if (userType === 'PA') {
      flashReportTotals.giftCardTotalSales = giftCardData?.total ? giftCardData?.total : 0;
      flashReportJson.push({
        '': 'Gift Cards',
        'Items Sold': giftCardData?.sold ? giftCardData.sold : '0',
        'Total Sales': giftCardData?.total ? numeral(giftCardData.total).format('$0,0.00') : '$0.00'
      });
    }

    flashReportJson.push({
      '': 'Credit Cards',
      'Items Sold': '',
      'Total Sales': ''
    });

    transactionData?.creditCards.map((creditCard) => {

      if (creditCard.name !== 'Gift Card') {
        flashReportTotals.creditCardTotalSold = flashReportTotals.creditCardTotalSold + creditCard.sold;
        flashReportTotals.creditCardTotalSales = flashReportTotals.creditCardTotalSales + creditCard.total;

        flashReportJson.push({
          '': creditCard.name,
          'Items Sold': creditCard.sold,
          'Total Sales': numeral(creditCard.total).format('$0,0.00')
        });
      }

    });

    flashReportJson.push({
      '': 'Subtotal',
      'Items Sold': flashReportTotals.creditCardTotalSold,
      'Total Sales': numeral(flashReportTotals.creditCardTotalSales).format('$0,0.00')
    });


    flashReportJson.push({
      '': 'Total Collected',
      'Items Sold': '',
      'Total Sales': numeral(flashReportTotals.creditCardTotalSales + transactionData?.cash.total + flashReportTotals.giftCardTotalSales + transactionData?.ach?.total).format('$0,0.00')
    });

    return flashReportJson;

  },

  createGiftCardSummaryReportCSV: function(transactionData, user) {
    const account = _.find(user.data.merchantAccounts, ['mea_id', parseInt(user.selectedMerchantAccount)]);

    const giftCardSummaryReportJson = [];

    transactionData.giftCards.map((item) => {
      giftCardSummaryReportJson.push({
        'Account Number': account.mid,
        'DBA': account.dba_name,
        'Activities': item.activity,
        'Items Sold': item.quantity,
        'Total Sales': numeral(item.sum).format('$0,0.00')
      });
    });

    return giftCardSummaryReportJson;
  },

  createModifierPerformanceCsv: function (data, user, allAccounts) {
    let accountNumber = _.find(user.data.merchantAccounts, ['mea_id', Number(user.selectedMerchantAccount)]).mid;

    let result = [];

    (data) ? (Object.keys(data).map((key, index) => {

      return Object.keys(data[key].modifiers).map((setKey) => {

        result.push({
          'Account Number': allAccounts ? data[key].mid : accountNumber,
          'Set Name': key,
          'Modifier Name': setKey,
          'Quantity Sold': data[key].modifiers[setKey].count,
          'Net Sales': numeral(data[key].modifiers[setKey].total).format('$0,0.00')
        });
      });
    })) : result.push({
      'Account Number': '',
      'Set Name': '',
      'Modifier Name': '',
      'Quantity Sold': '',
      'Net Sales': ''
    });

    return result;

  },

  createBatchesSummaryCsv: function (data) {
    return data.map((batch) => {
      return {
        'Date': moment(batch.batch_date).format('MM/DD/YYYY h:mm a'),
        'Batch #': batch.batch_id,
        'Sales': batch.total_purch_trans,
        'Sales Amount': numeral(batch.total_purch_amt).format('$0,0.00'),
        'Refunds': batch.total_return_trans,
        'Refunds Amount': numeral(batch.total_return_amt).format('$0,0.00'),
        'Total Transactions': batch.total_trans,
        'Gross Amount': numeral(batch.total_gross_amt).format('$0,0.00'),
        'Net Amount': numeral(batch.total_volume).format('$0,0.00')
      }
    })
  },

  createsBatchDetailCsv: function (selectedBatch) {

    const batchNumber = selectedBatch?.batch_id;
    const transactions = selectedBatch?.detail?.transactions?.rows || [];

    return transactions.map((batch) => {
      return {
        'Batch Number': batchNumber,
        'Date': moment(batch.date).format('MM/DD/YYYY'),
        'Time': moment(batch.date).format('h:mm a'),
        'Device': batch.device,
        'Card Brand': batch.brand,
        'First 6': batch.first6,
        'Last 4': batch.last4,
        'Auth Type': batch.type,
        'Process': batch.amount < 0 ? 'Refund' : 'Sale',
        'Invoice': batch.invoice_number,
        'ARN': batch.arn,
        'Auth Number': batch.auth_code,
        'Total': numeral(batch.amount).format('$0,0.00')
      }
    })
  },

  mapNetworkTransactions: function (transactions) {
    const getType = (type, isGiftCard) => {
      const statement = `${type} - ${isGiftCard}`.toUpperCase();
      let formattedType;
      switch (statement) {
        case 'PERSONAL SAVINGS - FALSE':
          case 'BUSINESS SAVINGS - FALSE':
          formattedType = 'Savings Account';
        break;
        case 'PERSONAL CHECKING - FALSE':
          case 'BUSINESS CHECKING - FALSE':
          formattedType = 'Checking Account';
          break;
        case 'NULL - FALSE':
          formattedType = 'Cash';
          break;
        default:
          formattedType = type;
      }
      return formattedType;
    }
    return transactions?.map((transaction) => {

      const isAch = transaction?.uniq_id?.includes('ach') || transaction?.type?.includes('ACH');
      const noNetwork = !transaction?.network;
      const shouldMapTransaction = isAch || noNetwork;

      if (shouldMapTransaction) {
        transaction.network = getType(transaction?.cc_type, !!transaction?.gift_card_type);
      }

      return transaction;
    });
  },

  mapBulkImportHeaders: function (bulkImport) {
    return new Promise((resolve, reject) => {
      Papa.parse(bulkImport, {
        header: true,
        step: function (row, parser) {
          parser.abort();
        },
        error: (error) => reject(error),
        complete: ({data, errors, meta}) => {
          if (errors?.length) return reject(errors);
          return resolve({rows: data, headers: meta.fields});
        }
      });
    });
  }
};

export default csvUtil;
