/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import InvoiceSummary from '../shared/enhancedInvoices/InvoiceSummary';
import InvoiceDetail from './InvoiceDetail';
import Loading from '../Loading';
import { getCustomerInvoices, setInvoice, setClpInvoiceOriginal, setClpInvoiceWithoutCd } from '../../actions/invoicesActions';
import CustomerPaymentHeadline
  from '../shared/enhancedInvoices/CustomerPaymentHeadline';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import NoOpenInvoices from '../icons/NoOpenInvoices';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {paymentPageMainTitle} from '../../jss/invoicePaymentPageStyles'

export default class OpenInvoices extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.selectInvoice = this.selectInvoice.bind(this);
    this.clearSelectedInvoice = this.clearSelectedInvoice.bind(this);
    this.clearSelectedInvoiceAndLoad = this.clearSelectedInvoiceAndLoad.bind(this);

  }

  loadData() {
    const {token, decodedToken, dispatch} = this.props;

    if (token && decodedToken) {
      dispatch(getCustomerInvoices(token));
    }
  }

  selectInvoice(invoiceIndex) {
    const { dispatch, openInvoices } = this.props;
    dispatch(setInvoice(openInvoices[invoiceIndex]));
    dispatch(setClpInvoiceOriginal(openInvoices[invoiceIndex]));

  }

  clearSelectedInvoice() {
    const { dispatch } = this.props;
    dispatch(setInvoice(null));
    dispatch(setClpInvoiceOriginal(null));
    dispatch(setClpInvoiceWithoutCd(null));
  }

  async clearSelectedInvoiceAndLoad() {
    this.clearSelectedInvoice();
    await this.loadData();
  }

  render() {

    const { openInvoices, loading, customers, t, invoices, merchantSettings } = this.props;
    const { selectedInvoice } = invoices;
    const customerInfo = customers.customerPaymentData;
    const merchant = merchantSettings?.merchantSettings;
    const colorRender = merchant?.brand_color || LabelUtil.getLabelColor();

    return (
      <div className='openInvoices'>

        {selectedInvoice === null && !openInvoices?.length && loading && <Loading />}

        {selectedInvoice === null && openInvoices?.length > 0 && (
          <div className='openInvoicesLanding customerPaymentSection'>

            <CustomerPaymentHeadline customerInfo={customerInfo}/>

            <div className='sectionTitle'>
              <Typography
                component='h1'
                sx={paymentPageMainTitle}
              >
                {t('OpenInvoices.Title')}
              </Typography>
            </div>
            {openInvoices?.map((invoice, index) => (

              <InvoiceSummary
                invoice={invoice}
                onClick={() => this.selectInvoice(index)}
                key={index}
                actionItem={IconUtil.getIcon('ChevronRight')}
                t={t}
              />

            ))}

          </div>)}

        {selectedInvoice !== null  && (
          <div className='openInvoicesDetail'>
            <InvoiceDetail
              invoice={selectedInvoice}
              {...this.props}
              goBackAndReset={this.clearSelectedInvoiceAndLoad}
              brandColor={colorRender}
            />
          </div>)}

        {selectedInvoice === null && !loading && openInvoices?.length === 0 && (
          <Box
            data-test-id='open-invoices-empty'
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NoOpenInvoices
              color={colorRender}
              style={{
                marginBottom: '88px'
              }} />
            <Typography
              variant='h1'
              sx={{
                fontFamily: 'inter',
                fontSize: '30px',
                marginBottom: '15px',
                textAlign: 'center',
                fontWeight: 'bold',
                padding: '0 10px'
              }}
            >
              {t('NoOpenInvoices.Title')}
            </Typography>
            <Typography
              variant='p'
              sx={{
                fontFamily: 'inter',
                fontWeight: '500',
                fontSize: '17px',
                textAlign: 'center',
                maxWidth: '535px',
                marginBottom: '50px',
                padding: '0 10px'
              }}
            >
              {t('NoOpenInvoices.Description')}
            </Typography>
          </Box>
        )}
      </div>
    );

  }

}
