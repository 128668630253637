/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';

const MerchantProcessingAgreement = () => {
  return (
    <div className='MerchantProcessingAgreement'>
      <h2>MERCHANT PROCESSING AGREEMENT TERMS</h2>
      <strong>RECITAL AND DEFINITIONS: </strong>
      <br/>
      <span>Parties to these Merchant Processing Agreement Terms (this “Agreement”): </span>
      <br/>
      <span>“Merchant” - The Merchant identified on the Merchant Application, as defined below. </span>
      <br/>
      <span>“Guarantor(s)” - The Guarantor(s) of Merchant’s obligations under this Agreement, as set forth in the Merchant Application. </span>
      <br/>
      <span>“Member” –Member Bank identified on the Merchant Application or Bank Disclosure Page accompanying these Terms. </span>
      <br/>
      <span>“Agent” –The agent identified on the Merchant Application. </span>
      <br/>
      <span>“Processing Partner” – Any third party provider of electronic transaction processing services that has contracted with Agent to provide certain services to Merchants from time to time; provided, however, that with respect to ACH processing services identified under Article 7 and First National Bank of Omaha is the Originating Depository Financial Institution (“ODFI”), EPX Acquisition Company, LLC d/b/a EPX, shall be the third-party provider of such services. </span>
      <br/>
      <span>“Servicers” - collectively refers to Member, Agent and any applicable Processing Partner. </span>
      <br/>
      <span>“Merchant Portal” – The Merchant Portal is the online portal Merchant can access information and documents pertaining to the Merchant Account. </span>
      <p>Additional definitions are located in Article VIII - Glossary </p>
      <strong>ACCEPTANCE OF TERMS BY MERCHANT: </strong>
      <br/>
      <span>Merchant has completed and submitted to Servicers an application for services that provides information about
        Merchant’s business and processing needs (the “Merchant Application”). </span>
      <span>As part of the Merchant Application, Merchant has certified that it has read this Agreement, and understands
        and agrees that the terms of this Agreement will be binding upon it if and </span>
      <span>when Servicers approve the Merchant Application to receive requested services. A MERCHANT’S SUBMISSION OF A
        TRANSACTION TO SERVICERS SHALL CONSTITUTE </span>
      <span>RATIFICATION OF EACH AND ALL OF MERCHANT’S OBLIGATIONS UNDER THIS AGREEMENT. To the extent Merchant accepts
        American Express (“AXP”) cards, the </span>
      <span>provisions in this Agreement with respect to AXP apply if Merchant does not have a separate agreement with
        AXP. </span>
      <br/>
      <br/>
      <p className='larger'>
        <strong>THIS AGREEMENT CONTAINS A BINDING ARBITRATION PROVISION IN SECTION 1.50 THAT AFFECTS
          MERCHANT’S
          <span> RIGHTS. PLEASE REVIEW IT CAREFULLY. If Merchant has a dispute with Servicers that cannot be resolved
          informally, the </span>
          <span>arbitration provision in Section 1.50 below requires resolution of the dispute in an individual arbitration
          or small claims court proceedings. </span>
          <span>In arbitration, there is no judge or jury and there is less discovery and appellate review than in
          court. </span></strong>
      </p>
      <div style={{textAlign: 'center'}}><p>I.<strong>GENERAL PROVISIONS. </strong></p></div>
      <span>The General Provisions set out in this Article I govern the relationship between Servicers and Merchant for all
        services provided by or through Servicers. The subsequent sections of </span>
      <span>this Agreement define the terms of service for different service offerings of the Servicers and shall apply to
        Merchant at such time that Merchant commences receiving such services. </span>
      <br/>
      <br/>
      <strong>1.1 </strong><strong>Compliance with Rules and Servicers Guidelines. </strong>
      <br/>
      <span>Merchant agrees to comply with and be subject to all Rules of Mastercard International, Inc., (“Mastercard”),
        Visa International (“VISA”), JCB, International (“JCB”), Discover </span>
      <span>Network (“Discover Network”), American Express (“AXP”) and the National Automated Clearing House Association
        (“Nacha”), and certain non-bank card association fleet card </span>
      <span>services, including, but not limited to, Voyager and/or WEX cards (“Fleet”), as they may exist from time to
        time and as applicable to Merchant’s activities under this Agreement. </span>
      <span>Merchant also agrees to comply with all guidelines, policies and procedures for services provided to Merchant
        by Servicers from time to time. Additional information and links to </span>
      <span>locations where Merchant can see or obtain copies of the Rules are located at </span><a
      target='_blank'
      href='http://www.myresourceportal.com/programguide'>http://www.myresourceportal.com/programguide</a><span>, </span><a
      target='_blank'
      href='https://usa.visa.com/support/consumer/visa-rules.html'>https://usa.visa.com/support/consumer/visa-rules.html </a>
      <span>and </span><a
      target='_blank'
      href='https://www.mastercard.us/en-us/about-mastercard/what-we-do/rules.html'>https://www.mastercard.us/en-us/about-mastercard/what-we-do/rules.html</a>
      <span>. The Mastercard and Visa Rules are subject to change at any time without notice including, without </span>
      <span>limitation, the hyperlinks in the immediately preceding sentence. </span>
      <span>MERCHANT UNDERSTANDS AND ACKNOWLEDGES THAT DISCOVER, AXP, JCB, NACHA AND OTHER SERVICES ARE NOT PROVIDED TO IT
        BY MEMBER,, </span>
      <span>BUT ARE INSTEAD PROVIDED BY AGENT, THE PROCESSING PARTNER AND/OR THIRD PARTIES. FOR PIN DEBIT TRANSACTIONS,
        SUCH THIRD PARTIES MAY </span>
      <span>INCLUDE SPONSORING OR ACQUIRING BANKS THAT ARE NOT RELATED TO MEMBER BANK. </span>
      <span>THE PROVISIONS OF THIS AGREEMENT REGARDING DISCOVER NETWORK CARD TRANSACTIONS, AXP CARD TRANSACTIONS, JCB CARD
        TRANSACTIONS, </span>
      <span>NACHA TRANSACTIONS, OR ANY OTHER NON-BANK CARD TRANSACTIONS CONSTITUTE AN AGREEMENT SOLELY BETWEEN MERCHANT,
        AGENT AND </span>
      <span>THIRD PARTIES. MEMBER IS NOT A PARTY TO THIS AGREEMENT INSOFAR AS IT RELATES TO SUCH TRANSACTIONS. </span>
      <br/>
      <strong>1.2 </strong><strong>Enforcement of Servicer Rights. </strong>
      <br/>
      <span>To the maximum degree permitted by law and by the Rules of Mastercard, VISA, JCB, AXP, Discover Network, NACHA,
        and any applicable Fleet card provider, it is the </span>
      <span>intention of the parties that the rights of Servicers set forth in this Agreement, or arising from this
        Agreement, may be exercised solely by Agent or an applicable Processing Partner, </span>
      <span>or its or their authorized designees. </span>
      <br/>
      <strong>1.3 </strong><strong>Warranties of Merchant. </strong>
      <br/>
      <span>Merchant hereby represents and warrants to Servicers at the time of submission of the Merchant Application and
        during the term of this Agreement that: </span> <br/>
      <span>(a) </span><span>All information contained in the Merchant Application or any other documents delivered to or on
      behalf of Servicers in connection therewith is true and complete and accurately </span>
      <span>reflects Merchant’s business, financial condition and principal partners, owners or officers. </span>
      <br/>
      <span>(b) </span><span>Merchant is duly organized and in good standing under the laws of the jurisdiction of its
      organization, and is duly qualified to conduct business in each jurisdiction where failure </span>
      <span>to do so would have a material adverse effect on its business. </span> <br/>
      <span>(c) </span><span>Merchant has the power to execute, deliver and perform this Agreement, and this Agreement is duly
      authorized, constitutes a valid and binding obligation of Merchant and will </span>
      <span>not violate any provisions of law, or conflict with any other agreement to which Merchant is subject. </span>
      <br/>
      <span>(d) </span><span>Merchant has all licenses, if any, required to conduct its business and is qualified to do business
      in every jurisdiction where it is required to do so. </span> <br/>
      <span>(e) </span><span>There is no action, suit or proceeding at law or in equity now pending or to Merchant’s knowledge,
      threatened by or against or affecting Merchant which would substantially </span>
      <span>impair its right to carry on its business as now conducted or adversely affect its financial condition or
        operations. </span> <br/>
      <span>(f) </span><span>Unless Merchant notifies Servicers in writing (either on the Merchant Application or otherwise) and
      is approved by Servicers, no other processing relationship for any of the </span>
      <span>services offered by Servicers under this Agreement may exist between Merchant and another Card processing
        institution, for any business owned or operated by Merchant. </span> <br/>
      <span>(g) </span><span>(i) the taxpayer identification number (“TIN”) provided on the Merchant Application is Merchant’s
      true and correct TIN; (ii) Merchant is not subject to backup withholding </span>
      <span>because (a) Merchant is exempt from backup withholding, (b) Merchant has not been notified by the IRS that it
        is subject to backup withholding as a result of a failure to report all </span>
      <span>interest or dividends, or (c) the IRS has notified Merchant that it is no longer subject to backup withholding,
        and (iii) Merchant is a citizen of the United States of America (“U.S.”) or </span>
      <span>other U.S. person. (For federal tax purposes, Merchant is considered a U.S. person if Merchant is: an
        individual who is a U.S. citizen, or U.S. resident alien, partnership, corporation, </span>
      <span>company or association created or organized in the United States or under the laws of the United States, and
        estate (other than a foreign estate), or a domestic trust (as defined in the </span>
      <span>Internal Revenue Code section 301.7701-7.)) </span> <br/>
      <span>(h) </span><span>No owner, officer, director, employee or agent of Merchant is a current or former “senior” official
      in the executive, legislative, administrative, military, or judicial branch of any </span>
      <span>government (elected or not); or an official of a political party; or an executive of a government-owned
        commercial enterprise; or a family member of any of the foregoing officials; or </span>
      <span>a close personal or professional associate of any foregoing officials. </span>
      <br/>
      <strong>1.4 </strong><strong>Notifications Regarding Changes in Merchant’s Business or Application
      Information. </strong>
      <br/>
      <span>(a) </span><span>Merchant must provide Servicers with immediate notice of its intent to: (i) transfer or sell any
      substantial part of its total assets, or liquidate; (ii) change the basic nature of its </span>
      <span>business, including selling any products or services not related to its current business; (iii) change
        ownership or transfer control of its business; (iv) enter into any joint venture, </span>
      <span>partnership or similar business arrangement whereby any person or entity not a party to this Agreement assumes
        any interest in Merchant’s business; or (v) alter in any way Merchant’s </span>
      <span>approved monthly volume and average ticket. </span> <br/>
      <span>(b) </span><span>Merchant must immediately notify Servicers of any Bankruptcy, receivership, insolvency, levy or
      similar action initiated by or against Merchant or any of its principals. Merchant </span>
      <span>will include Servicers on the list of creditors filed with the applicable Bankruptcy Court in connection with
        any such Bankruptcy, whether or not a claim exists at the time of filing. </span> <br/>
      <span>(c) </span><span>Merchant must notify Servicers in writing of any changes to the information contained in the
      Merchant Application, including but not limited to, (i) a material change to Merchant’s </span>
      <span>financial condition (within three (3) days of such occurrence), (ii) any additional location(s) or new
        business, (iii) a change in the business location or contact information, both physical </span>
      <span>and email addresses, (iv) the identity of principals and/or owners, (v) the form of business organization, (vi)
        type of goods and services provided, and (vii) how sales are completed. </span>
      <span>Merchant must also notify Servicers in writing if Merchant sells or closes its business. Except for a change to
        the financial condition, all such notices must be received by Servicers </span>
      <span>seven (7) days before the change. Merchant will also provide updated information to Servicers upon request.
        Merchant acknowledges that Servicers are relying on Merchant maintaining </span>
      <span>current contact information with Servicers for all manner of notices allowed under this Agreement. Merchant’s failure to timely notify Servicers of any change(s) to its contact </span>
      <span>information will be a waiver of any defense of Merchant that timely or proper notice was not agreed to or received by Merchant. </span>
      <br/>
      <span>(d) </span><span>Merchant must immediately notify Servicers in writing if Merchant is threatened with or becomes party to any action, suit or proceeding at law or in equity that could substantially </span>
      <span>impair its right to carry on its business or adversely affect its financial condition or operations. Merchant must provide separate notification regarding changes to service providers used </span>
      <span>by Merchant in connection with Servicer-provided services, including but not limited to AXP, and equipment leasing companies. </span>
      <br/>
      <strong>1.5 </strong><strong>Credit and Financial Inquiries: Anti-Money Laundering; Additional Locations:
      Inspections. </strong> <br/>
      <span>(a) </span><span>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT. To help the government fight the funding of terrorism and money laundering </span>
      <span>activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person (individual, corporation, partnership, trust, estate, or any </span>
      <span>other entity recognized as a legal person) who opens an account. In order to comply with the requirements of the USA PATRIOT Act, Member may require the Merchant to provide </span>
      <span>its legal name, street address, taxpayer identification number and other information that will allow Member to identify Merchant prior to establishing accounts under, or in connection </span>
      <span>with, this Agreement. Member reserves the right to require that Merchant promptly provide, to Member sufficient identification documentation upon request and in connection with </span>
      <span>USA PATRIOT Act compliance. U.S. Economic Sanctions. Prior to establishing accounts under, or in connection with, this Agreement, Member may require identifiable information </span>
      <span>on the Merchant’s Cardholders to allow U.S. Bank USA to remain in compliance with U.S. Economic Sanctions. Merchant agrees to promptly provide any such information to Member. </span>
      <br/><span>(b) </span><span>Merchant hereby authorizes Servicers to make, at the time of submission of the Merchant Application and at any later time during which Merchant owes any obligation to </span>
      <span>Servicers, any credit inquiries which, in their discretion, may be necessary or prudent. Merchant, and each individual owner thereof (in the case of an unincorporated business), general </span>
      <span>partner thereof (in the case of a partnership), Guarantor, individual executing this Agreement, and individual who is or becomes personally liable for the performance of the obligations </span>
      <span>of Merchant under this Agreement, hereby agrees that such inquiries may include, in the Servicers’ discretion and without limitation, obtaining a consumer credit report of each of </span>
      <span>them. If requested to do so by Servicers, Merchant shall provide the written consent of any other person for which an inquiry has been or is to be made. Merchant will also provide any </span>
      <span>financial statements, income tax and business tax returns and other financial information as Servicers may consider necessary to perform initial or periodic reviews of Merchant’s </span>
      <span>financial stability and business practices. </span>
      <br/><span>(c) </span><span>Information obtained under Section 1. 5(a) above may be used by Servicers, without limitation, for one or more of the following purposes: (i) to evaluate current and ongoing </span>
      <span>credit worthiness; (ii) to evaluate continuing eligibility for the services provided under this Agreement, and to establish, administer, service, and enforce provisions of this Agreement; </span>
      <span>(iii) to verify the identity of Merchant, and each individual identified in Section 1. 5(b) above, including matching records or credit information; (iv) for detecting and preventing fraud </span>
      <span>and complying with anti-money laundering and terrorist financial regulations, including checking identities against watch lists established by regulatory agencies or similar bodies; (v) </span>
      <span>to meet legal, regulatory, audit, processing and security requirements; (vi) to support merchant retention operations; or (vi) from time to time, to determine Merchant’s eligibility for </span>
      <span>and occasionally to communicate with Merchant regarding additional products, services or business opportunities. </span>
      <br/><span>(d) </span><span>Merchant, and each person identified in Section 1. 5(b), acknowledges and agrees that a permissible purpose exists under the Fair Credit Reporting Act to support the Servicers </span>
      <span>request for consumer credit inquiries identified in Section 1. 5(b). Merchant may withdraw the authorization to obtain consumer credit reports by contacting the Agent customer service </span>
      <span>department in writing. </span>
      <br/><span>(e) </span><span>Merchant agrees to permit Servicers at any time from time to time, to inspect locations to confirm that Merchant has adhered or is adhering to the terms of this Agreement and is </span>
      <span>maintaining the proper facilities, equipment, inventory, records and licenses or permits (where necessary) to conduct its business. However, nothing in this Section 1.05(e) shall be </span>
      <span>deemed to waive Merchant’s obligation to comply in all respects with the terms of this Agreement. </span>
      <br/><span>(f) </span><span>Merchant may process Card transactions only at locations and websites approved by Servicers. Additional locations may be added, subject to Servicers’ approval. Either Merchant </span>
      <span>or Servicers may delete any location by providing notice as provided in this Agreement. </span>
      <br/><span>(g) </span><span>Representatives of Servicers may, during normal business hours, inspect, audit and make copies of Merchant’s books, accounts, records and files pertaining to any Card </span>
      <span>transactions processed by or through Servicers. </span> <br/>
      <strong>1.6 </strong><strong>Guarantor Notifications and Authorizations. </strong>
      <br/>
      <span>The decision of Servicers to enter into and continue processing Card transactions for Merchant is based on the financial condition of Guarantor and the ability of Guarantor to guarantee </span>
      <span>Merchant’s obligations under this Agreement. Accordingly, Guarantor must provide Servicers with information regarding changes in his or her contact information and financial </span>
      <span>circumstances. Guarantor agrees to take the actions required under Section 1.4(b) through (e). Guarantor also authorizes Servicers to make ongoing inquiries about Guarantor as </span>
      <span>detailed in Section 1.5. For purposes of understanding Guarantors obligations and authorizations under this Section 1.6, Guarantor will apply the provisions of Section 1. 4 and 1. 5 to </span>
      <span>Guarantor by substituting the term Guarantor in place of Merchant where it appears in such sections. </span>
      <br/><strong>1.7 </strong><strong>Account. </strong>
      <br/><span>(a) </span><span>Merchant will establish and maintain an Account at a depository institution approved by Servicers. Merchant will maintain sufficient funds in the Account to satisfy all obligations, </span>
      <span>including the fees, Chargebacks and returns contemplated by this Agreement. Merchant irrevocably authorizes Servicers to debit the Account for fees, Chargebacks, returns, fines and </span>
      <span>any other penalties or amounts owed under this Agreement. In the event the Account lacks sufficient funds, Merchant and Guarantors authorize Servicers, without notice, to debit any </span>
      <span>bank account in their name(s) or the name of any affiliated entity. Merchant must obtain prior consent from Servicers to change the Account. If Merchant does not obtain such consent, </span>
      <span>Servicers may immediately terminate this Agreement and may take other action necessary to protect their interests. </span>
      <br/><span>(b) </span><span>Servicers will settle all valid Card transactions to the Account subject to the terms of this Agreement. Merchant authorizes Servicers to initiate reversal or adjustment entries and </span>
      <span>initiate or suspend such entries. </span>
      <br/><span>(c) </span><span>Servicers, in their sole discretion, shall grant Merchant provisional credit for Card transaction amounts, subject to receipt of final payment by Servicers and subject to all </span>
      <span>Chargebacks and other amounts owed to Servicers under this Agreement. </span>
      <br/><span>(d) </span><span>Merchant authorizes Servicers to initiate ACH debit/credit entries to the Account, as the Account may be changed from time to time and to any other account maintained by </span>
      <span>Merchant at any institution, all in accordance with this Agreement. In the event Merchant changes the Account, this authorization will apply to the new Account. This authorization </span>
      <span>will be effective until both: (i) Servicers have received written notification from Merchant terminating this authorization, and (ii) all obligations of Merchant to Servicers have been </span>
      <span>paid in full. Merchant may be required to provide to Servicers with a voided Account check and/or fill in the required Account numbers on Merchant Application. </span>
      <br/><span>(e) </span><span>If the Account is closed or is otherwise unavailable to Servicers for ACH debit, Merchant consents to Servicers locating additional deposit accounts or assets by using any means </span>
      <span>legally available. In this event, Merchant waives all rights to their privacy in favor of Servicers until such time as all unpaid Chargebacks and fees owed to Servicers have been paid in </span>
      <span>full. </span>
      <br/>
      <strong>1.8 </strong><strong>Reserve Account. </strong>
      <br/><span>In addition to any other rights granted to Servicers under this Agreement, Merchant hereby authorizes Servicers to establish a Reserve Account, with or without prior notice to Merchant, </span>
      <span>at any time prior to, during or after termination of this Agreement, to ensure the recovery of any liabilities owed them or reasonably anticipated by Servicers in their sole discretion to </span>
      <span>be owed by Merchant pursuant to this Agreement. Servicers may also require as a condition of providing continued services, that Merchant fund and maintain a Reserve Account with </span>
      <span>Servicers as security against any costs, losses or expenses incurred by Servicers in connection with the provision of services to Merchant. Merchant’s obligation to maintain any Reserve </span>
      <span>Account shall survive the termination of this Agreement by a period of two hundred seventy (270) days (or longer depending on Merchant’s product and business practices) during </span>
      <span>which time Servicers’ right, title and interest therein shall continue. In addition Merchant further agrees: </span>
      <br/><span>(a) </span><span>Liabilities to be paid from the Reserve Account include, but are not limited to, those arising out of actual and/or potential post termination Chargebacks, as well as any and all </span>
      <span>Termination Fees, charges and expenses due or anticipated to be due Servicers from Merchant. </span>
      <br/><span>(b) </span><span>The Reserve Account shall be in such amount Servicers deem reasonable under the circumstances in their sole discretion. The Reserve Account may be funded and/or replenished </span>
      <span>by Servicers by withholding from Merchant’s Card transaction proceeds, and/or withholding or withdrawing from, or freezing all or any part of, the Account and/or any other deposit </span>
      <span>accounts maintained by Merchant wherever found by any means legally available. Unless Servicers agree otherwise in writing with Merchant, the Reserve Account shall not bear </span>
      <span>interest. </span>
      <br/><span>(c) </span><span>Servicers may enforce their right, title and interest in the Reserve Account without notice or demand being first made to Merchant. Servicers’ right to sums owed them by </span>
      <span>Merchant pursuant to this Agreement shall in no way be limited by the balance or existence of the Reserve Account. Servicers’ right, title and interest with respect to the Reserve </span>
      <span>Account shall survive the termination of this Agreement. </span>
      <br/><span>(d) </span><span>Servicers may exercise their rights under this Agreement to collect any amounts due to Servicers including, without limitation, rights of set-off and recoupment. Merchant shall </span>
      <span>have no right to withdraw funds or debit the Reserve Account. </span>
      <br/><span>(e) </span><span>It is stipulated and agreed that the funds placed in the Reserve Account are trust fund monies legally and exclusively held for the benefit of Servicers. In the event of Bankruptcy </span>
      <span>proceedings, Servicers may exercise their rights under this Agreement to debit the Reserve Account for amounts due Servicers regardless of the pre-petition or post-petition nature of </span>
      <span>the amount due Servicers. In the event of a Bankruptcy proceeding, Merchant also agrees that it will not contest any motion for relief from the automatic stay which Servicers may file </span>
      <span>to debit the Reserve Account. </span>
      <br/><span>(f) </span><span>Servicers may retain funds in the Reserve Account for as long as Merchant may be liable to make payments under this Agreement. Funds are typically retained in the Reserve </span>

      <span>Account for a minimum of two hundred seventy (270) days from the date of the oldest Card transaction in question or the date of termination, whichever is longer, and may be retained </span>
      <span>longer depending on the nature of Merchant’s Card transaction activity. Servicers will have sole control of the Reserve Account. In the event of a Bankruptcy proceeding Servicers do </span>
      <span>not consent to the assumption of this Agreement. Nevertheless, if this Agreement is assumed, Merchant agrees that in order to establish assurance of future performance within the </span>
      <span>meaning of 11 U.S.C. Sec 365, as amended from time to time, Merchant must establish a Reserve Account in an amount satisfactory to Servicers. </span>
      <br/><span>(g) </span><span>In addition to any other rights granted to Servicers under this Section 1.8, Merchant understands that a separate Reserve Account is required for the use of Agent’s ACH Services </span>
      <span>and authorizes Servicers to establish, using Merchant funds, a non-interest-bearing ACH Reserve Account held by the Member processing Merchant-originated ACH Entries to offset </span>
      <span>returned or disputed ACH items or other liabilities under this Agreement. The initial reserve shall be in such amount Agent deems reasonable under the circumstances in its sole </span>
      <span>discretion, but no less than ten percent (10%) of Merchants approved ACH Exposure Limit. Agent shall maintain the right to review and adjust the ACH reserve requirement based </span>
      <span>upon the Merchant’s actual performance. If the amount of returned ACH items exceeds the balance in the reserve account, Merchant authorizes Servicers to fund the excess via the </span>
      <span>Merchant’s operating account at its own DFI. Changes in reserve fund requirements will be determined by Agent according to Merchant's financial condition, Transaction volumes, </span>
      <span>Transaction amounts and ACH Return ratios; any such changes shall be effective immediately. Notwithstanding the ACH reserve fund, Merchant shall be liable and reimburse Agent </span>
      <span>for any returned items as set forth in Section 7.8 of this Agreement. The ACH reserve fund shall be funded, maintained and/or replenished by Servicers withholding from remittances </span>
      <span>hereunder. Agent may hold any or all reserve funds for a period of up to two hundred seventy (270) days (or longer depending on Merchant’s product and business practices) following </span>
      <span>the termination of this Agreement by either party. After such a period, any amounts remaining in the reserve fund will be promptly returned to Merchant. </span>
      <br/><span>(h) </span><span>Where Reserve Account funds are held with Fresno First Bank, the Reserve Account is not insured by the Federal Deposit Insurance Corporation (FDIC) and is not protected by </span>
      <span>FDIC pass-through deposit insurance coverage. </span>
      <br/> <strong>1.9 </strong><strong>Recoupment. </strong>
      <br/><span>Merchant acknowledges and agrees, notwithstanding anything set forth in this Agreement to the contrary, that any and all credits provided to Merchant by Servicers for collected Sales </span>
      <span>Drafts under this Agreement are provisional and cannot be equitably finalized until the respective periods permitted for Chargebacks, returns, fees, fines, penalties and other adjustments </span>
      <span>to be assessed or implemented have all expired under this Agreement. These Chargebacks, returns, fees, fines, penalties and other adjustments are an integral part of the credit to be </span>
      <span>given to Merchant in respect of such Sales Draft. To the extent that any Chargeback, return, fee, penalty, fine or other adjustment is assessed or implemented, Servicers may exercise </span>
      <span>their right of recoupment with regard to the credit provisionally paid for the respective Sales Draft. In the absence of this remedy, Merchant acknowledges that it would be overpaid </span>
      <span>for the respective Sales Draft, and such overpayment shall be held in trust by Merchant as the legal and equitable property of the Servicers. Merchant also acknowledges that, in order </span>
      <span>for rights and obligations of the parties to be fairly and equitable administered, the various Sales Drafts presented by Merchant to Servicers shall constitute a single, integrated </span>
      <span>transaction, and not a series of separate or discrete transactions. </span>
      <br/><strong>1.10 </strong><strong>Security Interest. </strong>
      <br/><span>To secure Merchant’s and Guarantor’s respective performance under this Agreement, including without limitation Merchant’s obligations arising out of Chargebacks or returns, </span>
      <span>Merchant and Guarantor each hereby grants to Servicers, pursuant to the Uniform Commercial Code of the State of Michigan, as amended from time to time, a security interest in all </span>
      <span>of Merchant’s and Guarantor’s personal assets and property, including but not limited to the following assets and property: (a) the electronic terminal, printer, imprinter and imprinter </span>
      <span>plate; (b) all Sales Drafts, ACH deposits, credit drafts, and in all Accounts and Reserve Accounts, regardless of source, wherever found, standing in the name of Merchant and/or </span>
      <span>Guarantor, whether established or designated and maintained pursuant to this Agreement or not; and (c) the proceeds and products of such assets and property. In the event of Merchant’s </span>
      <span>default under this Agreement, Merchant and Guarantor(s) stipulate: (i) that all personal accounts standing in their names shall be subject to this Agreement and ACH debit; and (ii) all </span>
      <span>ACH debits, whether made against the Account or Guarantor’s personal account, shall bear a commercial account code designation (CCD) for purposes of electronic collection via the </span>
      <span>ACH system, and (iii) Merchant and/or Guarantor irrevocably consent to Servicers using any means available to locate such deposit accounts until such time as all amounts due have </span>
      <span>been paid. Servicers may enforce this security interest as applicable by: </span>
      <br/><span>(a) </span><span>Making an immediate debit/charge via the ACH system to any deposit account standing in the name or names of Merchant and/or Guarantor, without notice or demand of any </span>
      <span>kind; and/or interrupting the electronic transmission of funds to any account through the Automated Clearing House (ACH) system; </span>
      <br/><span>(b) </span><span>Freezing the entire Account and/or Reserve Account, without notice or demand of any kind, upon Servicers determination that Merchant has breached any term of this Agreement; </span>
      <br/><span>(c) </span><span>Taking possession of any or all of Merchant’s or Guarantor’s personal assets or property; </span>
      <br/><span>(d) </span><span>Placing a receiver within Merchant’s place of business without notice or bond to intercept and collect all income derived from Merchant’s operations until such time as any </span>
      <span>indebtedness owed to Servicers arising under this Agreement has been satisfied in full; </span>
      <br/><span>(e) </span><span>By obtaining either a writ of attachment or a writ of possession without bond pertaining to Merchant’s and/or Guarantor’s personal assets or property. </span>
      <span>Merchant and Guarantor hereby irrevocably authorize the Servicers at any time and from time to time to file any financing statements and amendments thereto, in any jurisdiction </span>
      <span>required for the proper perfection of the Servicers’ security interest, and shall provide any statement or notice that Servicers determine to be necessary to preserve and protect this </span>
      <span>security interest. Merchant’s and/or Guarantor’s granting of this security interest in no way limits Merchant’s and Guarantor’s liabilities to Servicers under this Agreement. </span>
      <br/><strong>1.11 </strong><strong>Fiduciary Relationship. </strong><br/>
      <span>Whenever Merchant and/or Guarantor has a deposit held with Servicers that arose from or is subject to this Agreement, to which, pursuant to this Agreement, Merchant and/or Guarantor </span>
      <span>is not entitled, Merchant’s and Guarantor’s entitlement to such deposit shall be as a fiduciary of Servicers until any claim by Servicers against Merchant and Guarantor has been </span>
      <span>resolved. Merchant and Guarantor each agrees that its failure to repay, within five (5) calendar days of notification by Servicers, funds to which Merchant or Guarantor is not entitled </span>
      <span>to, shall result in a presumption that Merchant and/or Guarantor intends to misappropriate such funds. Merchant and Guarantor each further agrees that in the event Servicers seek to </span>
      <span>enforce their rights herein in a court of competent jurisdiction, that any receivership, temporary restraining order, preliminary injunction, writ of attachment or writ of possession may </span>
      <span>be issued against Merchant and/or Guarantor without bond. </span>
      <br/><strong>1.12 </strong><strong>Guarantors. </strong>
      <br/><span>As a primary inducement to Servicers to enter into this Agreement with Merchant, Guarantor, whether by signing the Merchant Application or by acknowledging consent by electronic </span>
      <span>means, jointly and severally (where more than one Guarantor), and unconditionally and irrevocably, guarantee the continuing full and faithful performance and payment by Merchant </span>
      <span>of each of its duties and obligations to Servicers pursuant to this Agreement, as it now exists or is amended from time to time, with or without notice. Guarantor understands further </span>
      <span>that Servicers may proceed directly against Guarantor without first exhausting its remedies against any other person or entity responsible therefore to it or any security held by Servicers. </span>
      <span>This guaranty will not be discharged or affected by the death of the Guarantor, will bind all heirs, administrators, representatives and assigns and may be enforced by or for the benefit </span>
      <span>of any successor of Servicers. Guarantor understand that the inducement to Servicers to enter into this Agreement is consideration for this guaranty, and that this guaranty remains in </span>
      <span>full force and effect even if the Guarantor receives no additional benefit from the guaranty. </span>
      <br/><strong>1.13 </strong><strong>Payment of Fees and Other Amounts Owed. </strong>
      <br/><span>Merchant shall pay to Servicers the fees and charges set forth on the Merchant Application, any separate schedule of fees, and the fee provisions of this Agreement, including those </span>
      <span>fees set forth in Sections 1.14 and 1.15, all of which are subject to change pursuant to the terms of this Agreement. Merchant agrees that Servicers may collect their fees, fines and </span>
      <span>other amounts owed under this Agreement by netting against the proceeds of Merchant’s processing activity. The Account will be debited through ACH for such amounts and for any </span>
      <span>other fees, charges or adjustments incurred by Merchant and associated with the services provided under this Agreement. Merchant is obligated to pay all taxes and other charges </span>
      <span>imposed by any governmental authority on the services provided under this Agreement. Servicers have the right to change fees as set forth in this Agreement. Any changes in fees </span>
      <span>under the Agreement implemented in connection with changes in a Card Network’s fees, interchange, assessments, Rules, gift card systems or any law or judicial decision will become </span>
      <span>effective at such time that Servicers may specify, which may be sooner than the beginning of the next billing cycle following the date of notice. </span>
      <br/><strong>1.14 </strong><strong>General Fees. </strong>
      <br/><span>In consideration of the services provided, Merchant will be charged, and Merchant agrees to pay, any and all fees set forth in this Agreement (for the purposes of clarity, this includes </span>
      <span>the Application and any additional pricing supplements or subsequent communications), all of which shall be calculated and payable pursuant to the terms of this Agreement and any </span>
      <span>additional pricing supplements or subsequent communications. Additional fees specific to each service offering are set forth in the Pricing Schedule section of the Merchant Application. </span>
      <span>Merchant is encouraged to review these additional rates and fees as set forth on the Merchant Application. If no per transaction fee is specified, AXP transactions will be subject to </span>
      <span>the same communications fee as VISA/Mastercard/Discover, specified on the Merchant Application. </span>
      <span>Different discount rates and other fees may apply to different types of Card transactions. Servicers will process Merchant’s Visa, Mastercard and Discover Card transactions at the </span>
      <span>Qualified Discount Rate only when Merchant transactions meet certain criteria set by the applicable Card Network and Servicers. When Card transactions fail to meet those qualification </span>
      <span>criteria, Servicers will process Merchant transactions at the higher Non-Qualified Discount Rate (or, in certain circumstances, at an intermediate Mid-Qualified Discount Rate) indicated </span>
      <span>on the Application. For example, a higher “Mid-Qualified Discount Rate” may be charged on other types of Card transactions, including without limitation, keyed cards at retail </span>
      <span>locations or keyed cards with valid AVS response and order number for “card not present” transactions; and a higher “Non-Qualified Discount Rate” may be charged on other types of </span>
      <span>Card transactions, including without limitation, Business, Corporate, or Purchase cards (collectively, “Commercial Cards”), International, or Government cards, keyed cards where </span>
      <span>AVS is not present or missing any of the required data elements, batches not closed within one calendar day of transaction, all rewards cards as defined by the Card Issuers, and any </span>
      <span>pre-authorized sale that is not processed/captured within 7 business days. </span>
      <span>If Merchant selects Interchange Plus or Qualified Incentive pricing on the Merchant Application, the Merchant will pay the actual interchange rate and the applicable discount rate </span>
      <span>appearing on the application for each transaction. </span><strong>If Merchant elects Qualified Incentive pricing on the Merchant Application, then any Card transaction failing to meet the </strong>
      <strong>qualification criteria for the Qualified Discount Rate will be assessed an additional surcharge of up to 1.49% of the Card transaction amount in addition to the actual </strong>
      <strong>interchange and discount rate identified on the Merchant Application.</strong><br/>
      <span>Certain Commercial Card transactions may benefit from lower interchange rates if certain information is passed to the Card Networks at the time of the Card transaction. Merchant </span>
      <span>may at any time opt to provide such additional information directly to the Servicers as part of the Card transaction and may recognize the entire interchange rate savings. In the event </span>
      <span>that Merchant fails to provide such additional information to the Card Networks, Merchant hereby authorizes and directs Servicers to provide such additional information to the </span>
      <span>applicable Card Networks, on Merchant’s behalf, in order to permit the qualification of such transactions for any available discounted interchange rate programs offered by the </span>
      <span>applicable Card Networks. The Servicers may provide a portion of these interchange rate reduction savings to Merchant, and Merchant can calculate such amount through a review of </span>
      <span>its monthly statement. If and when any Card Network implements merchant-level interchange incentives or other discounts relating to small businesses, industries/business sectors, or </span>
      <span>other groups, such incentives and discounts will be retained by Servicers; provided, however, that a qualifying Merchant with Interchange Plus or Qualified Incentive pricing as set </span>
      <span>forth in Merchant Application may request in writing that such incentives or discounts instead be passed through to it, and such requests will be processed by Servicers within a </span>
      <span>reasonable time frame, and implemented proactively thereafter. For additional information and disclosures, Merchant should review Card Network press releases and announcements, </span>
      <span>and/or may contact the customer service number provided on its statements and in its welcome letter. For more information on Visa’s and Mastercard’s interchange rates, please visit </span>
      <a target='_blank' href='https://www.usa.visa.com'>usa.visa.com </a><span>and </span><a
      target='_blank' href='https://www.mastercard.us'>www.mastercard.us</a><span> or contact Agent. </span>
      <span>A Merchant is billed a transaction fee each time communication is made with the host. The amount of this fee is initially assigned by the Agent or sales representative at the time </span>
      <span>Merchant submits its Merchant Application, and it is subject to change as described below. Merchant is required to annually validate PCI DSS compliance, with the initial validation </span>
      <span>required to be completed within ninety (90) days of the effective date of the Agreement. After such date, Merchant may be charged a fee (based on the equipment Merchant uses) in </span>
      <span>accordance with Agent’s PCI Plus Program, which is subject to change at Agent’s sole discretion, to offset the costs associated with maintaining compliance with PCI DSS and other </span>
      <span>governmental and regulatory requirements. Merchant may also be required to pay an early Termination Fee as fully set forth in Section 1.16 below. Furthermore, Merchant may also </span>
      <span>be assessed a fee in connection with enrollment into the Payments Hub, as fully set forth in Article VI.1. Additional charges that may occur from time to time include, but are not </span>
      <span>limited to, Chargeback fees, re-presentment fees and retrieval fees. A monthly minimum fee will be deducted unless Merchant has met its minimum processing volume. The standard </span>
      <span>delivery method for monthly statements is in an electronic, online version that will be generated each month and located on the Merchant Portal. </span>
      <strong>SERVICERS MAY MODIFY ALL FEES PAYABLE BY MERCHANT UNDER THIS AGREEMENT, INCLUDING THOSE DETAILED IN THE MERCHANT APPLICATION AND ANY SEPARATE SCHEDULE OF FEES, OR INTRODUCE NEW FEES,</strong>
      <strong> IN EACH CASE BY PROVIDING NOTICE TO MERCHANT OF CHANGES TO SUCH FEES, WHICH MAY INCLUDE BY STATEMENT MESSAGE. </strong>
      <span>If Merchant is affected by a fee increase or the introduction of new fees, other than a fee </span>
      <span>increase or new fees caused by a Card Network or other third party and passed through to Merchant, Merchant shall have 30 days from the date of its receipt of the first monthly statement </span>
      <span>reflecting such fee increase or new fee to provide written notice to Servicers of Merchant’s termination of the Merchant Agreement without incurring the early Termination Fees set </span>
      <span>forth in Section 1.16. In addition, a minimum of thirty (30) days advance notice will be provided to Merchant in the case of any fee increase or introduction of a new fee related to </span>
      <span>Mastercard and Maestro, and in such event Merchant will have the right to terminate Mastercard or Maestro acceptance under this Agreement within ninety (90) days of receiving notice </span>
      <span>of such fee increase or introduction of a new fee. Notwithstanding the foregoing, this termination right as to Mastercard acceptance will not apply to any fee increase made in accordance </span>
      <span>with a pre-determined fee schedule included in this Agreement. </span>
      <br/>
      <strong>1.15 </strong><strong>Card Network Fees. </strong><br/>
      <span>If fees are not listed on the Merchant Application or separate schedule of fees to the contrary, the following fees are applicable. These fees represent processing-related costs that are </span>
      <span>passed through to Merchant as a result of Merchant’s acceptance of VISA, Mastercard, AMEX, Discover Network and JCB Card transactions. These fees may include fees charged </span>
      <span>by the applicable Card Networks and/or a markup by Servicers, and may be adjusted from time to time to reflect increases implemented by Card Networks, Servicers or third parties </span>
      <span>in the delivery of processing-related services. Servicers also reserve the right to bundle all such fees. The names of the fees that will be charged on Merchant’s statement include those </span>
      <span>listed below. Merchant understands that every Credit Voucher issued will be subject on a daily basis to a transaction authorization, interchange, discount, network assessment or other </span>
      <span>applicable fees and there will be no refund of any such fees or charges associated with the original transaction. </span>
      <span>Visa International Card - Purchase, Visa ISA (US) - Single Currency, Visa Misuse of Authorization Fee, Visa Zero Floor Limit, Visa System File Transmission Fee, Visa Credit </span>
      <span>Voucher (Credit), Visa Credit Voucher (Debit), Visa FANF, Visa Debit Integrity Fee, Visa Zero Dollar Verification Fee, Visa NAPF Fee - Credit, Visa NAPF Fee - Debit, Visa </span>
      <span>Assessments-Debit Fee, Visa Assessments-Credit Fee, Visa International Service Assessment-Enhanced Fee, Visa International Service Assessment-Base Fee, Visa Transaction </span>
      <span>Integrity Fee, Visa Account Verification, MC Assessment, MC Acquirer Brand Volume Fee, MC Acquirer License Fee, MC Cross Border - Single Currency, MC Cross-Border Fee - </span>
      <span>Foreign, MC Global Acquirer Fee - Purchase, MC Processing Integrity Fee - Final Auth (Max), MC Processing Integrity Fee - Final Auth (Min) per Auth, MC Processing Integrity </span>
      <span>Fee - PreAuth/Undefined per Auth, MC Digital Enablement Fee, MC Location Fee, MC NABU Fee, MC AVS - Card Present, MC AVS - Card Not Present, MC Card Validation Code </span>
      <span>Fee, MC Kilobyte Access Fee, DSC Assessments, DSC International Processing Fee, DSC International Card - Purchase, DSC Network Authorization Fee, DSC Data Usage Fee, AX </span>
      <span>Assessments, AX Global Acquirer - Purchase, AX CNP, PayPal Participation Fee, PayPal Assessments Fee, Debit Switch Fee, Processor Fee - CU Account Verification, Processor </span>
      <span>Fee - JCB Account Verification, Processor Fee - Diners Account Verification, Processor Fee - DSC Account Verification, Processor Fee - CU Digital Investment Fee, Processor Fee - </span>
      <span>JCB Digital Investment Fee, Processor Fee - Diners Digital Investment Fee, Processor Fee - DSC Digital Investment Fee, Processor Fee - CU Address Verification Fee, Processor Fee </span>
      <span>- JCB Address Verification Fee, Processor Fee - Diners Address Verification Fee, Processor Fee - DSC Address Verification Fee, Processor Fee - AX Acquirer Transaction Fee, </span>
      <span>Processor Fee - MC Digital Enablement Fee (max per item), Processor Fee - MC Decline Reason Code Service Fee, Processor Fee - MC Digital Enablement Fee (min per item), </span>
      <span>Processor Fee - Visa Declines - Issuer Will Never Approve (Int'l), Processor Fee - Visa Address Verification Fee, Processor Fee - Visa Declines - Issuer Will Never Approve. </span>
      <br/><strong>1.16 </strong><strong>Term: Termination. </strong><br/>
      <span>This Agreement shall remain in full force and in effect for an initial term of three (3) years. This Agreement shall be automatically extended for successive one (1) month periods on </span>
      <span>the same terms and conditions expressed herein, or as may be amended, unless Merchant gives written notice of termination at least thirty (30) days prior to the expiration of the initial </span>
      <span>term or any extension or renewals thereof, in which case this Agreement will terminate at the end of the then-current term. </span>
      <br/><span>(a) </span><span>This Agreement shall not become effective until the Merchant Application is approved by Servicers. Any party may terminate this Agreement or one or more services delivered </span>
      <span>under this Agreement at any time with or without cause by providing written notice to the other parties and such termination will become effective on the date specified by such notice. </span>
      <span>If Merchant terminates this Agreement, Servicers shall have thirty (30) days from date of receipt of the notice to close Merchant’s Account. All rights and obligations of the parties </span>
      <span>existing hereunder as of the effective time of termination shall survive the termination of this Agreement. If Merchant has applied for Card processing and is approved by Servicers, </span>
      <span>and if Merchant exercises its option to terminate this Agreement within three (3) years after such approval, then Merchant will pay to Servicers a Termination Fee as follows: all </span>
      <span>monthly fees assessed to Merchant under the Agreement and due to Servicers for the remainder of the then existing term of the Agreement, including all minimum monthly fee </span>
      <span>commitments, shall be immediately due and payable to Servicers (the “Termination Fee”); provided, however, that in no event shall the Termination Fee be less than $295; and, </span>
      <span>provided further, that in no event shall the Termination Fee exceed the maximum amount permitted by applicable state law. Merchant hereby authorizes Servicers to deduct the </span>
      <span>Termination Fee from Merchant’s account referenced in Section 1. 7, or to otherwise withhold the total amount from amounts due to Merchant from Servicers, immediately on or after </span>
      <span>the effective date of termination. For purposes of calculating the Termination Fee, Servicers reserve the right, in their discretion, to calculate such remaining fees based upon the </span>
      <span>anticipated annual volume and average transaction levels contemplated by either (i) the Merchant’s actual volume and transaction levels during the period in which it actively processed </span>
      <span>with Servicers, or (ii) published industry volume and transaction levels associated with the Merchant’s MCC, as selected in Servicers’ sole discretion. If the Merchant’s account does </span>
      <span>not contain sufficient funds for the debit or the amount cannot be withheld by Servicers from amounts due to Merchant, Merchant shall pay Servicers the amount due within ten (10) </span>
      <span>days of the date of servicers’ invoice for same. The payment of accelerated monthly fees as described here is not a penalty, but rather is hereby agreed by the parties to be a reasonable </span>
      <span>amount of liquidated damages to compensate Servicers for its termination expenses and all other damages under the circumstances in which such amounts would be payable. </span>
      <br/><span>(b) </span><span>Servicers may terminate this Agreement for any reason immediately without prior notice, including, without limitation, if (i) they have reason to believe that fraudulent Card </span>
      <span>transactions or other activities prohibited by this Agreement are occurring at any Merchant location, (ii) such action is taken to prevent loss to Servicers or Card Issuers, (iii) Merchant </span>
      <span>appears on any Card Network’s security reporting, including the Member Alert to Control High-Risk Merchants (“MATCH”), (iv) Servicers’ merchant acceptance criteria changes, </span>
      <span>(v) Merchant breaches this Agreement, (vi) Merchant violates the rules and regulations of a Card Network, (vi) Merchant engages in conduct that creates harm to or loss of goodwill </span>
      <span>to any Card Network, or (vii) Merchant fails over a consecutive one hundred eighty (180) day period to process Card transactions at or above the anticipated annualized volume and/or </span>
      <span>average transaction levels as contemplated by published industry volume and transaction levels associated with the Merchant’s MCC. All rights and obligations of the parties existing </span>
      <span>hereunder as of the effective time of termination shall survive the termination of this Agreement. In the event that the Agreement is terminated under any of subparts (i) - (iii), or (v) - </span>
      <span>(vii), of this Subsection (b), then Servicers shall have the right to calculate and assess the Termination Fee set forth in Section 1.16(a) above. In addition, an administrative account </span>
      <span>closure fee of $1,000.00 will be charged if Merchant if terminated under any of subparts (i) - (iii), or (v) - (vi), of this Section 1.16(b). All rights and obligations of the parties existing </span>
      <span>hereunder as of the effective time of termination shall survive the termination of this Agreement. </span>
      <br/><span>(c) </span><span>Merchant will be assessed a software recovery fee if it: (i) received a free promotional copy of any third party software (such as QuickBooks or Quicken) upon entering in this </span>
      <span>Agreement, and (ii) terminated this Agreement, in its sole discretion, within two years of acceptance by Servicers. Such software recovery fee shall be determined by Servicers in their </span>
      <span>sole discretion, but will be no greater than the then current direct, retail price charged to the public to purchase such software. This software recovery fee shall be in addition to any </span>
      <span>Termination Fee set forth in this Agreement. </span>
      <br/> <span>(d) </span><span>If any case or proceeding is commenced by or against Merchant under any federal or state law dealing with insolvency, Bankruptcy, receivership or other debt relief, this </span>
      <span>Agreement shall simultaneously therewith automatically terminate, and any amounts due to Servicers under this Agreement shall become immediately due and payable, without the </span>
      <span>necessity of any notice, declaration or other act by Servicers. Notwithstanding such termination, Servicers, in their sole discretion, may determine that consent to Merchant’s subsequent </span>
      <span>assumption of this Agreement is in Servicers’ best interests. In such event, the assumption will be made under terms and conditions that are acceptable to Servicers and comply with </span>
      <span>the applicable federal or state laws governing such assumption. </span>
      <br/> <span>(e) </span><span>Merchant acknowledges and agrees that a Card Network can limit or terminate processing in its sole discretion and at any time. </span>
      <br/><span>(f) </span><span>Notwithstanding the foregoing, if within forty-five (45) days of Merchant’s execution of this Agreement Merchant provides Agent with written notice that it wishes to terminate </span>
      <span>this Agreement immediately and, if applicable, returns at Merchant’s expense any free terminals Merchant received in connection with this Agreement within thirty (30) days of the </span>
      <span>date of such notice, Merchant shall not be responsible for the payment of the Termination Fee, but shall be responsible for compliance with all other terms and conditions set forth in </span>
      <span>this Agreement, including but not limited to payment for all fees incurred prior to the termination of this Agreement. </span>
      <br/> <strong>1.17 </strong><strong>Effect of Termination. </strong><br/>
      <span>Without limiting the provisions of Section 1.54 below, all of Merchant’s obligations under this Agreement which arise or are incurred prior to the effective date of termination shall </span>
      <span>survive the expiration or termination of this Agreement. </span><br/>
      <strong>1.18 </strong><strong>Third Parties. </strong><br/>
      <span>(a) </span><span>Merchant may be using certain third-party products, services or software offered by Servicers, including through Servicers non-public websites or platforms, or provided separately </span>
      <span>by such third party to assist Merchant in processing Card transactions, including Authorizations and settlements, accounting functions, or other value added services (“Third Party </span>
      <span>Products”). Merchant acknowledges and agrees that: (i) all Third Party Products are managed and controlled by third parties; (ii) Servicers do not control, directly or indirectly, the </span>
      <span>provision of Third Party Products; (iii) any failure or impact of Third Party Products is not the responsibility of Servicers and Servicers expressly disclaim any liability from such failure </span>
      <span>or impact; (iv) in the event that a third party modifies or discontinues the Third Party Products it provides, or related support, any such modification or discontinuance will not in any </span>
      <span>way be the responsibility of Servicers and Servicers expressly disclaim any liability from such modification, discontinuance or cessation of support. Servicers do not warrant or make </span>
      <span>any representations with respect to Third Party Products. </span>
      <br/><span>(b) </span><span>Merchant is responsible for ensuring compliance with the requirements of any third party in using Third Party Products, including, without limitation, complying with any separate </span>
      <span>terms and conditions applicable specifically to the use of such Third Party Products. This includes making sure Merchant has and complies with any software updates and ensuring that </span>
      <span>such software satisfies all security standards required under the Rules (including PA DSS and PCI DSS), as set forth more fully below. Servicers have no responsibility for any Card </span>
      <span>transaction until that point in time Servicers receive data about the Card transaction. </span>
      <br/> <span>(c) </span><span>Merchant will notify Servicers prior to the use of any electronic Authorization or data capture terminal or software provided by any entity other than Servicers or its authorized </span>
      <span>designee (“Third Party Terminals”) to process Card transactions. If Merchant elects to use such Third Party Terminals, Merchant agrees that the third party provider of the terminal is </span>
      <span>Merchant’s agent and Merchant is liable for the acts and omission of its agent and the terminals for failing to comply with the Rules, this Agreement and any applicable federal and </span>
      <span>state law. </span>
      <br/><span>(d) </span><span>Servicers may provide Merchant with reference links to websites operated by third parties (“Third Party Websites”). These links are provided as a convenience only. Such Third </span>
      <span>Party Websites are not under the control of Servicers. Servicers are not responsible for the content of any Third Party Website or any link contained in a Third Party Website. Servicers </span>
      <span>do not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Websites, and the inclusion of any link is not and does not imply an </span>
      <span>affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by Servicers of any information contained in any Third Party Website. Access to any Third </span>
      <span>Party Website is at Merchant’s own risk, and Merchant acknowledges and understands that linked Third Party Websites may contain terms and privacy policies that are different from </span>
      <span>those of Servicers. Servicers are not responsible for such provisions, and expressly disclaim any liability for them. </span>
      <br/><strong>1.19 </strong><strong>Limitation of Liability. </strong><br/>
      <span>(a) </span><span>MERCHANT AGREES AND ACKNOWLEDGES THAT SERVICERS SHALL HAVE NO LIABILITY, EITHER IN TORT, CONTRACT OR IN COMBINATION </span>
      <span>THEREOF, FOR CLAIMS ARISING FROM TRANSACTIONS PROCESSED UNDER THIS AGREEMENT EXCEPT IN THE CASE OF GROSS NEGLIGENCE OR WILLFUL </span>
      <span>MISCONDUCT ON THE PART OF SERVICERS. ANY SUCH CLAIMS ARE SUBJECT TO LIMITATIONS SET FORTH BELOW AND IN NO EVENT SHALL SERVICERS </span>
      <span>BE LIABLE FOR SPECIAL, CONSEQUENTIAL, INDIRECT OR EXEMPLARY DAMAGES, INCLUDING LOST PROFITS, REVENUES OR LOST BUSINESS </span>
      <span>OPPORTUNITIES. </span>
      <br/><span>(b) </span><span>THE LIABILITY, IF ANY, OF SERVICERS, UNDER THIS AGREEMENT WHETHER TO MERCHANT OR TO ANY OTHER PARTY, WHATEVER THE BASIS OF </span>
      <span>LIABILITY, SHALL NOT EXCEED IN THE AGGREGATE THE DIFFERENCE BETWEEN (I) THE AMOUNT OF FEES PAID BY MERCHANT TO SERVICERS DURING </span>
      <span>THE MONTH IN WHICH THE TRANSACTION OUT OF WHICH THE LIABILITY AROSE OCCURRED, AND (II) ASSESSMENTS, CHARGEBACKS, RETURNS AND ANY </span>
      <span>OFFSETS AUTHORIZED UNDER THIS AGREEMENT AGAINST SUCH FEES WHICH AROSE DURING SUCH MONTH. IN THE EVENT MORE THAN ONE MONTH </span>
      <span>WAS INVOLVED, THE AGGREGATE AMOUNT OF SERVICERS LIABILITY SHALL NOT EXCEED THE LOWEST AMOUNT DETERMINED IN ACCORD WITH THE </span>
      <span>FOREGOING CALCULATION FOR ANY ONE MONTH INVOLVED. IN NO EVENT WILL THE LIABILITY OF SERVICERS UNDER THIS AGREEMENT EXCEED $50,000. </span>
      <br/> <span>(c) </span><span>EXCEPT AS EXPRESSLY PROVIDED HEREIN, SERVICERS MAKE NO OTHER WARRANTY, EXPRESS OR IMPLIED, REGARDING THE SOFTWARE AND </span>
      <span>SERVICES, AND NOTHING CONTAINED IN THIS AGREEMENT WILL CONSTITUTE SUCH A WARRANTY. SERVICERS DISCLAIM ALL IMPLIED WARRANTIES, </span>
      <span>INCLUDING THOSE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. SERVICERS FURTHER DO NOT </span>
      <span>REPRESENT OR WARRANT THAT THE SERVICES WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE, </span>
      <span>OR ENTIRELY ERROR-FREE. SERVICERS FURTHER EXPRESSLY DISCLAIM ANY LIABILITY FOR UNAUTHORIZED ACCESS OF FACILITIES OR TO MERCHANT’S </span>
      <span>DATA OR PROGRAMS DUE TO ACCIDENT, ILLEGAL OR FRAUDULENT MEANS OR DEVICES USED BY ANY THIRD PARTY, OR OTHER CAUSES BEYOND </span>
      <span>SERVICERS’ REASONABLE CONTROL. </span>
      <br/><strong>1.20 </strong><strong>Indemnification by Merchant. </strong>
      <span>Merchant agrees to indemnify and hold harmless Servicers, and their affiliates, agents, representatives, members, or stockholders (including, without limitation, the respective officers, </span>
      <span>directors, employees, attorneys of all of the foregoing), from and against any and all claims actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, </span>
      <span>fines, costs or expenses (including reasonable attorneys’ fees and other litigation expenses) arising out of or relating to: </span>
      <br/><span>(a) </span><span>any transaction that does not conform to the requirements of this Agreement, the program guide, the Rules or applicable laws; </span>
      <br/> <span>(b) </span><span>any dispute between Merchant and a Cardholder or check writer or customer, or any Sales Draft or ACH deposit paid for by Servicers; </span>
      <br/> <span>(c) </span><span>any actual or alleged action or omission by Merchant that would constitute a breach of any representation, warranty, or obligation of Merchant set forth in this Agreement; </span>
      <br/> <span>(d) </span><span>any damage or loss caused by negligence, fraud, dishonesty or willful misconduct by Merchant or any of its employees, agents or customers; </span>
      <br/>
      <span>(e) </span><span>the reliability, accuracy, or legitimacy of payment data submitted by Merchant; </span>
      <br/>
      <span>(e) </span><span>any alleged infringement of another party’s intellectual property rights by Merchant; </span>
      <br/> <span>(f) </span><span>a failure of Merchant to maintain the confidentiality of Cardholder or check writer information; </span>
      <br/> <span>(g) </span><span>any action Servicers take against the Account under this Agreement. Merchant will also indemnify and hold harmless the institution at which Merchant maintains the Account </span>
      <span>for acting in accordance with any instruction from Servicers regarding the Account; or </span>
      <br/> <span>(h) </span><span>any Chargebacks or fees, fines or penalties assessed by a Card Network with respect to transactions submitted by Merchant to Servicers. </span>
      <span>If Merchant is an agency or instrumentality of a state of the United States and is precluded by the law of Merchant’s state from entering into indemnification obligations, then the </span>
      <span>obligations under this Section 1.20 shall apply only to the extent permitted by such state law. This section will survive termination of this Agreement. </span>
      <br/><strong>1.21 </strong><strong>Confidential Information. </strong>
      <br/><span>(a) </span><span>Servicers may disclose information relating to Merchant’s sales to financial institutions, Card Network and third parties that need the information for a purpose relating to this </span>
      <span>Agreement and to the Servicers’ agent or referral source, if any that played a role in establishing Merchant’s relationship with Servicers solely for the purpose of computing payment </span>
      <span>due to such persons. Servicers may also disclose information regarding Merchant as part of an investigation by Servicers into Merchant’s compliance with its obligations under this </span>
      <span>Agreement if Servicers determine there is cause to make such an inquiry, or to the extent required or requested by a court or governmental authority, or otherwise as required by law. </span>
      <br/> <span>(b) </span><span>Except to the extent specifically permitted by the Rules, the operating rules of NACHA or this Agreement, Merchant shall not disclose any information relating to any Card </span>
      <span>transaction , or any Cardholder or check writer, to any person or entity other than Servicers, Servicers’ employees, agents and independent contractors, and those of Merchant’s </span>
      <span>employees who have a specific need to know such information except to complete the Card transaction or as otherwise required or authorized under this Agreement or by law. Merchant </span>
      <span>shall treat all documents provided by Servicers relating to this Agreement as confidential and proprietary and protect them with the same degree of care as Merchant would protect its </span>
      <span>own confidential and proprietary information, and not less than reasonable care. </span>
      <br/><span>(c) </span><span>Merchant agrees that this Agreement, and all other user information provided by Servicers, the process utilized by Servicers for providing services, and all written communications </span>
      <span>concerning services hereunder are confidential and proprietary information of Servicers. Merchant agrees that neither it nor any of its employees, agents, representatives, or independent </span>
      <span>contractors will disclose any such confidential, proprietary information to any person or entity that is not a party to this Agreement without the express written consent of Servicers. </span>
      <br/><strong>1.22 </strong><strong>Safeguarding of Information. </strong>
      <br/><span>(a) </span><span>Merchant and its agents shall be in full compliance with Rules adopted by any Card Network relating to the privacy and security of Cardholder and Card transaction data, including </span>
      <span>without limitation the Payment Application Data Security Standard (“PA DSS”) and the Payment Card Industry Data Security Standard (“PCI DSS”), as they may be amended from </span>
      <span>time to time. Information pertaining to such requirements may be found at <a
        target='_blank' href={'https://www.pcisecuritystandards.org'}>https://www.pcisecuritystandards.org</a>. PCI DSS and other industry aligned validation requirements </span>
      <span>collectively form the basis for each of the following Card Network compliance programs, with which Merchant must also comply: (i) the Cardholder Information Security Program </span>
      <span>(“CISP”) - VISA’s data security program; (ii) the Site Data Protection (“SDP”) - Mastercard’s data security program; (iii) AXP’s Data Security Operating Policy (“DSOP”); and (iv) </span>
      <span>the Discover Network Information Security and Compliance (“DISC”) - Discover Network’s data security program. Additionally, Merchant shall be responsible for demonstrating </span>
      <span>compliance by its agents for these programs. </span>
      <br/><span>(b) </span><span>Merchant shall be liable for all fines, charges and penalties that may be assessed by any Card Network as a result of transactions made by Merchant or Merchant’s noncompliance </span>
      <span>with the preceding requirements. Merchant also acknowledges that it may be prohibited from participating in Card Network programs if it is determined that Merchant is non-compliant. </span>
      <span>Merchant acknowledges that it may be subject to, and Servicers retain the right, to conduct or cause to be conducted an audit to verify Merchant’s compliance with the foregoing </span>
      <span>security requirements. Merchant must notify Servicers within twenty-four (24) hours after becoming aware of (i) any suspected or actual data security breach or (ii) any noncompliance </span>
      <span>by Merchant with the security requirements set forth herein. In such event, if requested by Servicers or Card Network, Merchant shall, at its own expense, (a) perform or cause to be </span>
      <span>performed an independent investigation of any data security breach of Card or Card transaction data by an authorized assessor acceptable to Servicers, (b) take all such remedial actions </span>
      <span>recommended by such investigation, by Servicers or by VISA, Mastercard, AXP or Discover, and (c) cooperate with Servicers in the investigation and resolution of any security breach. </span>
      <br/><span>(c) </span><span>Merchant will not, under any circumstances, disclose any Cardholder’s account number nor any information relating to any Cardholder’s account number or any Sales Drafts or </span>
      <span>Credit Vouchers which may have been imprinted with any Card to any person other than Servicers, or as required by law. Merchant agrees not to store, distribute, copy or otherwise </span>
      <span>manipulate card account numbers or PINs that appear, are encoded or are otherwise associated with Cards. All electronic commerce Merchants must provide Cardholders with a secure </span>
      <span>transaction method, such as Secure Sockets Layer (SSL) or 3-D Secure. Further, Merchant agrees to store (to the extent such storage is permitted) any and all material containing </span>
      <span>Cardholder account numbers, imprints or information in a secure manner, in an area limited to selected personnel, and to destroy such numbers, imprints, and information before </span>
      <span>discarding in a fashion that renders the data unreadable and unrecoverable. Neither Merchant nor any of its agents shall retain or store the full contents of any track on the Magnetic- </span>
      <span>Stripe, or equivalent data on the Contactless Payment chip, subsequent to Authorization of a Card transaction. </span>
      <br/><span>(d) </span><span>Merchant must notify Servicers of any third party agent of Merchant that will have any access to Cardholder data. </span>
      <br/> <span>(e) </span><span>Merchant understands and agrees that due to requirements of law, Card receipts may not contain (i) more than the last five digits of the credit card account number; and (ii) that </span>
      <span>the Card receipt may not contain the expiration date. </span>
      <br/><span>(f) </span><span>If Merchant sells goods or services on the Internet, Merchant’s web site must contain Merchant’s consumer privacy policy and a description of Merchant’s method of safeguarding </span>
      <span>consumer transaction data. </span>
      <br/><span>(g) </span><span>Merchant must fully cooperate with Servicers and Card Networks if Merchant is undergoing a forensic investigation at any time with regard to the Account. </span>
      <br/><strong>1.23 </strong><strong>Account Access Password. </strong> <br/>
      <span>(a) </span><span>If Merchant receives a user identification name or password from Servicers to access Servicers’ database or use services offered by Servicers, Merchant will: (i) keep the user </span>
      <span>identification name and password confidential; (ii) not allow any other entity or person to use the user identification name or password or gain access to Servicers’ database; (iii) be </span>
      <span>liable for all action taken by any user of the user identification name or password; and (iv) promptly notify Servicers if Merchant believes the user identification name or password </span>
      <span>have been used inappropriately or the confidentiality of the information made available through their use has been compromised. </span>
      <br/><span>(b) </span><span>Merchant agrees that any loss incurred as a result of any party gaining access to Account or Servicers’ website using information which that party was not authorized to obtain or </span>
      <span>using such information in a manner not permitted by this Agreement (including but not limited to improper or unauthorized use of Merchant’s ID number and PIN) shall be the </span>
      <span>responsibility of Merchant. </span>
      <br/> <strong>1.24 </strong><strong>Privacy and Data Use. </strong>
      <br/><span>(a) </span><span>Agent will have access to the data associated with Merchant’s use of the service. Agent will handle this information in accordance with its privacy policy. Agent or Processing </span>
      <span>Partner may use transaction data associated with Merchant’s use of the service as a part of performing payment processing or other transaction related services for Merchant (“Merchant </span>
      <span>Transaction Data”), for fraud, suspected fraud, or related investigations. Agent or Processing Partner may also use Transaction Data for the purposes of providing additional or enhanced </span>
      <span>products and services to Merchant, other merchants, or third-parties. This includes collecting, using, and de-identifying cardholder information, dates, amounts, and other Merchant </span>
      <span>Transaction Data to provide Merchant with analytic products and services as well as collecting and using Merchant Transaction Data in aggregated and anonymized form with other </span>
      <span>merchants’ transaction data for research and development or to provide Merchant, other merchants, and/or third-parties with products and services. See our Privacy Policy for how we </span>
      <span>treat your data at </span><a target='_blank'
                                         href='https://www.north.com/privacy'>www.north.com/privacy</a><span>. </span>
      <br/><span>(b) </span><span>Merchants who are residents of the State of California may have additional individual rights with respect to their own personal information under California privacy law. Please </span>
      <span>email </span><a href='mailto:privacy@nabancard.com'>privacy@nabancard.com </a><span>for more information regarding your California privacy rights. </span>
      <br/><strong>1.25 </strong><strong>Feedback. </strong><br/>
      <span>Servicers may provide Merchant with a mechanism to provide feedback, suggestions and ideas about Servicers products and services (“Feedback”). Merchant agrees that Servicers </span>
      <span>may, in their sole discretion, use the Feedback in any way, including in future modifications of the products and services and any related advertising and promotional materials. </span>
      <span>Merchant grants Servicers a perpetual, worldwide, fully transferable, non-revocable, royalty free license to use, reproduce, modify, create derivative works from, perform, distribute </span>
      <span>and display for any purpose any information Merchant provides to Servicers in the Feedback. Merchant will have no obligation to provide any Feedback to Servicers. </span>
      <br/><strong>1.26 </strong><strong>Modifications to Agreement. </strong><br/>
      <span>Any Servicer may, from time-to-time, amend any provision of this Agreement. Servicers will provide notice to Merchant of the amendment, and unless specified otherwise, the amended </span>
      <span>agreement shall become effective at the start of the first billing cycle after Servicers have provided notice. </span>
      <br/><strong>1.27 </strong><strong>Additional Services Offered by Agent. </strong><br/>
      <span>From time to time, Agent may offer to Merchant additional products and services which may or may not be related to the processing of Card transactions. In the event of such offers, </span>
      <span>Merchant shall indicate its desire to Agent to decline such offers or be deemed to have accepted the offers and be liable for payment therefore. </span>
      <br/><strong>1.28 </strong><strong>Compliance with Law. </strong><br/>
      <span>In performing its obligations under this Agreement, Merchant shall comply with all laws applicable to Merchant, Merchant’s business and any Card transactions, including without </span>
      <span>limitation to all Rules, state and federal consumer credit and consumer protection laws, as well as laws for any special services used by Merchant, such as gift card and other services, </span>
      <span>including, without limitation, the rules and regulations promulgated by the Office of Foreign Assets Control of the US Department of the Treasury (“OFAC”). Merchant further </span>
      <span>acknowledges and agrees that it will not use its merchant account and/or the services for illegal transactions, for example, those prohibited by the Unlawful Internet Gambling </span>
      <span>Enforcement Act, 31 U.S.C. Section 5361 et seq., as such may be amended from time to time, or processing and acceptance of transactions in certain jurisdictions pursuant to 31 CFR </span>
      <span>Part 500 et seq. and other laws enforced by OFAC. </span>
      <br/><strong>1.29 </strong><strong>Merchant Dispute Notification. </strong><br/>
      <span>Merchant is responsible for the timely reconciliation of all issues related to services provided under this Agreement. Merchant will promptly examine all merchant statements relating </span>
      <span>to the Account and immediately notify Servicers in writing of any errors. Merchant’s written notice must include: (i) Merchant name and Account number, (ii) the dollar amount of the </span>
      <span>asserted error, (iii) a description of the asserted error, and an explanation of why Merchant believes an error exists and the cause of it, if known. That written notice must be received </span>
      <span>by Servicers within thirty (30) days after Merchant received the periodic statement containing the asserted error. Merchant may not make any claim against Servicers for any loss or </span>
      <span>expense relating to any asserted error for sixty (60) days immediately following receipt of Merchant’s written notice. During that sixty (60) day period, Servicers will be entitled to </span>
      <span>investigate the asserted error and Merchant will not incur any cost or expense in connection with the asserted error without notifying Servicers. </span>
      <br/><strong>1.30 </strong><strong>Notices; Consent to Electronic Communications; Telephone Monitoring; Online
      Statements. </strong>
      <br/><span>(a) </span><span>By applying for services and confirming that it has read this Agreement, Merchant is confirming to Servicers that it has the means to access the Internet through its own service </span>
      <span>provider and download or print electronic communications. Merchant agrees to the receipt of electronic communications by email, delivery by E-Statement (defined below), or by the </span>
      <span>posting of such information by Servicers at one or more of Servicers’ sponsored Merchant Portals. Such communications may pertain to the services delivered by Servicers, the use of </span>
      <span>information Merchant may submit to Servicers, changes in laws or Rules impacting the service or other reasons, such as amendment of this Agreement. In addition, all notices and </span>
      <span>other communications required or permitted under this Agreement by Servicers to Merchant may also be delivered by Servicers to Merchant either by FAX, overnight carrier or first </span>
      <span>class mail, postage or other charges prepaid, addressed and transmitted as set forth below. All notices and other communications required or permitted under this Agreement by </span>
      <span>Merchant to Servicers shall be delivered by Merchant to Servicers by overnight carrier or certified mail, postage or other charges prepaid, addressed and transmitted as set forth below. </span>
      <span>Notice by FAX or e-mail shall be deemed delivered when transmitted. Notice by mail or overnight carrier shall be deemed delivered on the first (1st) business day after mailing or </span>
      <span>delivery to the carrier. Following are the addresses for the purposes of notices and other communications hereunder, which may be changed by written notice in accordance with this </span>
      <span>section: </span>
      <br/><span>i. </span><span>If to Servicers, addressed and transmitted as follows: Agent, 250 Stephenson Highway Troy, Michigan Attn: Customer Support </span>
      <br/> <span>ii. </span><span>If to Merchant, at the address provided as the billing address, or the FAX number or e-mail address and to the contact listed on the Merchant Processing Application </span>
      <br/> <span>(b) </span><span>In addition to Merchant’s consent to be contacted via the methods set forth in Section 1.56 below, from time to time the Servicers and/or their agents may monitor and record </span>
      <span>telephone calls regarding Merchant’s account to assure the quality of their service(s) and for other evidentiary reasons. Merchant agrees, in order for Servicers and/or their agents to </span>
      <span>service the account or to collect any amounts Merchant may owe, that Servicers and/or their agents may from time to time make calls and/or send e-mails and/or text messages to the </span>
      <span>Merchant, using prerecorded/artificial voice messages and/or through the use of an automatic dialing device, at any telephone number associated with Merchant’s account, including </span>
      <span>wireless telephone numbers that could result in charges to Merchant, or at any email address Merchant provide to Servicers and/or their agents. </span>
      <br/><span>(c) </span><span>If Merchant elects to receive monthly statements by accessing them online (an “E-statement”), Merchant will need to visit and register at the Merchant Portal, Agent’s secure </span>
      <span>website, to obtain a user ID and password. Merchant will be required to provide their merchant ID and other additional information to verify their identification. After completing the </span>
      <span>registration process, Merchant will be able to access their E-statement(s) by visiting the Merchant Portal and logging in with their user ID and password, which user ID and passwordcan </span>
      <span>l be reset at any time by Merchant. Merchant will typically have access to the E-statement by the second week of the following month. Merchant acknowledges and agrees that if they </span>
      <span>elect to receive E-Statements, they will not receive a monthly paper account statement by mail. However, Merchant is entitled to also receive a paper copy of monthly statements </span>
      <span>through the U.S. mail by visiting the Merchant Portal paper, and following the opt-out procedures provided through that link, or by contacting their agent or the customer service </span>
      <span>number provided on their statements and in the welcome letter; provided, however, exercising the option to opt out does not constitute a withdrawal of consent to receive monthly E- </span>
      <span>statements. If Merchant chooses to receive paper statements, Merchant may be charged a monthly fee for each statement. In order to register to receive and access to E-statements, </span>
      <span>Merchant will need (i) internet access, (ii) a currently supported version of one the following internet browsers Internet Explorer, Firefox or Safari, and (iii) a currently supported PDF </span>
      <span>viewer (such as Adobe Reader which is available for free download at <a target='_blank'
                                                                                    href='http://get.adobe.com/reader/'>http://get.adobe.com/reader/</a>). These requirements, browsers and/or PDF readers may change from time to time, </span>
      <span>and Merchant will be provided notice of any such change. Neither the Servicers nor their agents are responsible for ensuring that Merchant’s E- statements are accessible through </span>
      <span>outdated vendor products. In the event Merchant is unable to access any of the information that has been made available on the E-statement, it is Merchant’s obligation to notify its </span>
      <span>agent immediately. Without limiting any of the rights under this Agreement, neither the Servicers nor their agents are responsible for: (i) consequential or incidental damages caused </span>
      <span>by services performed by the Servicers or their agents, or Merchant’s internet services provider; (ii) damages arising from unauthorized access to E-statements; or (iii) any costs </span>
      <span>associated with updating, modifying, or terminating Merchant’s software or hardware. Servicers and its agents may change, suspend, or terminate all or any aspect of the E-statement </span>
      <span>upon notice to Merchant. </span>
      <br/> <strong>1.31 </strong><strong>Further Assurances. </strong>
      <br/><span>At any time or from time to time upon the request of Servicers, Merchant will execute and deliver such further documents and do such other acts as Servicers may reasonably request </span>
      <span>in order to effectuate fully the purposes of this Agreement. </span>
      <br/><strong>1.32 </strong><strong>Force Majeure. </strong><br/>
      <span>Any delay in or failure of performance by Servicers under this Agreement will not be considered a breach of this Agreement and will be excused to the extent caused by any occurrence </span>
      <span>beyond their reasonable control, including, but not limited to, acts of God, power outages, failures of the Internet, failures of banking institutions, or Card Networks. </span>
      <br/><strong>1.33 </strong><strong>Choice of Law, Jurisdiction, Venue. </strong><br/>
      <span>(a) </span><span>The parties acknowledge that substantial performances and transactions under this Agreement are occurring in Michigan and that Merchant’s entry into and performance of this </span>
      <span>Agreement constitutes transaction of business within the State of Michigan. Except with respect to Section 1.50 concerning arbitration, which shall be subject to the law and jurisdiction </span>
      <span>set forth in Section 1.50, this Agreement and any Disputes (as defined below) arising out of or relating to (a) this Agreement, (b) the relationships that result from this Agreement, or </span>
      <span>(c) the validity, scope, interpretation, or enforceability of this Agreement, shall be governed by Michigan law, without giving effect to Michigan’s conflicts-of-law principles. </span>
      <span>(b) </span><span>Subject to Section 1.50 below: (i) the parties hereby knowingly, intelligently, and voluntarily consent to the exclusive jurisdiction and venue for any action relating to the subject </span>
      <span>matter of this Agreement in either the courts of the State of Michigan sitting in Oakland County, Michigan or the United States District Court for the Eastern District of Michigan, </span>
      <span>sitting in Detroit, Michigan; (ii) the parties consent to the jurisdiction of such courts and agree that process may be served in the manner allowed by the laws of the State of Michigan </span>
      <span>or United States federal law: (iii) the parties hereby knowingly, voluntarily, and intelligently waive any claim or defense in any such legal action, suit or proceeding commenced in any </span>
      <span>of the above-referenced courts asserting that it is not subject personally to the jurisdiction of such court, that service upon it as hereinabove set forth is invalid, that its property is </span>
      <span>immune or exempt from attachment or execution, that the legal action, suit or proceeding is brought in an inconvenient forum, that the venue of the legal action, suit or proceeding is </span>
      <span>improper or that this Agreement, or the subject matter hereof, may not be enforced in or by such court. In the event of any incurable conflict(s) between this Section 1.33 and Section </span>
      <span>1.50, Section 1.50 shall govern with respect to such incurable conflict(s). </span>
      <br/><strong>1.34 </strong><strong>Jury Waiver. </strong><br/>
      <span>IN THE EVENT ANY CONTROVERSY OR CLAIM BETWEEN OR AMONG THE PARTIES, THEIR AGENTS, EMPLOYEES, REPRESENTATIVES, OR AFFILIATES </span>
      <span>SHALL ARISE IN ANY JUDICIAL OR LEGAL PROCEEDING, EACH PARTY HEREBY KNOWINGLY, INTELLIGENTLY AND VOLUNTARILY WAIVES ITS </span>
      <span>RESPECTIVE RIGHT TO TRIAL BY JURY OF SUCH CONTROVERSY OR CLAIM. </span>
      <br/><strong>1.35 </strong><strong>Costs. </strong><span>Subject to the provisions set forth in Section 1.50(i) and 1.50(j) (both of which control in any conflict with this Section 1.35), if any action at law or in equity is necessary </span>
      <span>to enforce or interpret the terms of this Agreement, or any part hereof, the prevailing party shall be entitled to recover its reasonable attorneys’ and experts’ fees and costs in addition </span>
      <span>to any other legal and/or equitable remedies to which it is entitled. </span>
      <br/><strong>1.36 </strong><strong>Publicity. </strong><br/>
      <span>Merchant agrees that Servicers may issue a press release or similar public announcement referencing Merchant as a customer of Servicers. Merchant also grants to Servicers a limited </span>
      <span>license to use Merchant’s and its affiliates’ names, logos, trademarks, service marks or copyrights in any advertising, promotional or instructional materials for Servicers or their </span>
      <span>affiliates’ services. </span>
      <br/><strong>1.37 </strong><strong>Intellectual Property. </strong><br/>
      <span>Servicers retain all right, title and interest in and to the services and any related technology utilized by it under or in connection with this Agreement, including but not limited to </span>
      <span>all associated intellectual property rights. No title to or ownership of any of the foregoing is granted to Merchant or any other entity or person under this Agreement. Merchant will not </span>
      <span>reverse engineer, disassemble, decompile or otherwise attempt to discover the source code or trade secrets for any of the Servicers services or related technology. </span>
      <br/><strong>1.38 </strong><strong>Taxes. </strong>
      <span>Merchant shall promptly pay when due any and all liability or expense relating to the payment of federal, state, and local taxes (other than taxes based in whole or in part upon income </span>
      <span>attributable to Servicers). Merchant represents and warrants that the taxpayer identification and/or social security number provided to Servicers is true, correct and complete. Upon </span>
      <span>request, Merchant will complete, sign and deliver to Servicers a Form W-9 to verify Merchant’s taxpayer identification and/or social security number. Merchant shall also take all other </span>
      <span>actions as may be required by Servicers in order to comply with Internal Revenue Section 6050W, and any amendments or supplements thereto. </span>
      <br/><strong>1.39 </strong><strong>Rights Cumulative. </strong>
      <span>All rights and remedies existing in this Agreement are cumulative to, and not exclusive of, any other rights or remedies available under contract or applicable law. </span>
      <br/><strong>1.40 </strong><strong>Headings. </strong>
      <span>The headings listed after each section number in this Agreement are inserted for convenience only and do not constitute a part of this Agreement and are not to be considered in </span>
      <span>connection with the interpretation or enforcement of this Agreement. </span>
      <br/><strong>1.41 </strong><strong>Waiver. </strong><br/>
      <span>Failure by Servicers to enforce one or more of the provisions of this Agreement shall not constitute a waiver of the right to enforce the same or other provision in the future. All waivers </span>
      <span>must be signed by the waiving party. </span>
      <br/><strong>1.42 </strong><strong>Entire Agreement. </strong><br/>
      <span>This Agreement, including the Merchant Application, any separate schedule of fees, the Rules, information contained in websites or electronic links referenced in this Agreement, and </span>
      <span>Exhibits to this Agreement, expresses the entire understanding of the parties with respect to its subject matter and except as provided herein. Reference to “this Agreement” also </span>
      <span>includes all documents, websites and electronic links incorporated into this Agreement by reference. </span>
      <br/><strong>1.43 </strong><strong>Severability. </strong><br/>
      <span>If any provision of this Agreement is held invalid or unenforceable by any court of final jurisdiction, it is the intent of the parties that all other provisions of this Agreement is construed </span>
      <span>to remain fully valid, enforceable and binding on the parties. </span>
      <br/><strong>1.44 </strong><strong>Assignment. </strong><br/>
      <span>(a) </span><span>This Agreement may be assigned by Servicers, but may not be assigned by Merchant, directly or by operation of law, without the prior written consent of Servicers, which may </span>
      <span>be withheld in its sole discretion. The Servicers’ right to assign this Agreement includes the ability to assign Servicers’ right to debit the Account (and other accounts). If Merchant </span>
      <span>nevertheless assigns this Agreement without such consent, this Agreement will be binding upon the assignee at the option of Servicers, but otherwise such assignment shall be null and </span>
      <span>void, and of no force and effect. </span>
      <br/><span>(b) </span><span>Upon notice to Merchant, another Visa and MasterCard member may be substituted for Member under whose sponsorship this Agreement is performed with respect to Visa and </span>
      <span>MasterCard transactions. Upon substitution, such other Visa and MasterCard member shall be responsible for all obligations required of Member for Visa and MasterCard transactions, </span>
      <span>including without limitation, full responsibility for its Card program and such other obligations as may be expressly required by applicable Rules. </span>
      <br/><span>(c) </span><span>Subject to Rules, Agent may assign or transfer this Agreement and its rights and obligations hereunder and/or may delegate Servicers duties hereunder, in whole or in part, to any </span>
      <span>third party, whether in connection with a change in sponsorship, as set forth in the preceding sentence, or otherwise, without notice to Merchant or Merchant’s consent. </span>
      <br/><span>(d) </span><span>This Agreement shall be binding upon and inure to the benefit of the parties’ respective heirs and personal representatives for an individual, and otherwise its permitted successors </span>
      <span>and assigns. If Merchant sells its business and the new owners incur Chargebacks or returns, absent notification and consent by Servicers, both the new owner and the original owner </span>
      <span>and all Guarantors will be held personally liable for all liabilities of Merchant before and after the sale of the business. Without limiting the foregoing, the assignment by Merchant of its </span>
      <span>future payment rights under this Agreement to any merchant advance company or other purchaser of such payment rights is expressly prohibited, and therefore null and void, unless </span>
      <span>approved by Servicers’ in advance and in writing. </span>
      <br/><strong>1.45 </strong><strong>Authorization of Agreement. </strong><br/>
      <span>Merchant represents and warrants that the person signing or electronically authorizing this Agreement is duly authorized to bind Merchant to all provisions of this Agreement and that </span>
      <span>such person is authorized to execute any documents and to take any action on behalf of Merchant which may be required by Servicers now or in the future. Merchant will execute a </span>
      <span>separate Entity Certification if requested to do so by Servicers. Merchant, by its signature, upon its first transmission of Card transactions, or first payment of fees acknowledges that </span>
      <span>it agrees to be bound by this Merchant Agreement as it may be modified from time to time. </span>
      <br/><strong>1.46 </strong><strong>Relationship of the Parties. </strong><br/>
      <span>The parties are independent contractors and nothing in this Agreement shall make them joint ventures, partners, employees, agents or other representatives of the other party. </span>
      <br/><strong>1.47 </strong><strong>Export Restrictions. </strong><br/>
      <span>Merchant acknowledges that any software provided to it by Servicers in connection with delivering services is subject to the U.S. Export Administration Regulations (15 CFR, Chapter </span>
      <span>VII). These laws include restrictions on destinations, end users and end use. </span>
      <br/><strong>1.48 </strong><strong>Counterparts. </strong><br/>
      <span>If copies of this Agreement or any amendments are to be physically signed, this Agreement may be executed and delivered in several counterparts and transmitted by facsimile, a copy </span>
      <span>of which will constitute an original and all of which taken together will constitute a single agreement. </span>
      <br/><strong>1.49 </strong><strong>Electronic Signature. </strong><br/>
      <span>Merchant may become a party to, and become bound by, this Agreement by completing the Merchant Application and accepting it electronically over the Internet. This is done by </span>
      <span>clicking or entering “I Agree”, by providing an electronic form of signature or otherwise by affirmatively indicating acceptance or consent where requested on an electronic version of </span>
      <span>the Merchant Application (any such method constituting an “Electronic Consent”). By providing such Electronic Consent, Merchant acknowledges that it has received and reviewed </span>
      <span>all applicable pages, terms and conditions of this Agreement, and it represents, warrants, consents and agrees as follows: </span>
      <br/><span>(a) </span><span>The electronic application process allows Merchant to sign and agree to legally binding agreements online by providing its Electronic Consent. </span>
      <br/><span>(b) </span><span>Merchant intends to use the electronic application process to provide its Electronic Consent. </span>
      <br/><span>(c) </span><span>Merchant’s Electronic Consent is legally binding, and is governed by the Electronic Signatures in Global and National Commerce Act of 2000, and/or the Uniform Electronic </span>
      <span>Transactions Act governances (or an amended version thereof) in its state of residence, and Merchant agrees to be bound by these governances. </span>
      <br/><span>(d) </span><span>The individual providing Electronic Consent on behalf of Merchant is authorized by Merchant to do so. </span>
      <br/><span>(e) </span><span>The Electronic Consent will be binding upon Merchant, and will not be construed by a court of law to have any less effect than a standard ink or paper signature. </span>
      <br/><span>(f) </span><span>The information provided on the electronic version of the Merchant Application is complete and accurate, and Servicers are authorized to verify the information on such Merchant </span>
      <span>Application and to receive and exchange information about Merchant, including the credit and financial inquiries identified in Section 1. 5 of this Agreement and confidential </span>
      <span>information under Section 1.21 above. </span>
      <br/><span>(g) </span><span>The complete and legible Merchant Application and this Agreement has been provided to Merchant, and Merchant (a) was technically capable of opening, reading, printing, </span>
      <span>downloading and/or saving all sections of the Merchant Application and this Agreement, and (b) had a reasonable opportunity to open each section of the Merchant Application and </span>
      <span>this Agreement, read it, and sign and agree to it by providing its Electronic Consent. </span>
      <br/><span>(h) </span><span>No strikeouts, interlineations, additions or modifications to the electronic version of the Merchant Application and this Agreement may be made. </span>
      <br/><span>(i) </span><span>The electronic version of the Merchant Application and this Agreement, as accepted by the Electronic Consent of Merchant, may be transmitted to or from Servicers or their </span>
      <span>designees and/or retained electronically by Servicers or their designees, which will constitute an original. </span>
      <br/><span>(j) </span><span>The electronic version of the Merchant Application and this Agreement is subject to approval by Servicers. </span>
      <br/><strong>1.50 </strong><strong>Dispute Resolution and Binding Arbitration. </strong>
      <span className='larger'>
        <strong> <span>BY ENTERING INTO THIS AGREEMENT AND ACCEPTING SERVICE FROM THE SERVICERS, MERCHANT AGREES TO RESOLVE ALL DISPUTES (EXCEPT </span>
          <span>FOR SMALL CLAIMS, DESCRIBED BELOW), THROUGH MANDATORY, FINAL, BINDING ARBITRATION, ADMINISTERED BY, AND PURSUANT TO THE </span>
          <span>RULES PRESCRIBED BY, THE AMERICAN ARBITRATION ASSOCIATION (“AAA”). MERCHANT IRREVOCABLY AND EXPRESSLY WAIVES ANY RIGHT (1) </span>
          <span>TO TRIAL BY JURY, AND (2) TO PARTICIPATE IN CLASS PROCEEDINGS (AS SET FORTH IN SECTION 1.51 BELOW). </span></strong>
      </span><br/>
      <span>(a) </span><strong>Definitions</strong><span>. As used in this Section 1.50, the term “Dispute” means any dispute, claim, or controversy between Merchant and Servicers, or any Servicer, arising out of or relating </span>
      <span>to this Agreement that has arisen or that may arise in the future, whether based in contract, statute, regulation, ordinance, tort (including, but not limited to, fraud, misrepresentation, </span>
      <span>fraudulent inducement, negligence, or any other intentional tort), or any other legal or equitable theory. The term “Dispute” also includes any dispute regarding whether a particular </span>
      <span>controversy is subject to arbitration, including any claim as to the enforceability of this Section 1.50. “Dispute” is to be given the broadest possible meaning that will be enforced. As </span>
      <span>used in this Section 1.50, Servicers and Merchants includes each party’s officers, directors, employees, members, affiliates, agents, successors, and assigns, and any other party that </span>
      <span>Merchant may contend is jointly or severally liable with any such persons or entities, as well as any other party set forth in the Recitals and Definition. </span>
      <br/><span>(b) </span><strong>Informal Dispute Resolution</strong><span>. Most Disputes can be resolved quickly and informally. If Merchant has a Dispute, Merchant can contact us at 1-855-733-7264 or write us at 250 </span>
      <span>Stephenson Highway, Troy, Michigan 48083. In the event that the Dispute is not resolved to satisfaction (or if Servicers have not been able to resolve a Dispute with Merchant </span>
      <span>informally), we each agree to resolve all Disputes through binding arbitration administered by the AAA or, if it is a claim for $6,500 or less, either in the proper Small Claims court for </span>
      <span>Oakland County, Michigan or in expedited arbitration, as provided in more detail below. Any arbitration or Small Claims court action will be on an individual basis only; class </span>
      <span>arbitrations, class actions, and any representative litigation of any kind are not permitted. </span>
      <br/><span>(c) </span><strong>Notice of Dispute</strong><span>. A party who intends to seek arbitration (whether they have tried to resolve the Dispute informally or not) must first send to the other, by certified mail, a </span>
      <span>written notice of dispute (“Notice”). For any Disputes by a Merchant against Servicers, the Claim Notice shall be sent to: Claims Administrator, 250 Stephenson Highway, Troy, </span>
      <span>Michigan 48083. Servicers will contact Merchant in any manner approved in Section 1.30 above. The Notice must (a) describe the nature and basis of the claim or Dispute; and (b) set </span>
      <span>forth the specific relief sought (“Demand”). If Merchant and Servicers do not reach an agreement to resolve the Dispute within 30 days after the Notice is received, Merchant or </span>
      <span>Servicers may commence an arbitration proceeding, as provided below. </span>
      <br/><span>(d) </span><strong>Commencement of Arbitration</strong><span>. Arbitration may be initiated by filing a Claim Form with the AAA and by sending a copy of the Claim Form to the other party. For any </span>
      <span>arbitration by a Merchant against Servicers, the Claim Notice shall be sent to: Claims Administrator, 250 Stephenson Highway, Troy, Michigan 48083. Servicers will contact Merchant </span>
      <span>in any manner approved in Section 1.30 above. The Claim Form is available on the AAA website (</span><a
      target='_blank' href={'https://www.adr.org'}>www.adr.org</a><span>). </span>
      <br/><span>(e) </span><strong>Applicable Law</strong><span>. This agreement evidences a transaction in interstate commerce, and thus the Federal Arbitration Act, not state arbitration law, will govern the interpretation and </span>
      <span>enforcement of this Section 1.50 as well as the arbitrability of all Disputes. However, applicable federal law or Michigan law shall apply to and govern the substance of any Disputes. </span>
      <span>Any state statutes pertaining to arbitration shall not be applicable under this Section 1.50. </span>
      <br/><span>(f) </span><strong>Arbitration Proceedings</strong><span>. Arbitration shall be administered by AAA in accordance with its Commercial Arbitration Rules, and any applicable supplementary AAA rules, in </span>
      <span>effect when an arbitration is initiated pursuant to this Arbitration proceeding, as modified by this Agreement. A single, neutral arbitrator selected pursuant to the AAA Commercial </span>
      <span>Arbitration Rules, and any applicable supplementary AAA rules, shall conduct and resolve all Disputes. If there is a conflict between this Section 1.50 and the applicable AAA rules, , </span>
      <span>and any applicable supplementary AAA rules, this Section 1.50 shall govern. Merchant should know that participating in arbitration may result in limited discovery. The arbitrator will </span>
      <span>honor claims of privilege recognized by law and will take reasonable steps to protect customer account information and other confidential or proprietary information. The arbitrator </span>
      <span>will make any award in writing but need not provide a statement of reasons unless requested by a party. The arbitrator shall have no authority to award punitive, consequential, or </span>
      <span>other monetary damages not measured by the prevailing party’s actual damages, except as may be required by statute or as otherwise provided below. Judgment on an award rendered </span>
      <span>by the arbitrator may be entered in any court having jurisdiction for purposes of enforcement. </span>
      <br/><span>(g) </span><strong>Location of Arbitration</strong><span>. If the amount of the arbitration claim is $75,000 or less, Merchant may choose whether the arbitration takes place in person, by telephone, video </span>
      <span>conference or other remote means, or on written submissions (“Remote Proceedings”). If the amount of the claim is more than $75,000, the type of hearing shall be determined by the </span>
      <span>agreement of the parties or, in the absence of an agreement, by AAA rules. The arbitration locale for any in-person proceedings shall be Oakland County, Michigan, except that the </span>
      <span>arbitrator, upon appointment and upon a determination that (1) applicable law requires a different locale, or (2) arbitration in Oakland County, Michigan would be a substantial hardship </span>
      <span>on Merchant, may require that the arbitration proceed via Remote Proceeding. All required preliminary hearings shall be conducted via Remote Proceedings. </span>
      <br/><span>(h) </span><strong>Expedited and Complex Commercial Claims; Failure to Appear or
      Participate. </strong><span>Disputes (1) of less than $1,000,000, or (2) where the respondent fails to appear, cooperate, </span>
      <span>or participate, shall proceed pursuant to the Expedited Procedures provisions of AAA’s Commercial Arbitration Rules. In cases, (1) where the main claim(s) stem from allegations of </span>
      <span>breach of contract, or (2) where a party fails to appear, cooperate, or participate, the arbitrator shall forego a preliminary hearing and may issue an appropriate scheduling order if </span>
      <span>necessary. The Parties agree that the threshold to invoke procedures for Large, Complex Commercial Disputes under the AAA Rules shall be $1,000,000, except that any such arbitration </span>
      <span>shall be conducted by a single, neutral arbitrator selected pursuant to the AAA Commercial Arbitration Rules. </span>
      <br/><span>(i) </span><strong>Payment of Arbitration Fees, Costs, and Attorney’s Fees</strong><span>. Subject to Section 1.50(j) below, the party demanding arbitration shall be responsible for paying all filing fees </span>
      <span>required by AAA. The parties shall share the cost of the arbitrator, but, except as otherwise provided in this Section 1.50, all other costs including attorneys’ fees, shall be paid by the </span>
      <span>party that incurred them. Notwithstanding anything in this Section 1.50 to the contrary, the arbitrator shall include in the award all reasonable (1) costs, including the filing fee, and (2) </span>
      <span>attorneys’ fees, incurred by the prevailing party. </span>
      <br/><span>(j) </span><strong>Resolution of Small Disputes</strong><span>. For Disputes of $6,500.00 (or the maximum amount permitted by the small claims court) or less that are initiated by Merchant (“Small Disputes”), </span>
      <span>the following rules shall apply notwithstanding anything to the contrary in the procedures or rules of the AAA and/or in this Section 1.50: </span>
      <br/><span>i. </span><strong>Merchant may elect to seek resolution of Small Disputes in either the appropriate
      Small Claims court for Oakland County, Michigan or in expedited arbitration, as provided and </strong>
      <span>limited below. </span>
      <br/><span>ii. </span><span>If Merchant elects to seek resolution of a Small Dispute in the Small Claims court for Oakland County, Michigan, then Merchant agrees that any appeal from the Small Claims </span>
      <span>court shall be by binding arbitration pursuant to the provisions of this Section 1.50. Any such appeal shall be commenced by giving the Notice described in this Section 1.50. </span>
      <br/><span>iii. </span><span>If Merchant elects to seek expedited arbitration, that arbitration shall be conducted in accordance with the AAA’s Expedited Procedures and other applicable terms of this Section </span>
      <span>1.50. For such expedited arbitrations, Servicers will reimburse the AAA filing and administrative fees set forth in the AAA Commercial Rules, unless the arbitrator finds that either the </span>
      <span>substance of Merchant’s claim or the relief sought was frivolous or was brought for an improper purpose (as measured by standards set forth in Federal Rule of Civil Procedure 11(b)). </span>
      <span>All other costs, including attorney’s fees, incurred in relation to this Section (Resolution of Small Disputes) shall be paid by the party that incurred them. Merchant may choose to </span>
      <span>conduct such an arbitration in the state of its principal address. </span>
      <br/><span>(k) </span><strong>Binding Settlement Offers</strong><span>. After receiving a Notice as described in this Section 1.50, either party may make a written settlement offer to the other party in the Dispute to resolve </span>
      <span>the Dispute pursuant to this paragraph. Such a settlement offer must be sent to the other party by certified mail or trackable overnight delivery and, if made by a Merchant to Servicers, </span>
      <span>the offer shall be sent to the Attention of the Claims Administrator: 250 Stephenson Highway, Troy, Michigan 48083. The form of the settlement offer must expressly reference this </span>
      <span>paragraph and state with particularity the amount proposed to settle the Dispute. If a party claiming damages from another party makes an offer of settlement which is rejected by that </span>
      <span>other party and the offering party recovers a final arbitration award (or, if applicable, Small Claims court award) in an amount greater than 125 percent of such settlement offer, the </span>
      <span>offering party shall be entitled to recover reasonable attorney's fees and expenses related to prosecution of the Dispute incurred by the offering party from the date of the rejection of </span>
      <span>the settlement offer through the date of the award. If a party against whom damages is sought makes an offer of settlement that is rejected by the party claiming damages, the offering </span>
      <span>party shall be entitled to recover reasonable attorney's fees and expenses related to defense of the Dispute incurred by the offering party from the date of the rejection of the settlement </span>
      <span>offer through the date of the award if the final arbitration award (or, if applicable, Small Claims court award) is one of no liability or the final award obtained by the other party is less </span>
      <span>than 75 percent of such offer of settlement. Any settlement offer made pursuant to this paragraph shall be deemed as rejected if it is not accepted in writing by the party receiving the </span>
      <span>offer within 21 days of its being made. No offer may be made pursuant to this paragraph within 21 days of a final hearing on a Dispute. Evidence of an unaccepted offer is not admissible </span>
      <span>except in a proceeding to determine fees and expenses. Any Dispute concerning an award of attorney’s fees and expenses pursuant to this paragraph shall be conducted in accordance </span>
      <span>with the AAA’s Expedited Procedures. </span>
      <br/><span>(l) </span><strong>Non-Prohibited Communications</strong><span>. Nothing in this Section 1.50 is intended to or shall limit Merchant’s ability to respond to a lawful subpoena; report to or cooperate with any </span>
      <span>government agency; or comply with any other legal obligation. </span>
      <br/><span>(m) </span><strong>Claims Related to AXP</strong><span>. Notwithstanding the foregoing, Merchant hereby agrees that claims applicable to AXP may be resolved through arbitration as further described in the </span>
      <span>AXP Merchant Requirements Guide (the “AXP Guide”) attached as an appendix to the AXP OptBlue Program Operating Regulations. Claims against any Servicer shall be resolved </span>
      <span>as set forth in this Section 1.50. </span>
      <br/><span>(n) </span><strong>Continuation and Survival</strong><span>. This Section 1.50 shall survive and remain effective even if the Agreement has been terminated. </span>
      <br/><strong>1.51 </strong><strong>Waiver of Class Actions. </strong>
      <span className='larger'>
        <strong>
          <span>MERCHANT EXPRESSLY WAIVES THE RIGHT TO BRING A CLASS ACTION CLAIM AND AGREES NOT TO PURSUE ARBITRATION OR LITIGATION ON </span>
          <span>A CLASS-WIDE BASIS OR TO PURSUE ANY CLAIMS IN ANY FORUM ON A REPRESENTATIVE BASIS OR ON BEHALF OF AN ALLEGED CLASS. ANY PARTY </span>
          <span>TO ARBITRATION OR A LAWSUIT MUST BE INDIVIDUALLY NAMED. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE </span>
          <span>ARBITRATED OR LITIGATED IN A CLASS ACTION OR ON BASES INVOLVING CLAIMS BROUGHT IN A REPRESENTATIVE CAPACITY ON BEHALF OF </span>
          <span>THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER RECIPIENTS OF SERVICES FROM ANY OF THE SERVICERS, OR OTHER </span>
          <span>PERSONS SIMILARLY SITUATED. </span>
        </strong>
      </span><br/>
      <strong>1.52 </strong><strong>Non-Disparagement. </strong> <br/>
      <span>Merchant and Guarantor(s) each agree not to disparage either or both of Servicers or their respective vendors, and promise to refrain from engaging, directly or indirectly, in any action, </span>
      <span>communication or conduct negligently, recklessly or intentionally undertaken to damage the name or reputation of either or both of Servicers or their respective vendors. </span>
      <br/><strong>1.53 </strong><strong>General. </strong><br/>
      <span>Merchant is responsible for its employees’ actions while in its employ. Except as otherwise expressly provided in this Agreement, the parties do not intend to confer any benefits on </span>
      <span>any person or entity other than Merchant and Servicers. Merchant is also responsible for procuring, maintaining and servicing all equipment and software necessary to allow it to </span>
      <span>engage with Servicers systems to process Card transactions through Servicers, as well as for all related Internet, telecommunication, mobile phone (including sms charges and mobile </span>
      <span>data plan fees) and other similar fees. </span>
      <br/><strong>1.54 </strong><strong>Survival. </strong><br/>
      <span>All Sections of this Agreement that by their nature should survive termination or expiration will survive, including, without limitation, accrued rights to payment, indemnification </span>
      <span>obligations, confidentiality obligations, warranty disclaimers, and limitations of liability. </span>
      <br/><strong>1.55 </strong><strong>Setoff. </strong><br/>
      <span>Without limiting any statutory, common-law, or other right of setoff or similar right, (i) Agent may set off any amount that Agent owes to Merchant, or any affiliate of Merchant, (each </span>
      <span>of Merchant and any such affiliate being a “Merchant Entity”) or that Agent holds for, or for benefit of, a Merchant Entity, any amount that Merchant owes to Agent and (ii) any </span>
      <span>affiliate of Agent (each an “Agent Affiliate”) may set off from any amounts(s) owed by the Agent Affiliate to a Merchant Entity, or that the Agent Affiliate holds for, or for benefit of, </span>
      <span>a Merchant Entity, any amount(s) that Merchant owes to Agent under this Agreement. Where assignment of an account would be required under applicable law to permit any setoff </span>
      <span>contemplated above, Merchant, for itself and each Merchant Entity stipulates to each such assignment. </span>
      <br/><strong>1.56 </strong><strong>Consent to be Contacted. </strong><br/>
      <span>MERCHANT HEREBY AUTHORIZES AGENT TO SEND, AND MERCHANT CONSENTS TO THE RECEIPT OF,”SMS” (I.E., “TEXT”) ALERTS, FOR THE FOLLOWING </span>
      <span>DESIGNATED PURPOSES: (I) TO NOTIFY MERCHANT WHEN ITS MERCHANT APPLICATION IS APPROVED OR ACTIVATED, OR WHEN ITS EQUIPMENT IS </span>
      <span>SHIPPED OR REPAIRED; (II) TO NOTIFY MERCHANT THAT AGENT NEEDS ADDITIONAL INFORMATION TO COMPLETE THE MERCHANT APPLICATION, TO </span>
      <span>SECURE APPROVAL, OR TO AUTHENTICATE THE MERCHANT APPLICATION OR MERCHANT; (III) TO NOTIFY MERCHANT OF NEW PRODUCTS OR FEATURES </span>
      <span>OFFERED BY AGENT; (IV) TO NOTIFY MERCHANT THAT AGENT IS TRYING TO REACH IT; (V) TO NOTIFY MERCHANT OF CUSTOMER SERVICE ISSUES; (VI) </span>
      <span>TO NOTIFY MERCHANT OF SERVICE OUTAGES; (VII) TO PROVIDE MERCHANT WITH INFORMATION REGARDING ACCOUNT BALANCES, CARD </span>
      <span>TRANSACTIONS, CHARGEBACKS, RESERVES, AND SIMILAR MATTERS; AND (VIII) TO NOTIFY MERCHANT OF ANY MATTERS RELATING TO ITS MERCHANT </span>
      <span>ACCOUNT. MERCHANT UNDERSTANDS THAT IT WILL RECEIVE FUTURE SMS ALERTS FOR THE AFOREMENTIONED PURPOSES BY OR ON BEHALF OF AGENT </span>
      <span>TO THE WIRELESS PHONE NUMBER DESIGNATED IN THE MERCHANT APPLICATION (AS WELL AS ANY WIRELESS PHONE NUMBER MERCHANT ASSOCIATES </span>
      <span>WITH THE ACCOUNT) AND, HAVING RECEIVED AND REVIEWED THESE DISCLOSURES, MERCHANT UNAMBIGUOUSLY AUTHORIZES AGENT TO DELIVER </span>
      <span>OR CAUSE TO BE DELIVERED, AND AGREES TO RECEIVE, VOICE AND SMS ALERTS USING AN AUTOMATIC TELEPHONE DIALING SYSTEM OR AN ARTIFICIAL </span>
      <span>OR PRERECORDED VOICE. MERCHANT ACKNOWLEDGES THAT THE WIRELESS PHONE NUMBER PROVIDED IS IN THE NAME OF AND CONTROLLED BY </span>
      <span>MERCHANT, THAT MERCHANT’S CONSENT IS VOLUNTARY AND NOT REQUIRED (DIRECTLY OR INDIRECTLY) AS A CONDITION TO ITS RECEIVING </span>
      <span>PRODUCTS OR SERVICES FROM AGENT OR ITS AFFILIATED COMPANIES IN ACCORDANCE WITH THIS MERCHANT AGREEMENT, AND THAT MERCHANT </span>
      <span>HAS THE RIGHT TO WITHDRAW THIS CONSENT AT ANY TIME BY PROVIDING AGENT WITH WRITTEN NOTICE OF SUCH WITHDRAWAL IN ACCORDANCE </span>
      <span>WITH SECTION 1.30 ABOVE. THIS CONSENT IS BEING PROVIDED ELECTRONICALLY IN ACCORDANCE WITH SECTION 1.49 ABOVE. </span><br/><br/>
      <div style={{textAlign: 'center'}}><strong>II. </strong><strong>CARD TRANSACTION
        SERVICES/RESTRICTIONS. </strong></div>
      <br/>
      <span>The following terms and conditions govern Card services provided by Servicers to Merchant. </span>
      <br/>
      <span><strong>2.1</strong><strong> Advertising. </strong></span><br/>
      <span>(a) </span><span>Merchant will prominently display the promotional materials provided by Servicers in its place(s) of business. Use of promotional materials and use of any trade name, trademark, </span>
      <span>service mark or logo type (“Marks”) associated with Card(s) shall be limited to informing the public that Card(s) will be accepted at Merchant’s place(s) of business. Merchant’s use </span>
      <span>of promotional materials and Marks is subject to the direction of Servicers. </span>
      <br/><span>(b) </span><span>Merchant may use promotional materials and Marks during the term of this Agreement and shall immediately cease their use and return any inventory to Servicers upon termination </span>
      <span>of this Agreement. </span>
      <br/> <span>(c) </span><span>Merchant shall not use any promotional materials or Marks associated with VISA, Mastercard, AXP, Discover Network or JCB in any way which implies that VISA, Mastercard, </span>
      <span>AXP, Discover Network or JCB endorses any goods or services other than Card services, or otherwise in violation of the Rules. </span>
      <br/><strong>2.2 </strong><strong>Warranties of Merchant Regarding Card Transactions. </strong><br/>
      <span>Merchant hereby represents and warrants to Servicers at the time of execution and during the term of this Agreement that: </span>
      <br/><span>(a) </span><span>Each Sales Draft presented to Servicers for collection is genuine and is not the result of any fraudulent or illegal transaction and is not being deposited on behalf of any business </span>
      <span>other than Merchant. Further, Merchant warrants that each Sales Draft is the result of a Card transaction for the bona fide purchase of goods or services by the Cardholder in the total </span>
      <span>amount stated on the Sales Draft. </span>
      <br/><span>(b) </span><span>Merchant has performed or will perform all of its obligations to the Cardholder in connection with the Card transaction evidenced thereby. </span>
      <br/><span>(c) </span><span>Merchant has complied with Servicers’ procedures accepting Cards, and the Card transaction itself shall not involve any element of credit for any other purposes other than as set </span>
      <span>forth in this Agreement and shall not be subject to any defense, dispute, offset or counter claim which may be raised by any Cardholder under the Rules, the Consumer Credit Protection </span>
      <span>Act (15 USC 1601), or other relevant state or federal statutes or regulations. </span>
      <br/><span>(d) </span><span>Any Credit Voucher which it issues represents a bona fide refund or adjustment on a Card transaction by Merchant with respect to which a Sales Draft has been accepted and </span>
      <span>shall be credited to the same Card used in the original Card transaction. </span>
      <br/><strong>2.3 </strong><strong>Honoring Cards. </strong><br/>
      <span>(a) </span><span>If Merchant elects to accept Card payments, Merchant will accept without discrimination, all valid Cards properly presented by Cardholders for payment for goods or services. </span>
      <span>With respect to VISA and Mastercard products, Merchant may elect to accept credit cards or debit/prepaid cards or both. Merchant shall so elect on the Merchant Application. Merchant </span>
      <span>agrees to pay and the Account will be charged pursuant to this Agreement for any additional fees incurred as a result of Merchant’s subsequent acceptance of Card transactions with </span>
      <span>any VISA or Mastercard product that it has elected not to accept. </span>
      <br/><span>(b) </span><span>Merchant may establish a minimum transaction size for accepting Card transactions so long as such amount does not exceed $10, or such other amount established by law, and </span>
      <span>such minimum transaction size requirement does not discriminate between Card Issuers or between Card Networks. Merchant may not impose a maximum transaction size on Card </span>
      <span>transactions, or add a fee to a Card transaction. </span>
      <br/><span>(c) </span><span>Merchant may offer discounts or in-kind incentives for payment by the use of cash, checks, Debit Cards, or Credit Cards, provided that the terms of the discount or in-kind </span>
      <span>incentives do not differentiate on the basis of the Card Issuer or the Card or Card Network. </span>
      <br/><span>(d) </span><span>Merchant shall not accept a Card as payment (other than for a mail order, telephone order, or preauthorized sale to the extent permitted under this Agreement), if the person </span>
      <span>seeking to use the Card does not present the Card to permit Merchant to examine it and obtain an imprint or otherwise use the physical Card to complete the Transaction. </span>
      <br/><strong>2.4 </strong><strong>Card Acceptance. </strong>
      <span>When accepting a Card, Merchant will follow the steps provided by Servicers for accepting Cards and will: </span>
      <br/><span>(a) </span><span>Determine in good faith and to the best of its ability that the Card is valid on its face, and that the back of the Card is not disfigured or tampered with. </span>
      <br/><span>(b) </span><span>Obtain Authorization before completing any Card transaction. Where Authorization is obtained, Merchant will be deemed to warrant the true identity of the customer as the </span>
      <span>Cardholder. </span>
      <br/><span>(c) </span><span>Obtain an Imprint of the Card unless the Sales Draft is electronically generated from a swiped Card transaction or is the result of an Internet, mail, phone or preauthorized order. </span>
      <br/><span>(d) </span><span>If Merchant’s terminal is unable to read the magnetic stripe on the Card, Merchant will obtain an imprint of the Card and the Cardholder’s signature on the imprinted draft before </span>
      <span>processing the Sales Draft. </span>
      <br/><span>(e) </span><span>Enter a description of the goods or services sold and the price thereof (including any applicable taxes) on the Sales Draft. </span>
      <br/><span>(f) </span><span>Refrain from writing any personal information of the Cardholder on the Sales Draft. </span>
      <br/><span>(g) </span><span>Obtain the Cardholder’s signature on the completed Sales Draft and match that signature to the signature on the Card. </span>
      <br/><span>(h) </span><span>Deliver a true and completed copy of the Sales Draft to the Cardholder at the time of delivery of the goods or performance of the services, or if the Sales Draft is prepared by a </span>
      <span>point-of-sale terminal, at the time of the sale. </span>
      <br/><span>(i) </span><span>Offer the Sales Draft to Servicers for purchase according to the Servicers procedures and the terms of this Agreement. </span>
      <br/><span>(j) </span><span>Legibly reproduce without alteration of the original Card transaction receipt, the Cardholder’s name, account number, expiration date and Merchant’s name and place of business </span>
      <span>if that information is not legibly imprinted on the Sales Draft. Also, for Mastercard transactions, Merchant will legibly reproduce the name of the Card Issuer as it appears on the face </span>
      <span>of the Card. </span>
      <br/><strong>2.5 </strong><strong>Authorization. </strong>
      <br/><span>(a) </span><span>Merchant will obtain an Authorization for all Card transactions. If Merchant cannot, for any reason, obtain an electronic Authorization, Merchant will request a voice Authorization </span>
      <span>from the designated authorization center, and will legibly print the Authorization number on the Sales Draft. Fees for voice Authorizations are as set forth on the Merchant Processing </span>
      <span>Application. </span>
      <br/> <span>(b) </span><span>Merchant shall not request Authorization for a Card transaction unless Merchant intends to submit a Card transaction for the authorized amount. </span>
      <br/><span>(c) </span><span>Merchant acknowledges that an Authorization provides only that the Cardholder account has sufficient credit available to cover the amount of the current sale, and that an </span>
      <span>Authorization is not a guarantee of payment. </span>
      <br/><span>(d) </span><span>Merchant will not attempt to obtain Authorization on an expired Card. Card transactions will be deemed invalid on Cards that are expired, whether or not Authorization has been </span>
      <span>obtained. </span>
      <br/><strong>2.6 </strong><strong>Unusual and Suspicious Transactions; Account Monitoring. </strong><br/>
      <span>(a) </span><span>Merchant acknowledges that Servicers will monitor Merchant’s daily deposit activity for purposes of monitoring compliance with this Agreement. Merchant agrees that </span>
      <span>Merchant’s deposit activity will remain consistent with the “approved” monthly volume and average ticket amount approved on the Merchant Application and/or by Servicers. Servicers </span>
      <span>may require additional documentation if Merchant should exceed “approved” monthly volume or average ticket, and Merchant agrees promptly to provide such documentation upon </span>
      <span>request. Merchant agrees that Servicers may, in their sole discretion, (i) elect not to process volumes over the approved monthly volume or overlimit transactions, (ii) to terminate </span>
      <span>processing of Card transactions for cause in the event of overlimit or excessive activity, and/or (iii) modify Merchant’s “approved” monthly volume or high ticket based on, without </span>
      <span>limitation, industry or risk-based data available to Servicers, including in relation to Merchant’s MCC. Merchant further agrees that Servicers may, in their sole discretion, suspend the </span>
      <span>disbursement of Merchant’s funds for any reasonable period of time required to investigate suspicious or unusual deposit activity and that such suspension may subject Merchant to </span>
      <span>additional suspension fees as set forth herein. Servicers will make good faith efforts to notify Merchant of such suspension as promptly as is commercially reasonable, but no liability </span>
      <span>shall accrue against Servicers if such notice is not so provided. Servicers shall have no liability for any losses, either direct or indirect, which Merchant may attribute to any suspension </span>
      <span>of funds disbursement. </span>
      <br/><span>(b) </span><span>In the event of suspension arising from a suspicious or unusual Card transaction, Merchant agrees that the Account may be charged a Security Processing Fee of $100 per </span>
      <span>suspended Card transaction and, if the suspended Card transaction or unusual or suspicious activity exceeds $3,000, an additional $100 Investigative Fee maybe assessed for each such </span>
      <span>event, without limiting any other rights or remedies of Servicers under this Agreement; provided that the actual assessment of the Fee set forth in this section is up to the discretion of </span>
      <span>the Servicers and non-assessment of such Fee in any circumstance shall not act as a waiver of such fee in the future. </span>
      <br/><span>(c) </span><span>If a batch is suspended by Servicers, Merchant acknowledges that the consumer’s product or service must be delivered just as if Merchant has been paid. Further, if a batch or a </span>
      <span>Card transaction is suspended, Merchant acknowledges that fees associated with the Card transactions will be charged including security fees. </span>
      <br/><span>(d) </span><span>Merchant’s presentation to Servicers of Excessive Activity will be a breach of this Agreement and a cause for immediate termination of this Agreement. “Excessive Activity” </span>
      <span>means, during any monthly period and for any one of Merchant’s terminal identification numbers or merchant identification numbers, (i) Chargebacks and/or retrieval requests in </span>
      <span>excess of 1% of the average monthly dollar amount of Card transactions, (ii) returns in excess of 3% of the average monthly dollar amount of Card transactions, or (iii) processing an </span>
      <span>average ticket above the amount approved by Servicers. Merchant authorizes, upon the occurrence of Excessive Activity, Servicers to take additional actions as they may deem </span>
      <span>necessary, including, but not limited to, suspension of processing privileges or creation or maintenance of a Reserve Account in accordance with this Agreement. </span>
      <br/><strong>2.7 </strong><strong>Forms. </strong><br/>
      <span>Merchant shall use only such forms or modes of transmission of Sales Drafts and Credit Vouchers as are provided or approved in advance by Servicers. </span>
      <br/><strong>2.8 </strong><strong>Retention and Retrieval of Cards. </strong><br/>
      <span>Merchant shall use its best efforts, by reasonable and peaceful means, to retain or recover a Card when receiving such instructions upon making a request for Authorization. In carrying </span>
      <span>out such obligation Merchant will not breach the peace or cause any injury to any person or property. </span>
      <br/><strong>2.9 </strong><strong>Personal Information. </strong><br/>
      <span>Merchant may not require Cardholders to provide any personal information as a condition of honoring a Card unless such information is required for delivery of the goods or services </span>
      <span>or Merchant has reason to believe that the person presenting the Card may not be the actual Cardholder. </span>
      <br/><strong>2.10 </strong><strong>Records; Requests for Copies </strong><br/>
      <span>Merchant shall retain a copy of all Sales Drafts and Credit Vouchers for customer present transactions and electronically approved authorizations for all Card Not Present Transactions </span>
      <span>for at least three (3) years after the date of the Card transaction. Within three (3) business days of receipt of any written or verbal request by Servicers, Merchant shall provide either </span>
      <span>the actual paper Sales Draft or a legible copy thereof and any other documentary evidence reasonably requested by Servicers. A failure of Merchant to deliver the requested </span>
      <span>documentation in the required time frame may result in the transaction in question being charged back to the Account and Merchant losing further rights. </span>
      <br/><strong>2.11 </strong><strong>Multiple Transaction Records; Partial Payments. </strong><br/>
      <span>Merchant shall include all goods and services purchased in a single Card transaction at one time on a single Sales Draft, except: (i) for purchases in separate departments of a multiple </span>
      <span>department store; (ii) for installment payment; or (iii) for delayed or amended charges governed by rules for travel and entertainment merchants and Card transactions. </span>
      <br/><strong>2.12 </strong><strong>Telephone Orders “TO”, Mail Orders “MO”, Internet Orders, Preauthorized
      Orders
      “PO” and Installment Orders “IO”. </strong><br/>
      <span>(a) </span><span>Unless Merchant has been approved by Servicers to accept Internet, mail and phone orders, Merchant warrants that it is a walk-in trade business, located in a retail business place. </span>
      <span>If Merchant is found to be accepting mail orders, telephone orders, or Internet Card transactions without such consent, such charges are subject to Chargeback, as provided in this </span>
      <span>Agreement. When allowed, telephone and Internet orders are permitted only to the extent that the contact is initiated by the Cardholder. </span>
      <br/><span>(b) </span><span>If authorized to accept payment by Internet, mail, phone or pre- authorized order, the Sales Draft may be completed without the Cardholder’s signature or an imprint, but in such </span>
      <span>case Merchant shall create a Sales Draft containing Cardholder account number, expiration date, transaction date, an Authorization number, the sale amount and the letters “MO”, </span>
      <span>“TO”, “PO”, or “IO” as appropriate. In addition, Merchant’s business name, city and state must be included. For Internet orders, Merchant shall attempt to obtain the Card expiration </span>
      <span>date and forward it as part of the Authorization request for non-secure Card transactions and non-authenticated security transactions. Receiving an Authorization shall not relieve </span>
      <span>Merchant of liability for Chargeback on any Card transaction for which Merchant did not obtain an imprint and the Cardholders signature. </span>
      <br/><span>(c) </span><span>For Approved MO, TO, PO, and IO Merchants, Merchant must use reasonable procedures to verify that each Card sale is made to a purchaser who actually is the Cardholder or </span>
      <span>the authorized user of the Card. The Address Verification System (“AVS”) is recommended and in some cases required. AVS is not a guarantee for payment, and the use of AVS will </span>
      <span>not waive any provision of this Agreement or otherwise validate a fraudulent Card transaction. </span>
      <br/><span>(d) </span><span>Unless approved in writing by Servicers, Merchant shall not process sales prior to delivery of product or service. If the product is being shipped, the customer must be given the </span>
      <span>shipping date of the product once the sale is processed. </span>
      <br/><span>(e) </span><span>Merchant may limit acceptance of returned merchandise or establish a policy to make price adjustments for any Card transactions. If a Merchant refund policy prohibits returns </span>
      <span>under certain circumstances, Merchant may still receive a Chargeback relating to such sales pursuant to Card Network Rules. </span>
      <br/><span>(f) </span><span>Merchant must not accept credit card sales via “electronic mail” over the Internet. </span>
      <br/><span>(g) </span><span>Merchant must be approved by Servicers to charge customers via an automatic or recurring payment plan. Any Card transactions in violation of this provision are subject to </span>
      <span>Chargeback to Merchant. For any such automatic or recurring Card transactions, Merchant must obtain a written or electronic form of request from Cardholder for such goods and </span>
      <span>services to be charged to the Cardholder’s account, which request must specify (i) the Cardholder’s name, address and Card number and expiration date, (ii) the Card transaction </span>
      <span>amounts (including the total of all transaction amounts authorized) to be charged to the Cardholder’s account, (iii) the frequency of the recurring charge and the duration of time during </span>
      <span>which such charges may be made, (iv) a general description of the Card transaction, and (v) Merchant’s business name and customer service telephone number that the Cardholder </span>
      <span>may call to obtain customer assistance from Merchant or to revoke approval of the automatic payment plan. Merchant shall not complete any recurring Card transaction after receiving </span>
      <span>a cancellation notice from the Cardholder, the Servicers, or a response to an Authorization request which indicates that the Card is not to be honored. Merchant shall type or legibly </span>
      <span>print the words “Recurring Transaction” on the signature line of the Sales Draft. Merchant shall provide a copy of Cardholder’s written request to Servicers upon request, and shall </span>
      <span>retain a copy of such written request for one year after this Agreement between Merchant and Cardholder is terminated. For multi-year agreements, Merchant shall renew written </span>
      <span>request with Cardholder annually during the 12th month of the current agreement. The first automatic or recurring payment plan Card transaction must contain a positive response from </span>
      <span>the AVS. If the first payment does not contain this information, all subsequent payments are subject to Chargeback to Merchant. </span>
      <br/><strong>2.13 </strong><strong>Lodging and Vehicle Rental Transactions. </strong>
      <br/><span>(a) </span><span>Merchant must estimate and obtain Authorization for the amount of the Card transaction based upon the Cardholder’s intended length of stay or rental. Additional Authorization </span>
      <span>must be obtained and recorded for charges actually incurred in excess of the estimated amount. Some lodging Merchants are eligible to participate in VISA’s Advanced Deposit Service </span>
      <span>Program. Merchants participating in this service must adhere to the Rules concerning the Advanced Deposit Service Program, as set forth by VISA. </span>
      <br/><span>(b) </span><span>Regardless of the terms and conditions of any written preauthorization form, the Sales Draft amount for any lodging or vehicle rental Card transaction shall include only that </span>
      <span>portion of the sale, including any applicable taxes, evidencing a bona fide rental of personal property by Merchant to the Cardholder and shall not include any consequential charges. </span>
      <span>Nothing contained herein is intended to restrict Merchant from enforcing the terms and conditions of its preauthorization form through means other than a Card transaction. </span>
      <br/><strong>2.14 </strong><strong>Returns and Adjustments: Credit Vouchers. </strong><br/>
      <span>(a) </span><span>Merchant’s policy for the exchange or return of goods sold and the adjustment for services rendered shall be established and posted in accordance with the Rules. If applicable, </span>
      <span>Merchant must disclose to a Cardholder before a Card sale is made, that if merchandise is returned: (i) no refund, or less than a full refund, will be given; (ii) returned merchandise will </span>
      <span>only be exchanged for similar merchandise of comparable value; (iii) only a credit toward purchases will be given; or (iv) special conditions or circumstances apply to the sale (e.g., </span>
      <span>late delivery, charges, or other noncredit terms)sale (e.g., late delivery, charges, or other noncredit terms). </span>
      <br/><span>(b) </span><span>Disclosures must be made on all copies of Sales Drafts in letters approximately 1/4” high in close proximity to the space provided for the Cardholder’s signature on the Sales </span>
      <span>Draft and issued at the time of sale. </span>
      <br/><span>(c) </span><span>If Merchant does not make these disclosures, a full refund in the form of a credit to the Cardholder’s Card account must be given. Merchant will not refund cash to a Cardholder </span>
      <span>who paid for the item by Card. </span>
      <br/><span>(d) </span><span>Credits must be made to the same Card account number on which the original sale transaction was processed. </span>
      <br/><span>(e) </span><span>If Merchant accepts any goods for return or terminates or cancels any services, in conjunction with each such Card transaction, Merchant shall have sufficient funds in its account </span>
      <span>available to Servicers to cover the amount of the Card transaction and any related fees. </span>
      <br/><strong>2.15 </strong><strong>Cash Payments. </strong><br/>
      <span>Merchant shall not receive any payments from a Cardholder for charges included in any Card transaction resulting from the use of a Card, nor receive any payment from a Cardholder </span>
      <span>to prepare and present a Card transaction for the purpose of affecting a deposit to the Cardholder’s Card account. </span>
      <br/><strong>2.16 </strong><strong>Cash Advances. </strong>
      <br/><span>(a) </span><span>Merchant shall not deposit any Card transaction for the purpose of obtaining or providing a cash advance either on Merchant’s Card or the Card of any other party. Merchant </span>
      <span>agrees that any such deposit shall be grounds for immediate termination. </span>
      <br/><span>(b) </span><span>Merchant shall not under any circumstances obtain Authorization for, nor process a sale on any Card that Merchant is not authorized to use. Processing Merchant’s own Card is </span>
      <span>grounds for immediate termination. </span>
      <br/><strong>2.17 </strong><strong>Duplicate Transactions. </strong><br/>
      <span>Merchant shall not deposit duplicate Card transactions. Merchant shall be debited for any duplicate Card transactions and shall be liable for any Chargebacks which may result </span>
      <span>therefrom. </span>
      <br/><strong>2.18 </strong><strong>Deposit of Fraudulent Transactions. </strong><br/>
      <span>Merchant must not deposit any transaction that is known to be either fraudulent or not authorized by the Cardholder. Merchant shall not accept or deposit any fraudulent Card transaction </span>
      <span>and may not present for processing or credit, directly or indirectly, a Card transaction which originated with any other merchant or any other source. Merchant shall not deposit Card </span>
      <span>transactions evidencing sales that were solicited by outbound telemarketing activities. If Merchant deposits any such transactions, Servicers may hold funds and/or demand a Reserve </span>
      <span>Account. Perpetrators of fraudulent Card transactions will be referred to law enforcement officials and may be subject to Card Network penalties. </span>
      <br/><strong>2.19 </strong><strong>Collection of Pre-Existing Debt. </strong><br/>
      <span>Merchant shall not present any Card transaction representing the refinancing of an existing obligation of a Cardholder including, but not limited to obligations (i) previously owed to </span>
      <span>Merchant, (ii) arising from the dishonor of a Cardholder’s personal check, and/or (iii) representing the collection of any other pre-existing indebtedness, including collection of </span>
      <span>delinquent accounts on behalf of third parties. </span>
      <br/><strong>2.20 </strong><strong>E-Commerce Transactions. </strong><br/>
      <span>(a) </span><span>Merchant must display the address of its permanent establishment on its website. This address should include the country of domicile and should be located either on the checkout </span>
      <span>screen used to present the total purchase amount to the Cardholder, or within the sequence of web pages the Cardholder accesses during the checkout process. Merchant must also </span>
      <span>display approved Card Network signage. </span>
      <br/><span>(b) </span><span>Merchant must obtain an authorization on the Card transaction date, except when goods are shipped and then the authorization may be obtained up to seven (7) calendar days </span>
      <span>prior to shipping goods. A detailed transaction receipt must be generated for each Card transaction. See the Rules for details. A transaction receipt must be available upon request and </span>
      <span>Merchant must communicate instructions for such request on its website. Merchant may deliver the transaction receipt electronically or by paper. Merchant must attempt to obtain the </span>
      <span>Expiration Date from the Card and forward it as part of the authorization request. </span>
      <br/><span>(c) </span><span>Merchant’s website must communicate its refund policy to the Cardholder and require the Cardholder to select a “click-to-accept” or other affirmative button to acknowledge the </span>
      <span>policy. The terms and conditions of the purchase must be displayed on the same screen view as the checkout screen that presents the total purchase amount, or within the sequence of </span>
      <span>website pages the Cardholder accesses during the checkout process. </span>
      <br/><span>(d) </span><span>Merchants classified under MCC’s (4829, 5967, 6051, and 7995), are not eligible for E-commerce transactions. </span>
      <br/><strong>2.21 </strong><strong>Settlement of Transactions. </strong><br/>
      <span>Servicers shall accept from Merchant all valid Sales Drafts deposited by Merchant under the terms of this Agreement and shall present the same to the appropriate Card Issuers for </span>
      <span>collection against Cardholder accounts. Settlement of all Sales Drafts is subject to this Agreement and the Rules. Servicers shall provisionally credit the value of collected, valid Sales </span>
      <span>Drafts to Merchant’s Account and reserve the right to adjust amounts so credited to reflect the value of Chargebacks, fees, penalties, late submission charges, the difference in currency </span>
      <span>exchange if the Chargeback amount is greater than the original sale, issuer claims, and items for which Servicers did not receive final payment for any reason. Servicers may refuse to </span>
      <span>accept any Sales Draft or revoke its prior acceptance of a Sales Draft in the following circumstances: (a) the transaction giving rise to the Sales Draft was not made in compliance with </span>
      <span>all the terms and conditions of this Agreement; (b) the Cardholder disputes his liability to Servicers for any reason, including but not limited to those Chargeback rights enumerated in </span>
      <span>the Rules; (c) the transaction giving rise to the Sales Draft was not directly between Merchant and Cardholder, or (d) the transaction is outside the parameters indicated on the Merchant </span>
      <span>Application. Merchant will pay Servicers for any amount previously credited to Merchant for a Sales Draft not accepted or later revoked by them. </span>
      <br/><strong>2.22 </strong><strong>Processing Limits. </strong><br/>
      <span>Servicers may limit the dollar amount of Sales Drafts that it will process for Merchant. This limit may be changed by Servicers from time to time with or without notice to Merchant. </span>
      <span>If Merchant exceeds the limit established by this Agreement, Servicers may suspend processing, charge overlimit fees, hold the funds over the cap and/or return to Merchant all Sales </span>
      <span>Drafts evidencing funds over the cap. </span>
      <br/><strong>2.23 </strong><strong>Endorsement. </strong><br/>
      <span>The presentment of Sales Drafts to Servicers for collection and payment is Merchant’s agreement to sell and assign its right, title and interest in each Sales Draft completed in conformity </span>
      <span>with Servicers’ acceptance procedures and shall constitute an endorsement by Merchant to Servicers of such Sales Drafts. Merchant hereby authorizes Servicers to supply such </span>
      <span>endorsement on Merchant’s behalf. Merchant agrees that this Agreement is a contract of financial accommodation within the meaning of Bankruptcy Code 11 U.S.C. Section 365, as </span>
      <span>amended from time to time. Merchant acknowledges, in accordance with Section 1. 9 above, that its obligation to Servicers for all amounts owed under this Agreement arise out of the </span>
      <span>same transaction as Servicers obligation to deposit funds to the Account. </span>
      <br/><strong>2.24 </strong><strong>Transmission Method. </strong><br/>
      <span>If Merchant utilizes electronic Authorization and/or data capture services, Merchant will enter data related to a Card transaction into a computer terminal or magnetic stripe reading </span>
      <span>terminal no later than the close of business on the date the Card transaction is completed. If Merchant provides its own electronic terminal or similar device, such terminals must meet </span>
      <span>Servicers requirements for processing Card transactions. Information regarding a Card transaction transmitted with a computer or magnetic stripe reading terminal will be transmitted </span>
      <span>by Merchant to Servicers in the form Servicers from time to time specify or as required under the Rules. The means of transmission indicated in the Merchant Application shall be the </span>
      <span>exclusive means utilized by Merchant until Merchant has provided Servicers with at least thirty (30) days prior written notice of Merchant’s intention to change the means of such </span>
      <span>delivery or otherwise to alter in any material respect Merchant’s medium of transmission of data to Servicers. </span>
      <br/><strong>2.25 </strong><strong>Prohibited Collection. </strong><br/>
      <span>Unless specifically authorized in writing by Servicers, Merchant shall not collect or attempt to collect from a Cardholder any amount owed for any Card transaction in any manner </span>
      <span>other than as permitted by law and the Rules. </span>
      <br/><strong>2.26 </strong><strong>Chargebacks. </strong><br/>
      <span>All Chargebacks are due upon presentation to Merchant. In the case of a failure to pay a Chargeback upon such presentment, in addition to any other remedies which may be exercised </span>
      <span>by Servicers, Merchant agrees to pay a late charge of one and one half percent (1.5%) per month or portion thereof, or the highest amount allowable by law, whichever is less, on all </span>
      <span>unpaid Chargebacks. Servicers are authorized to deduct the amount of any Chargebacks and fees from any settlement amounts due to Merchant or from the Reserve Account, if any, </span>
      <span>or collect by any other means, including by debit to Account. Merchant acknowledges and agrees that it is bound by the Rules with respect to any Chargeback. Merchant further </span>
      <span>acknowledges that it is solely responsible for providing Servicers with any available information to re-present a Chargeback and that, regardless of any information it provides or does </span>
      <span>not provide Servicers in connection with a Chargeback, or any other reason, Merchant shall be solely responsible for the liability related to such Chargeback. Merchant understands </span>
      <span>and agrees that Card transactions are subject to Chargeback for a variety of reasons under the Rules, or if Merchant has breached this Agreement, including without limitation, for the </span>
      <span>following reasons: </span><br/>
      <span>(a) </span><span>The Sales Draft is illegible, not signed by the Cardholder or has not been presented to Servicers within the required time-frames. </span>
      <br/><span>(b) </span><span>The Sales Draft does not contain the Imprint of a valid unexpired Card. </span>
      <br/><span>(c) </span><span>A valid authorization number has not been correctly and legibly recorded on the Sales Draft. </span>
      <br/><span>(d) </span><span>The Sales Draft is a duplicate of a prior Card transaction or is the result of two or more Card transactions generated for a single sale. </span>
      <br/><span>(e) </span><span>The Cardholder alleges that he or she did not participate in the sale, authorize the use of the Card, receive goods or services purchased, or receive a required credit adjustment, or </span>
      <span>disputes the quality of the goods or services purchased. </span>
      <br/><span>(f) </span><span>The price of goods or services on the Sales Draft differs from the amount which Merchant presents for payment. </span>
      <br/><span>(g) </span><span>The Card transaction results from an Internet, mail, phone or preauthorized order and the Cardholder disputes entering into or authorizing the Card transaction or the Card </span>
      <span>transaction has been made on an expired or non-existing account number. </span>
      <br/><span>(h) </span><span>Servicers reasonably believe in their discretion that Merchant has violated any provision of this Agreement. </span>
      <br/><span>(i) </span><span>Servicers reasonably determine that the Card transaction is not bona fide or is subject to any claim of illegality, cancellation, rescission, or offset for any reason whatsoever, </span>
      <span>including without limitation, negligence, fraud or dishonesty on the part of Merchant or Merchant’s agents or employees. </span>
      <br/><span>(j) </span><span>Merchant fails to provide a Sales Draft or legible copy thereof to Servicers in accordance with this Agreement. </span>
      <br/><span>(k) </span><span>Merchant shall not initiate a Card transaction in an attempt to collect a Chargeback. </span>
      <br/><strong>2.27 </strong><strong>Effect of Termination. </strong><br/>
      <span>(a) </span><span>In the event of termination for any reason, Merchant expressly authorizes Servicers to withhold settlement of Card transactions and other payment transactions of Merchant </span>
      <br/><span>(b) </span><span>At the discretion of Servicers, collected funds may be placed in a Reserve Account until Merchant pays any equipment and processing Termination Fees and any outstanding </span>
      <span>charges, losses or amounts for which Merchant is liable under this Agreement. Further, Servicers reserve the right to require Merchant to deposit additional amounts in the Reserve </span>
      <span>Account based upon Merchant’s processing history and/or anticipated risk of loss to Servicers into the Reserve Account. The Reserve Account shall be maintained for a minimum of </span>
      <span>two hundred seventy (270) days after the termination date or the date of the oldest Card transaction (or longer depending on Merchant’s product and business practices), and for a </span>
      <span>reasonable time thereafter during which Cardholder disputes may remain valid under the Rules or during which Merchant may have outstanding obligations to Servicers. Any balance </span>
      <span>which remains in the Reserve Account after such period will be returned to Merchant. Servicers may debit the Reserve Account for all amounts owed by Merchant under this Agreement. </span>
      <br/><span>(c) </span><span>Merchant expressly acknowledges that MATCH (formerly known as the Combined Terminated Merchant Files or “CTMF”) is a file maintained by Mastercard and accessed by </span>
      <span>VISA, and the Consortium Merchant Negative File, maintained by Discover Network, containing the business names and the identification of principals of merchants which have been </span>
      <span>terminated for one or more reasons specified in the Rules. Such reasons include, but are not limited to: fraud, counterfeit paper, unauthorized Card transactions, excessive Chargebacks, </span>
      <span>retrievals or highly suspect activity. Merchant acknowledges and agrees that Servicers are required to report Merchant and the names and identification of its principals to MATCH </span>
      <span>and the Consortium Merchant Negative File if Merchant is terminated for any such reason. Merchant consents to such reporting to the Card Networks by Servicers and waives any </span>
      <span>claims which Merchant may raise against Servicers as a result of such reporting. Merchant also consents to Servicers reporting incidents of fraud, counterfeit paper, unauthorized Card </span>
      <span>transactions, excessive Chargebacks, retrievals or highly suspect activity to any governmental authorities. </span>
      <br/><span>(d) </span><span>Upon termination for any reason, Merchant will immediately cease requesting Authorizations and will cease transmitting Sales Drafts to Servicers which are made after the </span>
      <span>termination date. </span>
      <br/><span>(e) </span><span>Following termination, Merchant shall upon request provide Servicers with all original and electronic copies of Sales Drafts and Credit Vouchers to be retained as of the date of </span>
      <span>termination. </span>
      <br/><span>(f) </span><span>Effective immediately upon termination whether by Merchant or by Servicers, Merchant must cease the use of all Card Network signage which indicates the acceptance of those </span>
      <span>Cards under this Agreement. </span>
      <br/><strong>2.28 </strong><strong>EBT Services. </strong><br/>
      <span>If applicable, Agent and Processing Partner(s) may offer electronic interfaces to Electronic Benefits Transfer (“EBT”) networks for the processing of cash payments or credits to or for the </span>
      <span>benefit of benefit recipients (“Recipients”). If such services are offered, Agent and such applicable Processing Partner will provide settlement and switching services for various Point </span>
      <span>of Sale transactions initiated through Merchant for the authorization of the issuance of the United States Department of Agriculture, Food and Nutrition Services (“FNS”) food stamp </span>
      <span>benefits (“FS Benefits”) and/or government delivered cash assistance benefits (“Cash Benefits, “with FS Benefits, “Benefits”) to Recipients through the use of a state-issued card (“EBT </span>
      <span>Card”). </span>
      <br/><strong>2.29 </strong><strong>AXP Card Acceptance. </strong><br/>
      <span>Merchant hereby acknowledges and agrees that for purposes of acceptance of AXP, the AXP Guide is hereby incorporated by reference into this Agreement. In addition, Merchant </span>
      <span>hereby authorizes Agent or the Processing Partner to submit AXP transactions to, and receive settlement from, AXP on behalf of Merchant. Merchant must accept the AXP card as </span>
      <span>payment for goods and services (other than those goods and services prohibited under the AXP Guide) sold, or (if applicable) for charitable contributions made, at all of its </span>
      <span>establishments, except as expressly permitted by state statute. Merchant is jointly and severally liable for the obligations of Merchant’s establishments under this Agreement. For the </span>
      <span>avoidance of doubt, “Cardholder” as used in this Agreement shall include “Card Members” as defined in the AXP Guide. </span>
      <span>Merchant hereby acknowledges and agrees that (i) Agent and the Processing Partner may disclose AXP Transaction Data (which for purposes of this Section 2.29 shall have the same </span>
      <span>definition as “Transaction Data” in the AXP Guide), Merchant Data (as defined below), and other information about Merchant to AXP, (ii) AXP may use such information to perform </span>
      <span>its responsibilities in connection with the “AXP Program”, as defined in the AXP Guide, promote the AXP network, perform analytics and create reports, and for any other lawful </span>
      <span>business purpose, including marketing purposes, and (iii) AXP may use the information obtained in this application at the time of setup to screen and/or monitor Merchant in connection </span>
      <span>with AXP Card marketing and administrative purposes. If Merchant has provided a wireless phone number in connection with this Agreement, Merchant hereby agrees that it may be </span>
      <span>contacted at that number and the communications sent may include autodialed text messages or automated prerecorded calls. If Merchant has provided a fax number, Merchant hereby </span>
      <span>agrees that it may be sent fax communications. To opt out of AXP-related marketing communications, Merchant may contact the Agent customer service department as described in </span>
      <span>this Agreement. For purposes of this Section 2.29, “Merchant Data” means names, postal and email addresses, tax ID numbers, names and social security numbers of the authorized </span>
      <span>signer of Merchant and similar identifying information about Merchant. For clarification, Merchant Data does not include AXP Transaction Data. </span>
      <span>Merchant hereby agrees that, in the event that Merchant becomes a High Charge Volume Merchant (as defined below), Merchant will be converted from the AXP Program to a direct </span>
      <span>AXP Card acceptance relationship with AXP, and upon such conversion, (i) Merchant will be bound by AXP’ then-current card acceptance agreement, and (ii) AXP will set pricing </span>
      <span>and other fees payable by Merchant for AXP Card acceptance. “High Charge Volume Merchant” for purposes of this Section 2.29 means an AXP Program Merchant with either (i) </span>
      <span>greater than $1,000,000 in AXP charge volume in a rolling twelve (12) month period or (ii) greater than $100,000 in AXP charge volume in any three consecutive months. For </span>
      <span>clarification, if Merchant has multiple establishments, the AXP charge volume from all establishments shall be summed to together when determining whether Merchant has exceeded </span>
      <span>the thresholds above. </span>
      <span>Merchant shall not assign to any third party any AXP-related payments due to it under this Agreement, and all indebtedness arising from AXP Charges (as defined below) will be for </span>
      <span>bona fide sales of goods and services (or both) at its establishments (as defined below) and free of liens, claims, and encumbrances other than ordinary sales taxes; provided, however, </span>
      <span>that Merchant may sell and assign future AXP transaction receivables to Agent or the Processing Partner, its or their affiliated entities and/or any other cash advance funding source </span>
      <span>that partners with Agent, the Processing Partner or its or their affiliated entities, without consent of AXP. </span>
      <span>Merchant hereby agrees that AXP shall have third party beneficiary rights, but not obligations, to enforce this Agreement against Merchant to the extent applicable to AXP processing. </span>
      <span>Merchant’s termination of AXP card acceptance shall have no direct or indirect effect on Merchant’s rights to accept other card brands. To terminate AXP acceptance, Merchant may </span>
      <span>contact Agent’s customer service as described in this Agreement. </span>
      <span>Without limiting any other rights provided herein, Agent or the Processing Partner shall have the right to immediately terminate Merchant’s acceptance of AXP cards upon request of </span>
      <span>AXP. Merchant may not bill or collect from any AXP Card Member for any purchase or payment on the AXP card unless a Chargeback has been exercised, Merchant has fully paid </span>
      <span>for such charge, and it otherwise has the right to do so. Merchant shall use the AXP brand and marks in accordance with the requirements set forth in the AXP Guide. </span>
      <br/><strong>2.30 Fleet Card Acceptance. </strong><br/>
      <span>(a) </span><span>If you accept Fleet cards, you agree to be bound by such Fleet provider’s rules. You also agree to be bound by all other provisions of this agreement which are applicable to Fleet </span>
      <span>Card services. If you execute an agreement, including this Merchant Agreement for the purpose of accepting one or more Fleet Cards, you understand that Agent or Processing Partner </span>
      <span>(hereafter in this Section 2.30 “we” or “our”) shall have no obligation whatsoever to you with respect to processing such Fleet Cards until the Fleet provider agrees that you may accept </span>
      <span>its Fleet Card for transactions. If one or more Fleet providers agree that you may accept its Fleet Card for transactions, you understand that such transactions may be processed, </span>
      <span>authorized and funded directly by the Fleet provider. You understand that we are not responsible and assume absolutely no liability with regard to any such Fleet Card transactions, </span>
      <span>including but not limited to the funding and settlement of Fleet Card transactions, unless we are obligated to process, authorize or fund transactions through our separate agreement </span>
      <span>with such Fleet Card provider. You understand that additional terms may apply for the acceptance of Fleet Cards and that Fleet Card providers may charge additional fees for the </span>
      <span>services that it provides. You will be notified of any such additional terms or fees to which you must agree to accept Fleet Cards. Notwithstanding anything in this Agreement to the </span>
      <span>contrary, our obligation to provide services to you relating to any Fleet Card will terminate automatically, without penalty to us, upon the earlier of (i) the Fleet provider’s notice of </span>
      <span>such termination, (ii) the Fleet provider’s discontinuance of its Card, (iii) termination of this Agreement, (iv) your failure to comply with any material term relating to Fleet Card </span>
      <span>transactions or Fleet provider rules, or (v) the expiration of our notice of our termination of providing such Fleet Card services. </span>
      <br/><span>(b) </span><span>Merchant’s acceptance of Fleet cards may be subject to additional terms for acceptance of Fleet Cards, as provided by Servicers. </span>
      <br/><strong>2.31 CyberSource. </strong><br/>
      <span>(a) </span><span>CyberSource Services. Merchant acknowledges that during the Term Agent and Processing Partner may offer it the opportunity to utilize one or more of the digital commerce </span>
      <span>services provided by CyberSource Corporation (“CyberSource Services”) in providing Merchant’s services to its customers. </span>
      <br/><span>(b) </span><span>Merchant Representations and Warranties to CyberSource. Merchant represents and warrants that (i) its products or its website do not infringe on or violate the intellectual property </span>
      <span>rights of any third party and will not contain any content which violates any third party rights, (ii) it will follow all requirements for account set up, (iii) it will obtain all necessary </span>
      <span>consents for the submission of any personal information in connection with the CyberSource Services, and (iv) it will at all times remain compliant with Sections 1.18(b) and 1.22(a). </span>
      <br/><span>(c) </span><span>Merchant Feedback. Merchant acknowledges and agrees that CyberSource and its affiliates are in the business of making the CyberSource Services widely available to others. </span>
      <span>During the course of using the CyberSource Services, Merchant may from time to time provide feedback or related information to CyberSource for use in connection with, or otherwise </span>
      <span>regarding, CyberSource’s products, programs, systems or services, or other information provided by Merchant to CyberSource for the purpose of enabling or improving CyberSource </span>
      <span>products, programs, or services. Unless otherwise expressly agreed, all feedback is and shall be given entirely voluntarily and the Merchant shall have any obligation to do so. If </span>
      <span>Merchant does provide feedback, Merchant grants CyberSource and its affiliates a worldwide, perpetual, non-revocable, sublicensable, royalty-free right and license to Merchant’s </span>
      <span>intellectual property rights in the feedback in any format and in any manner without any obligation, payment, or restriction based on Merchant’s intellectual property rights in the </span>
      <span>feedback or otherwise and for any and all purposes necessary for CyberSource and its affiliates to enjoy such license. To be clear, this license includes the rights to use and disclose </span>
      <span>the feedback in any manner CyberSource or its affiliates choose, and to display, perform, copy, have copied, make, have made, incorporate, have incorporated, use, sell, offer to sell, </span>
      <span>import, distribute, and otherwise dispose of CyberSource or its affiliates’ programs, products, or services embodying such feedback in any manner, but without reference to Merchant </span>
      <span>being the source of such feedback. Nothing in this Agreement, the Merchant’s use of the CyberSource Services, or the feedback, limits CyberSource and its affiliates’ rights to </span>
      <span>independently use, develop, evaluate, or market products, whether incorporating feedback or otherwise. </span>
      <br/><span>(d) </span><span>CyberSource Limitation of Liability. MERCHANT AGREES AND ACKNOWLEDGES THAT CYBERSOURCE SHALL HAVE NO LIABILITY, EITHER IN TORT, </span>
      <span>CONTRACT OR IN COMBINATION THEREOF, FOR CLAIMS ARISING FROM MERCHANT’S USE OF THE CYBERSOURCE SERVICES. ANY SUCH CLAIMS ARE </span>
      <span>SUBJECT TO LIMITATIONS SET FORTH HEREIN AND IN NO EVENT SHALL CYBERSOURCE BE LIABLE FOR SPECIAL, CONSEQUENTIAL, INDIRECT OR </span>
      <span>EXEMPLARY DAMAGES, INCLUDING LOST PROFITS, REVENUES OR LOST BUSINESS OPPORTUNITIES. THE LIABILITY, IF ANY, OF CYBERSOURCE, UNDER </span>
      <span>THIS AGREEMENT TO MERCHANT, WHATEVER THE BASIS OF LIABILITY, SHALL NOT EXCEED IN THE AGGREGATE THE FEES RECEIVED BY CYBERSOURCE </span>
      <span>FOR THE PROVISION OF THE CYBERSOUCE SERVICES TO MERCHANT DURING THE SIX (6) MONTH PERIOD PRIOR TO THE DATE THAT THE CAUSE OF ACTION </span>
      <span>AROSE. </span>
      <br/><span>(e) </span><span>Merchant agrees that the permissions and licenses conferred upon Services in Section 1.36 (Publicity) of this Agreement shall be equally conferred upon CyberSource. </span>
      <br/>
      <br/>
      <strong>2.32 Verifi Services. </strong><br/>
      <span>(a) Verifi Services. Merchant acknowledges that during the Term Agent or Processing Partner may offer Merchant the opportunity to utilize one or more of Verifi, Inc.’s (“Verify”) transaction monitoring, dispute, notification and resolution solutions products and services (“Verifi Services”). Merchant agrees that its use of Verifi Services is subject to these Merchant Agreement Terms and in accordance with Verifi’s guidelines.</span>
      <br/>
      <span>(b) Termination of Verifi Services. Upon termination of the Merchant Agreement, regardless of the reason for termination, thereafter, Merchant shall be precluded from participating in any Verifi Services or from receiving the Verifi Services, for six (6) months following termination, unless otherwise agreed to in writing by Verifi.</span>
      <br/>
      <span>(c) Merchant Information. The Verifi Services provided are licensed products owned and controlled by Verifi, and Merchant acknowledges that all data and information set forth in or relating to the Verifi Services, as well as any other relevant Merchant data, shall be shared with Verifi and/or its affiliates without any further approvals or permissions.</span>
      <br/>
      <span>(d) Right of Termination or Suspension at Any Time. In the event that Verifi provides notice to Agent and Processing Partner, Agent and Processing Partner shall reserve the right to terminate or suspend Merchant’s access to the Verifi Services at any point during the Term. Such notice may only be served if Merchant is in material breach that has not been remedied within a reasonable period of time.</span>
      <br/>
      <span>(e) Merchant Obligations for Verifi Services. Merchant agrees to conform in all respects to the use of, and operational guidelines for, the Verifi Services including, but not limited to, Merchant’s agreement (a) that it shall not leverage any Verifi Services for any other purpose besides its intended use, including but not limited to, agreeing that it shall not use the Verifi Services to issue refunds or credits, (b) to adhere to mandatory response times and response rates required by Verifi, and (c) to permit Agent or Processing Partner to provide any and all data reasonably requested by Verifi for purposes audits related to the Verify Services to ensure compliance with the obligations for use of the same.</span>
      <br/>
      <br/>
      <div style={{textAlign: 'center'}}><strong>III. </strong><strong>DEBIT CARD SERVICES </strong></div>
      <br/>
      <span>The following terms and conditions govern Debit Card processing services provided by Servicers to Merchant, regardless of whether Merchant applies to receive such services at the </span>
      <span>time of its initial application for electronic payment services, or subsequently elects to receive such services from Services. </span>
      <br/><strong>3.1 </strong><strong>Services. </strong> <br/>
      <span>Agent and the Processing Partner (if applicable) shall sponsor Merchant for membership in the Debit Card network that Merchant has selected. Merchant acknowledges that sponsorship </span>
      <span>does not guarantee acceptance. Agent and the Processing Partner (if applicable) shall process and settle Merchant’s PIN Debit Card transactions. </span>
      <br/><strong>3.2 </strong><strong>Debit Fees. </strong> <br/>
      <span>Merchant agrees to pay Agent the fees for Debit Card transactions as set forth in the Merchant Application. Furthermore, certain Debit Networks may assess an annual network fee </span>
      <span>which includes fees assessed by both Member and Agent. Payment and modification of fees will be handled as set forth in Article I of this Agreement. Merchant acknowledges that in </span>
      <span>addition to the Debit Card transaction fee set forth on the Merchant Application, each Debit Card transaction will also be charged the Payment Network fee on a per transaction and per </span>
      <span>network basis for the Payment Networks accessed and utilized by Merchant. These fees are subject to change from time to time to conform to the interchange provisions set forth by </span>
      <span>the Payment Networks. </span>
      <br/><strong>3.3 </strong><strong>Merchant Obligations. </strong> <br/>
      <span>(a) </span><span>Unless otherwise authorized by Agent, Merchant shall utilize Agent’s compatible terminals, PIN pads or systems capable of processing all Automatic Clearing House (ACH) </span>
      <span>Debit Card transactions as well as online Debit Card transactions. </span>
      <br/><span>(b) </span><span>In connection with Debit Card transactions, Merchant shall comply with all Rules of the applicable Payment Network. </span>
      <br/><span>(c) </span><span>Merchant shall be responsible for all paper copies of Debit Card transactions, in accordance with the applicable Payment Network rules. Within one (1) business day of the day </span>
      <span>of the Debit Card transaction, Merchant shall balance each location to the system for each business day that such location is open. </span>
      <br/><span>(d) </span><span>Merchant shall be responsible for all telephone message unit costs, if any, which may be incurred by Merchant for the Debit Card services as well as expenses related to installation </span>
      <span>of and training in the use of terminals. </span>
      <br/><span>(e) </span><span>Merchant shall be responsible for entering data correctly. If an entry is made incorrectly, Agent shall use their reasonable efforts to assist Merchant in correcting the entry, but </span>
      <span>cannot guarantee that the effort will be successful. </span>
      <br/><strong>3.4 </strong><strong>PIN Security. </strong> <br/>
      <span>(a) </span><span>Merchant is expressly prohibited from requesting PIN numbers verbally or in writing as a condition of a sale. Merchant may not document or retain PIN numbers in any fashion </span>
      <span>at any time. </span>
      <br/><span>(b) </span><span>Point of sale (“POS”) terminals must be positioned so as to be reasonably secure from observation by third parties. Merchant’s security cameras must not be able to view the PIN </span>
      <span>pad. Pin Entry Device (PED) shielding will be considered as one of the possible prevention methods. </span>
      <br/><span>(c) </span><span>The operational placement and use of the Tamper Resistant Security Module (TRSM) must be within its intended physical and logical environment. POS terminals designed for </span>
      <span>indoor use are not suitable for outside use. The modification of TRSMs from their original manufactured specifications must be restrained. </span>
      <div style={{textAlign: 'center'}}><strong>IV. </strong><strong>EQUIPMENT PLACEMENT </strong></div>
      <strong>4.1 </strong><strong>Placed Equipment. </strong> <br/>
      <span>If the Merchant has accepted the placement of Agent owned equipment for the processing of transactions hereunder, then the addendum executed by the Merchant related to such </span>
      <span>equipment shall be incorporated into this Agreement as if such terms were set forth herein. However, if the Merchant has accepted the placement of Agent owned equipment (the </span>
      <span>“Equipment”) and no addendum or other agreement was signed, the following terms and conditions shall apply to such placement: </span>
      <br/><span>(a) </span><span>Merchant agrees that the Equipment is the property of Agent, is being licensed to Merchant, and must be returned in good and working condition within ten (10) days of the </span>
      <span>termination or expiration of this Agreement. If the Equipment is not returned within ten (10) days, Merchant agrees to pay Agent the value of such equipment, which shall be the same </span>
      <span>as Agent charges for the same equipment to its other merchants. </span>
      <br/><span>(b) </span><span>If the box referencing rental of the Equipment is checked in the Merchant Application, Merchant shall pay the stated license fee on the Equipment on a monthly basis, plus the </span>
      <span>applicable sales use taxes, beginning the month the Equipment is received by Merchant and continuing until the Equipment is returned by Merchant or this Agreement is terminated or </span>
      <span>expires. </span>
      <br/><span>(c) </span><span>Merchant agrees to be responsible for any damage to the Equipment as a result of misuse or negligence. </span>
      <br/><span>(d) </span><span>Merchant agrees to indemnify and hold Servicers harmless from and against any and all liabilities, losses, claims, damages, disputes, offsets, claims or counterclaims of any kind </span>
      <span>in any way related to the use (or misuse) of the Equipment. This includes any damage to the Equipment resulting from an act of nature, fire, or theft, or from misuse or negligence by </span>
      <span>Merchant or its agents. Merchant also agrees to pay Agent a shipping/handling charge for each delivery of replacement Equipment, regardless of the reason. </span>
      <span>Notwithstanding the prior paragraph, if Merchant subscribes to certain level(s) of Agent’s Payments Hub Program, it will not be responsible for failure of Equipment for any reason, </span>
      <span>so long as such Equipment is returned to Agent. Under this program, Agent will also provide overnight replacement of the Equipment free of shipping/handling charges, after remote </span>
      <span>troubleshooting efforts are unsuccessful. Comparable replacement Equipment may be new or refurbished, or a different brand or model. Replacements are limited to two (2) in any </span>
      <span>consecutive twelve (12) month period. </span> <br/><br/>
      <div style={{textAlign: 'center'}}><strong>V. </strong><strong>WIRELESS SERVICE PROVISIONS </strong></div>
      <strong>5.1 </strong><strong>Wireless Services. </strong> <br/>
      <span>Agent has acquired the right to resell and/or sublicense certain wireless data communication services offered by certain cellular telephone and data networks (the “Wireless Networks”), </span>
      <span>in order to allow merchants to capture and transmit to Servicers wireless Card transactions on wireless POS terminals and accessories (the “Wireless Equipment”). The Wireless </span>
      <span>Networks are provided by third party vendors and, as such, the Servicers are in no way responsible for providing, maintaining, servicing or supporting such networks. In the event that </span>
      <span>Merchant utilizes Wireless Equipment and Wireless Services for Card transactions, it agrees to utilize such Wireless Networks and Wireless Equipment only as permitted by the third </span>
      <span>party vendors and in accordance with applicable laws, regulations, requirements, and rulings, as such may be updated from time to time. In addition, Merchant agrees to look exclusively </span>
      <span>to the applicable third party vendor of the Wireless Networks and/or Wireless Equipment for any and all warranties relating thereto, and agrees that Servicers have not provided any </span>
      <span>such warranties. Servicers shall not be responsible for transmission errors, interruptions in service, corruption of data or for the security of data during any transmission. In addition to </span>
      <span>any other provision of this Agreement, the Wireless Services will terminate immediately upon termination of the agreement between Agent and the third party vendor, upon </span>
      <span>discontinuance of the delivery of Wireless Services by the third party vendor, or at such time as the third party vendor or Agent is prevented from providing the Wireless Services by </span>
      <span>reason of any laws, regulations, requirements, rulings or notices issued by any governmental authority. </span>
      <br/><strong>5.2 </strong><strong>Mobile Network Operator Acknowledgement. </strong><br/>
      <span>You authorize your wireless carrier</span><span>(s) </span><span>to use or disclose information about your account and your wireless device</span><strong>(s)</strong><span>, if available, to </span><span>Servicers </span><span>or our service provider for the duration </span>
      <span>of your business relationship, solely to help </span><strong>Servicers </strong><span>identify you or your wireless device</span><span>(s) </span><span>and to prevent fraud. </span>
      <div style={{textAlign: 'center'}}><strong>VI. </strong><strong>PAYMENTS HUB </strong></div>
      <strong>6.1 </strong><strong>Payments Hub. </strong><br/>
      <span>Upon acceptance of the Application by Servicers, Merchant will automatically be enrolled in a sixty (60) day free trial of the Payments Hub subscription services. </span><span>Merchant may </span>
      <span>modify the plan, or opt out of the plan entirely, at any time prior to the end of the free trial by visiting </span><a
      target='_blank' href={'https://www.paymentshub.com'}>https://www.paymentshub.com </a><span>or by contacting the Customer Support Team at </span>
      <a href='mailto:support@paymentshub.com'>support@paymentshub.com</a> <span>or calling (877) 464-4218. If Merchant does not opt out by the end of the trial period, the Merchant account will be charged a monthly fee as set forth on </span>
      <span>the Application.  Learn more about the current benefits and features of the Payments Hub plans, including a no-cost option, and review additional optional paid features at </span>
      <span>https://www.paymentshub.com</span><span>. Remember, no matter what Payments Hub features or plan you select, Merchant may always modify the existing plan, or opt out of the Payments Hub </span>
      <span>service entirely, at any time. Terms and conditions for the Payments Hub Program may be viewed at </span><a
      target='_blank' href={'https://www.paymentshub.com'}>https://www.paymentshub.com</a><span>. </span>
      <div style={{textAlign: 'center'}}><strong>VII. </strong><strong>ACH PROCESSING TERMS </strong></div>
      <br/>
      <span>This Article VII governs Merchant’s use of the ACH services provided by Processing Partner pursuant to this Agreement. Unless otherwise set forth in this Article VII, all other the </span>
      <span>terms of the Agreement continue to apply with respect to the provision of the ACH Services. </span>
      <br/><strong>7.1 </strong><strong>ACH Services. </strong> <br/>
      <span>Processing Partner will transmit Entries initiated by Merchant into the ACH Network as provided in the Nacha Operating Rules. For any Entries of which Servicer is not the Originator, </span>
      <span>Merchant assumes the obligations of an Originator under the Nacha Operating Rules and may not initiate or request the transmission of any Entries that violate the laws of the United </span>
      <span>States. Merchant must utilize Servicer’s technology, software, or other methods of electronic file transfer to obtain Receiver account information for ACH Entries. Servicers  reserve the </span>
      <span>right to make changes to the ACH Services from time to time, so long as the changes do not prevent Merchant from receiving the ACH Services substantially as contemplated under this </span>
      <span>Agreement. Processing Partner agrees to use reasonable care to (i) comply with instructions of Merchant; and (ii) process Entries received from Merchant to conform with the </span>
      <span>specifications set forth in the Nacha Operating Rules or as otherwise required by Processing Partner. </span><span>The ODFI identified by Servicers agrees to use reasonable care to (i) transmit such </span>
      <span>Entries as an ODFI to the ACH Operator; and (ii) settle such Entries as provided in the Nacha Operating Rules and this ACH </span>
      <span>services. Merchant will be solely responsible for ensuring the validity, accuracy, and completeness of all information, data, files, and instructions provided or transmitted to Processing </span>
      <span>Partner. Processing Partner will be entitled to rely upon all such information in providing the ACH services and will have no liability in connection with such reliance. Processing Partner </span>
      <span>will not be required to act on instructions provided by Merchant if Processing Partner reasonably doubts an instruction’s contents or Merchant’s compliance with Applicable Law, the </span>
      <span>Nacha Operating Rules, or this Agreement. Merchant will prepare each Entry or file or API transmitting requested Entries in accordance with the Nacha Operating Rules and any </span>
      <span>additional requirements that may be provided by Processing Partner. All Entries transmitted by Merchant to Processing Partner for processing must comply with the formatting and other </span>
      <span>requirements in the Nacha Operating Rules or as otherwise required by Processing Partner. </span>
      <br/><strong>7.2 </strong><strong>Receiver Authorization. </strong><br/>
      <span>Merchant agrees to obtain Authorization from Receiver(s) pursuant to the requirements of the Nacha Operating Rules prior to debiting or crediting Receivers’ account(s). Merchant must </span>
      <span>retain the original or a copy of each written Receiver Authorization, or a readily and accurately reproducible record (as such term is defined in the Nacha Operating Rules) evidencing </span>
      <span>any other form of Authorization, for two years from the termination or revocation of the Authorization. Merchant represents and warrants with respect to all Entries originated by </span>
      <span>Merchant and processed by Servicers that (1) each Receiver has authorized the debiting and/or crediting of its account, (2) each Entry is for an amount agreed to by the Receiver, and (3) </span>
      <span>each Entry is in all other respects properly authorized. Merchant agrees to indemnify Servicers and ODFI, including their respective directors, officers, employees, and affiliates, for any </span>
      <span>claims, demands, losses, liabilities, costs, or expenses suffered or incurred (including attorneys’ fees and costs) relating to, arising out of, or involving any breach of these representations </span>
      <span>and warranties. Merchant and Servicers will comply with the Nacha Operating Rules and any amendments thereto, as applicable, to each party’s ACH activities under this Agreement. </span>
      <br/><strong>7.3 </strong><strong>Entries. </strong><br/>
      <span>In addition to all other representations and warranties made in this Agreement, Merchant represents and warrants the following to Servicers and ODFI now and as of the time it initiates </span>
      <span>each Entry: </span>
      <br/><span>(a) </span><span>The Entry is for a sum due and owing to Merchant from Receiver, or for a sum due and owing to Receiver from Merchant; </span>
      <br/><span>(b) </span><span>Each Receiver in an Entry received by Servicers from Merchant has authorized the initiation of such Entry and the crediting and/or debiting of its account in the amount and on the </span>
      <span>Effective Entry Date shown in such Entry </span>
      <br/><span>(c) </span><span>The Entry is forwarded in accordance with an authorization executed by the Receiver and held by Merchant; </span>
      <br/><span>(d) </span><span>That the Receiver’s authorization is neither inoperative nor ineffective by operation of law, nor has it been terminated by operation of law; </span>
      <br/><span>(e) </span><span>That, at the time the Entry is transmitted to the ACH Network by Servicers, Merchant does not have actual knowledge of the revocation or termination of the authorization by the </span>
      <span>Receiver; </span>
      <br/><span>(f) </span><span>The Entry is timely; </span>
      <br/><span>(g) </span><span>Merchant specifically acknowledges that if settlement of an ACH Credit Entry is not received, the RDFI shall be entitled to a refund from the Receiver’s account of the amount </span>
      <span>credited, and that Merchant shall not be deemed to have paid the Receiver the amount of the Entry; </span>
      <br/><span>(h) </span><span>Merchant acknowledges and agrees that all Entries originated shall comply with the Nacha Operating Rules, applicable ODFI and Agency requirements, and all Regulations, </span>
      <span>including but not limited to, any economic sanctions administered by OFAC and shall not act on behalf of, or transmit funds to or from, any party subject to such sanctions. </span>
      <br/><strong>7.4 </strong><strong>Unacceptable Transactions. </strong><br/>
      <span>Merchant shall not (a) electronically process any ACH item drawn on any DFI that is not federally insured or part of the ACH network; (b) accept any third party ACH made payable </span>
      <span>where the purpose is for the Receiver to receive cash or cash back; (c) submit for processing any transaction representing the financing of an existing obligation whether previously owed </span>
      <span>to Merchant, arising from the dishonor of an ACH debit or credit, electronic check, or arising from a credit card, debit card, or smart card dispute with Merchant; (d) submit an ACH </span>
      <span>item written for goods or services that are not provided to the Receiver or for goods or services provided by a third party; nor (e) submit an ACH item which is altered by Merchant in </span>
      <span>any way. Unacceptable transactions are not valid Entries, and Servicers are under no obligation to transmit unacceptable transactions to the ACH system. </span>
      <br/><strong>7.5 </strong><strong>Surcharges and Taxes. </strong><br/>
      <span>Merchant shall not impose any surcharge on any electronically processed check transaction. Merchant shall collect all required taxes at time of sale. All required taxes must be included </span>
      <span>in the total transaction amount at the time such is submitted for authorization by Processing Partner and must be reflected in the face amount of the check. In any event, Merchant shall </span>
      <span>not collect any required taxes separately in cash, or otherwise. Merchant is responsible for paying all taxes collected to the appropriate authorities in a timely manner. </span>
      <br/><strong>7.6 Nested Third-Party Senders. </strong><br/>
      <span>Merchant agrees it will not submit any Entries of which Merchant is not the Originator. If Merchant submits Entries on behalf of another Originator not covered under the Agreement, </span>
      <span>Merchant understands that it assumes the obligations of a Nested Third-Party Sender (“Nested TPS”), as defined by the Nacha Operating Rules and represents, warrants, and covenants </span>
      <span>that Processing Partner is hereby authorized to originate Entries on behalf of each Originator for which Merchant acts as a Nested TPS to the Receivers’ accounts and hereby makes to </span>
      <span>Servicers all additional representations and warranties made by a Third Party Sender or TPS to an ODFI under the Nacha Operating Rules. Merchant agrees to provide  Servicers, within </span>
      <span>two (2) Banking Days of receiving request, any documentation or information an ODFI reasonably requests and deems necessary to identify each Nested TPS and regarding each Entry </span>
      <span>(including, but not limited to, those documents that an Originator would be obligated to retain such as the source document (or a copy thereof) for each accounts receivable entry, the </span>
      <span>receipts for each point of purchase entry, and the record of the Originator or Receiver Authorization for an Entry). Furthermore, if acting in the capacity of a Nested TPS, Merchant must </span>
      <span>conduct, or have conducted, an annual ACH audit showing compliance with the Nacha Operating Rules and complete an ACH risk assessment of Merchant's ACH activities and maintain </span>
      <span>the results for review by ODFI for not less than six (6) years. Merchant agrees to provide documentation supporting such audit and risk assessment to Processing Partner within five (5) </span>
      <span>Banking Days of receiving request. </span>
      <br/><strong>7.7 ACH File Submission. </strong><br/>
      <span>In the circumstances where Merchant creates the ACH file, Merchant shall transmit each ACH file to Processing Partner in proper format and on a timely basis, no later than 8:00 P.M., </span>
      <span>Eastern Time one (1) Banking Day prior to the Settlement Date (“Cut-Off Time”). Failure to do so may result in a delay in the availability of funds. Merchant understands that Processing </span>
      <span>Partner may reject Merchant’s Entries for any reason permitted in this Agreement and/or if acceptance of an Entry would cause Processing Partner to potentially violate any federal or </span>
      <span>state law or regulation. Processing Partner may, in its sole discretion, place a hold on funds due to Merchant to ensure against potential losses. Processing Partner will then provide a </span>
      <span>net deposit to Merchant after a period acceptable to Processing Partner. Merchant represents, warrants and covenants that Merchant will advise Processing Partner in writing of any errors </span>
      <span>in any ACH transactions submitted hereunder. </span>
      <br/><strong>7.8 </strong><strong>ACH Settlement. </strong><br/>
      <span>All payments to Merchant shall be made after first deducting any discount fee, transaction fee, credit, ACH return, reserve or other fee or charge for which Merchant is responsible under this </span>
      <span>Agreement. Such charges and fees shall be deducted from incoming transactions or may be debited from Merchant’s designated account(s) at Processing Partner’s sole discretion, without </span>
      <span>any further notice or demand. Settlement shall be done no sooner than two (2) business days following the date of the transaction, excluding any potential hold on funds. ACH returns will </span>
      <span>be deducted from net settlement or may be debited from the Merchant’s account if no pending credits are available to offset the ACH return. All settlements between Processing Partner </span>
      <span>and Merchant are provisional and subject to fees, credit transactions, contingent claims for ACH returns, adjustments, a consumer’s right to dispute the charge against the consumer’s </span>
      <span>account, and any hold on funds under Section 1.8 of this Agreement. All payments to Merchant for legitimate and authorized transactions shall be made by Processing Partner through </span>
      <span>the ACH network and transmitted directly to Merchant’s designated account. Processing Partner does not guarantee the timeliness with which any payment may be credited by Merchant’s </span>
      <span>bank. Merchant agrees to work with Processing Partner to help resolve any problems in crediting Merchant’s account. In the event payment is rejected by Merchant’s bank or fails to </span>
      <span>arrive within seven (7) Banking Days from the Settlement Date due to problems beyond Processing Partner’s control, Processing Partner may periodically wire transfer all funds due </span>
      <span>Merchant until the problem is corrected, at Merchant’s expense. </span>
      <br/><strong>7.9 Returned Entries. </strong><br/>
      <span>Merchant shall bear the full liability for, and shall indemnify Servicers from, ACH items returned by the Receiver whose account was debited or credited whether or not proper </span>
      <span>Authorization was obtained. In accordance with the Nacha Operating Rules, Servicers shall reject returned items deemed “untimely.” In no event, however, shall Servicers be liable for </span>
      <span>Merchant’s ACH return items. SERVICERS OFFER NO GUARANTEE FOR ENTRIES RETURNED UNPAID AND MERCHANT SHALL BE LIABLE FOR 100% OF ALL LOSSES </span>
      <span>ARISING FROM RETURNED ENTRIES</span><span>. </span>
      <br/><strong>7.10 Audit </strong><br/>
      <span>During the term of this Agreement and for one (1) year after its termination, Servicers or their representatives may audit and inspect Merchant’s business processes and business records </span>
      <span>to verify compliance with this Agreement and the Nacha Operating Rules. </span>
      <br/><strong>7.11 Cancellation, Amendment, Reversal</strong><strong>. </strong><br/>
      <span>Merchant has no right to cancel, amend, or reverse an Entry after Processing Partner has received such Entry. If Merchant requests Processing Partner to cancel, amend, or reverse an </span>
      <span>Entry, Processing Partner may, in its sole discretion, attempt to honor such request, but will have no liability for its failure to do so. Merchant agrees to reimburse Processing Partner for </span>
      <span>any expenses, losses, or damages Processing Partner incurs in effecting or attempting to effect Merchant’s request. </span>
      <br/><strong>7.12 Late and Rejected Entries</strong><strong>. </strong><br/>
      <span>Any Entries received after the Cut-Off Time will be processed the following Business Day. Any exceptions that may be allowed, in Processing Partner’s sole discretion, may be subject </span>
      <span>to a late fee assessed by Processing Partner. Processing Partner will notify Merchant of late or rejected Entries. Subject to any applicable fees, Processing Partner may reject any Entry </span>
      <span>that does not, in Processing Partner’s sole determination, comply with the requirements of this Agreement. </span>
      <br/><strong>7.13 Reserved. </strong>
      <br/><strong>7.14 Transmission of ACH Data and Indemnification. </strong><br/>
      <span>(a) </span><span>Merchant shall bear the risk of loss resulting from the transmission of Merchant data to Processing Partner’s data processing center. In the case of physical transmission of such </span>
      <span>data, the responsibility for loss shall remain with Merchant until Processing Partner actually receives delivery of the data, and in the case of electronic transmission, until Processing </span>
      <span>Partner confirms receipt, at which time the risk of loss shall shift to Processing Partner. If a transmission of data to Merchant by Processing Partner fails for any reason, Merchant’s </span>
      <span>sole and exclusive remedy is Processing Partner’s retransmission of that data, when available, at no additional cost to Merchant. </span>
      <br/><span>(b) </span><span>In addition to any other obligations of Merchant set forth in this Agreement, Merchant hereby indemnifies Servicers against any claim, loss, damage, cost, expense, attorneys' </span>
      <span>fees and liability incurred by Servicers or third parties on account of Merchant's breach of the terms of this Article VII or any representations, warranties or covenants herein, other than </span>
      <span>claims arising from Processing Partner’s gross negligence or willful misconduct. If at any time Servicers become aware of, or has reason to believe that they may have, a claim for </span>
      <span>indemnification hereunder, Servicers have the right to refuse transmittal, and/or hold all transferred funds for a period of up to one hundred-eighty (180) Bank Days. </span>
      <br/><strong>7.15 Rates and Fees. </strong><br/>
      <span>Merchant will compensate Servicers for providing the ACH Service referred to herein according to the rates and fees set forth in the Merchant Application. Merchant shall be charged </span>
      <span>the non-sufficient funds (NSF) fee set forth on the Merchant Application when Processing Partner attempts to debit Merchant for any returns, rejects, fines, fees and assessments and such </span>
      <span>charges are returned to Processing Partner for insufficient funds. Servicers shall provide Merchant with prior written notification of all ACH rate and fee changes. If such a change </span>
      <span>occurs, Merchant has the right to terminate the Agreement as set forth in Section 1.14 of this Agreement. </span>
      <br/><strong>7.16 Merchant’s Acknowledgement to Member as ODFI </strong><br/>
      <span>Merchant confirms and agrees that it has authorized TPS to act as its agent in processing ACH Entries for Merchant, and that TPS will establish one or more clearing accounts with, and submit ACH Entries on behalf of the Merchant to an ODFI. </span>
      <span>Merchant: (i) assumes the responsibilities of and makes the warranties of an Originator under the Nacha Operating Rules and agrees to reimburse ODFI for returns, reversals, adjustments, </span>
      <span>reclamations, and warranty claims and responsibilities related to Merchant’s ACH Entries; (ii) agrees to comply with the Nacha Operating Rules, including but not limited to the </span>
      <span>requirements of Article Two (Rights and Responsibilities of ODFIs, Their Originators and Third Party Senders), Rule 2.15 (Obligations of Third-Party Senders, and of ODFIs and </span>
      <span>Originators that Use Third Party Senders), and if international ACH Entries are initiated by Merchant, the Nacha Operating </span><span>Rules applicable to IAT ACH Entries, (iii) agrees to comply </span>
      <span>with all applicable state and federal laws, rules and regulations, including but not limited to sanction laws administered by OFAC, the Electronic Funds Transfer Act, the Unlawful </span>
      <span>Internet Gambling Enforcement Act and Federal Reserve Board Regulation E (the foregoing and the ACH Rules are, collectively, the “Applicable Rules”); and (iv) acknowledges that </span>
      <span>ACH Entries may not be initiated that violate the laws of the United States, including but not limited to the sanctions laws, regulations, and orders administered by OFAC, laws, </span>
      <span>regulations, rules, and orders administered by the Financial Crimes Enforcement Network (“FinCEN”) (as such terms are defined below), and any state laws, regulations, or orders </span>
      <span>applicable to the providers of ACH payment services. </span>
      <span>Merchant represents and warrants as to each ACH Entry that it has obtained the necessary authorizations under the Nacha Operating Rules, Applicable Rules, or other Rules that may </span>
      <span>apply, and that it shall not initiate any funds transfer after the authorization for the same has been revoked (or the agreement between Merchant and Agent has been terminated). With </span>
      <span>respect to each IAT Entry Processing Partner sends to ODFI on behalf of Merchant, Merchant represents and warrants to ODFI that such IAT Entry is in compliance with United States </span>
      <span>law, including, but not limited to, rules promulgated and programs administered by OFAC and FinCEN, that no such IAT Entry violates United States law, including, but not limited to, </span>
      <span>rules promulgated and programs administered by OFAC and FinCEN, that neither Processing Partner nor the Merchant are acting on behalf of or transmitting funds to any party subject </span>
      <span>to OFAC sanctions and that such IAT Entry complies with the laws and payment system rules of the receiving country. Merchant acknowledges that ODFI and other parties must comply </span>
      <span>with the Nacha Operating Rules and United States law for IAT Entries. The performance by each of these parties, including ODFI, of obligations with respect to IAT Entries may cause </span>
      <span>delays in processing, settlement, and/or availability of IAT Entries. Merchant waives and releases ODFI from any liability or obligation, including, but not limited to, funds availability </span>
      <span>obligations, caused by or arising out of any such delay associated with IAT Entries. </span>
      <span>Merchant understands that ODFI has the right to: (i) review, monitor, and audit Merchant’s ACH transactions, processes, and procedures for compliance with this Agreement and the </span>
      <span>Nacha Operating Rules, Applicable Rules, or other Rules that may apply; (ii) restrict or limit the amount or type of ACH Entries processed for Merchant; and (iii) suspend, discontinue, </span>
      <span>or terminate ACH processing based on its assessment of the risk posed to the ODFI and/or the breach or termination of its agreement with Processing Partner. </span>
      <span>Merchant is responsible for the results of using a Processing Partner, the services, and for the accuracy and adequacy of the data Merchant or Processing Partner provides. Merchant </span>
      <span>authorizes ODFI to act on any instruction which has been or reasonably appears to have been sent by Processing Partner or Merchant, including but not limited to funds transfer </span>
      <span>instructions. ODFI is not obliged to take any further steps to confirm or authenticate such instructions and will act on them without getting further confirmation. Merchant understands </span>
      <span>that if it or the Processing Partner provides ODFI with incorrect information or if there is any error in the instruction it accepts full responsibility for losses resulting from any of the </span>
      <span>errors, duplication, ambiguities, or fraud in the information that was provided to ODFI. ODFI is not responsible to third parties (such as, but not limited to, third party service providers </span>
      <span>and the third parties to whom wire or ACH debit or credits are transmitted hereunder) and Merchant shall defend, indemnify, and hold ODFI harmless from, the actions or omissions of </span>
      <span>Processing Partner, or any claim made against ODFI arising out of Merchant’s use of the services, breach of this Agreement, or breach of any warranty under the Nacha Operating Rules. </span>
      <strong>IN NO EVENT WILL ODFI BE LIABLE OR RESPONSIBLE. MERCHANT BEARS ALL RISK ASSOCIATED WITH, FOREIGN EXCHANGE CONVERSION AND ANY GAINS AND LOSSES RESULTING FROM THE CONVERSION OF CURRENCIES IN CONNECTION WITH ANY ENTRY. </strong>
      <span>This Article VII shall survive the </span>
      <span>termination of the agreement between Agent and ODFI. Notwithstanding anything to the contrary elsewhere in the Agreement between Agent and Merchant, ODFI shall be considered </span>
      <span>an intended beneficiary of this Acknowledgment and is entitled to enforce its terms. This Acknowledgment is agreed to in consideration of ODFI’s agreement to serve as ODFI. </span>
      <div style={{textAlign: 'center'}}><strong>VIII. </strong><strong>GLOSSARY </strong></div>
      <br/><strong>8.1 </strong><strong>As used in this Agreement, the following terms shall have the following
      meanings: </strong><br/>
      <span>(a) </span><span>“ACH”: means the Automated Clearing House transfer system, governed by the Nacha Operating Rules. </span>
      <br/><span>(b) </span><span>“ACH Operator”: </span><span>is the central facility for the clearing, delivery, and settlement of Entries between or among Participating DFIs. </span>
      <br/><span>(c) </span><span>“Account”: An account at a banking institution designated by Merchant as the account to be debited and credited by Servicers for Card transactions, fees, Chargebacks and other </span>
      <span>amounts due under this Agreement or in connection with this Agreement. </span>
      <br/><span>(d) </span><span>“Authorization”: Approval by, or on behalf of, the Card Issuer to validate a Card transaction for a merchant or another affiliate bank. </span><span>An authorization only indicates the </span>
      <span>availability of the Cardholder’s credit limit at the time the Authorization is requested. </span>
      <br/><span>(e) </span><span>“Banking Day”: means any day on which a participating financial depository institution is open to the public during any part of the day for substantially all its banking functions. </span>
      <br/><span>(f) </span><span>“Bankruptcy”: A case under Title 11 of the United States Code, as amended from time to time. </span>
      <br/><span>(g) </span><span>“Card”: See either Credit Card or Debit Card. </span>
      <br/><span>(h) </span><span>“Card Network”: Any entity formed to administer and promote Cards, including without limitation, VISA, Mastercard, Discover, JCB, AXP and any applicable Payment Network. </span>
      <br/><span>(i) </span><span>“Card Issuer”: The Card Network or bank institution that issues a Card to an individual. </span>
      <br/><span>(j) </span><span>“Cardholder”: The individual whose name is embossed on a Card and any authorized user of such Card. </span>
      <br/><span>(k) </span><span>“Card Not Present Transaction” occurs when the Card is not present at the point of sale, including Internet order, mail order or telephone order Card sales. </span>
      <br/><span>(l) </span><span>“Chargeback”: The procedure by which a Sales Draft or other indicia of a Card transaction (or disputed portion) is returned to the Servicers. </span>
      <br/><span>(m) </span><span>“Credit Card”: A valid Card authorizing the Cardholder to buy goods or services on credit and bearing the service mark of VISA, Mastercard, Discover Network, JCB, AXP or </span>
      <span>other Card Network specified by Agent. </span>
      <br/><span>(n) </span><span>“Credit Voucher”: Evidence of a return of goods or services by Merchant to a Cardholder, or other refund made by Merchant to a Cardholder, regardless of whether such evidence </span>
      <span>is in paper or electronic form or otherwise, all of which must conform to the Rules. </span>
      <br/><span>(o) </span><span>“Debit Card”: A PIN Debit or Non-PIN Debit Card. </span>
      <br/><span>(p) </span><span>“DFI”: means a depository financial institution. </span>
      <br/><span>(q) </span><span>“Effective Entry Date” the date, specified by the Originator and based on the Receiver’s authorization, settlement should occur. </span>
      <br/><span>(r) </span><span>“Entry or Entries”: an order or request for transfer of funds, resulting in a debit or credit of monies to a designated Receiver’s account, or a non-monetary Entry to the Receiver’s </span>
      <span>account. </span>
      <br/><span>(s) </span><span>“Exposure Limit” The maximum total limit established by Agent or Processing Partner for each of its Merchants beyond which the Processing Partner is unwilling to process </span>
      <span>additional ACH files. This limit is established as a fraud and error control under the Agent’s risk management program. </span>
      <br/><span>(t) </span><span>“IAT”: International ACH transaction. </span>
      <br/><span>(u) </span><span>“MCC”: The merchant category code assigned to Merchant and used to identify the type of business and/or goods or services sold by Merchant. </span>
      <br/><span>(v) </span><span>“Nacha”: means the National Automated Clearing House Association and its related payment alliances that establish and enforce the standards, rules and procedures (the “Nacha </span>
      <span>Operating Rules”) that enable DFIs to exchange payments on a national basis. </span>
      <br/><span>(w) </span><span>“Nacha Operating Rules” </span><span>means the rules, industry standards and procedures governing the exchange of commercial ACH payments by depository financial institutions promulgated </span>
      <span>by the National Automated Clearing House Association. Merchant acknowledges it may purchase a copy of the Nacha Operating Rules at </span><a
      target='_blank' href={'https://www.nacha.org'}>www.nacha.org</a><span>. </span>
      <br/><span>(x) </span><span>“Nested Third-Party Sender”: means a Third-Party Sender that has an agreement with another Third-Party Sender to act on behalf of an Originator, and does not have a direct </span>
      <span>agreement with the ODFI used for processing </span>
      <br/><span>(y) </span><span>“Non-PIN Debit Card”: A Debit Card with a VISA, Mastercard or Discover Network mark that is tied to a Cardholder’s bank account or a prepaid account and which is processed </span>
      <span>without the use of a PIN. </span>
      <br/><span>(z) </span><span>“ODFI”: means the Originating DFI and is the DFI that transmits ACH Entries to the ACH Operator on behalf of the Originator. </span>
      <br/><span>(aa) </span><span>“Originator”: a company that initiates a credit Entry, debit Entry, or non-monetary Entry to a Receiver’s account at an RDFI. </span>
      <br/><span>(bb) </span><span>“Payment Network”: A Debit Card network such as, but not limited to, Accel, AFFN, Interlink, MAC, Maestro, NYCE, Pulse, Star and Tyme. </span>
      <br/><span>(cc) </span><span>“PIN”: A personal identification number entered by a Cardholder to submit a PIN Debit Card transaction. </span>
      <br/><span>(dd) </span><span>“PIN Debit Card”: A Debit Card used at a merchant location by means of a Cardholder-entered PIN in the merchant PIN Pad. PIN Debit Cards bear the marks of ATM networks. </span>
      <br/><span>(ee) </span><span>“RDFI”: means the Receiving DFI and is the DFI that receives ACH Entries from the ACH Operator and posts them to the accounts of its depositors, the Receivers </span>
      <br/><span>(ff) </span><span>“Receiver”: a consumer or company that has authorized an Originator to initiate a credit Entry, debit Entry, or non-monetary Entry to their account at the RDFI. </span>
      <br/><span>(gg) </span><span>“Reserve Account”: An account established using merchant funds and managed by Servicers to protect against actual or contingent liability arising from Chargebacks, adjustments, </span>
      <span>fees and other charges due to or incurred by Servicers. </span>
      <br/> <span>(hh) </span><span>“Rules”: The rules, regulations, releases, interpretations, guidelines and other requirements (whether contractual or otherwise) imposed or adopted by any Card Network. Without </span>
      <span>limitation, the Rules include VISA International Operating Regulations, the Mastercard Rules, and the AXP OptBlue Program Operating Regulations, and all applicable Fleet provider </span>
      <span>rules. </span>
      <br/><span>(ii) </span><span>“Sales Draft”: Evidence of a purchase of goods or services by a Cardholder from Merchant using a Card, regardless of whether such evidence is in paper or electronic form or </span>
      <span>otherwise, all of which must conform to the Rules. </span>
      <br/><span>(jj) </span><span>“Third-Party Sender or TPS”: a Third-Party Service Provider that acts as an intermediary in transmitting ACH Entries between an Originator and an ODFI. </span>
      <br/>
    </div>
  );
};

export default MerchantProcessingAgreement;
