/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import DateUtil from '../../util/DateUtil';
import AverageCard from '../../../components/activity/cards/AverageCard';
import MostCommonWordsCard from '../../activity/cards/MostCommonWordsCard';
import MostRecentReviewsCard from '../../activity/cards/MostRecentReviewsCard';
import CompetitorPerformanceCard from '../../activity/cards/CompetitorPerformanceCard';
import GetStartedTrends from './trends/GetStartedTrends';
import Loading from '../../Loading';
import ScoreCard from '../../activity/cards/ScoreCard';
import moment from 'moment';
import CustomerReviewDetailsPanel from './customerReviews/CustomerReviewDetailsPanel';
import reputationStatusOptions from '../../../constants/reputationStatusOptions';

import DateUtils from '../../util/DateUtil';
import {setSelectedDateRange} from '../../../actions/userExperienceActions';
import {getReputationSettings} from '../../../actions/reputationActions';

const { getMomentDateFromUnix } = DateUtil;

const defaultDateSelectionPeriod = 'Today';

export default class Trends extends Component {
  constructor(props) {
    super(props);
    const { userExperience } = props;

    this.getCommonWords = this.getCommonWords.bind(this);
    this.getCompetitorsCards = this.getCompetitorsCards.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);

    this.hideReviewDetails = this.hideReviewDetails.bind(this);
    this.showReviewDetails = this.showReviewDetails.bind(this);
    this.setEditMode = this.setEditMode.bind(this);

    this.state = {
      displayReviewDetails: false,
      selectedIndex: 0,
      isEditing: false
    }
  }

  showReviewDetails(selectedIndex, source) {
    if (source === 'Google') this.setState({selectedIndex, displayReviewDetails: true});
  }

  setEditMode() {
    this.setState({isEditing: true})
  }

  hideReviewDetails() {
    this.setState({selectedIndex: -1, displayReviewDetails: false, isEditing: false});
  }

  getCompetitorsCards = (competitors) => {
    const competitorData = _.reduce(competitors, (acc, item) => {

      if(item && item.status !== reputationStatusOptions.COMPETITOR_NOT_FOUND && item.status !== reputationStatusOptions.COMPETITOR_REQUEST_DENIED) {
        let negative = 0;
        let positive = 0;
        let neutral = 0;
        let average = 0;
        let accumulator = 0;

        const total = item.data?.reviews?.length || 0;

        item.data?.reviews?.length && item.data.reviews.forEach(({rating}) => {
          if(rating >= 4) positive++;
          else if(rating === 3) neutral++;
          else negative++;

          accumulator += rating;
        });

        average = accumulator ? (accumulator / total).toFixed(1) : 0;

        const  current = {
          name: item.data.name,
          satisfaction: {
            total,
            average
          },
          sentimental: {
            negative,
            positive,
            neutral
          }
        };
        return [...acc, current];
      }
      return acc;
      },  []);

     return competitorData;

   }

  getCommonWords = (words) => {
    if (!words?.length) return [];
    const mostRelevantWord = words.reduce((prev, current) => (prev.count > current.count) ? prev : current).count;


    return words.reduce((acc, item)=> {
      let opacity = '1';
      if(item.count <= (mostRelevantWord/4)){
        opacity = '0.23';
      } else if(item.count <= (mostRelevantWord/3)){
        opacity = '0.45';
      } else if(item.count <= (mostRelevantWord/2)){
        opacity = '0.76';
      }
      return [...acc, {...item, props: {style: {color: '#000000', opacity: opacity}}}];
    },[]);
  }

  handleDateSelection(value, dateRange) {
    this.setState({ dateRange });
    this.props.dispatch(setSelectedDateRange(value, dateRange));
    this.props.dispatch(getReputationSettings(this.props.user, dateRange));
  }

  render() {
    const dateRange = this.props.userExperience.selectedDate.dateRange || this.props.dateRange || DateUtils.getDateRange(defaultDateSelectionPeriod);

    const {googleAccount, isFetching, isUpdatingCompetitors} = this.props.reputationManagement;
    const { merchantSettings: { isProcessing } } = this.props;

    const isFetchingData = isFetching || this.props.userExperience.isFetching;

    const paReviews = googleAccount?.pa_reviews;
    const paReviewsAverage =
      paReviews && paReviews.length ? (paReviews.reduce((acc, curr) => {
        return acc + curr.rating;
      }, 0) / paReviews.length).toFixed(1)
    :
      0;
    const scoreCardsData = googleAccount?.trend ? googleAccount.trend : {};

    const selectedReview = this.props.reviews ? this.props.reviews[this.state.selectedIndex] : {};

    const isReputationManagementEnabled = (!_.isNull(googleAccount)
      && !_.isUndefined(googleAccount)
      && (!_.isEmpty(googleAccount.client_id)));

    const mostCommonWordsContent = isReputationManagementEnabled ? this.getCommonWords(googleAccount?.mostCommonWords) : [];
    const competitorsCards = isReputationManagementEnabled ? this.getCompetitorsCards(googleAccount?.competitors, dateRange) : [];


    const  { t } = this.props;
    const isLoading = isFetchingData || isUpdatingCompetitors || isProcessing;

    return (
      <div className='trends'>

        {isReputationManagementEnabled && !isLoading ? (<>
          <div className='cardsContainer'>
            <ScoreCard {...this.props} isLoading={isLoading} type='OverallSatisfaction' scoreData={scoreCardsData} />
            <ScoreCard {...this.props} isLoading={isLoading} type='Sentiment' scoreData={scoreCardsData} />
            <ScoreCard {...this.props} isLoading={isLoading} type='ReputationChange' scoreData={scoreCardsData} period={dateRange} />
          </div>
          <div className='cardsContainer middle'>
            <MostRecentReviewsCard  {...this.props} onClick={this.showReviewDetails} />
            <CompetitorPerformanceCard {...this.props} data={competitorsCards} isLoading={isLoading}/>
            {mostCommonWordsContent && <MostCommonWordsCard {...this.props} data={mostCommonWordsContent} isLoading={isLoading}/>}
            <AverageCard {...this.props} type='payanywhere' rating={paReviewsAverage} ratingsNumber={paReviews?.length} isLoading={isLoading} icon='/images/payanywhere/logoSmall.svg' />
          </div>
          <div className='cardsContainer'>  </div>
<CustomerReviewDetailsPanel  {...this.props} hideReviewDetails={this.hideReviewDetails} displayReviewDetails={this.state.displayReviewDetails} selectedReview={selectedReview} isEditing={this.state.isEditing} setEditMode={this.setEditMode} />

        </>) :(isLoading ? <Loading/>  : <GetStartedTrends  {...this.props}  />) }
      </div>
    )
  }
}
