/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Menu from '@mui/material/Menu';
import _ from 'lodash';
import MenuItem from '../components/shared/MenuItem';
import withAppData from './withAppData';
import routes from '../constants/routes';
import {
  setVisibilityFilter,
  toggleInventoryPopover
} from '../actions/userExperienceActions';
import { isJSON } from '../components/util/CommonUtil';
import {isInvalid} from 'redux-form';
import filterSelectors from '../selectors/filterSelectors';

const appRoutePrefix = globalApplicationLabel.path;

function withInventoryLayout(WrappedComponent) {
  return class WrappedWithInventoryData extends React.Component {

    constructor(props) {
      super(props);

      this.handleCreateNew = this.handleCreateNew.bind(this);
      this.handleCreateMenuClose = this.handleCreateMenuClose.bind(this);

      /* istanbul ignore else */
      if (process.env.RUNNING_TESTS === 'true') {
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleTopBarFilterSelection = this.handleTopBarFilterSelection.bind(this);
        this.handleFilterSelection = this.handleFilterSelection.bind(this);
        this.handleCreateMenuClose = this.handleCreateMenuClose.bind(this);
        this.renderCreateMenu = this.renderCreateMenu.bind(this);

        this.state = {anchorEl: null};
      }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
      return !_.isEqual(nextProps, this.props);
    }

    /**
     * If filter is not current navigates to
     * the correct list view sets and flag so showDetailForRow
     * is call from the start else calls showDetailForRow
     * @param {*} filterName event object
     * @param {*} openCreateNew create new menu flag
     * @param {*} currentRoute
     * @param {*} showDetailForRow callback
     */
    handleCreateNew(filterName, openCreateNewFromTheStart, currentRoute, showDetailForRow) {
      const nextRoute = `${appRoutePrefix}${routes.inventory.root}${routes.inventory[filterName]}`;

      if (nextRoute != currentRoute) {
        this.props.history.push({
          pathname: nextRoute,
          state: { openCreateNewFromTheStart }
        })
      } else {
        showDetailForRow('new');
      }

      this.handleCreateMenuClose();
    }

    /**
     * On create button click, open menu
     * and set its anchor element to the button.
     * @param {*} event event object
     */
    handleCreateClick(event) {
      event.preventDefault();
      this.props.dispatch(toggleInventoryPopover(true));
      this.setState({
        anchorEl: event.currentTarget,
      });
    }

    // On createMenuClose set proper state
    handleCreateMenuClose() {
      this.props.dispatch(toggleInventoryPopover(false));
    }

    renderCreateMenu() {
      const currentRoute = this.props.location.pathname;
      const { t } = this.props;
      return (
        <Menu
          open={this.props.userExperience.openInventoryPopover}
          anchorEl={this.state.anchorEl}
          placement='right'
          onClose={this.handleCreateMenuClose}
        >
          <MenuItem primaryText={t('CreateMenu.NewItem')} onClick={() => this.handleCreateNew('items', true, currentRoute, this.showDetailForRow)}/>
          <MenuItem primaryText={t('CreateMenu.NewCategory')} onClick={() => this.handleCreateNew('categories', true, currentRoute, this.showDetailForRow)}/>
          <MenuItem primaryText={t('CreateMenu.NewModifierSet')} onClick={() => this.handleCreateNew('modifiers', true, currentRoute, this.showDetailForRow)}/>
          <MenuItem primaryText={t('CreateMenu.NewDiscount')} onClick={() => this.handleCreateNew('discounts', true, currentRoute, this.showDetailForRow)}/>
        </Menu>
      )
    }

    handleFilterSelection(filter) {
      if (filter && filter.text && typeof filter.text === 'string') {
        let filterText = filter.text.toLowerCase();
        if (filterText === 'modifier sets') filterText = 'modifiers';
        if (filterText === 'archived items') filterText = 'archivedItems';
        this.setState({selectedIndex: null}, () => this.handleCreateNew(filterText, false));
      } else {
        this.setState({selectedIndex: null});
      }
      this.props.dispatch(setVisibilityFilter(null, 'pagination'));
    }

    handleTopBarFilterSelection(filter) {
      const filterObj = isJSON(filter)  ? JSON.parse(filter) : {};

      this.setState({selectedFilter: filter, selectedIndex: null});

      this.props.dispatch(setVisibilityFilter({
        property: 'type',
        value: filter,
        ...(filterObj?.categoryId ? { categoryId: filterObj.categoryId } : {})},
        'filter'));
    }

    getNewProps(props) {

      const {
        generateFilterData,
        handleFilterSelection,
        handleTopBarFilterSelection,
        handleCreateClick,
        handleCreateMenuClose,
        renderCreateMenu,
        handleCreateNew
      } = this;

      return {
        generateFilterData,
        handleFilterSelection,
        handleTopBarFilterSelection,
        renderCreateMenu,
        handleCreateClick,
        handleCreateMenuClose,
        handleCreateNew,
      }
    }

    render() {
      const sharedProps = {
        ...this.props,
        ...this.getNewProps(this.props)
      };

      return (
        <div className='inventory'>
          <WrappedComponent {...sharedProps}/>
        </div>
      );
    }
  }
};


function mapStateToProps(state, ownProps) {
  const {
    salesItemImages,
    taxes,
    merchantSettings,
    visibilityFilter,
    log } = state;

  const { location } = ownProps;

  const items = filterSelectors.filterInventoryItems({ ...state, location });

  const isItemDetailFormInvalid = isInvalid('itemDetailForm')(state);

  return {
    items,
    taxes,
    salesItemImages,
    merchantSettings,
    visibilityFilter,
    isItemDetailFormInvalid,
    log
  }
}


export default compose(
  withAppData,
  connect(mapStateToProps),
  withInventoryLayout
);

