/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import _ from 'lodash';
import query from 'query-string';
import { withTranslate } from '../hoc/withTranslate';
import { getApplePaySession } from '../actions/applePayActions';
import { postLog, postLogWithToken } from '../actions/loggerActions';
import actionTypes from '../constants/actionTypes';
import Modal from './shared/Modal';
import Button from './shared/Button';

export class ApplePayButton extends Component {

  constructor(props) {
    super(props);
    this.onApplePayBuyClick = this.onApplePayBuyClick.bind(this);

    this.state = {
      displayApplePayButton: false,
      sessionActive: false,
      isApplePayErrorDialogOpen: false
    };
  }

  componentDidMount(){
    if (window.ApplePaySession) { // The Apple Pay JS API is available
      try {
        if (ApplePaySession.canMakePayments()) {
          this.setState({displayApplePayButton: true});
        } else {
          this.handlePostLog('Device cannot make Apple Pay payments', 'info', actionTypes.applePaySessionCanMakePayments);
        }
      } catch (error) { //Will throw if trying to start an Apple Pay session from an insecure document
        this.handlePostLog(error, 'error', actionTypes.applePaySessionCanMakePayments);
      }
    }
  }

  async onApplePayBuyClick() {
    const { validate } = this.props;
    const { sessionActive } = this.state;

    if (!!validate) {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
    }

    if (!sessionActive) {
      const session = new ApplePaySession(1, this.props.paymentRequest);
      this.setState({ sessionActive: true }, () => this.createApplePaySession(session));
    }
  }

  createApplePaySession = (session) => {
    /**
     * Merchant Validation
     * We call our merchant session endpoint, passing the URL to use
     */
    session.onvalidatemerchant = async (event) => {
      const validationURL = event.validationURL;
      try {
        const response = await this.props.dispatch(getApplePaySession(this.props?.user, validationURL));
        const validatedResponse = _.isString(response.response) ? JSON.parse(response.response) : response.response;
        session.completeMerchantValidation(validatedResponse);
      } catch (error) {
        this.handlePostLog(error, 'error', actionTypes.establishApplePaySessionRequest);
        this.abortApplePaySession(session);
      }
    };

    /**
     * Shipping Method Selection
     * If the user changes their chosen shipping method we need to recalculate
     * the total price (if applicable). We can use the shipping method identifier to determine
     * which method was selected.
     */
    session.onshippingmethodselected = (event) => {
      //If we only have one shipping option then no changes are needed.
      const {total, lineItems} = this.props.paymentRequest;
      session.completeShippingMethodSelection(ApplePaySession.STATUS_SUCCESS, total, lineItems);
    };

    session.onpaymentmethodselected = (event) => {
      const {total, lineItems} = this.props.paymentRequest;
      session.completePaymentMethodSelection(total, lineItems);
    };

    session.onshippingcontactselected = (event) => {
      const {total, lineItems, shippingMethods} = this.props.paymentRequest;
      session.completeShippingContactSelection(ApplePaySession.STATUS_SUCCESS, shippingMethods, total, lineItems);
    };

    /**
     * Payment Authorization
     * Here you receive the encrypted payment data. You would then send it
     * on to your payment provider for processing, and return an appropriate
     * status in session.completePayment()
     */
    session.onpaymentauthorized = (event) => {

      const payment = event.payment;

      //External callback received by the ApplePayButton component that will handle
      //the payment token according to the particular page where it's used (invoices, supplies, etc)
      this.props.handlePaymentProcessing(
        this.props.user,
        payment,
        this.props.paymentData,
        this.props.tipAmount
      ).then((response) => {
        this.completePayment(session, ApplePaySession.STATUS_SUCCESS);
      }).catch((error) => {
        this.completePayment(session, ApplePaySession.STATUS_FAILURE);
      }).finally(() => {
        this.setState({ sessionActive: false });
      });

    };

    session.oncancel = (event) => {
      this.setState({ sessionActive: false });
    };

    // All our handlers are setup - start the Apple Pay payment
    session.begin();
  }

  completePayment = (session, status) => {
    try {
      session.completePayment(status);
    } catch (error) {
      this.handlePostLog(error, 'error', actionTypes.applePayCompletePayment);
      this.abortApplePaySession(session);
    }
  };

  abortApplePaySession = (session) => {
    this.setState({ sessionActive: false });
    this.openApplePayErrorDialog();
    try {
      session.abort();
    } catch (error) {
      this.handlePostLog(error, 'error', actionTypes.applePayAbortSession);
    }
  };

  handlePostLog(log, type, actionType) {
    const {
      dispatch,
      user,
      token,
      location,
      paymentType
    } = this.props;

    const queryString = query.parse(location?.search);
    const foundToken = token || queryString?.token;

    const errorLogPayload = {
      actionType,
      type,
      log
    };

    if (!!foundToken) {
      dispatch(postLogWithToken(foundToken, errorLogPayload, paymentType));
    } else {
      dispatch(postLog(user, errorLogPayload));
    }
  }

  openApplePayErrorDialog = () => (
    this.setState({ isApplePayErrorDialogOpen: true })
  );

  closeApplePayErrorDialog = () => (
    this.setState({ isApplePayErrorDialogOpen: false })
  );

  render() {
    const { disabled, t } = this.props;
    const { isApplePayErrorDialogOpen, displayApplePayButton } = this.state;

    const applePayButtonWithText = (
      <Button
        disabled={!!disabled}
        className='apple-pay-button apple-pay-button-black'
        onClick={this.onApplePayBuyClick}
      />
    );

    const applePayErrorDialog = (
      <Modal
        title={t('ApplePayErrorDialog.Title')}
        open={isApplePayErrorDialogOpen}
        confirmText={t('Okay')}
        onConfirm={this.closeApplePayErrorDialog}
        onClose={this.closeApplePayErrorDialog}
        hideCancelButton
      >
        {t('ApplePayErrorDialog.Body')}
      </Modal>
    );

    return (
      <div>
        {displayApplePayButton ? applePayButtonWithText : null}
        {applePayErrorDialog}
      </div>
    );

  }
}

ApplePayButton.propTypes = {

};

export default withTranslate(ApplePayButton);
