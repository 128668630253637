/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {
  change,
  touch,
  stopSubmit,
  Field, getFormMeta,
  getFormSyncErrors,
  getFormValues,
  reduxForm
} from 'redux-form';
import {connect} from 'react-redux';
import Captcha from '../Captcha';

import Validator from '../util/Validator';
import TextField from '../shared/TextField';
import FormatTextUtil from '../util/FormatTextUtil';
import VisibilityToggle from '../visibilityToggle';
import BrowserUtil from '../util/BrowserUtil';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Button from '../shared/Button';
import RightChevronIcon from '../icons/RightChevronIcon';
import ApplePayButton from '../ApplePayButton';
import TipSelector from '../shared/enhancedInvoices/TipSelector';
import paymentLinks from '../../constants/paymentLinks';
import numeral from 'numeral';
import { roundToTwoDecimals } from '../util/CommonUtil';
import HighAmountModal from '../business/HighAmountModal';
import { highTransactionLimitAmount } from '../../constants/applicationConstants';
import paymentTypes from '../../constants/paymentTypes';
import { setModalVisibility } from '../../actions/userExperienceActions';
import _ from 'lodash';
import { paymentLinkSectionStyles, paymentLinkFormOptionStyles } from '../../jss/paymentLinksStyles';

const normalizeCurrency = (value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 20);

const validate = (values) => {
  return Validator.validatePaymentLinkPayments(values);
};

export class PaymentLinkFormComponent extends Component {

  constructor(props) {
    super(props);

    this.toggleCardNumberVisibility = this.toggleCardNumberVisibility.bind(this);
    this.setTip = this.setTip.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.setOtherAmount = this.setOtherAmount.bind(this);
    this.getFormattedTotal = this.getFormattedTotal.bind(this);
    this.getFormattedApplePayAmount = this.getFormattedApplePayAmount.bind(this);
    this.getTipAmount = this.getTipAmount.bind(this);
    this.getSubTotal = this.getSubTotal.bind(this);
    this.getSubTotalWithoutCD = this.getSubTotalWithoutCD.bind(this);
    this.getTaxAmount = this.getTaxAmount.bind(this);
    this.calculateCashDiscountAmount = this.calculateCashDiscountAmount.bind(this);
    this.getTotal = this.getTotal.bind(this);
    this.validateApplePay = this.validateApplePay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeHighAmountDialog = this.closeHighAmountDialog.bind(this);
    this.openHighAmountDialog = this.openHighAmountDialog.bind(this);

    this.state = {
      cardVisibility: false,
      tip: {
        value: 0,
        type: ''
      },
      selectedAmountIndex: null
    }
  }

  async componentDidMount() {
    if (this.props.settings.type === paymentLinks.amountTypes.fixedAmount) {
      await this.updateRelatedAmounts();
    }
  }

  async componentDidUpdate(prevProps) {
    const { currentValues, settings, merchantSettings, taxes } = this.props;

    const [prevAmount, prevOtherAmount] = _.at(prevProps?.currentValues, ['amount', 'other_amount']);
    const [amount, otherAmount] = _.at(currentValues, ['amount', 'other_amount']);

    if (prevAmount !== amount || prevOtherAmount !== otherAmount) {
      await this.updateRelatedAmounts();
    } else if (settings?.tax_enabled) {
      if (merchantSettings?.is_geotax_enabled) {
        const prevGeoTaxRate = prevProps?.taxes?.geoTaxRate;
        const newGeoTaxRate = taxes?.geoTaxRate;

        if (prevGeoTaxRate !== newGeoTaxRate && !_.isNaN(newGeoTaxRate)) {
          await this.updateRelatedAmounts();
        }
      } else {
        const prevTaxRate = prevProps?.taxes?.taxRate;
        const newTaxRate = taxes?.taxRate;

        if (prevTaxRate !== newTaxRate && !_.isNaN(newTaxRate)) {
          await this.updateRelatedAmounts();
        }
      }
    }
  }

  toggleCardNumberVisibility() {
    this.setState(prevState => ({ cardVisibility: !prevState.cardVisibility }));
  }

  setTip({value, type}) {
    this.setState({
      tip: { value, type }
    }, () => {
      this.props.dispatch(change('paymentLinkForm', 'tip_amount', this.formatNumber(this.calculateTipAmount())));
    });
  }

  setAmount(amount, index) {
    this.props.dispatch(change('paymentLinkForm', 'amount', amount));
    this.props.dispatch(change('paymentLinkForm', 'other_amount', null));
    this.setState({selectedAmountIndex: index});
  }

  setOtherAmount(amount) {
    this.props.dispatch(change('paymentLinkForm', 'amount', null));
    this.props.dispatch(change('paymentLinkForm', 'other_amount', amount));
    this.setState({selectedAmountIndex: null});
  }

  parseNumber(value) {
    const result = parseFloat(value?.replace(/[$,]/g, '')) || 0;
    return roundToTwoDecimals(result);
  }

  formatNumber(value) {
    return numeral(value).format('$0,0.00');
  }

  calculateTipAmount() {
    const { tip: { value, type }} = this.state;
    const subtotalWithTax = this.getSubTotal() + this.getTaxAmount();
    let tipAmount = 0;

    if (value) {
      if (type === '%') {
        const tipPercent = parseFloat(value.replace('%', '')) / 100;
        tipAmount = roundToTwoDecimals(tipPercent * subtotalWithTax);
      } else {
        tipAmount = this.parseNumber(value);
      }
    }

    return tipAmount;
  }

  getTipAmount() {
    const { currentValues: { tip_amount } } = this.props;
    return this.parseNumber(tip_amount);
  }

  calculateTaxAmount() {
    const { taxes, settings, merchantSettings } = this.props;

    if (settings.tax_enabled) {
      const taxRate = parseFloat(merchantSettings?.is_geotax_enabled ? taxes.geoTaxRate : taxes.taxRate) / 100;

      if (!_.isNaN(taxRate)) {
        return roundToTwoDecimals(this.getSubTotal() * taxRate);
      }
    }

    return 0;
  }

  getTaxAmount() {
    const { currentValues: { tax_amount } } = this.props;
    return this.parseNumber(tax_amount);
  }

  calculateCashDiscountAmount() {
    const {
      merchantSettings: {cd_enabled, cd_fixed_amount, cd_percent_amount},
      settings: {cash_discount_enabled}
    } = this.props;

    if (cd_enabled && cash_discount_enabled) {

      const cdFlatAmount = numeral(cd_fixed_amount).value();
      const cdPercentAmount = numeral(cd_percent_amount).value() / 100;
      const subtotal = this.getSubTotalWithoutCD();
      const percentCdApplied = subtotal * cdPercentAmount;
      return cdFlatAmount >= percentCdApplied ? cdFlatAmount : percentCdApplied;
    }
    return 0;
  }

  getCashDiscountAmount() {
    return this.calculateCashDiscountAmount();
  }

  getSubTotalWithoutCD() {
    let subTotal = 0;
    const { currentValues: { amount, other_amount } } = this.props;

    subTotal += this.parseNumber(amount);
    subTotal += this.parseNumber(other_amount);

    return subTotal;
  }

  getSubTotal() {
    return this.getSubTotalWithoutCD() + this.getCashDiscountAmount();
  }

  getTotal() {
    const amountWithoutCD = this.getSubTotalWithoutCD() + this.getTipAmount(); //Note that getTaxAmount is based on the subtotal with CD
    const total = this.getSubTotal() + this.getTaxAmount() + this.getTipAmount();

    return amountWithoutCD > 0 ? total : 0;
  }

  getFormattedTotal() {
    const total = this.getTotal();
    return total ? numeral(total).format('$0,0.00') : '';
  }

  getFormattedApplePayAmount(amount) {
    const numericalAmount = amount ?? 0;
    return numeral(numericalAmount).format('0.00');
  }

  async updateRelatedAmounts() {
    const { dispatch } = this.props;
    await dispatch(change('paymentLinkForm', 'cash_discount_amount', this.formatNumber(this.calculateCashDiscountAmount())));
    await dispatch(change('paymentLinkForm', 'tax_amount', this.formatNumber(this.calculateTaxAmount())));
    await dispatch(change('paymentLinkForm', 'tip_amount', this.formatNumber(this.calculateTipAmount())));
  }

  async validateApplePay() {
    const { dispatch } = this.props;

    await dispatch(change('paymentLinkForm', 'isApplePay', true));

    const { formSyncErrors } = this.props;
    const isHighAmount = this.getTotal() > highTransactionLimitAmount;
    const isValid = Object.keys(formSyncErrors).length === 0 && !isHighAmount;

    if (!isValid) {
      dispatch(touch('paymentLinkForm', ['amount']));
      dispatch(stopSubmit('paymentLinkForm', formSyncErrors));
    }

    if (isHighAmount) {
      this.openHighAmountDialog();
    }

    return isValid;
  }

  async handleSubmit() {
    const { handleSubmit, dispatch } = this.props;

    if (this.getTotal() > highTransactionLimitAmount) {
      this.openHighAmountDialog();
    } else {
      await dispatch(change('paymentLinkForm', 'isApplePay', false));

      handleSubmit && handleSubmit();
    }
  }

  closeHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  openHighAmountDialog() {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('highTransactionAmountDialog'));
  }

  render() {
    const {
      submitting,
      currentValues,
      handleSubmitApplePay,
      virtualTerminal,
      merchantSettings,
      settings,
      errors,
      taxes,
      showRecaptcha,
      t
    } = this.props;

    const isSafari = BrowserUtil.isSafari();

    const shouldShowAvsFields = !merchantSettings?.ignore_avs_failure;

    const formHasCdigits = virtualTerminal.cardType && currentValues && currentValues.cdigits &&
      currentValues.cdigits.length > 0;

    const tipAmounts = (merchantSettings?.tip_enabled || merchantSettings?.is_tip_enabled) && settings?.tips_enabled ? merchantSettings?.tip_default_values : null;
    const tipAmount = this.getTipAmount();

    const buttonColor = merchantSettings?.merchantSettings?.brand_color;

    const applePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      shippingMethods: [],
      lineItems: [
        {
          label: 'Subtotal',
          amount: this.getFormattedApplePayAmount(this.getSubTotal()),
          type: 'final'
        },
        {
          label: 'Tax',
          amount: this.getFormattedApplePayAmount(this.getTaxAmount()),
          type: 'final'
        }
      ],
      total: {
        label: settings.title,
        amount: this.getFormattedApplePayAmount(this.getTotal()),
        type: 'final'
      },
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      merchantCapabilities: ['supports3DS'],
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['name', 'email', 'phone']
    };

    if (tipAmount > 0) {
      applePayPaymentRequest.lineItems.push({
        label: 'Tip',
        amount: this.getFormattedApplePayAmount(tipAmount),
        type: 'final'
      });
    }

    const applePayButton = isSafari ?
      (<div className='applePayContainer'>
        <ApplePayButton
          {...this.props}
          disabled={taxes?.isFetching}
          tipAmount={tipAmount}
          paymentRequest={applePayPaymentRequest}
          paymentData={currentValues}
          paymentType={paymentTypes.paymentLink}
          handlePaymentProcessing={handleSubmitApplePay}
          validate={this.validateApplePay}
        />
      </div>) : null;

    const amountErrorMessageValues = {
      minimum: numeral(settings?.open_end_min).format('$0,0.00'),
      maximum: numeral(settings?.open_end_max).format('$0,0.00')
    };

    let paymentAmountContainer;
    switch (settings.type) {
      case paymentLinks.amountTypes.fixedAmount:
        paymentAmountContainer = (
          <div className='fixedAmount'>{currentValues.amount}</div>
        );
        break;
      case paymentLinks.amountTypes.openEnded:
        paymentAmountContainer = (
          <Field
            component={TextField}
            name='amount'
            errorMessageValue={amountErrorMessageValues}
            label={t('PaymentLinkForm.Field.AmountLabel')}
            hintText={t('PaymentLinkForm.Field.AmountHint')}
            className='amount'
            maxLength='11'
            disabled={submitting}
            normalize={normalizeCurrency}
          />
        );
        break;
      case paymentLinks.amountTypes.multipleAmount:
        paymentAmountContainer = (
          <div className='multipleAmounts'>
            <div className='fixedAmounts'>
              <div className='amountSubtitle'>{t('PaymentLinkForm.Field.SelectAmountLabel')}</div>
              <div className={`amountOptions ${errors.amount ? 'required' : ''}`}>
                {settings.amount.map( (amount, index) => {
                  const paymentAmount = normalizeCurrency(amount);
                  return (<Box
                    sx={paymentLinkFormOptionStyles}
                    key={`paymentAmount${index}`}
                    className={`amountOption ${index === this.state.selectedAmountIndex ? 'selected' : ''}`}
                    onClick={() => this.setAmount(paymentAmount, index)}>{paymentAmount}</Box>);
                })}
              </div>
              {errors.amount && <div className='amountOptionError'>{t(errors.amount)}</div>}
              <Field name='amount' component='input' type='hidden' />
            </div>

            {settings.include_other && (
              <div className='otherAmount'>
                <Field
                  component={TextField}
                  name='other_amount'
                  errorMessageValue={amountErrorMessageValues}
                  label={t('PaymentLinkForm.Field.SelectOtherAmount')}
                  hintText={t('PaymentLinkForm.Field.AmountHint')}
                  className='amount'
                  maxLength='11'
                  disabled={submitting}
                  normalize={normalizeCurrency}
                  onChange={(e, value) => this.setOtherAmount(value)}
                />
              </div>
            )}
          </div>
        );
        break;
    }

    const extraFieldsContainer = settings.custom_field && (
      <Field
        component={TextField}
        name='custom_field'
        label={settings.custom_field.name}
        className='extraField'
        maxLength='100'
        disabled={submitting}
      />
    );

    const paymentLinkForPayment = this.props.paymentLinks?.paymentLinkForPayment;
    const dba = paymentLinkForPayment?.merchant?.business_name;
    const isHighAmountTransactionDialogOpen = this.props.userExperience?.modalVisibility?.highTransactionAmountDialog;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className='paymentLinkForm'>
          <div className='paymentLinkSection'>
            <div className='paymentLinkTitle'>{settings.title}</div>
            {settings.subtitle && <div className='paymentLinkSubTitle'>{settings.subtitle}</div>}
            {settings.image && <img className='paymentLinkImage' alt={settings.title} src={`data:application/octet-stream;base64,${settings.image}`} />}

            <div className='paymentLinkAmountForm'>
              {paymentAmountContainer}
              {tipAmounts && <TipSelector tipAmounts={tipAmounts} setTip={this.setTip} t={t} />}
              {extraFieldsContainer}
              {applePayButton}
            </div>
          </div>

          <Box className='paymentLinkSection' sx={paymentLinkSectionStyles}>
            <div className='paymentLinkTitle'>{t('PaymentLinkForm.PayViaCreditCardOption')}</div>

            <div className='paymentLinkCardForm'>
              <Field
                component={TextField}
                disabled={submitting}
                label={t('PaymentLinkForm.Field.CardHolderLabel')}
                hintText={t('PaymentLinkForm.Field.CardHolderLabel')}
                maxLength='75'
                name='name_on_card'
                id='name_on_card'
              />

              <div className='userData'>
                <Field
                  component={TextField}
                  disabled={submitting}
                  label={t('EmailAddress')}
                  hintText={t('EmailAddress')}
                  maxLength='75'
                  name='email_address'
                  id='email_address'
                />

                <Field
                  className='textField'
                  component={TextField}
                  disabled={submitting}
                  label={t('PhoneNumber')}
                  hintText={t('PhoneNumber')}
                  maxLength='75'
                  name='phone_number'
                  id='phone_number'
                  normalize={FormatTextUtil.formatPhoneNumber}
                />
              </div>

              <div className='cardData'>
                <div className='cardContainerWithVisibilityToggle'>
                  <Field
                    className={`cardNumber ${this.state.cardVisibility ? 'visible' : ''}`}
                    component={TextField}
                    disabled={submitting}
                    label={t('PaymentLinkForm.Field.CardNumberLabel')}
                    hintText={t('PaymentLinkForm.Field.CardNumberLabel')}
                    maxLength='25'
                    name='cdigits'
                    id='cdigits'
                    normalize={value => FormatTextUtil.formatCardNumber(value)}
                  />
                  <span className='cardImage'>
                    <img width='35' src={`${serverDomainUrl}images/cards/${formHasCdigits ? virtualTerminal.cardType : 'unknown_card'}.png`}/>
                  </span>
                  <VisibilityToggle
                    visibility={this.state.cardVisibility}
                    onClick={this.toggleCardNumberVisibility}/>
                </div>

                <div className='cardMetadata'>
                  <Field
                    component={TextField}
                    name='edate'
                    label={t('Expiration')}
                    hintText='MM/YY'
                    normalize={FormatTextUtil.formatCreditCardExpiration}
                    disabled={submitting}
                    maxLength='5'
                  />

                  <Field
                    name='cvv'
                    component={TextField}
                    label={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                    hintText={virtualTerminal.isAmex ? 'CID' : 'CVV'}
                    maxLength={'10'}
                    disabled={submitting}
                    normalize={FormatTextUtil.formatWholeNumber}
                  />
                </div>
              </div>
              {shouldShowAvsFields && <div className='avs'>
                <Field
                  component={TextField}
                  name='street_number'
                  label={t('BillingStreet.Label')}
                  hintText={t('BillingStreet.Label')}
                  normalize={FormatTextUtil.formatStreetNumber}
                  disabled={submitting}
                />
                <Field
                  component={TextField}
                  name='zip'
                  label={t('ZipCode.Label')}
                  hintText={t('ZipCode.Label')}
                  maxLength='5'
                  disabled={submitting}
                  normalize={FormatTextUtil.formatWholeNumber}
                  className='quarterScreen textField'
                />
              </div>}
              {showRecaptcha && <div className='paymentlinkRecaptcha' data-test-id='paymentlinkRecaptcha'>
                <Field
                  name='captcha_response'
                  component={Captcha}
                  t={t}
                />
              </div>}
            </div>
            <Button
              className='payButton enableHover'
              data-test-id='payButton'
              fullWidth
              disabled={submitting || taxes?.isFetching}
              onClick={this.handleSubmit}
              endIcon={<SvgIcon><RightChevronIcon/></SvgIcon>}
              sx={{
                background: buttonColor
              }}
              label={`${t('PaymentLinkForm.Pay')} ${this.getFormattedTotal()}`}
            />
            <div className='footerText'>{t('PaymentLinkForm.TotalMayInclude')}</div>
          </Box>
          <HighAmountModal
            onClose={this.closeHighAmountDialog}
            open={isHighAmountTransactionDialogOpen}
            bodyText={t('HighAmountModal.ExternalBody', {dba})}
            t={t}
          />
        </div>
      </form>
    );
  }

}

export const PaymentLinkForm = reduxForm({
  form: 'paymentLinkForm',
  validate,
  enableReinitialize: false,
  touchOnChange: false,
  touchOnBlur: false
})(PaymentLinkFormComponent);

export function mapStateToProps(state, ownProps) {
  const currentValues = getFormValues('paymentLinkForm')(state) || {};
  currentValues.amount = !currentValues.amount && ownProps.settings.type === paymentLinks.amountTypes.fixedAmount ?
    normalizeCurrency(ownProps.settings.amount) : currentValues.amount;
  currentValues.settings = ownProps.settings;
  currentValues.avsEnabled = !ownProps.merchantSettings?.ignore_avs_failure;

  const formSyncErrors = getFormSyncErrors('paymentLinkForm')(state);
  const formMeta = getFormMeta('paymentLinkForm')(state);
  const errors = Object.keys(formSyncErrors)
    .filter(fieldName => formMeta[fieldName]?.touched && !formMeta[fieldName]?.pristine)
    .reduce((errors, fieldName) => {
      errors[fieldName] = formSyncErrors[fieldName];
      return errors;
    }, {});

  return {
    initialValues: {
      ...currentValues
    },
    currentValues,
    errors,
    formSyncErrors
  };
}

export default connect(mapStateToProps)(PaymentLinkForm);
