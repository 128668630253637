/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { setSubmitButtonEnabled, toggleNorthGatekeeper } from '../../actions/userExperienceActions';
import { connect } from 'react-redux';
import TextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import _ from 'lodash';
import {
  Field,
  FieldArray,
  reduxForm,
  submit,
  reset,
  getFormValues,
  touch,
  Form
} from 'redux-form';
import FormUtil from './../util/FormUtil';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import IconUtils from './../util/IconUtil';
import LabelUtil from './../util/LabelUtil';
import {
  RecurringInvoiceInfo,
  RecurringInvoiceInfoType
} from '../RecurringInvoiceInfo';
import CustomTextField from '../shared/TextField';
import Modal from '../shared/Modal';
import CardOnFileForm from '../shared/enhancedInvoices/CardOnFileForm';
import IconUtil from '../util/IconUtil';
import {
  setCustomer,
  getCustomer,
  deletePaymentMethod,
  sendRewardEmail
} from '../../actions/customerActions';
import UpdateSpinner from '../UpdateSpinner';
import ReskinMessageDialog from '../shared/MessageDialog';
import DateUtil from '../util/DateUtil';
import { toastr } from 'react-redux-toastr';
import actionTypes from '../../constants/actionTypes';
import UserUtil from '../util/UserUtil';
import CommonUtil from '../util/CommonUtil';
import Button from '../shared/Button';
import CustomerUtil from '../util/CustomerUtil';
import Tooltip from '../shared/Tooltip';
import {
  setModalVisibility,
} from '../../actions/userExperienceActions';
import Bugsnag from '@bugsnag/js';
import PaymentMethodSummary from '../shared/enhancedInvoices/PaymentMethodSummary';
import Autocomplete from '../shared/Autocomplete'
import Box from '@mui/material/Box';
import FilterUtil from '../util/FilterUtil';
import { countryStateList } from '../../constants/countryStateList';
import { customerDetailCardStyles, customerDetailStyles, detailsStyles, margin, padding, availableRewards } from '../../jss/customerDetailStyles';
import Typography from '@mui/material/Typography';

export const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    const errors = Validator.validateCustomerDetailForm(values);
    if (values.id && errors?.email_addresses?.length && errors?.phone_number) {
      props.dispatch(touch('customerDetailForm', 'email_addresses[0]'));
      props.dispatch(touch('customerDetailForm', 'phone_number'));
    }
    return errors;
  }
  return {};
};

export const customerEmailAddresses = ({ isFetching, fields, meta: {touched, error}, t, disabled}) => (
  <div>
    {fields.map((emailAddress, index) =>
      (<Field
          autoComplete='off'
          component={CustomTextField}
          key={index}
          disabled={isFetching || disabled}
          label={t('EmailAddress')}
          maxLength='50'
          name={'email_addresses[' + index + ']'}
      />)
    )}
  </div>
);

export const getFieldValue = (formValue = {}, selectedCustomerValue = {}, fieldName) => (
  (formValue?.[fieldName] || formValue?.[fieldName] === '')
    ? formValue?.[fieldName]
    : selectedCustomerValue?.[fieldName]
);

export class CustomerDetailContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentMethodDialog: false,
      paymentCards: [],
      openDeleteDialog: false,
      sendingRewardEmail: false,
      triggerOnTouch: false,
      showCustomerAddress: false
    }

    this.closePaymentModal = this.closePaymentModal.bind(this);
    this.confirmCardOnFileForm = this.confirmCardOnFileForm.bind(this);
    this.submitCardOnFileForm = this.submitCardOnFileForm.bind(this);
    this.handleCardSubmit = this.handleCardSubmit.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.removePaymentMethod = this.removePaymentMethod.bind(this);
    this.openCardOnFile = this.openCardOnFile.bind(this);
    this.submit = this.submit.bind(this);
    this.removePaymentMethodHelper = this.removePaymentMethodHelper.bind(this);
    this.sendRewardEmail = this.sendRewardEmail.bind(this);
    this.closeCardErrorModal = this.closeCardErrorModal.bind(this);
    this.handleError = this.handleError.bind(this);
    this.toggleCustomerAddress = this.toggleCustomerAddress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let enableSubmit = FormUtil.shouldEnableSubmit(nextProps);
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }

    const { customers: { selectedCustomer }, setUnsavedChangesMessage }  = this.props;

    const { customers: { selectedCustomer: nextPropsCustomer } } = nextProps;
    const cardsUpdated = (!selectedCustomer?.payment_methods &&
      !!nextPropsCustomer?.payment_methods) ||
      (selectedCustomer?.payment_methods?.length !== nextPropsCustomer?.payment_methods?.length) ||
      selectedCustomer?.payment_methods?.filter(paymentMethod => !!paymentMethod?.id && !nextPropsCustomer?.payment_methods.find(newPaymentMethod => newPaymentMethod?.id === paymentMethod?.id))?.length > 0; // new card

    if (cardsUpdated) {
      this.setState({ paymentCards:  nextProps.customers?.selectedCustomer?.payment_methods });
    }

    if ((this.props.invalid || nextProps.invalid) && this.state.triggerOnTouch) {
      this.setState({ triggerOnTouch: false });
      Object.keys(nextProps.customerDetailFormValues)
        .forEach(field => {
          if (field === 'email_addresses') return this.props.dispatch(touch('customerDetailForm', `${field}[0]`));
          this.props.dispatch(touch('customerDetailForm', field));
          setUnsavedChangesMessage(field);
        });
    }
  }

  async componentDidMount() {
    const {
      customers: {
        selectedCustomer
      },
      user,
      isNew,
      dispatch,
      location,
    } = this.props;

    if (isNew) return;

    let getCustomerArgs = null;

    let paCustomerId = location?.state?.pa_customer_id;
    let customerId = location?.state?.customer_id;

    if (selectedCustomer?.is_pa_customer) {
      getCustomerArgs = [user, selectedCustomer.id];
    } else if (selectedCustomer?.id) {
      getCustomerArgs = [user, selectedCustomer.id, false];
    } else if (paCustomerId) {
      getCustomerArgs = [user, paCustomerId];
    } else if (customerId){
      getCustomerArgs = [user, customerId, false];
    }

    getCustomerArgs && await dispatch(getCustomer(...getCustomerArgs));
  }


  closePaymentModal() {
    const { dispatch, customers: { selectedCustomer } } = this.props;

    dispatch(reset('cardOnFileForm'));
    dispatch(setCustomer({
      ...selectedCustomer,
      adding_cards: false
    }));
    this.setState({ paymentMethodDialog: false });
  };

  confirmCardOnFileForm () {
    const { dispatch } = this.props;
    dispatch(submit('cardOnFileForm'));
  }

  submitCardOnFileForm(values) {
    const { handleSubmit } = this.props;
    return handleSubmit(this.handleCardSubmit.bind(this, values))();
  }

  closeCardErrorModal () {
    const { dispatch } = this.props;
    dispatch(setModalVisibility('hidden'));
  }

  handleError(e) {
    const errorMessage = e?.message || JSON.stringify(e);
    toastr.error('Error', errorMessage, {
      timeOut: 3000,
      removeOnHover: false,
      icon: 'error',
      status: 'error'
    });
    Bugsnag.notify(errorMessage);
  }

  async handleCardSubmit (cardOnFileValues) {
    const { onSubmit, customerDetailFormValues, customers, merchantSettings } = this.props;
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const paymentMethods = FilterUtil.filterPaymentMethodsAch(customers?.selectedCustomer?.payment_methods, achEnabled);

    let newCardOnFileValues = { ...cardOnFileValues };
    let paymentMethodsToKeep = paymentMethods?.length > 0 ? [...paymentMethods]: [];

    if (paymentMethodsToKeep.length === 0) newCardOnFileValues = { ...cardOnFileValues, is_default: true };

    this.setState({
      paymentMethodDialog: false,
    });
    try {
     await onSubmit({
          ...customerDetailFormValues,
          id: customers?.selectedCustomer.id,
          ['payment_methods']: [newCardOnFileValues] },
        true
      );
    } catch (e) {
     this.handleError(e);
    }
  }

  closeDeleteDialog() {
    this.setState({ openDeleteDialog: false });
  }

  async removePaymentMethodHelper() {
    const { paymentMethodIdToRemove } = this.state;
    const { paymentMethodId, type } = paymentMethodIdToRemove;
    const {  customers: { selectedCustomer }, dispatch, user, history } = this.props;
    this.setState({ openDeleteDialog: false });
    try {
      await dispatch(deletePaymentMethod(user, selectedCustomer, paymentMethodId, type));
    } finally {
      dispatch(getCustomer(user, selectedCustomer.id, selectedCustomer.is_pa_customer));
    }
  }

  removePaymentMethod(paymentMethodId, type) {
    this.setState({
      openDeleteDialog: true,
      paymentMethodIdToRemove: {paymentMethodId, type}
    });
  }

  openCardOnFile() {
    const { dispatch, customers: { selectedCustomer }, user } = this.props;

    if (UserUtil.isNorthDemoAccount(user)) {
      dispatch(toggleNorthGatekeeper(true));
      return;
    }

    dispatch(setCustomer({
      ...selectedCustomer,
      adding_cards: true
    }));

    this.setState({ paymentMethodDialog: true, triggerOnTouch: true });
  }

  renderPaymentMethods() {
    const { customers, user, t, initialValues, customerDetailFormValues, isReadOnly, merchantSettings } = this.props;
    const { paymentCards } = this.state;

    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const paymentMethods = FilterUtil.filterPaymentMethodsAch(paymentCards, achEnabled);

    const currentValues = customerDetailFormValues || initialValues;
    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);
    const addPaymentMethodDisabled = !_.isEmpty(Validator.validateCustomerDetailForm(currentValues));
    const infoIcon = IconUtil.getIcon('InfoIcon', LabelUtil.getLabelColor());
    const tooltip = (<Tooltip
      title={t('CustomerDetailForm.AddCC')}>
      <p>{infoIcon}</p>
    </Tooltip>);

    if (customers?.selectedCustomer?.isFetching) {
      return (
        <div className='paymentMethodsContainer loader'>
          <UpdateSpinner size={45} thickness={5} className='spinner' classes='customerSpinner'/>
        </div>
      );
    }

    const addPaymentMethodLink = isPremiumPlusAccount && !isReadOnly ? (
      <div className='paymentMethodsContainer'>
        <Button
          onClick={this.openCardOnFile}
          label={t('CustomerDetailForm.AddPaymentMethod')}
          disabled={addPaymentMethodDisabled}
          id='openCardOnFile'
          variant='text'
          startIcon={IconUtil.getIcon('AddIcon', LabelUtil.getLabelColor(), '14px')}
          labelStyle={{
            marginLeft: '16px',
            fontSize: '15px !important',
            fontWeight: '600'
          }}
        />
        {addPaymentMethodDisabled && tooltip}
      </div>
    ) : null;

    const renderedPaymentMethods = paymentMethods?.map((paymentMethod) => {

      const {id, type} = paymentMethod;

      return (
        <Box
          key={`payment_method_${id}`}
        >
          <PaymentMethodSummary
            backgroundColor='#F2F2F2'
            paymentMethod={paymentMethod}
            showEditButton={false}
            showDefault={paymentMethod?.is_default}
            dropDownOnClick={() => this.removePaymentMethod(id, type)}
            showIcon={true}
            customDropDownIcon='DeleteIcon'
            t={t}
          />
        </Box>
      );
    }).filter(card => card);

    const renderedPaymentMethodsLength = renderedPaymentMethods?.length;

    const paymentMethodsWithAddLink = (
      <div className='cardsWrap' >
        {renderedPaymentMethods}
        {renderedPaymentMethodsLength < 3 && isPremiumPlusAccount && !isReadOnly ?
          <div className='flexContainer addPaymentMethodExisting'>
            <a onClick={this.openCardOnFile} disabled={addPaymentMethodDisabled} data-test-id='linkAddPaymentMethod'>
              <Typography variant='regularBodySemiBold' color={LabelUtil.getLabelColor()}>
                {t('CustomerDetailForm.AddPaymentMethod')}
              </Typography>
            </a>
            {addPaymentMethodDisabled && tooltip}
          </div>: null
        }
     </div>
    );

    return renderedPaymentMethodsLength > 0 ? paymentMethodsWithAddLink : addPaymentMethodLink;
  }

  submit() {
    const { handleSubmit, onSubmit } = this.props;
    this.setState({ paymentMethodDialog: false });

    handleSubmit((values) => {
      return onSubmit(values).catch(e => this.handleError(e));
    })();

  }

  async sendRewardEmail() {
    const { dispatch, customers, user, t } = this.props;
    this.setState({sendingRewardEmail: true});
    const customerId = customers.selectedCustomer.id
    const result = await dispatch(sendRewardEmail(user, customerId));
    this.setState({sendingRewardEmail: false});
    const notificationType = result.type === actionTypes.customerSendRewardEmailSuccess ? 'Success' : 'Error';
    const notificationMsg = result.type === actionTypes.customerSendRewardEmailSuccess ? t('LoyaltyPrograms.SendEmailSuccess') : t('LoyaltyPrograms.SendEmailFailure');
    toastr.success(notificationType, notificationMsg);
  }

  toggleCustomerAddress() {
    this.setState((prevState) => ({
      showCustomerAddress: !prevState.showCustomerAddress
    }));
  }

  render() {
    const {
      auth,
      auth: {
        isManager,
        isManagerActivity
      },
      initialValues,
      isLocked,
      hasAccountNumber,
      transactionCount,
      ratingCount,
      customers,
      t,
      user,
      userExperience,
      merchantSettings,
      submitting,
      isReadOnly
    } = this.props;

    const { sendingRewardEmail, openDeleteDialog, paymentMethodDialog, paymentCards, showCustomerAddress } = this.state;

    let hasActivityAccess = true;

    if (isManager) {
      hasActivityAccess = isManagerActivity;
    }

    const hasEmailAndPhoneNumber = !!initialValues?.email_addresses?.[0] || !!initialValues?.phone_number;
    const isValidEmailORisValidPhoneNumber = CustomerUtil.hasValidEmail(initialValues?.email_addresses) || CustomerUtil.hasValidPhoneNumber(initialValues?.phone_number);
    const isResendRewardEmailActive =  hasEmailAndPhoneNumber && isValidEmailORisValidPhoneNumber;
    const merchantName = UserUtil.getActiveAccount(user)?.dba_name;

    const { modalVisibility: { cardErrorDialogOpen, cardErrorMessage }} = userExperience;
    const { processingPaymentMethods } = customers;

    const deleteConfirmationDialog = (
      <ReskinMessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='deleteConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.removePaymentMethodHelper}
        onRequestClose={this.closeDeleteDialog}
        open={openDeleteDialog}
        scrollable={true}
        titleText={t('CustomerDetailForm.CardDeleteConfirmationDialog.Title')}
        bodyText={
          <p className='confirmationText'>{t('CustomerDetailForm.CardDeleteConfirmationDialog.Body')}</p>
        } />
    );

    const customerLoading = customers?.selectedCustomer?.isFetching;
    const appRoutePrefix = globalApplicationLabel.path;
    const customerTransactionsRoute = appRoutePrefix + routes.activity.root + routes.activity.transactions;
    const isPACustomer = customers.selectedCustomer?.is_pa_customer;
    const customerLastTransactionDate = customers.selectedCustomer?.last_transaction_date;
    const customerId = initialValues && initialValues.id;
    const transactionsQueryParam = isPACustomer ? `?paCustomerId=${customerId}&lastTransactionDate=${customerLastTransactionDate}` : `?customerId=${customerId}&lastTransactionDate=${customerLastTransactionDate}`;
    const hasRecurringInvoices = _.get(this.props, 'initialValues.inv_pk') || false;

    const transactionCountContainer = transactionCount && customerId ? (
      <Box sx={margin}>
        <Typography variant='label'>
          {t('CustomerDetailForm.TransactionHeader')}
        </Typography>
        <Box sx={detailsStyles} className='transactionCount'>
          <Typography className='detailDescriptionText' variant='regularBody'>
            {transactionCount}
          </Typography>
          {
            (hasActivityAccess && !customerLoading) ?
              <Box display='flex' alignItems='center'>
                <Link className='transactionCountButton' to={{pathname: customerTransactionsRoute, search: transactionsQueryParam}}>
                  <Typography variant='subtext'>
                    {t('CustomerDetailForm.SeeTransactions')}
                  </Typography>
                </Link>
                {IconUtils.getIcon('ChevronRightIcon')}
              </Box> : null
          }
        </Box>
      </Box>
    ) : null;

    const ratingContainer = customerId ? (
      <Box sx={padding}>
        <Typography variant='label'>
          {t('CustomerDetailForm.RatingHeader')}
        </Typography>
        <Box sx={customerDetailCardStyles} className='ratingContainer'>
          <Box className='averageRating'>

            <Typography variant='regularBody'
              className='detailsRow detailDescriptionText'
            >{`${ratingCount} Ratings`}</Typography>
            
            <div className='starContainer'>
              <div className='stars'>
                {_(5).times((idx) => {
                  if (idx < this.props.ratingAverage) {
                    return <span key={idx}>{IconUtils.getIcon('Star', LabelUtil.getLabelColor())}</span>
                  } else {
                    return (<span key={idx}>
                      {IconUtils.getIcon('StarBorder', LabelUtil.getLabelColor())}</span>)
                  }
                })}
              </div>
              <Typography variant='subtext' className='ratingText'>{t('CustomerDetailForm.RatingText')}</Typography>
            </div>
          </Box>
        </Box>
      </Box>
    ) : null;

    const recurringContainer = hasRecurringInvoices && customerId ? (
      <div className='recurringContainer detailDescription'>
        <div className='detailsRow detailsHeader'>{t('CustomerDetailForm.RecurringHeader')}</div>
        <RecurringInvoiceInfo
          data={initialValues}
          displayType={RecurringInvoiceInfoType.CUSTOMER}
          t={t}
        />
      </div>
    ) : null;

    const isLoading = submitting || processingPaymentMethods || sendingRewardEmail;
    const rewards = customers.selectedCustomer?.loyalty_vpc_status;
    const rewardAmount = rewards?.reward_amount;
    const rewardType = rewards?.reward_amount_type === 'dollar' ? '$' : '%';
    const rewardActive = rewards?.reward_is_eligible
    const loyaltyStatus = rewards || {};
    const isCsUser = UserUtil.isUserCS(user, auth);
    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);
    const isLoyalty = merchantSettings?.loyaltyVpc?.enabled && UserUtil.isLoyaltyEnabled(user, merchantSettings) && (isPremiumPlusAccount) && !!rewards?.points_to_earn_reward;
    const isCustomerEnrolledInLoyalty = rewards?.opted_in_loyalty;
    const achEnabled = merchantSettings?.merchantSettings?.ach_enabled;
    const statesList = countryStateList('United States');

    if (loyaltyStatus.reward_amount_type === 'dollar') {
      loyaltyStatus.dollar = '$';
      loyaltyStatus.percent = '';
    } else {
      loyaltyStatus.percent = '%';
      loyaltyStatus.dollar = '';
    }

    loyaltyStatus.points_label = (loyaltyStatus.points_name === 'points') ? t('LoyaltyPrograms.Points') : loyaltyStatus.points_name;
    loyaltyStatus.program_label = (loyaltyStatus.program_name === 'Loyalty Program') ? t('LoyaltyPrograms.TitleSingular') : loyaltyStatus.program_name || '';

    const hasPendingReward = rewardActive ? (
        <div className='rewardResend'>
          <Typography className='reward' variant='regularBody'>
            {`${rewardType === '$' ? `${rewardType}${rewardAmount}` : `${rewardAmount}${rewardType}`} ${t('LoyaltyPrograms.RewardEarned')}`}
          </Typography>
          <Typography className='methodUsed' variant='subtext' color='common.subtext' marginBottom={3}>
            {`${loyaltyStatus.program_label} ${rewards?.reward_expiration_date ? `${t('LoyaltyPrograms.LoyaltyExpires')} ${DateUtil.formatDate(rewards?.reward_expiration_date, 'M/D/YY')}`: ''}`}
          </Typography>
          <div className='actionContainer' onClick={this.sendRewardEmail} disabled={!isResendRewardEmailActive}>
            <Typography component='span' variant='regularBodySemiBold' color={LabelUtil.getLabelColor()}>
              {t('LoyaltyPrograms.ResendEmail')}
            </Typography>
          </div>
        </div>
    ) : null;

    return (
      <Form onSubmit={this.submit}>
        { deleteConfirmationDialog }
        { isLoading && (
          <UpdateSpinner size={80} thickness={5} className='spinner' />
        )}
        <Box sx={customerDetailStyles} className='customerDetail detailsContainer'>
          <Field
              component={CustomTextField}
              disabled={isLocked || isReadOnly || customerLoading}
              label={t('CustomerDetailForm.Fields.FirstName')}
              maxLength='50'
              name='first_name'
          />
          <Field
              component={CustomTextField}
              disabled={isLocked || isReadOnly || customerLoading}
              label={t('CustomerDetailForm.Fields.LastName')}
              maxLength='50'
              name='last_name'
          />
          <Field
              component={CustomTextField}
              label={t('CustomerDetailForm.Fields.PhoneNumber')}
              disabled={isReadOnly || customerLoading}
              maxLength='50'
              name='phone_number'
              normalize={TextUtil.formatPhoneNumber}
          />
          <br/>
          <FieldArray
              component={customerEmailAddresses}
              name='email_addresses'
              isFetching={customerLoading}
              disabled={isReadOnly}
              t={t}
          />

          { showCustomerAddress ?
            <div className='addressInfo' data-test-id='addressInfo'>
              <div className='customerStreet'>
                <div className='customerStreet1Field'>
                  <Field
                    component={CustomTextField}
                    name='address.street_address_1'
                    label={t('StreetAddress')}
                    hintText={t('EnterStreetAddress1')}
                    disabled={isReadOnly || customerLoading}
                  />
                </div>

                <div className='customerStreet2Field'>
                  <Field
                    component={CustomTextField}
                    name='address.street_address_2'
                    label={t('StreetAddress2')}
                    hintText={t('EnterStreetAddress2')}
                    disabled={isReadOnly || customerLoading}
                  />
                </div>
              </div>

              <div className='customerCity'>
                <Field
                  component={CustomTextField}
                  name='address.city'
                  label={t('City')}
                  hintText={t('EnterCity')}
                  disabled={isReadOnly || customerLoading}
                />
              </div>

              <div className='customerStateZip'>

                <div className='customerState'>
                  <Field
                    className='textField'
                    component={Autocomplete}
                    defaultValue={statesList.find(state => customers.selectedCustomer?.address?.state === state.key)}
                    label={t('BusinessForm.State')}
                    name='address.state'
                    options={statesList}
                    isClearable
                    t={t}
                  />
                </div>

                <div className='customerZip'>
                  <Field
                    component={CustomTextField}
                    name='address.zip'
                    label={t('Zip')}
                    hintText={t('EnterZipCode')}
                    disabled={isReadOnly || customerLoading}
                    normalize={TextUtil.formatZip}
                  />
                </div>

              </div>
            </div>
          : null }

          <a
            className={`customerAddressToggle linkLike ${customerLoading ? 'disabled' : ''}`}
            data-test-id='customerAddressToggle'
            onClick={customerLoading ? null : this.toggleCustomerAddress}
          >{showCustomerAddress
              ? t('HideCustomerAddress')
              : t('ShowCustomerAddress')
          }</a>

          <br/>

          {(isPremiumPlusAccount || paymentCards?.length) ?
            <Typography className='ccTitle' variant='label'>
              {t('CustomerDetailForm.SavedPaymentMethods')}
            </Typography>
          : null}
          { this.renderPaymentMethods() }
          { paymentMethodDialog && <Modal
            cancelText={t('Cancel')}
            confirmText={t('Save')}
            isConfirmDisabled={isCsUser}
            maxWidth='md'
            externalClassName='paymentDialog'
            onClose={this.closePaymentModal}
            onConfirm={this.confirmCardOnFileForm}
            open={true}
            title={t('CardOnFile.Title')}
            subtitle={t('CardOnFile.SubTitle')}
          >
            <CardOnFileForm
              onSubmit={this.submitCardOnFileForm}
              showAvsNotice={true}
              showAcknowledgement={true}
              ignoreAvsFailure
              achEnabled={achEnabled}
              merchant={merchantName}
              isManagingPaymentMethods
            />
          </Modal>
          }
          {cardErrorDialogOpen && <Modal
            hideCancelButton
            confirmText={t('Okay')}
            maxWidth='md'
            onConfirm={this.closeCardErrorModal}
            open={cardErrorDialogOpen}
            title={t('CustomerDetailForm.AddPaymentMethodErrorTitle')}
            subtitle={`(${cardErrorMessage}) ${t('CustomerDetailForm.AddPaymentMethodError')}`}
          />}
          {auth && auth.debtRepayment ?
            (
              <Field
                  component={CustomTextField}
                  disabled={hasAccountNumber || customerLoading || isReadOnly}
                  label={t('CustomerDetailForm.Fields.AccountNumber')}
                  maxLength='100'
                  name='account_number'
              />
            ) : null
          }
          {transactionCountContainer}
          {recurringContainer}
          {isLoyalty && isCustomerEnrolledInLoyalty && <Box sx={padding}>
            <Typography variant='label' sx={availableRewards}>
              {t('AvailableRewards')}
            </Typography>
            <Box className='loyaltyStatusContainer'>
              {hasPendingReward}
              {!rewardActive && <div className='loyaltyStatus'>
                <p className='loyaltyStatusTitle'>{loyaltyStatus.program_label}</p>
                <p>{t('LoyaltyPrograms.LoyaltyStatus', loyaltyStatus)}</p>
              </div>}
            </Box>
          </Box>}
          {ratingContainer}
        </Box>
      </Form>
    )
  }
}

let CustomerDetail = reduxForm({
  form: 'customerDetailForm',
  validate,
  fields: ['id', 'first_name', 'last_name', 'phone_number', 'email_addresses',
    'transaction_count', 'account_number', 'address.street_address_1',
    'address.street_address_2', 'address.city', 'address.zip', 'address.state'
  ],
  enableReinitialize: true
})(CustomerDetailContainer);

function mapStateToProps(state, ownProps) {
  const customerDetailFormValues = getFormValues('customerDetailForm')(state);

  const selectedCustomer = state?.customers?.selectedCustomer || {};

  const formCustomerEmails = customerDetailFormValues?.email_addresses?.filter(email => email !== null)
  const formCustomerPhone = customerDetailFormValues?.phone_number;

  const pristineCustomerEmails = selectedCustomer?.email_addresses;
  const pristineCustomerPhone = selectedCustomer?.phone_number;

  let customerEmails = CommonUtil.checkLength(formCustomerEmails);

  if (!customerEmails.length) {
    customerEmails = CommonUtil.checkLength(pristineCustomerEmails);
  }

  const formAddress = customerDetailFormValues?.address || {};
  const selectedCustomerAddress = selectedCustomer?.address || {};

  const street_address_1 = getFieldValue(formAddress, selectedCustomerAddress, 'street_address_1');
  const street_address_2 = getFieldValue(formAddress, selectedCustomerAddress, 'street_address_2');
  const customer_city = getFieldValue(formAddress, selectedCustomerAddress, 'city');
  const customer_state = getFieldValue(formAddress, selectedCustomerAddress, 'state');
  const customer_zip = getFieldValue(formAddress, selectedCustomerAddress, 'zip');


  const customer = {
    ...selectedCustomer,
    first_name: getFieldValue(customerDetailFormValues, selectedCustomer, 'first_name'),
    last_name: getFieldValue(customerDetailFormValues, selectedCustomer, 'last_name'),
    phone_number: (formCustomerPhone || formCustomerPhone === '' ) ? formCustomerPhone : TextUtil.formatPhoneNumber(pristineCustomerPhone),
    email_addresses:  customerEmails || [null],
    adding_cards: state?.customers?.selectedCustomer?.adding_cards,
    address: {
      street_address_1,
      street_address_2,
      city: customer_city,
      state: customer_state,
      zip: customer_zip
    }
  };

  const hasTransactions = !!(customer && customer.transaction_count);
  const transactionCount = hasTransactions ? TextUtil.formatNumberWithTrailingText(customer.transaction_count, 'Transactions') : null;

  const ratingCount = customer && customer.ratings ? customer.ratings.length : 0;
  const ratingAverage = customer && _.mean(customer.ratings);

  const cardOnFileFormValues = getFormValues('cardOnFileForm')(state);

  let initialValues = customer;

  return {
    initialValues,
    transactionCount: transactionCount,
    ratingCount: ratingCount,
    ratingAverage: ratingAverage,
    isLocked: hasTransactions,
    hasAccountNumber: !!(customer && customer.account_number),
    customerDetailFormValues,
    cardOnFileFormValues
  };
}

CustomerDetail = connect(mapStateToProps)(CustomerDetail);

export default CustomerDetail;

