/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, {Component} from 'react';
import { Box } from '@mui/material';
import IconUtil from '../../util/IconUtil';
import Loading from '../../Loading';
import NoData from '../../NoData';
import CsvUtil from '../../util/CsvUtil';
import ReportUtil from '../../util/ReportUtil';
import Button from '../../shared/Button';
import { tableStyles, expandedTransactionReportStyles } from '../../../jss/table';
import MobileScrollNote from '../../shared/MobileScrollNote';

export default class PaymentLinksReport extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handlePrintViewClick = this.handlePrintViewClick.bind(this);
  }

  handlePrintViewClick() {
    let reportWindow = window.open('about:blank');
    const csvData = CsvUtil.createPaymentLinksCsv(this.props.reports?.paymentLinks);
    const html = ReportUtil.createHtmlFromCsvReport('Payment Links Report', this.props, csvData);
    if (!!reportWindow?.document) {
      reportWindow.document.write(html);
      reportWindow.document.title = 'Payment Links Report';
      ReportUtil.windowBindListeners(reportWindow);
    }
  }

  handleClick() {
    this.props.exportReport('PaymentLinksReport', this.props);
  }

  render() {

    const {isFetching, reports, t} = this.props;
    const paymentLinksData = reports.paymentLinks;

    const isEmpty = paymentLinksData === null;
    const printIcon = IconUtil.getIcon('PrintIcon');
    const exportIcon = IconUtil.getIcon('LaunchIcon');

    if (isEmpty && isFetching) {
      return (
        <Loading/>
      )
    } else {
      if (paymentLinksData.length > 0) {
        const paymentLinksJson = CsvUtil.createPaymentLinksCsv(paymentLinksData);

        return(
          <Box sx={expandedTransactionReportStyles} data-test-id={'reportContainer'}>
            <div className='exportContainer'>
              <div className='downloadLink'>
                <Button variant='link' className='printButton' onClick={this.handlePrintViewClick} startIcon={printIcon} label={t('Print')} />
                <Button variant='link' className='exportButton' onClick={this.handleClick} startIcon={exportIcon} label={t('Export')} />
              </div>
            </div>

            <MobileScrollNote t={t} />

            <div className='outerTableContainer'>
              <div className='innerTableContainer'>
                <Box sx={tableStyles}>
                  <div className='flexRowHeader hideMobile'>
                    <div>{'DATE'}</div>
                    <div>{'TRANSACTION SOURCE'}</div>
                    <div>{'TRANSACTION TYPE'}</div>
                    <div>{'ACCOUNT NUMBER'}</div>
                    <div>{'DBA'}</div>
                    <div>{'PAYMENT LINK'}</div>
                    <div>{'SOLD BY'}</div>
                    <div>{'INVOICE'}</div>
                    <div>{'CUSTOMER NAME'}</div>
                    <div>{'TOTAL TRANSACTION AMOUNT'}</div>
                    <div>{'PAYMENT AMOUNT'}</div>
                    <div>{'AUTHORIZED AMOUNT'}</div>
                    <div>{'TIP'}</div>
                    <div>{'TAX'}</div>
                    <div>{'CASH DISCOUNTING AMOUNT'}</div>
                    <div>{'PAYMENT TYPE'}</div>
                    <div>{'CARD BRAND'}</div>
                    <div>{'FIRST 6'}</div>
                    <div>{'LAST 4'}</div>
                    <div>{'CUSTOM FIELD'}</div>
                  </div>

                  {paymentLinksJson.map((paymentLink, i) => {
                    return (
                      <div key={i} className='flexRowItem hideMobile'>
                        <div>{paymentLink['Date']}</div>
                        <div>{paymentLink['Transaction Source']}</div>
                        <div>{paymentLink['Transaction Type']}</div>
                        <div>{paymentLink['Account Number']}</div>
                        <div>{paymentLink['DBA']}</div>
                        <div>{paymentLink['Payment Link']}</div>
                        <div>{paymentLink['Sold By']}</div>
                        <div>{paymentLink['Invoice']}</div>
                        <div>{paymentLink['Customer Name']}</div>
                        <div>{paymentLink['Total Transaction Amount']}</div>
                        <div>{paymentLink['Payment Amount']}</div>
                        <div>{paymentLink['Authorized Amount']}</div>
                        <div>{paymentLink['Tip']}</div>
                        <div>{paymentLink['Tax']}</div>
                        <div>{paymentLink['Cash Discounting Amount']}</div>
                        <div>{paymentLink['Payment Type']}</div>
                        <div>{paymentLink['Card Brand']}</div>
                        <div>{paymentLink['First 6']}</div>
                        <div>{paymentLink['Last 4']}</div>
                        <div>{paymentLink['Custom Field']}</div>
                      </div>
                    );
                  })}
                </Box>
              </div>
            </div>
          </Box>
        )
      } else {
        return (<NoData text={t('NoDataPaymentLinksReport')} />)
      }
    }
  }
}

PaymentLinksReport.defaultProps = {
  isFetching: true,
};

