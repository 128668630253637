/**
 North American Bancard ('NAB') CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { accordionSummary, chipStyles, featureIcon, featuresListContainer, planCardActivationStyles } from '../../../jss/planCardActivationStyles';

const PlanCardActivation = ({t, plan, selected, onClick}) => {

  return (
    <Paper
      elevation={0}
      onClick={onClick}
      sx={planCardActivationStyles(selected)}
    >
      <Stack
        spacing={0}
        direction='row'
        marginBottom='25px'
        display='flex'
        justifyContent='center'
      >
        <Chip
          label={t(plan?.type)}
          size='small'
          color='primary'
          sx={chipStyles}
        />
      </Stack>
      <Box
        component='div'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Typography
          variant='primaryFontFamilyBold'
          component='h1'
          m='0'
          fontSize='30px'
        >
          {t(plan?.price)}
        </Typography>
        <Typography
          variant='primaryFontFamilySemiBold'
          component='h2'
          m='0'
          fontSize='17px'
        >
          {t(plan?.heading)}
        </Typography>
        <Typography
          variant='primaryFontFamilySemiBold'
          component='h3'
          m='0'
          fontSize='14px'
          color='primary.main'
        >
          {t(plan?.subHeading)}
        </Typography>
      </Box>
      <List
        sx={featuresListContainer}
      >
        {plan?.features?.map((feature, index) =>
          <ListItem
            key={`feature${index}`}
            sx={{
              padding: '0',
              marginTop: feature?.type === 'plus' ? '20px' : '0'
            }}
          >
            <ListItemIcon
              sx={featureIcon}
            >
              {feature?.type === 'plus' ? <AddSharpIcon/> : <CheckSharpIcon/>}
            </ListItemIcon>
            <Typography
              variant='primaryFontFamilyMedium'
              component='p'
              m='0'
              fontSize='14px'
            >
              {t(feature?.text)}
            </Typography>
          </ListItem>
        )}
      </List>
      {plan?.seeMore && <Accordion className='seeMoreAccordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          id={`panel-header-${plan.type}`}
          sx={accordionSummary}
        >
          {t(plan.seeMore.heading)}
        </AccordionSummary>
        <AccordionDetails>
          {t(plan.seeMore.text)}
        </AccordionDetails>
      </Accordion>}
    </Paper>
  );

}

export default PlanCardActivation;
