import React from 'react';
import Box from '@mui/material/Box';
import IconUtils from '../util/IconUtil';
import Image from '../shared/Image'

const ChargezoomCard = ({isFeatured, toggleShouldShowChargezoomDetails, cardRefs, tagsContainer, isChargezoomIntegrated, t}) => (
    <Box
        className={isFeatured ? 'featuredCard' : 'card'}
        id={'chargeZoomCard'}
        onClick={toggleShouldShowChargezoomDetails}
        ref={cardRefs['chargeZoomCard']}
    >
      {isFeatured && <img
          alt={'header'}
          className={'cardHeader'}
          src={`${serverDomainUrl}images/chargezoom/header.png`}
      />}
      <div className={'content'}>
        {isFeatured ? <div className={'logoContainer'}>
          <img
              alt={'Chargezoom'}
              className={'logo'}
              src={`${serverDomainUrl}images/chargezoom/logo.png`}
          />
        </div> :
        <Image
            alt={'Chargezoom logo'}
            src={'/images/chargezoom/logo.png'}
            sx={{ width: '150px', paddingTop: '16px' }}
        />}
        <p>{t('IntegrationsPage.Chargezoom.Card.Text')}</p>
        {tagsContainer([t('IntegrationsPage.Tags.Accounting')])}
        {isChargezoomIntegrated && (
          <div className={'connected'}>
            <div className={'iconWrapper'}>
              {IconUtils.getIcon('CheckIcon', 'white')}
            </div>
          </div>
        )}
      </div>
    </Box>
  );

  export default ChargezoomCard;