/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */


export default {

  whatsNew: {
    message: null, // Replace null with message when we want this turned on again.
    title: 'Notice',
  },
  maintenance: {
    siteMessages: [
      'SiteMessage1',
      'SiteMessage2'
    ],
    registrationMessage: [],
    title: 'MaintenanceTitle',
  },
  feedback: {
    message: 'FeedbackMessage',
    thanks: 'FeedbackThanks'
  },
  tooLarge: {
    message: 'TooLargeMessage',
  },
  tooLargeTransactions: {
    message: 'TooLargeTransactionsMessage',
  },
  employeeMBP: {
    owner: {
      access: 'EmployeeFullAccess',
      description: 'EmployeeMBPOwnerDescription'
    },
    admin: {
      access: 'EmployeeFullAccess',
      description: 'EmployeeMBPAdminDescription'
    },
    manager: {
      access: 'EmployeeMidLevelAccess',
      description: 'EmployeeMBPManagerDescription',
      options: {
        activity: {
          title: 'EmployeeActivityAccess',
          description: 'EmployeeMBPManagerOptionActivityDescription'
        },
        onlinePayments: {
          title: 'EmployeeVirtualTerminalAccess',
          description: 'EmployeeMBPManagerOptionOnlinePaymentsDescription'
        },
        invoiceActions: {
          title: 'EmployeeInvoicesAccess',
          description: 'EmployeeMBPManagerOptionInvoiceActionsDescription'
        }
      },
    },
    report: {
      access: 'EmployeeActivityOnlyAccess',
      description: 'EmployeeMBPReportDescription'
    },
    staff: {
      access: 'EmployeeLimitedAccess',
      description: 'EmployeeMBPStaffDescription'
    },
  },
  employee: {
    owner: {
      access: 'EmployeeFullAccess',
      description: 'EmployeeOwnerDescription'
    },
    admin: {
      access: 'EmployeeFullAccess',
      description: 'EmployeeAdminDescription'
    },
    manager: {
      access: 'EmployeeMidLevelAccess',
      description: 'EmployeeManagerDescription',
      options: {
        activity: {
          title: 'EmployeeActivityAccess',
          description: 'EmployeeManagerOptsActivityDescription'
        },
        onlinePayments: {
          title: 'EmployeeVirtualTerminalAccess',
          description: 'EmployeeManagerOptsOnlinePaymentsDescription'
        },
        invoiceActions: {
          title: 'EmployeeInvoicesAccess',
          description: 'EmployeeManagerOptsInvoiceActionsDescription'
        },
        paymentLinkActions: {
          title:'EmployeePaymentLinksAccess',
          description: 'EmployeeManagerOptsPaymentLinkActionsDescription'
        },
        reputationActions: {
          title: 'EmployeeReputationManagementAccess',
          description: 'EmployeeManagerOptsReputationActionsDescription'
        }
      },
    },
    report: {
      access: 'EmployeeActivityOnlyAccess',
      description: 'EmployeeReportDescription'
    },
    cashier: {
      access: 'EmployeeLimitedAccess',
      description: 'EmployeeCashierDescription'
    },
    staff: {
      access: 'EmployeeLimitedAccess',
      description: 'EmployeeStaffDescription'
    },
    cs: {
      access: 'EmployeeCsAccess',
      description: 'EmployeeCsDescription'
    },
    duplicateEmployeeError: 'DuplicateEmployeeError'
  },
  invoice: {
    generatedNumber: 'InvoiceGeneratedNumber',
    confirmEndSeries: 'InvoiceConfirmEndSeries',
    sendToCustomer: 'InvoiceSendToCustomer'
  },
  autopay: {
    confirmRemoveSeriesAutopay: 'AutopayConfirmRemoveSeriesAutopay',
    confirmRemoveAutopay: 'AutopayConfirmRemoveAutopay'
  },
  validator: {
    requiredText: 'Validator.RequireText',
    alphaNumericRequired: 'Validator.AlphaNumericRequired',
    incorrectPartialAmount: 'Validator.IncorrectPartialAmount',
    accessMustBeSelected: 'Validator.AccessMustBeSelected',
    invalidPhoneNumber: 'Validator.InvalidPhoneNumber',
    invalidMobileNumber: 'Validator.InvalidMobileNumber',
    customerEquityOwnershipMore: 'Validator.customerEquityOwnershipMore',
    customerEquityOwnershipLess: 'Validator.customerEquityOwnershipLess',
    customerEquityOwnershipZero: 'Validator.customerEquityOwnershipZero',
    customerEquityOwnershipPrimary: 'Validator.customerEquityOwnershipPrimary',
    customerOwnershipPrimary: 'Validator.customerOwnershipPrimary',
    invalidEmail: 'Validator.InvalidEmail',
    invalidAmountEmail: 'Validator.InvalidEmailAmount',
    invalidAmountRange: 'Validator.InvalidAmountRange',
    invalidDateRange: 'Validator.InvalidDateRange',
    invalidPreviousDateRange: 'Validator.InvalidPreviousDateRange',
    invalidWebDomain: 'Validator.InvalidWebDomain',
    httpsRequired: 'Validator.HttpsRequired',
    previousRangeIdentical: 'Validator.PreviousRangeIdentical',
    passwordsDoNotMatch: 'Validator.PasswordsDoNotMatch',
    passwordsDoNotMatchNew: 'Validator.PasswordsDoNotMatchNew',
    invalidPassword: 'Validator.InvalidPassword',
    emailsDoNotMatch: 'Validator.EmailsDoNotMatch',
    emailOrPhoneRequired: 'Validator.EmailOrPhoneRequired',
    amountRequired: 'Validator.AmountRequired',
    expirationDateInvalid: 'Validator.ExpirationDateInvalid',
    expirationDateRequired: 'Validator.ExpirationDateRequired',
    expirationDateExpired: 'Validator.ExpirationDateExpired',
    creditCardRequired: 'Validator.CreditCardRequired',
    creditCardInvalid: 'Validator.CreditCardInvalid',
    debitCardRequired: 'Validator.DebitCardRequired',
    debitCardInvalid: 'Validator.DebitCardInvalid',
    debitCardInvalidForInstantFunding: 'Validator.DebitCardInvalidForInstantFunding',
    creditCardInvalidForDebtRepayment: 'Validator.CreditCardInvalidForDebtRepayment',
    securityCodeRequired: 'Validator.SecurityCodeRequired',
    securityCodeInvalid: 'Validator.SecurityCodeInvalid',
    billingStreetNumberRequired: 'Validator.BillingStreetNumberRequired',
    billingStreetNumberInvalid: 'Validator.BillingStreetNumberInvalid',
    invalidStreet1: 'Validator.InvalidStreet1',
    requiredStreet1: 'Validator.RequiredStreet1',
    requiredState: 'Validator.RequiredState',
    requiredCity: 'Validator.RequiredCity',
    zipRequired: 'Validator.ZipRequired',
    zipInvalid: 'Validator.ZipInvalid',
    zipRequiredWithZipPlusFour: 'Validator.ZipRequiredWithZipPlusFour',
    zipShouldBeFiveDigits: 'Validator.ZipShouldBeFiveDigits',
    zipPlusFourShouldBeFourDigits: 'Validator.ZipPlusFourShouldBeFourDigits',
    nameOnCardRequired: 'Validator.NameOnCardRequired',
    firstAndLastNameRequired: 'Validator.FirstAndLastNameRequired',
    firstOrLastNameRequired: 'Validator.FirstOrLastNameRequired',
    emailRequired: 'Validator.EmailRequired',
    dateCannotBeInPast: 'Validator.DateCannotBeInPast',
    recurringEndRequiredAndNonZero: 'Validator.RecurringEndRequiredAndNonZero',
    recurringRepeatRequiredAndNonZero: 'Validator.RecurringRepeatRequiredAndNonZero',
    recurringRepeatFrequencyRequired: 'Validator.RecurringRepeatFrequencyRequired',
    recurringEndLessThanStart: 'Validator.RecurringEndLessThanStart',
    invalidName: 'Validator.InvalidName',
    invalidPrice: 'Validator.InvalidPrice',
    invalidQuantity: 'Validator.InvalidQuantity',
    invalidDate: 'Validator.InvalidDate',
    invalidUnderage: 'Validator.InvalidUnderage',
    invalidBarCode: 'Validator.InvalidBarCode',
    invalidOneOrMorePrice: 'Validator.InvalidOneOrMorePrice',
    missingModifiersError: 'Validator.MissingModifiersError',
    targetValueRequired: 'Validator.TargetValueRequired',
    closedAccount: 'Validator.ClosedAccount',
    closedAccountTitle: 'Validator.ClosedAccountTitle',
    invalidAccountNumber: 'Validator.InvalidAccountNumber',
    abaInvalidNumber: 'Validator.AbaInvalidNumber',
    invalidRoutingNumber: 'Validator.InvalidRoutingNumber',
    routingNumberNotFound: 'Validator.RoutingNumberNotFound',
    invalidSSN: 'Validator.InvalidSSN',
    invalidSSNFormat: 'Validator.InvalidSSNFormat',
    invalidFFSSN: 'Validator.InvalidFFSSN',
    accountNameLengthInvalid: 'Validator.AccountNameLengthInvalid',
    accountNumber: 'Validator.AccountNumber',
    accountNumberInvalid: 'Validator.AccountNumberInvalid',
    bankingAccountNumberInvalid: 'Validator.BankingAccountNumberInvalid',
    routingNumberInvalid: 'Validator.RoutingNumberInvalid',
    alreadyAttachedToAccount: 'Validator.AlreadyAttachedToAccount',
    ssnTaxId: 'Validator.SsnTaxId',
    principleNameError: 'Validator.PrincipleNameError',
    cashFixedAmount: 'Validator.CashFixedAmount',
    cashPercentAmount: 'Validator.CashPercentAmount',
    invalidOwnershipPercentage: 'Validator.InvalidOwnershipPercentage',
    invalidContactPriority: 'Validator.InvalidContactPriority',
    taxAmountMustBeGreaterThanZero: 'Validator.TaxAmountMustBeGreaterThanZero',
    taxAmountMustBeEqualGreaterThanZeroNoTaxable: 'Validator.TaxAmountMustBeEqualGreaterThanZeroNoTaxable',
    taxAmountMetaphorMustBeLessThanNinetyNine: 'Validator.TaxAmountMetaphorMustBeLessThanNinetyNine',
    taxAmountMantissaMustBeEqualOrLessThanThreeDigits: 'Validator.TaxAmountMantissaMustBeEqualOrLessThanThreeDigits',
    invalidDisputeNote: 'Validator.InvalidDisputeNote',
    invalidDisputeDocumentDescription: 'Validator.InvalidDisputeDocumentDescription',
    invalidURL: 'Validator.InvalidURL' ,
    northInvalidURL: 'Validator.NorthInvalidURL',
    invalidSecureURL: 'Validator.InvalidSecureURL',
    invalidFacebookHandle: 'Validator.InvalidFacebookHandle',
    invalidTwitterHandle: 'Validator.InvalidTwitterHandle',
    invalidInstagramHandle: 'Validator.InvalidInstagramHandle',
    invalidYelpHandle: 'Validator.InvalidYelpHandle',
    paymentTypeRequired: 'Validator.PaymentTypeRequired',
    paymentInputTypeRequired: 'Validator.PaymentInputTypeRequired',
    customerCodeRequired: 'Validator.CustomerCodeRequired',
    taxAmountRequired: 'Validator.TaxAmountRequired',
    taxIdRequired: 'Validator.TaxIdRequired',
    invalidTaxId: 'Validator.InvalidTaxId',
    itemDescriptionRequired: 'Validator.ItemDescriptionRequired',
    itemQuantityRequired: 'Validator.ItemQuantityRequired',
    measureUnitRequired: 'Validator.MeasureUnitRequired',
    unitPriceRequired: 'Validator.UnitPriceRequired',
    productCodeRequired: 'Validator.ProductCodeRequired',
    commodityCodeRequired: 'Validator.CommodityCodeRequired',
    requestorNameRequired: 'Validator.RequestorNameRequired',
    companyNameRequired: 'Validator.CompanyNameRequired',
    lengthOfFieldTooLong: 'Validator.LengthOfFieldTooLong',
    firstFiveSSNShouldBeFiveDigits: 'Validator.FirstFiveSSNShouldBeFiveDigits',
    cardInvalid: 'Validator.CardInvalid',
    cardAlreadyExists: 'Validator.CardAlreadyExists',
    tipType: 'Validator.TipType',
    tipValue: 'Validator.TipValue',
    amountMustBeInteger: 'Validator.AmountMustBeInteger',
    amountMustBeGreaterThanZero: 'Validator.AmountMustBeGreaterThanZero',
    amountMetaphorMustBeLessThanNinetyNine: 'Validator.AmountMetaphorMustBeLessThanNinetyNine',
    amountMantissaMustBeEqualOrLessThanThreeDigits: 'Validator.AmountMantissaMustBeEqualOrLessThanThreeDigits',
    refundQuantityExceedsOriginal: 'Validator.RefundQuantityExceedsOriginal',
    refundTipExceedsOriginal: 'Validator.RefundTipExceedsOriginal',
    unSelectedCategory: 'Validator.UnSelectedCategory',
    itemDiscountExceedsTotal: 'Validator.ItemDiscountExceedsTotal',
    expressItemNameRequired: 'Validator.ExpressItemNameRequired',
    expressItemPriceRequired: 'Validator.ExpressItemPriceRequired',
    expressItemTaxRateRequired: 'Validator.ExpressItemTaxRateRequired',
    integratedTokenMustNotHaveSpaces: 'Validator.IntegratedTokenMustNotHaveSpaces',
    duplicateDiscount: 'Validator.DuplicateDiscount',
    duplicateModifierSet: 'Validator.DuplicateModifierSet',
    duplicateIntegratedToken: 'Validator.DuplicateIntegratedToken',
    creditCardHolderNameRequired: 'Validator.CreditCardHolderNameRequired',
    invalidBillingStreetNumber: 'Validator.InvalidBillingStreetNumber',
    unselectedReportType: 'Please select the frequency with which you want to receive the report.',
	  unselectedDayOfTheMonth: 'Please select the day of the month you want to receive the report',
    unselectedDayOfTheWeek: 'Please select the day of the week you want to receive the report',
    rewardDollarAmountLimit: 'Validator.RewardDollarAmountLimit',
    rewardPercentAmountLimit: 'Validator.RewardPercentAmountLimit',
    minimumLargerThanMaximum: 'Validator.MinimumLargerThanMaximum',
    duplicatedPaymentAmounts: 'Validator.DuplicatedPaymentAmounts',
    amountBetweenMinMax: 'Validator.AmountBetweenMinMax',
    minWithoutMax: 'Validator.MinWithoutMax',
    maxAmountReached: 'Validator.MaxAmountReached',
    acceptTermsAndPrivacyPolicy: 'Validator.AcceptTermsAndPrivacyPolicy',
    leadSignupInvalidName: 'Validator.SignupFullNameRequired',
    leadSignupInvalidMobile: 'Validator.SignupInvalidMobile',
    leadSignupInvalidPassword: 'Validator.SignupInvalidPassword',
    leadSignupInvalidEmail: 'Validator.SignupInvalidEmail',
    leadSignupPasswordsDoNotMatch: 'Validator.SignupInvalidConfirmPassword',
    leadSignupAcceptTerms: 'Validator.SignupAcceptTerms',
    leadSignupAcceptPrivacyPolicy: 'Validator.SignupAcceptPrivacyPolicy',
    invalidPercent: 'Validator.InvalidPercent'
  },
  errors: {
    internalProcessingError: 'Errors.InternalProcessingError',
    voidRefundError: 'Errors.VoidRefundError',
    completePreAuthError: 'Errors.CompletePreAuthError',
    avs: {
      heading: 'Errors.Avs.Heading',
      message: 'Errors.Avs.Message'
    },
    avsPayInvoiceFailure: 'Errors.AvsPayInvoiceFailure',
    issueCreditFailure: 'Your refund could not be processed. Please check the card number, expiration date, and that your billing address matches what\'s\ on file and try again.',
    partialPayment: {
      heading: 'Errors.PartialPayment.Heading',
      message: 'Errors.PartialPayment.Message'
    },
    invoiceApiError: 'Errors.InvoiceApiError',
    invalidCSRF: 'Errors.InvalidCSRF',
    openTransactions: {
      tipExceedsLimit: 'OpenTransactions.TipExceedsLimit'
    },
    twoFactor: {
      rateLimit: 'Errors.TwoFactor.RateLimit'
    },
    funding: {
      rateLimit: 'Errors.Funding.RateLimit'
    }
  },
  todayFeedback: {
    heading: 'TodayFeedback.Heading',
    text: 'TodayFeedback.Text',
    linkText: 'TodayFeedback.LinkText'
  },
  legal: {
    headerA: 'Legal.HeaderA',
    paCopy1: 'Legal.PaCopy1',
    headerRequired: 'Legal.HeaderRequired',
    copyRequired: 'Legal.CopyRequired',
    userAgree: 'Legal.UserAgree',
    linkText: 'Legal.LinkText',
    fdLink: 'https://www.nabancard.com/fdnterms',
    hostedFD: '/files/FDterms.pdf',
    businessInformationChangeRequestform: '/files/Business_Change_Request_Form.pdf'
  },
  supplies: {
    pa: {
      agreeToDebit: 'Supplies.pa.AgreeToDebit'
    }
  },
  MBPRegistration: {
    alreadyRegisteredHeader: 'MBPRegistration.AlreadyRegisteredHeader',
    alreadyRegisteredContent: 'MBPRegistration.AlreadyRegisteredContent',
    unableToRegisterHeader: 'MBPRegistration.UnableToRegisterHeader',
    unableToRegisterContent: 'MBPRegistration.UnableToRegisterContent',
    successHeader: 'Success',
    successContent: 'MBPRegistration.SuccessContent',
    partialMatchHeader: 'MBPRegistration.PartialMatchHeader',
    partialMatchContent: 'MBPRegistration.PartialMatchContent',
    emailSentHeader: 'MBPRegistration.EmailSentHeader',
    emailSentContent: 'MBPRegistration.EmailSentContent',
    midUsedHeader: 'MBPRegistration.MidUsedHeader',
    midUsedContent: 'MBPRegistration.MidUsedContent',
    canRegisterMid: [
      'This account number does not exist in our system',
      'MBPRegistration.CanRegisterMid.Option2',
      'MBPRegistration.CanRegisterMid.Option3'
    ],
    helper: [
      'Check your most recent statement for your Merchant ID/Account Number.',
      'Enter the first name of the principal owner associated with the account.',
      'Enter the last name of the principal owner associated with the account.',
      'This email address will be your new Payments Hub Login'
    ]
  },
  MBPMigration: {
    formHeader: 'MBPMigration.FormHeader',
    formSubHeader: 'MBPMigration.FormSubHeader',
    successHeader: 'Success',
    successContent: 'MBPMigration.SuccessContent',
    partialSuccessHeader: 'MBPMigration.PartialSuccessHeader',
    partialSuccessContent: 'MBPMigration.PartialSuccessContent',
    alreadyMigratedHeader: 'MBPMigration.AlreadyMigratedHeader',
    alreadyMigratedContent: 'MBPMigration.AlreadyMigratedContent',
    failedMigrationHeader: 'MBPMigration.FailedMigrationHeader',
    failedMigrationContent: 'MBPMigration.FailedMigrationContent',
    noMbpUser: 'MBPMigration.NoMbpUser',
    noMbpUserMessage: 'MBPMigration.NoMbpUserMessage',
    fieldLabels: {
      mbpUsername: {
        label: 'MBPMigration.FieldLabels.MbpUsername.Label',
        hint: 'MBPMigration.FieldLabels.MbpUsername.Hint'
      },
      mbpPassword: {
        label: 'MBPMigration.FieldLabels.MbpPassword.Label',
        hint: 'MBPMigration.FieldLabels.MbpPassword.Hint'
      },
      emailAddress: {
        label: 'EmailAddress',
        hint: 'MBPMigration.FieldLabels.EmailAddress.Hint'
      },
      createPassword: {
        label: 'MBPMigration.FieldLabels.CreatePassword.Label',
        hint: 'MBPMigration.FieldLabels.CreatePassword.Hint'
      },
      verifyCreatePassword: {
        label: 'MBPMigration.FieldLabels.VerifyCreatePassword.Label',
        hint: 'MBPMigration.FieldLabels.VerifyCreatePassword.Hint'
      }
    },
    helper: [
      'This is the username for your current MyBizPerks account',
      'This is the password for your current MyBizPerks account',
      'This email address wil be your new Payments Hub Login'
    ],
    userAlreadyAttachedToMIDs: 'MBPMigration.UserAlreadyAttachedToMIDs'
  },
  addAccountDialog: {
    defaultError: 'AddAccountDialog.DefaultError',
    errorMap: {
      'Required fields were missing: mids': 'AddAccountDialog.ErrorsMap.MissingMids',
      'missingUserData': 'AddAccountDialog.ErrorsMap.MissingUserData',
      'midPrincipalsDoNotMatch': 'AddAccountDialog.ErrorsMap.MidPrincipalsDoNotMatch',
      'midAlreadyAttached': 'AddAccountDialog.ErrorsMap.MidAlreadyAttached',
      'midDoesNotExist': 'AddAccountDialog.ErrorsMap.MidDoesNotExist'
    },
    doNotAppendMids: ['Required fields were missing: mids', 'missingUserData']
  },
  accountSection: {
    credentials: {
      ph: 'AccountSection.Credentials.ph',
      pa: 'AccountSection.Credentials.pa',
      ps: 'AccountSection.Credentials.ps'
    },
    twoFactor: {
      headerText: 'AccountSection.TwoFactor.HeaderText',
      description: 'AccountSection.TwoFactor.Description',
    },
    giact: {
      accountType: 'AccountSection.Giact.AccountType',
      missingVerification: 'AccountSection.Giact.MissingVerification'
    }
  },
  user: {
    restrictedMbpAccess: 'User.RestrictedMbpAccess'
  },
  loadingReport: [
    'LoadingReport.Option1',
    'LoadingReport.Option2',
    'LoadingReport.Option3',
    'LoadingReport.Option4',
    'LoadingReport.Option5',
    'LoadingReport.Option6'
  ],
  transactionInfo: {
    title: 'TransactionInfo.Title',
    details: 'TransactionInfo.Details'
  },
  integrations: {
    feedback: 'IntegrationsPage.Feedback',
  },
  importItems: {
    uploadMessage: 'ImportItems.UploadMessage',
    maxFileSize: 'ImportItems.MaxFileSize',
    itemLimit: 'ImportItems.ItemLimit'
  },
  paymentshubTier: {
    premiumPrice: 'PaymentshubTier.PremiumPrice',
    optOutOfPremiumContent: 'PaymentshubTier.OptOutOfPremiumContent',
    upgradeToPremiumContent: 'PaymentshubTier.UpgradeToPremiumContent',
    accessInvoiceContentOwner: 'PaymentshubTier.AccessInvoiceContentOwner',
    accessInvoiceContentNonOwner: 'PaymentshubTier.AccessInvoiceContentNonOwner',
    accessSuppliesContent: 'PaymentshubTier.AccessSuppliesContent',
    accessReputationManagementContent: 'PaymentshubTier.AccessReputationManagementContent',
    accessLoyaltyProgramContent: 'PaymentshubTier.AccessLoyaltyProgramContent',
    notificationTitle: 'PaymentshubTier.NotificationTitle',
    notificationEpxContent: 'PaymentshubTier.NotificationEpxContent',
    notificationGlbContent: 'PaymentshubTier.NotificationGlbContent',
    notificationBaseContent: 'PaymentshubTier.NotificationBaseContent',
    successfullyEnrolled: 'PaymentshubTier.SuccessfullyEnrolled',
    successfullyOptedOut: 'PaymentshubTier.SuccessfullyOptedOut'
  },
  cardPresent: {
    activity: {
      connectionConnecting: 'CardPresent.Activity.ConnectionConnecting',
      missingDriver: 'CardPresent.Activity.MissingDriver',
      missingDevice: 'CardPresent.Activity.MissingDevice',
      connecting: 'CardPresent.Activity.Connecting',
      connected: 'CardPresent.Activity.Connected',
      disconnecting: 'CardPresent.Activity.Disconnecting',
      disconnected: 'CardPresent.Activity.Disconnected',
      unknown: 'CardPresent.Activity.Unknown',
      readyToUse: 'CardPresent.Activity.ReadyToUse',
      useCard: 'CardPresent.Activity.UseCard',
      isProcessing: 'CardPresent.Activity.IsProcessing',
      needsInput: 'CardPresent.Activity.NeedsInput',
      processed: 'CardPresent.Activity.Processed',
      transactionComplete: 'CardPresent.Activity.TransactionComplete',
      localAction: 'CardPresent.Activity.LocalAction',
      needsUpgrade: 'CardPresent.Activity.NeedsUpgrade'
    },
    activityDetails: {
      connectionConnecting: 'CardPresent.ActivityDetails.ConnectionConnecting',
      missingDriver: 'CardPresent.ActivityDetails.MissingDriver',
      missingDevice: 'CardPresent.ActivityDetails.MissingDevice',
      connecting: 'CardPresent.ActivityDetails.Connecting',
      connected: 'CardPresent.ActivityDetails.Connected',
      disconnecting: 'CardPresent.ActivityDetails.Disconnecting',
      disconnected: 'CardPresent.ActivityDetails.Disconnected',
      unknown: 'CardPresent.ActivityDetails.Unknown',
      readyToUse: 'CardPresent.ActivityDetails.ReadyToUse',
      processed: 'CardPresent.ActivityDetails.Processed',
      localAction: 'CardPresent.ActivityDetails.LocalAction',
      needsUpgrade: 'CardPresent.ActivityDetails.NeedsUpgrade'
    },
    activityActions: {
      download: 'CardPresent.ActivityActions.Download',
      connect: 'CardPresent.ActivityActions.Connect',
      upgradedConnect: 'CardPresent.ActivityActions.UpgradedConnect',
      startReader: 'CardPresent.ActivityActions.StartReader',
      cancelReader: 'Cancel',
      submitResponse: 'Submit'
    }
  },
  virtualTerminal: {
    isMobileTitle: 'VirtualTerminal.IsMobileTitle',
    isMobileContent: 'VirtualTerminal.IsMobileContent',
    cashDiscountInfo: 'VirtualTerminal.CashDiscountInfo'
  },
  refreshTokenDialog: {
    title: 'RefreshTokenDialog.Title',
    content: 'RefreshTokenDialog.Content',
    timeRemaining: 'RefreshTokenDialog.TimeRemaining'
  },
  refreshInvoiceToken: {
    title: 'RefreshInvoiceToken.Title',
    content: 'RefreshInvoiceToken.Content',
    buttonLabel: 'RefreshInvoiceToken.ButtonLabel'
  },
  refreshInvoiceLinkSent: {
    title: 'RefreshInvoiceLinkSent.Title',
    content: 'RefreshInvoiceLinkSent.Content',
  },
  bizSettings: {
    formTitle: 'BizSettings.FormTitle',
    formSubtitle: 'BizSettings.FormSubtitle'
  },
  appSettings: {
    formTitle: 'AppSettings.FormTitle',
    formSubtitle: 'AppSettings.FormSubtitle',
    closing: {
      manual: 'AppSettings.Closing.Manual',
      auto: 'AppSettings.Closing.Auto',
      manualPrintReport: 'AppSettings.Closing.ManualPrintReport',
      autoPrintReport: 'AppSettings.Closing.AutoPrintReport',
      manualCaptureTime: 'AppSettings.Closing.ManualCaptureTime'
    },
    cashDiscountDescription: 'AppSettings.CashDiscountDescription',
    edgeProgramDescription: 'AppSettings.EdgeProgramDescription',
    edgeDialogFirst: 'AppSettings.EdgeDialogFirst',
    edgeDialogSecond: 'AppSettings.EdgeDialogSecond',
    edgeTerms: 'AppSettings.EdgeTerms',
    edgeDialogFirstOptOut: 'AppSettings.EdgeDialogFirstOptOut',
    edgeTermsOptOut: 'AppSettings.EdgeTermsOptOut',
    cantDoEdge: 'AppSettings.CantDoEdge',
    cantDisableEdge: 'AppSettings.CantDisableEdge'
  },
  storeSettings: {
    formTitle: 'StoreSettings.FormTitle',
    formSubtitlePartOne: 'StoreSettings.FormSubtitlePartOne',
    formSubtitlePartTwo: 'StoreSettings.FormSubtitlePartTwo',
    formSubtitleLink: 'StoreSettings.FormSubtitleLink',
    formEnableStore: 'StoreSettings.FormEnableStore',
    formPreviewStore: 'StoreSettings.FormPreviewStore',
    formStoreURL: 'StoreSettings.FormStoreURL',
    formBusinessName: 'StoreSettings.FormBusinessName',
    formErrorsLabel: 'StoreSettings.FormErrorsLabel'
  },
  tin: {
    tinContent: 'Tin.TinContent',
    tinImportantText: 'Tin.TinImportantText',
    k1099Primary: 'Tin.K1099Primary',
    k1099Secondary: 'Tin.K1099Secondary',
    k1099Contact: 'Tin.k1099Contact',
    k1099Email: '1099K@paymentshub.com',
    k1099PolicyLink: 'https://generalmarketingdocs.s3.us-west-2.amazonaws.com/1099-K+Policy+Statement.pdf',
    k1099PolicyFaq: 'https://generalmarketingdocs.s3-us-west-2.amazonaws.com/1099-K+Policy+FAQ.pdf',
    k1099BestPractices: 'https://generalmarketingdocs.s3-us-west-2.amazonaws.com/1099-K+Best+Practices.pdf'
  },
  tinFaq: [
    {
      question: 'TinFaq.Question1',
      answer: [
        'TinFaq.Answer1.Option1',
        'TinFaq.Answer1.Option2'
      ]
    },
    {
      question: 'TinFaq.Question2',
      answer: [
        'TinFaq.Answer2.Option1'
      ]
    },
    {
      question: 'TinFaq.Question3',
      answer: [
        'TinFaq.Answer3.Option1'
      ]
    }
  ],
  twoFactorAuth: {
    header: 'TwoFactorAuth.Header',
    verification: {
      missingNumber: 'TwoFactorAuth.Verification.MissingNumber',
      error: 'TwoFactorAuth.Verification.Error',
      errorRetry: 'TwoFactorAuth.Verification.ErrorRetry'
    },
    setup: {
      request: 'errorRequestCodeSetup2fa',
      verify: 'errorVerifyCodeSetup2fa'
    }
  },
  banking: {
    editBankingNote: 'Banking.EditBankingNote',
    editButton: {
      settlement: 'Banking.EditButton.Settlement',
      chargeback: 'Banking.EditButton.Chargeback',
      billing: 'Banking.EditButton.Billing'
    },
  },
  tooltips: {
    banking: {
      settlement: 'Tooltips.Banking.Settlement',
      chargeback: 'Tooltips.Banking.Chargeback',
      billing: 'Tooltips.Banking.Billing'
    },
    owners: {
      support: 'Tooltips.Owners.Support'
    },
    paymentLinks: {
      addCustomFieldTitle: 'Tooltips.PaymentLinks.AddCustomFieldTitle'
    }
  },
  refunds: {
    refundExceedsLimit: 'RefundsMessages.RefundExceedsLimit',
    refundAmountExceedsLimit: 'RefundsMessages.RefundCustomAmountExceedsLimit',
    refundAll: 'RefundsMessages.RefundAll'
  },
  terms2FADialog: {
    header: 'Terms2FADialog.Header',
    merchantHaveNoPhoneNumber: 'Terms2FADialog.MerchantHaveNoPhoneNumber',
    descriptionWithPhone: 'Terms2FADialog.DescriptionWithPhone',
    descriptionNoPhone: 'Terms2FADialog.DescriptionNoPhone',
    processFinishedSuccess: 'Terms2FADialog.ProcessFinishedSuccess',
    processFinishedFailureByToken: 'Terms2FADialog.ProcessFinishedFailureByToken',
    processFinishedFailureByTokenAdd: 'Terms2FADialog.ProcessFinishedFailureByTokenAdd',
    processFinishedFailureByPhone: 'Terms2FADialog.ProcessFinishedFailureByPhone',
    processFinishedFailureByPhoneAdd: 'Terms2FADialog.ProcessFinishedFailureByPhoneAdd'
  },
  twoFactorTokenValidated: 'TWO_FACTOR_PHONE_VERIFY_SUCCESS',
  merchantVerifyByCSDialog: {
    title: 'MerchantVerifyByCSDialog.Title',
    text: 'MerchantVerifyByCSDialog.Text'
  },
  invoiceDetailModal: {
    sendReminder: {
      title: 'InvoiceDetailModal.SendReminder.Title',
      cancelText: 'InvoiceDetailModal.SendReminder.CancelText',
      confirmText: 'InvoiceDetailModal.SendReminder.ConfirmText',
      children: 'InvoiceDetailModal.SendReminder.Children'
    },
    sendInvoice: {
      title: 'InvoiceDetailModal.SendInvoice.Title',
      cancelText: 'InvoiceDetailModal.SendInvoice.CancelText',
      confirmText: 'InvoiceDetailModal.SendInvoice.ConfirmText',
      children: 'InvoiceDetailModal.SendInvoice.Children'
    },
    markAsPaid: {
      title: 'InvoiceDetailModal.MarkAsPaid.Title',
      confirmText: 'InvoiceDetailModal.MarkAsPaid.ConfirmText',
      cancelText: 'InvoiceDetailModal.MarkAsPaid.CancelText',
      children: 'InvoiceDetailModal.MarkAsPaid.Children'
    },
    editPaymentAmount: {
      title: 'InvoiceDetailModal.EditPaymentAmount.Title',
      subtitle: 'InvoiceDetailModal.EditPaymentAmount.SubTitle',
      confirmText: 'Save',
      cancelText: 'Cancel',
      children: null
    },
    cancelInvoice: {
      title: 'InvoiceDetailModal.CancelInvoice.Title',
      confirmText: 'InvoiceDetailModal.CancelInvoice.ConfirmText',
      cancelText: 'InvoiceDetailModal.CancelInvoice.CancelText',
      children: 'InvoiceDetailModal.CancelInvoice.Children'
    },
    cancelSeries: {
      title: 'InvoiceDetailModal.CancelSeries.Title',
      confirmText: 'InvoiceDetailModal.CancelSeries.ConfirmText',
      cancelText: 'InvoiceDetailModal.CancelSeries.CancelText',
      children: 'InvoiceDetailModal.CancelSeries.Children'
    },
    viewAllActivities: {
      title: 'InvoiceDetailModal.ViewAllActivities.Title',
      confirmText: 'InvoiceDetailModal.ViewAllActivities.ConfirmText',
      cancelText: 'InvoiceDetailModal.ViewAllActivities.CancelText',
      children: 'InvoiceDetailModal.ViewAllActivities.Children'
    },
  },
  quickbooksAccount: {
    title: 'Check your QuickBooks account',
    text: 'We\'ve detected an issue with your QuickBooks Online account. Check your account settings on the Quickbooks website.'
  },
  scheduleReportForm: {
    title: 'ScheduledReports.Title',
    text: 'ScheduledReports.Text'
  },
  paymentUtil: {
    noPaymentResponse: 'PaymentUtil.NoResponseErrorMessage'
  }
};
