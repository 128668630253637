/**

 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import IconUtil from '../../util/IconUtil';
import Loading from '../../Loading';
import NoData from '../../NoData';
import CsvUtil from '../../util/CsvUtil';
import ReportUtil from '../../util/ReportUtil';
import UserUtil from '../../util/UserUtil';
import SecondaryLoader from '../../SecondaryLoader';
import Button from '../../shared/Button';
import routes from '../../../constants/routes';
import { tableStyles, reportContainerStyles } from '../../../jss/table';

export default class DeclinedTransactionExport extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handlePrintViewClick = this.handlePrintViewClick.bind(this);
  }

  handlePrintViewClick() {
    let reportWindow = window.open('about:blank');
    const csvData = CsvUtil.createTransactionCsv(this.props.transactions.declinedReceipts, this.props.user, this.props.auth.debtRepayment, true);
    const html = ReportUtil.createHtmlFromCsvReport(' Declined Transactions', this.props, csvData);
    if (!!reportWindow?.document) {
      reportWindow.document.write(html);
      reportWindow.document.title = 'Declined Transactions Report';
      ReportUtil.windowBindListeners(reportWindow);
    }
  }

  async handleClick() {
    this.props.exportReport('DeclinedTransactions', this.props);
  }

  render() {
    const { transactions, user, auth, reports, t } = this.props;

    const printIcon = IconUtil.getIcon('PrintIcon');
    const exportIcon = IconUtil.getIcon('LaunchIcon');
    const isLargeProcessor = UserUtil.isLargeProcessor(user);

    if (reports.constructingCsv) {
      return (<SecondaryLoader {...this.props} />);
    } else if (transactions.isFetching) {
      return (
        <Loading/>
      );
    } else {
      if (transactions?.declinedReceipts?.length > 0) {

        let transactionList;
        let transactionListHeader = null;
        let printButton = null;

        const transactionPath = globalApplicationLabel.path + routes.activity.root + routes.activity.transactions;
        const selectedMid = UserUtil.getActiveAccount(user)?.mid;

        if (!isLargeProcessor) {

          const transactionsJson = CsvUtil.createTransactionCsv(transactions.declinedReceipts, user, auth.debtRepayment, true);

          printButton = (<Button variant='link' className='printButton' onClick={this.handlePrintViewClick} startIcon={printIcon} label={t('Print')} />)

          transactionListHeader = (
            <div className='flexRowHeader hideMobile'>
              <div>{'DATE'}</div>
              <div>{'ACCOUNT NUMBER'}</div>
              <div>{'INVOICE'}</div>
              <div>{'SOLD BY'}</div>
              <div>{'PLACED FOR'}</div>
              <div>{'CUSTOMER NAME'}</div>
              <div>{'TOTAL TRANSACTION AMOUNT'}</div>
              <div>{'PAYMENT AMOUNT'}</div>
              <div>{'AUTHORIZED AMOUNT'}</div>
              <div>{'TIP'}</div>
              <div>{'$DISCOUNT'}</div>
              <div>{'$TAX'}</div>
              <div>{'CASH DISCOUNTING AMOUNT'}</div>
              <div>{'PAYMENT TYPE'}</div>
              <div>{'CARD BRAND'}</div>
              <div>{'FIRST 6'}</div>
              <div>{'LAST 4'}</div>
              <div>{'ROUTING NUMBER'}</div>
              <div>{'STATUS'}</div>
              <div>{'CUSTOM FIELD'}</div>
            </div>
          );

          transactionList = transactionsJson.map((transaction, i) => {

            const content = (
              <>
                <div>{transaction['Date']}</div>
                <div>{transaction['Account Number']}</div>
                <div>{transaction['Invoice']}</div>
                <div>{transaction['Sold By']}</div>
                <div>{transaction['Placed For']}</div>
                <div>{transaction['Customer Name']}</div>
                <div>{transaction['Total Transaction Amount']}</div>
                <div>{transaction['Payment Amount']}</div>
                <div>{transaction['Authorized Amount']}</div>
                <div>{transaction['Tip']}</div>
                <div>{transaction['$ Discount']}</div>
                <div>{transaction['$ Tax']}</div>
                <div>{transaction['Cash Discounting Amount']}</div>
                <div>{transaction['Payment Type']}</div>
                <div>{transaction['Card Brand']}</div>
                <div>{transaction['First 6']}</div>
                <div>{transaction['Last 4']}</div>
                <div>{transaction['Routing Number']}</div>
                <div>{transaction['Status']}</div>
                <div>{transaction['Custom Field']}</div>
              </>
            );

            const originalTx = transactions.declinedReceipts[i];

            if (selectedMid === originalTx.mid && !auth.isReport) {
              return (
                <Link key={i} className='flexRowItem flexRowItemClickable hideMobile' to={transactionPath + '?receiptId=' + originalTx.receipt_id + '&declined=true'}>
                  {content}
                </Link>
              );
            }

            return <div key={i} className='flexRowItem hideMobile'>{content}</div>;

          });
        } else {
          transactionList = (<NoData text={t('NoDataDeclinedTransactionExportIsLargeProcessor')} />);
        }

        return (
          <Box sx={reportContainerStyles}>
            <div className='exportContainer'>
              <div className='downloadLink'>
                {printButton}
                <Button variant='link' className='exportButton' onClick={this.handleClick} startIcon={exportIcon} label={t('Export')} />
              </div>
            </div>
            <Box sx={tableStyles}>
              {transactionListHeader}
              {transactionList}
            </Box>
          </Box>
        )
      } else {
        return (<NoData text={this.props.reports?.error ?? t('NoDataDeclinedTransactionExport')} />);
      }
    }
  }
};

DeclinedTransactionExport.defaultProps = {
  isFetching: true,
};
