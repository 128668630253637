/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import withStyles from '@mui/styles/withStyles';

import AddShoppingIcon from '@mui/icons-material/AddShoppingCart';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlertErrorIcon from '@mui/icons-material/Error';
import AlertIcon from '@mui/icons-material/Notifications';
import AndroidIcon from '@mui/icons-material/Android';
import ArchiveIcon from '@mui/icons-material/Dns';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropdownToggle from '@mui/icons-material/ArrowDropDown';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import ClockIcon from '@mui/icons-material/AccessTime';
import CommentIcon from '@mui/icons-material/ModeComment';
import ContentCopyIcon from '@mui/icons-material/FileCopy';
import CompareIcon from '@mui/icons-material/SwapHoriz';
import ComputerIcon from '@mui/icons-material/Computer';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CsvExport from '@mui/icons-material/GridOn';
import Customer from '@mui/icons-material/Person';
import CustomerAdd from '@mui/icons-material/PersonAdd';
import CustomersIcon from '@mui/icons-material/RecentActors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DateIcon from '@mui/icons-material/DateRange';
import DefaultNoIcon from '@mui/icons-material/Build';
import DeleteIcon_OLD from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TickIcon from '@mui/icons-material/Done';
import DepositsIcon from '@mui/icons-material/CompareArrows';
import DiscountIcon from '@mui/icons-material/LocalOffer';
import DisputesIcon from '@mui/icons-material/Gavel';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import EmployeeIcon from '@mui/icons-material/AssignmentInd';
import Error from '@mui/icons-material/Error';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import ExportIcon from '@mui/icons-material/Reply';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import FileDownload from '@mui/icons-material/GetApp';
import FileIcon from '@mui/icons-material/InsertDriveFile';
import FilterDropIcon from '@mui/icons-material/FilterList';
import FlashIcon from '@mui/icons-material/FlashOn';
import HamburgerMenuIcon from '@mui/icons-material/Menu';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ImportIcon from '@mui/icons-material/Publish';
import IntegrationIcon from '@mui/icons-material/Power';
import InvoiceIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import LaunchIcon from '@mui/icons-material/Launch';
import LensIcon from '@mui/icons-material/Lens';
import LineChartIcon from '@mui/icons-material/MultilineChart';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import MailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import ModifierIcon from '@mui/icons-material/PlaylistAddCheck';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import NavigationArrowBack from '@mui/icons-material/ArrowBack';
import NavigationArrowForward from '@mui/icons-material/ArrowForward';
import NavigationClose from '@mui/icons-material/Close';
import NavigationMoreHoriz from '@mui/icons-material/MoreHoriz';
import OnlinePaymentIcon from '@mui/icons-material/Computer';
import PageView from '@mui/icons-material/Pageview';
import PauseIcon from '@mui/icons-material/Pause';
import PaymentIcon from '@mui/icons-material/Payment';
import PdfExport from '@mui/icons-material/PictureAsPdf';
import PercentIcon from '@mui/icons-material/Percent';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import PlayIcon from '@mui/icons-material/PlayCircleFilled'
import PlusIcon from '@mui/icons-material/Add';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PrintIcon from '@mui/icons-material/Print';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import ReceiptIcon from '@mui/icons-material/Subject';
import RecurrenceIcon from '@mui/icons-material/Update';
import ReferralsIcon from '@mui/icons-material/Forum';
import RefreshIcon from '@mui/icons-material/Refresh';
import RefundIcon from '@mui/icons-material/Refresh';
import RemoveShoppingIcon from '@mui/icons-material/RemoveShoppingCart';
import RepeatIcon from '@mui/icons-material/Repeat';
import ReplayIcon from '@mui/icons-material/Replay';
import ReportsIcon from '@mui/icons-material/Subject';
import SalesIcon from '@mui/icons-material/Equalizer';
import SaveIcon from '@mui/icons-material/Save';
import SelectCust from '@mui/icons-material/AccountBox';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Shuffle from '@mui/icons-material/Shuffle';
import SortIcon from '@mui/icons-material/Sort';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import StatementsIcon from '@mui/icons-material/LibraryBooks';
import StoreIcon from '@mui/icons-material/StoreMallDirectory';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ThumbsUp from '@mui/icons-material/ThumbUp';
import TimerIcon from '@mui/icons-material/Timer';
import TodayRefundIcon from '@mui/icons-material/Replay';
import TransactionIcon from '@mui/icons-material/Receipt';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import TrendingUp from '@mui/icons-material/TrendingUp';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VoidIcon from '@mui/icons-material/NotInterested';
import Warning from '@mui/icons-material/Warning';
import WorkIcon from '@mui/icons-material/Work';
import ErrorIcon from '@mui/icons-material/ReportProblem';
import DocIcon from '@mui/icons-material/Description';
import DragIndicator from '@mui/icons-material/DragIndicator';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Logout from '@mui/icons-material/Logout';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';


import LibraryAdd from '../icons/CustomSurveyIcon';
import AcceptDispute from '../icons/AcceptDispute';
import AddIcon from '../icons/AddIcon';
import ArrowRight from '../icons/RightArrowIcon';
import BatchIcon from '../icons/BatchIcon';
import BatchesNetAmountIcon from '../icons/BatchesNetAmountIcon';
import BatchesPrintIcon from '../icons/PrintIcon';
import BatchesSalesIcon from '../icons/BatchesSalesIcon';
import CashDrawIcon from '../icons/CashDrawIcon';
import CategoriesIcon from '../icons/CategoriesIcon';
import ChevronRight from '../icons/RightChevronIcon';
import CornerCheckMark from '../icons/CornerCheckMark';
import ComputerCheckMark from '../icons/ComputerIcon';
import CustomAttachmentIcon from '../icons/AttachmentIcon';
import CustomChevronIcon from '../icons/CustomChevronIcon';
import DeleteIcon from '../icons/DeleteIcon';
import CustomDepositsIcon from '../icons/DepositsIcon';
import CustomExportIcon from '../icons/CustomExportIcon';
import CustomImportIcon from '../icons/ImportIcon';
import CustomPrintIcon from '../icons/CustomPrintIcon';
import CustomSalesIcon from '../icons/SalesIcon';
import DepositsSummaryIcon from '../icons/DepositsSummaryIcon';
import DoneIcon from '../icons/DoneIcon';
import DropDownIcon from '../icons/DropDownIcon';
import EditPenIcon from '../icons/EditPenIcon';
import EmailAtIcon from '../icons/EmailAtIcon';
import ExportIconBatch from '../icons/ExportIcon';
import FilterIcon from '../icons/FilterIcon';
import GoBackIcon from '../icons/GoBackIcon';
import HeldFundsIcon from '../icons/HeldFundsIcon';
import HelpIcon from '../icons/HelpIcon';
import ImgUploadIcon from '../icons/ImgUploadIcon';
import LightBulbIcon from '../icons/LightBulbIcon';
import ItemsIcon from '../icons/ItemsIcon';
import KeyholeIcon from '../icons/KeyholeIcon';
import MarkPaidIcon from '../icons/MarkPaidIcon';
import MinusIcon from '../icons/MinusIcon';
import PayAnywhereTiled from '../icons/PayAnywhereTiled';
import PriorityIcon from '../icons/PriorityIcon';
import RebutDispute from '../icons/RebutDispute';
import RecurringIcon from '../icons/RecurringIcon';
import ResolvedDepositsIcon from '../icons/ResolvedDepositsIcon';
import RoundedAddIcon from '../icons/RoundedAddIcon';
import ScheduleIcon from '../icons/ScheduleIcon';
import ScreenIcon from '../icons/ScreenIcon';
import SliderIcon from '../icons/SliderIcon';
import SubtractIcon from '../icons/SubtractIcon';
import TipsIcon from '../icons/TipsIcon';
import TotalSalesIcon from '../icons/TotalSalesIcon';
import TwoFactorShieldIcon from '../icons/TwoFactorShieldIcon';
import WithdrawalsIcon from '../icons/WithdrawalsIcon';
import LoyaltyProgramIcon from '../icons/LoyaltyProgramIcon';
import LoyaltyTagIcon from '../icons/LoyaltyTagIcon';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import InfoIcon from '../icons/InfoIcon';
import SyncIcon from '../icons/SyncIcon';

import MainMenu_BatchesIcon from '../icons/MainMenu/BatchesIcon';
import MainMenu_CustomersIcon from '../icons/MainMenu/CustomersIcon';
import MainMenu_DashboardIcon from '../icons/MainMenu/DashboardIcon';
import MainMenu_DepositsIcon from '../icons/MainMenu/DepositsIcon';
import MainMenu_DisputesIcon from '../icons/MainMenu/DisputesIcon';
import MainMenu_EmployeesIcon from '../icons/MainMenu/EmployeesIcon';
import MainMenu_HardwareIcon from '../icons/MainMenu/HardwareIcon';
import MainMenu_InventoryIcon from '../icons/MainMenu/InventoryIcon';
import MainMenu_InvoicesIcon from '../icons/MainMenu/InvoicesIcon';
import MainMenu_MenuIcon from '../icons/MainMenu/MenuIcon';
import MainMenu_PaymentLinksIcon from '../icons/MainMenu/PaymentLinksIcon';
import MainMenu_ReportsIcon from '../icons/MainMenu/ReportsIcon';
import MainMenu_ReputationIcon from '../icons/MainMenu/ReputationIcon';
import MainMenu_SalesIcon from '../icons/MainMenu/SalesIcon';
import MainMenu_SettingsIcon from '../icons/MainMenu/SettingsIcon';
import MainMenu_StatementsIcon from '../icons/MainMenu/StatementsIcon';
import MainMenu_TransactionsIcon from '../icons/MainMenu/TransactionsIcon';
import MainMenu_VirtualTerminalIcon from '../icons/MainMenu/VirtualTerminalIcon';

import MainMenu_BatchesIcon_Active from '../icons/MainMenu/BatchesIconActive';
import MainMenu_CustomersIcon_Active from '../icons/MainMenu/CustomersIconActive';
import MainMenu_DashboardIcon_Active from '../icons/MainMenu/DashboardIconActive';
import MainMenu_DepositsIcon_Active from '../icons/MainMenu/DepositsIconActive';
import MainMenu_DisputesIcon_Active from '../icons/MainMenu/DisputesIconActive';
import MainMenu_EmployeesIcon_Active from '../icons/MainMenu/EmployeesIconActive';
import MainMenu_HardwareIcon_Active from '../icons/MainMenu/HardwareIconActive';
import MainMenu_InventoryIcon_Active from '../icons/MainMenu/InventoryIconActive';
import MainMenu_InvoicesIcon_Active from '../icons/MainMenu/InvoicesIconActive';
import MainMenu_PaymentLinksIcon_Active from '../icons/MainMenu/PaymentLinksIconActive';
import MainMenu_ReportsIcon_Active from '../icons/MainMenu/ReportsIconActive';
import MainMenu_ReputationIcon_Active from '../icons/MainMenu/ReputationIconActive';
import MainMenu_SalesIcon_Active from '../icons/MainMenu/SalesIconActive';
import MainMenu_SettingsIcon_Active from '../icons/MainMenu/SettingsIconActive';
import MainMenu_StatementsIcon_Active from '../icons/MainMenu/StatementsIconActive';
import MainMenu_TransactionsIcon_Active from '../icons/MainMenu/TransactionsIconActive';
import MainMenu_VirtualTerminalIcon_Active from '../icons/MainMenu/VirtualTerminalIconActive';

import NoData from '../icons/NoData';

import AddEmployeeIcon from '../icons/dashboard/AddEmployeeIcon';
import ExportCustomersIcon from '../icons/dashboard/ExportCustomersIcon';
import HandshakeHelpIcon from '../icons/dashboard/HandshakeHelpIcon';


import AmexIcon from '../icons/creditCards/AmexIcon';
import DiscoverIcon from '../icons/creditCards/DiscoverIcon';
import MastercardIcon from '../icons/creditCards/MastercardIcon';
import VisaIcon from '../icons/creditCards/VisaIcon';
import TrendDownIcon from '../icons/TrendDown';

import LabelUtil from './LabelUtil';
import TrendUpIcon from '../icons/TrendUp';
import TrendFlatIcon from '../icons/TrendFlat';
import AppleIcon from '../icons/AppleIcon';
import Printer from '../icons/Printer';
import CashDrawer from '../icons/CashDrawer';
import BetaIcon from '../icons/BetaIcon';

import HowItWorks from '../icons/integrations/HowItWorks';
import Launch from '../icons/integrations/Launch';
import Connect from '../icons/integrations/Connect';
import IntegrationCheckIcon from '../icons/integrations/IntegrationCheckIcon';

import BankPaymentMethod from '../icons/paymentMethods/bankPaymentMethod';
import VisaPaymentMethod from '../icons/paymentMethods/visaPaymentMethod';
import DiscoverCardPaymentMethod from '../icons/paymentMethods/discoverPaymentMethod';
import AmexCardPaymentMethod from '../icons/paymentMethods/amexPaymentMethod';
import MasterCardPaymentMethod from '../icons/paymentMethods/masterCardPaymentMethod';
import AddIconPaymentMethod from '../icons/paymentMethods/addIconPaymentMethod';
import CheckPaymentMethod from '../icons/paymentMethods/checkPaymentMethod';
import ChevronPaymentMethod from '../icons/paymentMethods/chevronPaymentMethod';
import HelpIconPaymentMethod from '@mui/icons-material/Help';

import PositiveFaceIcon from '../icons/PositiveFaceIcon';
import NeutralFaceIcon from '../icons/NeutralFaceIcon';
import NegativeFaceIcon from '../icons/NegativeFaceIcon';
import ScheduledIcon from '../icons/ScheduledIcon';
import KeyIcon from '../icons/KeyIcon';
import ChargezoomLinkIcon from '../icons/ChargezoomLinkIcon';
import FeatureIcon from '../icons/FeatureIcon';


import CancelXIcon from '../icons/CancelXIcon';
import CopyIcon from '../icons/CopyIcon';
import ListIcon from '../icons/ListIcon';
import PowerButtonIcon from '../icons/PowerButtonIcon';
import QRCodeIcon from '../icons/QRCodeIcon';

import TapToPay from '../icons/TapToPay';
import Loyalty from '../icons/Loyalty';
import ReputationManagement from '../icons/ReputationManagement';

import PremiumPlus from '../icons/PremiumPlus';
import PowerUser from '../icons/PowerUser';
import NorthLogo from '../icons/NorthLogo';
import PaymentsHubLogo from '../icons/PaymentsHubLogo';

import ShareWindows from '../icons/ShareWindows';
import InvoiceDraftIcon from '../icons/InvoiceDraftIcon';

import DocumentIcon from '../icons/DocumentIcon';
import GoToIcon from '../icons/GoToIcon';
import DashedLine from '../icons/DashedLine';

const IconUtils = {

  getIcon: function (iconName, color = 'inherited', height = 'auto', width) {

    const style = {
      fill: color,
      height,
      ...(width !== undefined && { width })
    };

    let StyledIcon;
    const muiStyles = {
      root: {
        fill: color,
        height,
        ...(height !== 'auto' ? {
          fontSize: height,
        } : {}),
      },
    };

    const withMuiStyles = withStyles(muiStyles);

    switch (iconName) {
      case 'AcceptDispute':
        return (<AcceptDispute style={style}/>);
      case 'AddEmployeeIcon':
        return (<AddEmployeeIcon style={style}/>);
      case 'AddShoppingCart':
        StyledIcon = withMuiStyles(AddShoppingIcon);
        return (<StyledIcon/>);
      case 'AlarmIcon':
        StyledIcon = withMuiStyles(AlarmIcon);
        return(<StyledIcon/>);
      case 'AlertErrorIcon':
        StyledIcon = withMuiStyles(AlertErrorIcon);
        return (<StyledIcon/>);
      case 'AlertIcon':
        StyledIcon = withMuiStyles(AlertIcon);
        return (<StyledIcon/>);
      case 'AndroidIcon':
        return <AndroidIcon style={style}/>;
      case 'ArchiveIcon':
        muiStyles.root = { ...muiStyles.root, transform: 'rotate(90deg)'};
        StyledIcon = withStyles(muiStyles)(ArchiveIcon);
        return (<StyledIcon/>);
      case 'ArrowDropUp':
        StyledIcon = withMuiStyles(ArrowDropUp);
        return (<StyledIcon/>);
      case 'ArrowDropdownToggle':
        StyledIcon = withMuiStyles(ArrowDropdownToggle);
        return (<StyledIcon/>);
      case 'AttachmentIcon':
        StyledIcon = withMuiStyles(AttachmentIcon);
        return (<StyledIcon/>);
      case 'AutoRenewIcon':
        StyledIcon = withMuiStyles(AutoRenewIcon);
        return (<StyledIcon/>);
      case 'BankIcon':
        StyledIcon = withMuiStyles(BatchIcon);
        return (<StyledIcon/>);
      case 'BankPaymentMethod':
        StyledIcon = withMuiStyles(BankPaymentMethod);
        return (<StyledIcon/>);
      case 'BatchIcon':
        return (<BatchIcon style={style}/>);
      case 'CancelIcon':
        StyledIcon = withMuiStyles(CancelIcon);
        return (<StyledIcon/>);
      case 'CancelXIcon':
        return (<CancelXIcon style={style}/>);
      case 'CheckIcon':
        StyledIcon = withMuiStyles(CheckIcon);
        return (<StyledIcon/>);
      case 'CheckBoxOutlineBlankIcon':
        StyledIcon = withMuiStyles(CheckBoxOutlineBlankIcon);
        return (<StyledIcon/>);
      case 'CheckBoxIcon':
        StyledIcon = withMuiStyles(CheckBoxIcon);
        return (<StyledIcon/>);
      case 'CheckCircleOutlinedIcon':
        StyledIcon = withMuiStyles(CheckCircleOutlinedIcon);
        return (<StyledIcon/>);
      case 'ChevronLeftIcon':
        StyledIcon = withMuiStyles(ChevronLeftIcon);
        return (<StyledIcon/>);
      case 'ChevronRightIcon':
        StyledIcon = withMuiStyles(ChevronRightIcon);
        return (<StyledIcon/>);
      case 'CheckCircle':
        StyledIcon = withMuiStyles(CheckCircleIcon);
        return (<StyledIcon/>);
      case 'CheckmarkIcon':
        StyledIcon = withMuiStyles(CheckmarkIcon);
        return (<StyledIcon/>);
      case 'CircleIcon':
        StyledIcon = withMuiStyles(CircleIcon);
        return (<StyledIcon/>);
      case 'Clear':
        StyledIcon = withMuiStyles(ClearIcon);
        return (<StyledIcon/>);
      case 'ClockIcon':
        StyledIcon = withMuiStyles(ClockIcon);
        return (<StyledIcon/>);
      case 'CommentIcon':
        StyledIcon = withMuiStyles(CommentIcon);
        return (<StyledIcon/>);
      case 'ContentCopyIcon':
        StyledIcon = withMuiStyles(ContentCopyIcon);
        return (<StyledIcon/>);
      case 'CompareIcon':
        StyledIcon = withMuiStyles(CompareIcon);
        return (<StyledIcon/>);
      case 'ComputerIcon':
        StyledIcon = withMuiStyles(ComputerIcon);
        return (<StyledIcon/>);
      case 'CopyIcon':
        return (<CopyIcon style={style}/>);
      case 'CreditCardIcon':
        StyledIcon = withMuiStyles(CreditCardIcon);
        return (<StyledIcon/>);
      case 'CsvExport':
        StyledIcon = withMuiStyles(CsvExport);
        return (<StyledIcon/>);
      case 'Customer':
        StyledIcon = withMuiStyles(Customer);
        return (<StyledIcon/>);
      case 'CustomerAdd':
        StyledIcon = withMuiStyles(CustomerAdd);
        return (<StyledIcon/>);
      case 'CustomersIcon':
        StyledIcon = withMuiStyles(CustomersIcon);
        return (<StyledIcon/>);
      case 'CallMergeIcon':
        StyledIcon = withMuiStyles(CallMergeIcon);
      case 'ErrorOutlineIcon':
        StyledIcon = withMuiStyles(ErrorOutlineIcon);
        return (<StyledIcon/>);
      case 'NextPlanIcon':
        StyledIcon = withMuiStyles(NextPlanOutlinedIcon);
        return (<StyledIcon/>);
      case 'PowerSettingsIcon':
        StyledIcon = withMuiStyles(PowerSettingsNewIcon);
        return (<StyledIcon/>);
      case 'CashDrawIcon':
        return (<CashDrawIcon style={style}/>);
      case 'Camera':
        StyledIcon = withMuiStyles(PhotoCamera);
        return (<StyledIcon/>);
      case 'CornerCheckMark':
        return (<CornerCheckMark style={style}/>);
      case 'ComputerCheckMark':
        return (<ComputerCheckMark style={style}/>);
      case 'CustomAttachmentIcon':
        return (<CustomAttachmentIcon style={style}/>);
      case 'CustomChevronIcon':
        return (<CustomChevronIcon style={style}/>);
      case 'CustomExportIcon':
        return (<CustomExportIcon style={style}/>);
      case 'CustomPrintIcon':
        return (<CustomPrintIcon style={style}/>);
      case 'CategoriesIcon':
        return (<CategoriesIcon style={style}/>);
      case 'Close':
        StyledIcon = withMuiStyles(NavigationClose);
        return (<StyledIcon/>);
      case 'DashboardIcon':
        StyledIcon = withMuiStyles(DashboardIcon);
        return (<StyledIcon/>);
      case 'DateIcon':
        StyledIcon = withMuiStyles(DateIcon);
        return (<StyledIcon/>);
      case 'DeleteIcon':
        return (<DeleteIcon style={style} />);
      case 'DeleteIcon_OLD':
        StyledIcon = withMuiStyles(DeleteIcon_OLD);
        return (<StyledIcon/>);
      case 'DeleteOutlineIcon':
        StyledIcon = withMuiStyles(DeleteOutlineIcon);
        return (<StyledIcon/>);
      case 'DepositsIcon':
        StyledIcon = withMuiStyles(DepositsIcon);
        return (<StyledIcon/>);
      case 'DiscountIcon':
        StyledIcon = withMuiStyles(DiscountIcon);
        return (<StyledIcon/>);
      case 'DisputesIcon':
        StyledIcon = withMuiStyles(DisputesIcon);
        return (<StyledIcon/>);
      case 'DocIcon':
        StyledIcon = withMuiStyles(DocIcon);
        return (<StyledIcon/>);
      case 'DragIndicator':
        StyledIcon = withMuiStyles(DragIndicator);
        return (<StyledIcon/>);
      case 'DepositsSummaryIcon':
        return (<DepositsSummaryIcon style={style}/>);
      case 'DoneIcon':
        return (<DoneIcon style={style}/>);
      case 'DotIcon':
        StyledIcon = withMuiStyles(LensIcon);
        return (<StyledIcon/>);
      case 'EditIcon':
        StyledIcon = withMuiStyles(EditIcon);
        return (<StyledIcon/>);
      case 'EmailIcon':
        StyledIcon = withMuiStyles(EmailIcon);
        return (<StyledIcon/>);
      case 'EmployeeIcon':
        StyledIcon = withMuiStyles(EmployeeIcon);
        return (<StyledIcon/>);
      case 'Error':
        StyledIcon = withMuiStyles(Error);
        return (<StyledIcon/>);
      case 'ErrorIcon':
        StyledIcon = withMuiStyles(ErrorIcon);
        return (<StyledIcon/>);
      case 'ExpandIcon':
        StyledIcon = withMuiStyles(ExpandIcon);
        return (<StyledIcon/>);
      case 'ExportCustomersIcon':
        return (<ExportCustomersIcon style={style}/>);
      case 'ExportIcon':
        StyledIcon = withMuiStyles(ExportIcon);
        return (<StyledIcon/>);
      case 'EditPenIcon':
        return (<EditPenIcon style={style}/>);
      case 'EmailAtIcon':
        return (<EmailAtIcon style={style}/>);
      case 'Favorite':
        StyledIcon = withMuiStyles(Favorite);
        return (<StyledIcon/>);
      case 'FavoriteBorder':
        StyledIcon = withMuiStyles(FavoriteBorder);
        return (<StyledIcon/>);
      case 'FileDownload':
        StyledIcon = withMuiStyles(FileDownload);
        return (<StyledIcon/>);
      case 'FileIcon':
        StyledIcon = withMuiStyles(FileIcon);
        return (<StyledIcon/>);
      case 'FilterIcon':
        StyledIcon = withMuiStyles(FilterIcon);
        return (<StyledIcon/>);
      case 'FilterDropIcon':
        StyledIcon = withMuiStyles(FilterDropIcon);
        return (<StyledIcon/>);
      case 'FlashIcon':
        StyledIcon = withMuiStyles(FlashIcon);
        return (<FlashIcon style={style}/>);
      case 'GoBackIcon':
        return (<GoBackIcon style={style}/>);
      case 'HamburgerMenuIcon':
        StyledIcon = withMuiStyles(HamburgerMenuIcon);
        return (<StyledIcon/>);
      case 'GoBackIcon':
        return (<GoBackIcon style={style}/>);
      case 'HorizontalRuleIcon':
        return (<HorizontalRuleIcon style={style} />);
      case 'HandshakeHelpIcon':
        return (<HandshakeHelpIcon style={style}/>);
      case 'ImportIcon':
        StyledIcon = withMuiStyles(ImportIcon);
        return (<StyledIcon/>);
      case 'InfoIcon':
        return (<InfoIcon style={style}/>);
      case 'SyncIcon':
        return (<SyncIcon style={style}/>);
      case 'IntegrationIcon':
        StyledIcon = withMuiStyles(IntegrationIcon);
        return (<StyledIcon/>);
      case 'InvoiceIcon':
        StyledIcon = withMuiStyles(InvoiceIcon);
        return (<StyledIcon/>);
      case 'ImgUploadIcon':
        return (<ImgUploadIcon style={style}/>);
      case 'ItemsIcon':
        return (<ItemsIcon style={style}/>);
      case 'KeyIcon':
        return (<KeyIcon style={style}/>);
      case 'HelpIcon':
        return (<HelpIcon style={style}/>);
      case 'KeyholeIcon':
        return (<KeyholeIcon style={style}/>);
      case 'KeyboardRight':
        StyledIcon = withMuiStyles(KeyboardRight);
        return (<StyledIcon/>);
      case 'KeyboardArrowDownIcon':
        StyledIcon = withMuiStyles(KeyboardArrowDownIcon);
        return (<StyledIcon/>);
      case 'LanguageIcon':
        StyledIcon = withMuiStyles(LanguageIcon);
        return (<StyledIcon/>);
      case 'LaunchIcon':
        StyledIcon = withMuiStyles(LaunchIcon);
        return (<StyledIcon/>);
      case 'LineChartIcon':
        StyledIcon = withMuiStyles(LineChartIcon);
        return (<StyledIcon/>);
      case 'LockIcon':
        StyledIcon = withMuiStyles(LockIcon);
        return (<StyledIcon/>);
      case 'LockOpen':
        StyledIcon = withMuiStyles(LockOpen);
        return (<StyledIcon/>);
      case 'LibraryAddIcon':
        return (<LibraryAdd style={style}/>);
      case 'LightBulbIcon':
        return (<LightBulbIcon style={style}/>);
      case 'ListIcon':
        return (<ListIcon style={style}/>);
      case 'MailboxIcon':
        return (<MailboxIcon style={style}/>);
      case 'MobileFriendlyIcon':
        StyledIcon = withMuiStyles(MobileFriendlyIcon);
        return (<StyledIcon />);
      case 'ModifierIcon':
        return (<ModifierIcon style={style}/>);
      case 'More':
        StyledIcon = withMuiStyles(NavigationMoreHoriz);
        return (<StyledIcon/>);
      case 'OnlinePaymentIcon':
        StyledIcon = withMuiStyles(OnlinePaymentIcon);
        return (<StyledIcon/>);
      case 'OpenInNewIcon':
        StyledIcon = withMuiStyles(OpenInNewIcon);
        return (<StyledIcon/>);
      case 'SyncAltIcon':
        StyledIcon = withMuiStyles(SyncAltIcon);
        return (<StyledIcon/>);
      case 'PageView':
        StyledIcon = withMuiStyles(PageView);
        return (<StyledIcon/>);
      case 'PauseIcon':
        StyledIcon = withMuiStyles(PauseIcon);
        return (<StyledIcon/>);
      case 'PdfExport':
        StyledIcon = withMuiStyles(PdfExport);
        return (<StyledIcon/>);
      case 'PercentIcon':
        StyledIcon = withMuiStyles(PercentIcon);
        return (<StyledIcon/>);
      case 'PlayIcon':
        StyledIcon = withMuiStyles(PlayIcon);
        return (<StyledIcon/>);
      case 'PlusIcon':
        StyledIcon = withMuiStyles(PlusIcon);
        return (<StyledIcon/>);
      case 'PrintIcon':
        StyledIcon = withMuiStyles(PrintIcon);
        return (<StyledIcon/>);
      case 'AddFile':
        StyledIcon = withMuiStyles(PostAddIcon);
        return (<StyledIcon/>);
      case 'ProfileIcon':
        StyledIcon = withMuiStyles(ProfileIcon);
        return (<StyledIcon/>);
      case 'PayAnywhereTiled':
        return (<PayAnywhereTiled style={style}/>);
      case 'PowerButtonIcon':
        return (<PowerButtonIcon style={style}/>);
      case 'PriorityIcon':
        return (<PriorityIcon style={style}/>);
      case 'QRCodeIcon':
        return (<QRCodeIcon style={style}/>);
      case 'RebutDispute':
        return (<RebutDispute style={style}/>);
      case 'ReceiptIcon':
        StyledIcon = withMuiStyles(ReceiptIcon);
        return (<StyledIcon className='receiptIcon' />);
      case 'RecurrenceIcon':
        StyledIcon = withMuiStyles(RecurrenceIcon);
        return (<StyledIcon/>);
      case 'ReferralsIcon':
        StyledIcon = withMuiStyles(ReferralsIcon);
        return (<StyledIcon/>);
      case 'RefreshIcon':
        StyledIcon = withMuiStyles(RefreshIcon);
        return (<StyledIcon/>);
      case 'RefundIcon':
        StyledIcon = withMuiStyles(RefundIcon);
        return (<StyledIcon/>);
      case 'RemoveShoppingCart':
        StyledIcon = withMuiStyles(RemoveShoppingIcon);
        return (<StyledIcon/>);
      case 'RepeatIcon':
        StyledIcon = withMuiStyles(RepeatIcon);
        return (<StyledIcon/>);
      case 'ReplayIcon':
        StyledIcon = withMuiStyles(ReplayIcon);
        return (<StyledIcon/>);
      case 'ReportsIcon':
        StyledIcon = withMuiStyles(ReportsIcon);
        return (<StyledIcon/>);
      case 'RecurringIcon':
        return (<RecurringIcon style={style}/>);
      case 'SalesIcon':
        StyledIcon = withMuiStyles(SalesIcon);
        return (<StyledIcon/>);
      case 'SaveIcon':
        StyledIcon = withMuiStyles(SaveIcon);
        return (<StyledIcon/>);
      case 'SelectCust':
        StyledIcon = withMuiStyles(SelectCust);
        return (<StyledIcon/>);
      case 'SendIcon':
        StyledIcon = withMuiStyles(SendIcon);
        return (<StyledIcon/>);
      case 'SettingsIcon':
        StyledIcon = withMuiStyles(SettingsIcon);
        return (<StyledIcon/>);
      case 'ShoppingBasket':
        StyledIcon = withMuiStyles(ShoppingBasket);
        return (<StyledIcon/>);
      case 'ShoppingCart':
        StyledIcon = withMuiStyles(ShoppingCart);
        return (<StyledIcon/>);
      case 'Shuffle':
        StyledIcon = withMuiStyles(Shuffle);
        return (<StyledIcon/>);
      case 'SortIcon':
        StyledIcon = withMuiStyles(SortIcon);
        return (<StyledIcon/>);
      case 'Star':
        StyledIcon = withMuiStyles(Star);
        return (<StyledIcon/>);
      case 'StarBorder':
        StyledIcon = withMuiStyles(StarBorder);
        return (<StyledIcon/>);
      case 'StatementsIcon':
        StyledIcon = withMuiStyles(StatementsIcon);
        return (<StyledIcon/>);
      case 'StoreIcon':
        StyledIcon = withMuiStyles(StoreIcon);
        return <StyledIcon/>;
      case 'SwapHoriz':
        StyledIcon = withMuiStyles(SwapHoriz);
        return (<StyledIcon/>);
      case 'ScheduleIcon':
        return (<ScheduleIcon style={style}/>);
      case 'ScreenIcon':
        return (<ScreenIcon style={style}/>);
      case 'SliderIcon':
        return (<SliderIcon style={style}/>);
      case 'ThumbsUp':
        StyledIcon = withMuiStyles(ThumbsUp);
        return (<StyledIcon className='timerIcon' />);
      case 'TimerIcon':
        StyledIcon = withMuiStyles(TimerIcon);
        return (<StyledIcon className='timerIcon' />);
      case 'TickIcon':
        StyledIcon = withMuiStyles(TickIcon);
        return (<StyledIcon/>);
      case 'TodayRefundIcon':
        StyledIcon = withMuiStyles(TodayRefundIcon);
        return (<StyledIcon/>);
      case 'TransactionIcon':
        StyledIcon = withMuiStyles(TransactionIcon);
        return (<StyledIcon/>);
      case 'TrendingDown':
        StyledIcon = withMuiStyles(TrendingDown);
        return (<StyledIcon/>);
      case 'TrendingFlat':
        StyledIcon = withMuiStyles(TrendingFlat);
        return (<StyledIcon/>);
      case 'TrendingUp':
        StyledIcon = withMuiStyles(TrendingUp);
        return (<StyledIcon/>);
      case 'TwoFactorShieldIcon':
        return (<TwoFactorShieldIcon style={style}/>);
      case 'TipsIcon':
        return (<TipsIcon style={style}/>);
      case 'UploadFile':
        StyledIcon = withMuiStyles(UploadFileIcon);
        return (<StyledIcon/>);
      case 'Visibility':
        StyledIcon = withMuiStyles(VisibilityIcon);
        return (<StyledIcon/>);
      case 'VisibilityOff':
        StyledIcon = withMuiStyles(VisibilityOffIcon);
        return (<StyledIcon/>);
      case 'VoidIcon':
        StyledIcon = withMuiStyles(VoidIcon);
        return (<StyledIcon/>);
      case 'Warning':
        StyledIcon = withMuiStyles(Warning);
        return (<StyledIcon/>);
      case 'WorkIcon':
        StyledIcon = withMuiStyles(WorkIcon);
        return (<StyledIcon/>);
      case 'Withdrawal':
        StyledIcon = withMuiStyles(NavigationArrowBack);
        return (<StyledIcon/>);
      case 'Deposit':
        StyledIcon = withMuiStyles(NavigationArrowForward);
        return (<StyledIcon/>);
      case 'Processing Fee':
        StyledIcon = withMuiStyles(NavigationArrowBack);
        return (<StyledIcon/>);
      case 'Back':
        StyledIcon = withMuiStyles(NavigationArrowBack);
        return (<StyledIcon/>);
      case 'AddIconPaymentMethod':
        StyledIcon = withMuiStyles(AddIconPaymentMethod);
        return (<StyledIcon/>);
      case 'CheckPaymentMethod':
        StyledIcon = withMuiStyles(CheckPaymentMethod);
        return (<StyledIcon/>);
      case 'ChevronPaymentMethod':
        StyledIcon = withMuiStyles(ChevronPaymentMethod);
        return (<StyledIcon/>);
      case 'HelpIconPaymentMethod':
        StyledIcon = withMuiStyles(HelpIconPaymentMethod);
        return (<StyledIcon />);
      case 'AddIcon':
        return (<AddIcon style={style}/>);
      case 'MarkPaidIcon':
        return (<MarkPaidIcon style={style}/>);
      case 'MinusIcon':
        return (<MinusIcon style={style}/>);
      case 'SubtractIcon':
        return (<SubtractIcon style={style}/>);
      case 'DropDownIcon':
        return (<DropDownIcon style={style}/>);
      case 'ArrowRight':
        return (<ArrowRight style={style} />);
      case 'ChevronRight':
        return (<ChevronRight style={style}/>);
      case 'CustomDepositsIcon':
        return (<CustomDepositsIcon style={style}/>);
      case 'BatchesIcon':
        return (<MainMenu_BatchesIcon style={style}/>);
      case 'TotalSalesIcon':
        return (<TotalSalesIcon style={style}/>);
      case 'CustomSalesIcon':
        return (<CustomSalesIcon style={style}/>);
      case 'BatchesNetAmountIcon':
        return (<BatchesNetAmountIcon style={style}/>);
      case 'BatchesSalesIcon':
        return (<BatchesSalesIcon style={style}/>);
      case 'BatchesPrintIcon':
        return (<BatchesPrintIcon style={style}/>);
      case 'ExportIconBatch':
        return (<ExportIconBatch style={style}/>);
      case 'ResolvedDepositsIcon':
        return (<ResolvedDepositsIcon style={style}/>);
      case 'WithdrawalsIcon':
        return (<WithdrawalsIcon style={style}/>);
      case 'HeldFundsIcon':
        return (<HeldFundsIcon style={style}/>);

      case 'MainMenu_BatchesIcon':
        return (<MainMenu_BatchesIcon style={style}/>);
      case 'MainMenu_CustomersIcon':
        return (<MainMenu_CustomersIcon style={style}/>);
      case 'MainMenu_DashboardIcon':
        return (<MainMenu_DashboardIcon style={style}/>);
      case 'MainMenu_DepositsIcon':
        return (<MainMenu_DepositsIcon style={style}/>);
      case 'MainMenu_DisputesIcon':
        return (<MainMenu_DisputesIcon style={style}/>);
      case 'MainMenu_EmployeesIcon':
        return (<MainMenu_EmployeesIcon style={style}/>);
      case 'MainMenu_ReputationIcon':
        return (<MainMenu_ReputationIcon style={style}/>);
      case 'MainMenu_HardwareIcon':
        return (<MainMenu_HardwareIcon style={style}/>);
      case 'MainMenu_InventoryIcon':
        return (<MainMenu_InventoryIcon style={style}/>);
      case 'MainMenu_InvoicesIcon':
        return (<MainMenu_InvoicesIcon style={style}/>);
      case 'MainMenu_MenuIcon':
        return (<MainMenu_MenuIcon style={style}/>);
      case 'MainMenu_PaymentLinksIcon':
        return (<MainMenu_PaymentLinksIcon style={style}/>);
      case 'MainMenu_ReportsIcon':
        return (<MainMenu_ReportsIcon style={style}/>);
      case 'MainMenu_SalesIcon':
        return (<MainMenu_SalesIcon style={style}/>);
      case 'MainMenu_SettingsIcon':
        return (<MainMenu_SettingsIcon style={style}/>);
      case 'MainMenu_StatementsIcon':
        return (<MainMenu_StatementsIcon style={style}/>);
      case 'MainMenu_TransactionsIcon':
        return (<MainMenu_TransactionsIcon style={style}/>);
      case 'MainMenu_VirtualTerminalIcon':
        return (<MainMenu_VirtualTerminalIcon style={style}/>);

      case 'MainMenu_BatchesIcon_Active':
        return (<MainMenu_BatchesIcon_Active style={style}/>);
      case 'MainMenu_CustomersIcon_Active':
        return (<MainMenu_CustomersIcon_Active style={style}/>);
      case 'MainMenu_DashboardIcon_Active':
        return (<MainMenu_DashboardIcon_Active style={style}/>);
      case 'MainMenu_DepositsIcon_Active':
        return (<MainMenu_DepositsIcon_Active style={style}/>);
      case 'MainMenu_DisputesIcon_Active':
        return (<MainMenu_DisputesIcon_Active style={style}/>);
      case 'MainMenu_EmployeesIcon_Active':
        return (<MainMenu_EmployeesIcon_Active style={style}/>);
      case 'MainMenu_PaymentLinksIcon_Active':
        return (<MainMenu_PaymentLinksIcon_Active style={style}/>);
      case 'MainMenu_ReputationIcon_Active':
        return (<MainMenu_ReputationIcon_Active style={style}/>);
      case 'MainMenu_HardwareIcon_Active':
        return (<MainMenu_HardwareIcon_Active style={style}/>);
      case 'MainMenu_InventoryIcon_Active':
        return (<MainMenu_InventoryIcon_Active style={style}/>);
      case 'MainMenu_InvoicesIcon_Active':
        return (<MainMenu_InvoicesIcon_Active style={style}/>);
      case 'MainMenu_ReportsIcon_Active':
        return (<MainMenu_ReportsIcon_Active style={style}/>);
      case 'MainMenu_SalesIcon_Active':
        return (<MainMenu_SalesIcon_Active style={style}/>);
      case 'MainMenu_SettingsIcon_Active':
        return (<MainMenu_SettingsIcon_Active style={style}/>);
      case 'MainMenu_StatementsIcon_Active':
        return (<MainMenu_StatementsIcon_Active style={style}/>);
      case 'MainMenu_TransactionsIcon_Active':
        return (<MainMenu_TransactionsIcon_Active style={style}/>);
      case 'MainMenu_VirtualTerminalIcon_Active':
        return (<MainMenu_VirtualTerminalIcon_Active style={style}/>);
      case 'NoData':
        return (<NoData style={style}/>);
      case 'ActivePaymentLinksIcon':
        return (<LinkIcon style={style}/>);
      case 'InactivePaymentLinksIcon':
        return (<LinkOffIcon style={style}/>);
      case 'TrendDownIcon':
        return (<TrendDownIcon style={style}/>);
      case 'TrendUpIcon':
        return (<TrendUpIcon style={style}/>);
      case 'TrendFlatIcon':
        return (<TrendFlatIcon style={style}/>);
      case 'CustomImportIcon':
        return (<CustomImportIcon style={style}/>);
      case 'AppleIcon':
        return <AppleIcon style={style}/>;
      case 'CashDrawer':
        return (<CashDrawer {...{style}}/>);
      case 'Printer':
        return (<Printer {...{style}}/>);
      case 'RoundedAddIcon':
        return (<RoundedAddIcon {...{style}}/>);
      case 'HowItWorks':
        return (<HowItWorks style={style}/>);
      case 'Launch':
        return (<Launch {...{style}}/>);
      case 'Connect':
        return (<Connect {...{style}}/>);
      case 'IntegrationCheckIcon':
        return (<IntegrationCheckIcon {...{style}}/>);
      case 'PositiveFaceIcon':
        return (<PositiveFaceIcon {...{style}}/>);
      case 'NeutralFaceIcon':
        return (<NeutralFaceIcon {...{style}}/>);
      case 'NegativeFaceIcon':
        return (<NegativeFaceIcon {...{style}}/>);
      case 'BetaIcon':
        return (<BetaIcon style={style}/>);
      case 'ScheduledIcon':
        return (<ScheduledIcon style={style}/>);
      case 'LoyaltyProgramIcon':
        return (<LoyaltyProgramIcon style={style}/>);
      case 'LoyaltyProgramTag':
        return (<LoyaltyIcon style={style}/>);
      case 'LoyaltyVpcSmallTag':
        return (<LoyaltyTagIcon style={style}/>);
      case 'ChargezoomLinkIcon':
        return (<ChargezoomLinkIcon />);
      case 'FeatureIcon':
        return (<FeatureIcon style={style}/>);
      case 'TapToPay':
        return(<TapToPay style={style}/>);
      case 'Loyalty':
        return(<Loyalty style={style}/>);
      case 'ReputationManagement':
        return(<ReputationManagement style={style}/>);
      case 'PremiumPlus':
        return(<PremiumPlus style={style}/>);
      case 'PowerUser':
        return(<PowerUser style={style}/>);
      case 'NorthLogo':
        return(<NorthLogo style={style}/>);
      case 'PaymentsHubLogo':
        return(<PaymentsHubLogo style={style}/>);
      case 'ShareWindows':
        return(<ShareWindows style={style}/>);
      case 'InvoiceDraftIcon':
        return(<InvoiceDraftIcon style={style}/>);
      case 'DocumentIcon':
        return(<DocumentIcon style={style}/>);
      case 'Logout':
        return(<Logout style={style}/>);
      case 'ChatBubbleOutlineIcon':
        return(<ChatBubbleOutlineIcon style={style}/>);
      case 'ManageAccountsIcon':
        return(<ManageAccountsOutlinedIcon style={style}/>);
      case 'LightMode':
        StyledIcon = withMuiStyles(LightModeIcon);
        return (<StyledIcon/>);
      case 'DarkMode':
        StyledIcon = withMuiStyles(DarkModeOutlinedIcon);
        return (<StyledIcon/>);
      case 'RequestQuoteIcon':
        return (<RequestQuoteOutlinedIcon style={style}/>);
      case 'GoToIcon':
        return (<GoToIcon style={style}/>);
      case 'DashedLine':
        return (<DashedLine style={style}/>);
      case 'PaymentIcon':
        return(<PaymentIcon style={style}/>);
      default:
        StyledIcon = withMuiStyles(DefaultNoIcon);
        return (<StyledIcon/>);
    }
  },

  getDepositIcon: function (type) {

    switch (type) {
      case 'Held Transaction(s)':
        return (this.getIcon('Warning', '#ec251b'));
      case 'Resolved Transaction(s)':
        return (this.getIcon('CheckCircle', '#63c21e'));
      case 'Deposit':
        return (this.getIcon('Deposit', '#63c21e'));
      case 'Withdrawal':
        return (this.getIcon('Withdrawal', '#ec251b'));
      case 'SPG Withdrawal':
        return (this.getIcon('Withdrawal', '#ec251b'));
      case 'Processing Fee':
        return (this.getIcon('Processing Fee', '#ec251b'));
    }
  },

  colorForRole(role) {

    let labelColor = LabelUtil.getLabelColor();

    return {
      owner: labelColor,
      admin: labelColor,
      cashier: '#67E418',
      manager: '#35C2FD',
      report: '#E762FF'
    }[role];

  },

  creditCardIcon(code, paymentMethods = false) {
    switch (code) {
      case 'AX': {
        return (!paymentMethods ? <AmexIcon/> : <AmexCardPaymentMethod/>);
      }
      case 'DI': {
        return (!paymentMethods ? <DiscoverIcon/> : <DiscoverCardPaymentMethod/>);
      }
      case 'MC': {
        return (!paymentMethods ? <MastercardIcon/> : <MasterCardPaymentMethod/>);
      }
      case 'VI': {
        return (!paymentMethods ? <VisaIcon/> : <VisaPaymentMethod/>);
      }
    }
  }

};

export default IconUtils;
