/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import numeral from 'numeral';
import _ from 'lodash';

import { Box, MenuItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import CommonUtil from '../util/CommonUtil';
import FormUtil from '../util/FormUtil';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import TextUtil from '../util/FormatTextUtil';
import UserUtil from '../util/UserUtil';
import Validator from '../util/Validator';

import OwnerContainer from './owners/OwnerContainer';
import states from '../../constants/states';
import { setSubmitButtonEnabled } from '../../actions/userExperienceActions';

import CustomTextField from '../shared/TextField';
import Select from '../shared/Select';
import { AccordionStyles, businessProfileFormStyles } from '../../jss/inlineStyles';


export const validate = (values, props) => {
  if (!_.isEmpty(values)) {
    return Validator.validateBusinessForm(values, props);
  } else {
    return {};
  }
};


export class BusinessForm extends Component {

  constructor(props) {
    super(props);

  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    // Enable submit after invalid routing info has been modified
    const accountRoutingChanged = CommonUtil.allValuesTrue(nextProps.validationHistory.giactAccountValid);

    const enableSubmit = FormUtil.shouldEnableSubmit(nextProps) || accountRoutingChanged;
    if (this.props?.userExperience?.enableFormSubmitButton !== enableSubmit) {
      this.props.dispatch(setSubmitButtonEnabled(enableSubmit));
    }
  }

  render() {

    const { handleSubmit, canEdit, initialValues, displayDeleteOwnerDialog, displayOwnerDialog, user, t } = this.props;

    const isDemoAccount = UserUtil.isNorthDemoAccount(user);

    const showOwnersInfo = initialValues?.extraOwners && !isDemoAccount;

    const label = LabelUtil.getLabel();

    const buttonStyle = {
      color: label.primaryColor
    };

    return (
      <form onSubmit={handleSubmit} className='profileBusinessForm'>
        <div className='informationWrapper'>
          <div className='bizDetails'>
            <span>{IconUtils.getIcon('InfoIcon', 'inherited', 20)}</span>
            <div className='informationTile'>
              <span className='informationTitle'>{t('BusinessForm.BusinessFormTitle')}</span>
              <p className='info'>{initialValues.business_name}</p>
              <p className='info'>EIN {initialValues.EIN}</p>
              <p className='info'>{initialValues.corp_name}</p>
              <p className='info'>{initialValues.business_desc}</p>
              <p className='info'>{initialValues.business_type}</p>
            </div>

          </div>

          <div className='transactionsDetails'>
            <span>{IconUtils.getIcon('MainMenu_TransactionsIcon', 'inherited', 20)}</span>
            <div className='informationTile'>
              <span className='informationTitle'>{t('BusinessForm.TransactionalTitle')}</span>
              <p className='info'>{t('BusinessForm.AverageMonthlyVolume', { averageMonthlyVolume: initialValues.monthly_volume ? numeral(initialValues.monthly_volume).format('0,0.00') : numeral(0).format('0,0.00') })}</p>
              <p className='info'>{t('BusinessForm.AverageTicketAmount', { averageTicketAmount: initialValues.avg_ticket ? numeral(initialValues.avg_ticket).format('0,0.00') : numeral(0).format('0,0.00')})}</p>
            </div>

          </div>
        </div>
        <Box className='accordionContainerWrapper' sx={ businessProfileFormStyles }>
          <Accordion sx={{...AccordionStyles.root, ...AccordionStyles.firstItem}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color='labelColor' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>{t('BusinessForm.BusinessAddress')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='fieldsWrapper'>
                {!!initialValues.business_contact && <div className='flexRow' data-test-id='business-contact'>
                  <Field
                    component={CustomTextField}
                    name='business_contact'
                    label={t('BusinessForm.BusinessContact')}
                    className={'fullScreen'}
                    inputPropsStyles={{ cursor: 'not-allowed' }}
                    inputColor={'rgba(0,0,0,0.3)'}
                    disabled={true}
                  />
                </div>}
                <div className='flexRow'>
                    <Field
                      component={CustomTextField}
                      name='business_address'
                      label={t('BusinessForm.BusinessAddress')}
                      className={'threeQuarterScreen'}
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      disabled={!canEdit}
                    />
                    <Field
                      label={t('BusinessForm.SuiteApt')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className={'quarterScreen'}
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='business_address_2'
                    />
                  </div>
                  <div className='flexRow'>
                    <Field
                      component={CustomTextField}
                      name='business_city'
                      label={t('BusinessForm.City')}
                      className={'halfScreen'}
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      disabled={!canEdit}
                    />
                    <Field
                      label={t('BusinessForm.State')}
                      component={Select}
                      className='alignBottom quarterScreen'
                      cursorStyle={!canEdit ? 'not-allowed !important' : null}
                      selectColor={!canEdit ? 'rgba(0, 0, 0, 0.3) !important' : null}
                      name='business_state'
                      ref='business_state'
                      disabled={!canEdit}
                      t={t}
                    >
                      {states.content.map((state, i) => {
                        return <MenuItem value={state} key={i}>{state}</MenuItem>;
                      })}
                    </Field>
                    <Field
                      label={t('BusinessForm.Zip')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className={'quarterScreen'}
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='business_zip'
                      normalize={TextUtil.formatZip}
                    />
                  </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={AccordionStyles.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color='labelColor' />}
              aria-controls='panel2a-content'
              id='panel2a-header'
            >
              <Typography>{t('BusinessForm.CorporateAddress')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='fieldsWrapper'>
                <div>
                  <div className='flexRow'>
                    <Field
                      label={t('BusinessForm.CorporateAddress')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className='threeQuarterScreen alignBottom'
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='corporate_address'
                    />
                    <Field
                      label={t('BusinessForm.CorporateSuiteApt')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className='largeSuiteApt alignBottom paddingLeft'
                      data-test-id='largeSuiteApt'
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='corporate_address_2'
                    />
                  </div>
                  <div className='corporateCityContainer flexRow'>
                    <Field
                      label={t('BusinessForm.CorporateCity')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className='halfScreen alignBottom'
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='corporate_city'
                    />
                    <Field
                      label={t('BusinessForm.State')}
                      component={Select}
                      className='alignBottom quarterScreen paddingLeft'
                      name='corporate_state'
                      cursorStyle={!canEdit ? 'not-allowed !important' : null}
                      selectColor={!canEdit ? 'rgba(0, 0, 0, 0.3) !important' : null}
                      ref='corporate_state'
                      disabled={!canEdit}
                      t={t}
                    >
                      {states.content.map((state, i) => {
                        return <MenuItem value={state} key={i}>{state}</MenuItem>;
                      })}
                    </Field>
                    <Field
                      label={t('BusinessForm.CorporateZip')}
                      component={CustomTextField}
                      disabled={!canEdit}
                      className={'largeZip paddingLeft'}
                      data-test-id='largeZip'
                      inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                      inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                      name='corporate_zip'
                      normalize={TextUtil.formatZip}
                    />
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{...AccordionStyles.root, ...AccordionStyles.lastItem}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color='labelColor' />}
              aria-controls='panel3a-content'
              id='panel3a-header'
            >
              <Typography>{t('BusinessForm.ShippingAddress')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='fieldsWrapper'>
                <div>
                    <div className='flexRow'>
                      <Field
                        label={t('BusinessForm.ShippingName')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className='halfScreen alignBottom'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_name'
                      />
                      <Field
                        label={t('BusinessForm.ShippingContact')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className='halfScreen alignBottom'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_contact'
                      />
                    </div>
                    <div className='flexRow'>
                      <Field
                        label={t('BusinessForm.ShippingAddress')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className='threeQuarterScreen alignBottom'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_address_1'
                      />
                      <Field
                        label={t('BusinessForm.ShippingSuiteApt')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className='largeSuiteApt alignBottom paddingLeft'
                        data-test-id='largeSuiteApt'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_address_2'
                      />
                    </div>
                    <div className='flexRow'>
                      <Field
                        label={t('BusinessForm.ShippingCity')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className='halfScreen alignBottom'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_city'
                      />
                      <Field
                        label={t('BusinessForm.State')}
                        component={Select}
                        className='alignBottom quarterScreen paddingLeft'
                        name='shipping_state'
                        cursorStyle={!canEdit ? 'not-allowed !important' : null}
                        selectColor={!canEdit ? 'rgba(0, 0, 0, 0.3) !important' : null}
                        ref='shipping_state'
                        disabled={!canEdit}
                        t={t}
                      >
                        {states.content.map((state, i) => {
                          return <MenuItem value={state} key={i}>{state}</MenuItem>;
                        })}
                      </Field>
                      <Field
                        label={t('BusinessForm.ShippingZip')}
                        component={CustomTextField}
                        disabled={!canEdit}
                        className={'largeZip paddingLeft'}
                        data-test-id='largeZip'
                        inputPropsStyles={!canEdit ? { cursor: 'not-allowed' } : null}
                        inputColor={!canEdit ? 'rgba(0,0,0,0.3)' : null}
                        name='shipping_zip'
                        normalize={TextUtil.formatZip}
                      />
                    </div>
                  </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
        <div className='fieldsWrapper'>
          {showOwnersInfo &&
          <OwnerContainer
            data={initialValues}
            labelStyle={buttonStyle}
            displayDeleteOwnerDialog={displayDeleteOwnerDialog}
            displayOwnerDialog={displayOwnerDialog}
            canEdit={canEdit}
            t={t}
          />}
        </div>
      </form>);
  }
}

export const Business = reduxForm({
  form: 'businessForm',
  validate,
  fields: [
    'business_address',
    'business_address_2',
    'business_city',
    'business_state',
    'business_zip',
    'business_phone',
    'business_fax',
    'shipping_name',
    'shipping_contact',
    'shipping_address_1',
    'shipping_address_2',
    'shipping_city',
    'shipping_state',
    'shipping_zip',
    'corporate_address',
    'corporate_address_2',
    'corporate_city',
    'corporate_state',
    'corporate_zip',
    'additionalCorporateInfo',
    'additionalInfo'
  ],
  enableReinitialize: true
})(BusinessForm);

const selector = formValueSelector('businessForm');

export function mapStateToProps(state, ownProps) {

  let initialValues = state.accountProfile?.data ? state.accountProfile.data : {};
  initialValues.business_phone = TextUtil.formatPhoneNumber(initialValues.business_phone);
  initialValues.fax = TextUtil.formatPhoneNumber(initialValues.fax);
  initialValues.additionalCorporateInfo = !!(initialValues && initialValues.corporate_address);
  initialValues.additionalInfo = !!(initialValues && initialValues.shipping_address_1);
  initialValues.business_contact = initialValues?.extraOwners?.find(owner => owner.is_primary_contact)?.principal;

  const additionalCorporateInfo = selector(state, 'additionalCorporateInfo');
  const additionalInfo = selector(state, 'additionalInfo');

  return {
    initialValues,
    additionalCorporateInfo,
    additionalInfo
  };

}

export default connect(mapStateToProps)(Business);
