/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import moment from 'moment';
import FilterPanel from '../shared/FilterPanel';
import IconUtils from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import PdfExporter from '../util/PdfExporter';
import { Link } from 'react-router-dom';
import { getPCI, getPciEnvelope, getPciDoc } from '../../actions/pciActions';
import UserUtil from '../util/UserUtil';
import PciFaq from './PciFaq';
import UpdateSpinner from '../UpdateSpinner';
import Modal from '../shared/Modal';
import { dialogStyles } from '../../jss/inlineStyles';
import Page from '../shared/Page';

export default class Pci extends Component {

  constructor(props) {

    super(props);

    this.state = {
      openFaq: false,
      pdfRequest: false
    };

    this.loadData = this.loadData.bind(this);
    this.handleOpenFaq = this.handleOpenFaq.bind(this);
    this.handleCloseFaq = this.handleCloseFaq.bind(this);
    this.getPciDocs = this.getPciDocs.bind(this);

  }

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    props.dispatch(getPCI(props.user));
    props.dispatch(getPciEnvelope(props.user));
  }

  getPciDocs() {
    this.setState({ pdfRequest: true });
    const that = this;
    const pciInfo = UserUtil.getSaq(this.props.user.pciEnvelopes);
    return this.props.dispatch(getPciDoc(that.props.user, pciInfo)).then((res) => {
      that.setState({ pdfRequest: false });
      PdfExporter.download(res.response.data, pciInfo.name);
    });
  }

  handleOpenFaq() {
    this.setState({ openFaq: true });
  }

  handleCloseFaq() {
    this.setState({ openFaq: false });
  }

  render() {

    const { user, t } = this.props;

    const labelColor = LabelUtil.getLabelColor();

    const alertIcon = IconUtils.getIcon('ErrorIcon', 'red');
    const checkIcon = IconUtils.getIcon('CheckCircle', 'green');
    const alarmIcon = IconUtils.getIcon('AlarmIcon', '#ffcc00');
    const docIcon = IconUtils.getIcon('DocIcon', labelColor);
    const downloadIcon = IconUtils.getIcon('CustomImportIcon', labelColor);
    const mid = UserUtil.getActiveAccount(user).mid;
    const isHumboldt = UserUtil.isHumbolt(user);

    const i18TranslationsKeys = {
      pageTitle: t('Business Settings'),
      subPage: t('PCI Compliance'),
      contactInformation: {
        humboldt: {
          listText: 'PCICompliance.humboldt.listText',
          description: 'PCICompliance.humboldt.description',
          contactEmail: 'compliance@hbms.com',
          faqContactText: 'PCICompliance.humboldt.faqContactText',
          faqContactTextTwo: 'PCICompliance.humboldt.faqContactTextTwo'
        },
        paymentsHub: {
          listText: 'PCICompliance.paymentsHub.listText',
          description: 'PCICompliance.paymentsHub.description',
          contactEmail: 'pciplus@paymentshub.com',
          faqContactText: 'PCICompliance.paymentsHub.faqContactText',
          faqContactTextTwo: 'PCICompliance.paymentsHub.faqContactTextTwo'
        }
      }
    }

    const textToDisplay = isHumboldt ? i18TranslationsKeys.contactInformation.humboldt : i18TranslationsKeys.contactInformation.paymentsHub;

    const filterPanel = (
      <FilterPanel
          {...this.props}
          filterData={this.props.settingsMenuItems}
          selectFilterCallback={this.props.handleMenuSelection}
      />
    );

    const isEmpty = (user.pciEnvelopes === null);

    if (isEmpty) {
      return (
        <Page
          loading
          title={i18TranslationsKeys.pageTitle}
          initialSubpage={i18TranslationsKeys.subPage}
        />
      )
    }

    const downloading =  this.state.pdfRequest && <UpdateSpinner/>;
    const isCompliant =  user.pci && user.pci.compliant;
    const hasEnvelopes =  user.pciEnvelopes && user.pciEnvelopes.length;

    const complianceDate =  user.pci && user.pci.compliance_date !== '' ? moment(user.pci.compliance_date).add(1, 'years').format('MM/DD/YYYY') : null;
    const saqDate =  user.pci && user.pci.saq_date ? moment(user.pci.saq_date).format('MM/DD/YYYY') : null;
    const scanDate =  user.pci && user.pci.scan_date ? moment(user.pci.scan_date).format('MM/DD/YYYY') : null;
    const billedDate =  user.pci && user.pci.last_billed_date !== '' ? moment(user.pci.last_billed_date).format('MM/DD/YYYY') : null;


    return (
      <section className='accountReceiptSettings'>
        <div className='pageWrap'>
          <Page
            loading={isEmpty}
            title={i18TranslationsKeys.pageTitle}
            initialSubpage={i18TranslationsKeys.subPage}
          >
            <>
              <div className='flexContainer flexContainerResponsiveLayout'>
                {filterPanel}
                <div className='businessSettingsSubsection pciHolder'>
                  <h2 className='heading'>{t('PCICompliance.title')}</h2>
                  <p className='subText'>{t(textToDisplay.description)}</p>
                  <div className='pciSteps'>
                    {isCompliant === 'YES' ? <p className='compliant'><span
                        className='icon'
                      >{checkIcon}</span>{ complianceDate ? t('PCICompliance.meetsComplianceWithDate', {complianceDate}) : t('PCICompliance.meetsComplianceWithoutDate')}
                      </p> :
                      isCompliant === 'NO' ?
                        <p className='alert'><span
                          className='icon'
                        >{alertIcon}</span>{t('PCICompliance.alarm')}</p> :
                        <p className='pending'><span
                          className='icon'
                        >{alarmIcon}</span>{t('PCICompliance.nonCompliant')}</p>}
                    <p className='step'>{t(textToDisplay.listText)}</p>
                    <ul className='stepList'>
                      {!isHumboldt ? <li className='item'>
                        <Link className='link'
                              target='_blank'
                              to='/files/PCI_Training.pdf'
                        >
                          <span className='file'>{docIcon}</span>
                          <span className='text'>{t('PCICompliance.downloadPCIPlusTraining')}</span>
                          <span className='download'>{downloadIcon}</span>
                        </Link>
                      </li> : null}
                      <li className='item'>
                        <Link className='link'
                              target='_blank'
                              to='/files/Information_Security_Policy.pdf'
                        >
                          <span className='file'>{docIcon}</span>
                          <span
                            className='text'
                          >{t('PCICompliance.downloadInformationSecurityPolicy')}</span>
                          <span className='download'>{downloadIcon}</span>
                        </Link>
                      </li>
                      {hasEnvelopes ? <li className='item'>
                        <div className='link'
                             onClick={this.getPciDocs}
                        >
                          <span className='file'>{docIcon}</span>
                          <span
                            className='text'
                          >{t('PCICompliance.downloadSAQ')}</span>
                          <span className='download'>{downloadIcon}</span>
                        </div>
                      </li> : null}
                    </ul>
                    <p className='notice'>{t('PCICompliance.informationNeeded')} <a
                        href={`mailto:${textToDisplay.contactEmail}?subject=${mid}`}
                        id='complianceMailLink'
                        style={{
                          color: labelColor,
                          textDecoration: 'none'
                        }}
                        target='_blank'
                      >{textToDisplay.contactEmail}</a>
                    </p>
                  </div>
                  <div className='columnHolder'>
                    {saqDate && <div className='column'>
                      <span className='title'>{t('PCICompliance.saqDateTitle')}</span>
                      <span className='text'>{saqDate}</span>
                    </div>}
                    {scanDate && <div className='column'>
                      <span className='title'>{t('PCICompliance.scanDateTitle')}</span>
                      <span className='text'>{scanDate}</span>
                    </div>}
                    {billedDate && <div className='column'>
                      <span className='title'>{t('PCICompliance.billingDateTitle')}</span>
                      <span
                        className='text'
                      >{t('PCICompliance.billingDateText')}</span>
                    </div>}
                  </div>
                  <p className='learnMore'>{t('PCICompliance.learnMore')} <span
                    onClick={this.handleOpenFaq}
                    style={{color: labelColor}}
                  >{t('PCICompliance.learnMoreCTA')}</span></p>
                </div>
              </div>
              {<Modal
                hideActions
                maxWidth='md'
                onClose={this.handleCloseFaq}
                open={this.state.openFaq}
                paperProps={dialogStyles()}
                title={t('PCICompliance.FAQS.title')}
              >
                <PciFaq
                  contactEmail={textToDisplay.contactEmail}
                  contactText={t(textToDisplay.faqContactText)}
                  contactTextTwo={t(textToDisplay.faqContactTextTwo)}
                  t={t}
                />
              </Modal>}
              {downloading}
            </>
          </Page>
        </div>
      </section>
    );
  }
}
