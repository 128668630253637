/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import { setActiveFilterHeader } from '../../actions/userExperienceActions';
import MasterList from '../MasterList';
import NoData from '../NoData';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextUtil from '../util/FormatTextUtil';
import TableHeader from '../shared/TableHeader';
import CustomerDirectoryHeader from '../CustomerDirectoryHeader';
import IconUtils from '../util/IconUtil';
import PaymentUtil from '../util/PaymentUtil';


export default class CustomerDirectory extends Component {
  constructor(props) {
    super(props);

  }

  componentWillUnmount() {
    this.props.dispatch(setActiveFilterHeader(null));
  }

  render() {
    const { customers, t, selectedIndex, onCellClick, paginationComponent, isLoyaltyEnabled } = this.props;

    const titlePanel = customers.filteredData && customers.filteredData.length > 0 ? (<CustomerDirectoryHeader {...this.props}/>) : null;

    const listHeaders = ((customers.filteredData && customers.filteredData.length > 0) &&
      <TableRow className={'tableRow'}>
        <TableHeader
          type='table'
          clickable={true}
          attribute={'first_name'}
          headerId={'customerHeaderOne'}
          orthographicException
          name={t('Name')}
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'customerHeaderTwo'}
          name={t('EmailAddress')}
          clickable={true}
          attribute={'email_addresses'}
          className='hideMobileOnly'
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'customerHeaderThree'}
          name={t('CustomerTable.PhoneNumber')}
          className='hideMobileOnly'
          {...this.props}/>
        <TableHeader
          type='table'
          headerId={'customerHeaderFour'}
          clickable={true}
          attribute={'transaction_count'}
          name={t('Transactions')}
          className='hideMobileOnly'
          {...this.props}/>
        {isLoyaltyEnabled && (
          <TableHeader
            type='table'
            headerId={'customerHeaderFive'}
            clickable={true}
            attribute={'loyalty_vpc_status.reward_is_eligible'}
            name={t('CustomerTable.ActiveReward')}
            className='activeReward hideMobileOnly'
            {...this.props}/>
        )}
        <TableHeader
          type='table'
          headerId={'customerHeaderSix'}
          clickable={true}
          attribute={'default_payment_method.last4'}
          name={t('CustomerTable.CardOnFile')}
          className='defaultCard hideMobileOnly'
          {...this.props}/>
      </TableRow>
    ) || null;

    const customerRows = (customers.filteredData && customers.filteredData.length > 0) ?
      customers.filteredData.map((customer, i) => {
        const paymentMethodRenderImg = customer?.default_payment_method?.network || customer?.default_payment_method?.type;
        const tableRowClassName = selectedIndex === i ? 'tableRow selectedListRow customerRow' : 'tableRow customerRow';
        const loyaltyProgramIcon = IconUtils.getIcon('LoyaltyProgramIcon', '#3e3935');
        const cardSrc = PaymentUtil.getCardImage(paymentMethodRenderImg).cardImgUrl;
        const { loyalty_vpc_status } = customer;
        const hasLoyaltyReward = loyalty_vpc_status?.opted_in_loyalty && loyalty_vpc_status?.reward_is_eligible;

        return (
          <TableRow key={i} className={tableRowClassName}>
            <TableCell>
              {TextUtil.formatName(customer.first_name && customer.first_name.trim(), customer.last_name && customer.last_name.trim(), t('CustomerTable.Unnamed'))}
            </TableCell>
            <TableCell className='hideMobileOnly'>
              {customer.email_addresses[0]}
            </TableCell>
            <TableCell className='hideMobileOnly'>
              {TextUtil.formatPhoneNumber(customer.phone_number)}
            </TableCell>
            <TableCell className='hideMobileOnly'>
              {TextUtil.formatNumberWithTrailingText(customer.transaction_count, t('Transactions'))}
            </TableCell>
            {isLoyaltyEnabled && (
              <TableCell className='hideMobileOnly'>
                <div className='loyaltyIcon'>
                  {hasLoyaltyReward && loyaltyProgramIcon}
                </div>
              </TableCell>
            )}
            <TableCell className='hideMobileOnly'>
              {paymentMethodRenderImg && <img className='savedCardImage' src={cardSrc}  width='70' height='40' />}
            </TableCell>
          </TableRow>
        )
      })
    :
      (<NoData text='No Customers' type='table'/>);

    const customerList = (
      <TableBody className='customerList'>
        {listHeaders}
        {customerRows}
      </TableBody>
    );

    return (
      <section className='customerDirectory'>
        <MasterList
          header={titlePanel}
          tableData={customers.filteredData}
          tableRowLayout={customerList}
          onItemClick={onCellClick}
          paginationComponent={paginationComponent}
        />
      </section>
    )
  }
}
