/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import { Box } from '@mui/material';
import { cleanImportLog, onDropFileFailed } from '../../actions/itemsActions';
import {
  cancelBulkInvoice,
  getBulkInvoice,
  getBulkInvoiceReport,
  uploadBulkInvoice,
  sendBulkInvoices,
  completeBulkImport,
  discardBulkInvoice,
  getBulkInvoicesReport,
  setCustomMapping,
  closeImportDialog,
  deleteBulkInvoicesMappingsById,
  getBulkInvoicesMappings,
  setExistingMapping, setBulkParseError
} from '../../actions/bulkInvoicesActions';
import { submit, destroy } from 'redux-form';
import BulkList from './bulkInvoicing/bulkList';
import BulkLanding from './bulkInvoicing/bulkLanding';
import UploadProgressDialog from './bulkInvoicing/uploadProgressDialog';
import Modal from '../shared/Modal';
import BulkImportDialog from './bulkInvoicing/bulkImportDialog';
import SendConfirmationDialog from './bulkInvoicing/sendConfirmationDialog';
import BulkConfirmationPage from './bulkInvoicing/bulkConfirmationPage';
import { BulkInvoicesStyles } from '../../jss/inlineStyles'
import {
  setSubmitButtonEnabled,
  suppressAppError,
  toggleNorthGatekeeper
} from '../../actions/userExperienceActions';
import DiscardConfirmationDialog
  from './bulkInvoicing/discardConfirmationDialog';
import { BulkInvoicesSteps } from '../../constants/bulkInvoices';
import UserUtil from '../util/UserUtil';
import routes from '../../constants/routes';
import { defaultTemplateFields } from '../../constants/bulkInvoices';
import SaveMappingSettingDialog from './bulkInvoicing/saveMappingSettingDialog';
import CsvUtil from '../util/CsvUtil';
import ManageSavedMappingDialog from './bulkInvoicing/manageSavedMappingDialog';

class BulkInvoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasCustomFields: false,
      bulkInvoicesSent: false,
      openImportBulkingDialog: false,
      openProgressImportDialog: false,
      importDetails: {
        fileStatus: false,
        fileName: false
      },
      openBulkInvoiceDetails: false,
      openConfirmationDialog: false,
      openDiscardDialog: false,
      openSaveMappingSettingDialog: false,
      openManageSavedMappingDialog: false,
      resolvingErrors: false
    }
  }

  componentDidMount() {
    const {dispatch, user} = this.props;
    dispatch(getBulkInvoicesMappings(user));
  }

  componentDidUpdate(prevProps) {
    const {bulkImports, dispatch, user} = this.props;

    if ((bulkImports.bulkInvoiceUpload !== prevProps.bulkImports.bulkInvoiceUpload) && bulkImports.bulkInvoiceUpload) {
      dispatch(destroy('bulkInvoicesMapFieldsForm'));
      this.setState({
        importInProgress: false,
        openImportBulkingDialog: false,
        hasCustomFields: false
      });
      dispatch(getBulkInvoicesMappings(user));
    }

    if (bulkImports.bulkInvoiceId !== prevProps.bulkImports.bulkInvoiceId) {
      dispatch(getBulkInvoicesMappings(user));
      const invoiceDetailsAvailable = [BulkInvoicesSteps.READY, BulkInvoicesSteps.INPROGRESS].includes(bulkImports?.bulkInvoice?.status);
      if(bulkImports.bulkInvoiceId && invoiceDetailsAvailable){
        dispatch(getBulkInvoice(user, bulkImports.bulkInvoiceId, true))
      }
    }

    if ((bulkImports.bulkInvoiceUploadError !== prevProps.bulkImports.bulkInvoiceUploadError) && bulkImports.bulkInvoiceUploadError) {
      if (bulkImports.bulkInvoiceUploadError !== 'Conflict') {
        this.onFileRemove();
      }
    }

    if ((prevProps.bulkImports.bulkInvoice?.progress !== bulkImports.bulkInvoice?.progress) && bulkImports.bulkInvoice?.progress === 100) {
      this.closeProcessDialog();
    }


  }

  toggleBulkingInvoicesDialog = () => {
    this.props.dispatch(setSubmitButtonEnabled(false));
    this.setState(prevState => ({openImportBulkingDialog: !prevState.openImportBulkingDialog}))
  }

  toggleConfirmationDialog = () => {
    this.setState(prevState => ({openConfirmationDialog: !prevState.openConfirmationDialog}))
  }

  toggleDiscardDialog = () => {
    this.setState(prevState => ({openDiscardDialog: !prevState.openDiscardDialog}))
  }

  toggleSaveMappingSettingDialog = () => {
    this.setState(prevState => ({openSaveMappingSettingDialog: !prevState.openSaveMappingSettingDialog}))
  }

  toggleManageSavedMappingDialog = () => {
    this.setState(prevState => ({openManageSavedMappingDialog: !prevState.openManageSavedMappingDialog}))
  }

  toggleConfirmationPage = () => {
    this.setState(prevState => ({
      bulkInvoicesSent: !prevState.bulkInvoicesSent,
    }))
  }

  onFileDrop = (files, details) => {

    const {dispatch, t} = this.props;
    dispatch(cleanImportLog());

    if (files[0]?.name) {

      this.setState({
        importDetails: {
          fileStatus: t('ImportedFileUploaded', {fileName: files[0].name}),
          fileName: files[0].name
        }
      });
      this.props.dispatch(setSubmitButtonEnabled(true));

    } else {

      let errorMessage;
      const errorCode = details && details[0]?.errors && details[0]?.errors[0]?.code;

      if (errorCode === 'file-invalid-type') {
        errorMessage = 'OnDropFile.InvalidType';
      } else if (errorCode === 'file-too-large') {
        errorMessage = 'OnDropFile.ExceedsSize';
      } else {
        errorMessage = 'OnDropFile.ImportFailed'
      }
      dispatch(onDropFileFailed(true, t(errorMessage)));
    }

  }

  onFileRemove = () => {
    this.setState({
      importDetails: {
        fileStatus: false,
        fileName: false
      },
    });
    this.props.dispatch(setSubmitButtonEnabled(false));
    this.props.dispatch(setExistingMapping(null));
  }

  processUploadedCsvFile = async (values) => {
    const {dispatch, user, t} = this.props;

    this.setState({
      importInProgress: true,
      importDetails: {
        fileStatus: t('ImportInProgress', {fileName: this.state.importDetails.fileName}),
      }
    });
    let formData = new FormData();
    const file = values.bulk_invoice_template[0];
    formData.append('bulk-invoice', file);
    dispatch(suppressAppError(true));
    return CsvUtil.mapBulkImportHeaders(file).then((res) => {
      const fieldsValidation = !_.isEqual(res.headers, defaultTemplateFields);
      const headerLengthCheck = res.headers.length < defaultTemplateFields.length;
      if (fieldsValidation && !headerLengthCheck) {
        dispatch(setCustomMapping({
          headers: res.headers,
          firstRow: res.rows
        }));
        this.setState({hasCustomFields: true})
      } else {
        dispatch(uploadBulkInvoice(user, formData))
      }
      return
    }).catch((err) => {
      dispatch(setBulkParseError(err));
    });
  }

  onUploadClick = () => {
    const {user, dispatch} = this.props;
    if (UserUtil.isNorthDemoAccount(user)) {
      dispatch(toggleNorthGatekeeper(true));
      return;
    }
    this.toggleProcessDialog();
    this.toggleBulkingInvoicesDialog();
    this.props.dispatch(submit('importBulkingInvoicesForm'));
  }

  toggleProcessDialog = () => {
    this.setState(prevState => ({openProgressImportDialog: !prevState.openProgressImportDialog}))
  }

  handleSubmitMapCustomFields = () => {
    const {bulkImports} = this.props;
    if (bulkImports.bulkSelectedExistingMapping) {
      this.submitBulkWithExistingCustomMappings();
    } else {
      this.toggleSaveMappingSettingDialog();
    }
  }

  submitBulkWithExistingCustomMappings = () => {
    const {dispatch, user, formReducer, bulkImports} = this.props;
    this.setState({hasCustomFields: false});
    let formData = new FormData();
    const formValues = formReducer.importBulkingInvoicesForm.values.bulk_invoice_template[0];
    formData.append('mappingType', 'mapping_id');
    formData.append('mappingValue', bulkImports.bulkSelectedExistingMapping.id.toString());
    formData.append('bulk-invoice', formValues);
    dispatch(uploadBulkInvoice(user, formData));
    dispatch(destroy('bulkInvoicesMapFieldsForm'));
  }

  submitBulkWithNewCustomMapping = (name) => {
    const {dispatch, user, formReducer} = this.props;
    this.setState({hasCustomFields: false});
    let formData = new FormData();
    const formValues = formReducer.importBulkingInvoicesForm.values.bulk_invoice_template[0];
    const values = formReducer.bulkInvoicesMapFieldsForm.values;
    const formattedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [value, key])
    );

    const mappingValues = {fields: formattedValues};

    if (Boolean(name)) {
      mappingValues.name = name;
      mappingValues.save = true;
    }

    formData.append('mappingType', 'mapping');
    formData.append('mappingValue', JSON.stringify(mappingValues));
    formData.append('bulk-invoice', formValues);
    dispatch(uploadBulkInvoice(user, formData));
    this.toggleSaveMappingSettingDialog();
  }

  closeProcessDialog = () => {
    this.setState({openProgressImportDialog: false, hasCustomFields: false})
    this.props.dispatch(closeImportDialog());
    this.onFileRemove();
    this.props.dispatch(destroy('bulkInvoicesMapFieldsForm'));
  }

  toggleBulkingDetails = () => {
    this.setState(prevState => ({openBulkInvoiceDetails: !prevState.openBulkInvoiceDetails}));
  }

  showBulkingDetails = () => {
    const {bulkImports} = this.props;
    const {bulkInvoice} = bulkImports;
    this.setState({openBulkInvoiceDetails: true});
    if (bulkInvoice.status === BulkInvoicesSteps.READY && (!bulkInvoice.series && !bulkInvoice.bulkImports)) {
      this.getImportsDetails();
    }
  }

  getImportsProgress = () => {
    const {dispatch, bulkImports, user} = this.props;
    if (bulkImports.bulkInvoiceId && ![BulkInvoicesSteps.READY, BulkInvoicesSteps.CANCELED, BulkInvoicesSteps.COMPLETED].includes(bulkImports?.bulkInvoice?.status)) {
      dispatch(getBulkInvoice(user, bulkImports.bulkInvoiceId))
    }
  }

  getImportsDetails = () => {
    const {dispatch, bulkImports, user} = this.props;
    dispatch(getBulkInvoice(user, bulkImports.bulkInvoiceId, true));
  }

  getImportsReport = () => {
    const {dispatch, bulkImports, user} = this.props;
    return dispatch(getBulkInvoiceReport(user, bulkImports.bulkInvoiceId));
  }

  cancelBulkImport = () => {
    const {dispatch, bulkImports, user} = this.props;
    this.setState({
      openProgressImportDialog: false,
      openBulkInvoiceDetails: false,
      openDiscardDialog: false,
      hasCustomFields: false,
    });

    if (bulkImports.bulkInvoice.status === BulkInvoicesSteps.INPROGRESS) {
      dispatch(cancelBulkInvoice(user, bulkImports.bulkInvoiceId))
    } else if (bulkImports.bulkInvoice.status === BulkInvoicesSteps.COMPLETED) {
      dispatch(completeBulkImport());
    } else {
      dispatch(discardBulkInvoice(user, bulkImports.bulkInvoiceId))
    }
    dispatch(getBulkInvoicesReport(user));
    this.onFileRemove();
  }

  onSendClick = () => {
    const {dispatch, bulkImports, user} = this.props;
    this.toggleConfirmationDialog();
    this.toggleConfirmationPage();
    dispatch(sendBulkInvoices(user, bulkImports.bulkInvoiceId))
  }

  onBulkInvoiceDoneClick = () => {
    const {dispatch, resolveInvoices, user} = this.props;
    this.toggleBulkingDetails();
    this.toggleConfirmationPage();
    this.onFileRemove();
    dispatch(completeBulkImport());
    dispatch(getBulkInvoicesReport(user));
    resolveInvoices();
  }

  onResolveErrorsClick = () => {
    this.setState({ resolvingErrors: true });
    this.getImportsDetails();
    this.toggleConfirmationPage();
  }

  onViewInvoices = () => {
    const {bulkImports, history} = this.props;
    const bulkImportsRoute = `${globalApplicationLabel.path}${routes.business.root}${routes.business.invoices}?bulkId=${bulkImports.bulkInvoiceId}`;
    history.push(bulkImportsRoute);
    location.reload();
  }

  onUnMountAction = () => {
    const {dispatch, resolveInvoices, user} = this.props;
    const {resolvingErrors} = this.state;

    if (resolvingErrors) {
      this.setState({ resolvingErrors: false });
      return;
    }

    this.onFileRemove();
    this.setState({
      openBulkInvoiceDetails: false,
      bulkInvoicesSent: false,
      hasCustomFields: false,
    })
    dispatch(completeBulkImport());
    dispatch(getBulkInvoicesReport(user));
    resolveInvoices();
  }

  onCloseImportDialog = () => {
    this.toggleBulkingInvoicesDialog();
    this.onFileRemove();
  }

  onDeleteBulkImportMappingClick = async (mappingId) => {
    const {dispatch, user} = this.props;
    await dispatch(deleteBulkInvoicesMappingsById(user, mappingId));
    await dispatch(getBulkInvoicesMappings(user));

    if(!this.props.bulkImports.bulkImportExistingMappings?.length) {
      this.setState({openManageSavedMappingDialog: false});
    }
  }

  handleSelectMapping = (mappingId) => {
    this.props.dispatch(setExistingMapping(mappingId))
  }

  onGoBackProcessDialog = () => {
    const {dispatch} = this.props;
    dispatch(closeImportDialog());
    this.setState({hasCustomFields: true});
    this.toggleSaveMappingSettingDialog();
    dispatch(suppressAppError(true));
  }

  render() {
    const {
      bulkImports,
      t,
      userExperience,
      resolveInvoices,
      user,
      auth
    } = this.props;
    const bulkInvoiceCreated = bulkImports.bulkInvoiceId && Object.keys(bulkImports.bulkInvoice).length;
    const isCSImpersonate = UserUtil.isUserCS(user, auth);
    const isCSWriteUser = UserUtil.isUserCSWrite(user);
    const submitButtonDisabled = !userExperience.enableFormSubmitButton || isCSWriteUser || isCSImpersonate;
    const uploadCsvModal = (
      <Modal
        centerTitle
        cancelText={t('Cancel')}
        confirmText={t('Next')}
        hideConfirmButton={false}
        isCancelDisabled={false}
        isConfirmDisabled={submitButtonDisabled}
        maxWidth='md'
        onClose={this.onCloseImportDialog}
        onConfirm={this.onUploadClick}
        open={this.state.openImportBulkingDialog}
        title={t('ImportInvoices')}
      >
        <BulkImportDialog
          onFileDrop={this.onFileDrop}
          onFileRemove={this.onFileRemove}
          onSubmit={this.processUploadedCsvFile}
          {...this.state}
          t={t}
        />
      </Modal>
    );

    return (
      <Box sx={BulkInvoicesStyles.mainWrapper}>
        <SaveMappingSettingDialog
          isOpen={this.state.openSaveMappingSettingDialog}
          onSave={this.submitBulkWithNewCustomMapping}
          onClose={this.toggleSaveMappingSettingDialog}
          t={t}
        />
        <ManageSavedMappingDialog
          savedMappings={bulkImports.bulkImportExistingMappings}
          isOpen={this.state.openManageSavedMappingDialog}
          onClose={this.toggleManageSavedMappingDialog}
          t={t}
          handleDeleteSavedMappings={this.onDeleteBulkImportMappingClick}
        />
        {this.state.bulkInvoicesSent ?
          <BulkConfirmationPage
            isFetching={bulkImports.isFetching}
            onMountAction={this.getImportsReport}
            onUnMountAction={this.onUnMountAction}
            onViewAllClick={resolveInvoices}
            details={bulkImports.bulkInvoice}
            onDoneClick={this.onBulkInvoiceDoneClick}
            onResolveErrorsClick={this.onResolveErrorsClick}
            t={t}
            results={bulkImports.bulkResult}
            onViewInvoicesClick={this.onViewInvoices}
          /> :
          bulkInvoiceCreated ?
            <BulkList
              {...this.props}
              isFetching={bulkImports.isFetching}
              bulkDetails={bulkImports.bulkInvoice}
              onCancel={this.toggleDiscardDialog}
              onContinueClick={this.showBulkingDetails}
              onDetailClick={this.toggleProcessDialog}
              onSendClick={this.toggleConfirmationDialog}
              toggleConfirmationPage={this.toggleConfirmationPage }
              showDetails={this.state.openBulkInvoiceDetails}
              t={t}
              disabledImport={isCSImpersonate || bulkImports.isFetching}
            />
            : <>
              <BulkLanding
                disabledImport={isCSImpersonate}
                onClickImport={this.toggleBulkingInvoicesDialog}
                t={t}
              />
              {uploadCsvModal}
            </>
        }

        <UploadProgressDialog
          bulkImport={bulkImports}
          hasCustomFields={this.state.hasCustomFields}
          getProgress={this.getImportsProgress}
          importInProgress={this.state.importInProgress}
          isOpen={this.state.openProgressImportDialog}
          onCancel={this.toggleDiscardDialog}
          onClose={this.closeProcessDialog}
          onGoBack={this.onGoBackProcessDialog}
          submitCustomMappingFields={this.handleSubmitMapCustomFields}
          handleManageSavingMappings={this.toggleManageSavedMappingDialog}
          handleSelectMapping={this.handleSelectMapping}
          t={t}
        />
        {bulkInvoiceCreated &&
          <SendConfirmationDialog
            details={bulkImports.bulkInvoice}
            isOpen={this.state.openConfirmationDialog}
            onClose={this.toggleConfirmationDialog}
            onSend={this.onSendClick}
            t={t}
          />
        }
        <DiscardConfirmationDialog
          isOpen={this.state.openDiscardDialog}
          onClose={this.toggleDiscardDialog}
          onDiscard={this.cancelBulkImport}
          t={t}
        />

      </Box>
    )
  }
}

export default BulkInvoices;
