import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

import { CALL_API, encryptPayload, helperGetCSRFCookieToken } from '../middleware/api';

import actionTypes from '../constants/actionTypes';
import twoFactorRequestTypes from '../constants/twoFactorRequestTypes';
import messages from '../constants/messages';

import LabelUtil from '../components/util/LabelUtil';
import UserUtil from '../components/util/UserUtil';
import CommonUtil from '../components/util/CommonUtil';

import i18n from '../locales/i18n';

import { getCsSettings } from './merchantSettingsActions';
import { toggleAchMarketingDialog } from './userExperienceActions';

import generalOptions from '../constants/generalOptions';

const { isJSON, bodyParamBuilder, uuidUtil } = CommonUtil;

export function requestLogin() {
  return {
    type: actionTypes.loginRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function receiveLogin(account) {

  return {
    type: actionTypes.loginSuccess,
    isFetching: false,
    isAuthenticated: true,
    token: account.token
  };
}

export function loginError(message) {
  return {
    type: actionTypes.loginFailure,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function loginFormError(message) {
  return {
    type: actionTypes.loginFormError,
    message
  };
}

export function requestLogout() {
  return {
    type: actionTypes.logoutRequest,
    isFetching: true,
    isAuthenticated: true
  };
}

export function clearPayNowCardPresent() {

  localStorage.removeItem('pa-cp');
  localStorage.removeItem('pa-cp-t');

  return {
    type: actionTypes.clearPayNowCardPresent,
  };
}

export function receiveLogout() {

  localStorage.removeItem('pa-token');
  localStorage.removeItem('pa-u');
  localStorage.removeItem('pa-ma');
  localStorage.removeItem('pa-cart');
  localStorage.removeItem('pa-location');
  localStorage.removeItem('pa-mid');
  localStorage.removeItem('pa-geoTax');
  localStorage.removeItem('pa-tax');
  localStorage.removeItem('pa-dateRange');
  localStorage.removeItem('pa-ux');
  localStorage.removeItem('pa-demo-trial');

  return {
    type: actionTypes.logoutSuccess,
    isFetching: false,
    isAuthenticated: false
  };
}

export function requestAccount() {
  return {
    type: actionTypes.accountRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function requestForceResetPasswordLoginAccount() {
  return {
    type: actionTypes.forceResetPasswordLoginRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function requestSetup2faLogin() {
  return {
    type: actionTypes.setup2faLoginRequest,
    isFetching: true,
    isAuthenticated: false
  };
}

export function receiveTermsAccount(newToken, newUser, newMerchant) {
  localStorage.setItem('pa-token', newToken);
  localStorage.setItem('pa-u', JSON.stringify(newUser));

  let selectedMerchantAccount = newMerchant.mea_id;
  let selectedMerchantAccountRole = newMerchant.role;
  let selectedMerchantAccountManagerVtSetting = newMerchant.manager_online_payments_access_enabled ? newMerchant.manager_online_payments_access_enabled : false;
  let selectedMerchantAccountManagerReputationSetting = newMerchant.manager_can_access_reputation_mgmt ? newMerchant.manager_can_access_reputation_mgmt : false;
  let selectedMerchantAccountManagerActivitySetting = newMerchant.manager_activity_access_enabled ? newMerchant.manager_activity_access_enabled : false;
  let selectedMerchantAcceptedTermsAndConditions = newMerchant.accepted_terms_conditions ? newMerchant.accepted_terms_conditions : false;
  let selectedMerchantTCID = newMerchant.tc_id;
  let selectedMerchantTCEnabled = newMerchant.tc_enabled;

  localStorage.setItem('pa-ma', selectedMerchantAccount);

  return {
    type: actionTypes.accountSuccess,
    isFetching: false,
    isAuthenticated: true,
    user: newUser,
    selectedMerchantAccount: selectedMerchantAccount,
    role: selectedMerchantAccountRole,
    managerVt: selectedMerchantAccountManagerVtSetting,
    managerReputation: selectedMerchantAccountManagerReputationSetting,
    managerActivity: selectedMerchantAccountManagerActivitySetting,
    acceptedTermsAndConditions: selectedMerchantAcceptedTermsAndConditions,
    termsAndConditionsID: selectedMerchantTCID,
    termsAndConditionsEnabled: selectedMerchantTCEnabled
  };
}

export function receiveAccount(account) {

  const merchant = account.user.merchantAccounts[0];

  const selectedMerchantAccount = merchant.mea_id;
  const selectedMerchantAccountRole = merchant.role;
  const selectedMerchantAccountManagerReputationSetting = merchant.manager_can_access_reputation_mgmt ? merchant.manager_can_access_reputation_mgmt : false;
  const selectedMerchantAccountManagerVtSetting = merchant.manager_online_payments_access_enabled ? merchant.manager_online_payments_access_enabled : false;
  const selectedMerchantAccountManagerActivitySetting = merchant.manager_activity_access_enabled ? merchant.manager_activity_access_enabled : false;
  const selectedMerchantAccountManagerInvoiceSetting = merchant.manager_can_access_invoices ? merchant.manager_can_access_invoices : false;
  const selectedMerchantAccountManagerPaymentLinkSetting = merchant.manager_can_access_payment_links ? merchant.manager_can_access_payment_links : false;
  const selectedMerchantAcceptedTermsAndConditions = merchant.accepted_terms_conditions ? merchant.accepted_terms_conditions : false;
  const selectedMerchantTCID = merchant.tc_id;
  const selectedMerchantTCEnabled = merchant.tc_enabled;

  if (window._elev && account.user.elevioUserObject && account.user.elevioUserObject.email) {
    window._elev.on('load', function (_elev) {
      _elev.setUser(account.user.elevioUserObject);
      _elev.setSettings({disablePushState: true});
    });
  }

  localStorage.setItem('pa-ma', selectedMerchantAccount);

  return {
    type: actionTypes.accountSuccess,
    isFetching: false,
    isAuthenticated: true,
    user: account.user,
    selectedMerchantAccount: selectedMerchantAccount,
    role: selectedMerchantAccountRole,
    managerVt: selectedMerchantAccountManagerVtSetting,
    managerReputation: selectedMerchantAccountManagerReputationSetting,
    managerActivity: selectedMerchantAccountManagerActivitySetting,
    managerInvoice: selectedMerchantAccountManagerInvoiceSetting,
    managerPaymentLinks: selectedMerchantAccountManagerPaymentLinkSetting,
    acceptedTermsAndConditions: selectedMerchantAcceptedTermsAndConditions,
    termsAndConditionsID: selectedMerchantTCID,
    termsAndConditionsEnabled: selectedMerchantTCEnabled,
    needsInfo: (merchant.needs_info && merchant.type[0] === 'mbp') ? true : false,
    debtRepayment: merchant.dp_allowed,
    processingLevel: merchant.processing_level
  };
}

export function accountError(message) {
  return {
    type: actionTypes.accountFailure,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function loginUser(credentials, type, qr) {

  /////////////////////////////////////////////////////////////////////
  // Calls the API to get a token and dispatches actions along the way
  /////////////////////////////////////////////////////////////////////
  const labelName = LabelUtil.getLabel().text;

  let browserSignature = localStorage.getItem('pa-signature');

  if (!browserSignature) {
    browserSignature = uuidUtil.uuid();
    localStorage.setItem('pa-signature', browserSignature);
  }

  const body = encryptPayload(JSON.stringify({
    username: credentials.username,
    password: credentials.password,
    entryType: type,
    qr: !!qr
  }));

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-csrf-token': helperGetCSRFCookieToken(),
      'x-request-id': uuidUtil.uuid(),
      'x-browser-signature': browserSignature
    },
    credentials: 'include',
    body
  };

  return (dispatch, getState)  => {

    dispatch(requestLogin());
    dispatch(requestAccount());

    const { fingerprint } = getState();

    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    return fetch(serverBaseUrl + `users/create-session?labelName=${labelName}`, config)
      .then(response =>
        response.json()
          .then(payload => (
            {payload, response}
          ))
      ).then(({payload, response}) => {
        if (!response.ok) {
          dispatch(loginError(payload?.message));
          dispatch(accountError(payload?.message));
          return Promise.reject(payload);
        } else {
          const user = payload?.user;
          const hasUser = !!user;
          const hasToken = !!payload?.token;

          const need2FaLogin = user?.is_ecp && !user?.is_device_remembered;
          const need2FaSetup = !user?.is_ecp && !user?.is_password_expired && user?.force_two_factor && hasToken;
          const needForcePasswordReset = user?.is_password_expired && hasToken;
          const regularLogin = hasToken && hasUser;

          if (need2FaLogin) {
            dispatch(twoFactorRequired(payload.user.is_ecp, false));
            dispatch(receiveAccount(payload));
          } else if (need2FaSetup) {
            dispatch(force2FASetupRequired(payload));
            dispatch(setActionRequiredSession(payload.token));
          } else if (needForcePasswordReset) {
            dispatch(resetPasswordRequired(payload));
            dispatch(setActionRequiredSession(payload.token));
          } else if (regularLogin) {
            localStorage.setItem('pa-token', payload.token);
            localStorage.setItem('pa-u', JSON.stringify(payload.user));
            dispatch(receiveLogin(payload));
            dispatch(receiveAccount(payload));
            dispatch(maintenanceMessage(type));
            dispatch(showExpirePasswordMessage());
            dispatch(toggleAchMarketingDialog(true));
          } else {
            return Promise.reject(payload);
          }

        }
      }).catch(error => {
       return error;
      });
  };
}

export function loginCsUser(credentials) {

  const body = encryptPayload(JSON.stringify({
    username: credentials.username,
    password: credentials.password,
    mid: credentials.mid,
    recaptchaToken: credentials.recaptchaToken,
    bypassToken: true
  }));

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-csrf-token': helperGetCSRFCookieToken(),
      'x-request-id': uuidUtil.uuid()
    },
    credentials: 'include',
    body
  };

  return (dispatch, getState) => {

    dispatch(requestLogin());
    dispatch(requestAccount());
    dispatch(csLoginRequest());

    const { fingerprint } = getState();

    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    return fetch(serverBaseUrl + 'users/create-cs-session', config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {
        if (!response.ok) {
          dispatch(loginError(payload.message));
          dispatch(accountError(payload.message));
          dispatch(csLoginError());
          return Promise.reject(payload)
        } else {
          localStorage.setItem('pa-token', payload.token);
          localStorage.setItem('pa-u', JSON.stringify(payload.user));
          dispatch(receiveLogin(payload));
          dispatch(receiveAccount(payload));
          dispatch(csLoginSuccess(payload));
          dispatch(showExpirePasswordMessage());
          const { user } = getState();
          dispatch(getCsSettings(user));
        }
      }).catch(error => console.log('Error: ', error));
  };
}


export function loginUserWithToken(credentials, type, qr) {

  /////////////////////////////////////////////////////////////////////
  // Calls the API to get a token and dispatches actions along the way
  /////////////////////////////////////////////////////////////////////

  let browserSignature = localStorage.getItem('pa-signature');

  if (!browserSignature) {
    browserSignature = uuidUtil.uuid();
    localStorage.setItem('pa-signature', browserSignature);
  }

  const body = encryptPayload(JSON.stringify({
    username: credentials.username,
    userId: credentials.userId,
    merchantId: credentials.merchantId,
    password: credentials.password,
    entryType: type,
    qr: !!qr,
    token: credentials.token,
    rememberThisDevice: !!credentials.rememberThisDevice
  }));

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-csrf-token': helperGetCSRFCookieToken(),
      'x-request-id': uuidUtil.uuid(),
      'x-browser-signature': browserSignature
    },
    credentials: 'include',
    body
  };

  return (dispatch, getState) => {

    dispatch(twoFactorValidating());

    const { fingerprint } = getState();

    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    return fetch(serverBaseUrl + 'users/create-session/twofactor', config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {
        if (!response.ok) {
          dispatch(loginError(payload.message));
          dispatch(accountError(payload.message));
          dispatch(loginFormError(i18n.t(messages.twoFactorAuth.verification.errorRetry)));
          return Promise.reject(payload);
        } else if (payload?.user?.is_password_expired && !!payload?.token) {
          dispatch(resetPasswordRequired(payload));
          dispatch(setActionRequiredSession(payload.token));
        } else {
          localStorage.setItem('pa-token', payload.token);
          localStorage.setItem('pa-u', JSON.stringify(payload.user));
          dispatch(receiveAccount(payload));
          dispatch(receiveLogin(payload));
          dispatch(maintenanceMessage(type));
          dispatch(showExpirePasswordMessage());
          dispatch(toggleAchMarketingDialog(true));
          dispatch(twoFactorValid());
        }
      }).catch(error => console.log('Error: ', error));
  };
}

export function loginUserWithExpiredPassword(credentials, type, qr) {

  /////////////////////////////////////////////////////////////////////
  // Calls the API to reset user's password and dispatches actions along the way
  /////////////////////////////////////////////////////////////////////

  let browserSignature = localStorage.getItem('pa-signature');

  if (!browserSignature) {
    browserSignature = uuidUtil.uuid();
    localStorage.setItem('pa-signature', browserSignature);
  }

  const body = encryptPayload(JSON.stringify({
    password: credentials.password,
    newPassword: credentials.newPassword,
    entryType: type,
    qr: !!qr,
    token: credentials.token
  }));

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-request-id': uuidUtil.uuid(),
      'x-csrf-token': helperGetCSRFCookieToken(),
      'x-browser-signature': browserSignature
    },
    credentials: 'include',
    body
  };

  return (dispatch, getState) => {

    dispatch(requestForceResetPasswordLoginAccount());

    const { auth, fingerprint } = getState();
    config.headers['Authorization'] = `Bearer ${auth.actionRequiredToken}`;

    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    return fetch(serverBaseUrl + 'users/create-session/expired-password', config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {
        let actionType = actionTypes.forceResetPasswordLoginSuccess;

        if (!response.ok) {
          dispatch(loginError(payload.message));
          dispatch(accountError(payload.message));
          dispatch(loginFormError('There was an issue changing your password, please try again.'));
          actionType = actionTypes.forceResetPasswordLoginError
        } else if (!!payload?.token && !!payload?.user) {
          localStorage.setItem('pa-token', payload.token);
          localStorage.setItem('pa-u', JSON.stringify(payload.user));
          dispatch(receiveLogin(payload));
          dispatch(receiveAccount(payload));
          dispatch(maintenanceMessage(actionType));
          dispatch(showExpirePasswordMessage());
          dispatch(toggleAchMarketingDialog(true));
        }

        return Promise.resolve({ type: actionType, payload, entryType: type });

      }).catch(error => console.log('Error: ', error));
  };
}

export function logoutUser() {

  return dispatch => {
    dispatch(requestLogout());
    dispatch(expireToken());
    dispatch(receiveLogout());
  };

}

export function show2FAError() {
  return {
    type: actionTypes.twoFactorVerifyTokenFailure,
    isFetching: false,
    isAuthenticated: false
  };
}


export function loginSetup2FA(values) {

  /////////////////////////////////////////////////////////////////////
  // Calls the API to set up 2fa and dispatches actions along the way
  /////////////////////////////////////////////////////////////////////

  let browserSignature = localStorage.getItem('pa-signature');

  if (!browserSignature) {
    browserSignature = uuidUtil.uuid();
    localStorage.setItem('pa-signature', browserSignature);
  }

  const willRequestCode = Boolean(values.willRequestCode);

  const bodyParamsArray = [
    ['phone', values.cellPhone.replace(/\D/g, '')],
    ['token', values.token],
  ];

  const body = bodyParamBuilder(bodyParamsArray);

  const config = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-request-id': uuidUtil.uuid(),
      'x-browser-signature': browserSignature
    },
    credentials: 'include',
    body
  };

  return (dispatch, getState) => {
    let errorMessage = undefined;

    dispatch(requestSetup2faLogin());

    const { auth, fingerprint } = getState();
    config.headers['Authorization'] = `Bearer ${auth.actionRequiredToken}`;

    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    if (willRequestCode) {
      dispatch(twoFactorSendTokenRequest());
      errorMessage = i18n.t(messages.twoFactorAuth.setup.request);

    } else {
      dispatch(twoFactorVerifyTokenRequest());
      errorMessage = i18n.t(messages.twoFactorAuth.setup.verify);
    }

    return fetch(serverBaseUrl + 'users/create-session/setup-two-factor', config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({payload, response}) => {

        if (!response.ok) {
          if (willRequestCode) {
            dispatch(resetTwoFactorAuthCodeStatus());
          } else {
            dispatch(twoFactorRequired(false, true));
          }

          return Promise.resolve(setup2faLoginError(
            (payload?.message && isJSON(payload.message) && JSON.parse(payload.message)?.reason) || errorMessage)
          );
        }

        if (willRequestCode) {
          dispatch(twoFactorRequired(false, true));
        } else {
          dispatch(setup2faVerifyTokenSuccess());
        }

        return Promise.resolve({
          type: actionTypes.setup2faLoginSuccess,
          payload
        });

      }).catch(() => {
        return Promise.resolve(setup2faLoginError(errorMessage));
      });
  };
}

export function changeSelectedMerchantAccount(selectedMerchantAccount) {

  localStorage.setItem('pa-ma', selectedMerchantAccount);

  return {
    type: actionTypes.changeSelectedMerchantAccount,
    selectedMerchantAccount: selectedMerchantAccount
  };
}

export function refreshToken() {

  const endpoint = 'users/refresh';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.refreshTokenRequest, actionTypes.refreshTokenSuccess, actionTypes.refreshTokenFailure]
    }
  };

}

export function requestDesktopToken() {

  const endpoint = 'users/desktop/token';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.desktopTokenRequest, actionTypes.desktopTokenSuccess, actionTypes.desktopTokenFailure]
    }
  };

}

export function requestPayNowCPToken() {

  const cpToken = localStorage.getItem('pa-cp-t')

  const endpoint = `users/desktop/token?token=${cpToken}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.desktopTokenRequest, actionTypes.desktopTokenSuccess, actionTypes.desktopTokenFailure]
    }
  };

}

export function expireToken() {

  const endpoint = 'users/expireToken';

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [actionTypes.expireTokenRequest, actionTypes.expireTokenSuccess, actionTypes.expireTokenFailure],
    },
    type: actionTypes.expireTokenRequest
  };

}

export function expireActionRequiredToken() {

  /////////////////////////////////////////////////////////////////////
  // Calls the API to expire Expired Password JWT and dispatches actions along the way
  /////////////////////////////////////////////////////////////////////

  let browserSignature = localStorage.getItem('pa-signature');

  if (!browserSignature) {
    browserSignature = uuidUtil.uuid();
    localStorage.setItem('pa-signature', browserSignature);
  }

  const config = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'requestDate': moment().format('ddd, DD MMM YYYY HH:mm:ss Z'),
      'x-csrf-token': helperGetCSRFCookieToken(),
      'x-request-id': uuidUtil.uuid(),
      'x-browser-signature': browserSignature
    },
    credentials: 'include',
  };

  return (dispatch, getState) => {

    dispatch(expireActionRequiredRequest());

    const { auth, fingerprint } = getState();
    config.headers['Authorization'] = `Bearer ${auth.actionRequiredToken}`;


    if (fingerprint?.data?.visitorId) {
      config.headers['x-visitor-id'] = fingerprint.data.visitorId;
    }

    return fetch(serverBaseUrl + 'users/expireActionRequiredToken', config)
      .then(response =>
        response.json()
          .then(payload => ({payload, response}))
      ).then(({response}) => {
        let actionType = actionTypes.expireActionRequiredTokenSuccess;
        if (!response.ok) {
          actionType = actionTypes.expireActionRequiredTokenFailure
        }

        dispatch(cleanExpiredPasswordSession());

        return Promise.resolve({ type: actionType,});

      }).catch(error => console.log('Error: ', error));
  };

}

export function validateToken(token, type) {

  const endpoint = `users/validate-token?token=${encodeURIComponent(token)}&type=${type}`;

  const actionTypesForRequest = type === 'setPassword' ?
    [actionTypes.validateSetPasswordTokenRequest, actionTypes.validateSetPasswordTokenSuccess, actionTypes.validateSetPasswordTokenFailure] :
    [actionTypes.validateResetPasswordTokenRequest, actionTypes.validateResetPasswordTokenSuccess, actionTypes.validateResetPasswordTokenFailure];

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: actionTypesForRequest
    }
  };

}

export function checkTokenExpiration() {

  return (dispatch, getState) => {

    const token = localStorage.getItem('pa-token');

    if (!token) { return; }

    const tokenExpiration = jwtDecode(token).exp;
    const timeUntilNeedNewToken = generalOptions.timeForPromptTokenExpiration; // 5 minutes before token expires
    const dateNow = Date.now().valueOf() / 1000;
    const expiresMinusRefreshTime = tokenExpiration - timeUntilNeedNewToken;
    const tokenExpired = tokenExpiration && (expiresMinusRefreshTime < dateNow);

    const { auth, userExperience } = getState();
    const { tokenAutoRenew } = auth;
    const { openRefreshTokenDialog } = userExperience;


    if (tokenExpired && tokenAutoRenew && !openRefreshTokenDialog) {
      dispatch(refreshToken()).then(() => {
        const {auth} = getState();
        if (auth.newToken) {
          localStorage.setItem('pa-token', auth.newToken);
        }
      })
    } else {
      const tokenActionType = tokenExpired ? actionTypes.tokenExpiring : actionTypes.tokenValid;
      dispatch(handleTokenAction(tokenActionType));
    }
  };
}

export function handleTokenAction(tokenActionType) {
  return {
    type: tokenActionType
  };
}

export function forgotPassword(forgetPasswordInfo, notRegistered) {

  const endpoint = 'users/forgot-password';

  const body = {};
  body.email = forgetPasswordInfo.email;
  body.brand = forgetPasswordInfo.brand;
  body.mid = forgetPasswordInfo.mid;
  body.captchaResponse = forgetPasswordInfo.captcharesponse;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      types: [actionTypes.userForgotPasswordRequest, actionTypes.userForgotPasswordSuccess, actionTypes.userForgotPasswordFailure],
      body
    }
  };

}

export function forgotCSPassword(forgetPasswordInfo, notRegistered) {

  const endpoint = 'users/client-service/forgot-password';

  let body = {};
  body.email = forgetPasswordInfo.email;
  body.brand = forgetPasswordInfo.brand;
  body.mid = forgetPasswordInfo.mid;
  body.captchaResponse = forgetPasswordInfo.captcharesponse;
  body.notRegistered = notRegistered;
  body.firstName = forgetPasswordInfo.firstName ? forgetPasswordInfo.firstName : null;
  body.lastName = forgetPasswordInfo.lastName ? forgetPasswordInfo.lastName : null;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      types: [actionTypes.userForgotPasswordRequest, actionTypes.userForgotPasswordSuccess, actionTypes.userForgotPasswordFailure],
      body
    }
  };

}

export function clearForgotPasswordData() {
  return {
    type: actionTypes.clearForgotPasswordData
  };
}

export function resetPassword(resetPasswordInfo) {
  const {token, ...body} = resetPasswordInfo;
  const query = `?token=${encodeURIComponent(token)}`;
  const endpoint = `users/update-password${query}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.userResetPasswordRequest, actionTypes.userResetPasswordSuccess, actionTypes.userResetPasswordFailure],
      body
    }
  };
}

export function resetPasswordWithToken(resetPasswordInfo, twoFactorToken) {
  const {token, ...body} = resetPasswordInfo;
  const query = `?token=${encodeURIComponent(token)}`;
  const endpoint = `users/update-password/twofactor${query}`;

  body.token = twoFactorToken;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.userResetPasswordRequest, actionTypes.userResetPasswordSuccess, actionTypes.userResetPasswordFailure],
      body
    }
  };

}

export function setPassword(setPasswordInfo) {

  /////////////////////////////////////////////////////////////////////
  // POST Request password reset
  /////////////////////////////////////////////////////////////////////

  const query = `?token=${encodeURIComponent(setPasswordInfo.token)}`;

  const endpoint = `users/set-password${query}`;

  let body = {};
  body.password = setPasswordInfo.newPassword;
  body.username = setPasswordInfo.username;
  body.granterId = setPasswordInfo.granterId;
  body.merchantId = setPasswordInfo.merchantId;
  body.granteeId = setPasswordInfo.granteeId;
  body.brand = setPasswordInfo.brand;
  body.captchaResponse = setPasswordInfo.captcharesponse;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: true,
      types: [actionTypes.userSetPasswordRequest, actionTypes.userSetPasswordSuccess, actionTypes.userSetPasswordFailure],
      body
    }
  };

}

export function updateUserPassword(user, values) {

  const endpoint = 'users/accounts/' + user.selectedMerchantAccount + '/password';

  let body = {};
  body.username = user.data.username;
  body.newPassword = values.newPassword;
  body.currentPassword = values.currentPassword;
  body.captchaResponse = values.captcharesponse;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: true,
      encrypted: true,
      types: [actionTypes.userUpdateLoginInfoRequest, actionTypes.userUpdateLoginInfoSuccess, actionTypes.userUpdateLoginInfoFailure],
      body
    }
  };
}

export function csLoginRequest() {
  return {
    type: actionTypes.validateCSRequest
  };
}

export function csLoginError() {
  return {
    type: actionTypes.validateCSFailure
  };
}

export function csLoginSuccess() {
  return {
    type: actionTypes.validateCSSuccess
  };
}

export function sendNotificationEmail(user, status, brand) {

  const endpoint = '/notify-registration';

  const body = {
    notify: [{
      first_name: user.firstName,
      user_name: user.emailAddress,
      mid: user.accountNumber,
      status: status
    }],
    brand: brand
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'POST',
      authenticated: false,
      encrypted: false,
      types: [actionTypes.sendNotificationEmailRequest, actionTypes.sendNotificationEmailSuccess, actionTypes.sendNotificationEmailFailure],
      body
    }
  };
}

export function sendNotification(title, type, message) {
  return {
    type: actionTypes.sendNotification,
    notificationTitle: title,
    notificationType: type,
    notificationMessage: message
  };
}

export function sendMaintenanceMessage() {
  return {
    type: actionTypes.sendMaintenanceMessage,
  };
}

export function setTokenAutoRenew(tokenAutoRenew) {
  return {
    type: actionTypes.setTokenAutoRenew,
    tokenAutoRenew
  };
}

export function setIsUserIdle(isUserIdle) {
  return {
    type: actionTypes.setIsUserIdle,
    isUserIdle: Boolean(isUserIdle)
  };
}

export function resetPasswordRequired(values) {
  const { user } = values;

  const merchant = user.merchantAccounts[0];

  const selectedMerchantAccount = merchant.mea_id;

  return {
    type: actionTypes.forceResetPasswordRequired,
    user: user,
    selectedMerchantAccount,
    forceResetPassword: true
  };
}

export function force2FASetupRequired(values) {
  const { user } = values;

  const merchant = user.merchantAccounts[0];

  const selectedMerchantAccount = merchant.mea_id;

  return {
    type: actionTypes.force2FASetupRequired,
    user: user,
    selectedMerchantAccount,
  };
}

export function setActionRequiredSession(token) {

  return {
    type: actionTypes.setActionRequiredSession,
    token
  };
}

export function expireActionRequiredRequest() {

  return {
    type: actionTypes.expireActionRequiredTokenRequest
  };
}

export function cleanExpiredPasswordSession() {

  return {
    type: actionTypes.cleanExpiredPasswordSession
  };
}

export function twoFactorRequired(authyEnabled, setup2FA) {
  return {
    type: actionTypes.twoFactorTokenRequired,
    authyEnabled,
    setup2FA
  };
}
export function twoFactorSendTokenRequest() {
  return {
    type: actionTypes.twoFactorSendTokenRequest,
  };
}

export function twoFactorVerifyTokenRequest() {
  return {
    type: actionTypes.twoFactorVerifyTokenRequest,
  };
}

export function setup2faLoginError(errorMessage) {
  return {
    type: actionTypes.setup2faLoginError,
    payload: {
      message: errorMessage
    }
  };
}

export function setup2faVerifyTokenSuccess() {
  return {
    type: actionTypes.setup2faVerifyTokenSuccess,
  };
}

export function twoFactorValidating() {
  return {
    type: actionTypes.twoFactorTokenValidating,
    twoFactor: {isValidating: true}
  };
}

export function twoFactorValid() {
  return {
    type: actionTypes.twoFactorTokenValid
  };
}

export function twoFactorUpdating() {
  return {
    type: actionTypes.twoFactorModifySettingsRequest,
    isUpdating: true,
    twoFactorSaved: false
  };
}

export function twoFactorSaved(enabled, cellPhone) {

  let user = JSON.parse(localStorage.getItem('pa-u'));
  user.is_ecp = enabled;
  localStorage.setItem('pa-u', JSON.stringify(user));

  return {
    type: actionTypes.twoFactorModifySettingsSuccess,
    twoFactor: {enabled: enabled, cellPhone: cellPhone}
  };
}

export function twoFactorError(message) {
  return {
    type: actionTypes.twoFactorModifySettingsFailure,
    isUpdating: false,
    message
  };
}

export function twoFactorVerificationFailed(authyEnabled) {
  return {
    type: actionTypes.twoFactorVerifyTokenFailure,
    twoFactor: {enabled: authyEnabled, isValidating: false},
    isFetching: false,
    isAuthenticated: false,
    twoFactorVerificationFailed: true,
    message: 'We were unable to verify your user token. Please try again.'
  };
}

export function acceptTerms(user, termsID) {

  const method = 'POST';

  const activeAccount = UserUtil.getActiveAccount(user);

  const merchantId = user.selectedMerchantAccount;
  const brandAbbreviation = (UserUtil.userType(user) === 'MBP') ? 'mbp' : LabelUtil.getLabel().abbreviation;

  const processor = activeAccount.processor ? activeAccount.processor.toLowerCase() : null;
  const sponsor = activeAccount.sponsor ? activeAccount.sponsor.toLowerCase() : null;

  const endpoint = `users/accounts/${merchantId}/terms/${termsID}/${brandAbbreviation}`;

  let body = {};

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      encrypted: false,
      types: [actionTypes.agreementSaveRequest, actionTypes.agreementSaveSuccess, actionTypes.agreementSaveFailure],
      body
    }
  };
}

export function setResponsiveMenu(flag) {

  return {
    type: actionTypes.setResponsiveMenu,
    isFetching: false,
    isActive: flag
  };
}

export function getPhoneVerificationCode(user, phoneNumber, type = '') {

  /////////////////////////////////////////////////////////////////////
  // GET send user their authy phone token
  /////////////////////////////////////////////////////////////////////

  const phone = phoneNumber.replace(/\D/g, '');
  const queryString = `?action=requestphone${type}&phone=${phone}`;
  const endpoint = 'users/' + user.data.user_id + '/accounts/' + user.selectedMerchantAccount + '/twofactor' + queryString;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      types: [
        actionTypes.twoFactorPhoneCodeRequest,
        actionTypes.twoFactorPhoneCodeSuccess,
        actionTypes.twoFactorPhoneCodeFailure
      ]
    }
  };
}

export function validatePhoneVerificationCode(user, phoneNumber, code, type = '', twoFactorType) {

  /////////////////////////////////////////////////////////////////////
  // GET validates authy phone token
  /////////////////////////////////////////////////////////////////////

  const phone = phoneNumber.replace(/\D/g, '');
  const last4 = phone.slice(-4);
  code = code && code.toString().trim();
  const twoFactorQuery = twoFactorType ? `&type=${twoFactorType}` : '';
  const queryString = `?action=validatephone${type}&phone=${phone}&token=${code}${twoFactorQuery}`;
  const endpoint = 'users/' + user.data.user_id + '/accounts/' + user.selectedMerchantAccount + '/twofactor' + queryString;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: true,
      ...(twoFactorType ? { metadata: { twoFactorType, phoneNumber: last4 } } : {}),
      types: [actionTypes.twoFactorPhoneVerifyRequest, actionTypes.twoFactorPhoneVerifySuccess, actionTypes.twoFactorPhoneVerifyFailure]
    }
  };
}

export function getTwoFactorToken(user, force, type) {

  /////////////////////////////////////////////////////////////////////
  // GET send user their authy token
  /////////////////////////////////////////////////////////////////////

  let action;
  switch (type) {
    case twoFactorRequestTypes.EMAIL:
      action = 'requestemail';
      break;
    case twoFactorRequestTypes.VOICE:
      action = 'requestvoice';
      break;
    default:
      action = 'requesttoken';
      break;
  }

  const queryString = `?action=${action}&force=${force}`;
  const endpoint = 'users/' + user.data.user_id + '/accounts/' + user.selectedMerchantAccount + '/twofactor' + queryString;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.twoFactorSendTokenRequest, actionTypes.twoFactorSendTokenSuccess, actionTypes.twoFactorSendTokenFailure]
    }
  };
}

export function verifyTwoFactorToken(user, token, twoFactorType) {

  /////////////////////////////////////////////////////////////////////
  // GET verify an authy users two factor token
  /////////////////////////////////////////////////////////////////////

  const twoFactorTypeQuery = twoFactorType ? `&type=${twoFactorType}`:'';
  token = token && token.toString().trim();
  const queryString = `?action=validatetoken&token=${token}${twoFactorTypeQuery}`;
  const endpoint = `users/${user.data.user_id}/accounts/${user.selectedMerchantAccount}/twofactor${queryString}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      ...(twoFactorType ? { metadata: { twoFactorType } } : {}),
      types: [actionTypes.twoFactorVerifyTokenRequest, actionTypes.twoFactorVerifyTokenSuccess, actionTypes.twoFactorVerifyTokenFailure]
    }
  };
}

export function verifyTwoFactorRegistrationToken(userId, merchantId, token, type) {

  /////////////////////////////////////////////////////////////////////
  // GET verify an user that just registered with two factor token
  /////////////////////////////////////////////////////////////////////

  token = token && token.toString().trim();
  let action = 'validatetokenregistration';
  if (type === twoFactorRequestTypes.EMAIL) {
    action = 'validateemailtokenregistration';
  }

  const queryString = `?action=${action}&token=${token}`;
  const endpoint = `users/${userId}/accounts/${merchantId}/twofactor${queryString}`;

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: 'GET',
      authenticated: false,
      types: [actionTypes.twoFactorVerifyRegistrationTokenRequest, actionTypes.twoFactorVerifyRegistrationTokenSuccess, actionTypes.twoFactorVerifyRegistrationTokenFailure]
    }
  };
}

export function twoFactorUpdateSettings(user, phone, hasTwoFactorEnabled, brand) {

  const method = 'PUT';
  const endpoint = `users/${user.data.user_id}/accounts/${user.selectedMerchantAccount}/twofactor/enabled`;
  const body = {
    two_factor_enabled: hasTwoFactorEnabled,
    ...(phone && { two_factor_cell_phone: phone }),
    username: user.data.username,
    brand
  };

  return {
    [CALL_API]: {
      endpoint: endpoint,
      method: method,
      authenticated: true,
      types: [actionTypes.twoFactorModifySettingsRequest, actionTypes.twoFactorModifySettingsSuccess, actionTypes.twoFactorModifySettingsFailure],
      body
    }
  };

}

export function versionMessage(type) {

  ///////////////////////////////////////////////////////////////
  // Set last login and version in local storage
  // And show message unless mobile
  //////////////////////////////////////////////////////////////

  return dispatch => {

    if (type !== 'M') {
      let storedVersion = localStorage.getItem('pa-v') ? JSON.parse(localStorage.getItem('pa-v')) : null;

      // for now turn the message off all together till marketing wants it back on.
      if (messages.whatsNew && messages.whatsNew.message && messages.whatsNew.message.length > 0
        && (!storedVersion || storedVersion.version < applicationVersion)) {
        dispatch(sendNotification(i18n.t(messages.whatsNew.title), 'LightComponent', i18n.t(messages.whatsNew.message)));
      }

      localStorage.setItem('pa-v', JSON.stringify({
        version: applicationVersion,
        when: moment().format()
      }));
    }
  };

}

export function maintenanceMessage(type) {

  return dispatch => {

    if (type !== 'M') {

      dispatch(sendMaintenanceMessage());

    }
  };

}

export function showExpirePasswordMessage() {
  return {
    type: actionTypes.setOpenExpirePasswordMessage
  };
}

export function hideExpirePasswordMessage() {
  return {
    type: actionTypes.setCloseExpirePasswordMessage
  };
}

export function clearForgotPasswordSent() {
  return {
    type: actionTypes.clearForgotPasswordSent
  };
}

export function updatedTwoFactorForm(phoneNumber, twoFactorEnabled) {
  return {
    type: actionTypes.update2FAForm,
    phoneNumber,
    twoFactorEnabled
  }
}

export function resetTwoFactorAuthCodeStatus() {
  return {
    type: actionTypes.reset2FACodeStatus
  };
}

export function resetTwoFactorAuthPhoneStatus() {
  return {
    type: actionTypes.reset2FAPhoneStatus
  };
}

export function toggleSavingAgreement() {
  return {
    type: actionTypes.toggleSavingAgreement
  };
}

/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */
