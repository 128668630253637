/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';
import IconButton from './IconButton';
import SearchBarField from '../SearchBarField';
import IconUtil from '../util/IconUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import routes from '../../constants/routes';
import {
  addExpressItemButtonStyles,
  addExpressItemsButtonStyles,
  addExpressItemsButtonTextStyles,
  addExpressItemsListStyles,
  addExpressItemsSearchButtonStyles,
  addExpressItemsSearchDropdown,
  addExpressItemsSearchRowStyles,
  addExpressItemsTextStyles
} from '../../jss/addExpressItemStyles';

class ItemSearchDropdown extends Component {

  state = {
    isItemSearchDropdownOpen: false
  };

  itemSearchRef = React.createRef();
  itemListRef = React.createRef();

  componentDidMount() {
    window.addEventListener('mousedown', this.handleCloseItemSearchDropdown)
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleCloseItemSearchDropdown)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { items, visibilityFilter, itemizedCart } = this.props;
    const { isItemSearchDropdownOpen } = this.state;

    return !_.isEqual(items.filteredItems, nextProps.items.filteredItems)
      || !_.isEqual(items.filteredDiscounts, nextProps.items.filteredDiscounts)
      || !_.isEqual(visibilityFilter, nextProps.visibilityFilter)
      || !_.isEqual(itemizedCart, nextProps.itemizedCart)
      || !_.isEqual(isItemSearchDropdownOpen, nextState.isItemSearchDropdownOpen);
  }

  handleItemSearchFocus = () => {
    this.setState({ isItemSearchDropdownOpen: true });
  }

  handleCloseItemSearchDropdown = (event) => {
    if (!this.itemListRef?.current?.contains(event.target)
      && !this.itemSearchRef?.current?.contains(event.target)
    ) {
      this.setState({ isItemSearchDropdownOpen: false });
    }
  }

  calculateItemDropdownHeight = (dropdownRows) => {
    return dropdownRows?.length > 7 ? 540 : (dropdownRows?.length || 0) * 75;
  };

  onDiscountClick = (index) => {
    const { addDiscountToCart } = this.props;

    addDiscountToCart(index);
    this.setState({ isItemSearchDropdownOpen: false });
  };

  onItemClick = (index) => {
    const { openItemsDialog } = this.props;

    openItemsDialog(index);
    this.setState({ isItemSearchDropdownOpen: false });
  };

  renderRow = (row, index) => {
    const { items } = this.props;
    const itemsLength = items.filteredItems?.length || 0;

    if (index < itemsLength) {
      return this.renderItem(row, index);
    }

    const discountIndex = index - itemsLength;
    return this.renderDiscount(row, discountIndex);
  }

  renderDiscount = (discount, index) => {
    const { t, itemizedCart } = this.props;

    const discountValue = discount.type === 'percent'
      ? `${discount.percentage}% ${t('Off')}`
      : `${numeral(discount.amount).format('$0,0.00')} ${t('Off')}`

    const formattedColor = discount.color ? '#' + discount.color : '#888C8D';
    const avatarStyles = { width: 30, height: 30};
    const disableDiscount = itemizedCart.sub_total_amt <= 0;
    const onClick = !disableDiscount ? () => this.onDiscountClick(index) : undefined;

    return (
      <ListItem
        key={index}
        className={classnames('discountSearchRow', { disabled: disableDiscount })}
        onClick={onClick}
        sx={addExpressItemsSearchRowStyles}
      >
        <Stack sx={addExpressItemsTextStyles}>
          <Stack direction='row' alignItems='center' className='leftSide'>
            <IconButton disableTouchRipple className='discountIcon' size='large'>
              <Avatar style={{ ...avatarStyles, backgroundColor: formattedColor }}>
                {IconUtil.getIcon('DiscountIcon', '#FFFFFF')}
              </Avatar>
            </IconButton>
            <div className='itemName'>{discount.name}</div>
          </Stack>
          <Stack direction='row' alignItems='center' justifyContent='flex-end'>
            <div className='itemPrice'>{discountValue}</div>
          </Stack>
        </Stack>
      </ListItem>
    );
  }

  renderItem = (item, index) => {
    const { t } = this.props;

    //For any particular item, the info displayed in the dropdown list should be that of the first price that isnt out of stock, if any, otherwise just show info of the first price
    let firstPriceInStockIndex = !item.is_trackable
      ? 0
      : item.details.prices.reduce((result, currentPrice, currentIndex) => {
        return (currentPrice.quantity > 0 && (result === null)) ? currentIndex : result;
      }, null);
    firstPriceInStockIndex = firstPriceInStockIndex || 0; //In case all prices are out of stock for an item

    const itemBarcode = (item.details.prices.length > 0)
      ? item.details.prices[firstPriceInStockIndex].barcode || t('NoBarCode')
      : t('NoBarCode');

    const itemPrice = (item.details.prices.length > 0)
      ? numeral(item.details.prices[firstPriceInStockIndex].price).format('$0,0.00')
      : '$0.00';

    const itemInStockQuantity = (item.is_trackable && item.details.prices.length > 0)
      ? item.details.prices[firstPriceInStockIndex].quantity > 0
        ? `${item.details.prices[firstPriceInStockIndex].quantity} ${t('InStock').toLowerCase()}`
        : t('OutStock')
      : t('NotTracked');

    return (
      <ListItem key={index} sx={addExpressItemsSearchRowStyles} data-test-id='itemSearchRow'>
        <ListItemButton onClick={() => this.onItemClick(index)} sx={addExpressItemsSearchButtonStyles}>
          <Stack sx={addExpressItemsTextStyles}>
            <Stack direction='column' justifyContent='center' alignItems='flex-start'>
              <div className='itemName'>{item.name}</div>
              <div className='itemBarcode'>{itemBarcode}</div>
            </Stack>
            <Stack direction='column' justifyContent='center' alignItems='flex-end'>
              <div className='itemPrice'>{itemPrice}</div>
              <div className='itemInStockQuantity'>{itemInStockQuantity}</div>
            </Stack>
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  }

  render() {
    const { defaultTaxRate, t, className, items } = this.props;
    const appRoutePrefix = globalApplicationLabel.path;

    const dropdownDiscountRows = items.filteredDiscounts?.length > 0 ? items.filteredDiscounts : []
    const dropdownItemRows = items.filteredItems?.length > 0 ? items.filteredItems : []
    const dropdownRows = [...dropdownItemRows, ...dropdownDiscountRows];

    return (
      <Box className={`itemSearchDropdown ${className}`} sx={addExpressItemsSearchDropdown}>
        <div className='textField'>
          <div className='textFieldInfo'>
            <label>
              {t('AddItems')}
            </label>
          </div>
          <div ref={this.itemSearchRef}>
            <SearchBarField
              {...this.props}
              searchPlaceholder={t('EnterItem')}
              id='itemSearchBar'
              optionalHandleFocus={this.handleItemSearchFocus}
              autoCompleteOff={true}
              filterType={'secondaryFilter'}
            />
          </div>
        </div>

        {defaultTaxRate > 0 &&
          <div className='taxNote'>
            {`${t('TaxNoteText')} ${FormatTextUtil.formatTaxRate(defaultTaxRate)}. ${t('ConfigureTax')} `}
            <Link to={appRoutePrefix + routes.account.root + routes.account.settings} className='linkLike'>{t('Business Settings')}</Link>
          </div>
        }

        <List sx={addExpressItemsListStyles} ref={this.itemListRef} className={this.state.isItemSearchDropdownOpen ? '' : 'hideMe'}>
          <ListItem data-test-id='addExpressItemButton' disablePadding sx={addExpressItemButtonStyles} onClick={() => this.onItemClick(-1)}>
            <ListItemButton sx={addExpressItemsButtonStyles}>
              <ListItemText sx={addExpressItemsButtonTextStyles} primary={t('AddExpressItem')} />
            </ListItemButton>
          </ListItem>
          <AutoSizer disableHeight>
            {
              ({ width }) => (
                <VirtualizedList
                  width={width}
                  height={this.calculateItemDropdownHeight(dropdownRows)}
                  rowHeight={75}
                  rowRenderer={({ index, key, style }) => (
                    <div style={style} key={key}>
                      {this.renderRow(dropdownRows?.[index], index)}
                    </div>
                  )}
                  rowCount={dropdownRows?.length || 0}
                />
              )
            }
          </AutoSizer>
        </List>
      </Box>
    );
  }
};

export default ItemSearchDropdown;
