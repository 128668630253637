/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import {
  setSelectedItemTab
} from '../../actions/userExperienceActions';
import {connect} from 'react-redux';
import IconUtils from './../util/IconUtil';
import {Field, reduxForm, FieldArray, formValueSelector} from 'redux-form';
import { MenuItem } from '@mui/material';
import Button from '../shared/Button';
import IconButton from '../shared/IconButton';
import CustomToggle from '../shared/Toggle';
import Checkbox from '../shared/Checkbox';
import Select from '../shared/Select';
import LabelUtil from '../util/LabelUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import Validator from '../util/Validator';
import numeral from 'numeral';
import ItemDetailThumbnail from '../ItemDetailThumbnail';
import { Box, CircularProgress } from '@mui/material';
import TextField from '../shared/TextField'
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import UserUtil from '../util/UserUtil';
import Tooltip from '../shared/Tooltip';
import {inventoryStyles} from '../../jss/inventoryStyles';
import ResizableTextArea from '../shared/ResizableTextArea';

export const renderCheckbox = ({input, label, disabled}) => (
  <Checkbox
    label={label}
    input={input}
    externalClassName={'halfScreen'}
    disabled={disabled}
  />
);

export const renderModifiersField = ({fields, initialModifiers, meta: {touched, error}, t, disabled}) => (
  <div className='modifiersContainer'>
    <div className='detailsRow detailsHeader'>{t('ItemsDetail.Modifiers')}</div>
    {fields.map((modifierSets, index) => {
      return (
        <div key={index} className='detailsRow detailsCheckBox'>
          <Field
            name={`${modifierSets}.isSelected`}
            component={renderCheckbox}
            label={initialModifiers[index] && initialModifiers[index].name}
            disabled={disabled}
          />
          <span
            className='floatRight'>{`${initialModifiers[index] && initialModifiers[index].count} ${t('ItemsDetail.Options')}`}</span>
        </div>
      )
    })}

  </div>
);

export const renderAllowOutStockCheckbox = ({input, label, disabled}) => (
  <div className='tracking allowOutOfStock'>
    <CustomToggle
      label={label}
      input={input}
      labelPosition='end'
      style={{width: '60%', display: 'inline-block'}}
      toggled={!!input.value}
      onToggle={input.onChange}
      disabled={disabled}
    />
  </div>
);

export const renderFavoriteField = ({input, disabled, t}) => (
  <Checkbox
    externalClassName={'favoriteFieldWrapper'}
    className='favoriteButton'
    checkedIcon={IconUtils.getIcon('Favorite', disabled ? 'rgba(0, 0, 0, 0.38)' : LabelUtil.getLabelColor())}
    uncheckedIcon={IconUtils.getIcon('FavoriteBorder', disabled ? 'rgba(0, 0, 0, 0.38)' : LabelUtil.getLabelColor())}
    input={input}
    label={input.value ? t('ItemsDetail.RemoveFromFavorites') : t('ItemsDetail.AddToFavorites')}
    disabled={disabled}
    disableRipple
  />
);

export const findCategoryColor = function (initialCategories, categoryId) {
  let category = initialCategories.find(category => category.id === categoryId);
  return category ? category.color : 'D3D3D3';
};

export class ItemsDetail extends Component {

  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(value) {
    this.props.dispatch(setSelectedItemTab(value));
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!_.isEqual(this.props.salesItemImages.data, nextProps.salesItemImages.data)) {
      let imageId = this.props.initialValues.item.image_id;
      /* istanbul ignore else */
      if (!_.isUndefined(imageId)) {
        if (!_.isEmpty(nextProps.salesItemImages.data)) {
          if (this.props.salesItemImages.data[imageId] && this.props.salesItemImages.data[imageId].isFetching !== nextProps.salesItemImages.data[imageId].isFetching) {
            return true;
          }
        }
      }
      return false;
    }

    if (this.props.isTracked !== nextProps.isTracked) {
      return true;
    }

    return false;
  }

  render() {

    const { handleSubmit, user, auth, isTracked, isArchivedSection, isGiftCardItem, t } = this.props;

    const isCsUser = UserUtil.isUserCS(user, auth);
    const isReadOnly = !UserUtil.isActive(user);
    const isEditModeEnable = !isGiftCardItem && !(isCsUser || isArchivedSection);

    const labelColor = LabelUtil.getLabelColor();
    const deleteIcon = IconUtils.getIcon('DeleteIcon', labelColor);
    const deleteOption = {
      onClick: this.props.onDelete,
      text: t('ItemsDetail.ArchiveItem'),
      icon: deleteIcon,
      disabled: isGiftCardItem || isCsUser || isReadOnly
    };
    const deletePermanentlyOption = {
      onClick: this.props.onPermanentlyDelete,
      text: t('ItemsDetail.PermanentlyDeleteItem'),
      icon: deleteIcon,
      disabled: isCsUser || isReadOnly
    };

    const renderTaxCheckbox = ({input, label}) => (
      <Checkbox
        label={label}
        input={input}
        externalClassName={'halfScreen'}
        disabled={!isEditModeEnable || isReadOnly}
      />
    );

    const renderTrackItemCheckbox = ({input, label}) => (
      <div className='tracking'>
        <CustomToggle
          input={input}
          label={label}
          labelPosition='end'
          style={{width: '60%', display: 'inline-block'}}
          toggled={!!input.value}
          onToggle={input.onChange}
          disabled={!isEditModeEnable || isReadOnly}
        />
      </div>
    );

    const renderPricesField = ({fields, meta: {touched, error}, t}) => (
      <div>
        <div className='prices'>

          <div className='detailsRow detailsHeader'>{t('ItemsDetail.Prices')}</div>

          {error && fields.length === 0 ?
            <span className='errorText'>{t(error)}</span>
            : null}

          {fields.map((item, index) =>
            <div key={index} className='priceRow'>
              <div className='flexRow'>
                <Field
                  className='name errorSpacingBottom detailDescriptionText'
                  hintText={t('ItemsDetail.PriceNameHint')}
                  label={t('ItemsDetail.PriceNameLabel')}
                  name={`${item}.name`}
                  component={TextField}
                  disabled={!isEditModeEnable || isReadOnly}
                />
                <Field
                  className='barcode detailDescriptionText'
                  hintText={t('ItemsDetail.Barcode')}
                  label={t('ItemsDetail.Barcode')}
                  name={`${item}.barcode`}
                  normalize={(value) => FormatTextUtil.formatAlphaNumeric(value)}
                  component={TextField}
                  disabled={!isEditModeEnable || isReadOnly}
                />
              </div>
              <div className='flexRow'>
                <Field
                  className='errorSpacingBottom itemPrice detailDescriptionText'
                  hintText='$0.00'
                  label={t('ItemsDetail.Price')}
                  format={(value) => numeral(value).format('$0,0.00')}
                  normalize={(value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 10)}
                  name={`${item}.price`}
                  component={TextField}
                  disabled={!isEditModeEnable || isReadOnly}
                />
                <Field
                  className='errorSpacingBottom itemPrice detailDescriptionText'
                  hintText='$0.00'
                  label={t('ItemsDetail.Cost')}
                  format={(value) => numeral(value).format('$0,0.00')}
                  normalize={(value) => FormatTextUtil.formatCurrencyWithMaxDigit(value, 10)}
                  name={`${item}.cost`}
                  component={TextField}
                  disabled={!isEditModeEnable || isReadOnly}
                />
                {!isTracked && isEditModeEnable && <IconButton
                  className='deletePriceBtn'
                  onClick={() => fields.remove(index)}
                  size='large'
                  disabled={isReadOnly}
                >
                  {IconUtils.getIcon('DeleteIcon')}
                </IconButton>}
              </div>
              {isTracked &&
                <div className='flexRow'>
                  <div className='quantityContainer'>
                    <Field name={`${item}.quantity`}
                      normalize={FormatTextUtil.formatWholeNumber}
                      component={TextField}
                      label={t('ItemsDetail.NumberInStock').toLowerCase()}
                      style={{width: '80%'}}
                      className='quantity'
                      hintText='0'
                      disabled={!isEditModeEnable || isReadOnly}
                      maxLength={4}
                    />
                    <Field name={`${item}`} disabled={!isEditModeEnable} component={props =>
                      <div className='stockAlertIconContainer'>
                        {props.input.value.stock_enabled && parseInt(props.input.value.low_stock_alert_amount) >= parseInt(props.input.value.quantity) ?
                          <Tooltip
                              placement='right'
                              title={t('Tooltips.LowOfStock')}
                          >
                            {IconUtils.getIcon('Error', '#eb3223')}
                          </Tooltip>: null}
                      </div>
                    }/>
                  </div>
                  <Field
                    name={`${item}.low_stock_alert_amount`}
                    normalize={FormatTextUtil.formatWholeNumber}
                    component={TextField}
                    className='stockAlert'
                    label={t('ItemsDetail.LowStockAlertLabel')}
                    hintText={t('ItemsDetail.LowStockAlertHint')}
                    disabled={!isEditModeEnable || isReadOnly}
                    maxLength={3}
                  />
                  {isEditModeEnable && <IconButton
                    className='deletePriceBtn'
                    onClick={() => fields.remove(index)}
                    size='large'
                    disabled={isReadOnly}
                  >
                    {IconUtils.getIcon('DeleteIcon')}
                  </IconButton>}
                </div>
              }
            </div>
          )}
        </div>

        <div className='buttonContainer'>
          <Button
            className='simpleButton'
            disabled={!isEditModeEnable || isReadOnly}
            onClick={() => fields.push({})}
            startIcon={IconUtils.getIcon('PlusIcon')}
            label={t('ItemsDetail.AddPrice')}
            variant='text'
          />
        </div>

      </div>
    );

    const itemsOptions = this.props.selectedItem ? (
      this.props.isNewItem
        ? []
        : (this.props.isArchivedSection)
          ? [deletePermanentlyOption]
          : [deleteOption]
    ): [];

    const descriptionMaxLength = 250;


    const renderDescriptionField = ({input, label}) => (
      <>
        <ResizableTextArea
          label={label}
          hintText={t('ItemsDetail.ItemDescriptionHintText')}
          disabled={!isEditModeEnable || isReadOnly}
          maxLength={descriptionMaxLength}
          input={input}
        />
        {
          input.value?.length > 0 ?
            <span className='characterCount'>{`${(descriptionMaxLength - input.value?.length)}${t('LoyaltyPrograms.CharactersLeft')}`}</span>
          : 
            null
        }
      </>
    );

    return (
      <Box sx={inventoryStyles}>
        <form onSubmit={handleSubmit}>
          <div className='itemsDetail'>
            <div>
              <div className='detailContent itemTab'>
                <div>
                  <div className={'optionsContainer'}>
                    <DetailPanelOptions options={itemsOptions}/>
                    {
                      this.props.selectedItem?.is_active &&
                      <Field
                        component={renderFavoriteField}
                        name='item.is_favorite'
                        disabled={!isEditModeEnable || isReadOnly}
                        t={t}
                      />
                    }
                  </div>
                  <div className={'thumbnailContainer'}>
                  <Field
                    name='item.name'
                    disabled={!isEditModeEnable}
                    component={props =>
                      <ItemDetailThumbnail
                        {...this.props}
                        item={this.props.initialValues.item}
                        selectedColor={findCategoryColor(this.props.initialValues.categories, this.props.newCategory)}
                        name={props.input.value}
                        loadedImage={this.props.loadedImage}
                        setCroppedImage={this.props.setCroppedImage}
                        isEditModeEnable={isEditModeEnable}
                        disabled={isReadOnly}
                      />
                    }/>
                </div>
              </div>
              {isArchivedSection && <div className='archiveItemDescription'>
                <div>{IconUtils.getIcon('Warning', 'red')}</div>
                <div><span>{t('ItemsDetail.ArchiveItemTitle')}</span>
                  <p>{t('ItemsDetail.ArchiveItemDetail')}</p>
                  <a onClick={this.props.onReactivate}>{t('ItemsDetail.ReactivateLink')}</a>
                </div>
              </div>
              }
              <div className='flexRow'>
                <Field
                  className='nameInput'
                  label={t('ItemsDetail.ItemNameLabel')}
                  component={TextField}
                  fullWidth={false}
                  name='item.name'
                  disabled={!isEditModeEnable || isReadOnly}
                />
              </div>
              <div className='flexRow'>
                <div className='descriptionContainer'>
                  <Field
                    component={renderDescriptionField}
                    name='item.description'
                    label={t('ItemsDetail.ItemDescriptionLabel')}
                  />
                </div>
              </div>
              <div className='flexRow'>
                <Field
                  component={Select}
                  className='categorySelect'
                  name='selectedCategory'
                  label={t('Category.Label')}
                  disabled={!isEditModeEnable || isReadOnly}
                >
                  <MenuItem value={-1}>{t('ItemsDetail.SelectCategory')}</MenuItem>
                  {
                    this.props.initialValues.categories.map((item, i) => {
                      return <MenuItem value={item.id} key={i}>
                        <span className='bubble' style={{ marginRight: 15, marginLeft: 0 }}>
                          {IconUtils.getIcon('DotIcon', `#${item.color}`)}
                        </span>
                        {item.name}
                      </MenuItem>;
                    })
                  }
                </Field>
              </div>
              <div className='detailsRow detailsHeader'>{t('Tax')}</div>
              <div className='detailsCheckBox'>
                <Field
                  name='item.is_taxable'
                  component={renderTaxCheckbox}
                  label={t('ItemsDetail.SalesTaxLabel')}
                />
                <span
                  className='geoTaxLabel floatRight'>{this.props.taxes.isFetching ? (
                  <CircularProgress
                    className='refreshIndicator'
                    size={20}
                    style={{
                      boxShadow: 'none',
                      display: 'inline-block',
                      position: 'relative',
                      zIndex: '0',
                      float: 'left'
                    }}
                  />
                ) : this.props.initialValues.taxRate}</span>
              </div>
              <FieldArray
                name='modifierSets'
                component={renderModifiersField}
                initialModifiers={this.props.initialValues.modifierSets}
                disabled={!isEditModeEnable || isReadOnly}
                t={t}
              />
            </div>
            <div className='detailContent stockTab'>
              <div style={{marginBottom: '3em'}}>
                <Field
                  name='item.is_trackable'
                  component={renderTrackItemCheckbox}
                  label={t('ItemsDetail.TrackItemLabel')}
                />
                {this.props.isTracked &&
                  <Field
                    name='item.allow_outof_stock'
                    component={renderAllowOutStockCheckbox}
                    label={t('ItemsDetail.AllowOutOfStockSalesLabel')}
                    disabled={!isEditModeEnable || isReadOnly}
                  />}
              </div>
              <FieldArray name='item.details.prices' component={renderPricesField} t={t} />
            </div>
          </div>
        </div>
      </form>
      </Box>
    )
  }
}

let ItemsDetailContainer = reduxForm({
  form: 'itemDetailForm',
  validate: Validator.validateItemDetails.bind(Validator),
  fields: [
    'item.name',
    'item.description',
    'item.is_taxable',
    'item.is_trackable',
    'item.details.prices',
    'item.allow_outof_stock',
    'modifierSets',
    'selectedCategory',
  ],
  enableReinitialize: false,
})(ItemsDetail);

export function mapStateToProps(state, ownProps) {
  const selectedItem = ownProps.selectedItem;
  const isGiftCardItem = !!selectedItem?.is_gc;
  const categories = state?.items?.categories || [];
  const itemCategories = selectedItem && _.isArray(selectedItem.categories) ? selectedItem.categories : [];
  const selectedCategory = _.isArray(categories) && categories.find(category => itemCategories[0] && category.id === itemCategories[0].id) ? itemCategories[0].id : -1;
  const modifierSets = selectedItem?.details?.modifierSets || state?.items?.modifierSets || [];

  const basePrice = {
    name: 'Base Price',
    price: 0,
    quantity: null,
    stock_enabled: false,
    low_stock_alert_amount: null
  };

  if (!selectedItem.id) {
    selectedItem.details.prices = [basePrice];
  }

  let existingPrices = selectedItem.id ? selectedItem.details.prices : [];

  existingPrices.forEach(price => {
    if (!price.stock_enabled) {
      price.quantity = null;
    }
    if (price.name === '' || !price.hasOwnProperty('name')) {
      price.name = 'Base Price';
    }
  });

  const taxValue = ownProps?.items?.geoTaxEnabled ? Number(ownProps?.taxes?.taxRate || 0) : Number(ownProps?.items?.taxRate || 0);
  const taxRate = taxValue.toFixed(3) + '%';

  const newCategory = selector(state, 'selectedCategory');
  const isTracked = selector(state, 'item.is_trackable');

  return {
    initialValues: {
      item: selectedItem,
      taxRate: taxRate,
      modifierSets: modifierSets,
      categories: categories,
      selectedCategory: selectedCategory,
      existingCategories: itemCategories,
      existingPrices: existingPrices
    },
    newCategory,
    isTracked,
    isGiftCardItem,
    shouldValidate: ({ props, initialRender }) => {
      return !initialRender || props.dirty;
    }
  }

}

const selector = formValueSelector('itemDetailForm');
ItemsDetailContainer = connect(mapStateToProps)(ItemsDetailContainer);

export default ItemsDetailContainer;
