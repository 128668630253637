/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import numeral from 'numeral';
import { reset, submit } from 'redux-form';
import {
  emailValidation,
  postIdology,
  profileChange,
} from '../../actions/verificationActions';
import { getAccountProfile, getDavoStatus } from '../../actions/accountProfileActions';
import {
  getUserMerchantApplication,
  getUserProfile,
  updateUser,
  updateNeedsInfo
} from '../../actions/userProfileActions';
import { saveUserProfile, tierUpgrade } from '../../actions/userActions';
import {
  logoutUser,
  resetTwoFactorAuthCodeStatus,
  updateUserPassword
} from '../../actions/authActions';
import {
  emailValidatedFlag,
  setLinkEnabled,
  setMessageDialogContent,
  setOpenMessageDialog,
  setSubmitButtonEnabled,
  suppressAppError,
  updateUserEmail,
  toggleMerchantVerifyByCSDialog, togglePlanListDialog, setModalVisibility
} from '../../actions/userExperienceActions';
import { getCommunications } from '../../actions/communicationActions';
import actionTypes from '../../constants/actionTypes';
import {
  getAddOns,
  getMerchantSettings,
  toggleAutoReportingEnabled,
  toggleDavoFeatureEnabled,
  toggleIsISV,
  toggleLoyaltyFeatureEnabled,
  togglePushNotifications,
  updateAddOn,
  updateShowPersonalInfoMerchantSetting
} from '../../actions/merchantSettingsActions';
import ManageRememberedDevices from './ManageRememberedDevices';
import messages from '../../constants/messages';
import planTypes from '../../constants/planTypes';
import Button from '../shared/Button';
import TextField from '../shared/TextField';
import MessageDialog from '../shared/MessageDialog';
import FilterPanel from '../shared/FilterPanel';
import LastLoginActivity from '../shared/LastLoginActivity';
import MerchantVerifyByCSDialog from '../dialogs/account/MerchantVerifyByCSDialog';
import classNames from 'classnames';
import CustomToggle from '../shared/Toggle'
import Language from '../shared/Language';
import {buttonText, prices} from '../../constants/planInformation';
import Modal from '../shared/Modal';
import PlanList from './plans/PlanList';
import {IconButton, Menu} from '@mui/material';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import MenuItem from '../shared/MenuItem';
import { loyaltyAddOnMenuItemStyle } from '../../jss/inlineStyles';
import routes from '../../constants/routes';
import TwoFactorTools, { requestTwoFactorToken } from '../dialogs/account/TwoFactorTools';

import LabelUtil from '../util/LabelUtil';
import ProfileForm from './ProfileForm';
import UpdateSpinner from '../UpdateSpinner';
import UpdatePasswordForm from './UpdatePasswordForm';
import UpdateEmailForm from './UpdateEmailForm';
import UserUtil from '../util/UserUtil';
import twoFactorCodeStatusUtil from '../util/twoFactorCodeStatusUtil';
import IconUtils from '../util/IconUtil';
import PdfExporter from '../util/PdfExporter';
import DateUtils from '../util/DateUtil';
import Tooltip from '../shared/Tooltip';
import twoFactorTypes from '../../constants/twoFactorTypes';
import Page from '../shared/Page';
import { Box } from '@mui/material';
import {
  merchantSectionStyles,
  profileStyles
} from '../../jss/profileStyles';

export default class Profile extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.displayChangePasswordDialog = this.displayChangePasswordDialog.bind(this);
    this.displayChangeLanguageDialog = this.displayChangeLanguageDialog.bind(this);
    this.displayChangeEmailDialog = this.displayChangeEmailDialog.bind(this);
    this.displayCSDialog = this.displayCSDialog.bind(this);
    this.closeCSDialog = this.closeCSDialog.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.handleUpdatePasswordClose = this.handleUpdatePasswordClose.bind(this);
    this.handleUpdateEmailClose = this.handleUpdateEmailClose.bind(this);
    this.handleEmailCommunicationClose = this.handleEmailCommunicationClose.bind(this);
    this.validateChangePassword2fa = this.validateChangePassword2fa.bind(this);
    this.validateChangeEmail2fa = this.validateChangeEmail2fa.bind(this);

    this.onSavePasswordClick = this.onSavePasswordClick.bind(this);
    this.onSaveEmailClick = this.onSaveEmailClick.bind(this);
    this.onSaveProfileClick = this.onSaveProfileClick.bind(this);
    this.saveUserProfile = this.saveUserProfile.bind(this);

    this.sendValidationEmail = this.sendValidationEmail.bind(this);
    this.openMembershipDialog = this.openMembershipDialog.bind(this);
    this.handleCloseMembershipDialog = this.handleCloseMembershipDialog.bind(this);
    this.openAccessDialog = this.openAccessDialog.bind(this);
    this.handleCloseAccessDialog = this.handleCloseAccessDialog.bind(this);
    this.planUpgrade = this.planUpgrade.bind(this);
    this.openUpgradedDialog = this.openUpgradedDialog.bind(this);
    this.handleCloseUpgradedDialog = this.handleCloseUpgradedDialog.bind(this);
    this.handleCloseChangeConfirmation = this.handleCloseChangeConfirmation.bind(this);

    this.displayChangeManageRememberDevicesDialog = this.displayChangeManageRememberDevicesDialog.bind(this);
    this.handleManageRememberedDevicesClose = this.handleManageRememberedDevicesClose.bind(this);

    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.downloadMerchantApplication = this.downloadMerchantApplication.bind(this);
    this.downloadMerchantApplicationPdf = this.downloadMerchantApplicationPdf.bind(this);

    this.editUserProfile = this.editUserProfile.bind(this);

    this.openTwoFactorDialog = this.openTwoFactorDialog.bind(this);
    this.openTwoFactorSetUpDialog = this.openTwoFactorSetUpDialog.bind(this);

    this.on2faVerified = this.on2faVerified.bind(this);
    this.on2faProcessing = this.on2faProcessing.bind(this);
    this.on2faSetup = this.on2faSetup.bind(this);
    this.on2faClose = this.on2faClose.bind(this);

    // Methods if the idology endpoint doesnt retrieve the first 5 digits of the SSN
    this.callPostIdology = this.callPostIdology.bind(this);

    this.openMerchantVerifyByCSDialog = this.openMerchantVerifyByCSDialog.bind(this);
    this.closeMerchantVerifyByCSDialog = this.closeMerchantVerifyByCSDialog.bind(this);
    this.getAuthToUpdateInfo = this.getAuthToUpdateInfo.bind(this);

    this.togglePushNotifications = this.togglePushNotifications.bind(this);
    this.toggleIsISV = this.toggleIsISV.bind(this);
    this.toggleAutoReportingEnabled = this.toggleAutoReportingEnabled.bind(this);
    this.toggleLoyaltyFeatureEnabled = this.toggleLoyaltyFeatureEnabled.bind(this);
    this.toggleDAVOFeatureEnabled = this.toggleDAVOFeatureEnabled.bind(this);
    this.handleOpenLoyaltyAddOnMenu = this.handleOpenLoyaltyAddOnMenu.bind(this);
    this.handleCloseLoyaltyAddOnMenu = this.handleCloseLoyaltyAddOnMenu.bind(this);
    this.closeLoyaltyAddOnDialog = this.closeLoyaltyAddOnDialog.bind(this);
    this.openLoyaltyAddOnActivateOrDeactivateDialog = this.openLoyaltyAddOnActivateOrDeactivateDialog.bind(this);
    this.confirmLoyaltyAddOnActivationOrDeactivation = this.confirmLoyaltyAddOnActivationOrDeactivation.bind(this);
    this.goToLoyalty = this.goToLoyalty.bind(this);
    this.getAddOns = this.getAddOns.bind(this);

    this.state = {
      openUpdateLanguage: false,
      openUpdatePassword: false,
      openUpdateEmail: false,
      openTwoFactorSettingDialog: false,
      openCSDialog: false,
      openMerConfirmDialog: false,
      openFailedIdologyDialog: false,
      openEmailCommunicationDialog: false,
      loading: false,
      emailSelected: false,
      openMembershipDialog: false,
      openAccessDialog: false,
      twoFactorDialog: false,
      editPrimaryBankAccount : false,
      editChargebackBankAccount : false,
      editBillingBankAccount : false,
      upgradedDialog: false,
      changeConfirmation: false,
      loyaltyAddOnMenuAnchorEl: null,
      isUpdatingAddOn: false,
      twoFactorType: null,
      openChangePasswordDialogPending2fa: false,
      openChangeEmailDialogPending2fa: false,
      openDownloadMerchantApplication2fa: false
    };
  }

  componentDidMount() {
    this.loadData(this.props);
    const { user, t, dispatch, verification, userExperience: { openChangePasswordDialog } } = this.props;
    if (openChangePasswordDialog) {
      this.validateChangePassword2fa();
      requestTwoFactorToken(user, t, dispatch, verification);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setMessageDialogContent(null));
    this.props.dispatch(setOpenMessageDialog(false));
    this.props.dispatch(suppressAppError(false));
    this.props.dispatch(toggleMerchantVerifyByCSDialog(false));
    this.props.dispatch(resetTwoFactorAuthCodeStatus());
  }

  handleOpenLoyaltyAddOnMenu(event) {
    this.setState({loyaltyAddOnMenuAnchorEl: event.currentTarget});
  };

  handleCloseLoyaltyAddOnMenu() {
    this.setState({loyaltyAddOnMenuAnchorEl: null});
  };

  openLoyaltyAddOnActivateOrDeactivateDialog(isActivate) {
    this.handleCloseLoyaltyAddOnMenu();

    const {user, dispatch, auth: {isOwner}} = this.props;
    const isPA = UserUtil.userType(user) === 'PA';

    if(isOwner && isPA) {
      if(isActivate) {
        this.props.dispatch(setModalVisibility('loyaltyAddOnActivateDialog'));
      } else {
        this.props.dispatch(setModalVisibility('loyaltyAddOnDeactivateDialog'));
      }
    }
  }

  closeLoyaltyAddOnDialog() {
    this.props.dispatch(setModalVisibility('hidden'));
  }

  async confirmLoyaltyAddOnActivationOrDeactivation(isActivation) {
    const {user, dispatch} = this.props;

    await dispatch(updateAddOn(user, 'loyalty', isActivation));
    this.getAddOns();
    this.closeLoyaltyAddOnDialog();
  }

  goToLoyalty() {
    const appRoutePrefix = globalApplicationLabel.path;
    const customersRoute = appRoutePrefix + routes.business.root + routes.business.customers;

    this.props.history.push({
      pathname: customersRoute,
      state: {
        openLoyaltySettings: true
      }
    });
  }

  handleUpdatePasswordClose() {
    this.setState({openUpdatePassword: false});
  }

  handleUpdateEmailClose() {
    this.setState({openUpdateEmail: false});
  }

  async on2faVerified() {
    const { openChangePasswordDialogPending2fa, openChangeEmailDialogPending2fa, openDownloadMerchantApplication2fa } = this.state;
    const { verification, auth } = this.props;

    const updatePasswordVerified = verification?.twoFactorAuthenticationVerified[twoFactorTypes.updatePassword];
    const updateEmailVerified = verification?.twoFactorAuthenticationVerified[twoFactorTypes.updateEmail];
    const downloadMerchantVerified = verification?.twoFactorAuthenticationVerified[twoFactorTypes.downloadApplication];

    if (updatePasswordVerified && openChangePasswordDialogPending2fa) {
      this.setState({openChangePasswordDialogPending2fa: false});
      this.displayChangePasswordDialog();
    } else if (updateEmailVerified && openChangeEmailDialogPending2fa) {
      this.setState({openChangeEmailDialogPending2fa: false});
      this.displayChangeEmailDialog();
    } else if (downloadMerchantVerified && openDownloadMerchantApplication2fa) {
      await this.downloadMerchantApplicationPdf();
      this.setState({openDownloadMerchantApplication2fa: false});
    } else if (auth?.isOwner) {
      this.callPostIdology();
    }
  }

  on2faProcessing(state) {
    this.setState({ loading: state });
  }

  on2faSetup() {
    this.loadData(this.props);
  }

  on2faClose() {
    this.setState({
      openChangePasswordDialogPending2fa: false,
      openChangeEmailDialogPending2fa: false,
      openDownloadMerchantApplication2fa: false
    });
  }

  handleEmailCommunicationClose() {
    this.setState({openEmailCommunicationDialog: false});
  }

  getAddOns() {
    this.props.dispatch(getAddOns(this.props.user));
  }

  loadData(props) {
    const label = LabelUtil.getLabel();
    const {isOwner, isSpr, isAdmin} = props.auth;
    const isPA = UserUtil.userType(props.user) === 'PA';

    this.props.dispatch(setSubmitButtonEnabled(false));
    this.props.dispatch(getMerchantSettings(props.user));

    if (isOwner || isSpr) {
      props.dispatch(getAccountProfile(props.user));
      props.dispatch(setLinkEnabled(true));

      if ((label.abbreviation === 'ph' && UserUtil.userType(this.props.user) === 'MBP') || UserUtil.isEPX(this.props.user)) {
        props.dispatch(getCommunications(props.user));
      }
    }

    if (isSpr) {
      props.dispatch(getDavoStatus(props.user));
    }

    if (isOwner && isPA) {
      this.getAddOns();
    }

    if (!isSpr && (isOwner || isAdmin)) {
      props.dispatch(getUserProfile(props.user));
    }

  }

  sendValidationEmail() {
    const label = LabelUtil.getLabel();
    this.props.dispatch(emailValidation(this.props.user, label.abbreviation));
    this.setState({openEmailCommunicationDialog: true});
  }

  saveUserProfile(values) {
    const { auth, user, accountProfile, merchantSettings, dispatch } = this.props;
    const label = LabelUtil.getLabel();
    const that = this;
    const requests = [];
    const updateSettings = auth.isOwner && merchantSettings?.merchantSettings?.show_personal_info !== values.show_personal_info;

    this.setState({loading: true});

    values.principal_phone = values.principal_phone ? values.principal_phone.replace(/\D/g, '') : null;

    if ((label.abbreviation === 'ph' && UserUtil.userType(user) === 'MBP') || UserUtil.isEPX(user)) {

      /* istanbul ignore else */
      if (UserUtil.userType(user) === 'MBP') {
        if (values.comm_disputes === 'EMAIL' && accountProfile.data.email_validated === false) {
          requests.push(dispatch(saveUserProfile(user, values)));
          /* istanbul ignore else */
          if (!auth.isSpr) {
            requests.push(dispatch(emailValidation(user, label.abbreviation)));
          }
        } else {
          requests.push(dispatch(saveUserProfile(user, values)));
        }
      }
      if (UserUtil.userType(user) === 'PA') {
        if (values.comm_disputes === 'EMAIL' && accountProfile.data.email_validated === false) {
          requests.push(dispatch(saveUserProfile(user, values)));
          /* istanbul ignore else */
          if (!auth.isSpr) {
            requests.push(dispatch(emailValidation(user, label.abbreviation)));
          }
        } else {
          requests.push(dispatch(saveUserProfile(user, values)));
        }
      }
      if (updateSettings) {
        requests.push(dispatch(updateShowPersonalInfoMerchantSetting(user, values.show_personal_info)));
      }

      return Promise.all(requests).then((res) => {
        dispatch(profileChange());

        /* istanbul ignore else */
        if (!auth.isSpr) {
          if (accountProfile.data.email_validated === false && values.comm_disputes === 'EMAIL') {
            dispatch(emailValidatedFlag('progress'));
            that.setState({openEmailCommunicationDialog: true});
          } else {
            dispatch(emailValidatedFlag('invalid'));
          }
        }
        that.setState({loading: false, emailSelected: true});
        that.displayChangeConfirmationDialog();
        that.loadData(that.props);
        return Promise.resolve();
      });
    } else {
      return dispatch(saveUserProfile(user, values)).then((res) => {
        (updateSettings ? dispatch(updateShowPersonalInfoMerchantSetting(user, values.show_personal_info)) : Promise.resolve()).then((r) => {
          dispatch(profileChange());

          that.setState({loading: false});
          that.displayChangeConfirmationDialog();
          that.loadData(that.props);

          return Promise.resolve();
        });
      });
    }
  }

  changePassword(values) {

    let that = this;

    return this.props.dispatch(updateUserPassword(this.props.user, values)).then(function (response) {

      if (response.type !== actionTypes.userUpdateLoginInfoFailure) {
        that.handleUpdatePasswordClose();
      }

    });
  }

  changeEmail(values) {

    let that = this;

    return this.props.dispatch(updateUserEmail(this.props.user, values)).then(function (response) {

      if (response.type !== actionTypes.userUpdateLoginInfoFailure) {
        that.handleUpdateEmailClose();
        // Username update requires logging out user
        that.props.dispatch(logoutUser());
      }
    });
  }

  displayChangePasswordDialog() {
    this.props.dispatch(setSubmitButtonEnabled(true));
    this.setState({openUpdatePassword: true});
  }

  displayChangeLanguageDialog() {
    this.setState({ openUpdateLanguage: !this.state.openUpdateLanguage });
  }

  displayChangeEmailDialog() {
    this.props.dispatch(setSubmitButtonEnabled(true));
    this.setState({openUpdateEmail: true});
  }

  validateChangePassword2fa() {

    const { verification } = this.props;
    const updatePasswordVerified = verification?.twoFactorAuthenticationVerified[twoFactorTypes.updatePassword];

    if (updatePasswordVerified) {
      this.displayChangePasswordDialog();
    } else {
      this.openTwoFactorDialog(twoFactorTypes.updatePassword);
      this.setState({openChangePasswordDialogPending2fa: true});
    }
  }

  validateChangeEmail2fa() {

    const { verification } = this.props;
    const updateEmailVerified = verification?.twoFactorAuthenticationVerified[twoFactorTypes.updateEmail];

    if (updateEmailVerified) {
      this.displayChangeEmailDialog();
    } else {
      this.openTwoFactorDialog(twoFactorTypes.updateEmail);
      this.setState({openChangeEmailDialogPending2fa: true});
    }
  }

  openTwoFactorSetUpDialog() {
    this.props.dispatch(setSubmitButtonEnabled(true));
    this.props.dispatch(setModalVisibility('twoFactorSetupDialog'));
  }

  displayCSDialog() {
    this.setState({openCSDialog: true});
  }

  closeCSDialog() {
    this.props.dispatch(setOpenMessageDialog(false));
    this.props.dispatch(suppressAppError(false));
    this.setState({openCSDialog: false});
  }

  openMerchantVerifyByCSDialog() {
    this.props.dispatch(toggleMerchantVerifyByCSDialog(true));
  }

  getAuthToUpdateInfo() {
    this.props.dispatch(updateNeedsInfo(this.props.user));
  }

  closeMerchantVerifyByCSDialog() {
    this.props.dispatch(toggleMerchantVerifyByCSDialog(false));
  }

  onSavePasswordClick() {
    this.props.dispatch(submit('updatePasswordForm'));
  }

  onSaveEmailClick() {
    this.props.dispatch(submit('updateEmailForm'));
  }

  onSaveProfileClick() {
    this.props.dispatch(submit('profileForm'));
  }

  openMembershipDialog() {
    this.props.dispatch(setSubmitButtonEnabled(true));
    this.setState({openMembershipDialog: true});
  }

  handleCloseMembershipDialog() {
    this.props.dispatch(setSubmitButtonEnabled(false));
    this.setState({openMembershipDialog: false});
  }

  openAccessDialog() {
    this.setState({openAccessDialog: true});
  }

  handleCloseAccessDialog() {
    this.setState({openAccessDialog: false});
  }

  planUpgrade() {

    const membershipStatus = UserUtil.membershipStatus(this.props.user);

    const planType = membershipStatus.planType === 'Free' ? planTypes.premium : planTypes.free;

    this.props.dispatch(tierUpgrade(this.props.user, planType));

    this.handleCloseMembershipDialog();
    this.openUpgradedDialog();
  }

  openUpgradedDialog() {
    this.setState({upgradedDialog: true});
  }

  handleCloseUpgradedDialog() {

    let that = this;

    this.setState({upgradedDialog: false});

    this.props.dispatch(getUserProfile(this.props.user)).then(() => {
      that.props.dispatch(updateUser());
    });
  }

  displayChangeConfirmationDialog() {
    this.setState({changeConfirmation: true});
  }

  handleCloseChangeConfirmation() {
    this.setState({changeConfirmation: false});
  }

  handleEditCancel() {
    const { dispatch } = this.props;
    this.setState({editingProfile: false});
    dispatch(reset('profileForm'));
  }

  handleManageRememberedDevicesClose() {
    this.props.dispatch(setModalVisibility('hidden'));
  }

  displayChangeManageRememberDevicesDialog() {
    this.props.dispatch(setModalVisibility('manageRememberedDevices'));
  }

  downloadMerchantApplicationPdf() {

    return this.props.dispatch(getUserMerchantApplication(this.props.user))
      .then((statementPdf) => {

        const account = UserUtil.getActiveAccount(this.props.user);

        if (statementPdf.response && statementPdf.response.data) {
          const filename = 'merchant-application-' + account.mid + '.pdf';
          PdfExporter.download(statementPdf.response.data, filename);
        }

      });
  }

  openTwoFactorDialog(twoFactorType) {
    const { dispatch } = this.props;
    this.setState({ twoFactorType }, () => {
      dispatch(setModalVisibility('twoFactorDialog'));
    });
  }

  editUserProfile() {
    const { verification } = this.props;
    this.setState({editingProfile: true});
    if (!verification.twoFactorAuthenticationVerified[twoFactorTypes.userProfile]) {
      this.openTwoFactorDialog(twoFactorTypes.userProfile);
    }
  }

  callPostIdology(){
    this.setState({loading: true});
    this.props.dispatch(postIdology(this.props.user, null, null)).then(() => {
      this.setState({loading: false});
    });
  }

  togglePushNotifications() {
    const { user, dispatch } = this.props;
    const value = !this.props.merchantSettings.merchantSettings.push_notifications_enabled;
    this.setState({ loading: true });
    dispatch(togglePushNotifications(user, value))
      .finally(() => this.setState({ loading: false }));
  }

  toggleIsISV() {
    const { user, dispatch } = this.props;
    const value = !this.props.merchantSettings.merchantSettings.is_isv;
    this.setState({ loading: true });
    dispatch(toggleIsISV(user, value))
      .finally(() => this.setState({ loading: false }));
  }

  toggleAutoReportingEnabled() {
    const { user, dispatch } = this.props;
    const value = !this.props.merchantSettings.merchantSettings.auto_reporting_enabled;
    this.setState({ loading: true });
    dispatch(toggleAutoReportingEnabled(user, value))
      .finally(() => this.setState({ loading: false }));
  }

  toggleLoyaltyFeatureEnabled() {
    const { user, dispatch } = this.props;
    const value = this.props.merchantSettings.merchantSettings.loyalty_status === 'enabled' ? 'disabled' : 'enabled';
    this.setState({ loading: true });
    dispatch(toggleLoyaltyFeatureEnabled(user, value))
      .finally(() => this.setState({ loading: false }));
  }

  async toggleDAVOFeatureEnabled() {
    const {
      user,
      dispatch,
      merchantSettings
    } = this.props;

    const isDAVOEnabled = merchantSettings?.merchantSettings?.davo_enabled;

    this.setState({ loading: true });

    await dispatch(toggleDavoFeatureEnabled(user, !isDAVOEnabled));
    await dispatch(getDavoStatus(user));

    this.setState({ loading: false });
  }

  handleClosePlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(false));
  };

  handleOpenPlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(true));
  };

  downloadMerchantApplication = async () => {
    const { verification: { twoFactorAuthenticationVerified } } = this.props;
    if (twoFactorAuthenticationVerified[twoFactorTypes.downloadApplication]) {
      await this.downloadMerchantApplicationPdf();
    } else {
      this.setState({ openDownloadMerchantApplication2fa: true}, () => {
        this.openTwoFactorDialog(twoFactorTypes.downloadApplication);
      });
    }
  }

  render() {
    const merchantProcessingApplicationLink = 'https://enrollment-agreement.paymentshub.com/agreement/agreement.pdf';
    const { isFetching, accountProfile, user, auth, verification, communications, userExperience, merchantSettings, t } = this.props;

    const isLoading = userExperience.isFetching || accountProfile.isFetching || merchantSettings.isProcessing;

    const label = LabelUtil.getLabel();
    const membershipStatus = user && user.data && user.data.username ? UserUtil.membershipStatus(user) : null;
    const membershipPlan = UserUtil.accountCurrentPlan(user);
    const isPremiumForFree = user && user.data && user.data.username ? UserUtil.isPremiumForFree(user) : null;

    const readOnly = !UserUtil.isActive(user);
    const isEmpty = accountProfile.data === null;
    const loadCommunications = communications.data === null && label.abbreviation === 'ph';
    const profileView = auth.isOwner || auth.isSpr;

    const isOwner = UserUtil.isOwner(user) || auth?.isOwner;
    const isNonPA = UserUtil.userType(user) !== 'PA';
    const isHumboltUser = UserUtil.isHumbolt(user);
    const isCsUser = UserUtil.isUserCS(user, auth);
    const isCsUserWrite = UserUtil.isUserCSWrite(user);
    const isActiveMerchantAccount = UserUtil.isActive(user);
    const shouldShowPlanOptions = (isOwner && isCsUserWrite) || (isOwner && isCsUser) || isOwner;
    const isCsCbdAccount = UserUtil.isCsCbdAccount(auth, merchantSettings);
    const isCsCannabisAccount = UserUtil.isCsCannabisAccount(auth, merchantSettings);
    const isEdgeUser = merchantSettings?.edgeStatus?.is_edge;
    const isCashDiscountEnabled = merchantSettings?.merchantSettings?.cash_discount_enabled;
    const cashDiscountFixedAmount = numeral(merchantSettings?.merchantSettings?.cash_discount_fixed_amount).format('$0,0.00');
    const cashDiscountPercentAmount = merchantSettings?.merchantSettings?.cash_discount_amount_percent ?
      numeral(merchantSettings.merchantSettings.cash_discount_amount_percent / 100).format('0.00%') : '0.00%';
    const isVtEnabled = UserUtil.isCsPaVtInvoiceEnabled(auth, merchantSettings);
    const isSmsEnabled = UserUtil.isCsSmsEnabled(auth, merchantSettings);
    const isLavaEnabled = UserUtil.isLavaEnabled(auth, merchantSettings);

    const isLoyaltyEnabled = UserUtil.isLoyaltyEnabled(user, merchantSettings);
    const isLoyaltyInterested= UserUtil.isLoyaltyInterested(merchantSettings);
    const showLoyaltyProgramToggle = isLoyaltyInterested || isLoyaltyEnabled || isCsUserWrite;

    const isDAVOConnected = accountProfile.davo?.is_integrated;
    const isDAVOEnabled = merchantSettings?.merchantSettings?.davo_enabled;

    const lastLogin = user && user.data && user.data.last_login;

    const activeAccount = UserUtil.getActiveAccount(user);
    const displayMerchantApplication = auth.isOwner && activeAccount.can_view_application;
    const hasReadMerchantApplication = !!user.data.viewed_application_date;
    const customerServicePhone = label.abbreviation === 'ph' ? '877.464.4218' : '877.387.5640';
    const customerServiceEmail = label.abbreviation === 'ph' ? 'support@paymentshub.com' : 'custservice@payanywhere.com';
    const pdfIcon = IconUtils.getIcon('PdfExport', !hasReadMerchantApplication ? '#ff0000' : LabelUtil.getLabelColor());
    const keyholeIcon = IconUtils.getIcon('KeyholeIcon', LabelUtil.getLabelColor());
    const editPenIcon = IconUtils.getIcon('EditPenIcon', LabelUtil.getLabelColor());
    const emailAtIcon = IconUtils.getIcon('EmailAtIcon', LabelUtil.getLabelColor());
    const computerIcon = IconUtils.getIcon('ComputerCheckMark', LabelUtil.getLabelColor());
    const twoFactorShieldIcon = IconUtils.getIcon('TwoFactorShieldIcon', LabelUtil.getLabelColor());
    const languageIcon = IconUtils.getIcon('LanguageIcon', LabelUtil.getLabelColor());

    const openPlanListDialog = userExperience.openPlanListDialog;

    const pageTitle = t('UserSettingsTitle');
    const infoIcon = IconUtils.getIcon('InfoIcon', label.primaryColor);

    const pageLoading = (isEmpty && isFetching && profileView && loadCommunications) || isLoading;

    if (pageLoading) {
      return (
        <Page loading title={pageTitle} />
      )
    }

    const verifyingEmail = accountProfile.data && userExperience.uxStorage && userExperience.uxStorage.emailValidated ? userExperience.uxStorage.emailValidated === 'progress' && !accountProfile.data.email_validated : false;

    const isPremiumForFreeClassname = isPremiumForFree ? ' hideMe' : '';
    const submitButtonDisabled = !userExperience.enableFormSubmitButton;

    const changeLanguageDialog = (
      <Language isDialogOpen={this.state.openUpdateLanguage} toggleDialogOpen={this.displayChangeLanguageDialog} />
    );

    const userProfileVerified = verification.twoFactorAuthenticationVerified[twoFactorTypes.userProfile] && verification.verified;

    const changePasswordDialog = (<MessageDialog
        cancelDisabled={user.isUpdating}
        cancelText={t('Cancel')}
        confirmText={t('Save')}
        disabled={user.isUpdating || submitButtonDisabled}
        externalClassName='changePasswordDialog'
        isChoiceRequired={false}
        onConfirm={this.onSavePasswordClick}
        onRequestClose={this.handleUpdatePasswordClose}
        open={this.state.openUpdatePassword}
        titleText={t('UpdatePassword')}
        bodyText={
          <UpdatePasswordForm {...this.props} onSubmit={this.changePassword}/>
        } />
    );

    const changeEmailDialog = (<MessageDialog
        cancelDisabled={user.isUpdating}
        cancelText={t('Cancel')}
        confirmText={t('Save')}
        disabled={user.isUpdating || submitButtonDisabled}
        externalClassName='changeEmailDialog'
        isChoiceRequired={false}
        onConfirm={this.onSaveEmailClick}
        onRequestClose={this.handleUpdateEmailClose}
        open={this.state.openUpdateEmail}
        titleText={t('UpdateEmailAddress')}
        bodyText={
          <UpdateEmailForm onSubmit={this.changeEmail} t={t} />
        } />
    );

    const manageRememberedDevicesSettingDialog = (
      <MessageDialog
        confirmText=''
        hideConfirmButton
        isChoiceRequired={false}
        externalClassName='manageRememberedDevicesDialog'
        onRequestClose={this.handleManageRememberedDevicesClose}
        open={userExperience.modalVisibility.manageRememberedDevices}
        titleText={t('ManageRememberedDevices.Title')}
        bodyText={
          <ManageRememberedDevices
            dispatch={this.props.dispatch}
            user={this.props.user}
            t={this.props.t}
          />
        }
      />
    );

    const profileSection = profileView ? (
      <div className='profileSection'>
        <div className='profileSectionHolder businessProfile'>
          {userProfileVerified && this.state.editingProfile && (
            <div className='ownerProfileActions'>
              {!isHumboltUser && (
                <Button
                  className='editBusinessProfile flatButtonLink'
                  label={t('Save')}
                  onClick={this.onSaveProfileClick}
                  variant='text'
                />
              )}
              <Button
                className='editBusinessProfile flatButtonLink'
                label={t('Cancel')}
                onClick={this.handleEditCancel}
                variant='text'
              />
            </div>
          )}
          <ProfileForm
            disableFields={(auth.isSpr && !isCsUserWrite) || !UserUtil.isActive(user) || !userProfileVerified || !this.state.editingProfile}
            onSubmit={this.saveUserProfile} emailSelected={verifyingEmail}
            resendValidationEmail={this.sendValidationEmail}
            {...this.props}
          />
        </div>
        {auth.isOwner ? (
          <div className='personalSubmit'>
            {userProfileVerified && this.state.editingProfile ?
              <div>
                <Button
                  label={t('SaveProfile')}
                  disabled={readOnly || submitButtonDisabled}
                  onClick={this.onSaveProfileClick}
                  className='saveProfileButton editBusinessProfile'/>
                <Button
                  label={t('Cancel')}
                  disabled={readOnly || submitButtonDisabled}
                  onClick={this.handleEditCancel}
                  className='cancelProfileButton editBusinessProfile'/>
              </div> : null}
          </div>
        ) : null}
      </div>
    ) : null;

    const emailCommunicationDialog = (<MessageDialog
        confirmText={t('Close')}
        disabled={submitButtonDisabled}
        externalClassName='emailCommunicationDialog'
        isChoiceRequired={false}
        onConfirm={this.handleEmailCommunicationClose}
        onRequestClose={this.handleEmailCommunicationClose}
        open={this.state.openEmailCommunicationDialog}
        titleText={t('VerifyEmailAddress')}
        bodyText={
          <p>{t('EmailCommunicationDialogContent', { username: user.data.username })}</p>
        } />
    );

    const loading = (this.state.loading || twoFactorCodeStatusUtil.isSendingCode(auth.twoFactorCodeStatus)) ? <UpdateSpinner/> : null;

    const premiumPrice = t(messages.paymentshubTier.premiumPrice);

    const planListDialog = (
      <Modal
        title={t('UpgradePremiumPlus.ModalTitle')}
        centerTitle
        isClosableIconEnable
        floatingCloseButton
        hideConfirmButton
        hideCancelButton
        open={openPlanListDialog}
        onClose={this.handleClosePlanListDialog}
        maxWidth='lg'
      >
        <PlanList {...this.props} />
      </Modal>
    );

    const membershipDialog = (
      <MessageDialog
        cancelText={t('Cancel')}
        confirmText={membershipStatus && membershipStatus.planType === 'Premium' ? t('OptOut') : t('Upgrade')}
        disabled={submitButtonDisabled}
        isChoiceRequired={false}
        onConfirm={this.planUpgrade}
        onRequestClose={this.handleCloseMembershipDialog}
        open={this.state.openMembershipDialog}
        titleText={membershipStatus && membershipStatus.planType === 'Premium' ? t('OptOut') : t('UpgradePremiumPlan')}
        bodyText={
          <p className='membershipDialogContent'>
            {membershipStatus && membershipStatus.planType === 'Premium' ? t(messages.paymentshubTier.optOutOfPremiumContent) : t(messages.paymentshubTier.upgradeToPremiumContent, {premiumPrice})}
          </p>
        } />
    );

    const accessDialog = (
      <MessageDialog
        confirmText={t('Okay')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseAccessDialog}
        onRequestClose={this.handleCloseAccessDialog}
        open={this.state.openAccessDialog}
        titleText={membershipStatus && membershipStatus.planType === 'Premium' ? t('OptOutPremiumAccess') : t('UpgradePremiumAccess')}
        bodyText={
          <p className='membershipDialogContent'>{t('MembershipDialogContent')}</p>
        } />
    );

    const tickIcon = IconUtils.getIcon('CheckCircle', LabelUtil.getLabelColor());

    const upgradedDialog = (
      <MessageDialog
        confirmText={t('Okay')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseUpgradedDialog}
        onRequestClose={this.handleCloseUpgradedDialog}
        open={this.state.upgradedDialog}
        titleText={
          <div>
            {tickIcon}
            <h2>{membershipStatus && membershipStatus.planType === 'Premium' ? t('OptedOut') : t('Upgraded')}</h2>
          </div>}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>
            {membershipStatus && membershipStatus.planType === 'Premium' ? t(messages.paymentshubTier.successfullyOptedOut) : t(messages.paymentshubTier.successfullyEnrolled)}
          </p>
        }/>
    );

    const merchantApplicationSection = displayMerchantApplication ? (
      <Box className='merchantSection merchantApplicationSection' sx={merchantSectionStyles}>
        <div className='formHeading'>{t('MerchantApplicationHeading')}</div>
        <div className='merchantSectionBody'>
          <div className={classNames('merchantSectionIcon', { 'unread': !hasReadMerchantApplication})} onClick={this.downloadMerchantApplication}>
            {pdfIcon}
          </div>
          <div className='merchantSectionDescription'>
            <h4
              className={!hasReadMerchantApplication ? 'merchantSectionDescriptionTitle unread' : 'merchantApplicationDescriptionTitle'}
              onClick={this.downloadMerchantApplication}
            >{t('MerchantApplicationDownload')}</h4>
            <p>{t('MerchantApplicationContent', { customerServicePhone, customerServiceEmail })}</p>
          </div>
        </div>
      </Box>
    ) : null;

    const merchantProcessingAgreementSection = displayMerchantApplication ? (
      <Box className='merchantSection merchantProcessingAgreementSection' sx={merchantSectionStyles}>
        <div className='formHeading'>{t('MerchantProcessingAgreementHeading')}</div>
        <div className='merchantSectionBody'>
          <a href={merchantProcessingApplicationLink} target='_blank' className='merchantSectionIcon'>
            {pdfIcon}
          </a>
          <div className='merchantSectionDescription'>
            <a
              className='merchantSectionDescriptionTitle'
              href={merchantProcessingApplicationLink}
              target='_blank'
            >
              {t('MerchantProcessingAgreementDownload')}
            </a>
          </div>
        </div>
      </Box>
    ) : null;

    const accountProfileClass = 'pageWrap';
    const userNeedsInfo = !!user.data.merchantAccounts.filter(account => account.mea_id === Number(user.selectedMerchantAccount))[0].needs_info;


    const filterPanelMobileOnly = (
      <FilterPanel
        mobileOnly={true}
        {...this.props}
      />
    );

    const changeConfirmationDialog = (
      <MessageDialog
        confirmText={t('Okay')}
        isChoiceRequired={false}
        onConfirm={this.handleCloseChangeConfirmation}
        onRequestClose={this.handleCloseChangeConfirmation}
        open={this.state.changeConfirmation}
        titleText={t('UpdatesPendingTitle')}
        bodyText={
          <p className='tiersUpgradedPlanDialogContent'>{t('UpdatesPendingContent')}</p>
        }/>
    );

    const loyaltyEndDate = membershipStatus?.isInTrial ? DateUtils.formatDate(DateUtils.addTimeToToday(membershipStatus.daysRemaining, 'd'), 'MM/DD/YY') : null;

    const loyaltyAddOn = this.props.merchantSettings.addOns && this.props.merchantSettings.addOns.length ? this.props.merchantSettings.addOns[0] : {};
    const isLoyaltyAddOnActive = loyaltyAddOn.enabled;

    const isPremiumPlusAccount = UserUtil.isPremiumPlusAccount(user);
    const isPremiumPlusTrialAccount = UserUtil.isPremiumPlusTrialAccount(user);

    const checkIcon = IconUtils.getIcon('CheckIcon');
    const goToLoyaltyIcon = IconUtils.getIcon('NextPlanIcon');
    const deactivateIcon = IconUtils.getIcon('PowerSettingsIcon');

    const canChangeLoyalty = isPremiumPlusAccount && !isPremiumPlusTrialAccount && !isNonPA && !readOnly;

    const loyaltyAddOnActivateDialog = (<MessageDialog
        confirmText={t('AddOns.Loyalty.Activate')}
        externalClassName='loyaltyAddOnDialog'
        cancelText={t('Cancel')}
        isChoiceRequired={true}
        onConfirm={this.confirmLoyaltyAddOnActivationOrDeactivation.bind(this, true)}
        onRequestClose={this.closeLoyaltyAddOnDialog}
        open={!!this.props.userExperience.modalVisibility.loyaltyAddOnActivateDialog}
        titleText={t('AddOns.Loyalty.ActivationDialog.Title')}
        bodyText={
          <div className='loyaltyAddOnDialogContent'>
            <span>
              {t('AddOns.Loyalty.ActivationDialog.BodyFirstPart')}
            </span>
            <p>
              {t('AddOns.Loyalty.ActivationDialog.BodySecondPart')}
            </p>
          </div>
        } />
    );

    const loyaltyAddOnDeactivateDialog = (<MessageDialog
        confirmText={t('AddOns.Loyalty.Deactivate')}
        externalClassName='loyaltyAddOnDialog'
        cancelText={t('Cancel')}
        isChoiceRequired={true}
        onConfirm={this.confirmLoyaltyAddOnActivationOrDeactivation.bind(this, false)}
        onRequestClose={this.closeLoyaltyAddOnDialog}
        open={!!this.props.userExperience.modalVisibility.loyaltyAddOnDeactivateDialog}
        titleText={t('AddOns.Loyalty.DeactivationDialog.Title')}
        bodyText={
          <div className='loyaltyAddOnDialogContent'>
            <span>
              {t('AddOns.Loyalty.DeactivationDialog.BodyFirstPart')}
            </span>
            <p>
              {t('AddOns.Loyalty.DeactivationDialog.BodySecondPart')}
            </p>
          </div>
        } />
    );

    return (
      <Page
        loading={pageLoading}
        title={pageTitle}
      >
        <Box className='pageScrollableArea accountProfile' sx={profileStyles}>
          {filterPanelMobileOnly}
          <div className={accountProfileClass}>
            <div className='signInSettings'>
              {isActiveMerchantAccount && (
                <>
                  <div className={`updateTwoFactorContainer settingsContainer ${isCsUser ? 'disabled' : ''}`}>
                    {twoFactorShieldIcon}
                    <div className='updateTwoFactor updateSettingsValue' onClick={!isCsUser ? this.openTwoFactorSetUpDialog : null}>{t('SecuritySettings')}</div>
                  </div>
                  <div className='updateEmailContainer settingsContainer'>
                    {emailAtIcon}
                    <div className='updateEmail updateSettingsValue' onClick={this.validateChangeEmail2fa}>{t('UpdateEmailAddress')}</div>
                  </div>
                  <div className='updatePasswordContainer settingsContainer'>
                    {keyholeIcon}
                    <div className='updatePassword updateSettingsValue' onClick={this.validateChangePassword2fa}>{t('UpdatePassword')}</div>
                  </div>
                </>
              )}
              <div className='updateLanguageContainer settingsContainer'>
                {languageIcon}
                <div className='updateLanguage updateSettingsValue' onClick={this.displayChangeLanguageDialog}>
                  {t('UserLanguage')}
                </div>
              </div>
              {isActiveMerchantAccount && (
                <div className='updateManageRememberedDevicesContainer settingsContainer'>
                  {computerIcon}
                  <div className='updateManageRememberedDevices updateSettingsValue' onClick={this.displayChangeManageRememberDevicesDialog}>{t('UpdateManageRememberedDevices')}</div>
                </div>
              )}
              {isActiveMerchantAccount && !isHumboltUser && (!this.state.editingProfile || !userProfileVerified) && isOwner &&
                <div className='updateProfileContainer settingsContainer'>
                  {editPenIcon}
                  <div className='updateProfile updateSettingsValue' onClick={this.editUserProfile}>{t('EditPersonalProfile')}</div>
                </div>
              }
            </div>
            {auth.isSpr &&
              <div className='csSettingsHolder'>
                <h1 className='pageTitle csSettingsTitle'>{t('CsSettingsTitle')}</h1>
                <div className='csSettingsBreak'></div>
                <div className='csSettingsContainer'>
                  <div className='csSettings'>{t('CsSettings.PlanType')} <span>{user.data.merchantAccounts[0].plan_type}</span></div>
                  <div className='csSettings'>{t('CsSettings.HasOptedOut')} <span>{user.data.merchantAccounts[0].plan_has_opted_out ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.PlanOptedOut')} <span>{user.data.merchantAccounts[0].plan_opted_out_on}</span></div>
                  <div className='csSettings'>{t('CsSettings.PremiumFree')} <span>{user.data.merchantAccounts[0].is_premium_free ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.LargeVolumeProcessor')} <span>{user.data.merchantAccounts[0].is_big_data ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.ProcessingLevel')} <span>{user.data.merchantAccounts[0].processing_level}</span></div>
                  <div className='csSettings'>{t('CsSettings.DebtRepaymentAllowed')} <span>{user.data.merchantAccounts[0].dp_allowed  ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.CanViewApplication')} <span>{user.data.merchantAccounts[0].can_view_application  ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.AgentAccessStatus')} <span>{user.data.merchantAccounts[0].agent_access_status}</span></div>
                  <div className='csSettings'>{t('CsSettings.Salido')} <span>{user.data.merchantAccounts[0].is_salido ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.HBT')} <span>{user.data.merchantAccounts[0].isHbt ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.Cannabis')} <span>{`${isCsCannabisAccount}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.CBD')} <span>{`${isCsCbdAccount}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.IsEdgeUser')} <span>{`${isEdgeUser}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.CashDiscountEnabled')} <span>{`${isCashDiscountEnabled}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.CashDiscountFixedAmount')} <span>{`${cashDiscountFixedAmount}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.CashDiscountPercentAmount')} <span>{`${cashDiscountPercentAmount}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.VtEnabled')} <span>{`${isVtEnabled}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.SmsEnabled')} <span>{`${isSmsEnabled}`}</span></div>
                  <div className='csSettings'>{t('CsSettings.ReputationManagement')} <span>{user.data.merchantAccounts[0].is_reputation_management_enabled ? 'true' : 'false'}</span></div>
                  <div className='csSettings'>{t('CsSettings.LoyaltyStatus')} <span>{merchantSettings?.merchantSettings?.loyalty_status || 'disabled'}</span></div>

                  {showLoyaltyProgramToggle && (
                    <div className='csSettings csSettingsWithToggle'>{t('CsSettings.LoyaltyStatusWithToggle')}
                      <CustomToggle
                        labelPosition='start'
                        label={''}
                        disabled={merchantSettings.merchantSettings === null || !isCsUserWrite || merchantSettings?.merchantSettings?.loyalty_status === 'enabled'}
                        input={{
                          value: merchantSettings?.merchantSettings?.loyalty_status === 'enabled',
                          onChange: this.toggleLoyaltyFeatureEnabled
                        }}
                      />
                    </div>)}

                  {(user.data.merchantAccounts[0].loyalty_vpc || merchantSettings?.merchantSettings?.loyalty_status === 'enabled') &&
                    <div className='csSettings'>
                      {t('CsSettings.LoyaltyVpc')} <span>{user.data.merchantAccounts[0].loyalty_vpc ? 'true' : 'false'}</span>
                    </div>
                  }

                  <div className='csSettings'>{t('CsSettings.DAVOStatus')}
                    <span>{` ${!!isDAVOConnected}`}</span>
                  </div>
                  <div className='csSettings csSettingsWithToggle'>
                    {t('CsSettings.DAVOStatusWithToggle')}
                    <CustomToggle
                      input={{
                        value: isDAVOEnabled,
                        onChange: this.toggleDAVOFeatureEnabled
                      }}
                      label={''}
                      labelPosition={'start'}
                    />
                  </div>

                  <div className='csSettings'>{t('CsSettings.LavaEnabled')}
                    <span>{`${isLavaEnabled}`}</span></div>
                  <div
                    className='csSettings csSettingsWithToggle'>{t('CsSettings.CsSettingsWithToggle')}
                    <CustomToggle
                      labelPosition='start'
                      label={''}
                      disabled={merchantSettings.merchantSettings === null || !isCsUserWrite}
                      input={{
                        value: merchantSettings?.merchantSettings?.push_notifications_enabled,
                        onChange: this.togglePushNotifications
                      }}
                    />
                  </div>
                  <div className='csSettings csSettingsWithToggle'>{t('CsSettings.IsISV')}
                    <CustomToggle
                      labelPosition='start'
                      label={''}
                      disabled={merchantSettings.merchantSettings === null || !isCsUserWrite}
                      input={{
                        value: merchantSettings?.merchantSettings?.is_isv,
                        onChange: this.toggleIsISV
                      }}
                    />
                  </div>
                  <div className='csSettings csSettingsWithToggle'>{t('CsSettings.AutoReportingEnabled')}
                    <CustomToggle
                      labelPosition='start'
                      label={''}
                      disabled={merchantSettings.merchantSettings === null || !isCsUserWrite}
                      input={{
                        value: merchantSettings?.merchantSettings?.auto_reporting_enabled,
                        onChange: this.toggleAutoReportingEnabled
                      }}
                    />
                  </div>
                </div>
                {(isCsUserWrite && userNeedsInfo) ? (
                  <Button
                    label={t('CsSettings.CsVerified')}
                    onClick={this.openMerchantVerifyByCSDialog}
                  />
                ) : null}
              </div>
            }

            <div className='formItems'>
              <TextField
                label={t('UsernameLabel')}
                hintText={t('EmailAddress')}
                disabled={true}
                className='profileEmailAddressField'
                value={user.data.username}
              />
              <TextField
                label={t('Password')}
                autoComplete='new-password'
                disabled={true}
                hintText={t('Password')}
                type='password'
                className='profilePasswordField'
                value='        '
              />
            </div>

            <LastLoginActivity lastLogin={lastLogin} t={t} />

            {membershipStatus ?
              <div className='membershipInfo'>
                <div className='formHeading'>{'Plan'}</div>
                <div className='membershipContainer'>
                  <div className='membershipValues'>
                    <div className='membershipPlan'>{buttonText[membershipPlan]}</div>
                    <div className={'membershipDetails' + isPremiumForFreeClassname}>
                      <p>{prices[membershipPlan]}</p>
                      <p>{`${t('Enrolled', { enrolledDate: membershipStatus.enrolledDate })}`}</p>
                    </div>
                    {membershipStatus.isInTrial && (
                      <div className={'membershipRemaining'}>{t('MembershipRemaining', { daysRemaining: membershipStatus.daysRemaining })}</div>
                    )}
                  </div>
                  <div className={'membershipOption' + isPremiumForFreeClassname}>
                    {shouldShowPlanOptions && (
                      <Button
                        data-test-id='manage-plan-button'
                        onClick={this.handleOpenPlanListDialog}
                        variant='outlined'
                        size='small'
                        sx={{
                          backgroundColor: 'common.white',
                          borderRadius: '50px',
                          textTransform: 'none'
                        }}
                        label={t('ManagePlan')}
                      />
                    )}
                  </div>
                </div>
              </div>
              : null
            }



            {
              isOwner &&
              <div>
                <div className='addOns'>
                  <div className='formHeading'>{t('AddOns.Title')}</div>
                  <div className='loyalty'>
                    <div className='body'>
                      <span>{t('AddOns.Loyalty.Title')}</span>
                      <p>{`${t('AddOns.Loyalty.Pricing')} ${loyaltyEndDate && isLoyaltyAddOnActive ? '| ' + t('AddOns.Loyalty.FreeTrialExpiration') + loyaltyEndDate : ''}`}</p>
                    </div>
                    <div className='status'>
                      {
                        isLoyaltyAddOnActive && isPremiumPlusAccount && !isNonPA ?
                          <div className='activated'>
                            <div className='activatedButton'>
                              {checkIcon}
                              {t('AddOns.Loyalty.Activated')}
                            </div>
                            <div className='activatedControls'>
                              <IconButton
                                onClick={this.handleOpenLoyaltyAddOnMenu}
                              >
                                <MoreVertSharpIcon/>
                              </IconButton>
                              <Menu
                                anchorEl={this.state.loyaltyAddOnMenuAnchorEl}
                                open={!!this.state.loyaltyAddOnMenuAnchorEl}
                                onClose={this.handleCloseLoyaltyAddOnMenu}
                              >
                                <MenuItem
                                  primaryText={t('AddOns.Loyalty.GoToLoyalty')}
                                  leftIcon={goToLoyaltyIcon}
                                  style={loyaltyAddOnMenuItemStyle}
                                  onClick={this.goToLoyalty}
                                />
                                {!readOnly && (
                                  <MenuItem
                                    primaryText={t('AddOns.Loyalty.Deactivate')}
                                    leftIcon={deactivateIcon}
                                    style={loyaltyAddOnMenuItemStyle}
                                    onClick={this.openLoyaltyAddOnActivateOrDeactivateDialog.bind(this, false)}
                                  />
                                )}
                              </Menu>
                            </div>
                          </div>
                          :
                          <div className='activateContainer'>
                            {
                              isNonPA &&
                              <Tooltip
                                placement='left'
                                title={t(messages.appSettings.cantDoEdge)}
                              >
                                <p>{infoIcon}</p>
                              </Tooltip>
                            }
                            <Button
                              data-test-id='activate-loyalty-button'
                              onClick={this.openLoyaltyAddOnActivateOrDeactivateDialog.bind(this, true)}
                              variant='outlined'
                              size='small'
                              disabled={!canChangeLoyalty}
                              sx={{
                                backgroundColor: 'common.white',
                                borderRadius: '50px',
                                textTransform: 'none'
                              }}
                              label={t('AddOns.Loyalty.Activate')}
                            />
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
            {profileSection}
            {merchantProcessingAgreementSection}
            {merchantApplicationSection}
          </div>
          {loading}
          {changeLanguageDialog}
          {changePasswordDialog}
          {changeEmailDialog}
          {manageRememberedDevicesSettingDialog}
          {emailCommunicationDialog}
          {membershipDialog}
          {accessDialog}
          {upgradedDialog}
          {changeConfirmationDialog}
          {planListDialog}
          {loyaltyAddOnActivateDialog}
          {loyaltyAddOnDeactivateDialog}

          <TwoFactorTools
            type={this.state.twoFactorType}
            onProcessing={this.on2faProcessing}
            onSetup={this.on2faSetup}
            onVerified={this.on2faVerified}
            onClose={this.on2faClose}
            {...this.props}
          />

          {this.props.userExperience.toggleMerchantVerifyByCSDialog &&
            <MerchantVerifyByCSDialog
              acceptAction={this.getAuthToUpdateInfo}
              closeAction={this.closeMerchantVerifyByCSDialog}
              t={t}
            />
          }
        </Box>
      </Page>
    );
  }

}

Profile.defaultProps = {
  isFetching: true
};
