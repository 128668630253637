/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import numeral from 'numeral';
import NoData from './NoData';
import { AutoSizer, Column, Table } from 'react-virtualized';

import FormatTextUtil from './util/FormatTextUtil';
import TransactionsUtil from './util/TransactionsUtil';

import moment from 'moment';
import 'moment-timezone';
import TableHeader from './shared/TableHeader';
import MobileScrollNote from './shared/MobileScrollNote';
import Checkbox from '@mui/material/Checkbox';
import TextField from './shared/TextField';
import withStyles from '@mui/styles/withStyles';
import {primaryColorCheckBox} from '../jss/inlineStyles';
import { highTransactionLimitAmount } from '../constants/applicationConstants';
import {Box, Typography} from '@mui/material';
import {TransactionsAmountCellStyles, invertedScrollBar} from '../jss/inlineStyles';

export const CustomCheckbox = withStyles(primaryColorCheckBox)
((props) => <Checkbox color='default' {...props} />);

export default class MasterList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      listHeight: 710,
      listRowHeight: 71,
      overscanRowCount: 10
    };

    this.dateCellRenderer = this.dateCellRenderer.bind(this);
    this.transactionTypeCellRenderer = this.transactionTypeCellRenderer.bind(this);
    this.soldByCellRenderer = this.soldByCellRenderer.bind(this);
    this.customerCellRenderer = this.customerCellRenderer.bind(this);
    this.amountCellRenderer = this.amountCellRenderer.bind(this);
    this.amountHeaderRenderer = this.amountHeaderRenderer.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.headerRenderer = this.headerRenderer.bind(this);
    this.statusCellRenderer = this.statusCellRenderer.bind(this);
    this.rowSelectorHeaderRenderer = this.rowSelectorHeaderRenderer.bind(this);
    this.rowSelectorCellRenderer = this.rowSelectorCellRenderer.bind(this);
    this.tipCellRenderer = this.tipCellRenderer.bind(this);
    this.handleRowSelectorOnCheckSelectAll = this.handleRowSelectorOnCheckSelectAll.bind(this);
    this.handleTipOnChange = this.handleTipOnChange.bind(this);
    this.handleRowSelectorOnCheck = this.handleRowSelectorOnCheck.bind(this);
  }

  rowClicked(index, thisTransaction, props) {
    props.onItemClick(index);
  }

  dateCellRenderer({ rowData }) {
    return moment(rowData.datetime).format('MM/DD/YYYY') + ' ' + moment(rowData.datetime).format('h:mm a');
  }

  transactionTypeCellRenderer({ rowData }) {

    const { showDeclinedTransactions, t } = this.props;

    const transactionType = TransactionsUtil.getTransactionType(rowData, showDeclinedTransactions);
    return t(FormatTextUtil.formatTextToKey(transactionType));

  }

  soldByCellRenderer({ rowData }) {
    return rowData.sold_by_name ? rowData.sold_by_name : '';
  }

  customerCellRenderer({ rowData }) {
    const firstName = rowData.customer_firstname ? rowData.customer_firstname : rowData.first_name ? rowData.first_name : '';
    const lastName = rowData.customer_lastname ? rowData.customer_lastname : rowData.last_name ? rowData.last_name : '';
    return (firstName && lastName) ? (firstName + ' ' + lastName) : (firstName + lastName);
  }

  amountCellRenderer({ rowData }) {
    const formatAmount = numeral(rowData.amount).format('$0,0.00');
    return (
      <Typography
          component='span'
          sx={TransactionsAmountCellStyles}
      >
        {formatAmount}
      </Typography>
    );
  }

  amountHeaderRenderer({ dataKey, label }) {
    return (
      <TableHeader
          {...this.props}
          attribute={dataKey}
          clickable
          headerId={`transactions_${dataKey}`}
          name={this.props.t(label)}
          styles={{
            borderBottom: 0,
            textAlign: 'right',
            marginRight: '40px'
          }}
      />
    );
  }

  headerRenderer({ dataKey, label }) {
    return (
      <TableHeader
        {...this.props}
        name={this.props.t(label)}
        clickable={true}
        attribute={dataKey}
        styles={{ borderBottom: 0 }}
        headerId={`transactions_${dataKey}`}
      />
    )
  }

  handleRowSelectorOnCheckSelectAll(ev) {
    const allIds = this.props.tableData.map((row)=> row.id);
    this.props.setRowSelectionArray(ev.target.checked ? allIds : []);
  }

  handleRowSelectorOnCheck(row) {
    return () => {
      const rowId = this.props.tableData[row].id;
      const newRowSelection = this.props.rowSelectionArray.includes(rowId) ?
        [...this.props.rowSelectionArray.filter((id) => rowId !== id)] :
        [...this.props.rowSelectionArray, rowId];

      this.props.setRowSelectionArray(newRowSelection);
    };
  }

  rowSelectorHeaderRenderer() {
    const checked = this.props.tableData?.length === this.props.rowSelectionArray?.length;
    return (
      <div>
        <CustomCheckbox
          checked={checked}
          onChange={this.handleRowSelectorOnCheckSelectAll}
        />
      </div>
    )
  }

  statusCellRenderer({cellData}) {
    return (
      <span className={'statusCell'}>{cellData && cellData.length > 0 && cellData.toLowerCase() }</span>
    );
  }

  rowSelectorCellRenderer({rowData, rowIndex}) {
    return (
      <div>
        <CustomCheckbox
          checked={this.props.rowSelectionArray.includes(rowData.id)}
          onChange={this.handleRowSelectorOnCheck(rowIndex)}
        />
      </div>
    );
  }

  handleTipOnChange(id, totalAmount) {
    return (tipEvent) => {

      const tipValue = FormatTextUtil.formatCurrencyWithMaxDigit(tipEvent.target.value);

      const tipLimitExceed = (
        numeral(totalAmount).value() + numeral(tipValue).value()
      ) > highTransactionLimitAmount;

      this.props.setOpenTransactionTipChanges({
        id: id,
        value: tipValue,
        isInvalid: tipLimitExceed
      });

    }
  }

  tipCellRenderer({cellData, rowData}) {

    const openTransactionTip = this.props.openTransactionTipChanges?.find(openTransaction => openTransaction.id === rowData.id);
    const tipValue = openTransactionTip?.value || (cellData && `$${parseFloat(cellData).toFixed(2)}`) || '';

    const showTipEntry = (!['Credit Refund', 'Void'].includes(rowData.type)) && !rowData.void_uniq_id;
    const editTip = this.props.enableTipChanges && (!cellData || parseFloat(cellData) === 0) && showTipEntry && rowData.tip_allowed;

    const isTipInvalid = openTransactionTip?.isInvalid;

    const tipMeta = { error: true, showInputErrorOnly: true };

    return !editTip ? (
        <div className='noEditTip'>{numeral(cellData).format('$0,0.00')}</div>
      ) : (
      <div className={'tipCell'}>
        <TextField
          name={`tipCell-${rowData.id}`}
          input={{
            value: FormatTextUtil.formatCurrencyWithMaxDigit(tipValue),
            onChange: this.handleTipOnChange(rowData.id, rowData.amount),
          }}
          hintText='$0.00'
          maxLength='15'
          meta={{ ...(isTipInvalid && tipMeta) }}
        />
      </div>
    );
  }

  render() {

    const {
      tableData,
      noDataMessage,
      titleComponent,
      paginationComponent,
      isLargeProcessor,
      noDataComponent,
      specialColumns,
      t,
    } = this.props;
    const {listRowHeight, overscanRowCount} = this.state;

    const tableHeaderComponent = titleComponent ? titleComponent : null;
    const paginationHeaderComponent = paginationComponent ? paginationComponent : null;
    const mobileScrollNote = <MobileScrollNote t={t} />
    const autoSizer = (
      <AutoSizer className='infiniteListAutoSizer' disableHeight>
        {({ width }) => (
          <Table
            className={'infiniteListTable'}
            gridStyle={{ outline: 'none' }}
            onRowClick={({ index }) => !this.props.enableRowSelection && this.rowClicked(index, tableData[index], this.props)}
            width={width}
            headerHeight={58}
            height={tableData.length * (listRowHeight + 1)}
            overscanRowCount={overscanRowCount}
            rowHeight={listRowHeight}
            rowCount={tableData.length}
            rowGetter={({ index }) => tableData[index]}>
            {specialColumns?.show?.includes('rowSelector') ?
              <Column
                dataKey='rowSelector'
                headerRenderer={this.rowSelectorHeaderRenderer}
                width={width/2}
                cellRenderer={this.rowSelectorCellRenderer}
              /> : null
            }
            {specialColumns?.show?.includes('status_message') ?
              <Column
                label={t('Status')}
                dataKey='status_message'
                headerRenderer={this.headerRenderer}
                width={width}
                cellRenderer={this.statusCellRenderer}
              />
            : <Column
              label={t('Invoice')}
              dataKey='invoice'
              headerRenderer={this.headerRenderer}
              width={width} />
            }
            <Column
              label={t('Date')}
              dataKey='datetime'
              width={width}
              headerRenderer={this.headerRenderer}
              cellRenderer={this.dateCellRenderer} />
            {!specialColumns ||
              specialColumns?.hide && !specialColumns.hide.includes('type') ||
              specialColumns?.show && specialColumns.show.includes('type')
              ?
                <Column
                label={t('TransactionType')}
                dataKey='type'
                width={width}
                headerRenderer={this.headerRenderer}
                cellRenderer={this.transactionTypeCellRenderer} />
              : null}
            {!isLargeProcessor && <Column
              label={t('SoldBy')}
              dataKey='sold_by'
              width={width}
              headerRenderer={this.headerRenderer}
              cellRenderer={this.soldByCellRenderer} />}
            {!isLargeProcessor && <Column
              label={t('Customer')}
              dataKey='last_name'
              width={width}
              headerRenderer={this.headerRenderer}
              cellRenderer={this.customerCellRenderer} />}
            <Column
              label={t('Amount')}
              dataKey='amount'
              width={width}
              headerRenderer={this.amountHeaderRenderer}
              cellRenderer={this.amountCellRenderer} />
            {specialColumns?.show?.includes('tip_amount') ?
              <Column
                label={t('Tip')}
                dataKey='tip_amount'
                headerRenderer={this.headerRenderer}
                width={width}
                cellRenderer={this.tipCellRenderer}
              />
              : null
            }
          </Table>
        )}
      </AutoSizer>
    );

    if (tableData && tableData.length) {

      return (
        <div id='masterList' className='masterListContainer infiniteList'>

          {tableHeaderComponent}

          {paginationHeaderComponent}

          {this.props.hasMobileScrollNote && mobileScrollNote}

          <Box id='masterList' className='masterInfinitListContainer infiniteList' sx={invertedScrollBar}>

            {autoSizer}

          </Box>
        </div>
      )

    } else {

      return (
        <div id='masterList' className='masterListContainer infiniteList'>

          {tableHeaderComponent}
          {noDataComponent ? noDataComponent : (<NoData text={noDataMessage ? noDataMessage : 'No records found'} />)}

        </div>
      )
    }
  }
}
