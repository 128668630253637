import {useEffect} from 'react';
import {connect} from 'react-redux';
import {useIdleTimer} from 'react-idle-timer';
import {checkTokenExpiration, logoutUser, setIsUserIdle } from '../actions/authActions';
import {
  IDLE_USE_CROSS_TAB,
  IDLE_CROSS_TAB_SYNC_INTERVAL,
  IDLE_CROSS_TAB_NAME,
  IDLE_EVENTS_THROTTLE_INTERVAL,
  IDLE_DEBOUNCE_INTERVAL,
  IDLE_USER_TIMEOUT
} from '../constants/idle';
import LabelUtil from './util/LabelUtil';
import {toggleRefreshTokenDialog} from '../actions/userExperienceActions';

export const handleStorageEvent = (event, {
  dispatchCheckTokenExpiration,
  dispatchLogoutUser,
  dispatchToggleRefreshTokenDialog,
  openRefreshTokenDialog
}) => {
  const isTokenKey = event.key === 'pa-token';
  const renewedTokenDetected = !!event.newValue && !!event.oldValue;
  const removedTokenDetected = !event.newValue && !!event.oldValue

  if (isTokenKey && openRefreshTokenDialog) {
    if (renewedTokenDetected) {
      dispatchToggleRefreshTokenDialog(false);
      dispatchCheckTokenExpiration();
    }
    if (removedTokenDetected) {
      dispatchLogoutUser();
    }
  }
};

export const IdleDetectorComponent = (props) => {
  const {
    dispatchSetIsUserIdle,
    dispatchToggleRefreshTokenDialog,
    dispatchCheckTokenExpiration,
    dispatchLogoutUser,
    openRefreshTokenDialog
  } = props;

  useEffect(() => {
    const boundHandleStorageEvent = (event) =>
      handleStorageEvent(event, {
      dispatchCheckTokenExpiration,
      dispatchToggleRefreshTokenDialog,
      dispatchLogoutUser,
      openRefreshTokenDialog
    });

    window.addEventListener('storage', boundHandleStorageEvent);
    return () => {
      window.removeEventListener('storage', boundHandleStorageEvent);
    };
  }, [
    dispatchCheckTokenExpiration,
    dispatchToggleRefreshTokenDialog,
    dispatchLogoutUser,
    openRefreshTokenDialog
  ]);

  useIdleTimer({
    // Cross tab
    crossTab: IDLE_USE_CROSS_TAB,
    name: `${LabelUtil.getLabel().transactionSource}-${IDLE_CROSS_TAB_NAME}`,
    syncTimers: IDLE_CROSS_TAB_SYNC_INTERVAL,

    // Debounce intervals
    debounce: IDLE_DEBOUNCE_INTERVAL,
    eventsThrottle: IDLE_EVENTS_THROTTLE_INTERVAL,

    // Idle config
    timeout: IDLE_USER_TIMEOUT,
    onPresenceChange: dispatchSetIsUserIdle
  });

  return null;
};

export const mapStateToProps =
  ({ userExperience: { openRefreshTokenDialog } }) => ({ openRefreshTokenDialog });

export const mapDispatchToProps = (dispatch) => {
  const dispatchToggleRefreshTokenDialog = (value) => dispatch(toggleRefreshTokenDialog(value));

  return {
    dispatchToggleRefreshTokenDialog,
    dispatchCheckTokenExpiration: () => dispatch(checkTokenExpiration()),
    dispatchLogoutUser: () => dispatch(logoutUser()),
    dispatchSetIsUserIdle: (presence) => {
      const isUserIdle = presence.type === 'idle';
      dispatch(setIsUserIdle(isUserIdle));
      if (isUserIdle) {
        dispatchToggleRefreshTokenDialog(true);
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IdleDetectorComponent);
