/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import emailValidator from 'email-validator';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import errorMessages from '../../constants/messages';
import paymentLinks from '../../constants/paymentLinks';
import { highTransactionLimitAmount } from '../../constants/applicationConstants';
import FormatTextUtil from './FormatTextUtil';
import DateUtils from './DateUtil';
import {
  RecurringEnd,
  PaymentFrequency,
  CUSTOM_DATE_VALUE,
  CUSTOM_DUE_DATE_VALUE,
  CUSTOM_END_DATE_VALUE,
  CUSTOM_SEND_DATE_VALUE,
  PaymentMethod
} from './InvoiceUtil';
import CommonUtil from './CommonUtil';
import { isNorth } from './WhiteLabelUtil';

const Validator = {

  messages: errorMessages.validator,

  // EXP Validation REGEX (PA-25737)
  ccRegex: function (value) {
    const regex = new RegExp('^([0-9,*]){4,19}$');
    return regex.test(value);
  },

  cvvRegex: function (value) {
    const regex = new RegExp('^([0-9]){3,4}$');
    return regex.test(value);
  },

  expDateRegex: function (value) {
      const regex = new RegExp('^(0[1-9]|1[0-2])/\\d{2}$');
      return regex.test(value);
  },

  addressRegex: function (value) {
    const regex = new RegExp('^([a-zA-Z0-9\\/\\.\\-_,#\'&+\\s]){0,30}$');
    return regex.test(value);
  },

  zipRegex: function (value) {
    const regex = new RegExp('^([A-Za-z0-9\\-\\s]){0,10}$');
    return regex.test(value);
  },

  hasNoValue: function (value) {
    return (value && typeof value == 'string' && value.length > 0) ? !value.trim() : !value;
  },

  areEqual: function (value1, value2) {
    return value1 === value2;
  },

  hasSpace: function (value) {
    if (value && value.length > 0) {
      const trimmedValue = value.trim();
      return trimmedValue && trimmedValue.indexOf(' ') > 0;
    } else {
      return false;
    }

  },

  isAlphaNumeric: function (value) {
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(value);
  },

  isPhoneNumber: function (phoneNumber) {

    const phoneNumberRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

    return phoneNumberRegEx.test(phoneNumber);

  },

  isDate: (value) => {
    const dateRegEx = /^(0[1-9]|1[0-2])[\/|-](0[1-9]|[12][0-9]|3[01])[\/|-]([1-2][0-9]{3})$/;
    return dateRegEx.test(value);
  },

  isOver18: (dob, format = 'MM/DD/YYYY') => {
    return moment().diff(moment(dob, format), 'years') >= 18;
  },

  validPassword: (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*[~!@#$%^&*()-=+]))[A-Za-z\d~!@#$%^&*()-=+]{8,}$/;
    return passwordRegex.test(password);
  },

  validAccountNumber: (number) => {
    return number?.length > 3 && number?.length < 18 && number?.length !== 16 && !isNaN(number);
  },

  validRoutingNumberLength: (number) => {
    return number?.length === 9 && !isNaN(number);
  },

  validFullSSNNumber: number => number.split(' ').join('').length === 9 && !isNaN(parseInt(number)),

  validSSNNumber: number => (Validator.validFullSSNNumber(number) || number.split(' ').join('').length === 4) && !isNaN(parseInt(number)),

  validRoutingNumber: (value) => {
    const regEx = /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/;
    return regEx.test(value);
  },

  validRoutingNumberChecksum: (value) => {
    const weights = [3, 7, 1];
    let sum = 0;
    for (let i = 0; i < value.length; i++) {
      const number = parseInt(value[i]);
      if (i === 0 && (number < 0 || number > 3)) {
        return false;
      }
      sum += number * weights[i % 3];
    }
    return sum % 10 === 0;
  },

  validateAccountNumber: (number) => {
    return number.charAt('0') === '0' && number.charAt('1') === '0';
  },

  valueExistsInArray: (value, array) => {
    return array.some(element => element === value);
  },

  validDisputesNotes: notes => {
    const regex = /^([a-zA-Z0-9.\-\s,']){1,1000}$/;
    return regex.test(notes);
  },

  validDisputesDocumentDescription: description => {
    const regex = /^([a-zA-Z0-9.\-\s,']){1,50}$/;
    return regex.test(description);
  },

  validTaxId: value => {
    const fedTaxIdRegex = /^\d{9}$/;
    return fedTaxIdRegex.test(value);
  },

  isConsecutiveSSN: value => {
    const digits = value.split('').map(Number);
    const isAscending = digits.every((digit, index) => index === 0 || digit === digits[index - 1] + 1);
    const isDescending = digits.every((digit, index) => index === 0 || digit === digits[index - 1] - 1);
    return isAscending || isDescending;
  },

  isRepetitiveSSN: value => {
    return /^(\d)\1{8}$/.test(value);
  },

  validWebDomain: value => {
    const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    return regex.test(value);
  },

  validHttps: value => {
    const regex = /^(https:\/\/)/;
    return regex.test(value);
  },
  validateAch: function (values) {
    const that = this;
    const errors = {};
    const requiredFields = ['accountType', 'accountName', 'achTerms'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.accountName && values.accountName?.trim()?.split(' ')?.length < 2) {
      errors.accountName = that.messages.accountNameLengthInvalid
    }

    if (!this.validAccountNumber(values.accountNumber)) {
      errors.accountNumber = that.messages.bankingAccountNumberInvalid;
    }

    if (!this.validRoutingNumberLength(values.routingNumber) || !this.validRoutingNumber(values.routingNumber)) {
      errors.routingNumber = that.messages.routingNumberInvalid;
    }

    return errors;
  },

  validateCreditCard: function (values) {
    const errors = {};

    if (this.hasNoValue(values['cdigits'])) {
      errors.cdigits = values['paymentType'] === 'debtRepayment' ? this.messages.debitCardRequired : this.messages.creditCardRequired;
    } else if (values['paymentType'] === 'debtRepayment' && FormatTextUtil.parseCardType(values.cdigits) !== 'visa' && FormatTextUtil.parseCardType(values.cdigits) !== 'mastercard') {
      errors.cdigits = this.messages.creditCardInvalidForDebtRepayment;
    } else if (!this.ccRegex(values['cdigits'].replace(/-/g, ''))) {
      errors.cdigits = this.messages.creditCardInvalid;
    }

    if (this.hasNoValue(values['edate'])) {
      errors.edate = this.messages.expirationDateRequired;
    } else {
      if(!this.expDateRegex(values.edate)) {
        errors.edate = this.messages.expirationDateInvalid;
      }
    }

    if (this.hasNoValue(values['cvv'])) {
      errors.cvv = this.messages.securityCodeRequired;
    } else if (!this.cvvRegex(values['cvv'])) {
      errors.cvv = this.messages.securityCodeInvalid;
    }

    if (!this.addressRegex(values['street_number'])) {
      errors.street_number = this.messages.billingStreetNumberInvalid;
    }

    if (values['zip'] && values['zip'].length !== 5) {
      errors.zip = this.messages.zipShouldBeFiveDigits;
    } else if (!this.zipRegex(values['zip'])) {
      errors.zip = this.messages.zipInvalid;
    }

    if (values['zipPlus4'] && values['zipPlus4'].length !== 4) {
      errors.zipPlus4 = this.messages.zipPlusFourShouldBeFourDigits;
    }

    if (values['zipPlus4'] && values['zipPlus4'].length === 4 && this.hasNoValue(values['zip'])) {
      errors.zip = this.messages.zipRequiredWithZipPlusFour;
    }

    return errors;
  },

  validateUpdatePasswordForm: function (values) {

    let errors = {};
    const requiredFields = ['currentPassword', 'newPassword', 'confirmNewPassword', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatch;
    }

    return errors;
  },

  validateUpdateEmailForm: function (values) {

    let errors = {};
    const requiredFields = ['newUsername', 'confirmNewUsername', 'currentPassword', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (!emailValidator.validate(values?.newUsername?.trim())) {
      errors.newUsername = that.messages.invalidEmail;
    }

    if (!this.areEqual(values.newUsername, values.confirmNewUsername)) {
      errors.confirmNewUsername = that.messages.emailsDoNotMatch;
    }

    return errors;
  },

  validateForgetPassword: function (values) {

    let errors = {};
    const requiredFields = ['emailAddress', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    let emailAddressToValidate = values.emailAddress ? values.emailAddress.trim() : values.emailAddress;

    if (!emailValidator.validate(emailAddressToValidate)) {
      errors.emailAddress = that.messages.invalidEmail;
    }

    return errors;

  },

  validateResetPassword: function (values, isDemoAccount) {

    let errors = {};
    const requiredFields = ['newPassword', 'confirmNewPassword', 'twofactortoken', 'captcharesponse'];
    if (isDemoAccount) {
      requiredFields.push('phoneNumber');
    } else {
      requiredFields.push('accountNumber', 'ssn');
    }
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatch;
    }

    if (isDemoAccount && values.phoneNumber && !this.isPhoneNumber(values.phoneNumber)) {
      errors.phoneNumber = this.messages.invalidPhoneNumber;
    }

    return errors;
  },

  validateClientServiceForm: function (values) {

    let errors = {};
    const requiredFields = ['username', 'password', 'mid', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });


    return errors;
  },

  validateLogin: function (values) {
    let errors = {};
    const requiredFields = ['username', 'password'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    //Allow either valid emails or other non-email strings if it doesn't have an @
    if (values.username && values.username.includes('@') && !emailValidator.validate(values.username.trim())) {
      errors.username = that.messages.invalidEmail;
    }

    return errors;
  },

  validateRegisterMbpUser: function (values) {
    // first and last name not required
    let errors = {};

    let that = this;

    let emailAddressToValidate = values.emailAddress ? values.emailAddress.trim() : values.emailAddress;

    if (this.hasNoValue(values.captcharesponse)) {
      errors.captcharesponse = that.messages.requiredText;
    }

    if (this.hasNoValue(values.accountNumber)) {
      errors.accountNumber = that.messages.accountNumber;
    } else if (this.validateAccountNumber(values.accountNumber)) {
      errors.accountNumber = that.messages.accountNumberInvalid;
    }

    if (!emailValidator.validate(emailAddressToValidate)) {
      errors.emailAddress = that.messages.invalidEmail;
    }

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.validPassword(values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatchNew;
    }

    return errors;
  },

  validateRegisterMbpUserAuthentication: function (values) {

    let errors = {};
    const requiredFields = ['password', 'emailAddress', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    let emailAddressToValidate = values.emailAddress ? values.emailAddress.trim() : values.emailAddress;

    if (emailAddressToValidate && !emailValidator.validate(emailAddressToValidate)) {
      errors.emailAddress = that.messages.invalidEmail;
    }

    return errors;

  },

  validateMbpMigrateUserForm: function (values) {
    let errors = {};

    let that = this;

    let emailAddressToValidate = values.emailAddress ? values.emailAddress.trim() : values.emailAddress;

    if (this.hasNoValue(values.captcharesponse)) {
      errors.captcharesponse = that.messages.requiredText;
    }

    if (this.hasNoValue(values.username)) {
      errors.username = that.messages.requiredText;
    }

    if (this.hasNoValue(values.password)) {
      errors.password = that.messages.requiredText;
    }

    if (!emailValidator.validate(emailAddressToValidate)) {
      errors.emailAddress = that.messages.invalidEmail;
    }

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatch;
    }

    return errors;
  },

  validateAddAccountForm: function (values) {
    let errors = {};

    if (values.mid || null) {
      values.mid = values.mid.replace(/ /g, ''); // remove spaces from MID
    }
    if (this.hasNoValue(values.mid)) {
      errors.mid = this.messages.requiredText;
    }

    if (_.find(values.userMerchantAccounts, (merchantAccount) => merchantAccount.mid === values.mid)) {
      errors.mid = this.messages.alreadyAttachedToAccount;
    }

    return errors;
  },

  validateMbpUserNewPasswordForm: function (values) {

    let errors = {};
    const requiredFields = ['newPassword', 'confirmNewPassword', 'token', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if ((!values.ssn || values && values.ssn.length < 4) && (!values.taxId || values && values.taxId.length < 4)) {
      errors['ssn'] = that.messages.ssnTaxId;
      errors['taxId'] = that.messages.ssnTaxId;
    } else {
      errors['ssn'] = null;
      errors['taxId'] = null;
    }

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatch;
    }

    return errors;
  },

  validateSetPassword: function (values) {

    let errors = {};
    const requiredFields = ['newPassword', 'confirmNewPassword', 'captcharesponse'];
    let that = this;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (!this.validPassword(values.newPassword)) {
      errors.newPassword = that.messages.invalidPassword;
    }

    if (!this.areEqual(values.newPassword, values.confirmNewPassword)) {
      errors.confirmNewPassword = that.messages.passwordsDoNotMatch;
    }

    return errors;
  },

  validateLoyaltyVpc: function (values) {
    let errors = {};
    const requiredFields = [
      'pointsToEarnReward',
      'rewardAmountType',
      'rewardAmount',
      'minimumSpend'
    ];

    requiredFields.forEach((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = this.messages.requiredText;
      }
    });

    if (values['rewardAmountType'] === 'dollar') {
      if (Number(values['rewardAmount']) > 1000000) {
        errors['rewardAmount'] = this.messages.rewardDollarAmountLimit;
      }
    }

    if (values['rewardAmountType'] === 'percent') {
      if (Number(values['rewardAmount']) > 100) {
        errors['rewardAmount'] = this.messages.rewardPercentAmountLimit;
      }
    }

    if (values.enabled && !values.tcAccepted) {
      errors['tcAccepted'] = this.messages.requiredText;
    }

    return errors;
  },

  validateLoyaltyVpcApplyBox: function (values) {
    let errors = {};
    if (values['rewardCode'] && !this.isAlphaNumeric(values['rewardCode'])) {
      errors['rewardCode'] = this.messages.alphaNumericRequired;
    }

    return errors;
  },

  validateItemDetails: function (values) {

    const errors = {item: {details: {}}};
    let that = this;

    if (!_.isEmpty(values)) {

      const requiredFields = ['name'];

      _.map(requiredFields, (field) => {
        if (this.hasNoValue(values.item[field])) {
          errors.item[field] = that.messages.requiredText;
        }
      });

      if (values.item.details && values.item.details.prices) {

        if (values.item.details.prices.filter(price => price).length === 0) {
          errors.item.details.prices = {_error: that.messages.invalidOneOrMorePrice};
        }

        const barCodes = values.item.details.prices.map(price => price?.barcode);

        _.forEach(values.item.details.prices, (price, index) => {
          if (price) {

            if (!errors.item.details.prices) {
              errors.item.details.prices = [];
            }

            const quantityRequired = values.item.is_trackable && !price?.quantity;
            const priceIsInvalid = numeral(price.price).value() <= 0;

            if (!price.name || quantityRequired || priceIsInvalid) {
              errors.item.details.prices[index] = {};
            }

            if (!price.name) {
              errors.item.details.prices[index].name = that.messages.invalidName;
            }

            if (quantityRequired) {
              errors.item.details.prices[index].quantity = that.messages.invalidQuantity;
            }

            if (priceIsInvalid) {
              errors.item.details.prices[index].price = that.messages.invalidPrice;
            }

            const currentBarCodes = barCodes.filter(barCode => barCode === price.barcode);

            if (price.barcode && currentBarCodes?.length >= 2) {
              errors.item.details.prices[index] = !_.isEmpty(errors.item.details.prices[index]) ? { ...errors.item.details.prices[index], barcode: that.messages.invalidBarCode } : { barcode: that.messages.invalidBarCode };
            }

          }
        });

      } else {
        errors.item.details.prices = {_error: that.messages.invalidName};
      }

    }

    return errors;
  },

  validateCategories: function (values, props) {

    let errors = {};
    let that = this;

    if (!_.isEmpty(values)) {

      const requiredFields = ['name'];

      _.map(requiredFields, (field) => {
        if (this.hasNoValue(values[field])) {
          errors[field] = that.messages.requiredText;
        }
      });

      if (props.categories) {
        _.map(props.categories, (category) => {
          if (!(props.initialValues.id && props.initialValues.id == category.id) && category.name.toLowerCase() == values.name.toLowerCase()) {
            errors['name'] = that.messages.duplicateCategory
          }
        });
      }
    }


    return errors;
  },

  validateDiscounts: function (values, props) {

    let errors = {};
    let that = this;

    if (!_.isEmpty(values)) {

      const requiredFields = ['name'];

      _.map(requiredFields, (field) => {
        if (this.hasNoValue(values[field])) {
          errors[field] = that.messages.requiredText;
        }
      });

      let isPercentage = values.type === 'percent';
      let discountValue = isPercentage ? values.percentage : values.amount;

      if (discountValue) {
        let cleanAmount = discountValue.toString().replace(/[^\d]/g, '');
        let hasValidDiscount = parseFloat(cleanAmount) != 0;

        if (values.amount && !isPercentage && !hasValidDiscount) {
          errors.amount = that.messages.requiredText;
        } else if (values.percentage && isPercentage && !hasValidDiscount) {
          errors.percentage = that.messages.requiredText;
        }
      }

      if (props.discounts) {
        _.map(props.discounts, (discount) => {
          if (!(props.initialValues.id && props.initialValues.id == discount.id) && discount.name.toLowerCase() == values.name.toLowerCase()) {
            errors['name'] = that.messages.duplicateDiscount
          }
        });
      }
    }


    return errors;
  },

  validateModifiers: function (values, props) {

    let errors = {modifierSet: {}};
    let that = this;

    const currentModifierSet = props && props.initialValues && props.initialValues.modifierSet ? props.initialValues.modifierSet : null;

    if (!_.isEmpty(values)) {

      const requiredFields = ['name'];

      if (!values.modifierSet) {

        errors.modifierSet['name'] = that.messages.requiredText;
        errors.modifierSet['modifiers'] = {_error: that.messages.missingModifiersError};

      } else {
        requiredFields.map((field) => {
          if (this.hasNoValue(values.modifierSet[field])) {
            errors.modifierSet[field] = that.messages.requiredText;
          }
        });

        if (values.modifierSet.modifiers) {

          _.map(values.modifierSet.modifiers, (modifier, index) => {
            if (modifier && !modifier.name) {
              if (!errors.modifierSet.modifiers) {
                errors.modifierSet.modifiers = [];
              }
              errors.modifierSet.modifiers[index] = {name: that.messages.invalidName};
            }
          });

          if (values.modifierSet.modifiers.filter(modifier => modifier).length == 0) {
            errors.modifierSet['modifiers'] = {_error: that.messages.missingModifiersError};
          }

        } else {
          errors.modifierSet['modifiers'] = {_error: that.messages.missingModifiersError};
        }
      }

      if (props.modifierSets) {

        _.map(props.modifierSets, (modifierSet) => {
          if (!(currentModifierSet.id && currentModifierSet.id == modifierSet.id) && modifierSet.name.toLowerCase() == values.modifierSet.name.toLowerCase()) {
            errors.modifierSet['name'] = that.messages.duplicateModifierSet
          }
        });
      }
    }


    return errors;
  },

  validateCustomerDetailForm: function (values) {
    let errors = {};
    const { address } = values;

    const hasEmails = values['email_addresses']?.some(e => !this.hasNoValue(e));
    if (this.hasNoValue(values['phone_number']) && !hasEmails) {
      errors['email_addresses'] = [this.messages.emailOrPhoneRequired];
      errors['phone_number'] = this.messages.emailOrPhoneRequired;
    }

    if (this.hasNoValue(values.first_name) && this.hasNoValue(values.last_name)) {
      errors.first_name = this.messages.firstOrLastNameRequired;
      errors.last_name = this.messages.firstOrLastNameRequired;
    }

    if (values.phone_number && !this.isPhoneNumber(values.phone_number)) {
      errors.phone_number = this.messages.invalidPhoneNumber;
    }

    if (values.email_addresses) {
      _.map(values.email_addresses, (email, index) => {
        if (email && !emailValidator.validate(email)) {
          if (!errors.email_addresses) {
            errors.email_addresses = [];
          }
          errors.email_addresses[index] = this.messages.invalidEmail;
        }
      });
    }

    if (
      !this.hasNoValue(address?.street_address_1) ||
      !this.hasNoValue(address?.street_address_2) ||
      !this.hasNoValue(address?.city) ||
      !this.hasNoValue(address?.state) ||
      !this.hasNoValue(address?.zip)
    ) {

      if (this.hasNoValue(address.street_address_1)) {
        errors.address = errors.address || {};
        errors.address.street_address_1 = this.messages.requiredStreet1;
      }
      if (this.hasNoValue(address.city)) {
        errors.address = errors.address || {};
        errors.address.city = this.messages.requiredCity;
      }
      if (this.hasNoValue(address.state)) {
        errors.address = errors.address || {};
        errors.address.state = this.messages.requiredState;
      }
      if (this.hasNoValue(address.zip)) {
        errors.address = errors.address || {};
        errors.address.zip = this.messages.zipRequired;
      }
      if (address.zip && address.zip.length !== 5) {
        errors.address = errors.address || {};
        errors.address.zip = this.messages.zipShouldBeFiveDigits;
      }

    }

    return errors;
  },

  validateVirtualTerminalSettingsForm: function (values) {
    let errors = {};
    const requiredFields = ['label'];

    _.map(requiredFields, field => {
      if (this.hasNoValue(values[field])) {
        errors[field] = this.messages.requiredText;
      }
    });

    return errors;
  },

  validateCardOnFileForm: function (values, props = {}) {
    let errors = {};
    const ccRequiredFields = ['cdigits', 'edate', 'cvv', 'cardHolderName'];

    if (values.methodType === PaymentMethod.CREDIT_CARD) {
      if (!props.ignoreAvsFailure && !props?.showOptionalHint) {
        ccRequiredFields.push('streetNumber', 'cardZip');
      }

      _.map(ccRequiredFields, field => {
        if (this.hasNoValue(values[field])) {
          errors[field] = this.messages.requiredText;
        }
      });

      if (values.cdigits && !this.ccRegex(values.cdigits.replace(/-/g, ''))) {
        errors.cdigits = this.messages.cardInvalid;
      }

      if (this.hasNoValue(values.edate)) {
        errors.edate = this.messages.expirationDateRequired;
      } else {
        if(!this.expDateRegex(values.edate)) {
          errors.edate = this.messages.expirationDateInvalid;
        }
      }

      if (this.hasNoValue(values.cvv)) {
        errors.cvv = this.messages.securityCodeRequired;
      } else if (!this.cvvRegex(values.cvv)) {
        errors.cvv = this.messages.securityCodeInvalid;
      }
      if (!this.addressRegex(values.streetNumber)) {
        errors.street_number = this.messages.billingStreetNumberInvalid;
      }

      if (values.cardZip && values.cardZip.length !== 5) {
        errors.cardZip = this.messages.zipShouldBeFiveDigits;
      } else if (!this.zipRegex(values.cardZip)) {
        errors.cardZip = this.messages.zipInvalid;
      }

      if (values.zipPlus4 && values.zipPlus4.length !== 4) {
        errors.zipPlus4 = this.messages.zipPlusFourShouldBeFourDigits;
      }

      if (values.zipPlus4 && values.zipPlus4.length === 4 && this.hasNoValue(values.cardZip)) {
        errors.cardZip = this.messages.zipRequiredWithZipPlusFour;
      }

      if (values.cvv && values.edate && values.cardHolderName) {
        const cardType = FormatTextUtil.parseCardType(values.cdigits);
        const last4 = values.cdigits.slice(-4);
        const expYear = moment(values.edate, 'MM/YY').year();
        const expMonth = moment(values.edate, 'MM/YY').month() + 1;
        const nameOnCard = values.cardHolderName;

        if (props.paymentMethods) {

          const cardAlreadyExists = props.paymentMethods.some(paymentMethod =>
            paymentMethod.exp_month === expMonth &&
            paymentMethod.exp_year === expYear &&
            paymentMethod.last4 === last4 &&
            paymentMethod.network.toLowerCase() === cardType.toLowerCase() &&
            paymentMethod.name_on_card.toLowerCase() === nameOnCard.toLowerCase()
          );
          cardAlreadyExists && (errors.cardHolderName = this.messages.cardAlreadyExists);
        }
      }
    }

    if (values.methodType === PaymentMethod.BANKING_ACCOUNT) {
      errors = {
        ...this.validateAch(values)
      }

    }

    return errors;
  },

  validateQuickReplyForm: function (values) {
    const that = this;
    const errors = {};

    _.map(['category', 'text'], (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.text?.length > 350) {
      errors.text = that.messages.lengthOfFieldTooLong;
    }

    return errors;
  },

  validateInvoiceForm: function (values, props) {

    const that = this;
    let errors = {};

    const isSeries = values.frequency === PaymentFrequency.SERIES;
    const isTaxable = values['taxRate'] && values['taxRate'].length;
    const noEmailsPresent = !values.customer_email_addresses || !values.customer_email_addresses.length;

    if (values['name'] && values['name'].length > 50) {
      errors['name'] = that.messages.lengthOfFieldTooLong;
    }

    if (values['description'] && values['description'].length > 500) {
      errors['description'] = that.messages.lengthOfFieldTooLong;
    }

    if(!!props?.invoice_number_required && this.hasNoValue(values['invoice_number'])) {
      errors['invoice_number'] = that.messages.requiredText;
    }

    if (values['invoice_number'] && !this.isAlphaNumeric(values['invoice_number'])) {
      errors['invoice_number'] = that.messages.alphaNumericRequired;
    }

    if (this.hasNoValue(values['customer_first']) && this.hasNoValue(values['customer_last'])) {
      errors['customer_first'] = that.messages.firstOrLastNameRequired;
      errors['customer_last'] = that.messages.firstOrLastNameRequired;
    }

    if (this.hasNoValue(values['name'])) {
      errors['name'] = that.messages.requiredText;
    }

    if (values.customer_phone && !this.isPhoneNumber(values.customer_phone)) {
      errors.customer_phone = that.messages.invalidPhoneNumber;
    }

    if (values.isCbdCannabisMerchant) {
      if (noEmailsPresent) {
        errors.customer_email_addresses = that.messages.emailRequired;
      }
    } else {
      if (this.hasNoValue(values.customer_phone) && noEmailsPresent) {
        errors.customer_phone = that.messages.emailOrPhoneRequired;
        errors.customer_email_addresses = that.messages.emailOrPhoneRequired;
      }
    }

    if (!noEmailsPresent) {
      values.customer_email_addresses.forEach((email) => {
        if (!emailValidator.validate(email)) {
          errors.customer_email_addresses = that.messages.invalidEmail;
          return;
        }
      });
    }

    const validateAddress = [
      values.customer_street_address_1, values.customer_street_address_2,
      values.customer_zip, values.customer_state,
      values.customer_city].some((flag) => Boolean(flag));

    if (validateAddress && this.hasNoValue(values.customer_street_address_1)) {
      errors.customer_street_address_1 = that.messages.requiredStreet1;
    }

    if (values.customer_street_address_1 && !this.addressRegex(values.customer_street_address_1)) {
      errors.customer_street_address_1 = that.messages.invalidStreet1;
    }

    if (validateAddress && this.hasNoValue(values.customer_zip)) {
      errors.customer_zip = that.messages.zipRequired;
    }

    if (values.customer_zip && values.customer_zip.length !== 5) {
      errors.customer_zip = that.messages.zipShouldBeFiveDigits;
    } else if (values.customer_zip && !this.zipRegex(values.customer_zip)) {
      errors.customer_zip = that.messages.zipInvalid;
    }

    if (validateAddress && this.hasNoValue(values.customer_state)) {
      errors.customer_state = that.messages.requiredState;
    }

    if (validateAddress && this.hasNoValue(values.customer_city)) {
      errors.customer_city = that.messages.requiredCity;
    }

    const today = DateUtils.getTodayWithoutTime();

    if (isSeries) {

      const repeat = parseInt(values.recurring_repeat.repeat);
      const numberOfPayments = parseInt(values.numberOfPayments);

      if (this.hasNoValue(values.recurring_repeat.repeat) || _.isNaN(repeat) || repeat < 1) {
        errors.recurring_repeat = {
          ...errors.recurring_repeat,
          repeat : that.messages.recurringRepeatRequiredAndNonZero
        };
      }

      if (this.hasNoValue(values.recurring_repeat.recurringInterval)) {
        errors.recurring_repeat = {
          ...errors.recurring_repeat,
          recurringInterval : that.messages.recurringRepeatFrequencyRequired
        }
      }

      if (
        (values.ends === RecurringEnd.AFTER_NUMBER_OF_PAYMENTS) &&
        (this.hasNoValue(values.numberOfPayments) || _.isNaN(numberOfPayments) || numberOfPayments < 1)
      ) {
        errors.numberOfPayments = that.messages.recurringRepeatRequiredAndNonZero;
      }

      if (
        values.selectedSendDateValue === CUSTOM_DATE_VALUE &&
        values.selectedEndDateValue === CUSTOM_DATE_VALUE &&
        moment(values[CUSTOM_END_DATE_VALUE]).startOf('day') < moment(values[CUSTOM_SEND_DATE_VALUE]).startOf('day')
      ) {
        errors.selectedEndDateValue = that.messages.recurringEndLessThanStart;
      } else if (
        values.selectedSendDateValue !== CUSTOM_DATE_VALUE &&
        values.selectedEndDateValue !== CUSTOM_DATE_VALUE &&
        values.selectedEndDateValue < values.selectedSendDateValue
      ) {
        errors.selectedEndDateValue = that.messages.recurringEndLessThanStart;
      } else if (
        values.selectedSendDateValue === CUSTOM_DATE_VALUE &&
        values.selectedEndDateValue !== CUSTOM_DATE_VALUE &&
        DateUtils.addTimeToToday(values.selectedEndDateValue, 'days') < moment(values[CUSTOM_SEND_DATE_VALUE]).startOf('day')
      ) {
        errors.selectedEndDateValue = that.messages.recurringEndLessThanStart;
      } else if (
        values.selectedSendDateValue !== CUSTOM_DATE_VALUE &&
        values.selectedEndDateValue === CUSTOM_DATE_VALUE &&
        moment(values[CUSTOM_END_DATE_VALUE]).startOf('day') < DateUtils.addTimeToToday(values.selectedSendDateValue, 'days')
      ) {
        errors.selectedEndDateValue = that.messages.recurringEndLessThanStart;
      }

      if (values.selectedEndDateValue === CUSTOM_DATE_VALUE) {
        if (!values[CUSTOM_END_DATE_VALUE]) {
          errors.selectedEndDateValue = that.messages.invalidDate;
        } else if (today.isAfter(values[CUSTOM_END_DATE_VALUE], 'day')) {
          errors.selectedEndDateValue = that.messages.dateCannotBeInPast;
        }
      }
    }

    let sendDate = today.clone();

    if (values.selectedSendDateValue === CUSTOM_DATE_VALUE) {
      sendDate = values[CUSTOM_SEND_DATE_VALUE];
      if (!values[CUSTOM_SEND_DATE_VALUE]) {
        errors.selectedSendDateValue = that.messages.invalidDate;
      } else if (today.isAfter(values[CUSTOM_SEND_DATE_VALUE], 'day')) {
        errors.selectedSendDateValue = that.messages.dateCannotBeInPast;
      }
    } else if (values.selectedSendDateValue) {
      sendDate = sendDate.add(values.selectedSendDateValue, 'day');
    }

    if (values.selectedDueDateValue === CUSTOM_DATE_VALUE) {
      if (!values[CUSTOM_DUE_DATE_VALUE]) {
        errors.selectedDueDateValue = that.messages.invalidDate;
      } else if (today.isAfter(values[CUSTOM_DUE_DATE_VALUE], 'day')) {
        errors.selectedDueDateValue = that.messages.dateCannotBeInPast;
      } else if (values[CUSTOM_DUE_DATE_VALUE].isBefore(sendDate)) {
        errors.selectedDueDateValue = that.messages.recurringEndLessThanStart;
      }
    }

    if (isTaxable) {
      const taxAmount = values['taxRate'].replace(/%/g, '');
      const taxAmountMetaphor = taxAmount.includes('.') ? taxAmount.split('.')[0] : taxAmount;
      const taxAmountMantissa = taxAmount.includes('.') ? taxAmount.split('.')[1] : '0';

      if (taxAmount && Number(taxAmountMetaphor) > 99) {
        errors.taxRate = that.messages.taxAmountMetaphorMustBeLessThanNinetyNine;
      }

      if (taxAmount && taxAmountMantissa.length > 3) {
        errors.taxRate = that.messages.taxAmountMantissaMustBeEqualOrLessThanThreeDigits;
      }
    }

    if (!values.paymentMethod && !values.selectedPaymentMethod) {
      errors = {
        ...errors,
        ...this.validateCreditCard(values)
      };
    }

    if (values.paymentMethod === PaymentMethod.BANKING_ACCOUNT) {
      errors = {
        ...errors,
        ...this.validateAch(values)
      };
    }

    if (values?.selectedPaymentMethod?.type === 'ach') {
      if (!values.achTerms) {
        errors.achTerms = that.messages.requiredText;
      }
    }

    return errors;
  },

  validateCreatePaymentLinksForm: function (values) {

    const errors = {};
    const {
      paymentLinkTitle,
      paymentLinkAmountType,
      paymentAmount1,
      paymentAmount2,
      paymentAmount3,
      minimumAmount,
      maximumAmount,
      redirectUrl,
      customRequiredField,
      addCustomField
    } = values;
    const {hasNoValue, isURL, validHttps, messages} = this;

    if (hasNoValue(paymentLinkTitle)) {
      errors.paymentLinkTitle = messages.requiredText;
    }

    if ((paymentLinkAmountType === paymentLinks.amountTypes.fixedAmount || paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount) && hasNoValue(paymentAmount1)) {
      errors.paymentAmount1 = messages.requiredText;
    }

    if (!hasNoValue(minimumAmount) && !hasNoValue(maximumAmount) && (numeral(minimumAmount).value() >= numeral(maximumAmount).value())) {
      errors.minimumAmount = messages.minimumLargerThanMaximum;
      errors.maximumAmount = messages.minimumLargerThanMaximum;
    }

    if (!hasNoValue(redirectUrl) && (!isURL(redirectUrl) || !validHttps(redirectUrl))) {
      errors.redirectUrl = messages.invalidSecureURL;
    }

    if (paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount && hasNoValue(paymentAmount2) && !hasNoValue(paymentAmount3)) {
      errors.paymentAmount2 = messages.requiredText;
    }

    if (paymentLinkAmountType === paymentLinks.amountTypes.multipleAmount) {

      if (!hasNoValue(paymentAmount1) && !hasNoValue(paymentAmount2) && (paymentAmount1 === paymentAmount2)) {
        errors.paymentAmount1 = errors.paymentAmount2 = messages.duplicatedPaymentAmounts;
      }
      if (!hasNoValue(paymentAmount1) && !hasNoValue(paymentAmount3) && (paymentAmount1 === paymentAmount3)) {
        errors.paymentAmount1 = errors.paymentAmount3 = messages.duplicatedPaymentAmounts;
      }
      if (!hasNoValue(paymentAmount2) && !hasNoValue(paymentAmount3) && (paymentAmount2 === paymentAmount3)) {
        errors.paymentAmount2 = errors.paymentAmount3 = messages.duplicatedPaymentAmounts;
      }

    }

    const valuesShouldBePositiveIfPresent = {
      paymentAmount1,
      paymentAmount2,
      paymentAmount3,
      minimumAmount,
      maximumAmount
    };

    Object.entries(valuesShouldBePositiveIfPresent).map(keyValuePair => {

      if (!hasNoValue(keyValuePair[1]) && numeral(keyValuePair[1]).value() <= 0) {
        errors[keyValuePair[0]] = messages.amountMustBeGreaterThanZero;
      }

    });

    if (customRequiredField && hasNoValue(addCustomField)) {
      errors.addCustomField = messages.requiredText;
    }

    return errors;

  },

  validatePaymentLinkDetailForm: function (values) {

    let errors = {};
    const { paymentLinkTitle } = values;

    if (this.hasNoValue(paymentLinkTitle)) {
      errors.paymentLinkTitle = this.messages.requiredText;
    }

    return errors;

  },

  validateRecurringPaymentForm: function (values) {

    let errors = {};

    if (this.hasNoValue(values['amount'])) {
      errors['amount'] = this.messages.requiredText;
    }

    return errors;
  },

  validateEmployeeDetailForm: function (values) {

    let errors = {};
    const requiredFields = ['first_name', 'last_name', 'email_address'];
    let that = this;

    if (!values.original_phone_number) {
      requiredFields.push('phone_number');
    }

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.email_address && !emailValidator.validate(values.email_address)) {
      errors.email_address = that.messages.invalidEmail;
    }

    if (!this.isPhoneNumber(values['phone_number']) && !this.hasNoValue(values['phone_number'])) {
      errors.phone_number = that.messages.invalidPhoneNumber;
    }

    return errors;
  },

  validateSendReceiptForm: function (values) {

    let errors = {};
    let that = this;

    if (values.email_addresses) {
      _.map(values.email_addresses, (email, index) => {
        if (email && !emailValidator.validate(email)) {
          if (!errors.email_addresses) {
            errors.email_addresses = [];
          }
          errors.email_addresses[index] = that.messages.invalidEmail;
        }
      });
    }

    if (values.phone_numbers) {
      _.map(values.phone_numbers, (phoneNumber, index) => {
        if (phoneNumber && !this.isPhoneNumber(phoneNumber)) {
          if (!errors.phone_numbers) {
            errors.phone_numbers = [];
          }
          errors.phone_numbers[index] = that.messages.invalidPhoneNumber;
        }
      });
    }

    return errors;
  },

  validateProfileForm: function (values, label) {

    let errors = {};
    let that = this;

    const needsCommunication = ['ph', 'nh'].includes(label);

    const noCommunicationFields = [
      'principal',
      'principal_dob',
      'principal_address',
      'principal_city',
      'principal_state',
      'principal_zip',
      'principal_phone'
    ];

    const communicationFields = [
      'principal',
      'principal_dob',
      'principal_address',
      'principal_city',
      'principal_state',
      'principal_zip',
      'principal_phone'
    ];

    const requiredFields = needsCommunication ? communicationFields : noCommunicationFields;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    const phoneNumberFields = ['principal_phone'];

    _.map(phoneNumberFields, (field) => {
      if (values[field] && !this.isPhoneNumber(values[field])) {
        errors[field] = that.messages.invalidPhoneNumber;
      }
    });

    if (!this.hasSpace(values['principal'])) {
      errors['principal'] = that.messages.principleNameError;
    }

    return errors;

  },

  validateBusinessForm: function (values) {

    let errors = {};
    let that = this;

    const basic = [
      'business_address',
      'business_city',
      'business_state',
      'business_zip',
      'business_phone'
    ];

    const corporate = [
      'corporate_address',
      'corporate_city',
      'corporate_state',
      'corporate_zip'
    ];

    const shipping = [
      'shipping_name',
      'shipping_contact',
      'shipping_address_1',
      'shipping_city',
      'shipping_state',
      'shipping_zip'
    ];

    let requiredFields = basic;
    requiredFields = values.additionalCorporateInfo ? requiredFields.concat(corporate) : requiredFields;
    requiredFields = values.additionalInfo ? requiredFields.concat(shipping) : requiredFields;

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    const phoneNumberFields = ['business_phone', 'business_fax'];

    _.map(phoneNumberFields, (field) => {
      if (values[field] && !this.isPhoneNumber(values[field])) {
        errors[field] = that.messages.invalidPhoneNumber;
      }
    });

    return errors;

  },

  validateBankingForm: function (values) {

    let errors = {};
    let that = this;

    const requiredFields = ['account', 'routingNumber', 'accountType'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.account && !this.validAccountNumber(values.account)) {
      errors.account = that.messages.invalidAccountNumber;
    }

    if (values.routingNumber && this.validRoutingNumberLength(values.routingNumber) && !this.validRoutingNumber(values.routingNumber)) {
      errors.routingNumber = that.messages.abaInvalidNumber;
    }

    if (values.routingNumber && !this.validRoutingNumberLength(values.routingNumber)) {
      errors.routingNumber = that.messages.invalidRoutingNumber;
    }


    return errors;

  },

  validateFunding: function (values) {

    let errors = {};
    let that = this;

    if (values.is_if) {

      if (this.hasNoValue(values['debit_card_number'])) {
        errors.debit_card_number = that.messages.debitCardRequired;
      } else if (FormatTextUtil.parseCardType(values.debit_card_number) !== 'visa' && FormatTextUtil.parseCardType(values.debit_card_number) !== 'mastercard') {
        errors.debit_card_number = that.messages.debitCardInvalidForInstantFunding;
      } else if (!this.ccRegex(values['debit_card_number'].replace(/-/g, ''))) {
        errors.debit_card_number = that.messages.debitCardInvalid;
      }

      if (this.hasNoValue(values['expiration'])) {
        errors.expiration = that.messages.expirationDateRequired;
      } else {
        if(!this.expDateRegex(values.expiration)) {
          errors.expiration = that.messages.expirationDateInvalid;
        }
      }

      if (this.hasNoValue(values['cvv2'])) {
        errors.cvv2 = that.messages.securityCodeRequired;
      } else if (!this.cvvRegex(values['cvv2'])) {
        errors.cvv2 = that.messages.securityCodeInvalid;
      }

      if (this.hasNoValue(values['address'])) {
        errors.address = that.messages.billingStreetNumberRequired;
      } else if (!this.addressRegex(values['address'])) {
        errors.address = that.messages.billingStreetNumberInvalid;
      }

      if (this.hasNoValue(values['zip_code'])) {
        errors.zip_code = that.messages.zipRequired;
      }

      if (values['zip_code'] && values['zip_code'].length !== 5) {
        errors.zip_code = that.messages.zipShouldBeFiveDigits;
      } else if (!this.zipRegex(values['zip_code'])) {
        errors.zip_code = that.messages.zipInvalid;
      }

      if (this.hasNoValue(values['name_on_card'])) {
        errors.name_on_card = that.messages.nameOnCardRequired;
      }
    }

    return errors;

  },

  validateJsSdkDomain: function (values) {
    let errors = {};

    if (!Validator.validWebDomain(values.jsSdkDomain)) {
      errors.jsSdkDomain = Validator.messages.invalidWebDomain;
    }
    return errors;
  },

  validateWebhookDomain: function (values) {
    let errors = {};
    if (values?.webhookDomain) {
      if (!Validator.validWebDomain(values.webhookDomain)) {
        errors.webhookDomain = Validator.messages.invalidWebDomain;
      } else if (!Validator.validHttps(values.webhookDomain)) {
        errors.webhookDomain = Validator.messages.httpsRequired;
      }
    }
    return errors;
  },

  validateAccessAPIForm: function (values) {
    let errors = {};
    if (values?.accessOurAPI) {
      if (!values.accessPayNow && !values.accessJSSDK && !values.semiIntegration) {
        errors.accessOurAPI = Validator.messages.accessMustBeSelected;
      }

      if (values.accessJSSDK && !Validator.validWebDomain(values.jsSDKDomain)) {
        errors.jsSDKDomain = Validator.messages.invalidWebDomain;
      }
    }

    return errors;

  },

  validateWebhookForm: function (values) {

    let errors = {};

    if (values?.accessWebhook) {
      if (!Validator.validWebDomain(values.webhookDomain)) {
        errors.webhookDomain = Validator.messages.invalidWebDomain;
      } else if (!Validator.validHttps(values.webhookDomain)) {
        errors.webhookDomain = Validator.messages.httpsRequired;
      }
    }

    return errors;

  },

  validateReceiptSettingsForm: function (values) {

    let errors = {};
    let that = this;

    let requiredFields = ['merchant_name', 'address', 'city', 'state', 'zip'];

    //Pin enabled validation for PA-11897

    if (values.should_bcc && !values.bccEmailAddresses) {
      requiredFields.push('bccEmailAddresses');
    }

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.website && !this.isURL(values.website)) {
      errors.website = that.messages.invalidURL;
    }

    if (values.email_address && !emailValidator.validate(values.email_address)) {
      errors.email_address = that.messages.invalidEmail;
    }

    if (values.should_bcc && values.bccEmailAddresses && !emailValidator.validate(values.bccEmailAddresses)) {
      errors.bccEmailAddresses = that.messages.invalidEmail;
    }

    if (values.social_media_enabled && values.facebook_link && !this.validateFacebookHandle(values.facebook_link)) {
      errors.facebook_link = that.messages.invalidFacebookHandle;
    }

    if (values.social_media_enabled && values.twitter_link && !this.validateTwitterHandle(values.twitter_link)) {
      errors.twitter_link = that.messages.invalidTwitterHandle;
    }

    if (values.social_media_enabled && values.instagram_link && !this.validateInstagramHandle(values.instagram_link)) {
      errors.instagram_link = that.messages.invalidInstagramHandle;
    }

    if (values.social_media_enabled && values.yelp_link && !this.validateYelpHandle(values.yelp_link)) {
      errors.yelp_link = that.messages.invalidYelpHandle;
    }

    const reminderFields = [
      'upcoming_due_date_reminders',
      'overdue_invoices_reminders' ,
      'automatic_payments_reminders',
      'failed_payments_reminders'
    ];

    reminderFields.forEach(field => {
      const fieldValue = values[field];
      if (fieldValue) {
        let reminderDays = fieldValue.map((reminder) => reminder.days);

        reminderDays = reminderDays.reduce((acc, current, index) => {
          acc[index] = current;
          return acc;
        }, {});

        const dayKeys = Object.keys(reminderDays);
        const dayValues = Object.values(reminderDays);

        const valueCount = {};

        dayValues.forEach((value, index) => {
          if (value === undefined) return;
          if (valueCount[value]) {
            valueCount[value].push(dayKeys[index]);
          } else {
            valueCount[value] = [dayKeys[index]];
          }
        });

        const duplicateKeys = Object.values(valueCount)
          .filter(keys => keys.length > 1)
          .flat();

        if (duplicateKeys.length > 0) {
          duplicateKeys.forEach(key => {
            errors[field] = {
              ...(errors[field] || {}),
              [key]: 'Validator.DuplicateReminder',
            };
          });
        }

        Object.values(reminderDays).forEach((day, index) => {
          if (day === undefined) return;

          const minCheck = !Boolean(day) || parseInt(day) <= 0 || !/^-?\d+$/.test(day);
          const maxCheck = parseInt(day) > 14 && !['overdue_invoices_reminders', 'failed_payments_reminders'].includes(field) ;

          let message =  null;

          if (minCheck) {
            message = 'Validator.GreaterThanZero';
          } else if (maxCheck && field === 'upcoming_due_date_reminders') {
            message = 'Validator.UpcomingLesserThan14';
          } else if (maxCheck && field === 'automatic_payments_reminders') {
            message = 'Validator.AutomaticLesserThan14';
          }

          if (minCheck || maxCheck) {
            errors[field] = {
              ...(errors[field] || {}),
              [index]: message
            }
          }
        });


        Object.values(fieldValue).forEach((reminder, index) => {
          if (reminder.email === false && reminder.sms === false && !Boolean(reminder.text)) {
            errors[field] = {
              ...(errors[field] || {}),
              [index]: 'Validator.AtLeastOneNotification',
            }
          }
        });
      }
    });

    return errors;

  },

  validateStoreSettingsForm: function (values) {
    let errors = {};

    if (this.hasNoValue(values.online_store_subdomain) && values.online_store_enabled) {
      errors.online_store_subdomain = this.messages.requiredText;
    }

    return errors;
  },

  validateSettingsForm: function (values, isPA, achEnabled) {

    let errors = {};
    let that = this;

    let requiredFields = ['merchant_name', 'address', 'city', 'state', 'zip'];

    if (isPA) {
      requiredFields.push('receipt_options', 'theme');
    }

    if (values.set_closing_timer && !values.manual_capture_time) {
      requiredFields.push('manual_capture_time');
    }

    if (values.tip_one_amount || FormatTextUtil.checkTipType(values.tip_one_type)) {
      errors.tip_one_amount = this.validateTip(values.tip_one_amount, values.tip_one_type);
    }

    if (values.tip_two_amount || FormatTextUtil.checkTipType(values.tip_two_type)) {
      errors.tip_two_amount = this.validateTip(values.tip_two_amount, values.tip_two_type);
    }

    if (values.tip_three_amount || FormatTextUtil.checkTipType(values.tip_three_type)) {
      errors.tip_three_amount = this.validateTip(values.tip_three_amount, values.tip_three_type);
    }

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.tax_rate) {
      const taxAmount = values.tax_rate.replace(/%/g, '');
      const taxAmountMetaphor = taxAmount.includes('.') ? taxAmount.split('.')[0] : taxAmount;
      const taxAmountMantissa = taxAmount.includes('.') ? taxAmount.split('.')[1] : '0';

      if (taxAmount && Number(taxAmount) < 0) {
        errors.tax_rate = that.messages.taxAmountMustBeEqualGreaterThanZeroNoTaxable;
      }

      if (taxAmount && Number(taxAmountMetaphor) > 99) {
        errors.tax_rate = that.messages.taxAmountMetaphorMustBeLessThanNinetyNine;
      }

      if (taxAmount && taxAmountMantissa.length > 3) {
        errors.tax_rate = that.messages.taxAmountMantissaMustBeEqualOrLessThanThreeDigits;
      }

    }

    if (achEnabled) {
      if (!values?.ach_notification_revoke_days?.length) {
        errors.ach_notification_revoke_days = that.messages.requiredText
      }
      if (!values?.ach_notification_revoke_type?.length) {
        errors.ach_notification_revoke_type = that.messages.requiredText
      }
    }

    return errors;

  },

  validateCommunicationsSettingsForm: function (values, label, hasCommunicationPreferences, userIsMBPEPX, userIsEdge, isAtLeastOneInvoiceNotificationSelected) {

    let errors = {};
    let that = this;
    const needsCommunication = ['ph', 'nh'].includes(label);


    const alertsFields = ['alert_to'];
    const invoiceAlertsFields = ['invoice_alert_to'];
    const communicationSettingsRequiredFields = [];

    if (needsCommunication) {
      if (hasCommunicationPreferences) {
        communicationSettingsRequiredFields.push('comm_dailySnapshotEmail', 'comm_disputes');
      }

      if (userIsMBPEPX) {
        communicationSettingsRequiredFields.push('comm_compliance');
      }

      if (userIsEdge) {
        communicationSettingsRequiredFields.push('comm_statements');
      }
    }

    const alertsToRequiredFields = values?.no_transaction_activity || values?.daily_batch_activity_summary ? alertsFields : [];
    const invoiceAlertsToRequiredFields = isAtLeastOneInvoiceNotificationSelected ? invoiceAlertsFields : [];
    const requiredFields = [...alertsToRequiredFields, ...communicationSettingsRequiredFields, ...invoiceAlertsToRequiredFields];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values.alert_to && !emailValidator.validate(values.alert_to)) {
      errors.alert_to = that.messages.invalidEmail;
    }

    if (values.alert_cc && !emailValidator.validate(values.alert_cc)) {
      errors.alert_cc = that.messages.invalidEmail;
    }

    if (values.invoice_alert_to && !emailValidator.validate(values.invoice_alert_to)) {
      errors.invoice_alert_to = that.messages.invalidEmail;
    }

    if (values.invoice_alert_cc && !emailValidator.validate(values.invoice_alert_cc)) {
      errors.invoice_alert_cc = that.messages.invalidEmail;
    }

    if (values.comm_disputes) {
      if (!values.comm_disputes.length) errors.comm_disputes = that.messages.requiredText;
      else {
        const validEmail = values.comm_disputes.every(emailValidator.validate);
        if (!validEmail) errors.comm_disputes = that.messages.invalidEmail;
      }
    }

    const requiredTargetValueFields = [
      'transaction_over',
      'batch_over',
      'batch_under',
      'refund_transaction_over',
      'batch_refunds_over',
      'no_transaction_activity'
    ];

    _.map(requiredTargetValueFields, (field) => {
      if (values[field] && this.hasNoValue(values[field + '_target_value'])) {
        errors[field + '_target_value'] = that.messages.targetValueRequired;
      }
    });

    return errors;

  },

  validateItemsImport: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['items_library'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    return errors;

  },

  validateBulkInvoicesImport: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['bulk_invoice_template'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    return errors;

  },

  validateBankAccountFileImport: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['bank_account_verification'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    return errors;

  },

  validateReportCustomDateFilter: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['fromDate', 'toDate'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    let fromDateString = values.fromDate,
      fromDate = values.fromDate && moment(new Date(fromDateString)); // ignore seconds value

    let toDateString = values.toDate,
      toDate = values.toDate && moment(new Date(toDateString));

    if (toDate && fromDate && toDate.isSameOrBefore(fromDate, 'minute')) {
      errors.toDate = that.messages.invalidDateRange;
      errors.fromDate = that.messages.invalidDateRange;
    }

    if (fromDate && !fromDate.isValid()) {
      errors.fromDate = that.messages.invalidDate;
    }

    if (toDate && !toDate.isValid()) {
      errors.toDate = that.messages.invalidDate;
    }

    return errors;

  },

  // TODO: combine logic with validateReportCustomDateFilter
  validateReportCustomDateFilterWithPrevious: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['fromDate', 'toDate', 'fromDatePrevious', 'toDatePrevious'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    let fromDateString = values.fromDate,
      fromDate = values.fromDate && moment(new Date(fromDateString));

    let toDateString = values.toDate,
      toDate = values.toDate && moment(new Date(toDateString));

    let fromDatePreviousString = values.fromDatePrevious,
      fromDatePrevious = values.fromDatePrevious && moment(new Date(fromDatePreviousString));

    let toDatePreviousString = values.toDatePrevious,
      toDatePrevious = values.toDatePrevious && moment(new Date(toDatePreviousString));

    if (toDate && fromDate && toDatePrevious && fromDatePrevious &&
      toDate.isSame(toDatePrevious, 'day') && fromDate.isSame(fromDatePrevious, 'day')) {
      errors.fromDatePrevious = that.messages.previousRangeIdentical;
      errors.toDatePrevious = that.messages.previousRangeIdentical;
    }

    if (toDate && fromDate &&
      toDate.isSameOrBefore(fromDate, 'minute')) {
      errors.toDate = that.messages.invalidDateRange;
    }

    if (toDatePrevious && fromDatePrevious &&
      toDatePrevious.isSameOrBefore(fromDatePrevious, 'minute')) {
      errors.toDatePrevious = that.messages.invalidPreviousDateRange;
    }

    return errors;

  },

  validateTerms2FAForm: function (values) {

    const errors = {};
    let that = this;
    const requiredFields = ['token'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });
    if (values['cellPhone'] && !this.isPhoneNumber(values['cellPhone'])) {
      errors['cellPhone'] = that.messages.invalidMobileNumber;
    }

    if (this.hasNoValue(values.cellPhone)) {
      errors.cellPhone = that.messages.requiredText;
    }

    return errors;
  },

  validateTwoFactorForm: function (values) {

    const errors = {};
    let that = this;
    const requiredFields = ['validationCode'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values['cellPhone'] && !this.isPhoneNumber(values['cellPhone'])) {
      errors['cellPhone'] = that.messages.invalidMobileNumber;
    }

    return errors;
  },

  validateMFASetupForm: function (values) {

    const errors = {};
    let that = this;
    const requiredFields = ['cellPhone'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    if (values['cellPhone'] && !this.isPhoneNumber(values['cellPhone'])) {
      errors['cellPhone'] = that.messages.invalidMobileNumber;
    }

    if (this.hasNoValue(values.cellPhone)) {
      errors.cellPhone = that.messages.requiredText;
    }

    if (values['cellPhone'] && this.hasNoValue(values['token'])) {
      errors.token = that.messages.requiredText;
    }

    return errors;
  },

  validateTwoFactorVerificationForm: function (values) {
    const errors = {};
    if (this.hasNoValue(values.validation_code)) {
      errors.validation_code = this.messages.requiredText
    }
    return errors;
  },

  validateCustomField: function (value, _allValues, props) {
    if (props.custom_field_1?.required && Validator.hasNoValue(value)) {
      return Validator.messages.requiredText;
    }
  },

  validateOnlinePayments: function (values, props) {

    let that = this;
    let errors = {};
    const {validateCart, invoice_number_required} = props || {};

    if (this.hasNoValue(values['paymentType'])) {
      errors.paymentType = that.messages.paymentTypeRequired;
    }

    if (((this.hasNoValue(values['amount']) || numeral(values.amount).value() === 0)
      && (this.hasNoValue(values['rewardCode']) || this.hasNoValue(values['rewardCodeInformation'])))
    ) {
      errors.amount = that.messages.amountRequired;
    } else if (values.amount && numeral(values.amount).value() < 0) {
      errors.amount = that.messages.amountMustBeGreaterThanZero;
    } else if (values.paymentType === 'issueCredit' && numeral(values.amount).value() > highTransactionLimitAmount) {
      errors.amount = that.messages.maxAmountReached;
    }

    if (values.amount && values.partialPayment) {
      const formattedRemainingBalance = parseFloat(values.partialPayment.remainingBalance);
      const formattedAmount = numeral(values.amount).value();

      if (formattedAmount > formattedRemainingBalance) {
        errors.amount = that.messages.incorrectPartialAmount;
      }
    }

    if ((values.paymentMethod === PaymentMethod.CREDIT_CARD && values.creditCardPaymentFlag) && !values.selectedPaymentMethod) {
      errors = {
        ...errors,
        ...this.validateCreditCard(values)
      };
    }

    if (values?.selectedPaymentMethod?.type === 'ach') {
      if (!values.achTerms) {
        errors.achTerms = that.messages.requiredText;
      }
    }

    const validateAddress = [
      values.customer_street_address_1, values.customer_street_address_2,
      values.customer_zip, values.customer_state,
      values.customer_city].some((flag) => Boolean(flag));

    if (validateAddress && this.hasNoValue(values.customer_street_address_1)) {
      errors.customer_street_address_1 = that.messages.requiredStreet1;
    }

    if (values.customer_street_address_1 && !this.addressRegex(values.customer_street_address_1)) {
      errors.customer_street_address_1 = that.messages.invalidStreet1;
    }

    if (validateAddress && this.hasNoValue(values.customer_zip)) {
      errors.customer_zip = that.messages.zipRequired;
    }

    if (values.customer_zip && values.customer_zip.length !== 5) {
      errors.customer_zip = that.messages.zipShouldBeFiveDigits;
    } else if (values.customer_zip && !this.zipRegex(values.customer_zip)) {
      errors.customer_zip = that.messages.zipInvalid;
    }

    if (validateAddress && this.hasNoValue(values.customer_state)) {
      errors.customer_state = that.messages.requiredState;
    }

    if (validateAddress && this.hasNoValue(values.customer_city)) {
      errors.customer_city = that.messages.requiredCity;
    }

    if (values['customer_email_addresses'] && values['customer_email_addresses'].length > 0) {
      values['customer_email_addresses'].map(value => {
        if (!emailValidator.validate(value)) {
          errors.customer_email_addresses = that.messages.invalidEmail;
        }
      });
    }

    if (!this.isPhoneNumber(values['customer_phone']) && !this.hasNoValue(values['customer_phone'])) {
      errors.customer_phone = that.messages.invalidPhoneNumber;
    }

    if (values['saveCreditCard']
      || validateAddress
      || !this.hasNoValue(values['customer_first'])
      || !this.hasNoValue(values['customer_last'])
    ) {
      if (!(values['customer_email_addresses'] && values['customer_email_addresses'].length > 0)
        && this.hasNoValue(values['customer_phone'])
      ) {
        errors.customer_email_addresses = that.messages.emailOrPhoneRequired;
        errors.customer_phone = that.messages.emailOrPhoneRequired;
      }
    }

    if (validateAddress
      || values['saveCreditCard']
      || (values['customer_email_addresses'] && values['customer_email_addresses'].length > 0)
      || !this.hasNoValue(values['customer_phone'])
    ) {
      if (this.hasNoValue(values['customer_first']) && this.hasNoValue(values['customer_last'])) {
        errors.customer_first = that.messages.firstOrLastNameRequired;
        errors.customer_last = that.messages.firstOrLastNameRequired;
      }
    }

    if (validateCart) {
      const taxAmount = values['taxRate'] ? values['taxRate'].replace(/%/g, '') : '0';
      const taxAmountMetaphor = taxAmount.includes('.') ? taxAmount.split('.')[0] : taxAmount;
      const taxAmountMantissa = taxAmount.includes('.') ? taxAmount.split('.')[1] : '0';

      if (taxAmount && Number(taxAmountMetaphor) > 99) {
        errors.taxRate = that.messages.taxAmountMetaphorMustBeLessThanNinetyNine;
      }

      if (taxAmount && taxAmountMantissa.length > 3) {
        errors.taxRate = that.messages.taxAmountMantissaMustBeEqualOrLessThanThreeDigits;
      }

      if ((this.hasNoValue(values['subtotalPreDiscounts']) || numeral(values['subtotalPreDiscounts']).value() <= 0) && (
        (values['paymentType'] === 'oneTimePayment' && !!(values.amount && numeral(values.amount).value() < 0)) ||
        values['paymentType'] === 'itemizedSale'
      )) {
        errors.amount = that.messages.amountMustBeGreaterThanZero;
      }
    }

    const levelTwo = values['processingDataSelection'] === 'levelTwo';
    const levelThree = values['processingDataSelection'] === 'levelThree';

    // validation of level 2 & 3 additional data
    if ((levelTwo || levelThree) && this.hasNoValue(values['customerCode'])) {
      errors.customerCode = that.messages.customerCodeRequired;
    }

    if ((levelTwo || levelThree) && this.hasNoValue(values['taxAmount'])) {
      errors.taxAmount = that.messages.taxAmountRequired;
    }

    if (levelThree && this.hasNoValue(values['taxId'])) {
      errors.taxId = that.messages.taxIdRequired;
    } else if (!this.hasNoValue(values['taxId']) && !this.validTaxId(values['taxId'])) {
      errors.taxId = that.messages.invalidTaxId;
    }

    if (levelThree && this.hasNoValue(values['itemDescription'])) {
      errors.itemDescription = that.messages.itemDescriptionRequired;
    }

    if (levelThree && this.hasNoValue(values['itemQuantity'])) {
      errors.itemQuantity = that.messages.itemQuantityRequired;
    }

    if (levelThree && this.hasNoValue(values['measureUnit'])) {
      errors.measureUnit = that.messages.measureUnitRequired;
    }

    if (levelThree && this.hasNoValue(values['unitPrice'])) {
      errors.unitPrice = that.messages.unitPriceRequired;
    }

    if (levelThree && this.hasNoValue(values['productCode'])) {
      errors.productCode = that.messages.productCodeRequired;
    }

    if (levelThree && this.hasNoValue(values['commodityCode'])) {
      errors.commodityCode = that.messages.commodityCodeRequired;
    }

    if (levelThree && this.hasNoValue(values['requestorName'])) {
      errors.requestorName = that.messages.requestorNameRequired;
    }

    if (levelThree && this.hasNoValue(values['companyName'])) {
      errors.companyName = that.messages.companyNameRequired;
    }

    if (values.paymentMethod === PaymentMethod.BANKING_ACCOUNT) {
      errors = {
        ...errors,
        ...this.validateAch(values)
      };
    }

    if (values.tip_amount && numeral(values.tip_amount).value() > highTransactionLimitAmount) {
      errors.tip_amount = that.messages.maxAmountReached;
    }

    if (!!values.selectedPaymentMethod && values.paymentType === 'debtRepayment' &&
      !['Visa', 'Mastercard'].includes(values.selectedPaymentMethod.network)) {
      errors.selectedPaymentMethod = this.messages.creditCardInvalidForDebtRepayment;
    }

    if(!!invoice_number_required && this.hasNoValue(values['invoice_number'])) {
      errors['invoice_number'] = that.messages.requiredText;
    }

    return errors;
  },

  validatePaymentLinkPayments: function (values) {

    const { settings } = values;
    const errors = {};

    if (settings?.custom_field?.required) {
      if (this.hasNoValue(values.custom_field)) {
        errors.custom_field = this.messages.requiredText;
      }
    }

    switch (settings?.type) {
      case paymentLinks.amountTypes.openEnded:
        if (this.hasNoValue(values.amount)) {
          errors.amount = this.messages.requiredText;
        } else {
          const amountValue = numeral(values.amount).value();

          if (amountValue <= 0) {
            errors.amount = this.messages.requiredText;
          } else if ((settings.open_end_min && amountValue < settings.open_end_min) && !settings.open_end_max) {
            errors.amount = this.messages.minWithoutMax;
          } else if ((settings.open_end_min && amountValue < settings.open_end_min) || (settings.open_end_max && amountValue > settings.open_end_max)) {
            errors.amount = this.messages.amountBetweenMinMax;
          }
        }
        break;
      case paymentLinks.amountTypes.multipleAmount:
        if (settings.include_other) {
          const otherAmountValue = numeral(values.other_amount).value();

          if (this.hasNoValue(values.other_amount) && this.hasNoValue(values.amount)) {
            errors.amount = this.messages.requiredText;
          } else if (!this.hasNoValue(values.other_amount)) {
            if (otherAmountValue <= 0) {
              errors.other_amount = this.messages.amountMustBeGreaterThanZero;
            } else if ((settings.open_end_min && otherAmountValue < settings.open_end_min) && !settings.open_end_max) {
              errors.other_amount = this.messages.minWithoutMax;
            } else if ((settings.open_end_min && otherAmountValue < settings.open_end_min) || (settings.open_end_max && otherAmountValue > settings.open_end_max)) {
              errors.other_amount = this.messages.amountBetweenMinMax;
            }
          }
        } else if (this.hasNoValue(values.amount)) {
          errors.amount = this.messages.requiredText;
        }
        break;
    }

    if (!values.isApplePay) {
      let requiredFields = ['edate', 'cvv'];

      if (values.avsEnabled) {
        requiredFields = [...requiredFields, 'street_number', 'zip'];
      }

      _.map(requiredFields, (field) => {
        if (this.hasNoValue(values[field])) {
          errors[field] = this.messages.requiredText;
        }
      });

      const contactMethods = ['email_address', 'phone_number'];
      const atLeastOneRequired = contactMethods.every(field => this.hasNoValue(values[field]));

      if (atLeastOneRequired) {
        contactMethods.forEach((field) => {
          errors[field] = this.messages.emailOrPhoneRequired;
        });
      }


      if (this.hasNoValue(values.name_on_card)) {
        errors.name_on_card = this.messages.nameOnCardRequired;
      }

      if (!values.cdigits || !this.ccRegex(values.cdigits.replace(/-/g, ''))) {
        errors.cdigits = this.messages.cardInvalid;
      }

      if (!errors.edate && !this.hasNoValue(values.edate) && !this.expDateRegex(values.edate)) {
        errors.edate = this.messages.expirationDateInvalid;
      }

      if (!errors.cvv && !this.cvvRegex(values.cvv)) {
        errors.cvv = this.messages.securityCodeInvalid;
      }

      if (!errors.email_address && values.email_address && !emailValidator.validate(values.email_address)) {
        errors.email_address = this.messages.invalidEmail;
      }

      if (!errors.phone_number && values.phone_number && !this.isPhoneNumber(values.phone_number)) {
        errors.phone_number = this.messages.invalidPhoneNumber;
      }
    }

    return errors;

  },

  validateAddVTItem: function (values) {

    const errors = {};

    if (values.expressModifiers?.length > 0) {
      errors.expressModifiers = [];
      values.expressModifiers.map(modifier => {
        const modifierErrors = {};

        if (this.hasNoValue(modifier?.name)) {
          modifierErrors.name = this.messages.requiredText;
        }

        if (this.hasNoValue(modifier?.price) || numeral(modifier?.price).value() <= 0) {
          modifierErrors.price = this.messages.amountMustBeGreaterThanZero;
        }

        errors.expressModifiers.push(modifierErrors);
      });
    }

    if (values.itemPriceId) {

      if (numeral(values.itemTotal).value() < 0) {
        errors.itemDiscountValue = this.messages.itemDiscountExceedsTotal;
      }

    } else { //Express item

      if (this.hasNoValue(values.expressItemName)) {
        errors.expressItemName = this.messages.expressItemNameRequired;
      }

      if (this.hasNoValue(values.expressItemPrice) || values.expressItemPrice === '$0.00') {
        errors.expressItemPrice = this.messages.expressItemPriceRequired;
      }

      if (values.expressItemTaxable && (this.hasNoValue(values.expressItemTaxRate) || values.expressItemTaxRate === '0%')) {
        errors.expressItemTaxRate = this.messages.expressItemTaxRateRequired;
      }

    }

    return errors;
  },

  validateAddComment: function (values) {

    let errors = {};
    let that = this;
    const requiredFields = ['title', 'comment'];

    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });
    if (values.comment && !this.validDisputesNotes(values.comment)) {
      errors.comment = that.messages.invalidDisputeNote;
    }
    return errors;

  },

  validateAddFile: function (values) {
    let errors = {
      files: []
    };
    let that = this;

    if (values.files) {
      values.files.map((f, i) => {

        let imgError = {};

        if (!f.description) {
          imgError.description = that.messages.requiredText;
        }

        errors.files[i] = imgError;

      });
    }

    return errors;

  },

  validateDisputeFile: function (values) {
    const errors = {};
    const that = this;
    if (!values.comment) {
      errors.comment = that.messages.requiredText;
    }
    if (values.comment && !this.validDisputesNotes(values.comment)) {
      errors.comment = that.messages.invalidDisputeNote;
    }
    return errors;
  },

  validateEditPaymentAmountForm: function (values) {
    const errors = {};
    const that = this;

    if (!values.amount) {
      errors.amount = that.messages.requiredText;
    }

    if (values.isSeries && !values.duration) {
      errors.duration = that.messages.requiredText;
    }

    return errors;
  },

  validateBankVerificationImages: function (values, props) {
    let errors = {
      files: []
    };
    let that = this;

    if (!values.files || !values.files.length) {
      errors.verificationCount = errorMessages.accountSection.giact.missingVerification;
    } else {
      _.map(values.files, (f, i) => {

        let imgError = {};

        if (!f.clearing) {
          imgError.clearing = that.messages.requiredText;
        }

        errors.files[i] = imgError;

      });
    }
    return errors;
  },

  validateAddOwnerForm: function (values) {
    let errors = {};
    let that = this;
    const requiredFields = ['first_name', 'last_name', 'owner_ssn', 'owner_dob', 'contact_priority', 'percentage_of_ownership', 'contact_type', 'email', 'phone_number', 'address', 'city', 'state', 'zip'];
    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });
    if (values.owner_ssn && !this.validSSNNumber(values.owner_ssn)) {
      errors.owner_ssn = that.messages.invalidSSN;
    }
    if (values.email && !emailValidator.validate(values.email)) {
      errors.email = that.messages.invalidEmail;
    }
    if (values.phone_number && !this.isPhoneNumber(values.phone_number)) {
      errors.phone_number = that.messages.invalidPhoneNumber;
    }
    if ((values.ownership_allocated + parseInt(values.percentage_of_ownership)) > 100) {
      errors.percentage_of_ownership = that.messages.invalidOwnershipPercentage;
    }

    if (values.contact_priority && this.valueExistsInArray(parseInt(values.contact_priority), values.priority_array)) {
      errors.contact_priority = that.messages.invalidContactPriority;
    }

    return errors;
  },

  isURL: function (url) {
    const expression = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
    const regexp = new RegExp(expression);
    return regexp.test(url);
  },

  validateFacebookHandle: function (handle) {
    const expression = /^[-zA-Z0-9.]{5,}$/i;
    const regexp = new RegExp(expression);
    return regexp.test(handle);
  },

  validateTwitterHandle: function (handle) {
    const expression = /^[a-zA-Z0-9_]{1,15}$/i;
    const regexp = new RegExp(expression);
    return regexp.test(handle);
  },

  validateInstagramHandle: function (handle) {
    const expression = /^[a-zA-Z0-9._]{1,30}$/i;
    const regexp = new RegExp(expression);
    return regexp.test(handle);
  },

  validateYelpHandle: function (handle) {
    const expression = /^[-\w\s]+$/i;
    const regexp = new RegExp(expression);
    return regexp.test(handle);
  },

  validateEmailString: function (string) {
    let flag = true;
    let array = string.split(',').map(s => s.trim());
    array.forEach((email) => {
      if (!emailValidator.validate(email)) {
        flag = false;
      }
    });
    return flag;
  },

  validatesFileName: function (name) {
    const regex = RegExp(/^([a-zA-Z0-9\.\-\s,\']){1,50}$/);
    let cleanName = name;
    if (!regex.test(name)) {
      if (name.length > 50) {
        const ext = cleanName.split('.').pop();
        cleanName = `${cleanName.slice(0, 45)}.${ext}`;
      }
      cleanName = cleanName.replace(/[^a-zA-Z0-9\.\-\s,\']/g, '');
    }
    return cleanName
  },

  validateTip: function (value, type) {
    const that = this;
    const amount = value ? value.toString().replace(/%/g, '') : '0';
    const amountMantissa = amount.includes('.') ? amount.split('.')[1] : '0';

    if (!value && type) {
      return that.messages.tipValue;
    }

    if (value && !FormatTextUtil.checkTipType(type)) {
      return that.messages.tipType;
    }

    if (amount.includes('.') && type === '1') {
      return that.messages.amountMustBeInteger;
    }

    if (type && amount && Number(amount) <= 0) {
      return that.messages.amountMustBeGreaterThanZero;
    }

    if (amount && amountMantissa.length > 3) {
      return that.messages.amountMantissaMustBeEqualOrLessThanThreeDigits;
    }
    return false;
  },

  validateRefunds: function (values) {

    const errors = {};

    if (!values.isItemized) {

      if (this.hasNoValue(values['customRefundAmount']) || values['customRefundAmount'] === '$0.00') {
        errors.customRefundAmount = this.messages.amountRequired;
      }

    } else {

      if (values.refundTip && values.originalTip && numeral(values.refundTip).value() > parseFloat(values.originalTip)) {
        errors.refundTip = this.messages.refundTipExceedsOriginal;
      }

    }

    values['lineItems'] && values['lineItems'].length && values['lineItems'].map((item, index) => {

      if (values.isItemized) {
        if (item.refundQuantity > item.originalQuantity) {
          errors.lineItems = errors.lineItems || [];
          errors.lineItems[index] = errors.lineItems[index] || {};

          errors.lineItems[index]['refundQuantity'] = this.messages.refundQuantityExceedsOriginal;
        }
      }

      if (item.restockQuantity > item.availableRestockQuantity) {
        errors.lineItems = errors.lineItems || [];
        errors.lineItems[index] = errors.lineItems[index] || {};

        errors.lineItems[index]['restockQuantity'] = this.messages.refundQuantityExceedsOriginal;
      }

    });

    return errors;

  },

  validateMergeCategories: function (values) {
    const errors = {};

    if (values.selectedCategory === -1) {
      errors.selectedCategory = errorMessages.validator.unSelectedCategory
    }

    if (values.newCategoryName === '') {
      errors.newCategoryName = errorMessages.validator.requiredText
    }

    return errors;
  },

  validateCustomerReviewResponse: function (values) {
    const errors = {};

    if (values.reply === '') {
      errors.reply = errorMessages.validator.requiredText;
    }

    if (values.reply?.length > 350) {
      errors.reply = errorMessages.validator.lengthOfFieldTooLong;
    }

    return errors;
  },

  validateEmailPhoneForm: function (values) {

    const that = this;
    const errors = {};

    if (values.email_addresses && values.email_addresses.length) {

      values.email_addresses.map((email) => {
        if (!emailValidator.validate(email)) {
          errors.email_addresses = that.messages.invalidEmail;
        }
      });

      if (values.email_addresses.length > 5) {
        errors.email_addresses = that.messages.invalidAmountEmail;
      }

    }

    return errors;

  },

  validateScheduleReport: function (values) {
    const errors = {};

    if (!emailValidator.validate(values.email)) {
      errors.email = errorMessages.validator.invalidEmail;
    }

    if (values.additionalEmail && !emailValidator.validate(values.additionalEmail)) {
      errors.additionalEmail = errorMessages.validator.invalidEmail;
    }

    if (values.frequency === '') {
      errors.frequency = errorMessages.validator.unselectedReportType;
    }

    if (values.frequency === 'Monthly' && values.dayOfTheMonth === -1) {
      errors.dayOfTheMonth = errorMessages.validator.unselectedDayOfTheMonth;
    }

    if (values.frequency === 'Weekly' && values.dayOfTheWeek === -1) {
      errors.dayOfTheWeek = errorMessages.validator.unselectedDayOfTheWeek;
    }
    return errors;
  },

  validateCustomReport: function (values) {
    const errors = {};
    if (!values?.reportName) {
      errors.reportName = errorMessages.validator.requiredText;
    }
    return errors;
  },

  validateLoyaltySettingsWebsiteForm: function (values) {
    const errors = {};
    if (values.website && !this.isURL(values.website)) {
      errors.website = errorMessages.validator.invalidURL;
    }
    return errors;
  },

  validateLoginEmail: (emailAddress) => {
    const regex = new RegExp(/^([.+0-9A-Z^_\-a-z])*@[a-zA-Z0-9](-?\.?_?[a-zA-Z0-9])*\.[a-zA-Z]+$/);
    return regex.test(emailAddress);
  },

  validateEnrollInLoyaltyForm: function (values) {
    const errors = {};

    if (this.hasNoValue(values.phone_number)) {
      errors.phone_number = this.messages.requiredText;
    }

    if (values.phone_number && !this.isPhoneNumber(values.phone_number)) {
      errors.phone_number = this.messages.invalidMobileNumber;
    }

    return errors;
  },

  validateLeadSignupForm: function (values) {
    const errors = {};

    if(this.hasNoValue(values.fullName)){
      errors.fullName = this.messages.leadSignupInvalidName;
    }

    if (values?.fullName && (values?.fullName.trim().split(' ').length <= 1)) {
      errors.fullName = this.messages.firstAndLastNameRequired;
    }

    if (this.hasNoValue(values.email) || !emailValidator.validate(values.email)) {
      errors.email = this.messages.leadSignupInvalidEmail;
    }

    if (this.hasNoValue(values.mobileNumber) || !this.isPhoneNumber(values.mobileNumber)) {
      errors.mobileNumber = this.messages.leadSignupInvalidMobile;
    }

    if (this.hasNoValue(values.password) || !this.validPassword(values.password)) {
      errors.password = this.messages.leadSignupInvalidPassword;
    }

    if (this.hasNoValue(values.confirmPassword) || values.password !== values.confirmPassword) {
      errors.confirmPassword = this.messages.leadSignupPasswordsDoNotMatch;
    }

    if (!values.tsAndCsAccepted) {
      errors.tsAndCsAccepted = this.messages.leadSignupAcceptTerms;
    }

    if (!values.privacyPolicyAccepted) {
      errors.privacyPolicyAccepted = this.messages.leadSignupAcceptPrivacyPolicy;
    }

    return errors;
  },

  validateMultiFactorAuthentication: (values) => {
    const errors = {};

    const codeArray = values?.codeArray;
    const isUnfilled = _.isEqual(codeArray, ['','','','','','']);

    if(isUnfilled) {
      errors._error = Validator.messages.requiredText;
    }

    return errors;
  },

  validateOwnersForm: function (values) {
    const errors = {};

    if (!values?.primaryOwner) {
      errors.primaryOwner = this.messages.customerOwnershipPrimary;
    }

    const requiredFields = [
      'fullName',
      'dateOfBirth',
      'email',
      'socialSecurityNumber',
      'phoneNumber',
      'equityOwnershipPercentage',
      'street',
      'city',
      'state',
      'zipCode'
    ];
    let that = this;

    if (values?.owners && values.owners.length > 0) {

      const ownersArrayErrors = [];

      const ownerEquity = CommonUtil.calculateTotal(values.owners, 'equityOwnershipPercentage');

      values.owners.forEach((owner, ownerIndex) => {
        const ownerErrors = {};

        _.map(requiredFields, (field) => {
          if (!owner || this.hasNoValue(owner[field])) {
            ownerErrors[field] = that.messages.requiredText;
          }
        });

        if (!this.hasNoValue(owner?.phoneNumber) && !this.isPhoneNumber(owner.phoneNumber)) {
          ownerErrors.phoneNumber = this.messages.invalidMobileNumber;
        }

        if (owner?.state && owner.state === -1) {
          ownerErrors.state = this.messages.requiredText;
        }

        if (owner?.equityOwnershipPercentage && ownerEquity > 100) {
          ownerErrors.equityOwnershipPercentage = that.messages.customerEquityOwnershipMore;
        }

        if (owner?.equityOwnershipPercentage && ownerEquity < 76) {
          ownerErrors.equityOwnershipPercentage = that.messages.customerEquityOwnershipLess;
        }

        if (owner?.equityOwnershipPercentage === '0') {
          ownerErrors.equityOwnershipPercentage = that.messages.customerEquityOwnershipZero;
        }

        if (owner?.email && !emailValidator.validate(owner?.email)) {
          ownerErrors.email = that.messages.invalidEmail;
        }

        if (owner?.dateOfBirth) {
          if (!that.isDate(owner.dateOfBirth)) {
            ownerErrors.dateOfBirth = that.messages.invalidDate;
          } else if (!that.isOver18(owner.dateOfBirth)) {
            ownerErrors.dateOfBirth = that.messages.invalidUnderage;
          }
        }

        if (owner?.fullName && (owner?.fullName.trim().split(' ').length <= 1)) {
          ownerErrors.fullName = that.messages.firstAndLastNameRequired;
        }

        if (owner?.zipCode && owner?.zipCode.length !== 5) {
          ownerErrors.zipCode = that.messages.zipShouldBeFiveDigits;
        }

        if (owner?.socialSecurityNumber && !that.validFullSSNNumber(owner.socialSecurityNumber)) {
          ownerErrors.socialSecurityNumber = that.messages.invalidSSN;
        }

        if (!!owner?.socialSecurityNumber && that.isConsecutiveSSN(owner.socialSecurityNumber)) {
          ownerErrors.socialSecurityNumber = that.messages.invalidSSNFormat;
        }

        if (!!owner?.socialSecurityNumber && that.isRepetitiveSSN(owner.socialSecurityNumber)) {
          ownerErrors.socialSecurityNumber = that.messages.invalidSSNFormat;
        }

        if (!_.isEmpty(ownerErrors)) {
          ownersArrayErrors[ownerIndex] = ownerErrors;
        }

      });

      if (ownersArrayErrors.length) {
        errors.owners = ownersArrayErrors;
      }

    }

    return errors;
  },

  validateAdvancedSearchForm: function (values) {
    const errors = {};
    const moreFieldsErrors = {};

    if (values.dateRangeFrom && !this.isDate(values.dateRangeFrom)) {
      errors.dateRangeFrom = this.messages.invalidDate;
    }

    if (values.dateRangeTo && !this.isDate(values.dateRangeTo)) {
      errors.dateRangeTo = this.messages.invalidDate;
    }

    if (values.dateRangeFrom && this.isDate(values.dateRangeFrom) && !values.dateRangeTo ||
      values.dateRangeTo && this.isDate(values.dateRangeTo) && !values.dateRangeFrom ||
      values.dateRangeFrom && this.isDate(values.dateRangeFrom) &&
      values.dateRangeTo && this.isDate(values.dateRangeTo) &&
      moment(values.dateRangeFrom).isAfter(moment(values.dateRangeTo))
    ) {
      errors.dateRangeFrom = this.messages.invalidDateRange;
      errors.dateRangeTo = this.messages.invalidDateRange;
    }

    if (values.amountFrom && !values.amountTo ||
      !values.amountFrom && values.amountTo ||
      values.amountFrom && values.amountTo &&
      numeral(values.amountFrom).value() > numeral(values.amountTo).value()
    ) {
      errors.amountFrom = this.messages.invalidAmountRange;
      errors.amountTo = this.messages.invalidAmountRange;
    }

    if (values.moreFields?.tipAmountFrom && !values.moreFields?.tipAmountTo ||
      !values.moreFields?.tipAmountFrom && values.moreFields?.tipAmountTo ||
      values.moreFields?.tipAmountFrom && values.moreFields?.tipAmountTo &&
      numeral(values.moreFields?.tipAmountFrom).value() > numeral(values.moreFields?.tipAmountTo).value()
    ) {
      moreFieldsErrors.tipAmountFrom = this.messages.invalidAmountRange;
      moreFieldsErrors.tipAmountTo = this.messages.invalidAmountRange;
    }

    if (values.moreFields?.authAmountFrom && !values.moreFields?.authAmountTo ||
      !values.moreFields?.authAmountFrom && values.moreFields?.authAmountTo ||
      values.moreFields?.authAmountFrom && values.moreFields?.authAmountTo &&
      numeral(values.moreFields?.authAmountFrom).value() > numeral(values.moreFields?.authAmountTo).value()
    ) {
      moreFieldsErrors.authAmountFrom = this.messages.invalidAmountRange;
      moreFieldsErrors.authAmountTo = this.messages.invalidAmountRange;
    }

    if (!_.isEmpty(moreFieldsErrors)) {
      errors.moreFields = moreFieldsErrors;
    }

    return errors;
  },

  isValidBusinessPercent: function(values) {
    let totalBusinessPercent = 0;

    values.forEach((value) => {
      if (value !== '') {
        totalBusinessPercent += value;
      }
    })

    return totalBusinessPercent === 100;
  },

  validateActivateBusinessForm: function (values) {

    const {
      businessType,
      accountType,
      federalTaxIdNumber,
      accountNumber,
      routingNumber,
      state,
      industryType,
      cardPresent,
      keyed,
      moto,
      internet,
      businessWebsite,
      email,
      phone
    } = values;
    const errors = {};

    const requiredFields = [
      'corporateName',
      'businessType',
      'mcc',
      'dbaName',
      'federalTaxIdNumber',
      'street',
      'city',
      'state',
      'zipCode',
      'accountType',
      'accountNumber',
      'routingNumber',
      'industryType',
      'email',
      'phone'
    ];

    let that = this;
    _.map(requiredFields, (field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = that.messages.requiredText;
      }
    });

    const validBusinessPercent = this.isValidBusinessPercent([cardPresent, keyed, moto, internet])

    if (state && state === -1) {
      errors.state = this.messages.requiredText;
    }

    if (businessType && businessType === -1) {
      errors.businessType = this.messages.requiredText;
    }

    if (accountType && accountType === -1) {
      errors.accountType = this.messages.requiredText;
    }

    if (industryType && industryType === -1) {
      errors.industryType = this.messages.requiredText;
    }

    if (!this.hasNoValue(federalTaxIdNumber) && !this.validTaxId(federalTaxIdNumber)) {
      errors.federalTaxIdNumber = that.messages.invalidTaxId;
    }

    if (!!federalTaxIdNumber && this.isConsecutiveSSN(federalTaxIdNumber)) {
      errors.federalTaxIdNumber = that.messages.invalidTaxId;
    }

    if (!!federalTaxIdNumber && this.isRepetitiveSSN(federalTaxIdNumber)) {
      errors.federalTaxIdNumber = that.messages.invalidTaxId;
    }

    if (accountNumber && !this.validAccountNumber(accountNumber)) {
      errors.accountNumber = that.messages.invalidAccountNumber;
    }

    if (routingNumber && this.validRoutingNumberLength(routingNumber) && (!this.validRoutingNumber(routingNumber) || !this.validRoutingNumberChecksum(routingNumber))) {
      errors.routingNumber = that.messages.abaInvalidNumber;
    }

    if (routingNumber && !this.validRoutingNumberLength(routingNumber)) {
      errors.routingNumber = that.messages.invalidRoutingNumber;
    }

    if (cardPresent !== 0 && this.hasNoValue(cardPresent)) {
      errors.cardPresent = that.messages.requiredText;
    }

    if (keyed !== 0 && this.hasNoValue(keyed)) {
      errors.keyed = that.messages.requiredText;
    }

    if (moto !== 0 && this.hasNoValue(moto)) {
      errors.moto = that.messages.requiredText;
    }

    if (internet !== 0 && this.hasNoValue(internet)) {
      errors.internet = that.messages.requiredText;
    }

    if (!validBusinessPercent) {
      errors.cardPresent = that.messages.invalidPercent;
      errors.keyed = that.messages.invalidPercent;
      errors.moto = that.messages.invalidPercent;
      errors.internet = that.messages.invalidPercent;
    }

    if (industryType === 'Internet' && this.hasNoValue(businessWebsite) ||
      internet > 0 && this.hasNoValue(businessWebsite)) {
        errors.businessWebsite = that.messages.requiredText;
    }

    if (['Retail', 'Restaurant'].includes(industryType)) {
      if (internet > 0 && this.hasNoValue(businessWebsite)) {
        errors.businessWebsite = that.messages.requiredText;
      }
    }

    if (businessWebsite && (!this.isURL(businessWebsite?.toLowerCase()) || !this.validHttps(businessWebsite?.toLowerCase()))) {
      errors.businessWebsite = isNorth() ? that.messages.northInvalidURL : that.messages.invalidURL;
    }

    if (!this.hasNoValue(phone) && (!this.isPhoneNumber(phone))) {
      errors.phone = that.messages.invalidPhoneNumber;
    }

    if (!this.hasNoValue(email) && !emailValidator.validate(email)) {
      errors.email = that.messages.invalidEmail;
    }

    return errors;
  },

  validatePayOrderForm: function(values) {
    const errors = {};

    const requiredFields = ['edate', 'cdigits','cvv'];

    requiredFields.map((field) => {
      if (this.hasNoValue(values[field])) {
        errors[field] = this.messages.requiredText;
      }
    });

    if (!errors.cdigits && !this.ccRegex(values.cdigits.replace(/-/g, ''))) {
      errors.cdigits = this.messages.cardInvalid;
    }

    if (!errors.edate && !this.hasNoValue(values.edate) && !this.expDateRegex(values.edate)) {
      errors.edate = this.messages.expirationDateInvalid;
    }

    if (!errors.cvv && !this.cvvRegex(values.cvv)) {
      errors.cvv = this.messages.securityCodeInvalid;
    }
    return errors;
  },

  validateMapFieldSelector: function(values) {
    const errors = {};

    const requiredFields = [
      'Customer Email Address',
      'Customer Phone Number',
      'Customer First Name',
      'Customer Last Name',
      'Invoice Name',
      'Invoice Number',
      'Description',
      'Payment Amount',
      'Tax Rate',
      'Cash Discounting',
      'Allow customer to add tip',
      'Frequency',
      'Send Date',
      'Last Send Date',
      'Repeat Every',
      'Cadence',
      'Due Date',
      'Payment Method'
    ];

    requiredFields.map((field) => {
      if (Validator.hasNoValue(values[field])) {
        errors[field] = Validator.messages.requiredText;
      }
    });

    return errors;
  }

};

export default Validator;
