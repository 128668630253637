/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, Field } from 'redux-form';
import { Box, Typography, Tooltip } from '@mui/material';
import FileInput from './FileInput';
import CustomTextField from './shared/TextField';
import UploadedFiles from './shared/UploadedFiles';
import IconButton from './shared/IconButton';
import IconUtils from './util/IconUtil';
import Validator from './util/Validator';
import PropTypes from 'prop-types';
import withTranslate from '../hoc/withTranslate';
import { BulkInvoicesDialogStyles } from '../jss/inlineStyles';

export const AttachFilesFormType = {
  DISPUTE: 'DISPUTE',
  GIACT: 'GIACT',
  LOGO: 'LOGO',
  OTHER: 'OTHER'
};

const validate = (values, props) => {
  if (props.displayType === AttachFilesFormType.GIACT) {
    return Validator.validateBankVerificationImages(values, props);
  }
  if (props.displayType === AttachFilesFormType.DISPUTE) {
    return Validator.validateDisputeFile(values);
  }
};


export class AttachFilesFormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null
    };

    this.onDropRejected = this.onDropRejected.bind(this);
    this.onDropAccepted = this.onDropAccepted.bind(this);
  }

  onDropRejected(rejectedFiles) {
    const {t, dropzoneOptions} = this.props;
    let isMultipleFiles, isInvalidFormat, exceedFileLimit, duplicatedFile;

    rejectedFiles.some(({errors}) =>
      errors.some(({code}) => {
        if (code === 'too-many-files') {
          isMultipleFiles = true;
          return true;
        } else if (code === 'file-invalid-type') {
          isInvalidFormat = true;
        } else if (code === 'exceed-file-limit') {
          exceedFileLimit = true;
        } else if (code === 'duplicated-file') {
          duplicatedFile = true;
        }

        return false;
      })
    );

    if (isMultipleFiles) {
      this.setState({errorMessage: t('AttachFilesFormError.MultipleFiles')});
    } else if (isInvalidFormat) {
      const accept = _.values(dropzoneOptions.accept).flat().join(', ');
      this.setState({errorMessage: t('AttachFilesFormError.InvalidFormat', {accept})});
    } else if (exceedFileLimit) {
      const {maxFiles} = dropzoneOptions;
      this.setState({errorMessage: t('AttachFilesFormError.ExceedsFileLimit', {maxFiles})});
    } else if (duplicatedFile) {
      this.setState({errorMessage: t('AttachFilesFormError.DuplicatedFile')});
    } else {
      let {maxSize} = dropzoneOptions;
      maxSize = Math.round(maxSize / 1000000);
      this.setState({errorMessage: t('AttachFilesFormError.ExceedsLimit', {maxSize})});
    }
  }

  onDropAccepted() {
    this.setState({errorMessage: null});
  }

  render() {
    const {handleSubmit, dropzoneOptions, label, insideForm, clearingType, secondaryText, t, validator} = this.props;
    const {errorMessage} = this.state;
    const isGiactFiles = this.props.displayType === AttachFilesFormType.GIACT;
    const isDisputeFiles = this.props.displayType === AttachFilesFormType.DISPUTE;
    const isLogoFile = this.props.displayType === AttachFilesFormType.LOGO;
    const deleteIcon = IconUtils.getIcon('DeleteIcon', '#404143');
    const uploadIcon = IconUtils.getIcon('UploadFile');
    const fileIcon = IconUtils.getIcon('AddFile');

    const renderFiles = ({ fields }) => {

      const allFields = fields.getAll();

      const attachedFilesGiact = allFields ? allFields.map((f, i) => {
        return (
          <Box
            key={i}
            className='fileBox'
          >
            <Box className='fileNameWrapper'>
              {uploadIcon}
              <Tooltip title={f.name} arrow>
                <Typography className='fileName' variant='primaryFontFamilyRegular'>
                  {f.name}
                </Typography>
              </Tooltip>
            </Box>
            <IconButton
              disableTouchRipple
              onClick={() => fields.remove(i)}
              size='large'
            >
              {deleteIcon}
            </IconButton>
          </Box>
        );
      }) : null;

      const attachedFilesNonGiact = !isLogoFile
      ? <div>
          <p className='fileInputLabel'>{t('AttachFilesFormInputLabel')}</p>
          <UploadedFiles items={fields} attachForm={true}/>
        </div>
      : null;

      return (
        <Box sx={BulkInvoicesDialogStyles}>
          <FileInput
            name='dropzone'
            label={label}
            classNameLabel='fileInputLabel'
            className='fileInputWrapper'
            onDropRejected={this.onDropRejected}
            onDropAccepted={this.onDropAccepted}
            {...(validator && {
              validator: (currentFile) => {
                const { dropzoneOptions } = this.props;
                let fieldExist = false;
                let allFields = fields.getAll();

                if (!!allFields?.length) {
                  if (allFields?.length === dropzoneOptions.maxFiles) {
                    return {
                      code: 'exceed-file-limit',
                    };
                  }

                  fieldExist = allFields.some(
                    (field) =>
                      field.name === currentFile.name &&
                      field.file.size === currentFile.size
                  );
                }

                if (fieldExist) {
                  return {
                    code: 'duplicated-file',
                  };
                }

                return null;
              },
            })}
            onDropFunction={(acceptedFiles) => {
              acceptedFiles.forEach(f => {
                fields.push({
                  name: isDisputeFiles ? Validator.validatesFileName(f.name) : f.name,
                  file: f,
                  description: '',
                  clearing: isGiactFiles ? clearingType : ''
                });
              });
              if(isLogoFile) {
                this.props.logoSetState(acceptedFiles);
              }
            }}
            dropzoneOptions={dropzoneOptions}
          >
            <Box alignItems='center'
                  display='flex'
                  flexDirection='column'
                  gap='10px'
                  justifyContent='center'
            >
              {fileIcon}
              <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
                gap='5px'
              >
                <Box display='flex'
                      flexDirection='row'
                      gap='3px'>
                  <Typography
                    component={'span'}
                    className='dropLabel bold'
                  >
                    {t('DropFile')}
                  </Typography>
                  <Typography
                    component={'span'}
                    className='dropLabel bold brandColor'
                  >
                    {t('ChooseFile')}
                  </Typography>
                </Box>
                {secondaryText &&
                  <Typography
                    component={'span'}
                    className='dropLabel secondaryText'
                  >
                    {secondaryText}
                  </Typography>
                }
              </Box>
            </Box>
          </FileInput>

          {isDisputeFiles &&
            <p className='acceptedFiles'>{t('AcceptedFiles')}.</p>
          }
          {errorMessage &&
          <p className='errorMsg'>{errorMessage}</p>}

          {allFields
            ? isGiactFiles
              ? attachedFilesGiact
              : attachedFilesNonGiact
            : null
          }
        </Box>
      );
    };

    const fieldItem = <div>
      <Field component={({meta}) =>
        <div>{meta.error && meta.submitFailed &&
        <span className='errorText'>{t(meta.error)}<br/><br/></span>}</div>
      } name='verificationCount' className={isGiactFiles ? '' : 'ErrorMessageField'}/>

      <FieldArray name='files' component={renderFiles}/>

      {isDisputeFiles ?
        <div>
          <p className='fileInputLabel'>{t('DisputesFilesInputLabel')}</p>
          <Field
            component={CustomTextField}
            fullWidth={true}
            maxLength='1000'
            name='comment'
          />
        </div> : null}
    </div>;

    return (insideForm && !isGiactFiles)
      ? <div className='attachFilesForm'>
          {fieldItem}
        </div>
      : <form onSubmit={handleSubmit} className='attachFilesForm'>
          {fieldItem}
        </form>;
  }

}

export const AttachFilesFormContainer = reduxForm({
  form: 'attachFilesForm',
  validate,
  fields: ['files', 'comment']
})(AttachFilesFormComponent);

export function mapStateToProps(state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(withTranslate(AttachFilesFormContainer));

AttachFilesFormComponent.propTypes = {
  displayType: PropTypes.oneOf([
    AttachFilesFormType.DISPUTE,
    AttachFilesFormType.GIACT,
    AttachFilesFormType.LOGO,
    AttachFilesFormType.OTHER,
  ]).isRequired
};
