/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import generic from './labels/generic';
import north from './labels/north';
import paymenthubs from './labels/paymenthubs';
import payanywhere from './labels/payanywhere';
import phoneswipe from './labels/phoneswipe';

const fileImportStyles = [
  // Add specific files imports in the same order as main.scss
  generic,
  north,
  paymenthubs,
  payanywhere,
  phoneswipe
];

const bodyStyles = (theme) => ({
  '*, *::before, *::after': {
    boxSizing: 'initial'
  },
  ...fileImportStyles.reduce((acc, fileImport) => ({
    ...acc,
    ...fileImport(theme)
  }), {}),
  body: {
    backgroundColor: theme.palette.common.backgroundColor,
    color: theme.palette.text.primary,
    fontSize: '12px',
    height: '100%',
    margin: '0',
    padding: '0 !important',
    overflowX: 'hidden !important',
    '&.noScroll': {
      overflowY: 'hidden !important',
    },
    '&.hideElevio #_elev_io': {
      display: 'none !important'
    },
  },
  'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
    transition: 'all 5000s ease-in-out 0s',
    transitionProperty: 'background-color, color',
    WebkitBoxShadow: '0 0 0 1000px white inset',
  },
  'a': {
    outline: 0
  },
  '.floatRight': {
    float: 'right',
    textAlign: 'right',
  },
  '.textRight': {
    textAlign: 'right',
  },
  '.textLeft': {
    textAlign: 'left',
  },
  '.noTextTransform': {
    textTransform: 'none',
  },
  '.hideComponent': {
    display: 'none !important',
  },
  '.hideComponentMobile': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      display: 'none',
    },
  },
  '.verticallyCenteredContainer': {
    display: 'flex',
    alignItems: 'center',
  },
  '.hideTablet': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
      display: 'none !important',
    },
  },
  '.isGrayIcon': {
    fill: theme.palette.common.iconColor,
    color: theme.palette.common.iconColor,
  },
  '.isPrimaryColor': {
    fill: theme.palette.labelColor.main,
    color: theme.palette.labelColor.main,
  },
  '.isSecondaryColor': {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  '.updateSpinner': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.541176)',
    transform: 'translateZ(0px)',
    transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    zIndex: 1501,
  },
  '.formModal': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    opacity: 1,
    backgroundColor: theme.palette.action.active,
    transform: 'translateZ(0px)',
    transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    zIndex: 1501,
    '& .holder': {
      position: 'relative',
    },
    '& .closeIcon': {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    '& .background': {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      borderRadius: 10,
      backgroundColor: theme.palette.common.white,
      maxWidth: 575,
      maxHeight: 485,
      padding: 55,
      '& .title': {
        ...theme.sharedStyles.subheading(theme),
        marginBottom: 18,
        textAlign: 'center',
      },
      '& .content': {},
      '& .buttons': {
        marginTop: 'auto',
        alignSelf: 'flex-end',
      },
    },
  },
  '.pageWrap': {
    clear: 'both',
    margin: '0 auto',
    position: 'relative',
    '& .flexContainer': {
      display: 'flex',
    },
    '& .masterInfinitListContainer': {
      flexBasis: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '& div.masterListContainer': {
      flexBasis: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      '&.pageScrollableArea': {
        overflowY: 'hidden'
      },
      '& h3': {
        margin: '2px 0',
        fontWeight: 'normal',
      },
      '& .columnSubHead': {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
          fontSize: '12px !important',
        },
      },
    },
  },
  'infiniteList': {
    overflowY: 'hidden',
    '& .transactionTitlePanel': {
      width: '100%',
      '& .titlePanel': {
        '& .titlePanelDetails': {
          '& .titleText': {
            width: '60%',
          },
        },
        '& .titlePanelDetailsText': {
          paddingRight: '20px',
        },
      },
    },
  },
  '.selectedListRow td': {
    backgroundColor: `${theme.palette.common.selectedRow} !important`,
  },
  '.flexRow': {
    display: 'flex',
    flexDirection: 'row',
    '&.flexEnd': {
      alignItems: 'flex-end',
    },
  },
  '.flexColumnMiddle': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.none': {
    listStyle: 'none outside none',
    margin: 0,
    padding: 0,
  },
  'nav ul li a': {
    paddingTop: 0,
  },
  '#detail': {
    'h2': {
      fontSize: '44px',
      fontWeight: 200,
    },
  },
  '.confirmDialog': {
    zIndex: '2500 !important',
    'h3': {
      fontWeight: 'bold !important',
      fontFamily: `${theme.typography.inter} !important`,
    },
    'p': {
      color: '#404143',
      fontFamily: `${theme.typography.inter} !important`,
      fontWeight: '500',
      fontSize: '13px !important',
    },
  },
  '.formHeading': {
    ...theme.sharedStyles.contentLabel(theme),
  },
  '.primaryText': {
    color: theme.palette.text.primary,
  },
  '.secondaryText': {
    color: theme.palette.text.secondary,
  },
  '.positive': {
    color: `${theme.palette.common.positiveColor} !important`,
  },
  '.negative': {
    color: `${theme.palette.common.negativeColor} !important`,
  },
  '.bold': {
    fontWeight: 800,
  },
  '.portalCheckbox, .portalCheckbox.disabledCheckbox': {
    'label': {
      fontSize: '16px',
    },

  },
  '.portalCheckbox.disabledCheckbox': {
    'input': {
      cursor: 'not-allowed !important',
    },
    'svg + svg': {
      fill: `${theme.palette.labelColor.main} !important`,
      stroke: 'none !important',
      color: `${theme.palette.labelColor.main} !important`,
    },
    'svg': {
      fill: `${theme.palette.common.white} !important`,
      stroke: `${theme.palette.common.gray} !important`,
      color: `${theme.palette.common.gray} !important`,
    },
    'label': {
      fontFamily: `${theme.typography.primaryFontFamily} !important`,
    },
  },
  '.portalCheckbox': {
    marginBottom: '10px',
    marginTop: '10px',

    'svg + svg': {
      fill: `${theme.palette.labelColor.main} !important`,
      stroke: 'none !important',
      color: `${theme.palette.labelColor.main} !important`,
    },
    'svg': {
      fill: `${theme.palette.common.white} !important`,
      stroke: `${theme.palette.common.gray} !important`,
      color: `${theme.palette.common.gray} !important`,
    },
  },
  'fieldset': {
    border: 'none',
    padding: '5px 0',
    '& legend': {
      color: theme.palette.common.regularColor,
    },
    '& .title': {
      padding: '10px 0',
      float: 'left',
      fontSize: '16px',
    },
    '& .value': {
      float: 'right',
    },
  },
  '.profileDialog div': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      width: '100% !important',
    },
  },
  '.dialogHeader': {
    color: theme.palette.text.secondary + ' !important',
    fontFamily: theme.typography.primaryFontFamilySemiBold,
    borderBottom: 'none !important',
    textAlign: 'center',
  },
  '.tiersDialogTitle': {
    fontFamily: theme.typography.secondaryFontFamily,
    borderBottom: 'none !important',
    textAlign: 'left',
    marginLeft: '50px !important',
    padding: '24px 24px 0 !important',
    '& svg': {
      marginTop: '30px',
      width: '70px !important',
      height: '70px !important',
    },
  },
  '.tiersUpgradedPlanDialogContent': {
    padding: '0 35px 5px !important',
    color: theme.palette.text.primary,
  },
  '.addButtonIcon': {}, // Empty object for the nested class
  '.addButton': {
    backgroundColor: theme.palette.labelColor.main,
    '& $addButtonIcon': {
      fill: theme.palette.common.backgroundColor,
      color: theme.palette.common.backgroundColor,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.regularColor,
    },
  },
  'div.searchAccessory button.disabledButton': {
    backgroundColor: theme.palette.common.gray,
  },
  '.addButton:hover': {
    backgroundColor: theme.palette.common.regularColor,
  },
  '.reportsNoDataMessage': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      width: '100% !important',
      height: '100vw !important',
      padding: '0 !important',
      marginTop: '30px !important',
    },
  },
  '.noDataMessage': {
    ...theme.sharedStyles.bodyText(theme),
    whiteSpace: 'normal !important',
    fontFamily: theme.typography.primaryFontFamily,
    fontSize: '16px !important',
    color: theme.palette.text.primary,
    textAlign: 'center !important',
    width: '80%',
    height: 'calc(100vh - 1020px)',
    padding: '5%',
    '& .noDataHeader': {
      ...theme.typography.heading1,
      textAlign: 'left',
      paddingLeft: '40px',
      paddingTop: '20px',
    },
    '& .noDataText': {
      color: theme.palette.common.phoneSwipeColorAlternativeColor + ' !important',
      fontSize: '14px',
      paddingLeft: '40px',
      marginRight: '60px',
      textAlign: 'left',
    },
    '& .noDataLink': {
      textAlign: 'left',
      paddingLeft: '40px',
      paddingTop: '90px',
    },
    '& .iconContainer': {
      '& .icon': {
        backgroundColor: theme.palette.common.subNavBackgroundColor,
        '& svg': {
          fill: theme.palette.common.iconColor + ' !important',
        },
      },
      '& .itemsIcon': {
        width: '24px',
        height: '24px',
      },
    },
  },
  '.freeMembershipScreen': {
    width: '100%',
    justifyContent: 'center',
    marginBottom: '2em',
    '& .biggerIconContainer': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      paddingTop: '4%',
      '& .icon': {
        backgroundColor: theme.palette.common.subNavBackgroundColor,
        height: '120px',
        width: '120px',
        borderRadius: '50%',
        '& svg': {
          fill: theme.palette.common.iconColor + ' !important',
          width: '90px !important',
          height: '90px !important',
          padding: '12% !important',
        },
      },
    },
    '& .descriptionTitle h1': {
      textAlign: 'center !important',
      paddingTop: '0.5%',
      width: '100%',
      ...theme.sharedStyles.heading(theme),
      margin: '1em 0',
    },
    '& .descriptionContent': {
      maxWidth: '450px',
      textAlign: 'center',
      ...theme.sharedStyles.bodyText(theme),
      margin: '2em auto',
    },
  },
  '.noDataRow': {
    borderBottom: 'none !important',
  },
  '.selectedListRow': {
    backgroundColor: theme.palette.common.selectedRow,
    color: theme.palette.common.white,
    fontWeight: 800,
    'svg': {
      paddingLeft: '10px',
    },
  },
  '.exportButton, .exportButtonHeader, .exportButtonContainer': {
    marginRight: '10px',
    display: 'inline-flex',
    'svg': {
      ...theme.sharedStyles.flip,
    },
  },
  '.exportButtonsContainer': {
    display: 'flex',
  },
  '.dateFilter, .employeeFilter, .reportNavIcon, .importCsvButton, .openBatchButton': {
    '& svg': {
      fill: `${theme.palette.text.secondary} !important`,
    },
  },
  '.errorText': {
    color: `${theme.palette.common.errorColor}`,
  },
  '.alignBottom': {
    verticalAlign: 'bottom',
  },
  '.alignField': {
    height: '72px !important',
  },
  '.registerMbpUserForm, .updatePasswordForm, .validTokenContainer, .registerMbpUserForm, .resetLoginForm': {
    '& .alignBottom': {
      marginTop: '15px',
      verticalAlign: 'bottom',
    },
  },
  '.resetLoginForm': {
    '& .ssnTax': {
      '& .textFieldInfo': {
        minHeight: '32px',
      },
    },
  },
  '.ssnTax': {
    display: 'flex',
    flexDirection: 'row',
  },
  '.ssnTaxDivider': {
    color: `${theme.palette.labelColor.main} !important`,
    padding: '7px !important',
    fontWeight: 'bold',
  },
  '.infoIcon': {
    position: 'relative',
    top: '40px',
    left: '480px',
    zIndex: 1,
  },
  '.popover': {
    '& div': {
      display: 'inherit !important',
      position: 'relative !important',
      top: '5px !important',
      left: '510px !important',
    },
  },
  '.description': {
    display: 'inherit !important',
    position: 'relative !important',
    fontSize: '12px',
    textAlign: 'center',
    verticalAlign: 'center',
    color: `${theme.palette.text.secondary}`,
    whiteSpace: 'normal',
    lineHeight: '20px',
    width: '390px',
  },
  'input.hiddenSubmit': {
    visibility: 'hidden',
    height: '1px',
    width: '1px',
    padding: 0,
    margin: 0,
    border: 'none',
    position: 'absolute',
  },
  '.displayNone': {
    display: 'none !important',
  },
  '._elevio_tooltip': {
    '& button': {
      height: '16px !important',
    },
    '& ._elevio_pulse': {
      '& button': {
        background: `${theme.palette.common.elevioHotSpot} !important`,
      },
      '& > div': {
        borderTopColor: `${theme.palette.common.elevioHotSpot} !important`,
        borderRightColor: `${theme.palette.common.elevioHotSpot} !important`,
        borderLeftColor: `${theme.palette.common.elevioHotSpot} !important`,
        borderBottomColor: `${theme.palette.common.elevioHotSpot} !important`,
      },
    },
  },
  '.buttonInverse': {
    ...theme.typography.secondaryFontFamilySemiBold,
    height: '35px !important',
    maxWidth: '134px',
    borderRadius: '3px',
    color: `${theme.palette.labelColor.main} !important`,
    fontSize: '13.5px',
    textTransform: 'uppercase',
    backgroundColor: `${theme.palette.common.backgroundColor} !important`,
    border: `1px solid ${theme.palette.common.backgroundColor} !important`,
    cursor: 'pointer',
  },
  '.itemsTabs': {
    '& button': {
      ...theme.typography.heading2,
      maxWidth: '100% !important',
    },
  },
  '.roundBorder': {
    borderRadius: '50%',
  },
  '.underline': {
    textDecoration: 'underline',
  },
  '.strongUnderlined': {
    '& strong': {
      textDecoration: 'underline',
    },
  },
  '.disabledSubmitButton': {
    opacity: 0.5,
    cursor: 'not-allowed !important',
  },
  '.autoSizeSubmitButton': {
    maxWidth: '100% !important',
  },
  '.hideMe': {
    display: 'none !important',
  },
  '.resetHeader, .niceHeader': {
    ...theme.sharedStyles.heading(theme),
    ...theme.typography.secondaryFontFamilyBold,
    margin: '30px 0 40px',
  },
  '.niceText': {
    fontSize: '25px',
    '&Small': {
      fontSize: '18px',
    },
  },
  '.loadingIndicatorContainer': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '35px 0',
  },
  '.itemThumbnail': {
    '& img.itemThumb': {
      width: '50px',
      height: '50px',
      objectFit: 'cover',
    },
  },
  '.listItem': {
    ...theme.sharedStyles.bodyText(theme),
  },
  '.reCaptchaContainer': {
    '& .errorText': {
      fontSize: '12px !important',
    },
  },
  '.niceLink': {
    marginTop: '5px',
    '& a': {
      textDecoration: 'none',
      color: `${theme.palette.labelColor.main} !important`,
      fontFamily: `${theme.typography.secondaryFontFamily}`,
    },
  },
  '.niceContainer': {
    margin: '0 auto',
    width: `${theme.measures.centeredLoginContentWidth}`,
  },
  '.centeredText': {
    textAlign: 'center',
  },
  '.updatePassword, .updateEmail, .updateTwoFactor, .updateProfile, .showHide, .linkLike, .relatedTransactions, .membershipButton, .toastrButton, .updateLanguage, .updateManageRememberedDevices': {
    ...theme.sharedStyles.link(theme),
    display: 'inline-block',
  },
  '.link': {
    color: `${theme.palette.labelColor.main} !important`,
    fontSize: '10px',
    textDecoration: 'none',
  },
  '.updateLanguage': {
    display: 'flex',
    alignItems: 'center',
    '.languageSelector': {
      marginLeft: '10px',
      '.textField': {
        padding: 0,
        margin: 0,
      },
      '.textFieldInfo': {
        display: 'none',
      },
    },
  },
  '.toastrButton': {
    paddingRight: '20px',
    fontSize: '12px !important',
  },
  '.linkLikeDisabled': {
    ...theme.sharedStyles.disabledLink,
    display: 'inline-block',
  },
  '.updateOwnerButton, .deleteOwnerButton': {
    backgroundColor: `${theme.palette.common.white} !important`,
    position: 'relative',
  },
  '.endSeriesButton, .linkButton, .markInvoicePaidButton, .removeAutopayButton, .removeCardButton, .resendInvoiceButton, .viewInvoiceSeriesButton, .viewSeriesButton': {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    '& span': {
      ...theme.typography.heading2,
      color: theme.palette.labelColor.main,
      padding: 0,
    },
  },
  '.viewOrderSummaryButton': {
    marginTop: '15px !important',
    maxWidth: '200px',
  },
  '.resendInvoiceButton, .sendInvoiceButton': {
    top: '-9px',
  },
  '.customDateFilter, .customTimeFilter': {
    padding: '30px 0',
    minWidth: '250px',
    '& .timeField': {
      [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
        width: '120px !important',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMaxPortrait)]: {
      flexDirection: 'column',
      '& .textField': {
        width: '100% !important',
      },
      '& .customTimeText': {
        padding: 0,
      },
    },
  },
  '.customDateFilter': {
    '& .customDateError': {
      color: theme.palette.common.errorColor,
    },
  },
  '.customTimeText': {
    paddingLeft: '25px',
    '& span': {
      fontWeight: 'bold',
    },
  },
  'input::-webkit-contacts-auto-fill-button': {
    visibility: 'hidden',
    display: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
  },
  '.topBottomPadding': {
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  '.noBottomBorder': {
    borderBottom: 'none !important',
  },
  '.doubleBottomBorder': {
    borderBottom: `4px solid ${theme.palette.common.lineColor} !important`,
  },
  '.doubleTopBorder': {
    borderTop: `4px solid ${theme.palette.common.lineColor} !important`,
    boxShadow: `0 3px 0 0 white, 0 4px 0 0 ${theme.palette.common.lineColor}`,
  },
  '.noScroll': {
    overflow: 'hidden !important',
    position: 'relative',
    height: '100%',
  },
  '.counterText': {
    padding: '0 10px',
    margin: '0 5px',
    fontFamily: `${theme.typography.primaryFontFamily} !important`,
    fontSize: '16px !important',
    color: `${theme.palette.text.primary} !important`,
  },
  '.outOfStockContainer': {
    fontFamily: `${theme.typography.primaryFontFamily} !important`,
    fontSize: '16px !important',
  },
  '.paymentTabs': {
    '& > div:first-of-type': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
    '& .paymentTabsContainer': {
      padding: '10px',
      backgroundColor: theme.palette.common.subNavBackgroundColor,
      '& .payInvoiceFormContainer': {
        '& .orderFormContainer': {
          '& .cardImage': {
            '& img': {
              marginBottom: '10px',
              marginLeft: '10px',
              backgroundColor: theme.palette.common.backgroundColor,
            },
            '& img.blankCard': {
              backgroundColor: 'inherit !important',
            },
          },
        },
      },
    },
    '& .paymentTab': {
      height: 'auto !important',
      maxWidth: '100% !important',
      color: 'rgb(136, 140, 141) !important',
    },
  },
  '.infiniteListColumn': {
    borderBottom: `1px solid ${theme.palette.common.lineColor} !important`,
    margin: '0 !important',
    '&:hover': {
      backgroundColor: `${theme.palette.common.selectedRow} !important`,
    },
  },
  '.transactionTitlePanel': {
    borderBottom: `1px solid ${theme.palette.common.lineColor}`,
  },
  '.ReactVirtualized__Collection': {},
  '.ReactVirtualized__Collection__innerScrollContainer': {},
  '.ReactVirtualized__Grid': {},
  '.ReactVirtualized__Grid__innerScrollContainer': {},
  '.ReactVirtualized__Table': {},
  '.ReactVirtualized__Table__Grid': {},
  '.ReactVirtualized__Table__headerRow': {
    fontWeight: 700,
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '.ReactVirtualized__Table__row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '.ReactVirtualized__Table__headerTruncatedText': {
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '.ReactVirtualized__Table__headerColumn': {
    marginRight: '10px',
    minWidth: '0px',
  },
  '.ReactVirtualized__Table__rowColumn': {
    marginRight: '10px',
    minWidth: '0px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '.ReactVirtualized__Table__headerColumn:first-of-type': {
    marginLeft: '10px',
  },
  '.ReactVirtualized__Table__rowColumn:first-of-type': {
    marginLeft: '10px',
  },
  '.ReactVirtualized__Table__sortableHeaderColumn': {
    cursor: 'pointer',
  },
  '.ReactVirtualized__Table__sortableHeaderIconContainer': {
    display: 'flex',
    alignItems: 'center',
  },
  '.ReactVirtualized__Table__sortableHeaderIcon': {
    flex: '0 0 24px',
    height: '1em',
    width: '1em',
    fill: 'currentColor',
  },
  '.ReactVirtualized__List': {},
  '.contactFooter': {
    fontFamily: theme.typography.primaryFontFamilySemiBold,
    margin: '50px 0',
    '& p': {
      textAlign: 'right',
      paddingRight: '50px',
      color: theme.palette.text.secondary,
      '& span': {
        fontWeight: 'bold',
      },
      '& a': {
        textDecoration: 'underline',
        color: theme.palette.text.secondary,
      },
    },
    '& .divider': {
      display: 'inline-block',
      margin: '0 5px',
      color: `${theme.palette.text.secondary} !important`,
    },
  },
  '.messageRow': {
    marginTop: '10px',
  },
  '.enrolled': {
    marginTop: '30px',
    '&Icon, &Data': {
      display: 'inline-block',
    },
    '&Icon': {
      verticalAlign: 'top',
    },
    '&Data': {
      listStyle: 'none',
      margin: 0,
    },
    '&Item': {
      fontSize: '17px',
      paddingBottom: '10px',
    },
    '.cardData': {
      paddingBottom: '20px',
    },
    '.loadingIndicator': {
      textAlign: 'center !important',
    },
    '.loader': {
      margin: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      top: '35%',
      left: '50%',
      height: '400px',
      transform: 'translate(-50%, 0)',
    },
  },
  '#_elev_io': {
    'button': {
      borderRadius: '3px !important',
      bottom: 'auto !important',
      height: 'auto !important',
    },
  },
  '.linkButton': {
    color: `${theme.palette.common.backgroundColor} !important`,
    '& svg': {
      fill: `${theme.palette.common.backgroundColor} !important`,
    },
    '&:hover': {
      color: `${theme.palette.common.backgroundColor}`,
      '& svg': {
        fill: `${theme.palette.common.backgroundColor}`,
      },
    },
  },
  'button:disabled, button[disabled]': {
    cursor: 'not-allowed !important',
  },
  '.rowForm': {
    paddingTop: '15px',
  },
  '.accessDialogBody': {
    textAlign: 'center',
  },
  '.midSelector': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 10px 40px',
    '& ul': {
      listStyle: 'none',
      padding: 0,
    },
    '& .row': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '16px',
      color: theme.palette.text.secondary,
      marginBottom: '7px',
      '&:last-child': {
        marginBottom: 0,
      },
      '& .icon': {
        marginRight: '10px',
      },
      '& span': {
        '& svg': {
          fill: `${theme.palette.text.secondary} !important`,
        },
      },
    },
    '& .active': {
      color: theme.palette.labelColor.main,
      '& span': {
        '& svg': {
          fill: `${theme.palette.labelColor.main} !important`,
        },
      },
    },
    '& .closed': {
      textDecoration: 'line-through',
    },
  },
  '.resetErrorMsg': {
    fontSize: '22px',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    paddingLeft: '20.5%',
  },
  '.forceReset': {
    '& .holder': {
      display: 'flex',
      justifyContent: 'center',
      '& .formWrapper': {
        width: '60%',
        '& .secondaryText': {
          fontSize: '18px',
          color: theme.palette.text.primary,
          fontWeight: 'bold',
        },
        '& .buttonHolder': {
          display: 'flex',
          justifyContent: 'flex-end',
        },
      },
      '& .successNotice': {
        flexDirection: 'column',
        '& p': {
          fontSize: '18px',
          color: theme.palette.text.primary,
          fontWeight: 'bold',
        },
      },
    },
  },
  '.errorMsgText': {
    whiteSpace: 'pre-wrap',
  },
  '.hideMobile': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
      display: 'none !important',
    },
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      display: 'none !important',
    },
  },
  '.hideMobileOnly': {
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      display: 'none !important',
    },
  },
  '.flexRowIndented': {
    '& > div:first-of-type': {
      transform: 'translateX(15px)',
    },
  },
  '.Dialog': {
    '& h3': {
      ...theme.sharedStyles.subheading(theme),
    },
    '& span': {
      ...theme.sharedStyles.bodyText(theme),
    },
    '& > div': {
      '& > div': {
        '& > div': {
          borderRadius: '10px !important',
          '& > div': {
            ...theme.sharedStyles.bodyText(theme),
            borderBottom: 'none !important',
            borderTop: 'none !important',
          },
        },
      },
    },
  },
  '.customTooltip': {
    color: '#FFFFFF',
    fontFamily: theme.typography.primaryFontFamilyMedium,
    fontSize: '12px !important',
    fontWeight: '100 !important',
    borderRadius: '5px',
    opacity: '1 !important',
  },
  '.customerCell': {
    alignItems: 'center',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingTop: '15px',
    position: 'relative',
    right: '20px',
  },
  '.roleWindowButton': {
    position: 'absolute !important',
    right: '40px',
    zIndex: '10',
    top: '5px',
  },
  '.tableRow td': {
    textTransform: 'none !important',
  },
  '[role=\'menuitem\']': {
    fontFamily: `${theme.typography.primaryFontFamilyMedium} !important`,
    fontSize: '16px !important',
    lineHeight: '48px !important',
  },
  'body > div > div[data-reactroot]': {
    borderRadius: '10px !important',
  },
  '.activitySales, .activityTransactions, .pageWrap, .account': {
    height: '100vh',
  },
  '.mobileScrollNote': {
    ...theme.sharedStyles.contentLabel(theme),
    padding: '8px 5px',
    display: 'none',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .scrollNoteIcon': {
      width: '15px',
      height: '15px',
      paddingLeft: '4px',
      '& svg': {
        width: '100%',
      },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
      display: 'flex',
    },
  },
  '.modal': {
    fontFamily: theme.typography.primaryFontFamilyMedium,
    '& .MuiDialog-paper': {
      padding: '24px',
      borderRadius: '16px',
      overflowY: 'auto',
    },
    '& .close-icon': {
      position: 'absolute',
      top: '16px',
      right: '16px',
      cursor: 'pointer',
    },
    '& .title': {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px 12px',
      '& .title-icon': {
        marginRight: '8px',
      },
      '& .title-label': {
        ...theme.sharedStyles.subheading(theme),
      },
      '& .center': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& .subtitle-label': {
        marginTop: '10px',
        ...theme.sharedStyles.bodyText(theme),
      },
    },
    '&.assistanceDialog .title': {
      marginLeft: '37px',
    },
    '&.jsSdkFormDialog .subtitle-label span': {
      color: theme.palette.common.errorColor,
      textTransform: 'uppercase',
    },
    '& .content': {
      padding: '0 12px 24px',
      ...theme.sharedStyles.bodyText(theme),
    },
    '& .formCopyLink': {
      display: 'flex',
    },
  },
  '.modal .pciFaq .content': {
    padding: '0 24px 24px 32px',
  },
  '.modal .content': {
    '.invoiceActivityItem': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.invoiceActivityItemCreatedBy': {
        display: 'block',
      },
      '.invoiceActivityItemCreatedDate': {
        marginLeft: '30px',
      },
      '.invoiceActivityItemComment': {
        marginTop: '5px',
        'p': {
          margin: 0,
          display: 'inline-block',
          maxWidth: '340px',
          minWidth: '180px',
        },
      },
    },
    '.invoiceActivityListModal': {
      padding: '10px 20px 20px',
      maxHeight: '480px',
      overflowY: 'scroll',
      '.invoiceActivityItem': {
        borderBottom: `1px solid ${theme.palette.common.lineColor}`,
        paddingBottom: '20px',
        marginTop: '25px',
        '&:last-of-type': {
          borderBottom: 'none',
          paddingBottom: 0,
        },
      },
    },
    '.editPaymentAmountDialogContent': {
      '.originalAmountField .textFieldHolder': {
        backgroundColor: theme.palette.common.white,
        borderColor: `${theme.palette.common.disabledGray}`,
        'input': {
          opacity: 1,
          color: `${theme.palette.common.black}`,
          WebkitTextFillColor: `${theme.palette.common.black}`,
        },
      },
      '.durationField .textFieldHolder': {
        height: '48px',
      },
    },
  },
  '.drawer': {
    '& .MuiDrawer-paper': {
      width: '750px !important',
      maxWidth: '100% !important',
    },
    '& .close-icon': {
      position: 'absolute',
      top: '16px',
      left: '30px',
      cursor: 'pointer',
    },
    '& .content': {
      padding: '30px 0 30px 0',
      '& .drawerContent': {
        padding: '45px 80px 0',
        [theme.breakpoints.down(theme.breakpoints.values.sizeMobileMax)]: {
          padding: '45px 35px 0',
        },
      },
    },
    '& .footer': {
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      bottom: 0,
      boxShadow: '0 -10px 25px -20px #777',
      boxSizing: 'border-box',
      display: 'flex',
      height: '100px',
      justifyContent: 'flex-end',
      padding: '15px 30px',
      position: 'fixed',
      width: '750px',
    },
  },
  '.menuButton': {
    '& .menuIconButton': {
      backgroundColor: 'transparent !important',
      border: '0 !important',
      '& .content': {
        display: 'flex',
        width: '100px',
        alignItems: 'center',
        '& .icon svg': {
          width: '18px',
          height: '18px',
        },
        '& .label': {
          ...theme.sharedStyles.bodyText(theme),
          color: `${theme.palette.labelColor.main} !important`,
          fontWeight: 'normal',
          paddingTop: '3px',
          paddingLeft: '10px',
          position: 'absolute',
          left: '30px',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  '.customPicker .textFieldHolder > div > div > div': {
    width: 'auto !important',
  },
  '.cardNumber.textField': {
    '& .textFieldHolder input': {
      fontFamily: 'text-security-disc',
    },
    '&.visible .textFieldHolder input': {
      fontFamily: 'inherit',
    },
  },
  '.bottomBarContainer': {
    height: '50px',
    position: 'fixed',
    display: 'flex',
    bottom: '0px',
    right: '0px',
    left: '0px',
    justifyContent: 'space-between',
    background: theme.palette.common.white,
    padding: '40px 40px 40px 100px',
    boxShadow: '0px 0px 10px 0px #a6a6a6',
    [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
      height: 'auto',
      display: 'block',
      padding: '10px 0px 10px 0px',
    },
    '& >:first-of-type': {
      display: 'flex',
      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
        width: '100%',
        marginBottom: '10px',
      },
    },
    '& >:last-child': {
      display: 'flex',
      [theme.breakpoints.down(theme.breakpoints.values.sizeTabletMax)]: {
        display: 'block',
        width: '100%',
      },
    },
  },
  'input[type="text"]:disabled': {
    opacity: '0.5',
  },
  '.paginationWrapper': {
    padding: '20px 0',
    height: '32px',
    width: '100%',
    '& > nav': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '.paddingTop20': {
    paddingTop: '20px',
  },
  '.redux-toastr': {
    '.draftInvoiceNotification': {
      '.rrt-holder': {
        '.InvoiceDraftIcon': {
          background: 'unset !important',
          borderRadius: 'unset !important'
        }
      }
    }
  },
  '.davoPortalLink': {
    ...theme.sharedStyles.link(theme),
    alignItems: 'center',
    color: theme.palette.labelColor.main,
    display: 'inline-flex',
    fontSize: '12px',

    'svg': {
      ml: '5px'
    }
  },
  '.archiveItemDescription': {
    'div a': {
      color: theme.palette.labelColor.main
    }

  },
  '.MuiMultiSectionDigitalClockSection-root': {
    'msOverflowStyle': 'none',
    'scrollbarWidth': 'none'
  },
  '.MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar': {
    display: 'none'
  }
});

export default bodyStyles;
