export const saveToggleContainer = {
  marginTop: '45px',

  '.customToggle': {
    '.toggleDescription': {
      pl: '41px',
      pt: 0,
    },

    '.saveToggleText': {
      fontSize: '13px',
    }
  }
}
