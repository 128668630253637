/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import {
  processCashPayment,
  processCreditCardPayment,
  processInvoicePayment,
  processNABPayment,
  markInvoiceAsPaid,
  processMerchantCreditRefund
} from '../../actions/transactionsActions';
import {
  payPaymentLink,
} from '../../actions/paymentLinksActions';
import {
  processInvoicePaymentApplePay,
  payPaymentLinkWithApplePay
} from '../../actions/applePayActions';
import Validator from '../util/Validator';
import { isJSON, roundToTwoDecimals } from '../util/CommonUtil';
import InvoiceUtil, {PaymentMethod} from './InvoiceUtil';
import FormatTextUtil from './FormatTextUtil';
import emailValidator from 'email-validator';
import numeral from 'numeral';
import _ from 'lodash';
import messages from '../../constants/messages';
import Bugsnag from '@bugsnag/js';
import LabelUtil from './LabelUtil';
import moment from 'moment';
import { change } from 'redux-form';
import CustomerUtil from './CustomerUtil';
import {saveExpressItems} from '../../actions/itemsActions';

const PaymentUtil = {

  process(values, props, itemizedCart) {

    numeral.defaultFormat('0.00');
    const isItemizedTransaction = !!(itemizedCart?.item_ids?.length > 0);

    const {
      subTotal,
      subTotalWithoutLoyalty,
      total,
      cashDiscountAmount,
      taxAmount,
      rewardDiscount
    } = InvoiceUtil.getCartAmounts(isItemizedTransaction, {...values, itemizedCart});
    values.subTotalWithoutLoyalty = subTotalWithoutLoyalty;
    values.total = total;
    values.cashDiscountAmount = cashDiscountAmount;
    values.rewardDiscount = rewardDiscount;
    values.cd_activated = props.merchantSettings?.merchantSettings?.cash_discount_enabled;

    let categories = [];

    if (props.merchantSettings.express_category_enabled) {
      const category = _.find(props.items.categories, ['id', values.selectedCategory]);
      if (category) {
        categories.push(category.id);
      }
    }

    const transactionTaxes = {
      taxAmount,
      taxRate: Number(String(values.taxRate).replace(/%/g, '')),
      geoLocation: props.taxes.geoLocation
    };

    if (values.paymentType === 'debtRepayment') {
      transactionTaxes.taxRate = 0;
    }

    values.selectedCustomer = props.customers?.selectedCustomer;

    return new Promise(function (resolve, reject) {

      props.dispatch(processCreditCardPayment(transactionTaxes, values, props.user, subTotal, categories, {}, itemizedCart, false, props?.merchantSettings?.merchantSettings?.custom_fields)).then(function (paymentResponse) {
        let isV2Response = true;

        if (paymentResponse?.response && Object.hasOwn(paymentResponse.response, 'simple')) {
          isV2Response = !paymentResponse.response.simple;
        }

        if (typeof paymentResponse.error !== 'undefined') {
          if (isJSON(paymentResponse.error)) {
            reject(JSON.parse(paymentResponse.error));
          } else {
            reject(paymentResponse.error);
          }
          return;
        }

        const returnValues = isV2Response ? {
          payment: paymentResponse.response,
          receipt: paymentResponse.response.receipt,
          formValues: values
        } : {
          payment: {
            response: {
              ...paymentResponse.response,
              entity: {
                id: FormatTextUtil.formatTransactionId(paymentResponse.response.uniq_id)
              },
              transaction_response: {
                ...paymentResponse.response
              }
            }
          },
          receipt: {
            id: FormatTextUtil.formatTransactionId(paymentResponse.response.uniq_id),
            loyalty_vpc: paymentResponse.response.loyalty_vpc
          },
          formValues: values
        };

        const {
          response_code,
          status_code,
          status_message
        } = isV2Response ? paymentResponse?.response?.response?.transaction_response : paymentResponse.response;
        const isDeclined = isV2Response ? response_code === 'DCL' : (response_code !== 'APR' && response_code !== 'AVS');
        const authorized_amt = isV2Response ? paymentResponse?.response?.response?.entity?.authorized_amt : paymentResponse.response.authorized_amt;
        const requested_amt = isV2Response ? paymentResponse?.response?.response?.entity?.requested_amt : paymentResponse.response.requested_amt;
        const isPartialAuth = isV2Response ? paymentResponse?.response.response?.transaction_response?.is_partial_auth : parseFloat(requested_amt) !== parseFloat(authorized_amt);
        const isDuplicate = isV2Response ? paymentResponse?.response?.response?.transaction_response?.is_duplicate : paymentResponse?.response?.is_duplicate;

        returnValues.isDeclined = isDeclined;
        returnValues.isPartialAuth = isPartialAuth;
        returnValues.isDuplicate = isDuplicate;
        returnValues.response_code = response_code;
        returnValues.status_message = status_message;

        returnValues.payment.response = {
          ...returnValues.payment.response,
          authorized_amt,
          requested_amt
        };

        if (isDeclined || paymentResponse.response.debt_repayment_error === true) {
          resolve(returnValues);
          return;
        }

        const approvalCode = status_code === '00';

        // should also check here for entity.transaction_response AVS
        if (response_code === 'AVS' && !props.merchantSettings.ignoreAvsFailure) {

          // Failed AVS
          let avsErrorMessage = messages.errors.avs.message;
          if (!approvalCode) {
            avsErrorMessage += status_message ? ' ' + status_message : '';
          }

          if (isPartialAuth) {
            // since portal voids partial payment, don't show void/okay buttons
            returnValues.postPaymentError = {
              ...messages.errors.partialPayment,
              type: 'partial'
            };
          } else {
            returnValues.postPaymentError = {
              heading: messages.errors.avs.heading,
              message: avsErrorMessage,
              type: 'avs'
            };
          }

          resolve(returnValues);

        } else {

          if (isPartialAuth) {
            returnValues.postPaymentError = {
              ...messages.errors.partialPayment,
              type: 'partial'
            };
          }
          resolve(returnValues);
        }
      }).catch(error => {
        reject('processCreditCardPayment Error: ' + error.message);
      });
    });

  },

  processInvoiceMarkAsPaid(values, props) {

    return new Promise(function (resolve, reject) {

      props.dispatch(markInvoiceAsPaid(values, props.user)).then(function (paymentResponse) {

        const returnValues = {
          response: paymentResponse.response,
          invoiceValues: values
        };

        resolve(returnValues);

      }).catch(error => {
        reject('processInvoiceMarkAsPaid Error: ' + error);
      });

    });

  },

  processCashSale(values, props) {

    return new Promise(function (resolve, reject) {

      props.dispatch(processCashPayment(values, props.user)).then(function (paymentResponse) {

        const returnValues = {
          response: paymentResponse.response,
          invoiceValues: values
        };

        resolve(returnValues);

      }).catch(error => {
        reject('processCashSalePayment Error: ' + error);
      });

    });

  },

  processInvoice(values, token, props, type) {

    return new Promise((resolve, reject) => {

      props.dispatch(processInvoicePayment(values, token, type)).then((paymentResponse) => {

        const returnValues = {
          payment: paymentResponse,
          formValues: values
        };
        if (paymentResponse?.response) {
          const {
            response_code,
            status_code,
            processor_reference_token,
            status_message,
            is_partial_auth
          } = paymentResponse.response;
          const avsCode = status_code === '-41';

          if (response_code === 'DCL' && processor_reference_token === 'NAB_AUTH_AVS_FAIL') {

            let avsErrorMessage = props.t(messages.errors.avsPayInvoiceFailure);
            if (!avsCode) {
              avsErrorMessage += ' ' + status_message;
            }

            returnValues.postPaymentError = {
              heading: messages.errors.avs.heading,
              message: avsErrorMessage,
              type: 'avs'
            };

            return resolve(returnValues);

          } else {

            if (is_partial_auth) {
              returnValues.postPaymentError = {
                ...messages.errors.partialPayment,
                type: 'partial'
              };
            }

            return resolve(returnValues);
          }
        } else {
          return reject(props.t(messages.paymentUtil.noPaymentResponse));
        }
      }).catch(error => {
        Bugsnag.notify(new Error(error.message));
        reject('processCreditCardPayment Error: ' + error.message);
      });
    })
  },

  processInvoiceWithApplePay(
      user,
      applePaymentData,
      paymentData,
      tip = 0,
      props,
      merchantToken
  ) {

    const {token, billingContact} = applePaymentData;
    const zipCode = (billingContact && billingContact.postalCode) || '';
    const streetAddress = (billingContact && billingContact.addressLines[0]) || '';
    const tipAmount = parseFloat(tip).toFixed(2);

    return new Promise(function (resolve, reject) {

      props.dispatch(processInvoicePaymentApplePay(user, token, zipCode, streetAddress, tipAmount, paymentData, merchantToken)).then((response) => {

        const returnValues = {
          payment: response
        };
        resolve(returnValues);

      }).catch((error) => {

        reject('processApplePayPayment Error: ' + error.message);

      });

    });
  },

  processPaymentLinkPayload(values, paymentLink, props) {
    const payload = {};

    if (!!values.applePaymentData) {
      const {token: applePaymentToken, billingContact, shippingContact} = values.applePaymentData;
      const zipCode = (billingContact && billingContact.postalCode) || '';
      const streetAddress = (billingContact && billingContact.addressLines[0]) || '';

      payload.applePaymentData = {
        applePaymentToken,
        zipCode,
        streetAddress
      };

      payload.customer = {
        first_name: shippingContact.givenName,
        last_name: shippingContact.familyName,
        email: shippingContact.emailAddress,
        phone: shippingContact.phoneNumber
      };
    } else {

      const expirationDate = moment(values.edate, 'MM-YY');
      const exp_month = expirationDate.format('MM');
      const exp_year = expirationDate.format('YYYY');

      payload.card = {
        name_on_card: values.name_on_card,
        number: values.cdigits.replace(/-/g, ''),
        exp_month,
        exp_year,
        cvv: values.cvv,
        input_type: 'KEYED'
      };

      if (values?.avsEnabled) {
        payload.card.address = values.street_number;
        payload.card.zip = values.zip;
      }

      const chunks = (values.name_on_card || '').split(' ');
      payload.customer = {
        first_name: chunks.shift(),
        last_name: chunks.join(' '),
        email: values.email_address,
        phone: values.phone_number?.replace(/\D/g, '')
      };
    }

    if (paymentLink.settings?.custom_field && values.custom_field) {
      payload.custom_field = {
        name: paymentLink.settings.custom_field.name,
        value: values.custom_field
      }
    }

    const service_fee_amount = numeral(values.cash_discount_amount).value();
    const other_amount = numeral(values.other_amount).value();
    const sub_total_amount = numeral(values.amount).value() + other_amount + service_fee_amount;
    const tax_amount = numeral(values.tax_amount).value();
    const tip_amount = numeral(values.tip_amount).value();
    const tax_rate = props.taxes.geoTaxRate ?? props.taxes.taxRate;
    const total_amount = roundToTwoDecimals(sub_total_amount + tax_amount);
    const latitude = props.taxes.geoLocation?.latitude ?? null;
    const longitude = props.taxes.geoLocation?.longitude ?? null;

    return {
      ...payload,
      id: paymentLink.id,
      type: 'payment_link',
      tax_rate,
      tax_amount,
      tip_amount,
      service_fee_amount,
      total_amount,
      sub_total_amount,
      transaction_source: LabelUtil.getLabel().transactionSource,
      latitude,
      longitude
    };
  },

  async processPaymentLinkPayment(values, token, recaptchaToken, props, paymentLink, useRecaptchaV2) {
    const payload = this.processPaymentLinkPayload(values, paymentLink, props);
    return await props.dispatch(payPaymentLink(token, recaptchaToken, payload, useRecaptchaV2));
  },

  async processPaymentLinkWithApplePay(values, token, props, paymentLink) {
    const payload = this.processPaymentLinkPayload(values, paymentLink, props);

    return new Promise(function (resolve, reject) {

      props.dispatch(payPaymentLinkWithApplePay(props.user, payload, token)).then((response) => {

        const returnValues = {
          payment: response
        };
        resolve(returnValues);

      }).catch((error) => {

        reject('processApplePayPayment Error: ' + error.message);

      });

    });
  },

  processSupplyPayment(user, values, shoppingCart, props) {

    return new Promise(function (resolve, reject) {

      props.dispatch(processNABPayment(user, values, shoppingCart)).then(function (paymentResponse) {

        const returnValues = {
          payment: paymentResponse,
          formValues: values,
        };

        resolve(returnValues);

      }).catch(error => {
        reject('processSupplyPayment Error: ' + error.message);
      });

    });
  },

  async processIssueCredit(values, props) {

    const { dispatch } = props;
    numeral.defaultFormat('0.00');
    const refundTotals = {
      total: numeral(values.amount).value()
    };

    const formValues = {...values};
    const validEmailAddresses = _.filter(values.customer_email_addresses, contact => emailValidator.validate(contact));
    const validMobileNumber = Validator.isPhoneNumber(values.customer_phone) ? values.customer_phone : '';
    const hasCustomerData = Boolean(values.customer_first || values.customer_last || validMobileNumber || validEmailAddresses.length > 0);
    const customerId = hasCustomerData ? props.customers?.selectedCustomer?.id : undefined;
    let mec_customer_id, pa_customer_id;

    if (customerId && props.customers?.selectedCustomer?.is_pa_customer) {
      pa_customer_id = customerId;
    } else {
      mec_customer_id = customerId;
    }

    let contactReqBody = {
      first_name: values.customer_first || '',
      last_name: values.customer_last || '',
      phone_number: validMobileNumber,
      address: {
        street_address_1: values?.customer_street_address_1 || '',
        street_address_2: values?.customer_street_address_2 || '',
        city: values?.customer_city || '',
        zip: values?.customer_zip || '',
        state: values?.customer_state || '',
      },
      email_addresses: validEmailAddresses.length > 0 ? validEmailAddresses : []
    };

    const hasAddressData = Object.values(contactReqBody.address)?.some(address => Boolean(address));

    if (!hasAddressData) {
      delete contactReqBody.address;
    }

    let isCustomerDirty = false;

    if (mec_customer_id || pa_customer_id) {
      const currentCustomer = props.customers?.selectedCustomer;

      const selectedCustomer = {
        first_name: currentCustomer?.first_name || '',
        last_name: currentCustomer?.last_name || '',
        phone_number: currentCustomer?.phone_number || '',
        address: hasAddressData ? {
          street_address_1: currentCustomer?.address?.street_address_1 || '',
          street_address_2: currentCustomer?.address?.street_address_2 || '',
          city: currentCustomer?.address?.city || '',
          zip: currentCustomer?.address?.zip || '',
          state: currentCustomer?.address?.state || '',
        } : undefined,
        email_addresses: currentCustomer?.email_addresses ?? []
      };

      const fieldsToCheck = [
        'email_addresses',
        'phone_number',
        'first_name',
        'last_name',
        'street_address_1',
        'street_address_2',
        'state',
        'city',
        'zip',
      ];

      isCustomerDirty = fieldsToCheck.some(field => {
        let selectedCustomerField = selectedCustomer[field];

        if (selectedCustomerField == null) {
          selectedCustomerField = selectedCustomer?.address?.[field];
        }

        let contactReqBodyField = contactReqBody[field];
        if (contactReqBodyField == null) {
          contactReqBodyField = contactReqBody?.address?.[field];
        }

        if (field === 'phone_number') {
          selectedCustomerField = selectedCustomerField.replace(/\D/g, '');
          contactReqBodyField = contactReqBodyField.replace(/\D/g, '')
        }
        return !_.isEqual(selectedCustomerField, contactReqBodyField);
      });
    } else {
      isCustomerDirty = hasCustomerData;
    }

    try {
      const {
        paCustomerId: pa_customer_id,
        customerId: mec_customer_id
      } = await CustomerUtil.checkCustomer(formValues, props);

      const response = await dispatch(processMerchantCreditRefund(null, props.user, formValues, refundTotals, mec_customer_id, pa_customer_id));

      const returnValues = {
        payment: response,
        formValues: values,
        receipt: {id: response.response.refund_id}
      };

      if (response.response?.status_code !== '00') {
        returnValues.postPaymentError = {
          message: `${messages.errors.issueCreditFailure} ${response.response?.status_message || ''}`
        };
      }

      return returnValues;
    } catch (error) {
      throw new Error('processMerchantRefund Error: ' + error.message);
    }
  },
  getCardImage(type) {
    const cards = {
      'American Express': {
        cardImgUrl: `${serverDomainUrl}images/cards/amex.png`
      },
      'Discover': {
        cardImgUrl: `${serverDomainUrl}images/cards/discover.png`
      },
      'Mastercard': {
        cardImgUrl: `${serverDomainUrl}images/cards/mastercard.png`
      },
      'Visa': {
        cardImgUrl: `${serverDomainUrl}images/cards/visa.png`
      },
      'Diners Club/Carte Blanche': {
        cardImgUrl: `${serverDomainUrl}images/cards/dinersclub.png`
      },
      'JCB (Central only)': {
        cardImgUrl: `${serverDomainUrl}images/cards/jcb.png`
      },
      'ach': {
        cardImgUrl: `${serverDomainUrl}images/cards/ach.svg`
      },
      'default': {
        cardImgUrl: `${serverDomainUrl}images/cards/unknown.png`
      }
    };
    return (cards[type] || cards['default']);
  },

  disableCashDiscount(props, formName, fieldName, toggleState) {
    const { initialValues, currentValues, merchantSettings } = props;
    const toggleChanged = toggleState !== initialValues?.cashDiscounting;
    const isDraft = initialValues?.isDraft;
    const cashDiscountEnabled = merchantSettings?.merchantSettings?.cash_discount_enabled;
    const formCashDiscountOn = currentValues?.cashDiscounting;
    const isBankAccountMethod = currentValues?.selectedPaymentMethod?.type === 'ach';
    const isBankAccountInput = currentValues?.paymentMethod === PaymentMethod.BANKING_ACCOUNT;
    const changedByUser = cashDiscountEnabled && toggleChanged;
    const shouldToggleCashDiscountOn = cashDiscountEnabled && !formCashDiscountOn && !(isBankAccountInput || isBankAccountMethod);
    const shouldToggleCashDiscountOff = (isBankAccountMethod || isBankAccountInput) && formCashDiscountOn;
    const shouldToggle = (isDraft && !initialValues?.cashDiscounting && changedByUser) || (!isDraft && !changedByUser);
    if (shouldToggle) {
      if (shouldToggleCashDiscountOff) {
        props.dispatch(change(formName, fieldName, false));
      } else if (shouldToggleCashDiscountOn) {
        props.dispatch(change(formName, fieldName, true));
      }
    }
  },

  saveItemToInventory : (props, itemizedCart) => {
    const {dispatch, user} = props;
    const items = itemizedCart.item_ids.reduce((result, id, index) => {
      if (id === 'express' && itemizedCart.save_to_inventory[index]) {
        result.push({
            name: itemizedCart.item_names[index],
            is_taxable: false,
            is_favorite: false,
            is_trackable: false,
            allow_outof_stock: false,
            prices: [
              {
                name: 'Base Price',
                price: itemizedCart.item_unit_price[index],
                quantity: null,
                stock_enabled: false,
                low_stock_alert_amount: null,
                cost: 0
              }
            ],
            categories: [],
            option_sets: []
        });
      }
      return result;
    }, []);
    dispatch(saveExpressItems(user, items))
  }

};

export default PaymentUtil;
