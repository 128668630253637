/**
  North American Bancard ("NAB") CONFIDENTIAL MATERIAL

  Copyright 2000 NAB, All Rights Reserved.

  NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
  contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
  protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
  strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
  is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
  and Non-disclosure agreements explicitly covering such access.

  The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
  which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
  ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
  CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
  INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
  IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
  MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import { submit } from 'redux-form';
import {
  emailValidatedFlag, setLocalCommunication, setSubmitButtonEnabled
} from '../../actions/userExperienceActions';
import { getAlerts, saveAlerts } from '../../actions/alertsActions';
import Button from '../shared/Button';
import CommunicationsSettingsFormContainer from './CommunicationsSettingsForm';
import LabelUtil from '../util/LabelUtil';
import FilterPanel from '../shared/FilterPanel';
import UserUtil from '../util/UserUtil';
import {
  getCommunications, saveCommunications
} from '../../actions/communicationActions';
import {emailValidation} from '../../actions/verificationActions';
import { getMerchantSettings, partialUpdateSettings } from '../../actions/merchantSettingsActions';
import SettingsUtil from '../util/SettingsUtil';
import applicationConstants from '../../constants/applicationConstants';
import { Trans } from 'react-i18next';
import Page from '../shared/Page';

export default class Alerts extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.invoiceAlertMapping = this.invoiceAlertMapping.bind(this);

    this.state = {
      isSaving: false,
      openEmailCommunicationDialog: false,
    };

  }

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    const label = LabelUtil.getLabel();
    const { isOwner, isSpr, isAdmin } = props.auth;
    const requests = [];
    if (isOwner || isSpr || isAdmin) {
      if ((label.abbreviation === 'ph' && UserUtil.userType(this.props.user) === 'MBP') || UserUtil.isEPX(this.props.user)) {
        requests.push(props.dispatch(getCommunications(props.user)));
      }
    }

    requests.push(props.dispatch(getMerchantSettings(props.user)));
    requests.push(props.dispatch(getAlerts(props.user)));
    requests.push(props.dispatch(setSubmitButtonEnabled(true)));

    this.setState({isSaving: true}, () => {
      Promise.all(requests).then(() => {
        this.setState({isSaving: false});
      })
    });
  }

  invoiceAlertMapping(values) {
    let alertsToSet = [];

    applicationConstants.invoiceAlertNames.forEach(invoiceAlertsName => {
      if (values[invoiceAlertsName] && !!values[invoiceAlertsName]) {
        alertsToSet.push({alert_name: invoiceAlertsName});
      }
    });

    return alertsToSet;
  }

  saveCommunicationSettings = (values) => {
    const { user, auth, accountProfile } = this.props;
    let that = this;
    let requests = [];

    const alertsPayload = {
      'alert_cc': values.alert_cc,
      'alert_to': values.alert_to,
      'daily_batch_activity_summary': values.daily_batch_activity_summary,
      'batch_over': values.batch_over,
      'batch_over_target_value': values.batch_over_target_value,
      'batch_under': values.batch_under,
      'batch_under_target_value': values.batch_under_target_value,
      'no_transaction_activity': values.no_transaction_activity,
      'no_transaction_activity_target_value': values.no_transaction_activity_target_value,
      'invoice_alert_to': values.invoice_alert_to,
      'invoice_alert_cc': values.invoice_alert_cc
    };

    const invoiceAlertsPayload = this.invoiceAlertMapping(values);

    const communicationPayload = {
      'comm_dailySnapshotEmail': values.comm_dailySnapshotEmail,
      'comm_compliance': values.comm_compliance,
      'comm_disputes': _.isArray(values.comm_disputes) ? values.comm_disputes.join(',') : values.comm_disputes,
      'comm_statements': values.comm_statements,
    };

    const closingEmailPayload = {
      'tip_adjust_manual_notification_enabled': values.tip_adjust_manual_notification_enabled,
      'manual_close_notifications_enabled': values.manual_close_notifications_enabled,
      'send_login_attempt_email': values.send_login_attempt_email
    };

    const hasClientServiceWritePermission = user && user.data && user.data.roles && user.data.roles.includes('cswrite');
    const hasCommunicationsWritePermission = !auth.isSpr || hasClientServiceWritePermission ? true : false;

    const [saveCommunicationPreferenceRequests, disputesHasChanged] = this.saveCommunicationPref(communicationPayload, hasCommunicationsWritePermission);

    requests = [
      ...this.saveAlerts(alertsPayload, invoiceAlertsPayload),
      ...saveCommunicationPreferenceRequests,
      ...this.saveClosingEmail(closingEmailPayload)
    ];

    this.setState({isSaving: true}, () => {
      Promise.all(requests).then(() => {
        /* istanbul ignore else */
        if (hasCommunicationsWritePermission) {
          that.props.dispatch(setLocalCommunication(values));
          if (!accountProfile?.data?.email_validated && values.comm_disputes?.length > 0) {
            that.props.dispatch(emailValidatedFlag('progress'));
            if (disputesHasChanged) that.setOpenEmailCommunicationDialog(true);
          } else {
            that.props.dispatch(emailValidatedFlag('invalid'));
          }
        }
        that.loadData(that.props);
        return Promise.resolve();
      });
    });
  }

  saveAlerts(values, invoiceAlertsPayload) {
    const requests = [];

    requests.push(this.props.dispatch(saveAlerts(this.props.user, this.props.alerts.data.alerts, values, invoiceAlertsPayload)));
    return requests;
  }

  saveClosingEmail(values) {
    const requests = [];
    requests.push(this.props.dispatch(partialUpdateSettings(SettingsUtil.createClosingEmailPayload(values), this.props.user)));
    return requests;
  }

  /**
   * Triggers remote and local change in communication preferences depending
   * on user and authentication status.
   * @param {*} values Object with the values from CommunicationPreferencesForm.
   */
  saveCommunicationPref(values, hasCommunicationsWritePermission) {

    const { filterNewCommunicationPreferences, props } = this;
    const { user, accountProfile, communications } = props;
    const currentCommunicationPreferences = communications.data;

    const label = LabelUtil.getLabel();
    const requests = [];

    let newCommunicationPreferences = [];
    let disputesHasChanged = false;

    const hasDisputesChanged = (oldDisputes, newDisputesValue) => (oldDisputes && newDisputesValue) && oldDisputes.address !== newDisputesValue;

    if (UserUtil.userType(user) === 'MBP' || UserUtil.isHumbolt(user)) {
      if (values.comm_disputes && !accountProfile?.data?.email_validated) {
        if (hasCommunicationsWritePermission) {
          newCommunicationPreferences = filterNewCommunicationPreferences(currentCommunicationPreferences, values, 'unverified');
          disputesHasChanged = hasDisputesChanged(currentCommunicationPreferences && currentCommunicationPreferences.find(preference => preference.communication_type === 'DISPUTES'), values.comm_disputes);

          if (disputesHasChanged) {
            requests.push(this.props.dispatch(emailValidation(user, label.abbreviation)));
          }
          /* istanbul ignore else */
          if (newCommunicationPreferences && newCommunicationPreferences.length) {
            requests.push(this.props.dispatch(saveCommunications(user, newCommunicationPreferences)));
          }
        }
      } else {
        if (hasCommunicationsWritePermission) {
          newCommunicationPreferences = filterNewCommunicationPreferences(currentCommunicationPreferences, values, 'all')
          /* istanbul ignore else */
          if (newCommunicationPreferences && newCommunicationPreferences.length) {
            requests.push(this.props.dispatch(saveCommunications(user, newCommunicationPreferences)));
          }
        }
      }
    }
    if (UserUtil.userType(user) === 'PA') {
      if (values.comm_disputes && !accountProfile?.data?.email_validated) {
        if (hasCommunicationsWritePermission) {
          newCommunicationPreferences = filterNewCommunicationPreferences(currentCommunicationPreferences, values, 'pa_unverified');
          disputesHasChanged = hasDisputesChanged(currentCommunicationPreferences && currentCommunicationPreferences.find(preference => preference.communication_type === 'DISPUTES'), values.comm_disputes );
          if (disputesHasChanged) {
            requests.push(this.props.dispatch(emailValidation(user, label.abbreviation)));
          }
          if (newCommunicationPreferences && newCommunicationPreferences.length) {
            requests.push(this.props.dispatch(saveCommunications(user, newCommunicationPreferences)));
          }
        }
      } else {
        if (hasCommunicationsWritePermission) {
          newCommunicationPreferences = filterNewCommunicationPreferences(currentCommunicationPreferences, values, 'pa');
          /* istanbul ignore else */
          if (newCommunicationPreferences && newCommunicationPreferences.length) {
            requests.push(this.props.dispatch(saveCommunications(user, newCommunicationPreferences)));
          }
        }
      }
    }

    return [requests, disputesHasChanged];
  }

  /**
   * Filters communication preferences by the specified filter type
   * and only changed or previously unset values.
   * @param {{}} oldValues values already stored in communications.
   * @param {{}} newValues new values comming from form.
   * @param {string} preferenceFilter communication options available to the user.
   */
  filterNewCommunicationPreferences(oldValues, newValues, preferenceFilter) {
    const options = {
      'compliance': {
        'channel': newValues.comm_compliance,
        'communication_type': 'COMPLIANCE'
      },
      'daily_summary': {
        'channel': newValues.comm_dailySnapshotEmail,
        'communication_type': 'DAILY_SUMMARY'
      },
      'disputes': {
        'channel': 'EMAIL',
        'communication_type': 'DISPUTES',
        'new_email': newValues.comm_disputes
      },
      'statements': {
        'channel': newValues.comm_statements,
        'communication_type': 'STATEMENTS'
      }
    };

    const preferencesFor = {
      'all': Object.entries(options).map(([key, value]) => value),
      'unverified': [options['compliance'], options['statements'], options['daily_summary']],
      'pa': [options['disputes'], options['daily_summary'], options['statements']],
      'pa_unverified': [options['statements'], options['daily_summary']]
    };

    const changedCommunicationValues = preferencesFor[preferenceFilter] && typeof preferencesFor[preferenceFilter] !== null && preferencesFor[preferenceFilter].filter(
      (preference) => {
        const channelIsNotEmpty = preference.channel !== '';
        const preferenceIsNotSetYet = oldValues && !oldValues.filter(oldValue => oldValue.communication_type === preference.communication_type).length;
        const preferenceHasChanged = oldValues && oldValues.filter(oldValue => oldValue.communication_type === preference.communication_type && oldValue.channel !== preference.channel).length;

        const disputeEmailHasChanged = preference.communication_type === 'DISPUTES' && oldValues && oldValues.filter(oldValue => oldValue.communication_type === preference.communication_type && oldValue.address !== preference.new_email).length;


        return channelIsNotEmpty && (preferenceIsNotSetYet || preferenceHasChanged || disputeEmailHasChanged);
      }
    );

    return changedCommunicationValues;
  }

  setOpenEmailCommunicationDialog = (openEmailCommunicationDialog) => this.setState({openEmailCommunicationDialog});;

  sendValidationEmail = () => {
    const label = LabelUtil.getLabel();
    this.props.dispatch(emailValidation(this.props.user, label.abbreviation));
    this.setOpenEmailCommunicationDialog(true);
  };

  handleEmailCommunicationClose = () => this.setOpenEmailCommunicationDialog(false);

  onSaveClick() {
    this.props.dispatch(submit('communicationsSettingsForm'));
  }

  render() {

    const {
      isFetching,
      alerts,
      userExperience,
      auth,
      user,
      t,
      formReducer,
    } = this.props;
    const { isSaving } = this.state;

    const isEmpty = alerts.data === null;

    const label = LabelUtil.getLabel();

    const linkStyle = {
      color: label.primaryColor,
      textDecoration: 'none',
      cursor: 'pointer'
    };

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
        buttonBar
      />
    );

    const pageLoading = !alerts.error && (isEmpty && isFetching || isSaving);

    if (pageLoading) {
      return (
        <Page
          title={t('Business Settings')}
          loading
        />
      )
    }

    const hasClientServiceWritePermission = user?.data?.roles?.includes('cswrite');

    const submitButtonDisabled = !userExperience.enableFormSubmitButton || (auth.isSpr  && !hasClientServiceWritePermission);

    const feedbackEmail = label.feedbackEmail;

    return (
      <section className='accountAlerts'>
        <div className='pageWrap'>
          <Page
            loading={pageLoading}
            title={t('Business Settings')}
            initialSubpage={t('Communication Settings')}
          >
            <div className='flexContainer flexContainerResponsiveLayout'>
              {filterPanel}
              <div className='businessSettingsSubsection alertsHolder'>
                <div className='alertsHeader'>
                  <div className='headerTitleContainer'>
                    <span className='titleText'>{t('CommunicationSettingsForm.Title')}</span>
                    <span className='subText'>{t('CommunicationSettingsForm.SubTitle')}</span>
                  </div>
                </div>
                {alerts.error ?
                  <div className='alertError'>
                    {t('CommunicationSettingsForm.AlertError')}
                  </div>
                  :
                  <div>
                    <CommunicationsSettingsFormContainer
                      {...this.props}
                      onSubmit={this.saveCommunicationSettings}
                      openEmailCommunicationDialog={this.state.openEmailCommunicationDialog}
                      sendValidationEmail={this.sendValidationEmail}
                      handleEmailCommunicationClose={this.handleEmailCommunicationClose}
                      disableFields={(auth.isSpr && !UserUtil.isUserCSWrite(user)) || !UserUtil.isActive(user)}
                    />

                    <div className='feedback'>
                      <h3 className='title'>{t('CommunicationSettingsForm.FeedbackTitle')}</h3>
                      <p className='text'>
                        <Trans
                          components={{ anchor: <a href={feedbackEmail}
                                                   style={linkStyle}
                                                   target='_blank'
                            /> }}
                          i18nKey='CommunicationSettingsForm.FeedbackText'
                          values={{ feedbackEmail }}
                        />
                      </p>
                    </div>
                    <div className='detailPanelHolderButtons saveContainer'>
                      { formReducer &&
                        formReducer.communicationsSettingsForm &&
                        formReducer.communicationsSettingsForm.syncErrors &&
                        formReducer.communicationsSettingsForm.anyTouched &&
                        <span className='settingsErrorBottom'>
                            {t('CommunicationSettingsForm.SettingsErrorBottom')}
                          </span>
                      }
                      <Button
                        label={t('CommunicationSettingsForm.SubmitButton')}
                        disabled={submitButtonDisabled}
                        onClick={this.onSaveClick}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </Page>
        </div>
      </section>

    );

  }
}

Alerts.defaultProps = {
  isFetching: true
};
