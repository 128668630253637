
/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL
 
 Copyright 2000 NAB, All Rights Reserved.
 
 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.
 
 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.
 
 */

import React from 'react';
import {Box, Typography} from '@mui/material';
import InvoicePaidImg from './images/invoicePaidImg';
import {
  invoiceDetailPaidContainer, invoiceDetailPaidSummary, invoiceDetailPaidSummaryRow,
  invoiceDetailPaidText, invoiceDetailPaidTextBold, invoiceDetailPaidTextNotification,
  invoiceDetailPaidTitle
} from '../../jss/invoicePaymentPageStyles';
import {Trans} from 'react-i18next';
import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment/moment';
import LoyaltyStatus from './LoyaltyStatus';
import LoyaltyEnroll from './LoyaltyEnroll';
import Button from '../shared/Button';
import FormatTextUtil from '../util/FormatTextUtil';

const InvoiceDetailPaid = ({t, paid, brandColor, isInvoiceFromSeries, merchant, achRevokeDays, sentReceiptTo, selectedInvoice, totalAmount, paymentMethodUsed, paymentToUse, achFormValues, currentInvoice, quantity, repeatsInfo, paymentMethods, shouldShowEnrollInLoyaltyToggle, loyaltyStatus, loyalty_vpc, customerInfo, toggleEnrollInLoyaltyValue, enrollInLoyaltyValue, handleAfterPay, submitEnrollInLoyaltyForm, confirmEnrollInLoyalty, customers}) => {
  
  const paymentMethodUsedToPay = paid && {
    isAch: paymentMethodUsed?.uniq_id?.includes('ach'),
    routingNumber: achFormValues?.routing_nbr?.toString() || paymentMethods?.find(({account_num_last4}) => account_num_last4 === paymentMethodUsed?.last4)?.routing_number,
    name: achFormValues ? `${achFormValues?.first_name} ${achFormValues?.last_name}` : `${paymentMethods?.find(({account_num_last4}) => account_num_last4 === paymentMethodUsed?.last4)?.first_name} ${paymentMethods.find(({account_num_last4}) => account_num_last4 === paymentMethodUsed?.last4)?.last_name}`,
    accountType: achFormValues?.account_type || paymentMethodUsed?.account_type,
    network: paymentMethodUsed?.network || paymentToUse?.network,
    last4: achFormValues?.account_nbr?.toString()?.slice(-4) || paymentMethodUsed?.last4 || paymentToUse?.last4,
    startDate: selectedInvoice?.series?.start_date,
    frequency: FormatTextUtil.formatFrequencyInvoice(selectedInvoice?.series?.start_date, selectedInvoice?.series?.frequency),
    totalPayments: selectedInvoice?.series?.end_amount_payments,
    duration: `${currentInvoice} of ${quantity}`,
    repeats: repeatsInfo
  };
  
  if (!paid) { return null; }
  
  return (
    <Box
      data-test-id='paid-invoice-state'
      sx={invoiceDetailPaidContainer}
    >
      <InvoicePaidImg brandColor={brandColor}/>
      <Typography
        sx={invoiceDetailPaidTitle}
      >
        {t('InvoiceDetail.PaidInvoice.Header')}
      </Typography>
      <Typography
        sx={invoiceDetailPaidTextNotification}
      >
        <Trans i18nKey={'InvoiceDetail.PaidInvoice.Description'}>
          We sent a receipt to <span>{{ email: sentReceiptTo }}</span>
        </Trans>
      </Typography>
      {isInvoiceFromSeries && paymentMethodUsedToPay?.isAch &&
        <Typography
          data-test-id='ach-recurring-notice'
          sx={invoiceDetailPaidTextNotification}
        >
          {t('InvoiceDetail.PaidInvoice.Recurring', {merchant: merchant?.business_name, time: achRevokeDays})}
        </Typography>}
      {paymentMethodUsedToPay.isAch && !isInvoiceFromSeries &&
        <Typography
          data-test-id='ach-paid-notice'
          sx={invoiceDetailPaidTextNotification}
        >
          {t('InvoiceDetail.PaidInvoice.Regular', { merchant: merchant?.business_name })}
        </Typography>
      }
      <Box
        component='ul'
        data-test-id='paid-invoice-summary'
        sx={invoiceDetailPaidSummary}
      >
        <Box
          component='li'
          sx={invoiceDetailPaidSummaryRow}
        >
          <Typography
            sx={invoiceDetailPaidTextBold}
          >
            {FormatTextUtil.capitalizeText(t('InvoiceNumberID', { number: selectedInvoice?.invoice}))}
          </Typography>
          <Typography
            sx={invoiceDetailPaidTextBold}
          >
            {FormatTextUtil.capitalizeText(`${t('amountPaid')}: ${numeral(totalAmount).format('$0,0.00')}`)}
          </Typography>
        </Box>
        <Box
          component='li'
          sx={invoiceDetailPaidSummaryRow}
        >
          {paymentMethodUsedToPay?.isAch &&
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${paymentMethodUsedToPay?.accountType} account`)}
            </Typography>}
          <Typography
            sx={invoiceDetailPaidText}
            data-test-id='payment-information'
          >
            {FormatTextUtil.capitalizeText(`${paymentMethodUsedToPay?.network || t('endingIn')} ${paymentMethodUsedToPay?.last4 || ''}`)}
          </Typography>
        </Box>
        {paymentMethodUsedToPay?.isAch &&
          <Box
            component='li'
            sx={invoiceDetailPaidSummaryRow}
          >
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${t('PayAndSaveForm.NameOnAccountField')}: ${paymentMethodUsedToPay.name}`)}
            </Typography>
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${t('startDate')}: ${moment(paymentMethodUsedToPay.startDate).format('MM/DD/YYYY')}`)}
            </Typography>
        </Box>}
        <Box
          component='li'
          sx={invoiceDetailPaidSummaryRow}
        >
          <Typography
            sx={invoiceDetailPaidText}
          >
            {FormatTextUtil.capitalizeText(t('InvoiceDetail.PaidDate', { date: moment().format('MM/DD/YYYY h:mm A') }))}
          </Typography>
          {isInvoiceFromSeries && paymentMethodUsedToPay?.isAch &&
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${t('totalPayments')}: ${numeral(paymentMethodUsedToPay.totalPayments).format('0')}`)}
            </Typography>}
        </Box>
        {paymentMethodUsedToPay?.isAch &&
          <Box
            component='li'
            sx={invoiceDetailPaidSummaryRow}
          >
            {isInvoiceFromSeries &&
              <Typography
                data-test-id='paid-frequency-text'
                sx={invoiceDetailPaidText}
              >
                {FormatTextUtil.capitalizeText(`${t('Frequency')}: ${paymentMethodUsedToPay.frequency.consecutiveText !== 'Every' ? t(paymentMethodUsedToPay.frequency.consecutiveText) : `${t(paymentMethodUsedToPay.frequency.consecutiveText)} ${paymentMethodUsedToPay.frequency.length} ${t(paymentMethodUsedToPay.frequency.period)}`}`)}
              </Typography>}
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${t('routingNumberEnd')}: ${paymentMethodUsedToPay.routingNumber || ''}`)}
            </Typography>
          </Box>}
        {isInvoiceFromSeries && paymentMethodUsedToPay?.isAch &&
          <Box
            component='li'
            sx={invoiceDetailPaidSummaryRow}
          >
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(`${paymentMethodUsedToPay.duration} ${t('paid')}`)}
            </Typography>
            <Typography
              sx={invoiceDetailPaidText}
            >
              {FormatTextUtil.capitalizeText(paymentMethodUsedToPay.repeats)}
            </Typography>
        </Box>}
      </Box>
      <Box>
        {
          shouldShowEnrollInLoyaltyToggle ?
            <LoyaltyEnroll
              confirmEnrollInLoyalty={confirmEnrollInLoyalty}
              customerInfo={customerInfo}
              customers={customers}
              enrollInLoyaltyValue={enrollInLoyaltyValue}
              loyalty_vpc={loyalty_vpc}
              submitEnrollInLoyaltyForm={submitEnrollInLoyaltyForm}
              t={t}
              toggleEnrollInLoyaltyValue={toggleEnrollInLoyaltyValue}
            />
            :
            <LoyaltyStatus
              loyaltyStatus={loyaltyStatus}
              loyalty_vpc={loyalty_vpc}
              t={t}
            />
        }
      </Box>
      <Box
        data-test-id='go-back-btn'
        display='flex'
        alignItems='center'
      >
        <Button
          variant='text'
          label={t('Done')}
          onClick={handleAfterPay}
        />
      </Box>
    </Box>
  );
  
}

export default InvoiceDetailPaid;
