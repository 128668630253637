/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from 'redux-form';
import numeral from 'numeral';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { Stack } from '@mui/material';

import { getPaymentLinkDetail } from '../../actions/paymentLinksActions';

import AttachFilesForm, { AttachFilesFormType } from '../AttachFilesForm';
import UpdateSpinner from '../UpdateSpinner';

import CustomToggle from '../shared/Toggle';
import TextField from '../shared/TextField';
import ResizableTextArea from '../shared/ResizableTextArea';
import BrandColorPicker from '../shared/BrandColorPicker';
import Select from '../shared/Select';

import FileUtil from '../util/FileUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import IconUtil from '../util/IconUtil';
import Validator from '../util/Validator';

import actionTypes from '../../constants/actionTypes';
import attachFileOptions from '../../constants/attachFileOptions';
import routes from '../../constants/routes';

import paymentLinks from '../../constants/paymentLinks';
import { toastr } from 'react-redux-toastr';

import Prism from 'prismjs';
import 'prismjs/components/prism-markup';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import {paymentLinkFormStyles} from '../../jss/paymentLinksStyles';
import classnames from 'classnames';

export const PAYMENT_LINK_DETAIL_FORM_ID = 'paymentLinkDetailForm';

export const validate = (values) => {
  return (!_.isEmpty(values)) ? Validator.validatePaymentLinkDetailForm(values) : {};
};

export class PaymentLinkDetailForm extends Component {

  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.handleAddImage = this.handleAddImage.bind(this);
    this.handleResetImage = this.handleResetImage.bind(this);
    this.onCopyCode = this.onCopyCode.bind(this);
    this.toggleShowColorPicker = this.toggleShowColorPicker.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);

    this.state = {
      showColorPicker: false
    }

  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { currentValues } = this.props;
    const { currentValues: prevCurrentValues } = prevProps;
    if (!_.isEqual(prevCurrentValues?.buttonText, currentValues?.buttonText) ||
      !_.isEqual(prevCurrentValues?.buttonColor, currentValues?.buttonColor) ||
      !_.isEqual(prevCurrentValues?.buttonFont, currentValues?.buttonFont)) {
      Prism.highlightAll();
    }
  }

  loadData() {

    const { selectedDetail, user, dispatch, paymentLinks: { selectedPaymentLink: previousSelectedPaymentLink } } = this.props;

    if (previousSelectedPaymentLink?.id !== selectedDetail?.id) {

      dispatch(getPaymentLinkDetail(selectedDetail?.id, user)).then(result => {

        if (result.type === actionTypes.getPaymentLinkDetailSuccess) {
          const imageString = result?.response?.settings?.image;

          if (imageString) {
            dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'image', imageString));
          }
        }

      });

    } else {
      dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'image', previousSelectedPaymentLink?.settings?.image));
    }
  }

  handleAddImage(file) {

    return FileUtil.getBase64(file[0]).then((result) => {

      const extension = result.base64.split('/')[1].split(';')[0];
      const imageString = result.base64.replace(`data:image/${extension};base64,`, '');

      this.props.dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'image', imageString));
      this.props.dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'imageExtension', extension));

    });
  }

  handleResetImage() {
    this.props.dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'image', null));
    this.props.dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'imageExtension', ''));
  }

  onCopyCode() {
    const { t, currentValues, selectedDetail: { settings: { url }} } = this.props;

    const codeString = paymentLinks.linkCode
      .replace('{url}', url)
      .replace('{buttonText}', currentValues?.buttonText)
      .replace('{buttonColor}', currentValues?.buttonColor)
      .replace('{buttonFont}', `${currentValues?.buttonFont}, sans-serif`);

    return navigator.clipboard.writeText(codeString).then(() => {
      toastr.success('Success', t('PaymentLinks.CopyCodeSuccess'));
    }, () => {
      toastr.error('Error', t('PaymentLinks.CopyCodeError'));
    });
  }


  toggleShowColorPicker() {
    this.setState((prevState) => ({
      showColorPicker: !prevState.showColorPicker
    }));
  }

  handleColorChange(color) {
    this.props.dispatch(change(PAYMENT_LINK_DETAIL_FORM_ID, 'buttonColor', color.hex));
  };

  render() {

    const { t, currentValues, selectedDetail, merchantSettings, onCopyUrl, paymentLinks: { isProcessing, isFetchingDetail } } = this.props;

    const { toggleShowColorPicker, handleColorChange } = this;
    const { showColorPicker } = this.state;

    const { active, settings, created_date, created_by, number_sales = 0, total_sales_amt = 0 } = selectedDetail;
    const { open_end_min, open_end_max, tax_enabled, cash_discount_enabled, type, amount, include_other, url = '' } = settings;

    const copyIcon = IconUtil.getIcon('CopyIcon');
    const removeIcon = IconUtil.getIcon('Close', '#ffffff');
    const hasMin = !!open_end_min;
    const hasMax = !!open_end_max;

    let selectedType;

    switch (type) {
      case 'fixed':
        selectedType = t('PaymentLinkTypes.fixedAmount');
        break;
      case 'multiple':
        selectedType = t('PaymentLinkTypes.multipleAmount');
        break;
      case 'open':
        selectedType = t('PaymentLinkTypes.openEnded');
    }


    let taxesAndTipsInfo = '';
    if (tax_enabled) {
      taxesAndTipsInfo += 'Taxable, ';
    } else {
      taxesAndTipsInfo += 'Not Taxable, ';
    }

    if (currentValues?.acceptTips) {
      taxesAndTipsInfo += 'Tips Enabled';
    } else {
      taxesAndTipsInfo += 'Tips Disabled';
    }


    let cdInfo = null;

    if (cash_discount_enabled) {

      const cdFixedAmount = parseFloat(merchantSettings?.merchantSettings?.cash_discount_fixed_amount);
      const cdPercent = parseFloat(merchantSettings?.merchantSettings?.cash_discount_amount_percent);
      const formattedCdFixedAmount = numeral(cdFixedAmount).format('$0,0.00');
      const formattedCdPercent = FormatTextUtil.formatTaxRate(cdPercent.toFixed(3));
      let merchantCDValues = '';

      if (cdFixedAmount && cdPercent) {
        merchantCDValues = `${formattedCdFixedAmount} / ${formattedCdPercent}`;
      } else if (cdFixedAmount && !cdPercent) {
        merchantCDValues = formattedCdFixedAmount;
      } else if (!cdFixedAmount && cdPercent) {
        merchantCDValues = formattedCdPercent;
      }

      cdInfo = <p className='detailSubtitle cdInfo'>{`${t('PaymentLinks.Detail.CDEnabled')} (${merchantCDValues})`}</p>
    }


    const paymentAmounts = [];

    if (type === 'multiple') {

      paymentAmounts.push(...amount);

      if (include_other) {
        paymentAmounts.push('Variable');
      }

    } else if (type === 'open') {
      paymentAmounts.push('Variable');

    } else {
      paymentAmounts.push(amount);
    }

    const paymentAmountItems = paymentAmounts.map( (amount , index) => {

      const isVariable = amount === 'Variable';
      const itemValue = isVariable ? amount : numeral(amount).format('$0,0.00');
      let minMaxText = '';

      if (hasMin) {
        minMaxText = `Min ${numeral(open_end_min).format('$0,0.00')}`;
      }
      if (hasMin && hasMax) {
        minMaxText += ' / ';
      }
      if (hasMax) {
        minMaxText += `Max ${numeral(open_end_max).format('$0,0.00')}`
      }

      return (
        <div className='amountItem' key={`amountItem${index}`} >
          <span className='amountItemValue'>{itemValue}</span>
          {isVariable && (hasMin || hasMax) &&
            <span className='amountItemDetails'>{minMaxText}</span>
          }
        </div>
      );

    });

    const date = moment(created_date).format('MM/DD/YYYY');
    const time = moment(created_date).format('h:mm a');

    const codeString = paymentLinks.linkCode
      .replace('{url}', url)
      .replace('{buttonText}', currentValues?.buttonText)
      .replace('{buttonColor}', currentValues?.buttonColor)
      .replace('{buttonFont}', `${currentValues?.buttonFont}, sans-serif`);

    const allowedFonts = ['arial', 'verdana', 'georgia', 'times new roman'];

    const fontsMenuItems = allowedFonts.map((value, index) => (
      <MenuItem key={`font${index}`} value={value}>{_.startCase(value)}</MenuItem>
    ));

    const imageContainer = (
      <div className='imageContainer'>

        {currentValues?.image ?
          <div className='uploadedImage'>
            <img
              src={`data:image/${currentValues?.imageExtension};base64,${currentValues?.image}`}
              alt='Company Logo'/>
            <span className='removeLogo' onClick={this.handleResetImage}>{removeIcon}</span>
          </div>
        :
        <AttachFilesForm
          insideForm
          displayType={AttachFilesFormType.LOGO}
          logoSetState={this.handleAddImage}
          filesCopy={t('PaymentLinks.CreateForm.ImageUpload')}
          secondaryText={`170x170, JPEG ${t('or')} PNG`}
          dropzoneOptions={attachFileOptions.imageSinglePngJpgDropzoneOptions}
          onSubmit={null}
        />}

        {isFetchingDetail && <UpdateSpinner classes='imageContainerSpinner' />}
      </div>
    );

    return (
      <Box sx={paymentLinkFormStyles}>
        <section className='paymentLinksDetailContainer'>

        <div className='linkInformation subsection'>

          <p className='subsectionTitle'>{t('PaymentLinks.Detail.LinkInformation')}</p>

          <div className='mainInfo'>

            <div className='titleDescriptionContainer'>

              <Field
                component={TextField}
                name='paymentLinkTitle'
                className='paymentLinkTitle'
                hintText={t('PaymentLinks.CreateForm.TitleHint')}
                disabled={isProcessing}
                maxLength='50'
              />

              <Field
                component={ResizableTextArea}
                name='paymentLinkDescription'
                className='paymentLinkDescription'
                hintText={t('PaymentLinks.Detail.NoDescription')}
                disabled={isProcessing}
              />

            </div>

            {imageContainer}

          </div>

          <div className='linkContainer'>
            <a
                className={`linkLike ${!active ? 'inactive' : ''}`}
                href={url}
                rel='noopener noreferrer'
                target='_blank'
            >
              {url}
            </a>
            { active ? <div className='copyIconContainer' onClick={onCopyUrl}>{copyIcon}</div> : <p className='inactiveLinkText'>{t('PaymentLinks.Detail.InactiveLink')}</p>}
          </div>

        </div>

        <div className='paymentInformation subsection'>

          <p className='subsectionTitle'>{t('PaymentLinks.Detail.PaymentInformation')}</p>

          <div className='paymentDetails'>

            <div className='detailContainer'>
              <h3 className='detailTitle'>{selectedType}</h3>
              <p className='detailSubtitle taxesAndTipsInfo'>{taxesAndTipsInfo}</p>
              {cdInfo}
            </div>

            <div className='detailContainer totalUses'>
              <h3 className='detailTitle'>{number_sales}</h3>
              <p className='detailSubtitle'>{t('PaymentLinks.Detail.TotalUses')}</p>
            </div>

            <div className='detailContainer totalVolume'>
              <h3 className='detailTitle'>{numeral(total_sales_amt).format('$0,0.00')}</h3>
              <p className='detailSubtitle'>{t('PaymentLinks.Detail.TotalVolumeGenerated')}</p>
            </div>

          </div>

          <div className='paymentAmounts'>
            {paymentAmountItems}
          </div>
          {merchantSettings?.merchantSettings?.is_tip_enabled && (
            <Field
              component={CustomToggle}
              name='acceptTips'
              customClass='acceptTips paymentOptionsToggle'
              label={t('PaymentLinks.CreateForm.AcceptTipsLabel')}
              checked={!!this.value}
              disabled={isProcessing}
            />
          )}
        </div>

        <div className='buttonCode subsection'>

          <p className='subsectionTitle'>{t('PaymentLinks.Detail.PaymentLinkButton')}</p>

          <Typography
            className='subsectionDisclaimer'
            data-test-id='subsectionDisclaimer'
            variant='primaryFontFamilySemiBold'
          >
            <Trans
              components={{
                receiptAndInvoiceSettingsAnchor: (
                  <Link
                    className='link'
                    target='_blank'
                    to={`${globalApplicationLabel.path}${routes.account.root}${routes.account.salesSettings}`}
                    rel='noopener noreferrer'
                  />
                )
              }}
              i18nKey='PaymentLinks.Detail.Disclaimer'
              values={{
                receiptAndInvoiceSettings: t('PaymentLinks.Detail.ReceiptAndInvoiceSettingsLink')
              }}
            />
          </Typography>

          <div className='buttonCodeContainer'>

            <Box className='buttonOptions'>

              <Field
                component={BrandColorPicker}
                label={'Button Color'}
                className='brandColor'
                maxLength='50'
                name='buttonColor'
                color={currentValues?.buttonColor}
                showColorName
                confirmButtonLabel={t('Confirm')}
                handleClick={toggleShowColorPicker}
                showColorPicker={showColorPicker}
                handleChange={handleColorChange}
              />

              <Stack className='buttonTextFontContainer' direction='row' spacing={2} >
                <Field
                  component={TextField}
                  label={'Button Text'}
                  className='buttonText'
                  name='buttonText'
                  hintText={'Pay Now'}
                  disabled={isProcessing}
                  maxLength='25'
                />
                <Field
                  component={Select}
                  label={'Font'}
                  className='buttonFont'
                  name='buttonFont'
                  disabled={isProcessing}
                  data-test-id='buttonFont'
                >
                  {fontsMenuItems}
                </Field>
              </Stack>

              <p className='codeAreaLabel'>Button Code</p>
              <pre className='codeArea line-numbers'>
                {active && <div className='copyIconContainer' onClick={this.onCopyCode}>{copyIcon}</div>}
                <code className='language-markup' data-test-id='codeString'>{codeString}</code>
              </pre>

            </Box>

            <div className='preview'>
              <p className='previewText'>{t('PaymentLinks.Detail.Preview')}</p>
              <Box
                className='previewButton'
                data-test-id='previewButton'
                sx={{background: currentValues?.buttonColor, fontFamily: `${currentValues?.buttonFont}, sans-serif`}}
              >
                <a
                  className={classnames('nab-payment-link', { inactive: !active })}
                  href={active ? url : ''}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {currentValues?.buttonText}
                </a>
              </Box>
            </div>
          </div>

        </div>

        <div className='createdInfo subsection'>
          <p className='createdText'>{t('PaymentLinks.Detail.Created', { date, time, createdBy: created_by})}</p>
        </div>

        { isProcessing && <UpdateSpinner /> }

      </section>
      </Box>
    );
  }
}

let PaymentLinkDetailFormContainer = PaymentLinkDetailForm;

PaymentLinkDetailFormContainer = reduxForm({
  form: PAYMENT_LINK_DETAIL_FORM_ID,
  validate,
  fields: [],
  enableReinitialize: true,
})(PaymentLinkDetailFormContainer);

export function mapStateToProps(state, ownProps) {

  const { selectedDetail, t, merchantSettings } = ownProps;
  const { settings: { title, subtitle, tips_enabled, button_color, button_text, button_font } } = selectedDetail;

  const currentValues = getFormValues(PAYMENT_LINK_DETAIL_FORM_ID)(state) || {};

  return {
    currentValues,
    initialValues: {
      paymentLinkTitle: title,
      paymentLinkDescription: subtitle,
      acceptTips: tips_enabled,
      image: undefined,
      imageExtension: 'jpg',
      buttonColor: button_color || merchantSettings?.merchantSettings?.brand_color,
      buttonFont: _.lowerCase(button_font) || 'arial',
      buttonText: button_text || t('PaymentLinks.Detail.PayNow')
    }
  };
}

export default connect(mapStateToProps)(PaymentLinkDetailFormContainer);
