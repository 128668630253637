/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import _ from 'lodash';
import FormUtil from './FormUtil';
import { getMerchantSettings as getMerchantSettingsAction } from '../../actions/merchantSettingsActions';
import {
  API_MAPPING,
  AUTOMATIC_PAYMENTS_REMINDERS,
  FAILED_PAYMENTS_REMINDERS,
  INVOICE_AUTOMATIC_PAYMENT,
  INVOICE_DUE_DATE,
  INVOICE_FAILED_PAYMENT, INVOICE_OVERDUE,
  OVERDUE_INVOICES_REMINDERS,
  UPCOMING_DUE_DATE_REMINDERS
} from '../../constants/reminders';

const SettingsUtil = {

  categorizeReminders: function (reminders = []) {
    return reminders.reduce((acc, reminder) => {
        const response = {...acc};

        if (reminder.reminder_type === INVOICE_AUTOMATIC_PAYMENT) {
          response[AUTOMATIC_PAYMENTS_REMINDERS] = [...response[AUTOMATIC_PAYMENTS_REMINDERS], reminder]
        } else if (reminder.reminder_type === INVOICE_FAILED_PAYMENT) {
          response[FAILED_PAYMENTS_REMINDERS] = [...response[FAILED_PAYMENTS_REMINDERS], reminder]
        } else if (reminder.reminder_type === INVOICE_DUE_DATE) {
          response[UPCOMING_DUE_DATE_REMINDERS] = [...response[UPCOMING_DUE_DATE_REMINDERS], reminder]
        } else if (reminder.reminder_type === INVOICE_OVERDUE) {
          response[OVERDUE_INVOICES_REMINDERS] = [...response[OVERDUE_INVOICES_REMINDERS], reminder]
        }

        return response;

    }, {
      [AUTOMATIC_PAYMENTS_REMINDERS]: [],
      [FAILED_PAYMENTS_REMINDERS]: [],
      [UPCOMING_DUE_DATE_REMINDERS]: [],
      [OVERDUE_INVOICES_REMINDERS]: [],
    });
  },
  formatReminders: function (reminderKey, reminders = [], t) {

    const textMapping = {
      [FAILED_PAYMENTS_REMINDERS]: {
        prefix: t('ReceiptSettingsForm.NotifyCustomersPrefix'),
        suffix: t('ReceiptSettingsForm.AfterPaymentFailedSuffix'),
        removable: true,
      },
      [UPCOMING_DUE_DATE_REMINDERS]: {
        prefix: t('ReceiptSettingsForm.NotifyCustomersPrefix'),
        suffix: t('ReceiptSettingsForm.BeforeDueDateSuffix'),
        removable: true,
      },
      [OVERDUE_INVOICES_REMINDERS]: {
        prefix: t('ReceiptSettingsForm.NotifyCustomersPrefix'),
        suffix: t('ReceiptSettingsForm.AfterDueDateSuffix'),
        removable: true,
      },
      [AUTOMATIC_PAYMENTS_REMINDERS]:{
        prefix: t('ReceiptSettingsForm.NotifyCustomersPrefix'),
        suffix: t('ReceiptSettingsForm.BeforePaymentSuffix'),
        removable: true,
      },
    };

    const groupedByDayReminders = reminders.reduce((acc, reminder) => {
      const key = reminder.days_offset;
      const transportType = reminder.transport_type;

      if (reminderKey === OVERDUE_INVOICES_REMINDERS && reminder.days_offset === 0) {
        return {
          ...acc,
          [key]: {
            text: t('ReceiptSettingsForm.OnDueDate'),
            removable: false,
            [transportType]: true,
            ...acc[key],
          }
        }
      }
      return {
        ...acc,
        [key]: {
          ...textMapping[reminderKey],
          days: reminder.days_offset,
          [transportType]: true,
          ...acc[key],
        }
      }
    }, {});

    let parseReminders = Object.values(groupedByDayReminders).map(reminder => {
      if (reminder.email === undefined) {
        return { ...reminder, email: false };
      } else if (reminder.sms === undefined) {
        return { ...reminder, sms: false };
      }
      return reminder
    })

    if (!Boolean(groupedByDayReminders[0]) && reminderKey === OVERDUE_INVOICES_REMINDERS) {
        const dueDate = {
          sms: false,
          email: false,
          removable: false,
          text: t('ReceiptSettingsForm.OnDueDate'),
        };

        parseReminders = [dueDate, ...parseReminders];
    }

    return parseReminders
  },
  parsedAutomatedReminders: function (data)  {
    const merchantSettingsData = data ? _.cloneDeep(data) : null;
    let merchantSettings = merchantSettingsData;

    if (merchantSettingsData) {
      merchantSettings = {
        ...merchantSettingsData,
        automatedReminders:
          SettingsUtil.categorizeReminders(merchantSettingsData?.automatedReminders)
      };
    }
    return merchantSettings;
  },
  createReceiptSettingsPayload: function (values, logo, custom_logo, color) {
    const payload = {};
    const cleanObject = this.cleanReceiptObject(values);

    switch (values.receipt_options) {
      case 'display':
        cleanObject.receipt_options_enabled = true;
        cleanObject.receipt_always_print = false;
        break;
      case 'skip_print':
        cleanObject.receipt_options_enabled = false;
        cleanObject.receipt_always_print = true;
        break;
      case 'skip':
        cleanObject.receipt_options_enabled = false;
        cleanObject.receipt_always_print = false;
        break;
      default:
        cleanObject.receipt_options_enabled = true;
        cleanObject.receipt_always_print = false;
    }
    cleanObject.street_address = {
      address1: values.address,
      city: values.city,
      state: values.state,
      zip_code: values.zip
    };

    cleanObject.phone_number = values.phone_number && values.phone_number.toString().replace(/[^\d]/g, '');
    cleanObject.bcc_email_addresses = values.bccEmailAddresses && this.joinEmails(values.bccEmailAddresses);
    cleanObject.logo = logo;
    cleanObject.custom_logo = custom_logo;
    cleanObject.cf_logo = !!values.cf_logo;
    cleanObject.cf_logo_custom = !!values.cf_logo_custom;

    const parseReminders = (type) => (reminders = []) => {
      const basePayload = {
        reminder_type: API_MAPPING[type],
      };

      const payload = reminders.map((reminder => {
        const transportTypes = [];

        let daysOffset = parseInt(reminder.days);

        if (reminder.text?.includes('on the due date')) daysOffset = 0;

        _.isBoolean(reminder.sms) && reminder.sms && transportTypes.push('sms');
        _.isBoolean(reminder.email) && reminder.email && transportTypes.push('email');

        return transportTypes.map(transportType => ({
          ...basePayload,
          transport_type: transportType,
          days_offset: daysOffset
        }));
      }));

      return _.flatten(payload);
    }

    const automaticReminderValues =
      values['automatic_payments_reminders_list_toggle'] ?
      values[UPCOMING_DUE_DATE_REMINDERS]  :
      values[AUTOMATIC_PAYMENTS_REMINDERS];

    const automaticPaymentValues =
      parseReminders(AUTOMATIC_PAYMENTS_REMINDERS)(automaticReminderValues);

    const failedPaymentValues =
      parseReminders(FAILED_PAYMENTS_REMINDERS)(values[FAILED_PAYMENTS_REMINDERS]);

    const upcomingDueDateValues =
      parseReminders(UPCOMING_DUE_DATE_REMINDERS)(values[UPCOMING_DUE_DATE_REMINDERS]);

    const overdueInvoicesValues =
      parseReminders(OVERDUE_INVOICES_REMINDERS)(values[OVERDUE_INVOICES_REMINDERS]);

    cleanObject.automated_reminder_settings = [
      ...automaticPaymentValues,
      ...failedPaymentValues,
      ...upcomingDueDateValues,
      ...overdueInvoicesValues
    ];


    if (color) {
      cleanObject.brand_color = color;
    }
    payload.merchant_settings = cleanObject;
    return payload;
  },

  createPayload: function (values, edgeUpgrade, resetResetInvoiceNumbering) {

    const payload = {};

    const cleanObject = this.cleanSettingsObject(values);

    cleanObject.tip_default_values = this.createTipsArray(values);

    cleanObject.cash_discount_fixed_amount = values.cash_discount_fixed_amount ? Number(values.cash_discount_fixed_amount) : 0;
    cleanObject.cash_discount_amount_percent = values.cash_discount_amount_percent ? Number(values.cash_discount_amount_percent) : 0;
    cleanObject.cash_discount_display_cash_price = values.cash_discount_display_cash_price;

    cleanObject.theme = parseInt(values.theme);

    cleanObject.custom_invoice_numbering_enabled = values.custom_invoice_numbering_enabled;
    cleanObject.custom_invoice_starting_number = resetResetInvoiceNumbering ? parseInt(values.custom_invoice_starting_number) : null;
    cleanObject.custom_invoice_restart_batches = values.custom_invoice_restart_batches;
    cleanObject.custom_invoice_apply_to_portfolio = values.custom_invoice_apply_to_portfolio;
    cleanObject.invoice_number_required = values.invoice_number_required;
    cleanObject.allow_override_sold_by = values.allow_override_sold_by;

    cleanObject.ignore_avs_failure = !values.ignore_avs_failure;

    cleanObject.tax_rate = values.tax_rate ? Number(values.tax_rate.replace('%', '')) : 0;

    cleanObject.manual_capture = _.isNull(values.manual_capture) ? false : values.manual_capture;

    cleanObject.manual_capture_time = values.set_closing_timer && !!values.manual_capture_time ? FormUtil.handleManualCaptureTime(values.manual_capture_time) : null;

    if (edgeUpgrade) {
      payload.edge_settings = {
        is_preferred_edge: true
      }
    }

    if (values.hasOwnProperty('is_preferred_edge')) {
      payload.edge_settings = {
        is_preferred_edge: values.is_preferred_edge
      };
      delete cleanObject.is_preferred_edge;
    }

    // add pin_enabled and pin_timeout when PA-11897 is done
    payload.user_settings = {
      theme: cleanObject.theme
    };

    // add pin_enabled and pin_timeout when PA-11897 is done
    delete cleanObject.theme;

    let new_gift_card_preset_amounts = [];

    cleanObject.gc_quantity = values.gc_type ? parseInt(values.gc_quantity) : null;

    for(let i = 0; i <= 3; i++) {
      values[`gift_card_preset_${i+1}`] = !!values[`gift_card_preset_${i+1}`] ? values[`gift_card_preset_${i+1}`].toString().replace('$', '').replace(/,/g, '') : '';
      new_gift_card_preset_amounts.push(!!values[`gift_card_preset_${i+1}`] ? Number(values[`gift_card_preset_${i+1}`]).toFixed(2) : 0);
    }

    cleanObject.gc_preset_amounts = !!values.theme && values.theme.toString() === '0' ? `[${new_gift_card_preset_amounts}]` : values.gc_preset_amounts;

    cleanObject.keep_gc_on_top = values.keep_gc_on_top;
    cleanObject.add_gc_to_favorites = values.add_gc_to_favorites;

    if (values.ach_notification_revoke_type) {
      cleanObject.ach_notification_revoke_type = {
        mail: !!values.ach_notification_revoke_type.find((element) => element === 'mail'),
        email: !!values.ach_notification_revoke_type.find((element) => element === 'email'),
        telephone: !!values.ach_notification_revoke_type.find((element) => element === 'telephone')
      };
    }

    if (values.ach_notification_revoke_days) {
      const [revokeDays] = values.ach_notification_revoke_days;
      cleanObject.ach_notification_revoke_days = parseInt(revokeDays);
    }

    payload.merchant_settings = cleanObject;

    return payload;

  },

  createClosingEmailPayload: function (values) {

    const payload = {};

    payload.merchant_settings = {
      tip_adjust_manual_notification_enabled: Boolean(values?.tip_adjust_manual_notification_enabled),
      manual_close_notifications_enabled: Boolean(values?.manual_close_notifications_enabled),
      send_login_attempt_email: Boolean(values?.send_login_attempt_email)
    };

    return payload;
  },

  createReputationNotificationPayload: function (values) {
    const payload = {
      merchant_settings: {
        reputation_management_alert_emails: values.email_addresses
      }
    };
    return payload;
  },

  cleanSettingsObject: function (object) {

    //valid properties for put settings endpoint
    const validProps = ['theme', 'express_view_enabled', 'express_category_enabled', 'is_geotax_enabled', 'is_auto_pax_print_closing_reports', 'tax_rate', 'express_item_taxable', 'store_forward_enabled', 'tickets_enabled', 'print_ticket_with_price', 'allow_pin_debit', 'pin_debit_enabled', 'ebt_enabled', 'ignore_avs_failure', 'barcode_search_enabled', 'is_signature_required', 'paper_signature_enabled', 'is_tip_enabled', 'combine_tips_signature_enabled', 'is_preferred_edge', 'cash_discount_enabled', 'cash_discount_per_transaction_enabled', 'cash_discount_display_cash_price', 'cash_discount_fixed_amount', 'cash_discount_amount_percent', 'accept_cash_payments', 'ach_notification_revoke_days', 'ach_notification_revoke_type'];

    return _.pick(object, validProps);

  },

  cleanReceiptObject: function (object) {

    //valid properties for put receipt settings endpoint
    const validProps = ['cf_logo', 'cf_logo_custom', 'merchant_name', 'website', 'email_address', 'should_bcc', 'customer_rating_enabled', 'esn_enabled', 'display_sold_by', 'display_company_logo', 'social_media_enabled', 'facebook_link', 'instagram_link', 'twitter_link', 'yelp_link', 'receipt_disclaimer'];

    return _.pick(object, validProps);

  },

  splitEmails: function (emailArray) {
    return emailArray.join(', ');
  },

  joinEmails: function (emailChain) {
    return emailChain.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9+._-]+\.[a-zA-Z0-9._-]+)/gi);
  },

  getImgExt: function (string) {
    const char = string.toString().charAt(0);
    let ext = '';
    switch(char) {
      case '/':
        ext = 'jpeg';
        break;
      case 'i':
          ext = 'png';
        break;
    }
    return ext;
  },
  bankingAccountsFormatter: (currentValues, formValues) => {
    let result = false;

    if(!!currentValues) {

      const formattedAccounts = JSON.parse(JSON.stringify(currentValues));
      formattedAccounts[formValues.bankAccountKind].type = formValues.accountType;
      formattedAccounts[formValues.bankAccountKind].account = formValues.account;
      formattedAccounts[formValues.bankAccountKind].routing = formValues.routingNumber;

      if (formValues.useForAll) {
        return ({
          settlementAccount: formValues.account,
          settlementRouting: formValues.routingNumber,
          settlementType: formValues.accountType,
          chargebackAccount: formValues.account,
          chargebackRouting: formValues.routingNumber,
          chargebackType: formValues.accountType,
          billingAccount: formValues.account,
          billingRouting: formValues.routingNumber,
          billingType: formValues.accountType
        });
      }

      result = {
        settlementAccount: formattedAccounts.settlement.account,
        settlementRouting: formattedAccounts.settlement.routing,
        settlementType: formattedAccounts.settlement.type,
        chargebackAccount: formattedAccounts.chargeback.account,
        chargebackRouting: formattedAccounts.chargeback.routing,
        chargebackType: formattedAccounts.chargeback.type,
        billingAccount: formattedAccounts.billing.account,
        billingRouting: formattedAccounts.billing.routing,
        billingType: formattedAccounts.billing.type
      }
    }

    return result;

  },
  createTipObject: (amount, type) => {
    const value = Number(amount) || 0;
    type = Number(type) || 0
    return {value, type, isDefault: false}
  },
  createTipsArray: function (formValues) {
    const tips = [
      {value: 0, type: null},
      {value: 0, type: null},
      {value: 0, type: null}
    ];

    if (!formValues.tip_one_amount && !formValues.tip_two_amount && !formValues.tip_three_amount) {
      return [];
    }

    if (formValues.tip_one_amount) {
      const tip = this.createTipObject(formValues.tip_one_amount, formValues.tip_one_type);
      tips.splice(0, 1, tip);
    }

    if (formValues.tip_two_amount) {
      const tip = this.createTipObject(formValues.tip_two_amount, formValues.tip_two_type);
      tips.splice(1, 1, tip);
    }

    if (formValues.tip_three_amount) {
      const tip = this.createTipObject(formValues.tip_three_amount, formValues.tip_three_type);
      tips.splice(2, 1, tip);
    }

    return tips;
  },
  getMerchantSettings: async function (props) {

    const { dispatch, merchantSettings, user } = props;
    let getMerchantSettings;

    if (!merchantSettings?.merchantSettings) {
      getMerchantSettings = await dispatch(getMerchantSettingsAction(user));
    } else {
      getMerchantSettings = {response: {merchant_settings: merchantSettings.merchantSettings}};
    }
    return getMerchantSettings;
  }
};

export default SettingsUtil;
