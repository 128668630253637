/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { useEffect, useRef } from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { change, submit } from 'redux-form';
import _ from 'lodash';
import numeral from 'numeral';
import {
  Paper,
  TableContainer,
  Box
} from '@mui/material';
import InvoicesErrorTableCell from './invoicesErrorTableCell';
import UpdateSpinner from '../../UpdateSpinner';
import TableHeader from '../../shared/TableHeader';
import InvoiceForm from '../../shared/enhancedInvoices/InvoiceForm';
import Modal from '../../shared/Modal';
import BottomBar from '../../shared/BottomBar';
import InvoiceUtil, {
  INVOICE_FORM_ID, PaymentFrequency, PaymentMethod
} from '../../util/InvoiceUtil';
import FormatTextUtil from '../../util/FormatTextUtil';
import DateUtil from '../../util/DateUtil';
import { setCustomer } from '../../../actions/customerActions';
import { fixBulkInvoiceErrors } from '../../../actions/bulkInvoicesActions';
import { BulkInvoicesStyles } from '../../../jss/inlineStyles'

const InvoicesErrorTable = (props) => {

  const {
    dispatch, rows, headers, isCompleted,
    submitFailed, syncErrors, isFetching,
    t, user, bulkImports, toggleConfirmationPage
  } = props;

  const errorTableContainerRef = useRef(null);
  const listRowHeight = 71;
  const overscanRowCount = 10;
  const headerHeight = 58;

  const showFillOutRequiredFieldsError = submitFailed && Object.keys(syncErrors).length > 0;

  const [selectedInvoice, setSelectedInvoice] = React.useState({});
  const [selectedInvoiceIndex, setSelectedInvoiceIndex] = React.useState(0);
  const [isEditInvoiceModalOpen, setIsEditInvoiceModalOpen] = React.useState(false);

  useEffect(() => {
    errorTableContainerRef.current.scrollRight = errorTableContainerRef?.current?.scrollWidth;
  }, []);

  const headerRenderer = ({dataKey, label}) => (
    <TableHeader name={label} attribute={dataKey}/>
  );

  const cellRenderer = ({ dataKey, rowData }) => {
    const cellData = rowData.find(row => row.column === dataKey) ?? {};
    return <InvoicesErrorTableCell cellData={cellData} />;
  };

  const getRowValue = (rowData, column) => {
    return rowData.find(row => row.column === column).value;
  };

  const openEditInvoiceModal = ({ rowData, index }) => {
    const isSeries = FormatTextUtil.toLowerCase(getRowValue(rowData, 'Frequency')) === 'recurring';
    const customerEmail = getRowValue(rowData, 'Customer Email Address');

    const formattedSelectedInvoice = {
      email_addresses: customerEmail ? [customerEmail] : [],
      phone_number: getRowValue(rowData, 'Customer Phone Number'),
      first_name: getRowValue(rowData, 'Customer First Name'),
      last_name: getRowValue(rowData, 'Customer Last Name'),
      name: getRowValue(rowData, 'Invoice Name'),
      invoice: getRowValue(rowData, 'Invoice Number'),
      description: getRowValue(rowData, 'Description'),
      item_unit_price: [getRowValue(rowData, 'Payment Amount')],
      item_tax_rate: [getRowValue(rowData, 'Tax Rate')],
      isSeries,
      created_date: DateUtil.getTodayWithoutTime(),
      due_date: getRowValue(rowData, 'Due Date'),
      payment_method: getRowValue(rowData, 'Payment Method'),
      is_draft: true
    };

    const allowTip = FormatTextUtil.toLowerCase(getRowValue(rowData, 'Allow customer to add tip')) === 'true';
    const cashDiscounting = FormatTextUtil.toLowerCase(getRowValue(rowData, 'Cash Discounting')) === 'true' ? 1 : 0;
    const sendDate = getRowValue(rowData, 'Send Date');
    const lastSendDate = getRowValue(rowData, 'Last Send Date');

    if (isSeries) {
      formattedSelectedInvoice.allow_tip = allowTip;
      formattedSelectedInvoice.service_fee_amount = cashDiscounting;

      if (!!sendDate && FormatTextUtil.toLowerCase(sendDate) !== 'immediately') {
        formattedSelectedInvoice.start_date = sendDate;
      }

      formattedSelectedInvoice.frequency = `${getRowValue(rowData, 'Repeat Every')} ${FormatTextUtil.toLowerCase(getRowValue(rowData, 'Cadence'))}`

      if (!!lastSendDate && FormatTextUtil.toLowerCase(lastSendDate) !== 'never') {
        formattedSelectedInvoice.end_date = lastSendDate;
      }
    } else {
      formattedSelectedInvoice.inv_allow_tip = allowTip;
      formattedSelectedInvoice.service_fee_amt = cashDiscounting;

      if (!!sendDate && FormatTextUtil.toLowerCase(sendDate) !== 'immediately') {
        formattedSelectedInvoice.send_date = sendDate;
      }
    }

    setSelectedInvoice(formattedSelectedInvoice);
    setSelectedInvoiceIndex(index);
    setIsEditInvoiceModalOpen(true);
  };

  const closeEditInvoiceModal = () => {
    setIsEditInvoiceModalOpen(false);
  };

  const clearSelectedCustomer = () => {
    dispatch(setCustomer({}));
  };

  const clearCustomerFields = (includeEmail = true) => {
    const fieldsToClean = [
      'customer_phone',
      'customer_first',
      'customer_last',
      'customer_street_address_1',
      'customer_street_address_2',
      'customer_state',
      'customer_zip',
      'customer_city'
    ];

    if (includeEmail) {
      fieldsToClean.unshift('customer_email_addresses');
    }

    fieldsToClean.forEach((field) => {
      dispatch(change(INVOICE_FORM_ID, field, null));
    });
  };

  const handleSaveInvoice = () => {
    dispatch(submit(INVOICE_FORM_ID));
  };

  const submitEditInvoiceForm = async (formValues) => {
    const values = InvoiceUtil.checkFormValues(formValues);

    const isSeries = values.frequency === PaymentFrequency.SERIES;

    const invoiceRowValues = {
      'Payment Amount': _.round(numeral(values.amount).value(), 2).toString(),
      'Cash Discounting': (!!values.cashDiscounting).toString(),
      'Description': values.description,
      'Send Date': InvoiceUtil.getSendDate(values),
      'Due Date': InvoiceUtil.getDueDate(values),
      'Customer Email Address': values.customer_email_addresses?.length > 0 ? values.customer_email_addresses[0] : '',
      'Customer First Name': values.customer_first,
      'Customer Last Name': values.customer_last,
      'Invoice Name': values.name,
      'Invoice Number': values.invoice_number,
      'Payment Method': values.paymentMethod === PaymentMethod.SEND_TO_CUSTOMER ? 'Send to Customer' : 'Card on File',
      'Customer Phone Number': FormatTextUtil.formatWholeNumber(values.customer_phone),
      'Tax Rate': String(values.taxRate).replace(/%/g, ''),
      'Allow customer to add tip': (!!values.allow_tip).toString()
    };

    if (isSeries) {
      const recurringInvoiceValues = InvoiceUtil.parseRecurringInvoice(values);

      invoiceRowValues['Frequency'] = 'Recurring';
      invoiceRowValues['Last Send Date'] = recurringInvoiceValues.end_date ? DateUtil.extractDate(recurringInvoiceValues.end_date) : values.ends;
      invoiceRowValues['Repeat Every'] = recurringInvoiceValues.length;
      invoiceRowValues['Cadence'] = recurringInvoiceValues.period;
    } else {
      invoiceRowValues['Frequency'] = 'One Time';
    }

    const readyToSendInvoice = {
      rowIndex: selectedInvoiceIndex,
      rowValue: invoiceRowValues
    }

    await dispatch(fixBulkInvoiceErrors(user, bulkImports?.bulkInvoiceId, readyToSendInvoice));

    if (isCompleted && rows.length <= 1) {
      toggleConfirmationPage();
    } else {
      closeEditInvoiceModal();
    }
  };

  return (
    <Paper
      data-testid='invoicesErrorTable'
      elevation={0}
      sx={BulkInvoicesStyles.table.pageWrapper}
    >
      {isFetching && <UpdateSpinner />}
      <Modal
        fullScreen
        hideActions
        isClosableIconEnable
        onClose={closeEditInvoiceModal}
        id='editInvoiceModal'
        title={t('EditInvoice')}
        externalClassName='createInvoiceModal'
        open={isEditInvoiceModalOpen}
        onCloseIcon={closeEditInvoiceModal}
        paperProps={{
          sx: {
            maxWidth: '100%'
          }
        }}
      >
        <InvoiceForm
          { ...props }
          formType={'bulkInvoicing'}
          clearCustomerFields={clearCustomerFields}
          clearSelectedCustomer={clearSelectedCustomer}
          onSubmit={submitEditInvoiceForm}
          selectedInvoice={selectedInvoice}
        />
        <BottomBar
          className='wrap-margin'
          rightContent={showFillOutRequiredFieldsError && <span className='settingsErrorBottom noWrap' style={{ margin: '17px' }}>{t('SubmitButtonClickedError')}</span>}
          rightActions={[
            {
              key: 'cancel',
              label: t('Cancel'),
              onClick: closeEditInvoiceModal,
              variant: 'text'
            },
            {
              key: 'save',
              label: isCompleted ? t('SendInvoice') : t('Save'),
              onClick: handleSaveInvoice,
              variant: 'contained'
            }
          ]}
        />
      </Modal>
      <TableContainer ref={errorTableContainerRef} sx={BulkInvoicesStyles.table.invertedScrollBar} data-test-id={'error-table-container'}>
        <Box sx={BulkInvoicesStyles.table.bulkInvoicesTableContainer} className='bulkInvoicesTableContainer errorTable'>
          <AutoSizer className='infiniteListAutoSizerBulkInvoices' disableHeight>
            {({ width }) => (
              <Table
                className='bulkInvoiceReviewTable'
                gridStyle={{ outline: 'none' }}
                width={width}
                headerHeight={headerHeight}
                height={530}
                onRowClick={openEditInvoiceModal}
                overscanRowCount={overscanRowCount}
                rowHeight={listRowHeight}
                rowCount={rows.length}
                rowGetter={({ index }) => rows[index]}
              >
                {headers.map((header) => (
                  <Column
                    key={header}
                    dataKey={header}
                    label={t(`BulkInvoices.ErrorHeaders.${header}`)}
                    headerRenderer={headerRenderer}
                    cellRenderer={cellRenderer}
                    width={width}
                  />
                ))}
              </Table>
            )}
          </AutoSizer>
        </Box>
      </TableContainer>
    </Paper>
  )
}
export default InvoicesErrorTable;
