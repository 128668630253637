/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { Box } from '@mui/material';
import Checkbox from '../shared/Checkbox';
import _ from 'lodash';
import moment from 'moment';
import {
  getMerchantStatementPdf,
  getMerchantStatements
} from '../../actions/statementsActions';
import { setVisibilityFilter } from '../../actions/userExperienceActions';
import Button from '../shared/Button';
import FilterPanel from '../shared/FilterPanel';
import IconUtil from '../util/IconUtil';
import PdfExporter from '../util/PdfExporter';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import withTranslate from '../../hoc/withTranslate';
import Page from '../shared/Page';
import { statementsStyles } from '../../jss/statementsStyles';
import { tableStyles, statementsContainerStyles } from '../../jss/table';
import JSZip from 'jszip';
import { toastr } from 'react-redux-toastr';

export class StatementsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      statements: [],
      allSelected: false
    };

    this.loadData = this.loadData.bind(this);
    this.formatStatements = this.formatStatements.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.clear = this.clear.bind(this);
    this.download = this.download.bind(this);
    this.downloadPdfStatement = this.downloadPdfStatement.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.generateStatementsExportFilename = this.generateStatementsExportFilename.bind(this);
    this.asyncFetchPdfsForStatements = this.asyncFetchPdfsForStatements.bind(this);
    this.zipAndDownloadStatementsAsPdfs = this.zipAndDownloadStatementsAsPdfs.bind(this);
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData(props) {
    props.dispatch(getMerchantStatements(props.user)).then(() => {
      const groupedStatements = _.groupBy(this.props.statements.filteredData, (statement) => moment(statement.date).format('YYYY'));
      const formattedStatements = this.formatStatements(groupedStatements);

      if (formattedStatements.length > 0) {
        this.props.dispatch(setVisibilityFilter({
          property: 'year',
          value: formattedStatements[0].year
        }, 'filter'));

        if (props.location.state?.shouldDownloadMostRecentStatement) {
          this.downloadPdfStatement(formattedStatements[0].statements[0]);
        }
      }
    });
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter('', 'filter'));
  }

  formatStatements(statementsByYear) {

    let result = [];

    for (let year in statementsByYear) {

      let statements = [];

      statementsByYear[year].forEach(statementsGroup => {
        statementsGroup.backends.forEach((backend) => {
          statements.push({date: statementsGroup.date, backend: backend});
        });
      });

      result.push({year, statements});

    }

    return _.orderBy(result, ['year'], ['desc']);

  }

  onCheck(statement) {

    let array = this.state.statements;

    const statementKey = statement.date + '*' + statement.backend;

    if (array.includes(statementKey)) {
      array = array.filter(item => item !== statementKey);
      this.setState({statements: array});
    } else {
      array.push(statementKey);
      this.setState({statements: array});
    }
  }

  selectAll(data) {
    if(this.state.statements.length) {
      this.clear();
    } else {
      const array = [];
      const yearToLook = this.props.visibilityFilter.filter?.value || data[0].year;
      if (this.props.visibilityFilter.search === '') {
        const obj = data.find(({year}) => year === yearToLook);
        obj?.statements.forEach((s) => {
          const statementKey = s.date + '*' + s.backend;
          array.push(statementKey);
        });
      } else {
        data.forEach(row => {
          row.year === yearToLook && row?.statements.forEach(s => {
            const statementKey = s.date + '*' + s.backend;
            array.push(statementKey);
          });
        });
      }
      this.setState({allSelected: true, statements: array});
    }
  }

  clear() {
    this.setState({statements: [], allSelected: false});
  }

  generateStatementsExportFilename() {
    const now = moment();
    const formattedDate = now.format('YYYY-MM-DD HH:mm:ss'); 
    return `${formattedDate} Exported Statements`
  }

  async asyncFetchPdfsForStatements(statements) {

    const allFetchedStatementsPdfsPromises = [];

    for (const statementKey of statements) {

      const splitKey = statementKey.split('*');
      const date = splitKey[0];
      const backend = splitKey[1];
      const statement = {date: date, backend: backend};

      allFetchedStatementsPdfsPromises.push(new Promise((resolve, reject)=> {
        this.props.dispatch(getMerchantStatementPdf(this.props.user, statement.date, statement.backend)).then((statementPdf)=>{
          resolve({ statement, statementPdf })
        }).catch((e)=>{reject(e)})
      }));

    }

    return Promise.all(allFetchedStatementsPdfsPromises);
  }

  async zipAndDownloadStatementsAsPdfs(statements) {

    const zip = new JSZip();

    const statementPdfs = await this.asyncFetchPdfsForStatements(statements);
      
    statementPdfs.forEach(({statementPdf, statement}) => {
      if (statementPdf.response && statementPdf.response.data) {

        const arrayBuffer = new Uint8Array(statementPdf.response.data).buffer;
        const pdfBlob = new Blob([arrayBuffer], {
          type: 'application/pdf'
        });

        const filename = `${statement.date}-${statement.backend}.pdf`;

        zip.file(filename, pdfBlob);
      }
    });

    zip.generateAsync({
      type: 'blob',
      streamFiles: true
    }).then((zipFile)=>{
      const filename = this.generateStatementsExportFilename();
      PdfExporter.triggerDownload(zipFile, filename);
    }).catch((e) => {
      const errorMessage = e?.message || JSON.stringify(e);
      toastr.error('Error', errorMessage, {
        timeOut: 3000,
        removeOnHover: false,
        icon: 'error',
        status: 'error'
      });
    });


  }

  download() {

    if (this.state.statements.length > 1) {
      this.zipAndDownloadStatementsAsPdfs(this.state.statements).catch((e)=>{
        const errorMessage = e?.message || JSON.stringify(e);
        toastr.error('Error', errorMessage, {
          timeOut: 3000,
          removeOnHover: false,
          icon: 'error',
          status: 'error'
        });
      })
    } else {
      this.state.statements.forEach((statementKey) => {

        const splitKey = statementKey.split('*');
        const date = splitKey[0];
        const backend = splitKey[1];
        const statement = {date: date, backend: backend};
  
        this.downloadPdfStatement(statement);
      });
    }

    this.clear();
  }

  downloadPdfStatement(statement) {

    return this.props.dispatch(getMerchantStatementPdf(this.props.user, statement.date, statement.backend))
      .then((statementPdf) => {
        if (statementPdf.response && statementPdf.response.data) {
          const filename = statement.date + '-' + statement.backend + '.pdf';
          PdfExporter.download(statementPdf.response.data, filename);
        }
      });
  }

  handleFilterSelection(filter) {
    this.props.dispatch(setVisibilityFilter({
      property: 'year',
      value: filter.name
    }, 'filter'));
  }

  render() {

    const { statements, visibilityFilter, t } = this.props;

    const exportIcon = IconUtil.getIcon('CustomExportIcon', '', '16px');
    const isEmpty = statements.data === null;

    const pageLoading = !!(isEmpty || statements.isFetching);

    if (pageLoading) {
      return <Page title={t('Statements')} loading />;
    }

    const groupedStatements =  _.groupBy(statements.filteredData, (statement) => moment(statement.date).format('YYYY'));
    const formattedStatements =  this.formatStatements(groupedStatements);

    const statementsList =  formattedStatements.filter((formattedStatement, formattedStatementIndex) => visibilityFilter.filter ? visibilityFilter.filter.value === formattedStatement.year : formattedStatementIndex === 0).map((formattedStatement, formattedStatementIndex) => (
      <div key={formattedStatementIndex}>
        <ul
          className='statementsList'
          id={`list${formattedStatementIndex}`}
          style={{display: 'block'}}
        >
          {formattedStatement.statements.map((statement, statementIndex) => (
            <li className='statementsItem flexRowItem' key={statementIndex}>
              <div>
                <Checkbox
                  externalClassName='statementsCheckBoxWrapper'
                  className='statementsCheckBox statementsItemCheckbox'
                  input={{
                    value: this.state.statements.includes(statement.date + '*' + statement.backend),
                    onChange: () => this.onCheck(statement)
                  }}
                />
              </div>
              <div className='statementsData'>
                    <span className='statementsInfo'>
                      {`${moment(statement.date).format('MMMM')} ${moment(statement.date).format('YYYY')}`}
                    </span>
              </div>
              <div>
                    <span className='statementsProcessor'>
                      {statement.backend}
                    </span>
              </div>
              <div className={'centered'}>
                {!this.state.statements.length ?
                  <Button
                    className='statementsLink linkButton'
                    variant='text'
                    startIcon={exportIcon}
                    label={t('ExportBtn')}
                    onClick={() => this.downloadPdfStatement(statement)}
                  /> : null
                }
              </div>
            </li>
          ))}
        </ul>
      </div>
    ));

    const downloadBar =  (
      <div className='statementsBar'>
        <Button onClick={this.clear} label={t('Cancel')} variant='text' />
        <Button
          onClick={this.download}
          label={t('DownloadButton', { statements: this.state.statements.length })}
        />
      </div>
    );

    const searchBar =  (
      <SearchBarAccessory {...this.props} searchPlaceholder={t('SearchStatements')} />
    );

    const filterData =  formattedStatements.map((formattedStatement, formattedStatementIndex) => {
      return {
        name: formattedStatement.year,
        id: formattedStatementIndex
      };
    });

    const filterPanel =  (
      <FilterPanel
        {...this.props}
        filterData={filterData}
        selectFilterCallback={this.handleFilterSelection}
      />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      }
    ];

    return (
      <Box className='statements pageWrap' sx={statementsStyles}>
        <Page
          accessories={pageAccessories}
          loading={pageLoading}
          title={t('Statements')}
        >
          <div className='flexContainer flexContainerResponsiveLayout'>
            {filterPanel}
            <div className='masterListContainer'>
              {this.state.statements.length > 0 && downloadBar}
              <Box sx={tableStyles}>
                <Box sx={statementsContainerStyles}>
                  {formattedStatements.length ?

                    (<div>


                      <div className='flexRowHeader'>
                        <div>
                          <Checkbox
                            className='statementsCheckBox statementsHeaderCheckbox'
                            input={{
                              value: this.state.allSelected,
                              onChange: () => this.selectAll(formattedStatements)
                            }}
                          />
                        </div>
                        <div className='statementsData'>{t('Month')}</div>
                        <div>{t('Processor')}</div>
                        <div className='centered'>{t('Action')}</div>
                      </div>

                      {statementsList}

                    </div>)

                    : <h2 className='statementsEmpty'>{t('StatementsEmpty')}</h2>}
                </Box>
              </Box>
            </div>
          </div>
        </Page>
      </Box>
    );
  }

}

export default withTranslate(StatementsComponent);
