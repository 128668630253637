/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, {Component} from 'react';
import MasterDetailLayout from '../MasterDetailLayout';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import {
  setVisibilityFilter,
  toggleAllAccounts
} from '../../actions/userExperienceActions';
import {
  getDisputeDetails,
  getDisputes,
  setDispute,
  setDisputeAction,
  updateDispute
} from '../../actions/disputesActions';
import NoData from '../NoData';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import FilterPanel from '../shared/FilterPanel';
import DisputeDetail from './DisputeDetail';
import IconUtil from '../util/IconUtil';
import LabelUtil from '../util/LabelUtil';
import DisputesFaq from './DisputesFaq';
import {DisputesResponseGlossaryCodes} from './DisputesResponseGlossaryCodes';
import TableHeader from '../shared/TableHeader';
import Modal from '../shared/Modal';
import FileUtil from '../util/FileUtil';
import Cancelcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import numeral from 'numeral';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import UserUtil from '../util/UserUtil';
import DateUtils from '../util/DateUtil';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import CsvUtil from '../util/CsvUtil';
import FormatTextUtil from '../util/FormatTextUtil';
import CsvExporter from '../util/CsvExporter';
import Bugsnag from '@bugsnag/js';
import Page from '../shared/Page';
import ButtonAccessory from '../appBar/accessories/ButtonAccesory';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';
import { disputesStyles } from '../../jss/inlineStyles';

const FAQ_LABEL = 'DisputesFAQ';
const REASON_CODE_GLOSSARY = 'Reason Code Glossary';
const POLICIES = 'Refund & Chargeback Policies';
const defaultDateSelectionPeriod = 'This Week';

export default class Disputes extends Component {

  constructor(props) {
    super(props);

    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.handleOpenFaq = this.handleOpenFaq.bind(this);
    this.handleCloseFaqDialog = this.handleCloseFaqDialog.bind(this);
    this.handleDisputeSubmit = this.handleDisputeSubmit.bind(this);
    this.toggleAllAccounts = this.toggleAllAccounts.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptDispute = this.handleAcceptDispute.bind(this);
    this.handleOpenRebut = this.handleOpenRebut.bind(this);
    this.handleCloseRebut = this.handleCloseRebut.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.toggleGlossaryDialog = this.toggleGlossaryDialog.bind(this);

    const dateRange = props.userExperience.selectedDate.dateRange ? props.userExperience.selectedDate.dateRange :
      DateUtils.getDateRange(defaultDateSelectionPeriod);

    this.state = {
      filterData: [
        {name: 'All Disputes'},
        {name: 'Action Required'},
        {name: 'Under Review'},
        {name: 'Closed'},
        {name: REASON_CODE_GLOSSARY, icon: 'InfoIcon', openModal: true, border: true},
        {name: FAQ_LABEL, icon: 'InfoIcon', openModal: true},
        {name: POLICIES, openModal: true}
      ],
      selectedIndex: null,
      openFaq: false,
      openGlossary: false,
      openRebut: false,
      openFilter: false,
      selectedAccount: 'default',
      dateRange: dateRange
    };
  }

  componentDidMount() {

    const { dispatch, userExperience } = this.props;

    this.loadData(userExperience.allAccounts);

    dispatch(setVisibilityFilter({
      property: 'status',
      value: 'All Disputes'
    }, 'filter'));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    /* istanbul ignore else */
    if (this.props.merchantSettings.customReportStartTime !== nextProps.merchantSettings.customReportStartTime) {
      const dateRange = this.props.userExperience.selectedDate.dateRange ? this.props.userExperience.selectedDate.dateRange :
        DateUtils.getPersonalizedDateRange(defaultDateSelectionPeriod, nextProps.merchantSettings.customReportStartTime);
      this.setState({dateRange: dateRange});
    }

  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
    this.props.dispatch(setVisibilityFilter(null, 'secondaryFilter'));
  }

  handleFilterSelection(filter) {

    if (filter.name === POLICIES) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: '/files/Policy on Refunding to Card Used for Original Payment - Goods and Services (2020).pdf'
      }).click();
    } else {
      if (filter.name === FAQ_LABEL) {
        this.setState({ openFaq: true });
      } else if (filter.name === REASON_CODE_GLOSSARY) {
        this.toggleGlossaryDialog();
      }
      else {
        this.setState({selectedIndex: null});
        this.props.dispatch(setVisibilityFilter({
          property: 'status',
          value: filter.name
        }, 'filter'));
      }
    }
  }

  toggleAllAccounts() {
    const showAllAccounts = !this.props.userExperience.allAccounts;
    this.props.dispatch(toggleAllAccounts(showAllAccounts));
    this.loadData(showAllAccounts);
  }

  handleDateSelection(value, dateRange) {

    const { dispatch, user, userExperience} = this.props;
    this.setState({dateRange: dateRange});
    dispatch(getDisputes(user, userExperience.allAccounts, dateRange));
  }

  handleOpenFaq() {
    this.setState({openFaq: true});
  }

  toggleGlossaryDialog() {
    this.setState(prevState => ({
      openGlossary: !prevState.openGlossary
    }));
  }

  loadData(allAccounts) {

    const { dispatch, user } = this.props;

    dispatch(getDisputes(user, allAccounts, this.state.dateRange));
  }

  showDetailForRow(row) {
    if (row) {
      let selectedIndex = row - 1;
      this.setState({selectedIndex});
      const selectedDispute = this.props.disputes.filteredData[selectedIndex];
      const selectedDisputeType = selectedDispute.isInquiry ? 'inquiry' : 'dispute';
      this.props.dispatch(getDisputeDetails(this.props.user, selectedDispute.chargeback_guid, selectedDisputeType));
      this.props.dispatch(setDispute(selectedDispute));
    } else {
      this.setState({selectedIndex: null});
    }
  }


  handleCloseFaqDialog() {
    this.setState({openFaq: false});
  }

  handleAcceptDispute() {
    return new Promise((resolve, reject) => {
        this.props.dispatch(setDisputeAction(
          {
            name: 'accept', dispute_type_code:
            this.props.disputes.selectedDispute.dispute_type_code
          }));
        resolve();
      }
    ).then(() => {
      this.handleDisputeSubmit();
    });
  }

  handleDisputeSubmit() {

    const { dispatch, disputes, user, userExperience } = this.props;

    const that = this;
    const values = {
      files: disputes.files || [],
      comments: disputes.comments,
      action: disputes.action,
      display_disclaimer: true
    };

    const guid = disputes.selectedDispute.chargeback_guid;
    const type = disputes.selectedDispute.isInquiry ? 'inquiry' : 'dispute';

    return Promise.all(values.files.map(f => {
      return FileUtil.getBase64(f.file);
    })).then(images => {
      values.files.forEach((f, i) => {
        f.base64 = images[i].base64;
        if (!f.description) {
          f.description = f.name;
        }
        delete f.file;
      });
      return dispatch(updateDispute(user, values, type, guid)).then(() => {
        that.loadData(userExperience.allAccounts);
      });
    })
    .catch((error) => {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      } else {
        Bugsnag.notify(new Error(JSON.stringify(error)));
      }
    });
  }

  handleSubmit() {
    this.setState({selectedIndex: null});
    this.loadData(this.props.userExperience.allAccounts);
    this.props.dispatch(setVisibilityFilter({
      property: 'status',
      value: 'All Disputes'
    }, 'filter'));
  }

  handleOpenRebut() {
    this.setState({openRebut: true});
  }

  handleCloseRebut() {
    this.setState({openRebut: false});
  }

  handleExportClick() {

    const labelName = LabelUtil.getLabel().text;
    const disputesJson = CsvUtil.createDisputesCsv(this.props.disputes.filteredData, this.props.user);
    CsvExporter.download(disputesJson, labelName + '_Disputes_' + moment().format('MMDDYYYY') + '.csv');

  }

  render() {
    const { disputes, userExperience, merchantSettings, user, auth, t } = this.props;
    const isEmpty = disputes.data === null  || merchantSettings.customReportStartTime === null;

    const faqDialog = (
      <Modal
        centerTitle
        confirmText={t('Close')}
        externalClassName='disputesInfoDialog'
        hideCancelButton
        onConfirm={this.handleCloseFaqDialog}
        open={this.state.openFaq}
        title={t('DisputesFAQ')}
      >
        <DisputesFaq t={t}/>
      </Modal>
    );

    const responseCodeDialog = (
      <Modal
        centerTitle
        confirmText={t('Close')}
        externalClassName='disputesInfoDialog'
        hideCancelButton
        onConfirm={this.toggleGlossaryDialog}
        open={this.state.openGlossary}
        title={t(REASON_CODE_GLOSSARY)}
      >
        <DisputesResponseGlossaryCodes t={t}/>
      </Modal>
    );

    const pageLoading = isEmpty || disputes.isFetching;

    if (pageLoading) {
      return (
        <Page title={t('Disputes')} loading/>
      );
    }

    const isCsUser = UserUtil.isUserCS(user, auth);
    const labelColor = LabelUtil.getLabelColor();

    const searchBar =  (
      <SearchBarAccessory {...this.props} searchPlaceholder={t('SearchDisputes')}/>
    );

    const filterPanel =  (
      <FilterPanel
        {...this.props}
        filterData={this.state.filterData}
        selectFilterCallback={this.handleFilterSelection}
      />
    );

    const pageAccessories = [
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'dateFilter',
        accessory: (
          <DateFilterAccessory
            {...this.props}
            dataName={'Deposits data'}
            defaultValue={this.state.dateRange.text}
            disableCompactVersion
            handleSelection={this.handleDateSelection}
            showAllTime={false}
            showPreviousCustomSelection={false}
          />
        ),
        showInMobile: true,
        hide: false
      },
      {
        name: 'allAccounts',
        accessory: (
          <ButtonAccessory
            onClick={this.toggleAllAccounts}
            endIcon={userExperience.allAccounts ?
              <CheckCircleIcon color='primary' fontSize='large'/> :
              <Cancelcon fontSize='large' />
            }
            label={t('AllAccounts')}
          />
        ),
        showInMobile: true,
        hide: !(user.data.merchantAccounts.length >= 2)
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            exportTitle={t('ExportDisputes')}
            handleSelection={this.handleExportClick}
          />
        ),
        showInMobile: true,
        hide: !(disputes.filteredData && disputes.filteredData.length > 0)
      },
    ];

    const listHeaders =  ((disputes.filteredData && disputes.filteredData.length) &&
      <TableRow className={'tableRow'}>
        <TableHeader
          attribute={'mid'}
          clickable
          headerId={'disputeHeaderOne'}
          name={t('DisputesTableHeaders.MID')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'due_date'}
          className='hideMobileOnly'
          clickable
          headerId={'disputeHeaderTwo'}
          name={t('DisputesTableHeaders.DueDate')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'original_transaction_date'}
          className='hideMobileOnly'
          clickable
          headerId={'disputeHeaderThree'}
          name={t('DisputesTableHeaders.TransactionDate')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'dispute_status'}
          clickable
          headerId={'disputeHeaderFour'}
          name={t('DisputesTableHeaders.Status')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'chargeback_amount'}
          clickable
          headerId={'disputeHeaderFive'}
          name={t('DisputesTableHeaders.DisputeAmount')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'reason_code'}
          className='hideMobileOnly'
          clickable
          headerId={'disputeHeaderSix'}
          name={t('DisputesTableHeaders.ReasonCode')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'reason_description'}
          className='hideMobileOnly'
          clickable
          headerId={'disputeHeaderSeven'}
          name={t('DisputesTableHeaders.ReasonDescription')}
          type='table'
          {...this.props}
        />
        <TableHeader
          attribute={'card_brand'}
          className='hideMobileOnly'
          clickable
          headerId={'disputeHeaderEight'}
          name={t('DisputesTableHeaders.Card')}
          type='table'
          {...this.props}
        />
      </TableRow>
    ) || null;

    const disputeRows =  disputes.filteredData && disputes.filteredData.length > 0 ?
      disputes.filteredData.map((dispute, i) => {

        const actionRequired = dispute.actionRequired;
        const isUnderReview = !actionRequired && !dispute.isClosed && dispute.isPending;

        const cardNumber = FormatTextUtil.formatDisputeMaskedCardNumber(dispute.card_number, dispute.card_brand);

        const status = actionRequired ? 'Action required' : isUnderReview ? 'Under review' : 'Closed';

        const tableRowClassName = (this.state.selectedIndex === i) ? 'tableRow selectedListRow' : 'tableRow';
        return (
          <TableRow
            className={tableRowClassName}
            key={i}
          >
            <TableCell>{dispute.mid}</TableCell>
            <TableCell className='hideMobileOnly'>{moment(dispute.due_date).format('MMM DD, YYYY')}</TableCell>
            <TableCell className='hideMobileOnly'>{moment(dispute.original_transaction_date).format('MMM DD, YYYY')}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{numeral(dispute.chargeback_amount).format('$0,0.00')}</TableCell>
            <TableCell className='hideMobileOnly'>{dispute.reason_code}</TableCell>
            <TableCell className='hideMobileOnly'>{dispute.reason_description}</TableCell>
            <TableCell className='hideMobileOnly'>{`${dispute.card_brand} ${cardNumber}`}</TableCell>
          </TableRow>
        );
      }
    ) : (
      <NoData
        text={t('NoDisputes')}
        type='table'
      />
    );

    const disputeList = (
      <TableBody>
        {listHeaders}
        {disputeRows}
      </TableBody>
    );

    const detailTitle =  disputes.selectedDispute && disputes.selectedDispute.dispute_type;
    const detailSubtitle =  disputes.selectedDispute && (
      <div className='disputesTitle'>
        <div className='disputesContainer'>
          <h1>{disputes.selectedDispute.merchant_name}</h1>
          <p>
            <span>{IconUtil.creditCardIcon(disputes.selectedDispute.card_brand_code)}</span>
            <span>{FormatTextUtil.formatDisputeMaskedCardNumber(disputes.selectedDispute.card_number, disputes.selectedDispute.card_brand)}</span>
            <span>|</span>
            <span>{t('DisputesDetail.DisputedAmount', { amount: numeral(disputes.selectedDispute.chargeback_amount).format('$0,0.00')})}</span>
          </p>
        </div>
      </div>);

    const helpIcon = IconUtil.getIcon('HelpIcon', labelColor);

    const detailOptions = [
      {
        onClick: this.handleOpenFaq,
        text: t('DisputesDetail.CompellingEvidence'),
        icon: helpIcon
      },
    ];

    let disputeDetailPanel = null;
    if ( this.state.selectedIndex !== null) {
      if (disputes.selectedDispute.isInquiry) {
        disputeDetailPanel = (
          <DetailPanel
            confirmationText={t('Respond')}
            disabled={isCsUser}
            heading={detailTitle}
            isNew={disputes.selectedDispute.actionRequired}
            onClick={this.handleOpenRebut}
            subheading={detailSubtitle}
          >
            <DetailPanelOptions options={detailOptions}/>
            <DisputeDetail
              closeRebut={this.handleCloseRebut}
              dispute={disputes.selectedDispute}
              disputeUpdated={this.loadData}
              isFetchingDetails={disputes.isFetchingDetails}
              isUpdating={disputes.isUpdating}
              openFaqDialog={this.handleOpenFaq}
              openRebut={this.state.openRebut}
              postSubmitAction={this.handleSubmit}
              submitAction={this.handleDisputeSubmit}
              {...this.props}
            />
          </DetailPanel>
        )
      } else {
        disputeDetailPanel = (
          <DetailPanel
            cancelText={t('Accept')}
            confirmationText={t('Rebut')}
            disabled={isCsUser}
            heading={detailTitle}
            isNew={disputes.selectedDispute.actionRequired}
            onCancelClick={this.handleAcceptDispute}
            onClick={this.handleOpenRebut}
            subheading={detailSubtitle}
          >
            <DetailPanelOptions options={detailOptions}/>
            <DisputeDetail
              closeRebut={this.handleCloseRebut}
              dispute={disputes.selectedDispute}
              disputeUpdated={this.loadData}
              isFetchingDetails={disputes.isFetchingDetails}
              isUpdating={disputes.isUpdating}
              openFaqDialog={this.handleOpenFaq}
              openRebut={this.state.openRebut}
              postSubmitAction={this.handleSubmit}
              submitAction={this.handleDisputeSubmit}
              {...this.props}
            />
          </DetailPanel>
        )
      }
    }

    return (
      <Box component={'section'} className='disputes' sx={disputesStyles}>
        <MasterDetailLayout
          {...this.props}
          pageInitialSubpage={t('All Disputes')}
          pageTitle={t('Disputes')}
          pageLoading={pageLoading}
          pageAccessories={pageAccessories}
          detailDisplayCallback={this.showDetailForRow}
          filterPanel={filterPanel}
          openFaqDialog={this.handleOpenFaq}
          tableData={disputes.filteredData}
          tableRowLayout={disputeList}
        >
          {disputeDetailPanel}
        </MasterDetailLayout>
        {faqDialog}
        {responseCodeDialog}
      </Box>
    );
  }

}

Disputes.defaultProps = {
  isFetching: true,
};

