/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { submit } from 'redux-form';
import {
  deleteCustomer,
  getCustomers,
  getCustomer,
  saveCustomer,
  setCustomer,
  updateCustomer,
  postPaymentMethod
} from '../../actions/customerActions';
import {
  setSubmitButtonEnabled,
  setVisibilityFilter,
  suppressAppError,
  setModalVisibility,
  setOpenMessageDialog,
  toggleNorthGatekeeper
} from '../../actions/userExperienceActions';
import IconUtil from '../util/IconUtil';
import CsvExporter from './../util/CsvExporter';
import CsvUtil from '../util/CsvUtil';
import LabelUtil from '../util/LabelUtil';
import moment from 'moment';
import CustomerDetailForm from './CustomerDetail';
import UserUtil from '../util/UserUtil';
import MessageDialog from '../shared/MessageDialog';
import ErrorMessageDialog from '../shared/ErrorMessageDialog';
import FilterPanel from '../shared/FilterPanel';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import DetailPanelOptions from '../shared/detailPanel/DetailPanelOptions';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import LoyaltyPrograms from './LoyaltyPrograms';
import CustomerDirectory from './CustomerDirectory';
import Pagination from '../shared/PaginationMui';
import customerOptionsConstants from '../../constants/customerOptions';
import LoyaltyActivity from './LoyaltyActivity';
import _ from 'lodash';
import FormatTextUtil from '../util/FormatTextUtil';
import LoyaltyProgramSettings from './LoyaltyProgramSettings';
import {toastr} from 'react-redux-toastr';
import actionTypes from '../../constants/actionTypes';
import { getAddOns } from '../../actions/merchantSettingsActions';
import Page from '../shared/Page';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';
import routes from '../../constants/routes';
import { countryStateList } from '../../constants/countryStateList';
import { customerCustomDetailPanelStyles } from '../../jss/customerDetailStyles';

const customerFailureActions = [actionTypes.customerSaveFailure];
const paymentFailureActions = [actionTypes.paymentMethodsPostFailure];
const failureActions = [...customerFailureActions, ...paymentFailureActions];
const appRoutePrefix = globalApplicationLabel.path;

export default class Customers extends Component {

  constructor(props) {
    super(props);

    this.createInvoiceLink = this.createInvoiceLink.bind(this);
    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showCreateNew = this.showCreateNew.bind(this);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.findIndex = this.findIndex.bind(this);
    this.displayDeleteDialog = this.displayDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCustomerDetailsClose = this.onCustomerDetailsClose.bind(this);
    this.saveUnsavedMessage = this.saveUnsavedMessage.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.hasUpdatedFields = this.hasUpdatedFields.bind(this);

    this.state = {
      selectedIndex: props?.visibilityFilter?.filter?.value ?? 'none',
      openDeleteDialog: false,
      unsavedChangesMessage: false,
      displayCustomerDetails: false
    };

  }

  async componentDidMount() {
    const { location, history, user, dispatch, merchantSettings} = this.props;

    await this.loadData();

    const {
      openCustomerDetail,
      openLoyaltySettings,
      exportCustomersCSV
    }  = location?.state || {};

    if (openCustomerDetail) {
      const customerId = location?.state?.customer_id || location?.state?.pa_customer_id
      this.findIndex(this.props, customerId);
      this.setState({ displayCustomerDetails: true });
      history.replace({ state: {} });

    } else if (openLoyaltySettings) {
      this.handleFilterSelection({ name: 'Loyalty Program' });
      history.replace({ state: {} });

    } else if (exportCustomersCSV) {
      this.handleExportClick();
      history.replace({ state: {} });
    }

    if (!merchantSettings.addOns) {
      dispatch(getAddOns(user));
    }

    if (!openLoyaltySettings) {
      dispatch(setVisibilityFilter({property: 'type', value: 'All Customers'}, 'filter'));
    }

  }


  createInvoiceLink() {
    const { history, customers } = this.props;
    const customerID = customers?.selectedCustomer?.id;
    const createInvoiceRoute = `${appRoutePrefix}${routes.business.root}${routes.business.invoices}`;
    history.push({pathname: createInvoiceRoute, state: {openNewInvoice: true, customerID}});
  }

  displayDeleteDialog() {
    this.setState({ openDeleteDialog: true});
  }

  closeDeleteDialog() {
    this.setState({ openDeleteDialog: false});
  }

  onCellClick(row) {
    this.setState({displayCustomerDetails: true});

    this.showDetailForRow(row);
  }

  onCustomerDetailsClose() {
    this.setState({displayCustomerDetails: false, unsavedChangesMessage: false});
    this.showDetailForRow(null);
  }

  saveUnsavedMessage(value) {
    this.setState({unsavedChangesMessage: value})
  }

  onChangePage(ev, page) {
    this.props.dispatch(setVisibilityFilter(page, 'pagination'))
  }

  loadData(customerId) {
    const { dispatch, user } = this.props;
    return dispatch(getCustomers(user)).then(() => {
      if (customerId) {
        this.findIndex(this.props, customerId);
      } else {
        this.setState({selectedIndex: null});
      }
    });
  }

  findIndex(props, id) {

    let that = this;

    const customerId = id || that.props.customers.customerId;

    const index = _.findIndex(this.props.customers.data, function (customer) {
      return customer.id === customerId
    });

    this.setState({selectedIndex: index});
    this.showDetailForRow(this.state.selectedIndex + 1);
  }

  showDetailForRow(row) {

    if (row) {

      const selectedCustomerIndex = row - 1;

      this.props.dispatch(setSubmitButtonEnabled(true));

      this.setState({selectedIndex: selectedCustomerIndex}, ()=> {
        this.props.dispatch(setCustomer(this.props.customers.filteredData[selectedCustomerIndex]));
      });
    } else {
      this.setState({selectedIndex: null});
    }

  }

  showCreateNew() {
    this.setState({ selectedIndex: 'new' });
    this.props.dispatch(setCustomer({
      first_name: null,
      last_name: null,
      phone_number: null,
      email_addresses: []
    }))
  }

  handleErrorResponse(apiResponse){
    const { dispatch, t } = this.props;
    const { response, error, type } = apiResponse;
    const responseType = response?.type ?? type;

    if (error || !response?.id){
      let message;
      let parsedError = {};

      try {
        parsedError = _.isString(error) ? JSON.parse(error) : error;
      }
      catch {
        parsedError = { error };
      }

      message = parsedError.reason || parsedError.error;

      if (!_.isString(message)) {
        message = message?.status_message;
      }

      const customerAccountNumberConflict =
        customerFailureActions.includes(responseType)
        && 'customerAccountNumberConflict'.includes(message);

      const creditCardError = paymentFailureActions.includes(responseType) ||
        (customerFailureActions.includes(responseType) && parsedError?.reason?.response);
      if (customerAccountNumberConflict) {
        dispatch(setOpenMessageDialog('customerAccountNumberConflict'));
        dispatch(suppressAppError(false));
      } else if (creditCardError){
        dispatch(setModalVisibility('cardError', { cardErrorMessage: message }));
      } else {
        toastr.error('Error', t(message));
      }
    }
  }

  hasUpdatedFields(values, selectedCustomer) {

    const initialValues = _.cloneDeep(selectedCustomer);
    initialValues.address = initialValues.address || {};
    let isDirty = false;

    Object.keys(values).some(field => {

      if (field === 'phone_number') {
        initialValues[field] = FormatTextUtil.formatPhoneNumber(initialValues[field]);
      }

      if (typeof values[field] !== 'undefined'){
        if (Array.isArray(values[field])) {
          isDirty = !_.isEqual(values[field], initialValues[field]);
        } else {
          isDirty = values[field] !== initialValues[field];
        }
      }

      if (field === 'address') {
        isDirty = !_.isEqual(values.address, initialValues.address);
      }

      if (isDirty) { return isDirty }
    });

    return isDirty;
  }

  async save(values = {}) {
    const { user, customers: { selectedCustomer }  , dispatch, t } = this.props;
    const { selectedIndex } = this.state;
    const isUpdate = selectedIndex !== 'new';

    if (!selectedCustomer) {
      return Promise.reject(new Error('No selected customer'));
    }

    dispatch(suppressAppError(true));

    let isAddingCard = selectedCustomer.adding_cards;
    const requests = [];

    let shouldUpdateCustomer = null;
    let shouldUpdatePaymentMethod = null;

    const statesList = countryStateList('United States');
    if (values.address) {
      values.address.state = statesList.find(state =>
        [state.label, state.key].includes(values.address?.state?.key || values.address?.state))?.key;
    }

    if (isUpdate) {
      shouldUpdateCustomer = this.hasUpdatedFields(values, selectedCustomer);
      shouldUpdatePaymentMethod = isAddingCard && values.payment_methods?.length;

      shouldUpdateCustomer && requests.push(dispatch(updateCustomer(user, selectedCustomer, values)));

      if (shouldUpdatePaymentMethod) {
        requests.push(dispatch(postPaymentMethod(user, values)));
      }
    } else {
      if (values?.payment_methods?.length > 0) {
        const noPaymentMethodValues = { ..._.cloneDeep(values), payment_methods: null };
        requests.push(dispatch(saveCustomer(user, selectedIndex, noPaymentMethodValues)));
      } else {
        requests.push(dispatch(saveCustomer(user, selectedIndex, values)));
      }
    }

    if (!requests.length) {
      this.setState({ displayCustomerDetails: false, selectedIndex: null });
      return Promise.resolve();
    }

    let results = [];

    try {
      results = await Promise.all(requests);
    } catch (e) {
      toastr.error('Error');
    }

    const isCustomerFailure = results.some((res) => customerFailureActions.includes(res?.type));

    if (isCustomerFailure) {
      const [customerResponse] = results;
      this.handleErrorResponse(customerResponse);
      return customerResponse;
    }

    if (!isUpdate && values?.payment_methods?.length > 0) {
      const [customerResponse] = results;
      let paymentPostResult = null;
      values.id = customerResponse?.response?.id;
      try {
        paymentPostResult = await dispatch(postPaymentMethod(user, values));
        isAddingCard = true;
      } catch(e) {
        paymentPostResult = e;
      }

      results = [customerResponse, paymentPostResult]
    }

   let [customerResponse, paymentResponse] = results;

    if (!shouldUpdateCustomer && shouldUpdatePaymentMethod) {
      [paymentResponse, customerResponse] = results;
    }

    let customerId = selectedCustomer.id;
    let customerType = selectedCustomer.is_pa_customer ? 'pa_customer_id': 'customer_id';

    if (!isUpdate) {
      customerId = customerResponse.response.id;
      customerType = customerResponse.response.is_pa_customer ? 'pa_customer_id': 'customer_id';
    }

    let openCustomerDetail = isAddingCard;
    Boolean(customerResponse) && dispatch(setCustomer(customerResponse.response));

    const customer = openCustomerDetail && customerId;

    return this.loadData(customer).then(() => {
      this.setState({ displayCustomerDetails: openCustomerDetail });
      dispatch(setVisibilityFilter({property: 'type', value: 'All Customers'}, 'filter'));
      Boolean(openCustomerDetail) && dispatch(getCustomer(user, customerId, customerType === 'pa_customer_id'));

      Boolean(customerResponse) &&
      !_.isEmpty(customerResponse) &&
      !failureActions.includes(customerResponse.type) &&
      toastr.success('Success', t('CustomerDetailForm.SaveCustomerSuccess'))

      const isPaymentFailure = results.some((res) => paymentFailureActions.includes(res.type));
      if (isPaymentFailure) {
        this.handleErrorResponse(paymentResponse);
        return paymentResponse;
      }
      if (paymentResponse && !failureActions.includes(paymentResponse.type)) {
        toastr.success('Success', t('CustomerDetailForm.AddPaymentMethodSuccess'));
      }
    });
  }

  delete() {
    const { dispatch, user, customers } = this.props;
    this.setState({ openDeleteDialog: false, selectedIndex: null }, async () => {
      await dispatch(deleteCustomer(user, customers.selectedCustomer));
      dispatch(setCustomer({}));
      this.loadData();
    });
  }

  onSaveClick() {
    this.props.dispatch(setCustomer({
      ...this.props.customers.selectedCustomer,
      adding_cards: false
    }));
    this.setState({ unsavedChangesMessage: false });
    this.props.dispatch(submit('customerDetailForm'));
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
  }

  handleFilterSelection(filter) {
    this.props.dispatch(setVisibilityFilter({property: 'type', value: filter.name}, 'filter'));
  }

  handleExportClick() {
    if (UserUtil.isNorthDemoAccount(this.props.user)) {
      this.props.dispatch(toggleNorthGatekeeper(true));
      return;
    }

    const labelName = LabelUtil.getLabel().text;
    let customersJson = CsvUtil.createCustomersCsv(this.props.customers.filteredDataRaw, this.props.user, this.props.auth.debtRepayment);
    CsvExporter.download(customersJson, labelName + '_Customers_' + moment().format('MMDDYYYY') + '.csv');
  }

  render() {
    const {
      customers,
      userExperience,
      user,
      auth,
      t,
      visibilityFilter,
      merchantSettings,
      location,
      loyaltyVpc
    } = this.props;

    const {
      displayCustomerDetails,
      selectedIndex
    } = this.state;

    const { isProcessing, addOns } = merchantSettings;
    const isFetching = customers?.isFetching || userExperience?.isFetching;
    const isReadOnly = !UserUtil.isActive(user);
    const labelColor = LabelUtil.getLabelColor();
    const filterPanelData = [{name: 'All Customers'}];
    const isLoyaltyEnabled = UserUtil.isLoyaltyEnabled(user, merchantSettings);
    const isLoyaltyActiveOrHasBeenActiveInThePast = !UserUtil.hasLoyaltyNeverBeenEnabled(merchantSettings);
    const isLoyaltyActive = merchantSettings?.loyaltyVpc?.enabled;
    const isPremiumPlus = UserUtil.isPremiumPlusAccount(user);
    const loyaltyAddOn = addOns?.length ? addOns[0] : {};
    const isLoyaltyAddOnActive = loyaltyAddOn.enabled;
    const isCsUser = UserUtil.isUserCS(user, auth);
    const isCSWriteUser = UserUtil.isUserCSWrite(user);
    const shouldShowLoyaltyProgram = isLoyaltyEnabled && (isPremiumPlus || isCSWriteUser);
    const hasRoleLoyaltyAccess = isLoyaltyAddOnActive ? true : auth.isOwner;
    const isHbtUser = UserUtil.isHumbolt(user);

    if (isLoyaltyActiveOrHasBeenActiveInThePast) {
      filterPanelData.push({name: 'With Active Reward'});
    }

    const isNewCustomer = selectedIndex === 'new';

    const openDetails = !!(
      isNewCustomer ||
      location?.state?.openCustomerDetail ||
      displayCustomerDetails
    ) && !customers?.isFetching;

    const isLoadingActive = isFetching || isProcessing || loyaltyVpc?.isFetching;

    const pageLoading = isLoadingActive && !openDetails;

    if (pageLoading) {
      return (
        <Page title={t('Customers')} loading />
      );
    }

    const deleteIcon = IconUtil.getIcon('DeleteIcon', labelColor);
    const sendIcon = IconUtil.getIcon('MainMenu_InvoicesIcon', labelColor, null, 24);

    if (UserUtil.userType(user) !== 'HBT' && (shouldShowLoyaltyProgram && hasRoleLoyaltyAccess || (auth.isOwner || auth.isAdmin))) {
      filterPanelData.push({name: 'Loyalty Program', border: true});
    }

    if (UserUtil.userType(user) !== 'HBT' && shouldShowLoyaltyProgram && isLoyaltyActive) {
      filterPanelData.push({name: 'Activity & Reports'});
      filterPanelData.push({name: 'Loyalty Program Settings'});
    }

    const selectedCustomer = !isNewCustomer ? customers.selectedCustomer : null;
    const detailsTitle = isNewCustomer ? t('CustomerDetailForm.NewCustomer') : t('CustomerDetailForm.EditCustomer');

    const isNew = isNewCustomer || !isReadOnly;

    let customerActions =
      [
        {
          onClick: this.createInvoiceLink,
          text: t('CustomerDetailForm.CreateInvoice'),
          icon: sendIcon,
          disabled: customers?.selectedCustomer?.isFetching,
          hide: isHbtUser || isCsUser || isCSWriteUser
        },
        {
          onClick: this.displayDeleteDialog,
          text: t('CustomerDetailForm.Delete'),
          icon: deleteIcon,
          hide: isCsUser
        }
      ];

    if (auth.isManager && (!auth.isManagerInvoice)) {
      const deleteAction = customerActions[1];
      customerActions = [deleteAction];
    }

    const customerOptions = selectedCustomer && !isReadOnly ? customerActions : [];

    const newDetail = selectedIndex != null ? (
      <DetailPanel
        heading={detailsTitle}
        isNew={isNew}
        disabled={customers?.selectedCustomer?.isFetching}
        isSubmitButtonVisible={!isCsUser}
        onClick={this.onSaveClick}
        onCloseClick={this.onCustomerDetailsClose}
        shouldDisplay={true}
        unsavedChangesMessage={this.state.unsavedChangesMessage}
        customStyles={customerCustomDetailPanelStyles}>
        <DetailPanelOptions options={customerOptions} />
        <CustomerDetailForm
          onSubmit={this.save}
          loadData={this.loadData}
          isNew={isNewCustomer}
          isReadOnly={isReadOnly}
          setUnsavedChangesMessage={this.saveUnsavedMessage}
          {...this.props}/>
      </DetailPanel>
    ) : null;


    const customerErrorMessage = userExperience.openMessageDialog === 'customerAccountNumberConflict' ? (
      <ErrorMessageDialog
        messageTitle={t('CustomerDetailForm.CustomerErrorDialog.Title')}
        messageContent={t('CustomerDetailForm.CustomerErrorDialog.Content')}
        messageBoxWidth='sm'
        {...this.props}
      />
    ) : null;

    const searchBar = (
      <SearchBarAccessory {...this.props} />
    );

    const deleteConfirmationDialog = (
      <MessageDialog
        cancelText={t('No')}
        confirmText={t('Yes')}
        externalClassName='deleteConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.delete}
        onRequestClose={this.closeDeleteDialog}
        open={this.state.openDeleteDialog}
        scrollable={true}
        titleText={t('CustomerDetailForm.CustomerDeleteConfirmationDialog.Title')}
        bodyText={
          <p className='confirmationText'>{t('CustomerDetailForm.CustomerDeleteConfirmationDialog.Body')}</p>
        } />
    );

    let displayContent;

    const isLargeCustomerList = customers.filteredDataTotal > customerOptionsConstants.customers.maximumPerPage;
    const paginationComponent = isLargeCustomerList && (
      <Pagination
        rowCount={customers.filteredDataTotal}
        onChangePage={this.onChangePage}
        pageSize={customerOptionsConstants.customers.maximumPerPage}
        page={visibilityFilter.pagination}
      />
    );

    const tabFilter = (type) => {
      const customers = (
        <CustomerDirectory
          {...this.props}
          selectedIndex={this.state.selectedIndex}
          onCellClick={this.onCellClick}
          isLoyaltyEnabled={isLoyaltyActiveOrHasBeenActiveInThePast}
          paginationComponent={paginationComponent}
        />
      );

      const tabs = {
        'All Customers': customers,
        'With Active Reward': customers,
        'Loyalty Program': (<LoyaltyPrograms {...this.props} />),
        'Activity & Reports': (<LoyaltyActivity {...this.props} />),
        'Loyalty Program Settings': (<LoyaltyProgramSettings {...this.props} />),
        'default': customers
      };
      return (tabs[type] || tabs['default']);
    };

    if (visibilityFilter.filter) {
      displayContent = tabFilter(visibilityFilter.filter.value);
    }

    const hideAccessories = !(visibilityFilter?.filter?.property === 'type' &&
      ['All Customers', 'With Active Reward',  'default'].includes(visibilityFilter?.filter?.value));

    const createNewButton = <CreateButtonAccessory
      onClick={this.showCreateNew}
      disabled={isCsUser || isReadOnly}
      isMobileOnly
    />

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
        hide: hideAccessories
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: hideAccessories
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            handleSelection={this.handleExportClick}
            exportTitle={t('ExportCustomers')}
            exportButtonClass={'exportCustomersHeader'}
          />
        ),
        showInMobile: true,
        hide: hideAccessories
      },
    ];

    return (
      <section className='businessCustomers'>
        <Page
          accessories={pageAccessories}
          loading={pageLoading}
          title={t('Customers')}
        >
          <>
            <div className='pageScrollableArea customersWrapper flexContainerResponsiveLayout'>
              <FilterPanel
                buttonOnClick={this.showCreateNew}
                buttonText={'Create Customer'}
                buttonWithCreateIcon
                disabled={isCsUser || isReadOnly}
                filterData={filterPanelData}
                selectFilterCallback={this.handleFilterSelection}
                {...this.props}
              />
              <div className={`customersContent ${isLargeCustomerList ? '' : 'overflow'}`}>
                {!isLoadingActive && displayContent}
              </div>
            </div>
            {newDetail}
            {customerErrorMessage}
            {deleteConfirmationDialog}
          </>
        </Page>
      </section>
    )
  }
}

