/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React from 'react';
import {Box, Typography} from '@mui/material';
import IconUtil from '../../../util/IconUtil';
import LabelUtil from '../../../util/LabelUtil';
import FormatTextUtil from '../../../util/FormatTextUtil';
import moment from 'moment';
import BulkReportCell from './bulkReportCell';
import {BulkInvoicesReportTileStyles} from '../../../../jss/inlineStyles'
import Button from '../../../shared/Button';

const BulkReportTile = ({data, clickViewInvoices, t}) => {
  const {
    id,
    type,
    merchant_seq_number,
    total_rows,
    created_date,
    invoices_created,
    invoices_sent,
    invoices_not_sent,
    total_invoice_volume,
    customers_created,
    series_created,
    error_file_url,
    file_url,
    status,
  } = data;
  const goToIcon = IconUtil.getIcon('GoToIcon', LabelUtil.getLabel().primaryColor, 24, 24);
  const fileUploadIcon = IconUtil.getIcon('UploadFile', '#707A7E', 24, 24);
  const downloadFileIcon = IconUtil.getIcon('FileDownload', LabelUtil.getLabel().primaryColor, 24, 24)
  const fileName = FormatTextUtil.getFileNameFromUrl(file_url);
  let labelDataKey = 'BulkInvoicesReports.completedAt';
  return (
    <Box sx={BulkInvoicesReportTileStyles} data-testid={'BulkReportTile'}>
      <Box className='headerContainer'>
        <Typography
          className='headerText'
        >
          {t('BulkInvoicesReports.bulkId', {bulkId: merchant_seq_number})}
        </Typography>
        <Box className='linkContainer' data-testid='linkContainer' >
          <Button
            onClick={() => clickViewInvoices(id)}
            endIcon={goToIcon}
            label={t('BulkInvoicesReports.viewBtn')}
            variant='text'
          />
        </Box>
      </Box>
      <Box
        className='fileLinkContainer'
      >
        <Box className='linkContainer'>
          <Button
            download
            endIcon={fileUploadIcon}
            href={file_url}
            label={fileName}
            variant='text'
          />
        </Box>
        <Typography
          className='text'
        >
          {t('BulkInvoicesReports.completedAt', {date: moment(created_date).format('MM/DD/YYYY, hh:mm a')})}
        </Typography>
      </Box>
      <Box className='rowsContainer'>
        <Box className='columnsContainer'>
          <BulkReportCell label={t('BulkInvoicesReports.labelRowsImported')}
                          value={total_rows}
          />
          <BulkReportCell label={t('BulkInvoicesReports.labelInvoicesCreated')}
                          value={invoices_sent}
          />
          <BulkReportCell borderless
                          label={t('BulkInvoicesReports.labelInvoicesError')}
                          value={invoices_not_sent}
          />
        </Box>
        <Box className='columnsContainer'>
          <BulkReportCell label={t('BulkInvoicesReports.labelNewCustomers')}
                          value={customers_created}
          />
          <BulkReportCell label={t('BulkInvoicesReports.labelVolumeCreated')}
                          value={FormatTextUtil.formatCurrencyDoubleDecimals(total_invoice_volume)}
          />
          <BulkReportCell borderless
                          label={t('BulkInvoicesReports.labelSeriesCreated')}
                          value={series_created}
          />
        </Box>
      </Box>
      {error_file_url &&
        <Box className='linkContainer'>
          <Button
            download
            endIcon={downloadFileIcon}
            href={error_file_url}
            label={t('BulkInvoices.DownloadWithErrors')}
            variant='text'
          />
        </Box>
      }
    </Box>
  )
}

export default BulkReportTile;
