/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import {
  setVisibilityFilter,
  setSelectedItemTab
} from '../../actions/userExperienceActions';
import {
  getItems,
  resetSalesItemImage
} from '../../actions/itemsActions';
import MasterDetailLayout from '../MasterDetailLayout';
import DetailPanel from '../shared/detailPanel/DetailPanel';
import FilterPanel from '../shared/FilterPanel';
import MessageDialog from '../shared/MessageDialog';
import IconUtils from './../util/IconUtil';
import { isJSON } from './../util/CommonUtil'
import _ from 'lodash';
import ItemsDetailForm from './ItemsDetail';
import CsvExporter from './../util/CsvExporter';
import moment from 'moment';
import LabelUtil from './../util/LabelUtil';
import CsvUtil from './../util/CsvUtil';
import TaxUtil from '../../components/util/TaxUtil';
import UserUtil from '../util/UserUtil';
import UpdateSpinner from '../UpdateSpinner';
import MobileUtil from './../util/MobileUtil';
import SearchBarAccessory from '../appBar/accessories/SearchBarAccessory';
import { reactivateItem, reactivateBatchItems, deletePermanentlyItem } from '../../actions/itemsActions';
import Pagination from '../shared/PaginationMui';
import NoData from '../NoData';
import inventoryOptions from '../../constants/inventoryOptions';
import Page from '../shared/Page';
import ExportImportAccessory from '../appBar/accessories/ExportImportAccessory';
import ButtonAccessory from '../appBar/accessories/ButtonAccesory';
import ItemsFilterAccessory from '../appBar/accessories/ItemsFilterAccessory';
import CreateButtonAccessory from '../appBar/accessories/CreateButtonAccessory';
import InventorySyncText from './InventorySyncText';


export default class ArchivedItems extends Component {

  constructor(props) {
    super(props);

    this.showDetailForRow = this.showDetailForRow.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showCreateNew = this.showCreateNew.bind(this);
    this.reactivate = this.reactivate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.exportItems = this.exportItems.bind(this);
    this.selectItems = this.selectItems.bind(this);
    this.checkRowSelected = this.checkRowSelected.bind(this);
    this.cleanSelectedItems = this.cleanSelectedItems.bind(this);
    this.reactivateMultipleItems = this.reactivateMultipleItems.bind(this);
    this.onReactivateConfirmation = this.onReactivateConfirmation.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onCloseDetail = this.onCloseDetail.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this)
    this.deletePermanently = this.deletePermanently.bind(this);
    this.selectAllItems = this.selectAllItems.bind(this);
    this.getDialogTitle = this.getDialogTitle.bind(this);
    this.getDialogMessage = this.getDialogMessage.bind(this);
    this.displayReactivateDialogList = this.displayReactivateDialogList.bind(this);
    this.displayReactivateDialogDetail = this.displayReactivateDialogDetail.bind(this);
    this.displayDeletePermanentlyDialogDetail = this.displayDeletePermanentlyDialogDetail.bind(this);
    this.closeReactivateDialog = this.closeReactivateDialog.bind(this);
    this.filterByCategoriesData = this.filterByCategoriesData.bind(this);
    this.setCroppedImage = this.setCroppedImage.bind(this);
    this.onChangePage = this.onChangePage.bind(this);

    this.handleCreateNew = this.props.handleCreateNew;
    this.renderCreateMenu = this.props.renderCreateMenu.bind(this);
    this.handleCreateClick = this.props.handleCreateClick.bind(this);
    this.handleFilterSelection = this.props.handleFilterSelection.bind(this);
    this.handleTopBarFilterSelection = this.props.handleTopBarFilterSelection.bind(this);
    this.handleCreateMenuClose = this.props.handleCreateMenuClose.bind(this);

    this.categoriesWithNoId = {
      allItems: JSON.stringify({name: 'All Items'}),
      favorite: JSON.stringify({name: 'Favorite'}),
    }

    this.state = {
      loading: false,
      pageNumber: 1,
      needToSearch: false,
      selectedIndex: 0,
      selectedCategory: {
        color: '000000',
        name: 'None'
      },
      selectedFilter: this.categoriesWithNoId.allItems,
      open: false,
      openDetail: false,
      createMenuOpen: false,
      openReactivateDialogType: 0,
      openDeleteDialog: false,
      image_data: null,
      loadedImage: null,
      selectedItems: [],
      allSelected: false
    };

    if (this.props.taxes.taxRate === 0) {
      TaxUtil.getGeoLocation(this);
    }
  }

    async componentDidMount() {
      await this.loadData();

      this.props.dispatch(setVisibilityFilter({
        property: 'type',
        value: this.categoriesWithNoId.allItems,
        categoryId: undefined
      }, 'filter'));
      this.props.dispatch(setVisibilityFilter('', ''));
    }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const searchChanged = prevProps.visibilityFilter.search !== this.props.visibilityFilter.search;
    const categoryChanged = prevProps.visibilityFilter.filter?.value !== this.props.visibilityFilter?.filter?.value;

    if (categoryChanged) {
      this.setPageNumberAndLoadData(1)
    }

    if (searchChanged) {
      this.setState({ needToSearch: true });
      this.debouncedLoadDataHandleSearch();
    }

  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'));
    this.props.dispatch(setVisibilityFilter('', ''));
  }

  loadData() {
    const { dispatch, user, visibilityFilter } = this.props;
    const { pageNumber } = this.state;
    const categoryId = visibilityFilter.filter?.categoryId || '';

    let searchValue = visibilityFilter.search;
    const priceRegex = /\$\d+(\.\d+)?/g;

    if (Boolean(searchValue?.match(priceRegex))) {
      searchValue = searchValue.replace('$', '');
    }

    dispatch(resetSalesItemImage());
    dispatch(getItems(user, 'inactive', pageNumber, searchValue, categoryId));
  }

  debouncedLoadDataHandleSearch = _.debounce(() => {
    this.setPageNumberAndLoadData(1);
  }, inventoryOptions.items.debounceTime);

  onChangePage(ev, pageNumber) {
    if (this.state.pageNumber !== pageNumber) {
      this.setPageNumberAndLoadData(pageNumber);
    }
  }

  setPageNumberAndLoadData = (pageNumber) => {
    this.setState({ pageNumber, needToSearch: false }, () => this.loadData());
  };

  onCloseDetail() {
    this.setState({ selectedIndex: -1, openDetail: false });
  }

  onCellClick(selectedIndex) {
    this.setState({ selectedIndex, openDetail: true });
  }

  showDetailForRow(selectedRow) {
    this.setState({ image_data: null, loadedImage: null });
    this.props.dispatch(setSelectedItemTab('details'));

    const selectedIndex = selectedRow - 1;
    this.onCellClick(isNaN(selectedIndex) ? selectedRow : selectedIndex);
  }

  showCreateNew() {
    this.setState({image_data: null, loadedImage: null});
    this.showDetailForRow('new');
  }

  filterByCategoriesData() {
    const filterCategoriesData = _.cloneDeep(this.props.items.categories);
    filterCategoriesData?.unshift(
      {
      name: this.categoriesWithNoId.allItems
      },
      {
      name: this.categoriesWithNoId.favorite,
        icon: IconUtils.getIcon('Favorite', LabelUtil.getLabelColor())
      });

    return filterCategoriesData;
  }

  displayReactivateDialogList() {
    this.setState({ openReactivateDialogType: 1 });
  }

  displayReactivateDialogDetail() {
    this.setState({ openReactivateDialogType: 2 });
  }

  displayDeletePermanentlyDialogDetail() {
    this.setState({ openDeleteDialog: true });
  }

  closeReactivateDialog() {
    if (this.state.openReactivateDialogType === 1) {
      this.cleanSelectedItems();
    }
    this.setState({openReactivateDialogType: 0});
  }

  closeDeleteDialog() {
    this.setState({ openDeleteDialog: false });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  newItem() {
    return {
      option_set_ids: [],
      name: '',
      is_taxable: false,
      is_favorite: false,
      is_trackable: true,
      allow_outof_stock: false,
      categories: [],
      details: {
        prices: [],
        modifierSets: null
      }
    }
  }

  selectItems(selectedId) {
    const { selectedItems } = this.state;
    const foundItem = selectedItems.some(id => id === selectedId);

    const newSelectedItems = foundItem ?
      selectedItems.filter(id => id !== selectedId) :
      [...selectedItems, selectedId];

    this.setState({
      selectedItems: newSelectedItems,
      allSelected: newSelectedItems.length === this.props.items.filteredArchivedItems.length
    });
  }

  selectAllItems() {
    if (this.state.allSelected) {
      this.setState({allSelected: false});
      this.cleanSelectedItems();
    } else {
      const selectedItems = this.props.items.filteredArchivedItems.map((item) => item.id);
      this.setState({ allSelected: true, selectedItems });
    }
  }

  checkRowSelected(id) {
    return this.state.selectedItems.includes(id);
  }

  cleanSelectedItems() {
    this.setState({ selectedItems: [], allSelected: false });
  }

  reactivate() {
    const that = this;
    this.setState({ openReactivateDialogType: 0, loading: true });
    this.props.dispatch(reactivateItem(this.props.user, this.props.items.filteredArchivedItems[this.state.selectedIndex])).then(() => {
      that.setState({ selectedIndex: 0, loading: false, openDetail: false });
      that.loadData();
    });
  }

  reactivateMultipleItems() {
    const that = this;
    this.setState({ openReactivateDialogType: 0, loading: true });
    this.props.dispatch(reactivateBatchItems(this.props.user, this.state.selectedItems)).then(() => {
      that.setState({ loading: false, openDetail: false });
      that.cleanSelectedItems();
      that.loadData();
    });
  }

  deletePermanently() {
    const that = this;
    this.setState({ openDeleteDialog: false, loading: true });
    this.props.dispatch(deletePermanentlyItem(this.props.user, this.props.items.filteredArchivedItems[this.state.selectedIndex].id)).then(() => {
      that.setState({ loading: false, openDetail: false });
      that.cleanSelectedItems();
      that.loadData();
    });
  }

  getDialogTitle() {
    return 'Reactivate item.';
  }

  getDialogMessage() {
     if (this.state.openReactivateDialogType === 1 || this.state.openReactivateDialogType === 2) {
       return (`Are you sure you want to reactivate ${
         this.state.selectedItems.length > 1
          ? `these ${this.state.selectedItems.length} items?`
          : 'this item?'
        }`)
     };
  }

  onReactivateConfirmation() {
    const { openReactivateDialogType } = this.state;

    if (openReactivateDialogType === 1) {
      this.reactivateMultipleItems();
    } else if (openReactivateDialogType === 2) {
      this.reactivate();
    }
  }

  exportItems() {

    const itemsJson = CsvUtil.constructExportItem(this.props.items.filteredArchivedItems, this.props.user);
    const itemsHeaders = CsvUtil.constructItemExportHeaders(itemsJson);
    const labelName = LabelUtil.getLabel().text;

    CsvExporter.download(itemsJson, labelName + '_Items_' + moment().format('MMDDYYYY') + '.csv', itemsHeaders)
  }

  setCroppedImage(image_data, image) {
    this.setState({
      image_data: image_data,
      loadedImage: image
    });
  }

  render() {
    const { isFetching, items, user, auth, t, visibilityFilter } = this.props;

    const isEmpty = items.salesItems === null;

    const pageLoading = (isEmpty && isFetching);

    if (pageLoading) {
      return (
        <Page
          title={t('Inventory')}
          pageInitialSubpage={t('Archived items')}
          forceInitialSubpage={true}
          loading />
      )
    }

    const isCsUser = UserUtil.isUserCS(user, auth);
    const isReadOnly = !UserUtil.isActive(user);

    const amountOfArchivedItemsInView = items && items.filteredArchivedItems && items.filteredArchivedItems.length ? items.filteredArchivedItems.length : 0;
    const totalAmountOfArchivedItems = items.salesItemsPagination?.rowCount || 0;

    const isNewItem = this.state.selectedIndex === 'new';

    const isMobile = MobileUtil.isMobileDevice();

    const salesItems = items.filteredArchivedItems || items.salesItems;
    const selectedItem = !isNewItem && salesItems[this.state.selectedIndex] || this.newItem();

    const itemsDetailPanel = (
      <DetailPanel
        isNew
        shouldDisplay
        open={this.state.openDetail}
        heading={isNewItem ? 'New Item' : selectedItem.name}
        cancelText='Close'
        isSubmitButtonVisible={false}
        onCloseClick={this.onCloseDetail}
      >
        <ItemsDetailForm
          selectedItem={selectedItem}
          loadedImage={this.state.loadedImage}
          onReactivate={this.displayReactivateDialogDetail}
          onPermanentlyDelete={this.displayDeletePermanentlyDialogDetail}
          isNewItem={isNewItem} {...this.props} isArchivedSection={true}
        />
      </DetailPanel>
    );

    const reactivateConfirmationDialog = (
      <MessageDialog
        cancelText='No'
        confirmText='Yes'
        externalClassName='reactivateConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.onReactivateConfirmation}
        onRequestClose={this.closeReactivateDialog}
        open={!!this.state.openReactivateDialogType}
        scrollable={true}
        titleText={this.getDialogTitle()}
        bodyText={
          <p className='confirmationText'>{this.getDialogMessage()}</p>
        } />
    );

    const deleteConfirmationDialog = (
      <MessageDialog
        cancelText='No'
        confirmText='Yes'
        externalClassName='reactivateConfirmationDialog'
        isChoiceRequired={false}
        onConfirm={this.deletePermanently}
        onRequestClose={this.closeDeleteDialog}
        open={this.state.openDeleteDialog}
        scrollable={true}
        titleText='Permanently delete item.'
        bodyText={
          <p className='confirmationText'>{
            `Are you sure you want to permanently delete this item?
              This action cannot be undone. This item will still appear in historic reporting.`
          }</p>
        } />
    );

    const selectedItemsLength = this.state.selectedItems.length;
    const filteredCategoriesData = this.filterByCategoriesData();

    const searchBar = (
      <SearchBarAccessory avoidCleaning searchPlaceholder={t('ItemsDetail.SearchItems')} {...this.props} />
    );

    const createNewButton = <CreateButtonAccessory
      onClick={this.handleCreateClick}
      disabled={isReadOnly}
      isMobileOnly
    />

    const pageAccessories = [
      {
        name: 'createNew',
        accessory: createNewButton,
        showInMobile: true,
      },
      {
        name: 'search',
        accessory: searchBar,
        showInMobile: true,
        hide: false
      },
      {
        name: 'filterDropdown',
        accessory: (
          <ItemsFilterAccessory
            onChange={(event) => this.handleTopBarFilterSelection(event.target.value)}
            value={this.state.selectedFilter}
            data={filteredCategoriesData}
            t={t}
          />
        ),
        showInMobile: true,
        hide: false
      },
      {
        name: 'reactivateItemButton',
        accessory: (
          <ButtonAccessory
            onClick={this.displayReactivateDialogList}
            disabled={isCsUser || isReadOnly}
            label={`Reactivate ${selectedItemsLength} item${selectedItemsLength > 1 ? 's' : ''}`}
          />
        ),
        showInMobile: true,
        hide: !(selectedItemsLength && !isMobile)
      },
      {
        name: 'exportButton',
        accessory: (
          <ExportImportAccessory
            handleSelection={this.exportItems}
            exportTitle='Export Inventory'
            disabled={isCsUser}
          />
        ),
        showInMobile: true,
        hide: false
      },
    ];

    const filterPanel = (
      <FilterPanel
        buttonOnClick={this.handleCreateClick}
        buttonText={'Create'}
        buttonWithCreateIcon
        {...this.props}
        filterData={UserUtil.getInventoryOptions('Archived items', totalAmountOfArchivedItems, auth, user, this.props.t)}
        selectFilterCallback={this.handleFilterSelection}
        disabled={isReadOnly}
        additionalFilterDataComponent={<InventorySyncText t={t}/>}
      />
    );

    const isLargeInventory = totalAmountOfArchivedItems > items.salesItemsPagination?.pageSize;
    const paginationComponent = isLargeInventory ? (
      <Pagination
        rowCount={totalAmountOfArchivedItems}
        onChangePage={this.onChangePage}
        pageSize={items.salesItemsPagination.pageSize}
        page={this.state.pageNumber}
      />
    ) : null;

    const onlyOneRow = amountOfArchivedItemsInView;
    const tableData = onlyOneRow ?
      [...items.filteredArchivedItems, {}] :items.filteredArchivedItems

    return (

      <section className='inventoryItems'>
        <MasterDetailLayout
          {...this.props}
          pageTitle={t('Inventory')}
          pageLoading={pageLoading}
          pageInitialSubpage={t('Archived items')}
          pageForceInitialSubpage={true}
          pageAccessories={pageAccessories}
          filterPanel={filterPanel}
          tableData={!this.state.needToSearch ? tableData : []}
          detailDisplayCallback={this.showDetailForRow}
          forceOpenDetail={isNewItem}
          paginationComponent={paginationComponent}
          dynamicLoading='items'
          onRowClick={this.onCellClick}
          rowSelectionProps={{
            allSelected: this.state.allSelected,
            checkRowSelected: this.checkRowSelected,
            selectRow: this.selectItems,
            toggleSelectAllRows: this.selectAllItems
          }}
          onlyOneRow={onlyOneRow}
          noDataComponent={this.state.needToSearch && (<NoData text={t('Searching')}/>)}
        />

        {itemsDetailPanel}
        {this.state.loading && <UpdateSpinner/>}
        {reactivateConfirmationDialog}
        {deleteConfirmationDialog}
        {this.renderCreateMenu()}
      </section>

    )
  }
}

ArchivedItems.defaultProps = {
  isFetching: true,
};
