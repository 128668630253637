/**
 North American Bancard ("NAB") CONFIDENTIAL MATERIAL

 Copyright 2000 NAB, All Rights Reserved.

 NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
 contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
 protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
 strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
 is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
 and Non-disclosure agreements explicitly covering such access.

 The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
 which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
 ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
 CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
 INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
 IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
 MAY DESCRIBE, IN WHOLE OR IN PART.

 */

import React, { Component } from 'react';
import { change, submit } from 'redux-form';

import {
  setEditButtonEnabled, setModalVisibility,
  setOpenMessageDialog,
  setSubmitButtonEnabled,
  suppressAppError
} from '../../actions/userExperienceActions';
import {
  getAccountFundingProfile,
  getAccountProfile
} from '../../actions/accountProfileActions';
import {
  giactSaveError,
  giactSaveErrorReset,
  postGiact,
  postGiactImage,
  postIdology,
  profileChange,
  resetTwoFactorAuthFlag
} from '../../actions/verificationActions';
import {
  saveFundingProfile
} from '../../actions/userActions';

import FileUtil from '../util/FileUtil';
import SettingsUtil from '../util/SettingsUtil';
import LabelUtil from '../util/LabelUtil';
import CommonUtil from '../util/CommonUtil';
import TextUtil from '../util/FormatTextUtil';
import UserUtil from '../util/UserUtil';

import messages from '../../constants/messages';
import actionTypes from '../../constants/actionTypes';
import bankAccountTypes from '../../constants/bankAccountTypes';

import FilterPanel from '../shared/FilterPanel';
import Button from '../shared/Button';
import Modal from '../shared/Modal';
import AttachFilesForm, { AttachFilesFormType } from '../AttachFilesForm';
import BankingComponent from './bankingComponent/BankingComponent';
import FundingForm from './FundingForm';
import TwoFactorTools from '../dialogs/account/TwoFactorTools';
import twoFactorTypes from '../../constants/twoFactorTypes';
import { getMerchantSettings } from '../../actions/merchantSettingsActions';
import attachFileOptions from '../../constants/attachFileOptions';
import Page from '../shared/Page';
import Spinner from '../UpdateSpinner';

export default class Funding extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openFilesDialog: false,
      openCSDialog: false,
      openGiactVerification: false,
      idologyAttempts: 0,
      loading: false,
      loadingSpinner: false,
      files: [],
      giactMsgs: [],
      openUploadError: false,
      successfullySavedFunding: false,
      displaySavedDebitCard: false,
      confirmedFunding: false,
      showConfirmFundingDialog: false,
      editPrimaryBankAccount: false,
      editChargeBackBankAccount: false,
      editBillingBankAccount: false,
      showFundingOptimization: false
    };

    this.loadData = this.loadData.bind(this);

    this.handleAddFiles = this.handleAddFiles.bind(this);
    this.handleAttachFilesClick = this.handleAttachFilesClick.bind(this);
    this.handleCloseFilesDialog = this.handleCloseFilesDialog.bind(this);
    this.handleGiactImgVerificationClose = this.handleGiactImgVerificationClose.bind(this);
    this.handleUploadErrorClose = this.handleUploadErrorClose.bind(this);
    this.displayCSDialog = this.displayCSDialog.bind(this);

    this.onSaveBankingClick = this.onSaveBankingClick.bind(this);
    this.onSaveFundingClick = this.onSaveFundingClick.bind(this);
    this.handleSaveBankingProfile = this.handleSaveBankingProfile.bind(this);
    this.handleSaveFunding = this.handleSaveFunding.bind(this);
    this.handleSuccessfullySavedFundingClose = this.handleSuccessfullySavedFundingClose.bind(this);
    this.toggleDebitCardInfo = this.toggleDebitCardInfo.bind(this);
    this.cancelConfirmFunding = this.cancelConfirmFunding.bind(this);
    this.confirmedFunding = this.confirmedFunding.bind(this);
    this.closeCSDialog = this.closeCSDialog.bind(this);

    this.on2faVerified = this.on2faVerified.bind(this);
    this.on2faProcessing = this.on2faProcessing.bind(this);

    // Methods if the idology endpoint doesnt retrieve the first 5 digits of the SSN
    this.editPrimaryBankAccount = this.editPrimaryBankAccount.bind(this);
    this.editChargeBackBankAccount = this.editChargeBackBankAccount.bind(this);
    this.editBillingBankAccount = this.editBillingBankAccount.bind(this);

    this.translatedAccountType = this.translatedAccountType.bind(this);

  }

  componentDidMount() {

    this.loadData();

    const { user, dispatch, merchantSettings } = this.props;
    if (!merchantSettings?.merchantSettings) {
      dispatch(getMerchantSettings(user));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(suppressAppError(false));
  }

  loadData() {
    const {dispatch, user, verification} = this.props;
    const requests = [];

    const twoFactorVerified = verification.twoFactorAuthenticationVerified;
    const bankingAccess = twoFactorVerified[twoFactorTypes.bankingAccess];
    const fundingAccess = twoFactorVerified[twoFactorTypes.fundingAccess];

    if (bankingAccess || fundingAccess) {
      dispatch(resetTwoFactorAuthFlag());
    }

    requests.push(dispatch(getAccountProfile(user)));
    requests.push(dispatch(getAccountFundingProfile(user, 'funding')));
    requests.push(dispatch(getAccountFundingProfile(user, 'banking')));

    this.setState({displaySavedDebitCard: true, loading: true});

    return Promise.all(requests).then(() => {
      dispatch(setSubmitButtonEnabled(false));
      dispatch(setEditButtonEnabled(true));
      this.setState({loading: false, [this.state.accountToEdit]: false});
    });
  }

  handleCloseFilesDialog() {
    this.setState({openFilesDialog: false});
  }

  handleAttachFilesClick() {
    this.props.dispatch(submit('attachFilesForm'));
  }

  displayCSDialog() {
    this.setState({openCSDialog: true});
  }

  toggleDebitCardInfo() {
    this.setState({displaySavedDebitCard: !this.state.displaySavedDebitCard});
  }

  closeCSDialog() {
    this.props.dispatch(setOpenMessageDialog(false));
    this.props.dispatch(suppressAppError(false));
    this.setState({openCSDialog: false});
  }

  cancelConfirmFunding() {
    this.setState({showConfirmFundingDialog: false});
  }

  confirmedFunding() {

    this.setState({showConfirmFundingDialog: false});
    this.onSaveFundingClick(null, true);

  }

  handleSuccessfullySavedFundingClose() {
    this.setState({successfullySavedFunding: false});
  }

  handleSaveFunding(values) {

    let that = this;
    const {user} = this.props;

    this.setState({successfullySavedFunding: false});

    return new Promise((resolve, reject) => {
      that.props.dispatch(saveFundingProfile(user, values)).then((res) => {
        if (res.type === actionTypes.userSaveSuccess) {
          that.setState({
            successfullySavedFunding: true,
            displaySavedDebitCard: true
          });
          that.props.dispatch(setSubmitButtonEnabled(true));
          that.loadData();
          //TODO: Send notification of change
        }
        resolve();
      });
    });

  }

  handleGiactImgVerificationClose() {
    this.loadData();
    this.setState({openGiactVerification: false});
  }

  handleUploadErrorClose() {
    this.setState({openUploadError: false});
  }

  handleSaveBankingProfile(values) {

    const {accountProfile, verification, validationHistory, user, dispatch} = this.props;
    const that = this;

    let accountsChanged = false;
    let requests = [];

    const bankingAccountsData = SettingsUtil.bankingAccountsFormatter(verification.fullInfo?.banking, values);

    if(bankingAccountsData) {

      this.setState({giactMsgs: [], loading: true});

      if (values.bankAccountKind === bankAccountTypes.primary) {
        accountsChanged = true;
        requests.push(dispatch(postGiact(accountProfile, verification, values, bankAccountTypes.primary, user)));
      }

      if (values.bankAccountKind === bankAccountTypes.chargeback) {
        accountsChanged = true;
        requests.push(dispatch(postGiact(accountProfile, verification, values, bankAccountTypes.chargeback, user)));
      }

      if (values.bankAccountKind === bankAccountTypes.billing) {
        accountsChanged = true;
        requests.push(dispatch(postGiact(accountProfile, verification, values, bankAccountTypes.billing, user)));
      }

      if (accountsChanged) {
        this.props.dispatch(suppressAppError(true));
        return Promise.all(requests).then(res => {
          const passedAccountsUpdated = CommonUtil.allValuesTrue(validationHistory.giactAccountValid);
          const responseTypes = res.find(giactResponse => _.get(giactResponse, 'type'));
          if (responseTypes && (responseTypes.type === actionTypes.giactSettlementFailure ||
            responseTypes.type === actionTypes.giactChargebackFailure ||
            responseTypes.type === actionTypes.giactBillingFailure)) {
            this.props.dispatch(giactSaveError(values.bankAccountKind, Boolean(responseTypes?.error?.rateLimitError)));
            this.setState({loading: false});
            this.props.dispatch(change('bankingForm', 'routingNumber', TextUtil.formatAccountNumber(values.routingNumber)));

          } else if (res.find(giactResponse => _.get(giactResponse, 'response.passFail') === 'Declined')) {
            res.map((giactResponse) => {
              switch (giactResponse.type) {
                case actionTypes.giactSettlementSuccess:
                  that.setState({giactMsgs: [...this.state.giactMsgs, bankAccountTypes.primary]});
                  break;
                case actionTypes.giactChargebackSuccess:
                  that.setState({giactMsgs: [...this.state.giactMsgs, bankAccountTypes.chargeback]});
                  break;
                case actionTypes.giactBillingSuccess:
                  that.setState({giactMsgs: [...this.state.giactMsgs, bankAccountTypes.billing]});
                  break;
              }
            });

            this.setState({
              openFilesDialog: true,
              businessValues: bankingAccountsData,
              loading: false
            });
          } else if (passedAccountsUpdated) {
            // All accounts passed server validation
            dispatch(profileChange());
            that.loadData();

          }
        }).catch(() => {
          this.setState({loading: false});
          this.loadData();
        });
      }
    }

  }

  handleAddFiles(values) {
    this.setState({openFilesDialog: false, loading: true});
    let data;
    return Promise.all(values.files.map(f => {
      return FileUtil.getBase64(f.file);
    })).then((results) => {
      results.forEach((result, i) => {
        const clearing = values.files[i].clearing;
        data = {
          image: result.base64,
          clearing_type: clearing
        };

        const {businessValues} = this.state;
        const {giactAccountResponseCode} = this.props.validationHistory;

        switch (clearing) {
          case bankAccountTypes.primary:
            data.account_number = businessValues.settlementAccount;
            data.routing_number = businessValues.settlementRouting;
            data.bank_type = businessValues.settlementType;
            data.giact_code = giactAccountResponseCode.settlement;
            break;
          case bankAccountTypes.chargeback:
            data.account_number = businessValues.chargebackAccount;
            data.routing_number = businessValues.chargebackRouting;
            data.bank_type = businessValues.chargebackType;
            data.giact_code = giactAccountResponseCode.chargeback;
            break;
          case bankAccountTypes.billing:
            data.account_number = businessValues.billingAccount;
            data.routing_number = businessValues.billingRouting;
            data.bank_type = businessValues.billingType;
            data.giact_code = giactAccountResponseCode.billing;
            break;
        }
      });
      this.props.dispatch(postGiactImage(this.props.user, data)).then(result => {
        if (result.type === actionTypes.giactImgSuccess) {
          this.props.dispatch(profileChange());
          this.setState({
            openGiactVerification: true
          });
        } else {
          this.props.dispatch(giactSaveError(values.files[0].clearing), false);
          this.props.dispatch(suppressAppError(true));
          this.setState({
            openFilesDialog: false,
            openUploadError: true,
          });
        }
      }).catch(error => {
        this.props.dispatch(giactSaveError(values.files[0].clearing), false);
        this.props.dispatch(suppressAppError(true));
        this.setState({
          openFilesDialog: false,
          openUploadError: true,
        });
      }).finally(() => {
        this.setState({
          loading: false,
          [this.state.accountToEdit]: false
        });
      });
    });
  }

  onSaveBankingClick() {
    this.props.dispatch(submit('bankingForm'));
  }

  onSaveFundingClick(event, confirmed) {
    if (confirmed) {
      this.setState({loading: true});
      this.props.dispatch(submit('fundingForm'));
    } else {
      this.setState({showConfirmFundingDialog: true});
    }

  }

  callPostIdology() {
    this.setState({loading: true});
    this.props.dispatch(postIdology(this.props.user, null, null)).then(() => {
      this.setState({loading: false});
      this.toggleEditBankAccout(this.state.accountToEdit);
    });
  }

  enableEdits(key) {
    const { verification, dispatch } = this.props;
    this.setState({accountToEdit: key});
    const isVerified = verification.twoFactorAuthenticationVerified[this.state.twoFactorType];
    if (!isVerified) {
      this.openTwoFactorDialog(twoFactorTypes.bankingAccess);
    } else {
      this.toggleEditBankAccout(key);
      dispatch(giactSaveErrorReset());
    }
  }

  editPrimaryBankAccount() {
    this.enableEdits('editPrimaryBankAccount');
  }

  editChargeBackBankAccount() {
    this.enableEdits('editChargeBackBankAccount');
  }

  editBillingBankAccount() {
    this.enableEdits('editBillingBankAccount');
  }

  toggleEditBankAccout(key) {
    const keys = {
      editPrimaryBankAccount: false,
      editChargeBackBankAccount: false,
      editBillingBankAccount: false
    };
    keys[key] = true;
    this.setState({...keys});
  }

  on2faVerified() {
    this.callPostIdology();
  }

  on2faProcessing(state) {
    this.setState({ loadingSpinner: state });
  }

  translatedAccountType(type) {
    const { t } = this.props;

    switch (type) {
      case bankAccountTypes.primary:
        return t('FundingComponent.ImgVerificationDialog.Primary');
      case bankAccountTypes.chargeback:
        return t('FundingComponent.ImgVerificationDialog.Chargeback');
      case bankAccountTypes.billing:
        return t('FundingComponent.ImgVerificationDialog.Billing');
    }
  }

  openTwoFactorDialog(twoFactorType) {
    this.setState({ twoFactorType }, () => {
      this.props.dispatch(setModalVisibility('twoFactorDialog'));
    });
  }

  render() {

    const {accountProfile, verification, validationHistory, user, auth, t} = this.props;

    const { giactMsgs } = this.state;

    const loading = !!this.state.loading;

    const label = LabelUtil.getLabel();

    const isHbt = UserUtil.isHbt(user);

    const twoFactorVerified = verification.twoFactorAuthenticationVerified;
    const bankingAccess = twoFactorVerified[twoFactorTypes.bankingAccess];

    const confirmFundingDialog = (
      <Modal
        title={t('FundingComponent.ConfirmFundingDialog.Title')}
        open={this.state.showConfirmFundingDialog}
        confirmText={t('Okay')}
        onConfirm={this.confirmedFunding}
        onClose={this.cancelConfirmFunding}
      >
        <span>{t('FundingComponent.ConfirmFundingDialog.Text')}</span>
      </Modal>
    );

    const fundingOptimization = this.state.showFundingOptimization && (
      <div className='businessFormHolder fundingOptimizationContainer'>
        <FundingForm
          {...this.props}
          onSubmit={this.handleSaveFunding}
          submitFundingForm={this.onSaveFundingClick}
          toggleDebitCardInfo={this.toggleDebitCardInfo}
          displaySavedDebitCard={this.state.displaySavedDebitCard}
        />
      </div>
    );

    const giactImgVerificationDialog = (
      <Modal
        title=''
        open={this.state.openGiactVerification}
        cancelText={t('Close')}
        onClose={this.handleGiactImgVerificationClose}
        hideConfirmButton
      >
        {t('FundingComponent.ImgVerificationDialog.Text', {type: this.translatedAccountType(giactMsgs[giactMsgs.length - 1])})}
      </Modal>);

    const accountType = this.state.giactMsgs.join(', ');
    const clearingType = this.props.formReducer?.bankingForm?.values?.bankAccountKind;

    const addFilesDialog = !!this.state.giactMsgs.length && (
      <Modal
        id='attachFilesModal'
        title={t('FundingComponent.AddFilesDialog.Title')}
        open={this.state.openFilesDialog}
        confirmText={t('Attach')}
        cancelText={t('GoBackAction')}
        onClose={this.handleCloseFilesDialog}
        onConfirm={this.handleAttachFilesClick}
      >
        <p>{t(messages.accountSection.giact.accountType, { accountType })}</p>
        <AttachFilesForm
          clearingType={clearingType}
          displayType={AttachFilesFormType.GIACT}
          needsVerification={validationHistory.giactAccountNeedsVerification}
          onSubmit={this.handleAddFiles}
          dropzoneOptions={attachFileOptions.accountFundingDropzoneOptions}
          secondaryText={t('DropFileImagesPngJpgFormat')}
          validator
        />
      </Modal>);

    const uploadErrorDialog = (
      <Modal
        cancelText={t('Close')}
        title={t('FundingComponent.UploadErrorDialog.Title')}
        open={this.state.openUploadError}
        onClose={this.handleUploadErrorClose}
        hideConfirmButton
      >
        <p>{t('FundingComponent.UploadErrorDialog.Text')}</p>
      </Modal>
    );

    const successfullySavedFundingDialog =
      <Modal
        title={t('FundingComponent.SuccessfullySavedFundingDialog.Title')}
        open={this.state.successfullySavedFunding}
        confirmText={t('Ok')}
        onConfirm={this.handleSuccessfullySavedFundingClose}
        onClose={this.handleSuccessfullySavedFundingClose}
        hideCancelButton
      >
        <span>{t('FundingComponent.SuccessfullySavedFundingDialog.Text')}</span>
      </Modal>;

    const filterPanel = (
      <FilterPanel
        {...this.props}
        filterData={this.props.settingsMenuItems}
        selectFilterCallback={this.props.handleMenuSelection}
      />
    );

    const bankingComponent = accountProfile.bankingData && accountProfile.bankingData.banking ?
      <BankingComponent
        accountProfile={accountProfile}
        editPrimaryBankAccountAction={this.editPrimaryBankAccount}
        editChargeBackBankAccountAction={this.editChargeBackBankAccount}
        editBillingBankAccountAction={this.editBillingBankAccount}
        editPrimaryBankAccount={this.state.editPrimaryBankAccount && bankingAccess}
        editChargeBackBankAccount={this.state.editChargeBackBankAccount && bankingAccess}
        editBillingBankAccount={this.state.editBillingBankAccount && bankingAccess}
        verification={verification}
        validationHistory={this.props.validationHistory}
        user={user}
        submitAction={this.handleSaveBankingProfile}
        isOwner={auth.isOwner}
        isHbt={isHbt}
        t={t}
      /> :
      <div className='primaryBankingHolder'>
        <div className='formHeading'>
          {t('Banking.NoData', { phoneNumber: label.phoneNumber })}
        </div>
      </div>;

    return (
      <section className='fundingSection showBankingDetailsContainer'>
        <div className='pageWrap settings'>
          <Page
            loading={loading}
            title={t('Business Settings')}
            initialSubpage={t('Funding')}
          >
            <>
              <div className='flexContainer flexContainerResponsiveLayout'>
                {filterPanel}
                <div className='businessSettingsSubsection funding forms'>
                  {!isHbt &&
                    <div className='businessFormHolder '>
                      {fundingOptimization}
                    </div>
                  }
                  <div className='businessFormHolder'>
                    <div className='settingsFormContainer'>
                      <div className='title'>
                        <div className='formHeading'>
                          <span className='heading'>{t('Banking.Title')}</span>
                        </div>
                      </div>
                      {bankingComponent}
                      {(verification.twoFactorAuthenticationVerified[this.state.twoFactorType] && !isHbt) && (
                        <div className='detailPanelHolderButtons saveContainer'>
                          <Button
                            label={t('Save')}
                            onClick={this.onSaveBankingClick}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {addFilesDialog}
              {uploadErrorDialog}
              {giactImgVerificationDialog}
              {successfullySavedFundingDialog}
              {confirmFundingDialog}

              <TwoFactorTools
                type={this.state.twoFactorType}
                onProcessing={this.on2faProcessing}
                onVerified={this.on2faVerified}
                {...this.props}
              />

              {this.state.loadingSpinner && <Spinner/>}
            </>
          </Page>
        </div>
      </section>
    );
  }
}
