/**
North American Bancard ("NAB") CONFIDENTIAL MATERIAL

Copyright 2000 NAB, All Rights Reserved.

NOTICE:  All information contained herein is, and remains the property of NAB. The intellectual and technical concepts
contained herein are proprietary to NAB and may be covered by U.S. and Foreign Patents, patents in process, and are
protected by trade secret or copyright law. Dissemination of this information or reproduction of this material is
strictly forbidden unless prior written permission is obtained from NAB.  Access to the source code contained herein
is hereby forbidden to anyone except current NAB employees, managers or contractors who have executed Confidentiality
and Non-disclosure agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended publication or disclosure of this source code,
which includes information that is confidential and/or proprietary, and is a trade secret, of NAB.
ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE
CODE WITHOUT THE EXPRESS WRITTEN CONSENT OF NAB IS STRICTLY PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND
INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES NOT CONVEY OR
IMPLY ANY RIGHTS TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT
MAY DESCRIBE, IN WHOLE OR IN PART.

*/

import React, { Component } from 'react';
import {
  setVisibilityFilter,
  togglePlanListDialog
} from '../../actions/userExperienceActions';
import FilterPanel from '../shared/FilterPanel';
import CompetitorPerformance from './reputation/CompetitorPerformance';
import CustomerReviews from './reputation/CustomerReviews';
import ReputationSettings from './reputation/ReputationSettings';
import Trends from './reputation/Trends';
import { deleteReputations, getLocations, getReputationSettings, updateActiveLocation } from '../../actions/reputationActions';
import TodayDataUtil from '../util/TodayDataUtil';
import withLayoutBreakPoints from '../util/WithLayoutBreakPoints';
import UserUtil from '../util/UserUtil';
import MessageDialog from '../shared/MessageDialog';
import Modal from '../shared/Modal';
import EditLocation from './reputation/EditLocation';
import PlanUpgradeOptIn from '../account/PlanUpgradeOptIn';
import PlanList from '../account/plans/PlanList';
import planTypes from '../../constants/planTypes';
import Page from '../shared/Page';
import DateFilterAccessory from '../appBar/accessories/DateFilterAccessory';
import DateUtils from '../util/DateUtil';
import {setSelectedDateRange} from '../../actions/userExperienceActions';

export class Reputation extends Component {
  constructor(props) {
    super(props);
    this.handleFilterSelection = this.handleFilterSelection.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getReputationTabs = this.getReputationTabs.bind(this);
    this.openEditLocationModal = this.openEditLocationModal.bind(this);
    this.closeEditLocationModal = this.closeEditLocationModal.bind(this);
    this.closeNoLocationsDialog = this.closeNoLocationsDialog.bind(this);
    this.openNoLocationsDialog = this.openNoLocationsDialog.bind(this);
    this.isRMEnabled = this.isRMEnabled.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.loadComponent = this.loadComponent.bind(this);

    const {user, auth} = this.props;
    this.isCSorCSWriteUser = UserUtil.isUserCS(user, auth) || UserUtil.isUserCSWrite(user);

    const dateRange = this.props?.userExperience?.selectedDate?.dateRange
    ? this.props.userExperience.selectedDate.dateRange
    : DateUtils.getPersonalizedDateRange('Today', '00:00:00');

    this.state = {
      filterPanelData: [],
      isEditLocationDialogOpen: false,
      isNoLocationsDialogOpen: false,
      dateRange,
      showDatePicker: true
    };
  }

  componentDidMount() {
    this.loadComponent();
  }

  loadComponent() {
    const {user} = this.props;
    const shouldLoadReputation = UserUtil.isPremiumAccount(user) || UserUtil.isPremiumPlusAccount(user);
    if (shouldLoadReputation) {
      this.loadData();
      this.setState(this.getTabs());
      this.getReputationTabs();
    }
  }

  componentDidUpdate(prevProps) {
    const {googleAccount} = this.props.reputationManagement;
    const {googleAccount : prevGoogleAccount} = prevProps.reputationManagement;
    const prevAccountPlan = UserUtil.accountCurrentPlan(prevProps.user);
    const currentAccountPlan = UserUtil.accountCurrentPlan(this.props.user);

    if(prevGoogleAccount !== googleAccount){
      this.getReputationTabs();
    }

    if (prevAccountPlan !== currentAccountPlan) {
      this.loadComponent();
    }
  }

  getReputationTabs() {
    const {reputationManagement} = this.props;
    const {googleAccount} = reputationManagement;
    const isReputationManagementEnabled = (!_.isNull(googleAccount) && !this.isCSorCSWriteUser
      && !_.isUndefined(googleAccount)
      && (!_.isEmpty(googleAccount.client_id)));

    this.setState(this.getTabs(!isReputationManagementEnabled));
  }

  componentWillUnmount() {
    this.props.dispatch(setVisibilityFilter(null, 'filter'))
  }

  openEditLocationModal() {
    this.setState({isEditLocationDialogOpen: true});
  }

  closeEditLocationModal() {
    this.setState({isEditLocationDialogOpen: false});
    this.deleteToken();
  }

  setLocation(locationId) {
    this.setState({activeLocationId: locationId});
  }

  openNoLocationsDialog() {
    this.setState({isNoLocationsDialogOpen: true});
  }

  closeNoLocationsDialog() {
    this.setState({isNoLocationsDialogOpen: false});
    this.deleteToken();
  }

  async selectLocation() {
    this.setState({isEditLocationDialogOpen: false});
    await this.props.dispatch(updateActiveLocation(this.props.user, this.props.reputationManagement.locations, this.state.activeLocationId));
    this.loadData();
  }

  deleteToken() {
    this.props.dispatch(deleteReputations(this.props.user));
  }

  async loadData() {
    const {history, user, dispatch, auth} = this.props;
    let value = 'Trends';

    if (history?.location?.state?.openSettings || this.isCSorCSWriteUser) {
      value = 'Settings';
    }

    dispatch(setVisibilityFilter({property: 'type', value}, 'filter'));

    const role = TodayDataUtil.getRole(user.data.merchantAccounts, user.selectedMerchantAccount);

    if (role === 'owner' || role === 'admin' || role === 'manager' || this.isCSorCSWriteUser) {
      await dispatch(getReputationSettings(this.props.user, this.props.userExperience.selectedDate.dateRange));

      if (this.isRMEnabled() && !this.isCSorCSWriteUser) {
        await dispatch(getLocations(this.props.user));
        const locations = this.props.reputationManagement.locations;
        const activeLocation = locations?.find(({is_active}) => is_active)?.id;

        if (activeLocation) {
          return;

        } else if (locations?.length === 1) {
          const newActiveLocation = locations[0].id;
          await dispatch(updateActiveLocation(user, locations, newActiveLocation));
          dispatch(getLocations(this.props.user));
          return dispatch(getReputationSettings(this.props.user, this.props.userExperience.selectedDate.dateRange));

        } else if (locations?.length > 1) {
          this.openEditLocationModal();

        } else {
          this.openNoLocationsDialog();
        }
      }
    }
  }

  getTabs(disabled = false) {
    return {
        filterPanelData: [
        {name: 'Trends', disabled: disabled},
        {name: 'Customer Reviews', disabled: disabled},
        {name: 'Competitor Performance', disabled: disabled},
        {name: 'Settings', border: true }
      ]
    };
  }

  handleFilterSelection(filter) {
    this.props.dispatch(setVisibilityFilter({property: 'type', value: filter.name}, 'filter'));

    if(
      filter.name === 'Competitor Performance' ||
      filter.name === 'Settings'
    ){
      this.setState({showDatePicker: false});
    }else{
      this.setState({showDatePicker: true});
    }
  }

  isRMEnabled() {
    const {reputationManagement: {googleAccount}} = this.props;
    return (!_.isNull(googleAccount) && !_.isUndefined(googleAccount) && (!_.isEmpty(googleAccount.client_id)));
  }

  handleClosePlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(false));
  };

  handleOpenPlanListDialog = () => {
    this.props.dispatch(togglePlanListDialog(true));
  };

  handleDateSelection = (value, dateRange) => {
    this.setState({ dateRange });
    this.props.dispatch(setSelectedDateRange(value, dateRange));
    this.props.dispatch(getReputationSettings(this.props.user, dateRange));
  }

  render() {
    const {user, userExperience} = this.props;
    const isReputationManagementEnabled = this.isRMEnabled();

    const isMobile = this.props.LayoutBreakPoints && this.props.LayoutBreakPoints.isMaxTablet;

    const {visibilityFilter, t} = this.props;
    let displayContent = '';

    const editLocationsModal = (
      <Modal
        title={t('ReputationProject.Settings.SelectLocation')}
        confirmText={'Select'}
        cancelText={'Cancel'}
        open={this.state.isEditLocationDialogOpen}
        onClose={this.closeEditLocationModal}
        onConfirm={this.selectLocation}
        externalClassName={'editLocationDialog'}
      >
        <EditLocation setLocation={this.setLocation} locationId={this.state.activeLocationId} {...this.props}/>
      </Modal>
    );

    const noLocationsDialog = (
      <MessageDialog
        titleText={t('ReputationProject.Settings.LocationsErrorTitle')}
        confirmText={t('Okay')}
        bodyText={t('ReputationProject.Settings.LocationsErrorBody')}
        isChoiceRequired={false}
        open={this.state.isNoLocationsDialogOpen}
        onConfirm={this.closeNoLocationsDialog}
      />
    );

    const planListDialog = (
      <Modal
        title={t('UpgradePremiumPlus.ModalTitle')}
        centerTitle
        isClosableIconEnable
        floatingCloseButton
        hideConfirmButton
        hideCancelButton
        open={userExperience.openPlanListDialog}
        onClose={this.handleClosePlanListDialog}
        maxWidth='lg'
      >
        <PlanList {...this.props} />
      </Modal>
    );

    const pageAccessories =  this.state.showDatePicker ? [
      {
        name: 'dateFilter',
        accessory: (
          <DateFilterAccessory
            {...this.props}
            defaultValue={this.state.dateRange?.text}
            handleSelection={this.handleDateSelection}
            showAllTime={false}
            showPreviousCustomSelection={false}
            disableCompactVersion={true}
            dataName={'Trends'}
          />
        ),
        showInMobile: true,
        hide: false
      },
    ]:[];

    const tabFilter = (type) => {

      const trends = (<Trends {...this.props} dateRange={this.state.dateRange}/>);
      const tabs = {
        'Trends': trends,
        'Customer Reviews': (<CustomerReviews {...this.props} dateRange={this.state.dateRange} />),
        'Competitor Performance': (<CompetitorPerformance {...this.props} />),
        'Settings': (<ReputationSettings {...this.props} isCSorCSWriteUser={this.isCSorCSWriteUser} />),
        'default': trends
      };
      return (tabs[type] || tabs['default']);
    };

    if (visibilityFilter.filter) {
      displayContent = tabFilter(visibilityFilter.filter.value);
    }

    const disableMobileMenu =  isMobile && !isReputationManagementEnabled;

    const isPremium = UserUtil.isPremiumAccount(user);
    const isPremiumPlus = UserUtil.isPremiumPlusAccount(user);
    const shouldShowReputation = isPremium || isPremiumPlus;
    const optInData = [
      {
        img: '/images/reputation/upgradeLeftTop.svg',
        text: t('UpgradePremiumPlus.Reputation.TopLeft'),
      },
      {
        img: '/images/reputation/upgradeRightTop.svg',
        text: t('UpgradePremiumPlus.Reputation.TopRight'),
      },
      {
        img: '/images/reputation/upgradeLeftBottom.svg',
        text: t('UpgradePremiumPlus.Reputation.BottomLeft'),
      },
      {
        img: '/images/reputation/upgradeRightBottom.svg',
        text: t('UpgradePremiumPlus.Reputation.BottomRight')
      }
    ];

    const pageLoading = userExperience.isFetching;

    return ( shouldShowReputation ?
          <section className='reputation'>
            <Page
              loading={pageLoading}
              title={t('Reputation')}
              accessories={pageAccessories}
            >
              <>
                <div className='pageScrollableArea reputationWrapper flexContainerResponsiveLayout'>
                  <FilterPanel
                    {...this.props}
                    className={`filterPanel reputationFilterPanel ${disableMobileMenu ? 'disabledReputationFilterPanel' : ''}`}
                    defaultIcon=''
                    filterData={this.state.filterPanelData}
                    selectFilterCallback={this.handleFilterSelection}
                  />
                  <div className='reputationContent'>
                    {displayContent}
                  </div>
                </div>
                {editLocationsModal}
                {noLocationsDialog}
              </>
            </Page>
          </section> :
        <Page
          title={t('Reputation')}
          loading={pageLoading}
          accessories={pageAccessories}
        >
          <div className='upgradeReputation'>
            <FilterPanel {...this.props} />
            <div className='upgradeContainerPadding upgradeInvoicesContainer'>
              <PlanUpgradeOptIn
                {...this.props}
                data={optInData}
                feature={t('ReputationScreen.ReputationManagement')}
                openPlansModal={this.handleOpenPlanListDialog}
                planType={planTypes.premium}
              />
              {planListDialog}
            </div>
          </div>
        </Page>
    );
  }
}

export default withLayoutBreakPoints(Reputation);
